import { BaseSliceController } from '@Base/slices/slices.base';
import { AttendancesRequester } from '../Services/StudentTestsRequester';
import { AttendancesPayload } from '../Types/Attendaces.types';

export class StudentAttendancesSlice extends BaseSliceController<
	AttendancesPayload,
	'StudentAttendances',
	AttendancesPayload
> {
	private static instance: StudentAttendancesSlice;
	static getInstance() {
		if (!StudentAttendancesSlice.instance) {
			StudentAttendancesSlice.instance = new StudentAttendancesSlice();
		}
		return StudentAttendancesSlice.instance;
	}
	constructor() {
		super('StudentAttendances', AttendancesRequester.getInstance());
	}
}

export const { studentAttendancesGetAll, studentAttendancesExportData } = StudentAttendancesSlice.getInstance().getActions();
