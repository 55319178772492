import { useTypedSelector } from '@Features/store';
import { getSingleCategory } from '@Pages/Categories/Slices/CategoriesSlice';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import EditCategoryInfo from './Components/EditCategoryInfo';

const EditCategory = () => {
	const {
		currentCategory,
		loaders: { getOne },
	} = useTypedSelector((state) => state.category);

	const { id } = useParams<{ id: string }>();
	const dispatch = useDispatch();

	useEffect(() => {
		if (id)
			dispatch(
				getSingleCategory({
					id: Number(id),
				})
			);
	}, []);

	return (
		<Container>
			{currentCategory && getOne === 'fulfilled' && <EditCategoryInfo />}
		</Container>
	);
};

export const Container = styled.div`
	padding: 1.375rem;
	border-radius: 10px;
	border: 1px solid #5ac0fc;
	height: 100vh;
`;

export default EditCategory;
