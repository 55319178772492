import { STUDENT_TABLE_SELECT_DATA } from '@Constants/inputs';
import { useTypedSelector } from '@Features/store';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useImmer } from 'use-immer';
import { useSnackbar } from '@Providers/useSnackbar';
import {
	CancelBtn,
	SaveBtn,
} from '@Pages/Roles/pages/AddRole/Components/addRoleInfo';

import { AddressesRespondData } from '@Services/educationalInfo/educationalInfo.types';
import { getAddressesData } from '@Services/educationalInfo/educationalInfo.req';
import { FlexLayout } from '@Styled/utilities';
import EdFormControl from '@Components/UI/Inputs/EdFormControl/EdFormControl';
import { SingleStudentData } from '@Services/students/students.res.types';
import { Divider, GridLayout } from '@Styled/utilities';
import styled from 'styled-components';
import { Checkbox, MenuItem } from '@mui/material';
import { useFormUpdater } from '@Hooks/Forms/useFormUpdater';
import { getEducationalInfo } from '@Features/classrooms/educationalSlice';
import { ChipFlexLayout } from '@Pages/ClassRooms/Components/AddEditClassroom.styled';
import { INFO_SCHEMA } from './Schema/Info.Schema';
import PickerDate from './Components/PickerDate';
import { StudentsRequester } from '@Pages/Students/Services/Students.Requester';
import { DevTool } from '@hookform/devtools';
import { cloneDeep } from 'lodash';
import { studentsGetSingle } from '@Pages/Students/Slice/Students.Slice';
export type SelectedAdderess = {
	id?: number;
	name?: string;
	cities?: Array<{
		id: number;
		name: string;
		governorate_id: number;
	}>;
};
export type SelectedCity = {
	id?: number;
	name?: string;
	governorate_id?: number;
};

const EditStudentForm: React.FC = () => {
	const { displaySnackbar } = useSnackbar();
	const dispatch = useDispatch();
	const history = useHistory();
	const { currentEntity } = useTypedSelector((state) => state.Students);
	const { educational_info, educational_type } = useTypedSelector(
		(state) => state.educational
	);
	const auth = useTypedSelector((state) => state.auth);
	const hasEditPermission =
		auth.user.type === 'super' || auth.permissions.has('u-st');
	const [AddressesData, setAddressesData] = useImmer<AddressesRespondData>([]);
	const { address } = currentEntity?.student;

	const defaultValues: SingleStudentData = React.useMemo(() => {
		return cloneDeep(currentEntity);
	}, [currentEntity]);

	const [, setSelectedAddress] = useState<SelectedAdderess>({});
	useEffect(() => {
		dispatch(getEducationalInfo());
	}, []);

	const { control, getValues, watch, setValue, formState, handleSubmit } =
		useForm<SingleStudentData>({
			mode: 'all',

			resolver: yupResolver(INFO_SCHEMA),
			defaultValues,
		});

	const watchTypes = watch('student.education_type_id');
	const watchYears = watch('student.education_year_id');
	const watchLanguages = watch('student.education_language_id');
	const watchSections = watch('student.education_section_id');
	const watchGovernorate = watch('student.address.governorate');

	const years = React.useMemo(() => {
		if (!watchTypes) {
			setValue('student.education_year_id', watchTypes);
		}
		const flatYears = educational_info
			.filter((_) => _.id)
			.map(({ educationYears }) => educationYears)
			.flat();
		return flatYears.filterDups('id');
	}, [watchTypes, educational_info]);

	const languages = React.useMemo(() => {
		if (!watchTypes) {
			setValue('student.education_language_id', watchTypes);
		}
		const flatLanguages = educational_info
			.filter((_) => _.id)
			.map(({ educationLanguages }) => educationLanguages)
			.flat();
		return flatLanguages.filterDups('id');
	}, [watchTypes, educational_info]);

	const sections = React.useMemo(() => {
		if (!watchYears) return;
		if (!watchYears) {
			setValue('student.education_section_id', watchYears);
		}
		const flatYears = educational_info
			.map(({ educationYears }) => educationYears)
			.flat()
			.filterDups('id')
			.filter((_) => _?.id);

		return flatYears
			.map(({ educationSections }) => educationSections)
			.flat()
			.filterDups('id');
	}, [watchYears, educational_info]);

	const cities = React.useMemo(() => {
		if (!watchGovernorate) return;
		if (!watchGovernorate) {
			setValue('student.address.city', watchGovernorate);
		}
		const flatCities = AddressesData?.filter((_) => _.id === watchGovernorate)
			.map(({ cities }) => cities)
			.flat();
		if (!flatCities) return;
		return flatCities?.filterDups('id');
	}, [watchGovernorate, AddressesData]);

	const loadAddressesData = async () => {
		try {
			const {
				data: { data },
			} = await getAddressesData();
			setAddressesData(data);
		} catch (e) {
			displaySnackbar(
				'error',
				e.response.data.message ?? 'Error in fetching Addresses data '
			);
		}
	};
	useEffect(() => {
		loadAddressesData();
	}, []);

	useEffect(() => {
		if (AddressesData.length !== 0 && address?.governorate) {
			let addressVal = null;
			for (let addre of AddressesData) {
				if (addre.id === Number(address?.governorate)) {
					addressVal = addre;
				}
			}
			if (addressVal) {
				setSelectedAddress(addressVal);
			}
		}
	}, [AddressesData]);

	const _onSubmit = async (form: SingleStudentData) => {
		console.log({ form });
		try {
			const {
				data: { data },
			} = await StudentsRequester.getInstance().update(form);
			dispatch(studentsGetSingle({ id: currentEntity?.uuid }));
			displaySnackbar('success', `Student updated successfully`);
		} catch (e) {
			const msg = e.response?.data.message || 'Unable to update student ';
			displaySnackbar('error', msg);
		}
	};

	const { isDirty, isValid } = formState;

	return (
		<form>
			<DevTool control={control} />
			<FlexLayout flexDirection="column">
				<Divider label="Educational Info" />
				<SectionLayout
					gridGap={'2rem'}
					gridTemplateColumns={{ sm: 'repeat(1,1fr)' }}
					marginBottom="2rem"
				>
					<EdFormControl
						control={control}
						name="student.school"
						label="School"
						disabled={!hasEditPermission}
					/>
				</SectionLayout>
				<SectionLayout
					gridGap={'2rem'}
					gridTemplateColumns={{ sm: 'repeat(2,1fr)' }}
				>
					<EdFormControl
						control={control}
						select
						name="student.education_type_id"
						multiple
						width="17rem"
						disabled={!hasEditPermission}
						renderValues={(value) => {
							if (!educational_type) return;
							const name =
								watchTypes &&
								educational_type?.find((_) => _?.value === watchTypes)?.name;
							return <div>{name}</div>;
						}}
						SelectProps={{
							multiple: false,
							renderValue: (selected) => {
								return (
									<ChipFlexLayout>
										{watchTypes &&
											educational_type.find((_) => _.value === watchTypes)
												?.name}
									</ChipFlexLayout>
								);
							},
						}}
						label="Diploma"
					>
						{educational_type.map((_, index) => {
							return <MenuItem value={_.value}>{_.name}</MenuItem>;
						})}
					</EdFormControl>

					<EdFormControl
						control={control}
						select
						name="student.education_year_id"
						width="17rem"
						disabled={!hasEditPermission}
						renderValues={(value) => {
							return (
								<div>
									{watchYears &&
										years?.find((_: any) => _?.id === watchYears)?.name}
								</div>
							);
						}}
						SelectProps={{
							multiple: false,
							renderValue: (selected) => {
								return (
									<ChipFlexLayout>
										{watchYears &&
											years?.find((_: any) => _?.id === watchYears)?.name}
									</ChipFlexLayout>
								);
							},
						}}
						label={'Study Year'}
					>
						{years?.map((_: any, index) => {
							return (
								<MenuItem key={`ed-year-${_.id}`} value={_.id}>
									{_.name}
								</MenuItem>
							);
						})}
					</EdFormControl>
					{sections &&
						years?.find((_: any) => _?.id === watchYears)?.name ===
							'Third_Secondary' && (
							<EdFormControl
								control={control}
								select
								name="student.education_section_id"
								multiple
								width="17rem"
								disabled={!hasEditPermission}
								renderValues={(value) => {
									return (
										<div>
											{watchSections &&
												sections.find((_: any) => _.id === watchSections)?.name}
										</div>
									);
								}}
								SelectProps={{
									multiple: false,
									renderValue: (selected) => {
										return (
											<ChipFlexLayout>
												{watchSections &&
													sections.find((_: any) => _.id === watchSections)
														?.name}
											</ChipFlexLayout>
										);
									},
								}}
								label="Study Branch "
							>
								{sections.map((_: any, index) => {
									return <MenuItem value={_.id}>{_.name}</MenuItem>;
								})}
							</EdFormControl>
						)}
					<EdFormControl
						control={control}
						select
						name="student.education_language_id"
						multiple
						width="17rem"
						disabled={!hasEditPermission}
						renderValues={(value) => {
							return (
								<div>
									{languages.find((_: any) => _.id === watchLanguages)?.name}
								</div>
							);
						}}
						SelectProps={{
							multiple: false,
							renderValue: (selected) => {
								return (
									<ChipFlexLayout>
										{watchLanguages &&
											languages.find((_: any) => _.id === watchLanguages)?.name}
									</ChipFlexLayout>
								);
							},
						}}
						label="Study Language"
					>
						{languages.map((_: any, index) => {
							return <MenuItem value={_.id}>{_.name}</MenuItem>;
						})}
					</EdFormControl>
				</SectionLayout>

				<Divider label="Personal Info" />
				<SectionLayout
					gridGap={'2rem'}
					gridTemplateColumns={{ sm: 'repeat(2,1fr)' }}
				>
					<EdFormControl
						control={control}
						name="first_name"
						label="First Name"
						disabled={!hasEditPermission}
					/>
					<EdFormControl
						control={control}
						name="last_name"
						label="Last Name"
						disabled={!hasEditPermission}
					/>
					<EdFormControl
						control={control}
						name="phone_number"
						label="Phone no."
						disabled={!hasEditPermission}
					/>
					<EdFormControl
						control={control}
						name="email"
						label="Email"
						disabled={!hasEditPermission}
					/>
					<EdFormControl
						control={control}
						name="username"
						label="Username"
						disabled={!hasEditPermission}
					/>
					<EdFormControl
						control={control}
						select
						name="gender"
						label="Gender"
						disabled={!hasEditPermission}
					>
						{STUDENT_TABLE_SELECT_DATA &&
							STUDENT_TABLE_SELECT_DATA.gender.map((opt, index) => (
								<MenuItem
									key={opt.name + opt.value}
									value={opt.value}
								>{`${opt.name}`}</MenuItem>
							))}
					</EdFormControl>
					<PickerDate
						control={control}
						name={`birth_date`}
						disabled={!hasEditPermission}
					/>
					<EdFormControl
						control={control}
						select
						name="student.address.governorate"
						label="Governorate"
						disabled={!hasEditPermission}
					>
						{AddressesData &&
							AddressesData.map((_, index) => (
								<MenuItem key={_.id} value={_.id}>{`${_.name}`}</MenuItem>
							))}
					</EdFormControl>
				</SectionLayout>
				<SectionLayout
					gridTemplateColumns={{ sm: 'repeat(1,1fr)' }}
					marginTop="2rem"
					marginBottom="2rem"
				>
					<EdFormControl
						control={control}
						select
						name="student.address.city"
						label="City"
						disabled={!hasEditPermission}
					>
						{cities?.map((_) => {
							return <MenuItem key={_.id} value={_.id}>{`${_.name}`}</MenuItem>;
						})}
					</EdFormControl>
					<EdFormControl
						control={control}
						name="password"
						label="Password"
						// disabled={!hasEditPermission}
					/>
				</SectionLayout>
				<SectionLayout>
					<EdFormControl
						control={control}
						select
						name="student.parent_relation"
						label="Parent Relation"
						disabled={!hasEditPermission}
					>
						{STUDENT_TABLE_SELECT_DATA &&
							STUDENT_TABLE_SELECT_DATA.parent_relation.map((opt, index) => (
								<MenuItem
									key={opt.name + opt.value}
									value={opt.value}
								>{`${opt.name}`}</MenuItem>
							))}
					</EdFormControl>
					<EdFormControl
						control={control}
						name="student.parent_phone"
						label="Parent Phone"
						disabled={!hasEditPermission}
					/>
					<EdFormControl
						control={control}
						switch
						name="phone_verified"
						label="Phone verified"
						disabled={!hasEditPermission}
					/>
					<EdFormControl
						control={control}
						switch
						name="email_verified"
						label="Email verified"
						disabled={!hasEditPermission}
					/>
					<EdFormControl
						control={control}
						switch
						name="student.profile_complete"
						label="Profile complete"
						disabled={!hasEditPermission}
					/>
					<EdFormControl
						control={control}
						switch
						name="student.registration_paid"
						label="Fees paid"
						disabled={!hasEditPermission}
					/>
				</SectionLayout>
			</FlexLayout>
			{hasEditPermission && (
				<FlexLayout justifyContent="flex-end" marginTop="1.5rem" gridGap="1rem">
					<CancelBtn
						variant="contained"
						// disabled={!isDirty}
						onClick={() => history.push('/dashboard/students')}
					>
						CANCEL
					</CancelBtn>
					<SaveBtn
						variant="contained"
						// disabled={!isDirty || !isValid}
						onClick={handleSubmit(_onSubmit, (e) => console.log(e))}
					>
						SAVE
					</SaveBtn>
				</FlexLayout>
			)}
		</form>
	);
};

export const SectionLayout = styled(GridLayout)`
	margin-right: 5.5rem;
	grid-gap: 2rem;
`;

export default EditStudentForm;
