import { useTypedSelector } from '@Features/store';
import React from 'react';
import EditSlotInfo from './Components/EditSlotInfo';

const EditSlot: React.FC = () => {
	const { currentEntity } = useTypedSelector((state) => state.Webinars);

	return <>{currentEntity && <EditSlotInfo />}</>;
};

export default EditSlot;
