import { HashString } from '@Components/UI/Tabs/RoutedTabs/RoutedTabs.types';
import { getCourseById } from '@Features/classrooms/classroomSlice';
import { useTypedSelector } from '@Features/store';
import { FormMode } from '@Pages/Instructors/instructor.types';
import { FlexLayout } from '@Styled/utilities';
import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import {
	Redirect,
	Route,
	Switch,
	useLocation,
	useParams,
	useRouteMatch,
} from 'react-router-dom';
import { FormContainerStyle } from './Form.Styles';
import { CourseFormRouteGroup } from './Constants/routes';
import MainCourseForm from './Pages/MainCourseForm/MainCourseForm';
import { Test } from './Pages/CourseContent/Pages/Test/Test';

const CourseForm = () => {
	const { path, url } = useRouteMatch();
	const { id } = useParams<{ id: string }>();
	const dispatch = useDispatch();
	const mode = useMemo<FormMode | undefined>(() => {
		if (!path) return;
		if (path.includes('create')) {
			return FormMode.ADD;
		} else {
			return FormMode.UPDATE;
		}
	}, [path]);

	useEffect(() => {
		if (mode === FormMode.UPDATE) {
			dispatch(
				getCourseById({
					id,
				})
			);
		}
	}, [mode]);

	const { hash, pathname } = useLocation();
	const tabIndex = useMemo<HashString>(() => {
		if (!hash) return '#info';
		return hash as HashString;
	}, [hash]);
	return (
		<>
			<FlexLayout px="4rem">
				<FormContainerStyle>
					<Switch>
						<Route path={`${path}`}>
							<MainCourseForm baseURL={url} />
						</Route>

						<Route path={'/**'} render={() => <div>404</div>} />
					</Switch>
				</FormContainerStyle>
			</FlexLayout>
		</>
	);
};

export default CourseForm;
