import api from '@Services/api';
import {
	UploadDocumentPayload,
	UploadPicturePayload,
	UploadVideoPayload,
} from './uploads.req.types';
import {
	UploadDocumentResponse,
	UploadPictureResponse,
	UploadVideoResponse,
} from './uploads.res.types';
import { AxiosRequestConfig } from 'axios';

export type VideoProgress = {
	onUploadProgress: (progressEvent: any) => void;
};

export const uploadVideo = async (
	{ video }: UploadVideoPayload,
	options: VideoProgress
) => {
	const formData = new FormData();
	formData.append('video', video);
	return await api.post<ApiResponse<UploadVideoResponse>>(
		'upload/video',
		formData,
		options
		// {
		//   headers: { "Content-Type": "multipart/form-data" },
		// }
	);
};

export const uploadPicture = async ({
	folder,
	photo,
}: UploadPicturePayload) => {
	const formData = new FormData();
	formData.append('photo', photo);
	formData.append('folder', folder);
	return await api.post<ApiResponse<UploadPictureResponse>>(
		'upload/photo',
		formData,
		{
			headers: { 'Content-Type': 'multipart/form-data' },
		}
	);
};

export const uploadDocument = async (
	{ folder, document }: UploadDocumentPayload,
	options?: AxiosRequestConfig
) => {
	const formData = new FormData();
	formData.append('document', document as Blob);
	formData.append('folder', folder);
	return await api.post<ApiResponse<UploadDocumentResponse>>(
		'upload/document',
		formData,
		{
			...options,
			headers: { 'Content-Type': 'multipart/form-data' },
		}
	);
};
