import React, { useEffect, useState } from 'react';
import { FlexLayout, Spacer } from '@Styled/utilities';
import {
	Button,
	ButtonGroup,
	Grow,
	Icon,
	InputAdornment,
	LinearProgress,
	MenuItem,
	MenuList,
	Paper,
	Popper,
} from '@mui/material';
import styled from 'styled-components';
import { useTypedSelector } from '@Features/store';
import EdUploadFile from '@Components/UI/Inputs/EdUploadFile/EdUploadFile';
import { useForm } from 'react-hook-form';
import { uploadDocument } from '@Services/uploads/uploads.req';
import { DevTool } from '@hookform/devtools';
import EdFormControl from '@Components/UI/Inputs/EdFormControl/EdFormControl';
import {
	ADD_Document,
	AddDocumentForm,
} from '@Pages/ClassRooms/Pages/SingleCourse/Pages/CourseForm/Pages/CourseContent/Pages/Documents/Schema/DocumentInfo.schema';
import { yupResolver } from '@hookform/resolvers/yup';
import { DocumentsRequester } from '@Pages/ClassRooms/Pages/SingleCourse/Pages/CourseForm/Pages/CourseContent/Pages/Documents/Services/Documents.req';
import { DocumentPayload } from '@Pages/ClassRooms/Pages/SingleCourse/Pages/CourseForm/Pages/CourseContent/Pages/Documents/Types/Documents.types';
import { useSnackbar } from '@Providers/useSnackbar';
import { useHistory } from 'react-router-dom';
import { DropButton } from '../Web Content/Pages/AddWebContent/AddWebContent';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';

const AddDocumentFormPage: React.FC = () => {
	const { currentCourse } = useTypedSelector((state) => state.classroom);

	const {
		handleSubmit,
		formState: { isValid, isDirty, isSubmitting },
		control,
		setValue,
		reset,
		getValues,
	} = useForm<AddDocumentForm>({
		resolver: yupResolver(ADD_Document),
		reValidateMode: 'onChange',
		mode: 'all',
		defaultValues: {
			unit: {
				type_id: 1,
			},
		},
	});
	const { displaySnackbar } = useSnackbar();
	const history = useHistory();

	const [open, setOpen] = React.useState(false);
	const anchorRef = React.useRef<HTMLDivElement>(null);

	const handleToggle = () => {
		setOpen((prevOpen) => !prevOpen);
	};

	const handleClose = (event: Event) => {
		if (anchorRef.current?.contains(event.target as HTMLElement)) {
			return;
		}
		setOpen(false);
	};

	const onSubmit = async (form: AddDocumentForm) => {
		try {
			const {
				data: { data },
			} = await DocumentsRequester.getInstance().create(
				form as DocumentPayload
			);
			displaySnackbar('success', 'Document Is Added Successfully');
			history.goBack();
		} catch (e) {
			const msg = e?.data?.message || 'Unable To Add Document';
			displaySnackbar('error', msg);
		}
	};

	const [uploadPercentage, setUploadPercentage] = useState<
		number | undefined
	>();
	const UploadDocument = async (blob: File | Blob | null) => {
		try {
			const {
				data: {
					data: { uri, extension, size, file_name },
				},
				status,
			} = await uploadDocument(
				{
					document: blob,
					folder: 'documents',
				},
				{
					onUploadProgress(progressEvent) {
						const { loaded, total } = progressEvent;
						let percent = Math.floor((loaded * 100) / total);
						if (percent === 100) {
							setUploadPercentage(undefined);
						} else if (percent < 100) {
							setUploadPercentage(percent);
						}
					},
				}
			);

			setValue('document.uri', uri, {
				shouldValidate: true,
				shouldDirty: true,
				shouldTouch: true,
			});
			setValue('document.extension', extension);
			setValue('document.size', size);
			setValue('document.file_name', file_name);
			return status === 200;
		} catch (e) {
			const msg = e?.data?.message || 'Unable to Upload Document ';
			displaySnackbar('error', msg);
		}
	};
	useEffect(() => {
		setValue('unit.course_id', currentCourse?.id as number);
	}, [currentCourse]);

	const handleReset = () => {
		reset({
			unit: {
				type_id: 1,
				course_id: currentCourse?.id,
				name: '',
			},
			document: {
				uri: '',
			},
		});
	};

	const onSaveAddAnother = async (form: AddDocumentForm) => {
		try {
			const {
				data: { data },
			} = await DocumentsRequester.getInstance().create(
				form as DocumentPayload
			);
			displaySnackbar('success', 'Created Web Content successfully');
			handleReset();
		} catch (e) {
			const msg = e.response?.data.message || 'Unable to Create Web Content ';
			displaySnackbar('error', msg);
		}
	};

	return (
		<form>
			<StyledHeader>{currentCourse?.name}</StyledHeader>
			<DevTool control={control} />
			<EdFormControl
				control={control}
				name="unit.name"
				label="Unit Name"
				InputProps={{
					startAdornment: (
						<InputAdornment position="start">
							<Icon className="material-icons-outlined">description</Icon>
						</InputAdornment>
					),
				}}
			/>
			<FlexLayout mt="32px" alignItems={'center'} gridGap="1rem">
				<StyledDiv>
					<EdUploadFile
						aspectRation={1}
						folder="documents"
						uploadLabel="UPLOAD FILE"
						uploadIcon={<Icon>upload_file</Icon>}
						control={control}
						name="document.uri"
						label=" "
						type="document"
						onFileUpload={UploadDocument as any}
					/>
				</StyledDiv>
				{uploadPercentage !== undefined && (
					<LabelWrapper>
						<Label>
							<span>{uploadPercentage}%</span>
						</Label>
						<LinearProgressStyle
							variant="determinate"
							value={uploadPercentage}
						/>
					</LabelWrapper>
				)}
			</FlexLayout>

			<FlexLayout justifyContent="flex-end">
				<Button
					variant="contained"
					color="warning"
					onClick={() => history.goBack()}
				>
					Cancel
				</Button>
				<Spacer mx="0.5rem" />
				<ButtonGroup
					variant="contained"
					ref={anchorRef}
					aria-label="split button"
				>
					<Button
						disabled={isSubmitting || !isValid}
						onClick={() => {
							const data = getValues();
							onSubmit(data);
						}}
						variant="contained"
					>
						Save
					</Button>
					<DropButton
						size="small"
						aria-controls={open ? 'split-button-menu' : undefined}
						aria-expanded={open ? 'true' : undefined}
						onClick={handleToggle}
						variant="contained"
						disabled={isSubmitting || !isValid}
					>
						<ArrowDropDownIcon />
					</DropButton>
				</ButtonGroup>
				<Popper
					sx={{
						zIndex: 1,
					}}
					open={open}
					anchorEl={anchorRef.current}
					role={undefined}
					transition
					disablePortal
				>
					{({ TransitionProps, placement }) => (
						<Grow
							{...TransitionProps}
							style={{
								transformOrigin:
									placement === 'bottom' ? 'center top' : 'center bottom',
							}}
						>
							<Paper>
								<ClickAwayListener onClickAway={handleClose}>
									<MenuList id="split-button-menu" autoFocusItem>
										<MenuItem
											key={1}
											onClick={() => {
												const data = getValues();
												onSaveAddAnother(data);
											}}
										>
											Save and add another
										</MenuItem>
									</MenuList>
								</ClickAwayListener>
							</Paper>
						</Grow>
					)}
				</Popper>
			</FlexLayout>
		</form>
	);
};

const StyledHeader = styled.p`
	color: ${(props) => props.theme.palette?.purple?.main};
	font-size: 22px;
	font-weight: bold;
	margin-bottom: 35px;
`;
const StyledDiv = styled.div`
	width: 28.4rem;
	margin-top: 32px;
`;


const LinearProgressStyle = styled(LinearProgress)`
	width: 8.25rem;
	height: 1.063rem;
	border-radius: 10px;
	background: #afafaf;
`;

const LabelWrapper = styled.div`
	position: relative;
`;

const Label = styled.div`
	position: absolute;
	top: 1px;
	left: 6px;
	z-index: 1;
	text-align: left;
	display: flex;
	align-items: center;
	margin-top: 0.05rem;
	span {
		width: 100%;
		font-size: 0.75rem;
		color: #fff;
		font-weight: 500;
	}
`;

export default AddDocumentFormPage;
