import { HexCode } from './Tags';

export type TestQuestionType =
	| 'mcq'
	| 'ordering'
	| 'gap'
	| 'matching'
	| 'dragAndDrop'
	| 'essay';

export type EssayAnswerSchema = '*' | 'text' | 'attachment';

export type MCQOption = {
	id?: number;
	choice: string;
	is_correct: boolean;
} & TimeStamp;

export type OrderQuestion = {
	option: string;
	order: number;
};
export type Gap = {
	choices: string;
	correct: string;
	gap: number;
	id: number;
	test_question_id: number;
} & TimeStamp;
export type Essay = {
	choices: string;
	correct: string;
	gap: number;
	id: number;
	test_question_id: number;
} & TimeStamp;

export type TestQuestion = {
	id: number;
	test_id: number;
	weight: number | null;
	content: string;
	type: TestQuestionType;
	feedback: string;
	answer_schema?: string;
	order: number;
	orderingOptions: Array<OrderQuestion>;
	parsed_content: string;
	multipleChoiceOptions: Array<MCQOption>;
	fillInTheGapOptions: Array<Gap>;
	tags: Array<Tag>;
} & TimeStamp;

export type TypedTestQuestion<T extends TestQuestionType> = T extends 'mcq'
	? Omit<TestQuestion, 'type'> & {
			multipleChoiceOptions: Array<MCQOption>;
			type: T;
	  }
	: T extends 'gap'
	? Omit<TestQuestion, 'type'> & { type: T }
	: T extends 'ordering'
	? Omit<TestQuestion, 'type'> & { type: T }
	: T extends 'essay'
	? Omit<TestQuestion, 'type'> & { type: T }
	: never;

export type TypedTestOrderingQuestion<T extends TestQuestionType> =
	T extends 'ordering'
		? Omit<TestQuestion, 'type'> & {
				orderingOptions: Array<OrderQuestion>;
				type: T;
		  }
		: never;

export type TypedTestOrderingQuestionPayload<T extends TestQuestionType> =
	T extends 'ordering'
		? Omit<TestQuestionPayload, 'type'> & {
				orderingOptions: Array<OrderQuestion>;
				type: T;
		  }
		: never;

export type TypedTestEssayQuestion<T extends TestQuestionType> =
	T extends 'essay'
		? Omit<TestQuestion, 'type'> & {
				orderingOptions: Array<Essay>;
				type: T;
		  }
		: never;

export type TypedTestEssayQuestionPayload<T extends TestQuestionType> =
	T extends 'essay'
		? Omit<TestQuestionPayload, 'type'> & {
				orderingOptions: Array<Essay>;
				type: T;
		  }
		: never;

export type TypedTestQuestionPayload<T extends TestQuestionType> =
	T extends 'mcq'
		? Omit<TestQuestionPayload, 'type'> & {
				multipleChoiceOptions: Array<MCQOption>;
				type: T;
				canAddChoice?: boolean;
		  }
		: T extends 'gap'
		? Omit<TestQuestionPayload, 'type'> & { type: T }
		: T extends 'essay'
		? Omit<TestQuestionPayload, 'type'> & { type: T }
		: never;
export type TestQuestionPayload = Omit<TestQuestion, 'tags'> & {
	tags: Array<number>;
};
export type Tag = {
	id: number;
	name: string;
	theme: HexCode;
} & TimeStamp;

export function getQuestion<T extends TestQuestionType>(
	type: T,
	question: TestQuestion
) {
	if (type === 'mcq') {
		let reQuestion: TypedTestQuestion<'mcq'> =
			question as unknown as TypedTestQuestion<'mcq'>;
		return reQuestion as TypedTestQuestion<'mcq'>;
	} else if (type === 'ordering') {
		let reQuestion: TypedTestOrderingQuestion<'ordering'> =
			question as unknown as TypedTestOrderingQuestion<'ordering'>;
		return reQuestion as TypedTestOrderingQuestion<'ordering'>;
	}
}
