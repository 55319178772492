import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import useInvalidateRouteGroup from '@Hooks/Routes/useInvalidateRouteGroup';
import styled from 'styled-components';
import ImportsTable from '@Pages/Imports/ImportsTable';
import { ImportsRoute } from '@Pages/Imports/Constants/routes';

const ImportsTab: React.FC = () => {
	const { path, url } = useRouteMatch();
	useInvalidateRouteGroup({
		baseURL: '/dashboard/imports',
		mode: 'include',
		routeGroup: ImportsRoute,
	});

	return (
		<StyledContainer>
			<Switch>
				<Route path={url} exact component={ImportsTable} />
			</Switch>
		</StyledContainer>
	);
};
const StyledContainer = styled.div`
	margin-top: 70px;
`;

export default ImportsTab;
