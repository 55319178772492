import {
	CoursePayload,
	SectionPayload,
	TabPayload,
	TabResponse,
} from '@Pages/ClassRooms/Types/ClassroomTabs.types';
import api from '@Services/api';
import { AxiosResponse } from 'axios';
import {
	CRUDBase,
	DeleteSingleEntityPayload,
	GetAllMeta,
	GetSingleEntityPayload,
} from 'src/base/services/services.base';

export class ClassroomCoursesRequester
	implements CRUDBase<CoursePayload, CoursePayload>
{
	baseURL = 'course/';
	getAll(
		payload?: GetAllMeta
	): Promise<AxiosResponse<ApiResponse<CoursePayload[]>>>;
	getAll(payload: number): Promise<AxiosResponse<ApiResponse<CoursePayload[]>>>;
	getAll(payload?: any): Promise<AxiosResponse<ApiResponse<CoursePayload[]>>> {
		throw new Error('Method not implemented.');
	}
	get(id: string): Promise<AxiosResponse<ApiResponse<CoursePayload>>>;
	get(id: number): Promise<AxiosResponse<ApiResponse<CoursePayload>>>;
	get(
		id: string | number,
		payload: GetSingleEntityPayload
	): Promise<AxiosResponse<ApiResponse<CoursePayload>>>;
	get(
		id: any,
		payload?: any
	): Promise<AxiosResponse<ApiResponse<CoursePayload>>> {
		throw new Error('Method not implemented.');
	}
	async create(
		payload: Omit<CoursePayload, 'id'>
	): Promise<AxiosResponse<ApiResponse<CoursePayload>>> {
		return await api.post(`${this.baseURL}create`, payload);
	}
	async update(
		payload: CoursePayload
	): Promise<AxiosResponse<ApiResponse<CoursePayload>>> {
		return await api.put(`${this.baseURL}update`, payload);
	}
	delete(id: number): Promise<AxiosResponse<ApiResponse<any>>>;
	delete(id: string): Promise<AxiosResponse<ApiResponse<any>>>;
	delete(
		id: string | number,
		payload: DeleteSingleEntityPayload
	): Promise<AxiosResponse<ApiResponse<any>>>;
	async delete(
		id: any,
		payload?: any
	): Promise<AxiosResponse<ApiResponse<any>>> {
		return await api.delete(`${this.baseURL}delete`, { data: { id } });
	}

	private static instance: ClassroomCoursesRequester;

	static getInstance() {
		if (!ClassroomCoursesRequester.instance) {
			ClassroomCoursesRequester.instance = new ClassroomCoursesRequester();
		}
		return this.instance;
	}
}
