import { TOKEN } from '@Constants/cookies';
import api from '@Services/api';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { logout } from '@Features/auth/authSlice';
import { useTypedSelector } from '@Features/store';

export const ApiProvider: React.FC = ({ children }) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const { isAuth } = useTypedSelector((state) => state.auth);
	useEffect(() => {
		api.interceptors.response.use(
			(response) => {
				if (response.status !== 200) {
					errorComposer(response)();
					return Promise.reject(response);
				} else {
					return response;
				}
			},
			(error) => {
				errorComposer(error)();
				console.log(error);
				return Promise.reject(error.response);
			}
		);

		const errorComposer = (error: any) => {
			return () => {
				const statusCode = error.response ? error.response.status : null;
				// if (statusCode === 403) {
				//   throw new Error("Test Failed");
				// }
				if (statusCode === 401) {
					dispatch(logout());
					history.push('/');
				}
			};
		};
	}, []);

	return <>{children}</>;
};
