import { Box, Button, Modal, OutlinedInput } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import styled from 'styled-components';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useDispatch } from 'react-redux';
import {
	generateScratchCards,
	getScratchCards,
} from '@Features/scratchCards/scratchCardsSlice';
import {
	fetchBatchesDropdown,
	fetchCoursesDropdown,
} from '@Features/dropdowns/dropdownsSlice';
import { useTypedSelector } from '@Features/store';
import EdNumericInput from '@Components/UI/Inputs/EdNumericInput/EdNumericInput';
import { Spacer } from '@Styled/utilities';
import { scratchCardsGenerate } from '../Slices/ScratchCards.slice';
import useFilters from '@Hooks/useFilters';
import useQueryURL from '@Hooks/useQueryUrl';
import { Switch } from '@mui/material';
import { DevTool } from '@hookform/devtools';

type Props = {
	onConfirmation: any;
	handleModelClose: () => void;
	openModel: boolean;
	classRooms: any;
};

export type CourseFormData = {
	classroom_id: string;
	course_id: number | null;
	quantity: number;
	scheme: string;
	instructor_id: number | null;
	value: number;
};

const SCHEMA = yup.object().shape({
	course_id: yup
		.number()
		.nullable()
		.when('mode', {
			is: (value: string) => value === 'Specific',
			then: (s) => s.required(),
			otherwise: (s) => s.nullable(true),
		}),
	scheme: yup.string().required(),
	mode: yup.string().notRequired(),
	checker: yup.boolean(),
	instructor_id: yup
		.number()
		.nullable(true)
		.when('withInstructor', {
			is: (val: boolean) => val === true,
			then: (schema) => {
				return schema.required();
			},
			otherwise: (schema) => {
				return schema.nullable(true);
			},
		}),
	classroom_id: yup
		.number()
		.nullable(true)
		.when('mode', {
			is: (val: string) => val === 'Instructor',
			then: (schema) => {
				return schema.nullable(true);
			},
			otherwise: (schema) => {
				return schema.required();
			},
		}),
	quantity: yup.number().positive().required(),
	value: yup
		.number()
		.nullable(true)
		.when('mode', {
			is: (val: string) => val === 'Instructor',
			then: (schema) => {
				return schema.required();
			},
			otherwise: (schema) => {
				return schema.nullable(true);
			},
		}),
	// withInstructor: yup.boolean(),
});

const GenerateCodes: React.FC<Props> = ({
	handleModelClose,
	onConfirmation,
	openModel,
	classRooms,
}) => {
	const dispatch = useDispatch();
	const {
		formState: { isValid, errors },
		getValues,
		watch,
		reset,
		control,
		handleSubmit,
		setValue,
	} = useForm<any>({
		resolver: yupResolver(SCHEMA),
		mode: 'onTouched',
		defaultValues: {
			scheme: 'numeric',
			checker: false,
			withInstructor: false,
			classroom_id: null,
			quantity: null,
			instructor_id: null,
			value: null,
		},
	});

	const { dropdownInstructors, dropdownCourses } = useTypedSelector(
		(state) => state.dropdowns
	);
	const [modeGeneration, setModeGeneration] = useState(false);
	const { selectedFilters } = useFilters();
	const { selectedQuery } = useQueryURL();
	const { loaders } = useTypedSelector((state) => state.ScratchCards);

	const [courses, setCourses] = useState([]);

	useEffect(() => {
		if (watch('classroom_id')) {
			if (classRooms && getValues('classroom_id')) {
				dispatch(fetchCoursesDropdown(watch('classroom_id')));
				// classRooms.map((classroom: any) => {
				// 	if (classroom.id === getValues('classroom_id')) {
				// 		setCourses(classroom.courses);
				// 	}
				// });
			}
			return () => {};
		}
	}, [classRooms, getValues, JSON.stringify(watch('classroom_id'))]);

	// useEffect(() => {
	// 	getValues('mode') === 'Specific'
	// 		? setModeGeneration(true)
	// 		: setModeGeneration(false);
	// }, [JSON.stringify(watch('mode'))]);

	const onSubmitForm = (formData: CourseFormData) => {
		if (getValues('mode') === 'Specific') setValue('course_id', null);
		dispatch(
			scratchCardsGenerate({
				classroom_id: formData.classroom_id,
				course_id: formData.course_id,
				quantity: formData.quantity,
				scheme: formData.scheme,
				value: formData.value,
				instructor_id: formData.instructor_id,
			})
		);
		dispatch(fetchBatchesDropdown());

		reset('', {
			keepValues: false,
		});
		handleModelClose();
	};

	const withInstructor = watch('mode') === 'Instructor';
	console.log({ withInstructor });
	// useEffect(() => {
	// 	reset({
	// 		withInstructor,
	// 		scheme: 'numeric',
	// 		checker: false,
	// 	});
	// }, [withInstructor]);
	return (
		<>
			<DevTool control={control} />
			<Modal
				open={openModel}
				onClose={handleModelClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Container>
					<form onSubmit={handleSubmit(onSubmitForm, (e) => console.log(e))}>
						<Box sx={{ marginLeft: '1.5rem', marginTop: '2.25rem' }}>
							<TitleStyled id="modal-modal-title"> Generate Codes </TitleStyled>
						</Box>

						<Box
							sx={{
								marginLeft: '3.5rem',
								marginRight: '4rem',
								marginTop: '1.5rem',

								display: 'flex',
								flexDirection: 'column',
							}}
						>
							{/* <Controller
								control={control}
								name="withInstructor"
								render={({ field }) => (
									<FormControlLabel
										label="Instructor"
										control={
											<Switch
												checked={field.value}
												onChange={(e, c) => field.onChange(c)}
											/>
										}
									/>
								)}
							/> */}
							<Controller
								name="mode"
								control={control}
								defaultValue="Generic"
								render={({ field: { onChange, ...field } }) => (
									<RadioGroupStyled
										aria-labelledby="radio-buttons-group-label"
										{...field}
										onChange={(e, v) => {
											if (v === 'Specific') setModeGeneration(true);
											else if (v === 'Generic') setModeGeneration(false);
											onChange(e);
										}}
									>
										<FormControlLabel
											value="Generic"
											control={<RadioStyled />}
											label="Generic"
										/>
										<FormControlLabel
											value="Specific"
											control={<Radio />}
											label="Specific"
										/>
										<FormControlLabel
											value="Instructor"
											control={<Radio />}
											label="Instructor"
										/>
									</RadioGroupStyled>
								)}
							/>

							{!withInstructor && (
								<>
									<Controller
										name="classroom_id"
										control={control}
										render={({ field }) => (
											<FormControl
												sx={{ marginTop: '0.5rem', marginBottom: '0.5rem' }}
												size="small"
											>
												<InputLabel id="demo-simple-select-label">
													Select Classroom
												</InputLabel>
												<SelectStyled
													{...field}
													labelId="demo-simple-select-label"
													id="demo-simple-select"
													label="Select Classroom"
													error={!!errors.classroom_id}
												>
													{classRooms &&
														classRooms.map((_: any) => {
															return (
																<MenuItem
																	value={_.id}
																>{`${_.title} - ${_.label}`}</MenuItem>
															);
														})}
												</SelectStyled>
											</FormControl>
										)}
									/>
									{modeGeneration && (
										<Controller
											name="course_id"
											control={control}
											render={({ field }) => (
												<FormControl
													fullWidth
													sx={{ marginTop: '0.5rem', marginBottom: '0.5rem' }}
													size="small"
												>
													<InputLabel id="demo-simple-select-label">
														Select Course
													</InputLabel>
													<SelectStyled
														error={!!errors.course_id}
														{...field}
														labelId="demo-simple-select-label"
														id="demo-simple-select"
														label="Select Course"
													>
														{dropdownCourses.length > 0 &&
															dropdownCourses.map((_: any) => {
																return (
																	<MenuItem
																		value={_.id}
																	>{` ${_.name}  `}</MenuItem>
																);
															})}
													</SelectStyled>
												</FormControl>
											)}
										/>
									)}
								</>
							)}

							{withInstructor && (
								<>
									<Controller
										control={control}
										name="instructor_id"
										render={({ field }) => (
											<FormControl
												sx={{ marginTop: '0.5rem', marginBottom: '0.5rem' }}
												size="small"
											>
												<InputLabel id="demo-simple-select-label">
													Select Instructor
												</InputLabel>
												<SelectStyled
													{...field}
													labelId="demo-simple-select-label"
													id="demo-simple-select"
													label="Select Instructor"
													error={!!errors.instructor_id}
												>
													{dropdownInstructors.map((_) => {
														return (
															<MenuItem
																value={_.id}
															>{`${_.first_name} ${_.last_name}`}</MenuItem>
														);
													})}
												</SelectStyled>
											</FormControl>
										)}
									/>
									<Controller
										control={control}
										name="value"
										render={({ field, fieldState }) => (
											<Spacer my="0.5rem">
												<EdNumericInput
													fullWidth
													size="small"
													label="Set Value"
													placeholder="Value"
													error={fieldState.invalid}
													{...field}
													variant="outlined"
												/>
											</Spacer>
										)}
									/>
								</>
							)}

							<Controller
								control={control}
								name="quantity"
								render={({ field, fieldState }) => (
									<Spacer my="0.5rem">
										<EdNumericInput
											fullWidth
											size="small"
											label="Set Quantity"
											placeholder="Quantity"
											error={fieldState.invalid}
											{...field}
											variant="outlined"
										/>
									</Spacer>
								)}
							/>
							<Controller
								control={control}
								name="scheme"
								defaultValue={getValues('scheme')}
								render={({ field }) => (
									<>
										<FormControl
											size="small"
											sx={{ marginTop: '0.5rem', marginBottom: '0.5rem' }}
										>
											<InputLabel id="demo-simple-select-label">
												Alpha Numeric
											</InputLabel>
											<SelectStyled
												labelId="demo-simple-select-label"
												id="demo-simple-select"
												{...field}
												label="Select or search options"
											>
												<MenuItem value="alpha numeric">Alpha Numeric</MenuItem>
												<MenuItem value="numeric">Numeric</MenuItem>
											</SelectStyled>
										</FormControl>
									</>
								)}
							/>
							<Controller
								control={control}
								name="checker"
								render={({ field }) => (
									<CheckboxStyled
										control={<Checkbox />}
										{...field}
										label="All selected options are correct"
									/>
								)}
							/>
						</Box>
						<ButtonsContainer>
							<Button
								variant="text"
								color="inherit"
								sx={{
									fontFamily: 'Roboto',
									fontSize: '14px',
									padding: '6px 16px',
									color: '#6c63ff',
									backgroundColor: '#fff',
									outline: 'none',
								}}
								// disabled={!isValid || !watch('checker')}
								type="submit"
							>
								Submit
							</Button>
							<Button
								variant="text"
								color="warning"
								sx={{
									fontFamily: 'Roboto',
									fontSize: '14px',
									padding: '6px 16px',
									color: '#ff9800',
									outline: 'none',
									backgroundColor: '#fff',
								}}
								onClick={() => {
									handleModelClose();
									reset();
								}}
							>
								Cancel
							</Button>
						</ButtonsContainer>
					</form>
				</Container>
			</Modal>
		</>
	);
};

export default GenerateCodes;

export const Container = styled(Box)`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	min-width: 23.625rem;
	min-height: 21rem;
	border-radius: 4px;
	border: 0;
	box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.38);
	margin: 39px 80px 50px 11.4px;
	padding: 12px 0 0;
	background-color: #ffffff;
	outline: none;
`;

export const TitleStyled = styled.span`
	font-weight: 500;
	font-family: Roboto;
	text-align: left;
	font-size: 1rem;
	color: rgba(0, 0, 0, 0.6);
`;

export const ButtonsContainer = styled(Box)`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	margin-bottom: 0.5rem;
	outline: none;
	margin-top: 1.625rem;
`;

export const RadioGroupStyled = styled(RadioGroup)`
	display: flex;
	flex-direction: row;
	.MuiTypography-root {
		font-family: Roboto;
		font-size: 0.875rem;
		color: rgba(0, 0, 0, 0.87);
	}
	.MuiRadio-root.Mui-checked {
		color: #6c63ff;
	}
`;

export const RadioStyled = styled(Radio)``;

export const SelectStyled = styled(Select)`
	width: 16.2rem;
`;

export const InputStyled = styled(OutlinedInput)`
	width: 16.2rem;
`;

export const CheckboxStyled = styled(FormControlLabel)`
	padding-left: 1.5rem;
	.MuiTypography-root {
		font-size: 0.75rem;
		font-family: Roboto;
		font-weight: normal;
	}
`;
