import {
	FormControl,
	FormControlLabel,
	FormLabel,
	Radio,
	RadioGroup,
} from '@mui/material';
import { FlexLayout, Spacer } from '@Styled/utilities';
import React, { FC } from 'react';
import styled from 'styled-components';
import { TypedTestQuestion } from '../../Types/TestQuestion';
import { QuestionContent } from './QuestionAccordion.styled';

type Props = {
	question: TypedTestQuestion<'mcq'>;
};
const MCQAccordion: FC<Props> = ({ question }) => {
	return (
		<FlexLayout flexDirection="column">
			<QuestionContent>
				<div dangerouslySetInnerHTML={{ __html: question.content }} />
			</QuestionContent>
			<Spacer mb="2rem" />
			<FlexLayout flexDirection="column">
				<FormControl>
					<AnswersLabel>Answers</AnswersLabel>
					<RadioGroup
						aria-labelledby="demo-radio-buttons-group-label"
						defaultValue="female"
						name="radio-buttons-group"
					>
						{question.multipleChoiceOptions.map((opt) => {
							return (
								<MCQAnswerLabel
									key={`${opt.choice}`}
									is_correct={opt.is_correct}
									// value={opt.choice}
									control={<Radio checked={opt.is_correct} />}
									label={opt.choice}
								/>
							);
						})}
					</RadioGroup>
				</FormControl>
			</FlexLayout>
		</FlexLayout>
	);
};

export default MCQAccordion;

const MCQAnswerLabel = styled(FormControlLabel)<{ is_correct: boolean }>`
	/* width: 100%; */
	overflow: hidden;
	background-color: ${(props) => (props.is_correct ? '#e5fbf0' : '')};
	padding: 0 0.5rem;
	border-radius: 4px;
	margin: 0;
	margin-bottom: 0.5rem;
`;

const AnswersLabel = styled.label`
	font-size: 1rem;
	margin-bottom: 0.438rem;
`;
