import DateTimeFormatter from '@Components/DateTimeFormatter/DateTimeFormatter';
import MainTable from '@Components/MainTable';
import { MainTableRow } from '@Components/MainTable/MainTable';
import { onPageChangeRequestMeta } from '@Components/MainTable/MainTable.types';
import NoResults from '@Components/NoResults/NoResults';
import RoundButton from '@Components/UI/Buttons/RoundButton';
import EdIcon from '@Components/UI/Utilities/EdIcon/EdIcon';
import { FlexLayout } from '@eduact/ed-system';
import { useTypedSelector } from '@Features/store';
import { TableCell } from '@mui/material';
import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { studentWebinarsExportData, studentWebinarsGetAll } from '../Slices/StudentWebinars.slice';

type Props = {
	classroom_id: number;
};

const tableHeaders: Array<TableHeader> = [
	{ displayName: 'Course Name', fieldName: 'course_name' },
	{ displayName: 'Webinar Name', fieldName: 'webinar_name' },
	{ displayName: 'Date', fieldName: 'slot_date' },
	{ displayName: 'Duration', fieldName: 'duration' },
	{ displayName: 'Status', fieldName: 'status' },
];
const StudentWebinarsTable: React.VoidFunctionComponent<Props> = ({
	classroom_id,
}) => {
	const dispatch = useDispatch();
	const { currentEntity: Student } = useTypedSelector(
		(state) => state.Students
	);
	const { filters, meta, fetchAll , selectedFilters,dateRange,query,sortBy } = useTypedSelector(
		(state) => state.StudentWebinars
	);
	const auth = useTypedSelector((state) => state.auth);

	const onPageChangeRequest = useCallback(
		({
			activePage,
			filters,
			perPage,
			query,
			sortBy,
			startDate,
			endDate,
		}: onPageChangeRequestMeta) => {
			dispatch(
				studentWebinarsGetAll({
					filters,
					page: activePage,
					perPage: perPage ?? 10,
					query,
					sortBy,
					classroom_id,
					from: startDate,
					to: endDate,
					student_id: Student.id,
				})
			);
		},
		[]
	);

	const getStatusColor = useCallback((status: WebinarStatus) => {
		switch (status) {
			case 'registered':
				return '#2196f3';
			case 'attended':
				return '#00d66b';
			case 'not attended':
				return '#ff3100';
			case 'not started':
				return '#FF8532';
		}
	}, []);

	const ExportTableData = () => {
		if (studentWebinarsExportData)
			dispatch(
				studentWebinarsExportData({
					filters: selectedFilters,
					page: meta?.current_page ?? 1,
					perPage: meta?.per_page ?? 10,
					query: query ?? '',
					sortBy,
					from: dateRange.from,
					to: dateRange.to,
					classroom_id,
					student_id: Student.id,
				})
			);
	};
	return (
		<>
			{(auth.user.type === 'super' || auth.permissions.has('exp-st')) && (
				<FlexLayout justifyContent="flex-end">
					<RoundButton
						onClick={() => ExportTableData()}
						startIcon={<EdIcon>south</EdIcon>}
						variant="contained"
					>
						Export
					</RoundButton>
				</FlexLayout>
			)}
			<MainTable
				onPageChangeRequest={onPageChangeRequest}
				dateFilter={false}
				tableFilters={filters}
				tableHeads={tableHeaders}
				totalPages={meta?.last_page ?? 1}
				RowsperPage={meta?.per_page}
				pageNum={meta?.current_page}
				total={meta?.total}
				renderItems={() => {
					if (fetchAll?.length === 0)
						return (
							<MainTableRow>
								<TableCell colSpan={tableHeaders.length}>
									<NoResults />
								</TableCell>
							</MainTableRow>
						);
					return fetchAll.map((webinar) => {
						return (
							<MainTableRow>
								<TableCell>{webinar.course_name}</TableCell>
								<TableCell>{webinar.webinar_name}</TableCell>
								<TableCell>
									<DateTimeFormatter date={webinar.slot_date} />
								</TableCell>
								<TableCell>{webinar.duration} mins</TableCell>
								<TableCell>
									<StatusLabel color={getStatusColor(webinar.status)}>
										{webinar.status}
									</StatusLabel>
								</TableCell>
							</MainTableRow>
						);
					});
				}}
			/>
		</>
	);
};

export default StudentWebinarsTable;

const StatusLabel = styled.span<{ color: string }>`
	color: ${(props) => props.color};
`;
