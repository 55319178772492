import { useTypedSelector } from '@Features/store';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { testQuestionsGetSingle } from '../../../Slice/TestQuestions/TestQuestionsSlice';
import EditOrderingInfo from './EditOrderingInfo';

const EditOrdering = () => {
	const { id } = useParams<{ id: string }>();
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(testQuestionsGetSingle({ id: Number(id) }));
	}, []);

	const {
		currentEntity,
		loaders: { getSingle },
	} = useTypedSelector((state) => state.TestQuestions);
	const { currentEntity: currentTest } = useTypedSelector(
		(state) => state.Tests
	);

	return (
		<>
			{currentEntity && currentTest && getSingle === 'fulfilled' && (
				<EditOrderingInfo />
			)}
		</>
	);
};

export default EditOrdering;
