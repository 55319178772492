import api from '@Services/api';
import {
	DeleteResponse,
	dropdownClassRoomsResponse,
	dropdownCoursesRespose,
	ScratchCardsResponse,
	DeleteScratchCardByBatchRequest,
	DeleteScratchCardByBatchResponse,
} from './scratchCards.res.types';

//
interface getReqType {
	page: number;
	perPage: number;
	sortBy?: any;
	filters: Array<any>;
	query: string;
	export: boolean;
	from?: Date;
	to?: Date;
}

export const ScratchCardsGetRequest = async (
	page: number,
	perPage: number,
	sortBy: any,
	filters: Array<any>,
	query: string,
	flag: boolean,
	from?: Date,
	to?: Date
) => {
	const data: getReqType = {
		page,
		perPage,
		filters,
		query,
		export: flag,
		from,
		to,
	};
	if (Object.keys(sortBy).length !== 0) data.sortBy = sortBy;

	return await api.post<ScratchCardsResponse>('scratchcards/all', data);
};

export const ScratchCardsGenerateRequest = async (
	classroom_id: string,
	course_id: number | null,
	scheme: string,
	quantity: number
) =>
	await api.post('scratchcards/generate', {
		classroom_id,
		course_id,
		scheme,
		quantity,
	});

export const ScratchCardsDeleteRequest = async (id: number) => {
	return await api.delete<DeleteResponse>('scratchcards/delete', {
		data: { id },
	});
};

export const classroomsDropdownReq = async () =>
	await api.get<dropdownClassRoomsResponse>('dropdown/classrooms');

export const coursesDropdownReq = async (id: number) =>
	await api.get<dropdownCoursesRespose>(`dropdown/courses/${id}`);

export const deleteScratchcardByBatch = async ({
	batch_number,
}: DeleteScratchCardByBatchRequest) =>
	await api.delete<DeleteScratchCardByBatchResponse>(
		'scratchcards/delete/by-batch',
		{ data: { batch_number } }
	);
