import EdButton from '@Components/UI/Buttons/EdButton/EdButton';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
} from '@mui/material';
import React, { FC } from 'react';
import styled from 'styled-components';

type Props = {
	open: boolean;
	handleClose: (close: boolean, confirm?: boolean) => void;
	text: string;
};
const EdConfirmModal: FC<Props> = ({ open, handleClose, text }) => {
	return (
		<Dialog
			open={open}
			onClose={() => handleClose(false)}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			{/* <DialogTitle id="alert-dialog-title">{text}</DialogTitle> */}
			<DialogContent>
				<StyledDialogContentText id="alert-dialog-description">
					{text}
				</StyledDialogContentText>
			</DialogContent>
			<DialogActions>
				<Button
					color="warning"
					onClick={() => {
						handleClose(false);
					}}
				>
					No
				</Button>
				<EdButton
					edcolor="purple"
					onClick={() => handleClose(false, true)}
					autoFocus
				>
					Yes
				</EdButton>
			</DialogActions>
		</Dialog>
	);
};

export default EdConfirmModal;

const StyledDialogContentText = styled(DialogContentText)``;
