import { FlattenAdminForm } from '@Pages/Admin/Schema/AdminsInfo.schema';
import { Admin, AdminPayload } from '@Pages/Admin/Types/admin.types';
import api from '@Services/api';
import { AxiosResponse } from 'axios';
import {
	CRUDBase,
	DeleteSingleEntityPayload,
	GetAllMeta,
	GetSingleEntityPayload,
} from 'src/base/services/services.base';

interface AdminCRUD extends CRUDBase<AdminPayload, Admin> {
	// toggleActive(admin: Admin): Promise<AxiosResponse<ApiResponse<Admin>>>;
}
export class AdminRequester implements AdminCRUD {
	private static instance: AdminRequester;
	static getInstance() {
		if (!AdminRequester.instance) {
			AdminRequester.instance = new AdminRequester();
		}
		return AdminRequester.instance;
	}
	baseURL = '';
	getAll(payload?: GetAllMeta): Promise<AxiosResponse<ApiResponse<Admin[]>>>;
	getAll(payload: number): Promise<AxiosResponse<ApiResponse<Admin[]>>>;
	async getAll(
		payload?: any
	): Promise<import('axios').AxiosResponse<ApiResponse<Admin[]>>> {
		return await api.post(`${this.baseURL}/all`, payload);
	}
	get(id: string): Promise<AxiosResponse<ApiResponse<Admin>>>;
	get(id: number): Promise<AxiosResponse<ApiResponse<Admin>>>;
	get(
		id: string | number,
		payload: GetSingleEntityPayload
	): Promise<AxiosResponse<ApiResponse<Admin>>>;
	async get(
		id: any,
		payload?: any
	): Promise<AxiosResponse<ApiResponse<Admin>>> {
		return await api.post(`${this.baseURL}`, { uuid: id });
	}

	async create(
		payload: AdminPayload
	): Promise<AxiosResponse<ApiResponse<Admin>>> {
		return await api.post(`${this.baseURL}/create`, payload);
	}
	async update(
		payload: AdminPayload
	): Promise<AxiosResponse<ApiResponse<Admin>>> {
		return await api.put(`${this.baseURL}/update`, payload);
	}

	async toggleActive(
		admin: AdminPayload
	): Promise<AxiosResponse<ApiResponse<Admin>>> {
		return await api.put(`${this.baseURL}/update`, {
			uuid: admin.uuid,
			active: !admin.active,
		});
	}
	delete(id: number): Promise<AxiosResponse<ApiResponse<any>>>;
	delete(id: string): Promise<AxiosResponse<ApiResponse<any>>>;
	delete(
		id: string | number,
		payload: DeleteSingleEntityPayload
	): Promise<AxiosResponse<ApiResponse<Admin>>>;
	async delete(
		id: any,
		payload?: any
	): Promise<AxiosResponse<ApiResponse<any>>> {
		return await api.delete(`${this.baseURL}/delete`, {
			data: {
				uuid: id,
			},
		});
	}
}
