import React, { FC } from 'react';
import styled from 'styled-components';
import TestsCounts from './Components/TestsCounts';
import TestsTable from './Components/TestsTable';
import { TestsCount } from './Types/Tests.types';

type Props = {
	count: TestsCount;
	id: number;
};

const StudentTests: FC<Props> = ({ count, id }) => {
	return (
		<>
			<Header>Tests</Header>
			<TestsCounts count={count} />
			<TestsTable id={Number(id)} />
		</>
	);
};

const Header = styled.p`
	font-size: 1.125rem;
	font-weight: bold;
	color: #6c63ff;
	margin-bottom: 1.063rem;
`;

export default StudentTests;
