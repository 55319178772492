import { onPageChangeRequestMeta } from '@Components/MainTable/MainTable.types';
import EdConfirmModal from '@Components/UI/Modals/EdConfirmModal/EdConfirmModal';
import { FlexLayout } from '@eduact/ed-system';
import { useTypedSelector } from '@Features/store';
import { Button } from '@mui/material';
import { studentsGetSingle } from '@Pages/Students/Slice/Students.Slice';
import { useSnackbar } from '@Providers/useSnackbar';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import DeviceCard from './Components/DeviceCard';
import { logoutAllDevices } from './Services/StudentDevices.requester';
import { studentDevicesGetAll } from './Slices/StudentDevices.slice';

const Devices = () => {
	const dispatch = useDispatch();
	const { displaySnackbar } = useSnackbar();
	const { id } = useParams<{ id: string }>();
	const [openDeleteAllModel, setOpenDeleteAllModel] = useState<boolean>(false);
	const { currentEntity } = useTypedSelector((state) => state.Students);
	const { fetchAll, meta, sortBy, filters, query, dateRange } =
		useTypedSelector((state) => state.StudentDevices);

	useEffect(() => {
		dispatch(studentsGetSingle({ id }));
	}, []);

	useEffect(() => {
		if (currentEntity) {
			dispatch(
				studentDevicesGetAll({
					page: meta?.first_page ?? 1,
					perPage: meta?.per_page ?? 10,
					sortBy,
					filters,
					query: query as string,
					from: dateRange.from,
					to: dateRange.to,
					id: currentEntity?.student?.user_id,
				})
			);
		}
	}, [dispatch, currentEntity?.student?.user_id]);

	const handleModelOpenDeleteAll = () => {
		setOpenDeleteAllModel(true);
	};

	const handleModelCloseDeleteAll = async (
		close: boolean,
		confirm?: boolean
	) => {
		try {
			if (confirm) {
				await logoutAllDevices(currentEntity?.student?.user_id).then((e) => {
					displaySnackbar('success', e?.data.message);
				});
				dispatch(
					studentDevicesGetAll({
						page: meta?.first_page ?? 1,
						perPage: meta?.per_page ?? 10,
						sortBy,
						filters,
						query: query as string,
						from: dateRange.from,
						to: dateRange.to,
						id: currentEntity?.student?.user_id,
					})
				);
			}
			setOpenDeleteAllModel(false);
		} catch (e) {
			const msg = e?.data.message || "Couldn't delete All devices";
			displaySnackbar('error', msg);
		}
	};

	return (
		<>
			<EdConfirmModal
				text="Are you sure you want to delete all devices?"
				open={openDeleteAllModel}
				handleClose={handleModelCloseDeleteAll}
			/>
			<Wrapper>
				<FlexLayout
					alignItems="center"
					justifyContent="space-between"
					mb="2rem"
				>
					<Header>Devices</Header>
					<RemoveButton
						variant="outlined"
						color="warning"
						onClick={(e) => {
							e.stopPropagation();
							handleModelOpenDeleteAll();
						}}
					>
						remove all devices
					</RemoveButton>
				</FlexLayout>
				<FlexLayout
					alignItems="stretch"
					justifyContent="flex-start"
					gridGap="2rem"
				>
					{fetchAll.map((device, index) => {
						return <DeviceCard device={device} key={index} />;
					})}
				</FlexLayout>
			</Wrapper>
		</>
	);
};

const Wrapper = styled.div`
	padding: 1.875rem 3.313rem;
`;
const Header = styled.p`
	font-size: 1.25rem;
	font-weight: bold;
	color: #251b33;
`;
const RemoveButton = styled(Button)`
	border-radius: 4px;
	font-weight: 500;
	font-size: 0.938rem;
	outline: #f44336;
`;

export default Devices;
