import EdIcon from '@Components/UI/Utilities/EdIcon/EdIcon';
import React, { FC } from 'react';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';

type DragButtonProps = {
	dragHandlers: DraggableProvidedDragHandleProps;
};
const DragButton: FC<DragButtonProps> = ({ dragHandlers }) => {
	return <EdIcon {...dragHandlers}>height</EdIcon>;
};

export default DragButton;
