//to get the selected filter name not value
export function chipFilterName(tableFilters: any, filter: any): string {
	let ChipName: string = '';
	tableFilters.forEach((filterObj: any) => {
		if (filterObj.value === filter.name && filterObj?.options) {
			ChipName += filterObj.name + ' : ';
			filterObj.options.forEach((opt: any) => {
				if (String(opt.value) === String(filter.value)) {
					ChipName += opt?.name;
				}
			});
		}
	});

	return ChipName;
}
