import React, { useState } from 'react';
import {
	Button,
	ButtonGroup,
	Grow,
	InputAdornment,
	MenuItem,
	MenuList,
	Paper,
	Popper,
} from '@mui/material';
import EdFormControl from '@Components/UI/Inputs/EdFormControl/EdFormControl';
import { DevTool } from '@hookform/devtools';
import { useTypedSelector } from '@Features/store';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import styled from 'styled-components';
import { FlexLayout, Spacer } from '@Styled/utilities';
import { useSnackbar } from '@Providers/useSnackbar';
import { useHistory } from 'react-router-dom';
import VideoLinks from '../../../Components/VideoLinks';
import { VideoUnitRequester } from '../../../Services/Video.requester';
import { VideoType } from '../../../Types/Video.types';
import { VIDEO_SCHEMA } from '../../../Schema/Video.schema';
import { ContentConfig } from '../../../../../Constants/CourseContent.constants';
import { DropButton } from '../../../../Web Content/Pages/AddWebContent/AddWebContent';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';

const embedLink =
	/<(\"[^\"]*\"|'[^']*'|[^'\">])([a-zA-Z]+)(?:[^>]*[^/]*)((<\/)\w+(>))?>$/;
const regex =
	/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)+(\.(mp4|mkv|flv|3gp|avi|mov|webm))?$/;

const AddVideoInfo = () => {
	const { displaySnackbar } = useSnackbar();
	const history = useHistory();

	const { currentCourse } = useTypedSelector((state) => state.classroom);

	const {
		control,
		formState,
		getValues,
		watch,
		reset,
		resetField,
		...formMethods
	} = useForm<VideoType>({
		mode: 'all',
		resolver: yupResolver(VIDEO_SCHEMA),
		defaultValues: {
			unit: {
				type_id: 2,
				course_id: currentCourse?.id,
			},
		},
	});

	const [open, setOpen] = useState(false);
	const anchorRef = React.useRef<HTMLDivElement>(null);

	const handleToggle = () => {
		setOpen((prevOpen) => !prevOpen);
	};

	const handleClose = (event: Event) => {
		if (anchorRef.current?.contains(event.target as HTMLElement)) {
			return;
		}
		setOpen(false);
	};

	const onSave = async (form: VideoType) => {
		if (form.video.link_schema === 'upload' && !form.video.uri.match(regex)) {
			displaySnackbar('error', 'Invalid URL!');
		} else if (
			form.video.link_schema === 'url' &&
			!form.video.uri.match(regex)
		) {
			displaySnackbar('error', 'Invalid URL!');
		} else if (
			form.video.link_schema === 'embed' &&
			!form.video.uri.match(embedLink)
		) {
			displaySnackbar('error', 'Invaild Embed Code!');
		} else {
			try {
				const {
					data: { data },
				} = await VideoUnitRequester.getInstance().create({
					...form,
				});
				displaySnackbar('success', 'Created Video Unit successfully');
				history.goBack();
			} catch (e) {
				const msg = e.response?.data.message || 'Unable to Video Unit';
				displaySnackbar('error', msg);
			}
		}
	};

	const handleReset = () => {
		reset({
			unit: {
				type_id: 2,
				course_id: currentCourse?.id,
				name: '',
			},
			video: {
				duration: '' as any,
				uri: '',
				link_schema: undefined,
			},
		});
	};

	const onSaveAddAnother = async (form: VideoType) => {
		if (form.video.link_schema === 'upload' && !form.video.uri.match(regex)) {
			displaySnackbar('error', 'Invalid URL!');
		} else if (
			form.video.link_schema === 'url' &&
			!form.video.uri.match(regex)
		) {
			displaySnackbar('error', 'Invalid URL!');
		} else if (
			form.video.link_schema === 'embed' &&
			!form.video.uri.match(embedLink)
		) {
			displaySnackbar('error', 'Invaild Embed Code!');
		} else {
			try {
				await VideoUnitRequester.getInstance().create(form);
				displaySnackbar('success', 'Created Video Unit successfully');
				handleReset();
			} catch (e) {
				const msg = e.response?.data.message || 'Unable to Video Unit';
				displaySnackbar('error', msg);
			}
		}
	};

	return (
		<>
			<DevTool control={control} />
			<FormProvider
				formState={formState}
				getValues={getValues}
				control={control}
				watch={watch}
				reset={reset}
				resetField={resetField}
				{...formMethods}
			>
				<form onSubmit={formMethods.handleSubmit(onSave)}>
					<FlexLayout gridGap="2.5rem" flexDirection="column">
						<EdFormControl
							size="small"
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										{ContentConfig['video'].icon}
									</InputAdornment>
								),
							}}
							control={control}
							label="Unit Name"
							name="unit.name"
						/>
						<VideoLinks />
						<Spacer />
						<EdFormControlDuration
							label="Duration"
							control={control as any}
							name="video.duration"
							placeholder="minutes"
						/>
						<EdFormControl
							label="Number of plays"
							control={control}
							name="video.max_plays"
							placeholder="Plays"
						/>
					</FlexLayout>

					<FlexLayout justifyContent="end" gridGap="1rem" marginTop="9rem">
						<Button
							disabled={!formState.isDirty}
							variant="contained"
							color="warning"
							onClick={() => history.goBack()}
						>
							Cancel
						</Button>
						<ButtonGroup
							variant="contained"
							ref={anchorRef}
							aria-label="split button"
						>
							<Button
								color="primary"
								variant="contained"
								disabled={!formState.isValid || !formState.isDirty}
								onClick={() => {
									const data = getValues();
									onSave(data);
								}}
							>
								Save
							</Button>
							<DropButton
								size="small"
								aria-controls={open ? 'split-button-menu' : undefined}
								aria-expanded={open ? 'true' : undefined}
								onClick={handleToggle}
								variant="contained"
								disabled={!formState.isDirty || !formState.isValid}
							>
								<ArrowDropDownIcon />
							</DropButton>
						</ButtonGroup>
						<Popper
							sx={{
								zIndex: 1,
							}}
							open={open}
							anchorEl={anchorRef.current}
							role={undefined}
							transition
							disablePortal
						>
							{({ TransitionProps, placement }) => (
								<Grow
									{...TransitionProps}
									style={{
										transformOrigin:
											placement === 'bottom' ? 'center top' : 'center bottom',
									}}
								>
									<Paper>
										<ClickAwayListener onClickAway={handleClose}>
											<MenuList id="split-button-menu" autoFocusItem>
												<MenuItem
													key={1}
													onClick={() => {
														const data = getValues();
														onSaveAddAnother(data);
													}}
												>
													Save and add another
												</MenuItem>
											</MenuList>
										</ClickAwayListener>
									</Paper>
								</Grow>
							)}
						</Popper>
					</FlexLayout>
				</form>
			</FormProvider>
		</>
	);
};

export const EdFormControlDuration = styled(EdFormControl)`
	width: 7.813rem;
	height: 2rem;
`;

export default AddVideoInfo;
