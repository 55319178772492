import EdFab from '@Components/UI/Utilities/EdFab/EdFab';
import EdIcon from '@Components/UI/Utilities/EdIcon/EdIcon';
import { FlexLayout, Spacer } from '@Styled/utilities';
import React, { useState } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import { TestQuestions } from '../../Constants/Questions.types';

const AddQuestionList = () => {
	const history = useHistory();
	const { url } = useRouteMatch();
	const { pathname } = useLocation();
	return (
		<>
			<QuestionContainer>
				<FlexLayout alignItems="center">
					<StyledEdFab
						disableFocusRipple={true}
						disableTouchRipple={true}
						disableRipple={true}
					>
						<EdIcon fontSize="large">add</EdIcon>
					</StyledEdFab>
					<Spacer mx="0.5rem" />
					<AddQuestionTitle>Add Question</AddQuestionTitle>
				</FlexLayout>
				<FlexLayout marginLeft="5.5rem" flexDirection="column">
					{TestQuestions.map((qs) => {
						const $isSelected = pathname.includes(qs.type);
						return (
							<QuestionType
								$isSelected={$isSelected}
								key={`${qs.type}-add-menu`}
								onClick={() => {
									history.push(`${url}/questions/${qs.type}`);
								}}
							>
								{qs.title}
							</QuestionType>
						);
					})}
				</FlexLayout>
			</QuestionContainer>
		</>
	);
};

export default AddQuestionList;

const QuestionContainer = styled.div`
	padding: 12px 10px;
	.active-qs {
		text-decoration: underline;
		font-weight: bold;
	}
`;

const AddQuestionTitle = styled.div`
	font-weight: 600;
	text-transform: uppercase;
`;

const QuestionType = styled.div<{ $isSelected: boolean }>`
	margin: 0.2rem 0;
	color: ${(props) => (props.$isSelected ? '#000' : '#707070')};
	text-decoration: ${(props) => (props.$isSelected ? 'underline' : '')};
	cursor: pointer;

	&:hover {
		color: #000;
	}
`;

const StyledEdFab = styled(EdFab)`
	background: transparent;
	box-shadow: none;
	color: ${(props) => props.theme.palette.primary.main};
	:hover {
		background: transparent;
		box-shadow: none;
		color: ${(props) => props.theme.palette.primary.main};
	}
`;
