import api from '@Services/api';
import { AxiosResponse } from 'axios';
import {
	CRUDBase,
	DeleteSingleEntityPayload,
	GetAllMeta,
	GetSingleEntityPayload,
} from 'src/base/services/services.base';
import { Assistants } from '../Types/Instructor.types';

interface AssistantsCRUD extends CRUDBase<Assistants, Assistants> {}
export class AssistantsRequester implements AssistantsCRUD {
	get(id: string): Promise<AxiosResponse<ApiResponse<Assistants>>>;
	get(id: number): Promise<AxiosResponse<ApiResponse<Assistants>>>;
	get(
		id: string | number,
		tId?: string | number | undefined,
		payload?: GetSingleEntityPayload | undefined
	): Promise<AxiosResponse<ApiResponse<Assistants>>>;
	get(
		id: unknown,
		tId?: unknown,
		payload?: unknown
	): Promise<AxiosResponse<ApiResponse<Assistants>>> {
		throw new Error('Method not implemented.');
	}
	create(
		payload: Omit<Assistants, 'id'>
	): Promise<AxiosResponse<ApiResponse<Assistants>>> {
		throw new Error('Method not implemented.');
	}
	update(payload: Assistants): Promise<AxiosResponse<ApiResponse<Assistants>>> {
		throw new Error('Method not implemented.');
	}
	delete(id: number): Promise<AxiosResponse<ApiResponse<any>>>;
	delete(id: string): Promise<AxiosResponse<ApiResponse<any>>>;
	delete(
		id: string | number,
		payload?: DeleteSingleEntityPayload | undefined
	): Promise<AxiosResponse<ApiResponse<any>>>;
	delete(
		id: unknown,
		payload?: unknown
	): Promise<AxiosResponse<ApiResponse<any>>> {
		throw new Error('Method not implemented.');
	}
	private static instance: AssistantsRequester;
	static getInstance() {
		if (!AssistantsRequester.instance) {
			AssistantsRequester.instance = new AssistantsRequester();
		}
		return AssistantsRequester.instance;
	}
	baseURL = 'instructor/assistants';
	getAll(
		payload?: GetAllMeta
	): Promise<AxiosResponse<ApiResponse<Assistants[]>>>;
	getAll(payload: number): Promise<AxiosResponse<ApiResponse<Assistants[]>>>;
	async getAll(
		payload?: any
	): Promise<import('axios').AxiosResponse<ApiResponse<Assistants[]>>> {
		return await api.post(`${this.baseURL}`, payload);
	}
}
