import { BaseSliceController } from '@Base/slices/slices.base';
import { AttendancesCountRequester } from '../Services/AttendancesCountRequester';
import { AttendancesCount } from '../Types/Attendaces.types';

export class StudentAttendancesCountSlice extends BaseSliceController<
	AttendancesCount,
	'StudentAttendancesCount',
	AttendancesCount
> {
	private static instance: StudentAttendancesCountSlice;
	static getInstance() {
		if (!StudentAttendancesCountSlice.instance) {
			StudentAttendancesCountSlice.instance =
				new StudentAttendancesCountSlice();
		}
		return StudentAttendancesCountSlice.instance;
	}
	constructor() {
		super('StudentAttendancesCount', AttendancesCountRequester.getInstance());
	}
}

export const { studentAttendancesCountGetSingle } = StudentAttendancesCountSlice.getInstance().getActions();
