import {
	CRUDBase,
	DeleteSingleEntityPayload,
	GetAllMeta,
	GetSingleEntityPayload,
} from '@Base/services/services.base';
import { TestModel } from '@Pages/ClassRooms/Pages/SingleCourse/Pages/CourseForm/Pages/CourseContent/Pages/Test/Types/TestModel.types';
import api from '@Services/api';
import { AxiosResponse } from 'axios';
import { TestModelPayload } from '../../Schema/TestModel.schema';

export class TestModelRequester
	implements CRUDBase<TestModelPayload, TestModel>
{
	get(id: string): Promise<AxiosResponse<ApiResponse<TestModel>>>;
	get(id: number): Promise<AxiosResponse<ApiResponse<TestModel>>>;
	get(
		id: string | number,
		payload?: GetSingleEntityPayload
	): Promise<AxiosResponse<ApiResponse<TestModel>>>;
	async get(
		id: any,
		payload?: any
	): Promise<AxiosResponse<ApiResponse<TestModel>>> {
		return await api.post(`${this.baseURL}get`, { id });
	}
	async create(
		payload: Omit<TestModelPayload, 'id'>
	): Promise<AxiosResponse<ApiResponse<TestModel>>> {
		return await api.post(`${this.baseURL}create`, payload);
	}
	async update(
		payload: TestModelPayload
	): Promise<AxiosResponse<ApiResponse<TestModel>>> {
		return await api.put(`${this.baseURL}update`, payload);
	}

	delete(id: number): Promise<AxiosResponse<ApiResponse<any>>>;
	delete(id: string): Promise<AxiosResponse<ApiResponse<any>>>;
	delete(
		id: string | number,
		payload?: DeleteSingleEntityPayload
	): Promise<AxiosResponse<ApiResponse<any>>>;
	async delete(
		id: number | string,
		payload?: DeleteSingleEntityPayload
	): Promise<AxiosResponse<ApiResponse<any>>> {
		return await api.delete(`${this.baseURL}delete`, { data: { id } });
	}
	getAll(
		payload?: GetAllMeta
	): Promise<AxiosResponse<ApiResponse<TestModel[]>>>;
	getAll(payload: number): Promise<AxiosResponse<ApiResponse<TestModel[]>>>;
	async getAll(
		payload?: GetAllMeta | number
	): Promise<AxiosResponse<ApiResponse<TestModel[]>>> {
		return api.post(`${this.baseURL}fetch/`, { id: payload });
	}

	baseURL = 'test/models/';
	public static instance: TestModelRequester = new TestModelRequester();
}
