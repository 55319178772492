import { rgba } from 'polished';
import React, { useContext, useEffect } from 'react';
import { Route, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import Contents from './Pages/Contents/Contents';
import WebinarRoute from './Pages/Webinar/WebinarRoute';
import AddDocumentFormPage from '@Pages/ClassRooms/Pages/SingleCourse/Pages/CourseForm/Pages/CourseContent/Pages/Documents/AddDocumentFormPage';
import EditDocument from '@Pages/ClassRooms/Pages/SingleCourse/Pages/CourseForm/Pages/CourseContent/Pages/Documents/EditDocument';
import { CourseContext } from '@Pages/ClassRooms/Pages/SingleCourse/Context/CourseContext';
import WebContent from './Pages/Web Content/WebContent';
import VideoUnit from './Pages/VideoUnit/VideoUnit';
import Assignment from './Pages/Assignment/Assignment';

const CourseContent = () => {
	const { path, url } = useRouteMatch();
	const { triggerUpdate } = useContext(CourseContext);

	return (
		<div>
			<Route path={path} exact>
				<Contents />
			</Route>
			<Route path={`${path}/document`}>
				<AddDocumentFormPage />
			</Route>
			<Route path={`${path}/edit/document/:id`}>
				<EditDocument />
			</Route>
			<Route path={`${path}/webinar/:id`}>
				<WebinarRoute />
			</Route>
			<Route path={`${path}/content`}>
				<WebContent />
			</Route>
			<Route path={`${path}/video`}>
				<VideoUnit />
			</Route>
			<Route path={`${path}/assignment`}>
				<Assignment />
			</Route>
		</div>
	);
};

export default CourseContent;

const Panel = styled.div`
	padding: 0.875rem 0;
	/* width: 100%; */
	border-radius: 5px;
`;
const SectionPanel = styled(Panel)`
	padding: 0.75rem 3.75rem;
	background: ${(props) =>
		rgba(props?.theme?.palette?.purple?.main ?? '', 0.16)};
`;
