import DateTimeFormatter from '@Components/DateTimeFormatter/DateTimeFormatter';
import MainTable from '@Components/MainTable';
import { MainTableRow } from '@Components/MainTable/MainTable';
import { onPageChangeRequestMeta } from '@Components/MainTable/MainTable.types';
import NoResults from '@Components/NoResults/NoResults';
import EdActiveStatus from '@Components/UI/Utilities/EdActiveStatus/EdActiveStatus';
import { TableHeader } from '@Features/generics/generics.type';
import { useTypedSelector } from '@Features/store';
import { Button, TableCell, Tooltip } from '@mui/material';
import { assistantsGetAll } from '@Pages/Instructors/Slices/Assistants.Slice';
import React, { FC, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import ActivationModel from '@Pages/Instructors/components/ActiviationModel/ActivationModel';
import { usePageLoader } from '@Providers/LoadingProvider';
import { useSnackbar } from '@Providers/useSnackbar';
import useFilters from '@Hooks/useFilters';
import useQueryURL from '@Hooks/useQueryUrl';
import {
	activateInstructor,
	deactivateInstructor,
} from '@Services/instructors/instructors.req';
import styled from 'styled-components';

type Props = {
	id: number | undefined;
	instructorName: string | undefined;
};

const TABLE_HEADS: Array<TableHeader> = [
	{ displayName: 'FIRST NAME', fieldName: 'first_name', canSort: true },
	{ displayName: 'LAST NAME', fieldName: 'last_name' },
	{ displayName: 'LABEL', fieldName: 'label' },
	{ displayName: 'ACTIVE', fieldName: 'active', canSort: false },
	{ displayName: 'CREATED AT', fieldName: 'created_at' },
];

const AssistantsTable: FC<Props> = ({ id, instructorName }) => {
	const dispatch = useDispatch();
	const { fetchAll, meta, filters } = useTypedSelector(
		(state) => state.Assistants
	);

	const { displaySnackbar } = useSnackbar();
	const { setIsLoading } = usePageLoader();
	const { selectedFilters } = useFilters();
	const { selectedQuery } = useQueryURL();
	const [openModel, setOpenModel] = useState<boolean>(false);
	const [activate, setActivateAssistant] = useState<boolean>(false);
	const [assistantId, setAssistantId] = useState<any>(null);
	const auth = useTypedSelector((state) => state.auth);
	const hasActivePermission =
		auth.user.type === 'super' || auth.permissions.has('act-inst');
	const hasDeActivePermission =
		auth.user.type === 'super' || auth.permissions.has('dact-inst');

	const onPageChangeRequest = useCallback(
		({
			activePage,
			endDate,
			filters,
			query,
			sortBy,
			startDate,
			perPage,
		}: onPageChangeRequestMeta) => {
			dispatch(
				assistantsGetAll({
					page: activePage,
					perPage: perPage ?? 10,
					sortBy,
					filters,
					query,
					from: startDate,
					to: endDate,
					instructor_id: id,
				})
			);
		},
		[dispatch, id]
	);

	const handleModelOpen = (id: number, active?: boolean) => {
		setOpenModel(true);
		setActivateAssistant(active ?? false);
		setAssistantId(id);
	};

	const handleModelClose = () => {
		setOpenModel(false);
		setActivateAssistant(false);
	};

	const onActivateInstructor = useCallback(() => {
		setIsLoading(true);
		if (!activate) {
			activateInstructor(assistantId)
				.then(() => {
					displaySnackbar('success', 'Activated Assistant successfully');
					dispatch(
						assistantsGetAll({
							page: meta?.current_page as number,
							perPage: meta?.per_page as number,
							sortBy: '',
							filters: selectedFilters,
							query: selectedQuery,
							instructor_id: id,
						})
					);
				})
				.catch((e: any) => {
					const msg =
						e.response?.data.message || 'Unable to Activated Assistant ';
					displaySnackbar('error', msg);
				});
		} else {
			deactivateInstructor(assistantId)
				.then(() => {
					displaySnackbar('success', 'Deactivated Assistant successfully');
					dispatch(
						assistantsGetAll({
							page: meta?.current_page as number,
							perPage: meta?.per_page as number,
							sortBy: '',
							filters: selectedFilters,
							query: selectedQuery,
							instructor_id: id,
						})
					);
				})
				.catch((e: any) => {
					const msg =
						e.response?.data.message || 'Unable to Deactivated Instructor ';
					displaySnackbar('error', msg);
				});
		}
		setOpenModel(false);
		setIsLoading(false);
	}, [activate, setIsLoading, dispatch, assistantId, displaySnackbar]);

	return (
		<Container>
			<Name>{instructorName}</Name>
			<ActivationModel
				text="Assistant"
				openModel={openModel}
				handleModelClose={handleModelClose}
				ban={activate}
				onConfirmation={onActivateInstructor}
			/>
			<MainTable
				total={meta?.total ?? 0}
				dateFilter
				pageNum={meta?.current_page}
				tableFilters={filters}
				tableHeads={TABLE_HEADS}
				totalPages={meta?.last_page ?? 0}
				RowsperPage={Number(meta?.per_page)}
				$hasActions
				onPageChangeRequest={onPageChangeRequest}
				renderItems={() => {
					if (fetchAll?.length === 0)
						return (
							<MainTableRow>
								<TableCell colSpan={TABLE_HEADS.length}>
									<NoResults />
								</TableCell>
							</MainTableRow>
						);
					return fetchAll?.map((assistant) => {
						return (
							<MainTableRow key={assistant.id} hover>
								<Tooltip title={assistant.first_name} placement="top">
									<TableCell>{assistant.first_name}</TableCell>
								</Tooltip>
								<TableCell>{assistant.last_name}</TableCell>
								<TableCell>{assistant.instructor.label}</TableCell>
								<TableCell>
									<EdActiveStatus active={assistant.instructor.is_active} />
								</TableCell>
								<TableCell>
									{assistant.created_at ? (
										<DateTimeFormatter date={assistant?.created_at} />
									) : (
										'-'
									)}
								</TableCell>
								<TableCell
									sx={{
										fontFamily: 'Roboto',
										fontSize: '1rem',
										padding: '0 1.063rem 0',
									}}
								>
									<Tooltip
										placement="left"
										title={
											assistant?.instructor?.is_active
												? 'Click to deactivate'
												: 'Click to activate'
										}
									>
										<Button
											id="instructoBanUnBan"
											disabled={
												(!hasActivePermission &&
													!assistant?.instructor?.is_active) ||
												(!hasDeActivePermission &&
													assistant?.instructor.is_active)
											}
											onClick={(e) => {
												e.stopPropagation();
												handleModelOpen(
													assistant?.id,
													assistant?.instructor?.is_active
												);
											}}
										>
											{!assistant?.instructor?.is_active ? (
												<AccountBoxIcon
													sx={{
														color: '#f74343',
														height: '26px',
														width: '26px',
													}}
												/>
											) : (
												<AccountBoxIcon
													sx={{
														color: '#5ac0fc',
														height: '26px',
														width: '26px',
													}}
												/>
											)}
										</Button>
									</Tooltip>
								</TableCell>
							</MainTableRow>
						);
					});
				}}
			/>
		</Container>
	);
};

const Container = styled.div`
	padding-top: 2rem;
	padding-inline-end: 1rem;
	padding-inline-start: 2rem;
`;
const Name = styled.p`
	font-size: 1.25rem;
	font-weight: bold;
	color: #251b33;
	padding-bottom: 3.5rem;
`;
export default AssistantsTable;
