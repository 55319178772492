import { FlexLayout } from '@Styled/utilities';
import styled from 'styled-components';

export const StyledInnerContainer = styled.div`
	display: flex;
	height: 54px;
	padding: 10px 0px 10px 10px;
	align-items: center;
	min-width: 43.313rem;
	border-radius: 10px;
	box-shadow: inset 0 0 2px ${(props) => props.theme.palette.uranianBlue?.main};
`;
export const StyledOuterContainer = styled(FlexLayout)`
	/* margin: 10px 38px 29px 0; */
	margin: 6px 0;
	width: fit-content;
`;

export const StyledLabel = styled.p`
	font-weight: 500;
	margin-right: 15px;
	margin-left: 15px;
	white-space: nowrap;
`;
