import { BaseSliceController } from '@Base/slices/slices.base';
import { TestModel } from '@Pages/ClassRooms/Pages/SingleCourse/Pages/CourseForm/Pages/CourseContent/Pages/Test/Types/TestModel.types';
import { TestModelRequester } from '@Pages/ClassRooms/Pages/SingleCourse/Pages/CourseForm/Pages/CourseContent/Pages/Test/Services/TestModel/TestModelRequester';
import { TestModelPayload } from '../../Schema/TestModel.schema';

export class TestModelSlice extends BaseSliceController<
	TestModel,
	'TestModels',
	TestModelPayload
	// TestModel
> {
	public static instance: TestModelSlice = new TestModelSlice();

	constructor() {
		super('TestModels', TestModelRequester.instance);
	}
}

export const {
	testModelsGetAll,
	testModelsDeleteById,
	testModelsGetSingle,
	testModelsUpdate,
	testModelsToggleActive,
} = TestModelSlice.instance.getActions();
