import { studentsGetSingle } from '@Pages/Students/Slice/Students.Slice';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Switch, useParams, useRouteMatch } from 'react-router-dom';
import { useTypedSelector } from '@Features/store';
import useInvalidateRouteGroup from '@Hooks/Routes/useInvalidateRouteGroup';
import { StudentClassroom } from '@Constants/routes';
import ClassroomTable from './Components/ClassroomTable';

interface Params {
	id: string;
}

const ClassRoomTab = () => {
	const dispatch = useDispatch();
	const { id: studentId } = useParams<Params>();
	const {
		currentEntity,
		loaders: { getSingle },
	} = useTypedSelector((state) => state.Students);

	useEffect(() => {
		dispatch(studentsGetSingle({ id: studentId }));
	}, []);

	const { url, path } = useRouteMatch();
	const { setURL, setCurrentRouteGroup, isCurrent } = useInvalidateRouteGroup({
		routeGroup: StudentClassroom,
		baseURL: `${url}/classroom`,
		routePlacement: 2,
		mode: 'end',
	});

	useEffect(() => {
		if (currentEntity) {
			setURL(url);
			setCurrentRouteGroup?.(StudentClassroom);
		}
	}, [currentEntity, setURL, setCurrentRouteGroup, url]);

	return (
		<>
			<Switch>
				{StudentClassroom && !Array.isArray(StudentClassroom) && (
					<>
						<Route exact path={url}>
							{currentEntity && getSingle === 'fulfilled' && <ClassroomTable />}
						</Route>

						{StudentClassroom.routes.map((_) => {
							return (
								<Route strict path={`${path}/${_.route}`}>
									{_.component}
								</Route>
							);
						})}
					</>
				)}
			</Switch>
		</>
	);
};

export default ClassRoomTab;
