import {
	activateInstructor,
	deactivateInstructor,
	getAllInstructors,
	getInstructor,
	InstructorRequester,
} from '../Services/instructors.req';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
	GetInstructorsResponse,
	GetInstructorResponse,
} from '../Services/instructors.res.types';
import {
	ActiveInstructorPayload,
	DeactiveInstructorPayload,
	FetchAllInstructorsPayload,
	InstructorSliceState,
} from './instructorsSlice.types';
import createThunkBody from '@Utils/createThunkBody';
import {
	InstructorPayload,
	InstructorResponse,
} from '../Types/Instructor.types';
import { GetAllMeta } from 'src/base/services/services.base';

interface FetchInstructorPayload {
	uuid: string;
}

const initialState: InstructorSliceState = {
	instructors: [],
	selectedInstructor: null,
	currentInstructor: null,
	filters: [],
	lastPage: 0,
	total: 0,
	page: 0,
	perPage: 0,
	loaders: {
		fetchAll: null,
		fetchOne: null,
		activeLoader: null,
		deactiveLoader: null,
	},
	errors: {
		activeError: '',
		deactiveError: '',
		fetchAll: '',
		fetchOne: '',
	},
};

// getAll: createAsyncThunk<ApiResponse<Admin[]>, GetAllMeta>(
//   `${this.SLICE_NAME}/all`,
//   async (args: GetAllMeta) => {
//     const { data } = await AdminRequester.getInstance().getAll(args);
//     return data;
//   }
// );

// side effects
export const fetchAllInstructors = createAsyncThunk<
	ApiResponse<InstructorResponse[]>,
	GetAllMeta,
	{}
>('instructor/all', async (payload) => {
	const { data } = await getAllInstructors(payload);
	return data;
});

export const fetchInstructor = createAsyncThunk<
	InstructorResponse,
	FetchInstructorPayload,
	{ rejectValue: string }
>('instructor/fetch', async ({ uuid }, thunkAPI) => {
	return await createThunkBody(
		getInstructor(uuid),
		thunkAPI,
		'Error fetching instructor'
	);
});

export const getSingleInstructor = createAsyncThunk<
	ApiResponse<InstructorResponse>,
	{ uuid: string },
	{ rejectValue: string }
>('classroom/getOne', async ({ uuid }, thunkApi) => {
	const { data } = await InstructorRequester.getInstance().get(uuid, { uuid });
	return data;
});

//active instructor
export const activateSingleInstructor = createAsyncThunk<
	ApiResponse<any>,
	ActiveInstructorPayload,
	{ rejectValue: string }
>('instructor/active', async ({ id }, thunkAPI) => {
	return await createThunkBody<ApiResponse<any>>(
		activateInstructor(id),
		thunkAPI,
		'Error activating instructor'
	);
});
//active instructor
export const deactivateSelectednstructor = createAsyncThunk<
	ApiResponse<any>,
	DeactiveInstructorPayload,
	{ rejectValue: string }
>('instructor/deactive', async ({ id }, thunkAPI) => {
	return await createThunkBody<ApiResponse<any>>(
		deactivateInstructor(id),
		thunkAPI,
		'Error activating instructor'
	);
});

// slice
const instructorSlice = createSlice({
	name: 'instructor',
	initialState,
	reducers: {},
	extraReducers(builder) {
		// fetch all instructors builder
		builder
			.addCase(fetchAllInstructors.pending, (state) => {
				state.loaders.fetchAll = true;
				state.errors.fetchAll = '';
			})

			.addCase(
				fetchAllInstructors.fulfilled,
				(state, { payload: { data, filters, message, meta } }) => {
					state.loaders.fetchAll = false;
					state.instructors = data;
					state.lastPage = meta?.last_page ?? 0;
					state.filters = filters || [];
					state.total = meta?.total ?? 0;
					state.page = meta?.current_page ?? 1;
					state.perPage = meta?.per_page ?? 10;
				}
			)

			.addCase(fetchAllInstructors.rejected, (state, { payload }) => {
				state.loaders.fetchAll = undefined;
				state.errors.fetchAll = (payload as string) || '';
			});

		// fetch selected instructor
		builder
			.addCase(fetchInstructor.pending, (state) => {
				state.loaders.fetchOne = true;
				state.errors.fetchOne = '';
			})
			// .addCase(
			//   fetchInstructor.fulfilled,
			//   (state, { payload: { data, filters, message, meta } }) => {
			//     const _instructor = data[0];
			//     state.loaders.fetchOne = false;
			//     state.selectedInstructor = {
			//       uuid: _instructor.uuid,
			//       id: _instructor.id,
			//       lms_id: _instructor.lms_id,
			//       email: _instructor.email,
			//       username: _instructor.username,
			//       phone_number: _instructor.phone_number,
			//       first_name: _instructor.first_name,
			//       label: _instructor.instructor.label,
			//       last_name: _instructor.last_name,
			//       branch_id: _instructor.instructor.branch_id,
			//       branch_name: _instructor.instructor.branch_name,
			//       gender: _instructor.gender,
			//       profile_photo: _instructor.profile_photo,
			//       bio: _instructor.instructor.bio,
			//       weight: _instructor.instructor.weight,
			//       classrooms: _instructor.instructor.classrooms,
			//       active: _instructor.instructor.is_active,
			//       educationLanguages: _instructor.instructor.educationLanguages,
			//       educationYears: _instructor.instructor.educationYears,
			//       educationTypes: _instructor.instructor.educationTypes,
			//       educationSections: _instructor.instructor.educationSections,
			//     };
			//   }
			// )

			.addCase(fetchInstructor.rejected, (state, { payload }) => {
				state.loaders.fetchOne = undefined;
				state.errors.fetchOne = payload || '';
			});

		// single instructor
		builder
			.addCase(getSingleInstructor.pending, (state) => {
				state.loaders.fetchOne = true;
				state.errors.fetchOne = '';
			})
			.addCase(getSingleInstructor.fulfilled, (state, { payload }) => {
				state.loaders.fetchOne = false;
				state.currentInstructor = payload.data;
				state.loaders.fetchOne = 'fulfilled';
			})
			.addCase(getSingleInstructor.rejected, (state, { payload }) => {
				state.loaders.fetchAll = undefined;
				state.errors.fetchOne = 'could not get instructor, please try again!';
			});

		//active instructor
		builder
			.addCase(activateSingleInstructor.pending, (state) => {
				state.loaders.activeLoader = true;
				state.errors.activeError = '';
			})
			.addCase(activateSingleInstructor.fulfilled, (state, { payload }) => {
				state.loaders.activeLoader = false;
			})
			.addCase(activateSingleInstructor.rejected, (state, { payload }) => {
				state.loaders.activeLoader = undefined;
				state.errors.activeError = payload || 'could not active instructor';
			});

		//deactive instructor
		builder
			.addCase(deactivateSelectednstructor.pending, (state) => {
				state.loaders.deactiveLoader = true;
				state.errors.deactiveError = '';
			})
			.addCase(deactivateSelectednstructor.fulfilled, (state, { payload }) => {
				state.loaders.deactiveLoader = false;
			})
			.addCase(deactivateSelectednstructor.rejected, (state, { payload }) => {
				state.loaders.deactiveLoader = undefined;
				state.errors.deactiveError =
					payload || 'could not deactivate instructor';
			});
	},
});

export default instructorSlice.reducer;
