import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { permissionsGetRequest } from '@Services/permissions/permissions.req';
import { PermissionsResponse } from '@Services/permissions/permissions.res.types';
import { PermissionState, PermissionPayload } from './permissionSlice.types';

export const getPermissions = createAsyncThunk<
	PermissionsResponse,
	PermissionPayload,
	{ rejectValue: string }
>('permissions/getPermissions', async ({ page, perPage }, thunkApi) => {
	try {
		const { data } = await permissionsGetRequest(page, perPage);
		return data;
	} catch (err) {
		const msg = err.response?.data.messege || 'failed';
		return thunkApi.rejectWithValue(msg);
	}
});

const initialState: PermissionState = {
	permissions: [],
	loaders: {
		permissions: null,
	},
	errors: {
		permissions: '',
	},
};

const PermissionSlice = createSlice({
	name: 'permissions',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(getPermissions.pending, (state) => {
				state.loaders.permissions = true;
				state.errors.permissions = '';
			})
			.addCase(getPermissions.fulfilled, (state, { payload }) => {
				state.permissions = payload?.data?.data;
				state.loaders.permissions = false;
			})
			.addCase(getPermissions.rejected, (state, action) => {
				state.errors.permissions = action.payload;
				state.loaders.permissions = undefined;
			});
	},
});

export default PermissionSlice.reducer;
