import {
	CRUDBase,
	DeleteSingleEntityPayload,
	GenerateCardMeta,
	GenerateCodesMeta,
	GetAllMeta,
	GetSingleEntityPayload,
} from '@Base/services/services.base';
import api from '@Services/api';
import { AxiosResponse } from 'axios';
import { StudentWebinarAnalytic } from '../Types/StudentWebinars.types';

export class StudentWebinarsRequester
	implements CRUDBase<StudentWebinarAnalytic>
{
	baseURL = 'classroom/analytics/webinars/';
	public static instance: StudentWebinarsRequester =
		new StudentWebinarsRequester();
	async export(payload: GetAllMeta): Promise<AxiosResponse<string>> {
		return await api.post(`${this.baseURL}`, { ...payload, export: true });
	}
	getAll(
		payload?: GetAllMeta | undefined
	): Promise<AxiosResponse<ApiResponse<StudentWebinarAnalytic[]>>>;
	getAll(
		payload: number
	): Promise<AxiosResponse<ApiResponse<StudentWebinarAnalytic[]>>>;
	async getAll(
		payload?: unknown
	): Promise<
		import('axios').AxiosResponse<ApiResponse<StudentWebinarAnalytic[]>>
	> {
		return await api.post(`${this.baseURL}`, payload);
	}
	get(id: string): Promise<AxiosResponse<ApiResponse<StudentWebinarAnalytic>>>;
	get(id: number): Promise<AxiosResponse<ApiResponse<StudentWebinarAnalytic>>>;
	get(
		id: string | number,
		tId?: string | number | undefined,
		payload?: GetSingleEntityPayload | undefined
	): Promise<AxiosResponse<ApiResponse<StudentWebinarAnalytic>>>;
	get(
		id: unknown,
		tId?: unknown,
		payload?: unknown
	): Promise<
		import('axios').AxiosResponse<ApiResponse<StudentWebinarAnalytic>>
	> {
		throw new Error('Method not implemented.');
	}
	create(
		payload: Omit<StudentWebinarAnalytic, 'id'>
	): Promise<AxiosResponse<ApiResponse<StudentWebinarAnalytic>>> {
		throw new Error('Method not implemented.');
	}
	update(
		payload: StudentWebinarAnalytic
	): Promise<AxiosResponse<ApiResponse<StudentWebinarAnalytic>>> {
		throw new Error('Method not implemented.');
	}
	delete(id: number): Promise<AxiosResponse<ApiResponse<any>>>;
	delete(id: string): Promise<AxiosResponse<ApiResponse<any>>>;
	delete(
		id: string | number,
		payload?: DeleteSingleEntityPayload | undefined
	): Promise<AxiosResponse<ApiResponse<any>>>;
	delete(
		id: unknown,
		payload?: unknown
	): Promise<import('axios').AxiosResponse<ApiResponse<any>>> {
		throw new Error('Method not implemented.');
	}
	generate?(
		payload: GenerateCodesMeta | GenerateCardMeta
	): Promise<AxiosResponse<string>> {
		throw new Error('Method not implemented.');
	}
	toggleActive?(
		payload: StudentWebinarAnalytic
	): Promise<AxiosResponse<ApiResponse<StudentWebinarAnalytic>>> {
		throw new Error('Method not implemented.');
	}
	process?(id: number): Promise<AxiosResponse<ApiResponse<any>>> {
		throw new Error('Method not implemented.');
	}
}
