import addQueryString from '@Utils/addQueryString';
import booleanify from '@Utils/booleanify';
import qs from 'querystring';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Pagination as MuiPagination } from '@mui/material';
import Stack from '@mui/material/Stack';
import PaginationItem from '@mui/material/PaginationItem';
import styled from 'styled-components';
import { onPageChangeRequestMeta } from '@Components/MainTable/MainTable.types';
import moment from 'moment';
interface Props {
	totalPages: number;
	sortBy: any;
	onPageChangeRequest?(args: onPageChangeRequestMeta): void;
	refetch?: boolean;
}

const Pagination: React.FC<Props> = ({
	totalPages,
	onPageChangeRequest,
	sortBy,
	refetch,
}) => {
	const location = useLocation();
	const history = useHistory();
	const queryPage = parseInt(
		qs.parse(location.search.replace('?', '')).page?.toString() ?? ''
	);

	let initialPageNumber;
	if (!queryPage || queryPage <= 0) {
		initialPageNumber = 1;
	} else initialPageNumber = queryPage;

	/* states */
	const [activePage, setActivePage] = useState<number>(initialPageNumber);
	const onPageChange = useCallback(
		(selected: number) => {
			setActivePage(selected);
			const search = addQueryString(location.search, 'page', selected);
			history.push({ search });
		},
		[history, location.search]
	);

	const structParams = useCallback(() => {
		const queries = qs.parse(location.search.replace('?', ''));
		const filters = Object.keys(queries)
			.filter((query) => /FILTER_/.test(query.toString()))
			.map((queryName) => {
				return {
					[queryName.replace('FILTER_', '')]: booleanify(
						queries[queryName]?.toString() ?? ''
					),
				};
			});

		const queryValue = queries['query'];
		const query = !!queryValue ? queryValue.toString() : '';
		// const startDate = queries["from"];
		const startDate = new Date(`${queries['from']}`);
		const perPage = queries['perPage'] ?? 10;
		const endDate = new Date(`${queries['to']}`);

		return { queries, filters, queryValue, query, startDate, perPage, endDate };
	}, [location.search, history, onPageChangeRequest, sortBy]);

	const handlePageChange = useCallback(() => {
		const { endDate, filters, perPage, queries, query, queryValue, startDate } =
			structParams();
		onPageChangeRequest?.({
			activePage: queries.page ? parseInt(queries.page?.toString()) : 1,
			filters,
			sortBy,
			endDate: endDate,
			startDate: startDate,
			perPage: Number(perPage),
			query,
		});
		if (!queryPage || queryPage <= 0) {
			initialPageNumber = 1;
		} else initialPageNumber = queryPage;
		setActivePage(initialPageNumber);
	}, [location.search, history, onPageChangeRequest, sortBy]);
	useEffect(() => {
		handlePageChange();
	}, [location.search, history, onPageChangeRequest, sortBy]);

	useEffect(() => {
		if (refetch) {
			handlePageChange();
		}
	}, [refetch]);
	return (
		<>
			<Stack spacing={2}>
				<StyledPagination
					count={totalPages}
					size="small"
					page={activePage}
					onChange={(e, value) => onPageChange(value)}
					color="primary"
					renderItem={(item) => (
						<PaginationItem
							sx={{
								fontSize: '11px',
							}}
							{...item}
						/>
					)}
				/>
			</Stack>
		</>
	);
};

export default React.memo(Pagination);

const StyledPagination = styled(MuiPagination)`
	.css-1col9cw-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
		background-color: #5ac0fc;
	}
	.MuiButtonBase-root.MuiPaginationItem-root.Mui-selected {
		color: #ffffff;
		size: 11px;
		font-family: Roboto;
	}
	.css-4ih7pr-MuiButtonBase-root-MuiPaginationItem-root {
		font-family: Roboto;
	}
`;
