import React from 'react';
import AddNewsFeedInfo from './Components/AddNewsFeedInfo';
import styled from 'styled-components';

const AddNewsFeed = () => {
	return (
		<NewsFeedContainer>
			<AddNewsFeedInfo />
		</NewsFeedContainer>
	);
};

export const NewsFeedContainer = styled.div`
	padding: 1.375rem;
	border-radius: 10px;
	border: 1px solid #5ac0fc;
	height: 100vh;
`;

export default AddNewsFeed;
