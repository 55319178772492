import { fetchCoursesDropdown } from '@Features/dropdowns/dropdownsSlice';
import { useTypedSelector } from '@Features/store';
import {
	ClassroomCourse,
	CourseWithPrerequisites,
} from '@Services/classrooms/classrooms.res.types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Updater } from 'use-immer';
import {
	MultipleOptionItem,
	MultiSelectOptions,
} from '@Components/MultipleSelect/MultipleSelect';
import { getSingleCourseRequest } from '@Services/classrooms/classrooms.req';
import { useSnackbar } from '@Providers/useSnackbar';
import styled from 'styled-components';
import { Button } from '@mui/material';
import { FlexLayout } from '@Styled/utilities';
import PrerequisitePath from './PrerequisitePath';
import AddIcon from '@mui/icons-material/Add';

type Props = {
	classroom_id: number | undefined;
	course_id: number | null;
	control: any;
	name: string;
	prerequisitesPaths: MultiSelectOptions[];
	setPrerequisitesPaths: Updater<MultiSelectOptions[]>;
	selectedCourse: (ClassroomCourse & CourseWithPrerequisites) | undefined;
	setSelectedCourse: any;
};

const PrerequisitePaths: React.FC<Props> = ({
	classroom_id,
	course_id,
	control,
	name,
	prerequisitesPaths,
	setPrerequisitesPaths,
	selectedCourse,
	setSelectedCourse,
}) => {
	const dispatch = useDispatch();
	const { displaySnackbar } = useSnackbar();

	const { courses } = useTypedSelector((state) => state.classroom);
	const [checked, setChecked] = useState<boolean | undefined>();
	const [clicked, setClicked] = useState<boolean | undefined>();
	const allCourses = useTypedSelector((state) => state.dropdowns.dropdownCourses);

	useEffect(() => {
		dispatch(fetchCoursesDropdown(classroom_id));
	}, []);

	useEffect(() => {
		fetchCourse();
	}, [course_id, allCourses]);

	useEffect(() => {
		setPrerequisitesPaths([]);
		adjustPrerequisites();
	}, [selectedCourse]);

	const adjustPrerequisites = () => {
		selectedCourse?.prerequisites_paths?.forEach((path) => {
			const sanitizedPrerequisites: {
				[key: string]: MultipleOptionItem;
			} = {};
			if (allCourses) {
				allCourses.forEach((course) => {
					if (course.id !== selectedCourse.id) {
						sanitizedPrerequisites[course.id.toString()] = {
							value: course.id,
							label: course.name,
							checked: path.includes(course.id) && true,
						};
					}
				});
			}
			setPrerequisitesPaths((draft) => {
				draft.push(sanitizedPrerequisites);
			});
		});
	};

	const fetchCourse = async () => {
		try {
			if (course_id) {
				const {
					data: { data },
				} = await getSingleCourseRequest(course_id.toString());
				setSelectedCourse(data);
			}
		} catch (e) {
			displaySnackbar(
				'error',
				e.response.data.message || 'error in fetching single course'
			);
		}
	};

	const addPathHandler = () => {
		const prerequisitesMap: MultiSelectOptions = {};

		if (allCourses) {
			allCourses.forEach((course) => {
				if (course.id !== course_id) {
					prerequisitesMap[course.id.toString()] = {
						value: course.id,
						label: course.name,
						checked: false,
					};
				}
			});
			setPrerequisitesPaths((draft) => {
				draft.push(prerequisitesMap);
			});
		}
	};
	const checkData = checked === undefined || checked;

	return (
		<FlexLayout justifyContent="space-between">
			<Label>Prerequisite</Label>
			<FlexLayoutStyle
				justifyContent="space-between"
				alignItems="center"
				flexDirection="column"
				margin="0 auto"
			>
				<FlexLayout flexDirection="column" gridGap="2rem">
					{prerequisitesPaths.map((path, index) => {
						return (
							<PrerequisitePath
								key={index}
								options={path}
								setOptions={setPrerequisitesPaths}
								prerequisitesPaths={prerequisitesPaths}
								pathIndex={index}
								name={name}
								control={control}
								setChecked={setChecked}
								clicked={clicked}
							/>
						);
					})}
				</FlexLayout>
				<AddPathWrapper>
					<AddPathButton
						color="primary"
						variant="text"
						onClick={() => {
							addPathHandler();
							setClicked(true);
						}}
						disabled={!checkData}
					>
						<AddIcon /> Add an alternative rule/ prerequisite
					</AddPathButton>
				</AddPathWrapper>
			</FlexLayoutStyle>
		</FlexLayout>
	);
};

const AddPathWrapper = styled.div`
	margin: 0 auto;
	width: 100%;
	display: flex;
`;

const AddPathButton = styled(Button)`
	margin-top: 1.625rem;
	margin-left: 0;
	font-weight: 500;
	font-size: 1rem;
	&:hover {
		background: transparent;
	}
`;

const FlexLayoutStyle = styled(FlexLayout)``;

const Label = styled.div`
	width: 170px;
`;
export default PrerequisitePaths;
