import { Box, Tab, Tabs, Typography } from '@mui/material';
import React, { useState } from 'react';
import styled from 'styled-components';
import Tests from './Tests/Tests';
import CourseOverview from '../Pages/CourseOverview';
import { useHistory, useRouteMatch } from 'react-router-dom';

interface TabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;
}

function TabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ pl: 0, pt: 1.531 }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

const ReportsTabs = () => {
	const { path } = useRouteMatch();
	const history = useHistory();

	const [value, setValue] = useState<number>(0);

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setValue(newValue);
	};

	const backButton = (val: string) => {
		history.replace({ pathname: `${path}/${val}` });
	};

	return (
		<TabWrraper>
			<Container>
				<Tabs value={value} onChange={handleChange}>
					<TabStyle
						label="Overview"
						onClick={() => {
							backButton('CourseOverview');
						}}
					/>
					<TabStyle
						label="Tests"
						onClick={() => {
							backButton('test');
						}}
					/>
					{/* <TabStyle
            label="Webinar"
            onClick={() => {
              backButton("webinar");
            }}
          /> */}
				</Tabs>
			</Container>
			<TabPanel value={value} index={0}>
				<CourseOverview />
			</TabPanel>
			<TabPanel value={value} index={1}>
				<Tests />
			</TabPanel>
			{/* <TabPanel value={value} index={2}>
        <Webinar />
      </TabPanel> */}
		</TabWrraper>
	);
};

const TabWrraper = styled(Box)`
	width: 100%;
	.MuiTabs-indicator {
		border: solid 1px #5ac0fc;
		height: 1px;
	}
	.MuiTab-root {
		font-size: 1rem;
		font-weight: 600;
		color: #afafaf;
		padding-bottom: 0;
		padding-left: 0;
		padding-right: 0;
		min-width: unset;
		:nth-child(even) {
			margin: 0 5.75rem;
		}
	}
	.Mui-selected {
		color: ${(props) => props.theme.palette.primary.main};
	}
`;
const Container = styled(Box)`
	border-bottom: solid 1px #d3d3d3;
`;
const TabStyle = styled(Tab)`
	text-transform: capitalize;
`;

export default ReportsTabs;
