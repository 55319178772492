import EdTransferList from '@Components/UI/Inputs/EdTransferList/EdTransferList';
import EdIcon from '@Components/UI/Utilities/EdIcon/EdIcon';
import { IconButton, MenuItem } from '@mui/material';
import { grey } from '@mui/material/colors';
import { FlexLayout } from '@Styled/utilities';
import { rgba } from 'polished';
import { FC } from 'react';
import styled from 'styled-components';
import { TestQuestion } from '../../../../Types/TestQuestion';

const OverallScoreTitle = styled.div`
	color: ${rgba('#000', 0.38)};
	font-size: 0.875rem;
	font-weight: 500;
`;
const OverallScoreValue = styled.div`
	font-size: 0.875rem;
	font-weight: 600;
`;
const OverallScoreWrapper = styled(FlexLayout)`
	flex-direction: column;
	align-items: center;
`;
export const OverallScore: FC<{ value: number }> = ({ value }) => {
	return (
		<OverallScoreWrapper>
			<OverallScoreTitle>Score</OverallScoreTitle>
			<OverallScoreValue>{value}</OverallScoreValue>
		</OverallScoreWrapper>
	);
};

export const QuestionContent = styled.div`
	max-width: 6.25rem;
	overflow: hidden;
	flex: 1;
	white-space: nowrap;
	text-overflow: ellipsis;
`;

export const QuestionTypeLabel = styled.label`
	text-transform: uppercase;
`;

export const TransferListItem = styled.div`
	width: 100%;
	font-family: 'Montserrat';
	padding: 0.375rem 1.563rem;
	font-weight: 500;
	font-size: 0.875rem;
	display: grid;
	place-items: center;
	grid-template-columns: repeat(4, 1fr);
	align-items: center;
	div {
		flex: 1;
	}
`;
export const TransferListItemHeader = styled(TransferListItem)`
	color: #adadad;
`;

export const QuestionsToTransfer: FC<{
	item: TestQuestion;
	index: number;
}> = ({ item, index }) => {
	return (
		<EdTransferList.ListItem
			offset={'from'}
			id={item.id}
			item={item}
			index={index}
		>
			<TransferListItem>
				<QuestionContent>Question {item.order}</QuestionContent>
				<QuestionTypeLabel> {item.type}</QuestionTypeLabel>
				<div> {item.weight}</div>
				<IconButton size="small">
					<EdIcon mIconType="Outlined">visibility</EdIcon>
				</IconButton>
			</TransferListItem>
		</EdTransferList.ListItem>
	);
};

export const ChosenQuestions: FC<{
	item: TestQuestion;
	index: number;
}> = ({ item, index }) => {
	return (
		<EdTransferList.ListItem
			index={index}
			item={item}
			id={item.id}
			offset={'to'}
		>
			<TransferListItem>
				<QuestionContent>Question {item.order}</QuestionContent>
				<QuestionTypeLabel> {item.type}</QuestionTypeLabel>
				<div> {item.weight}</div>
			</TransferListItem>
		</EdTransferList.ListItem>
	);
};

export const TransferListHeader = () => {
	return (
		<MenuItem>
			<TransferListItemHeader>
				<QuestionContent>Content</QuestionContent>
				<div>Type</div>
				<div>Points</div>
				<div></div>
			</TransferListItemHeader>
		</MenuItem>
	);
};
