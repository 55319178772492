import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Slice, SliceCaseReducers } from '@reduxjs/toolkit';
import { BaseSliceState } from '@Types/slices';
import { GetAllMeta } from 'src/base/services/services.base';
import { BaseSlice } from 'src/base/slices/interfaces/slices.base.interfaces';
import { BaseSliceController } from '../../../base/slices/slices.base';
import { AdminRequester } from '../Services/AdminRequester/AdminRequester';
import { Admin, AdminPayload } from '../Types/admin.types';
export class AdminSlice extends BaseSliceController<
	AdminPayload,
	'Admins',
	AdminPayload,
	Admin
> {
	private static instance: AdminSlice;
	static getInstance() {
		if (!AdminSlice.instance) {
			AdminSlice.instance = new AdminSlice();
		}
		return AdminSlice.instance;
	}
	constructor() {
		super('Admins', AdminRequester.getInstance());
	}
}

export const {
	adminsDeleteById,
	adminsExportData,
	adminsGenerate,
	adminsGetAll,
	adminsGetSingle,
	adminsToggleActive,
	adminsUpdate,
} = AdminSlice.getInstance().getActions();
