import { BaseSliceController } from '@Base/slices/slices.base';
import store, { TRootState } from '@Features/store';
import { createSlice } from '@reduxjs/toolkit';
import { TestQuestionsRequester } from '../../Services/TestQuestions/TestQuestionsRequester';
import { TestQuestion, TestQuestionPayload } from '../../Types/TestQuestion';

export class TestQuestionsSlice extends BaseSliceController<
	TestQuestion,
	'TestQuestions',
	TestQuestionPayload
> {
	public static instance: TestQuestionsSlice = new TestQuestionsSlice();
	constructor() {
		super('TestQuestions', TestQuestionsRequester.instance);
	}
}

export const {
	testQuestionsGetAll,
	testQuestionsGetSingle,
	testQuestionsExportData,
	testQuestionsAssignFetchAll,
	testQuestionsDeleteById,
} = TestQuestionsSlice.instance.getActions();
