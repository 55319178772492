import MainTable from '@Components/MainTable';
import { MainTableRow } from '@Components/MainTable/MainTable';
import { onPageChangeRequestMeta } from '@Components/MainTable/MainTable.types';
import NoResults from '@Components/NoResults/NoResults';
import { useTypedSelector } from '@Features/store';
import {
	Button,
	TableCell,
	ToggleButton,
	ToggleButtonGroup,
	Tooltip,
} from '@mui/material';
import { studentsGetSingle } from '@Pages/Students/Slice/Students.Slice';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { coursesTabGetAll } from './Slices/CourseTab.Slices';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import styled from 'styled-components';
import { FlexLayout } from '@Styled/utilities';
import StudentsTable from './Components/Students/StudentsTable';
import ProgressTable from './Components/Progress/ProgressTable';

const TABLE_HEADS = [
	{ displayName: 'course name', fieldName: 'course_name' },
	{ displayName: 'classroom name', fieldName: 'classroom_name' },
	{ displayName: 'code', fieldName: 'code' },
	{ displayName: 'price', fieldName: 'price' },
	{ displayName: 'buyable', fieldName: 'buyable' },
	{
		displayName: 'active',
		fieldName: 'active',
	},
	{ displayName: 'enrolled at', fieldName: 'enrolled_at' },
	{
		displayName: 'Purchase method',
		fieldName: 'Purchase_method',
	},
];

const progressTableHeaders = [
	{ displayName: 'course name', fieldName: 'course_name', width: '11%' },
	{ displayName: 'cr.name', fieldName: 'classroom_name', width: '11%' },
	{ displayName: 'code', fieldName: 'code', width: '6%' },
	{ displayName: 'price', fieldName: 'price', width: '6%' },
	{ displayName: 'buyable', fieldName: 'buyable', width: '7%' },
	{
		displayName: 'active',
		fieldName: 'active',
		width: '7%',
	},
	{ displayName: 'expiry date', fieldName: 'expiry_date', width: '10%' },
	{
		displayName: 'is expired',
		fieldName: 'is_expired',
		width: '8%',
	},
	{
		displayName: 'status',
		fieldName: 'status',
		width: '11%',
	},
	{
		displayName: 'progress',
		fieldName: 'progress',
		width: '12%',
	},
	{
		displayName: 'learning time',
		fieldName: 'learning_time',
		width: '12%',
	},
	{
		displayName: '',
		fieldName: '',
		width: '20%',
	},
];

const CoursesTab = () => {
	const dispatch = useDispatch();
	const { id } = useParams<{ id: string }>();
	const [checked, setChecked] = useState<boolean>(false);
	const { currentEntity } = useTypedSelector((state) => state.Students);
	const { fetchAll, meta, filters } = useTypedSelector(
		(state) => state.CoursesTab
	);
	const auth = useTypedSelector((state) => state.auth);

	useEffect(() => {
		dispatch(studentsGetSingle({ id }));
	}, []);

	const onPageChangeRequest = useCallback(
		({
			activePage,
			endDate,
			filters,
			query,
			sortBy,
			startDate,
			perPage,
		}: onPageChangeRequestMeta) => {
			dispatch(
				coursesTabGetAll({
					page: activePage,
					perPage: perPage ?? 10,
					sortBy,
					filters,
					query,
					from: startDate,
					to: endDate,
					id: currentEntity?.id as any,
				})
			);
		},
		[dispatch, currentEntity?.id]
	);

	return (
		<>
			<Title>Courses</Title>
			{(auth.user.type === 'super' || auth.permissions.has('v-std-mng-cr')) && (
				<>
					<FlexLayout
						justifyContent="start"
						alignItems="center"
						width="100%"
						marginBottom="1.625rem"
					>
						<ButtonGroup value={checked} exclusive aria-label="text alignment">
							<UserButton
								value={false}
								aria-label="Users"
								onClick={() => setChecked(false)}
							>
								Enrollment
							</UserButton>
							<UserButton
								value={true}
								aria-label="Progress"
								onClick={() => setChecked(true)}
							>
								Progress
							</UserButton>
						</ButtonGroup>
					</FlexLayout>

					<MainTable
						total={meta?.total ?? 0}
						dateFilter
						pageNum={meta?.current_page}
						tableFilters={filters}
						tableHeads={checked ? progressTableHeaders : TABLE_HEADS}
						totalPages={meta?.last_page ?? 0}
						RowsperPage={Number(meta?.per_page)}
						$hasActions
						onPageChangeRequest={onPageChangeRequest}
						renderItems={() => {
							if (fetchAll?.length === 0)
								return (
									<MainTableRow>
										<TableCell colSpan={TABLE_HEADS.length}>
											<NoResults />
										</TableCell>
									</MainTableRow>
								);
							return fetchAll?.map((course) => {
								return (
									<MainTableRow key={course.id} hover>
										<Tooltip title={course.name} placement="top">
											<TableCell>{course.name}</TableCell>
										</Tooltip>
										<Tooltip title={course.classroom.label} placement="top">
											<TableCell>{course.classroom.label}</TableCell>
										</Tooltip>
										<TableCell>{course.code}</TableCell>
										<TableCell>{course.price}</TableCell>
										<TableCell>
											{!course.buyable ? <CloseIcon /> : <CheckIcon />}
										</TableCell>
										<TableCell>
											{!course.active ? <CloseIcon /> : <CheckIcon />}
										</TableCell>
										{checked ? (
											<ProgressTable course={course} />
										) : (
											<StudentsTable course={course} />
										)}
									</MainTableRow>
								);
							});
						}}
					/>
				</>
			)}
		</>
	);
};

const UserButton = styled(ToggleButton)`
	background: #6e7577;
	color: #fff;
	font-size: 0.875rem;
	font-weight: normal;
	height: 2.7rem;

	&:hover {
		background: #6e7577;
		color: #fff;
	}

	&.Mui-selected,
	&.Mui-selected:hover {
		background: #5ac0fc;
		height: 3rem;
		color: #fff;
	}
`;
const ButtonGroup = styled(ToggleButtonGroup)`
	align-items: center;
`;
const Title = styled.div`
	color: #251b33;
	font-size: 20px;
	font-weight: bold;
	margin-bottom: 35px;
`;

const SyncButton = styled(Button)`
	color: #6c63ff;
	border: 1px solid #6c63ff;
`;

export default CoursesTab;
