import { MenuItem } from '@mui/material';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { CourseCompletion } from '../Constants/UnitOrderCourseCompletion.constants';
import { RolesPathsTypes } from '../types/RolesPaths.types';
import { EdSelect } from './UnitOrderSelect';

const CourseCompletionSelect = () => {
	const { control, resetField } = useFormContext<RolesPathsTypes>();
	return (
		<Controller
			control={control}
			name="progress_criteria"
			render={({ field: { onChange, ...fieldProps } }) => {
				return (
					<EdSelect
						native={false}
						{...fieldProps}
						value={fieldProps.value ?? ''}
						onChange={(e: any) => {
							if (e.target.value) {
								const { value } = e.target;
								onChange(value);
								resetField('progress_percentage', { keepError: false });
								resetField('units', { keepError: false });
							}
						}}
					>
						{CourseCompletion?.map((_) => {
							return (
								<MenuItem key={_.text} value={`${_.id}`}>
									{_.text}
								</MenuItem>
							);
						})}
					</EdSelect>
				);
			}}
		/>
	);
};

export default CourseCompletionSelect;
