import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	getNotificationAnalyticsRequest,
	getNotificationsClassroomsRequest,
	getNotificationsTestsRequest,
} from '@Services/notifications/notifications.req';
import {
	NotificationsStateType,
	OptionsPartialType,
	TestType,
	NotificationAnalysisResponse,
	NotificationAnalysisPayload,
} from '@Features/notifications/notificationsSlice.types';
import createThunkBody from '@Utils/createThunkBody';

const initialState: NotificationsStateType = {
	tests: [],
	classrooms: [],
	notificationAnalytics: [],
	loaders: {
		notificationANalysis: null,
	},
	errors: {
		notificationANalysis: '',
		tests: {
			message: '',
		},
	},
};

export const fetchNotificationsClassrooms = createAsyncThunk(
	'notifications/fetch-classrooms',
	async () => {
		const response = await getNotificationsClassroomsRequest();
		return response.data.data;
	}
);

export const fetchNotifiableTests = createAsyncThunk(
	'notifications/fetch-tests',
	async () => {
		const response = await getNotificationsTestsRequest();
		return response.data.data.map((notification) => {
			return {
				id: notification.unit.id,
				testName: notification.unit.name,
				courseName: notification.unit.course.name,
				classroomName: notification.unit.course.classroom.title,
				active: notification.active,
				lms_id: notification.unit.lms_id,
				options: {
					whatsapp: notification.whatsapp,
					sms: notification.sms,
					email: notification.email,
				},
			};
		});
	}
);

export const fetchNotificationAnalytics = createAsyncThunk<
	NotificationAnalysisResponse,
	NotificationAnalysisPayload,
	{ rejectValue: string }
>('notifications/analysis', async ({ id }, thunkApi) => {
	return await createThunkBody<NotificationAnalysisResponse>(
		getNotificationAnalyticsRequest(id),
		thunkApi,
		'Error fetching notification analysis methods'
	);
});
const NotificationsSlice = createSlice({
	name: 'notifications',
	initialState,
	reducers: {
		addTest: (state, { payload }: PayloadAction<TestType>) => {
			state.tests.push(payload);
		},
		updateOptions: (
			state,
			{
				payload: { testIndex, options },
			}: PayloadAction<{
				testIndex: number;
				options: OptionsPartialType;
			}>
		) => {
			const currentOptions = state.tests[testIndex].options;
			state.tests[testIndex].options = { ...currentOptions, ...options };
		},
		updateActive: (
			state,
			{
				payload: { testIndex, active },
			}: PayloadAction<{
				testIndex: number;
				active: any;
			}>
		) => {
			const checked = active;

			state.tests[testIndex].active = checked.active;
		},

		removeTest: (state, { payload }: PayloadAction<number>) => {
			state.tests = state.tests.filter((test) => test.id !== payload);
		},
	},
	extraReducers: (builder) => {
		builder.addCase(
			fetchNotificationsClassrooms.fulfilled,
			(state, { payload }) => {
				state.classrooms = payload;
			}
		);

		builder
			.addCase(fetchNotifiableTests.fulfilled, (state, { payload }) => {
				state.tests = payload;
				state.errors.tests.message = '';
			})
			.addCase(fetchNotifiableTests.rejected, (state, action) => {
				state.errors.tests.message =
					action.error.message ?? 'error in loading notification table';
			});

		/* getting notification analysis  */
		builder
			.addCase(fetchNotificationAnalytics.pending, (state) => {
				state.loaders.notificationANalysis = true;
				state.errors.notificationANalysis = '';
			})

			.addCase(
				fetchNotificationAnalytics.fulfilled,
				(state, { payload: { data } }) => {
					state.loaders.notificationANalysis = false;
					state.notificationAnalytics = data;
				}
			)
			.addCase(fetchNotificationAnalytics.rejected, (state, { payload }) => {
				state.loaders.notificationANalysis = undefined;

				state.errors.notificationANalysis =
					payload || 'could not get notification analysis, please try again!';
			});
	},
});

export default NotificationsSlice.reducer;

export const { addTest, updateOptions, removeTest, updateActive } =
	NotificationsSlice.actions;
