import EdFormControl from '@Components/UI/Inputs/EdFormControl/EdFormControl';
import { LinearProgress } from '@mui/material';
import React, { useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { uploadVideo } from '@Services/uploads/uploads.req';
import styled from 'styled-components';
import { FlexLayout } from '@Styled/utilities';
import { useSnackbar } from '@Providers/useSnackbar';
import { VideoType } from '../Types/Video.types';

type EdUploadFileProps = {
	onFileUpload?: (blob: Blob | File | null) => void | Promise<void>;
	inputFileRef: React.RefObject<HTMLInputElement>;
};

const SUPPORTED_FORMATS = [
	'video/x-matroska',
	'video/flv',
	'video/3gpp',
	'video/mp4',
	'video/quicktime',
	'video/avi',
	'video/webm',
	'',
];

const UploadVideo: React.FC<EdUploadFileProps> = ({
	onFileUpload,
	inputFileRef,
}) => {
	const { displaySnackbar } = useSnackbar();

	const { control } = useFormContext<VideoType>();

	const {
		field: { onChange, ...inputProps },
	} = useController({
		name: 'video.uri',
		control,
	});

	const [uploadPercentage, setUploadPercentage] = useState<number>(0);
	const [showProgress, setShowProgress] = useState<boolean>(false);

	const uploadFile = async (e: React.ChangeEvent<HTMLInputElement> | null) => {
		setShowProgress(true);
		if (!e?.target.files) return;
		if (!SUPPORTED_FORMATS.includes(e?.target.files[0].type)) {
			return displaySnackbar(
				'error',
				`video Invalid file extension ${e?.target.files[0].type}. Only mp4, webm, flv, mkv, 3gp, avi, mov are allowed`
			);
		}
		try {
			const options = {
				onDownloadProgress: (progressEvent: any) => {
					const { currentTarget } = progressEvent;
					if (currentTarget.statusText === 'Ok') {
						setUploadPercentage(100);
						setShowProgress(false);
					}
				},
				onUploadProgress: (progressEvent: any) => {
					const { loaded, total } = progressEvent;
					let percent = Math.floor((loaded * 100) / total);
					if (percent < 100) {
						setUploadPercentage(percent);
					}
				},
			};

			const {
				data: {
					data: { uri },
				},
			} = await uploadVideo(
				{
					video: e?.target?.files[0],
				},
				options
			);
			displaySnackbar('success', 'Video Uploaded successfully');
			onChange(uri);
			if (uri) {
				setUploadPercentage(100);
			}
		} catch (e) {
			const msg = e.response?.data.message || 'Unable to Upload Video ';
			displaySnackbar('error', msg);
		}
	};

	return (
		<>
			<FlexLayout alignItems="center" justifyContent="start" gridGap="1.563rem">
				<EdFormControlStyle
					control={control as any}
					disabled
					readOnly={true}
					placeholder="File URL"
					labelProps={{
						display: 'none',
					}}
					onChange={onChange}
					{...inputProps}
				/>
				<input
					onChange={uploadFile}
					type="file"
					accept="video/*, mkv, flv, 3gp, mp4"
					ref={inputFileRef}
					hidden
				/>
				{showProgress && uploadPercentage !== 100 && (
					<LabelWrapper>
						<Label>
							<span>{uploadPercentage}%</span>
						</Label>
						<LinearProgressStyle
							variant="determinate"
							value={uploadPercentage}
						/>
					</LabelWrapper>
				)}
			</FlexLayout>
		</>
	);
};

const LinearProgressStyle = styled(LinearProgress)`
	width: 8.25rem;
	height: 1.063rem;
	border-radius: 10px;
	background: #afafaf;
`;

const LabelWrapper = styled.div`
	position: relative;
`;

const Label = styled.div`
	position: absolute;
	top: 1px;
	left: 6px;
	z-index: 1;
	text-align: left;
	display: flex;
	align-items: center;
	margin-top: 0.05rem;
	span {
		width: 100%;
		font-size: 0.75rem;
		color: #fff;
		font-weight: 500;
	}
`;

const EdFormControlStyle = styled(EdFormControl)`
	width: 18.688rem;
	height: 2.5rem;
	border-radius: 4px;
	.MuiOutlinedInput-root {
		height: 2.5rem;
	}
`;

export default UploadVideo;
