import DateTimeFormatter from '@Components/DateTimeFormatter/DateTimeFormatter';
import { Button, TableCell, Tooltip } from '@mui/material';
import React, { FC, useCallback, useState } from 'react';
import styled from 'styled-components';
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';
import AddBoxSharpIcon from '@mui/icons-material/AddBoxSharp';
import { Course } from '@Services/students/students.res.types';
import EnrollementModel from '@Pages/ClassRooms/Pages/SingleCourse/Components/EnrollmentModel/EnrollmentModel';
import UnEnrollementModel from '@Pages/ClassRooms/Pages/SingleCourse/Components/EnrollmentModel/UnEnroolmentModel';
import { usePageLoader } from '@Providers/LoadingProvider';
import { useSnackbar } from '@Providers/useSnackbar';
import useFilters from '@Hooks/useFilters';
import useQueryURL from '@Hooks/useQueryUrl';
import {
	enrollUserRequest,
	UnEnrollUserRequest,
} from '@Services/classrooms/classrooms.req';
import { coursesTabGetAll } from '../../Slices/CourseTab.Slices';
import { useTypedSelector } from '@Features/store';
import { useDispatch } from 'react-redux';

type Props = {
	course: Course;
};

const StudentsTable: FC<Props> = ({ course }) => {
	const dispatch = useDispatch();
	const { currentEntity } = useTypedSelector((state) => state.Students);
	const [selectedCourse, setSelectedCourse] = useState<Course | null>(null);
	const [openEnrolled, setOpenEnrolled] = useState(false);
	const [openUnEnrolled, setOpenUnEnrolled] = useState(false);
	const [deduct, setDeduct] = useState<boolean | undefined>(undefined);
	const [refund, setRefund] = useState<boolean | undefined>(undefined);
	const { setIsLoading } = usePageLoader();
	const { displaySnackbar } = useSnackbar();
	const { selectedFilters } = useFilters();
	const { selectedQuery } = useQueryURL();
	const auth = useTypedSelector((state) => state.auth);

	const handleOpenUnEnrolledModel = (course: Course) => {
		setOpenUnEnrolled(true);
		setSelectedCourse(course);
	};

	const handleCloseUnEnrolledModel = () => {
		setOpenUnEnrolled(false);
	};

	const handleOpen = (course: Course) => {
		setOpenEnrolled(true);
		setSelectedCourse(course);
	};
	const handleClose = () => {
		setOpenEnrolled(false);
	};

	const _onEnroll = useCallback(() => {
		if (selectedCourse) {
			setIsLoading(true);
			enrollUserRequest(
				currentEntity?.email,
				selectedCourse?.pivot.pivot_course_id as number,
				deduct
			)
				.then(() => {
					setIsLoading(false);
					displaySnackbar('success', 'enrolled successfully');
					dispatch(
						coursesTabGetAll({
							page: 1,
							perPage: 10,
							sortBy: '',
							filters: selectedFilters,
							query: selectedQuery,
							id: currentEntity?.id as any,
						})
					);
				})
				.catch((e) => {
					setIsLoading(false);
					const msg = e.response?.data.message || 'Unable to enroll user';
					displaySnackbar('error', msg);
				});
		}
		setOpenEnrolled(false);
		setIsLoading(true);
	}, [
		selectedCourse,
		currentEntity,
		deduct,
		displaySnackbar,
		dispatch,
		setIsLoading,
	]);
	const _onUNEnroll = useCallback(() => {
		if (selectedCourse) {
			setIsLoading(true);
			UnEnrollUserRequest(
				currentEntity?.email || '',
				selectedCourse?.pivot.pivot_course_id as number,
				refund
			)
				.then(() => {
					setIsLoading(false);
					displaySnackbar('success', 'unenrolled successfully');
					dispatch(
						coursesTabGetAll({
							page: 1,
							perPage: 10,
							sortBy: '',
							filters: selectedFilters,
							query: selectedQuery,
							id: currentEntity?.id as any,
						})
					);
				})
				.catch((e) => {
					setIsLoading(false);
					const msg = e.response?.data.message || 'Unable to unenrolled user';
					displaySnackbar('error', msg);
				});
		}
		setOpenUnEnrolled(false);
		setIsLoading(true);
	}, [
		selectedCourse,
		currentEntity,
		displaySnackbar,
		refund,
		dispatch,
		setIsLoading,
	]);

	return (
		<>
			<EnrollementModel
				modelText="student"
				setDeduct={setDeduct}
				handleClose={handleClose}
				openEnrolled={openEnrolled}
				onEnroll={_onEnroll}
			/>

			<UnEnrollementModel
				modelText="student"
				handleCloseUnEnrolledModel={handleCloseUnEnrolledModel}
				onUNEnroll={_onUNEnroll}
				setRefund={setRefund}
				openUnEnrolled={openUnEnrolled}
			/>
			<Tooltip
				title={<DateTimeFormatter date={course.pivot.pivot_created_at} />}
				placement="top"
			>
				<TableCell>
					<DateTimeFormatter date={course.pivot.pivot_created_at} />
				</TableCell>
			</Tooltip>
			<TableCell>{course.pivot.pivot_purchase_method}</TableCell>
			<TableCell>
				{(auth.user.type === 'super' || auth.permissions.has('u-std-mng-cr')) &&
				course.pivot.pivot_purchase_method ? (
					<Tooltip placement="left" title="UnEnroll User">
						<Button
							id="unenrollUser"
							onClick={() => handleOpenUnEnrolledModel(course)}
						>
							<EnrollIcon />
						</Button>
					</Tooltip>
				) : (
					<Tooltip placement="left" title="Enroll User">
						<Button id="enrollUser" onClick={() => handleOpen(course)}>
							<AddEnrollIcon />
						</Button>
					</Tooltip>
				)}
			</TableCell>
		</>
	);
};

const EnrollIcon = styled(IndeterminateCheckBoxOutlinedIcon)`
	color: #5ac0fc;
	height: 27px;
	width: 26px;
`;
const AddEnrollIcon = styled(AddBoxSharpIcon)`
	color: #5ac0fc;
	height: 26px;
	width: 26px;
`;

export default StudentsTable;
