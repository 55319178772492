import { DASHBOARD_BASE_ROUTE } from '@Constants/routes';
import { Breadcrumbs, Link } from '@mui/material';
import React, { useContext, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { BreadcrumbContext } from '../Layout';

const HeaderBreadcrumbs = () => {
	const { pathname } = useLocation();
	const history = useHistory();
	const { extraLink } = useContext(BreadcrumbContext);

	return (
		<Breadcrumbs maxItems={4}>
			<StyledLink onClick={() => history.push(`/dashboard`)} color="inherit">
				Home
			</StyledLink>
			{pathname
				.split('/')
				.filter((_) => `/${_}` !== DASHBOARD_BASE_ROUTE)
				.filter((_) => _ !== '')
				.slice(0, 1)
				.map((_, index) => {
					return (
						<StyledLink
							key={`${index}-${_}-bc`}
							onClick={() => {
								history.push(`${DASHBOARD_BASE_ROUTE}/${_}`);
							}}
							color="inherit"
						>
							{_.replace('-', ' ')}
						</StyledLink>
					);
				})}
			{extraLink.map((_, index) => {
				return (
					<StyledLink
						key={`${index}-${_.routeHash}`}
						onClick={() => {
							if (!pathname.endsWith(_.route)) history.push(`${_.route}`);
						}}
						color="inherit"
					>
						{_.title}
					</StyledLink>
				);
			})}
		</Breadcrumbs>
	);
};

export default HeaderBreadcrumbs;

const StyledLink = styled(Link)`
	text-transform: capitalize;
	cursor: pointer;
`;
