import EdIcon from '../EdIcon/EdIcon';
import React, { FC } from 'react';
import {
	AnimatedIconContainerProps,
	AnimatedIconProps,
} from './EdAnimatedIcon.types';
import styled, { css, CSSObject } from 'styled-components';
import { Animations } from './EdAnimatedIcon.constants';
const EdAnimatedIcon: FC<AnimatedIconProps> = ({
	animation,
	children,
	...props
}) => {
	const getAnimations = () => {
		let animationsCSS = ``;
		if (Array.isArray(animation)) {
			animation.forEach((animation) => {
				Object.entries(animation).forEach((_) => {
					animationsCSS += `${_[0]} : ${_[1]};`;
				});
			});
		}
		const cssObject = css(animation[0] as CSSObject);
		return cssObject;
	};
	return (
		<>
			{Array.isArray(animation) && (
				<AnimatedIconContainer animationsCSS={getAnimations()}>
					<EdIcon {...props}>{children}</EdIcon>
				</AnimatedIconContainer>
			)}
			{!Array.isArray(animation) && (
				<AnimatedIconContainer animation={Animations[animation]}>
					<span className="extra-layer">
						<EdIcon {...props}>{children}</EdIcon>
					</span>
					<EdIcon {...props}>{children}</EdIcon>
				</AnimatedIconContainer>
			)}
		</>
	);
};

export default EdAnimatedIcon;

// Styles

const AnimatedIconContainer = styled.div<AnimatedIconContainerProps>`
	display: grid;
	position: relative;
	overflow: hidden;
	animation: ${({ animation }) => animation} 150ms 2 linear;
	${(props) =>
		css`
			${props.animationsCSS}
		`}

	.extra-layer {
		position: absolute;
		top: 0;
		left: 0;
		overflow: hidden;
		transform: translateX(-100%);
		transition: transform 275ms ease;
	}
`;
