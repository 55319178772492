import { FlexLayout } from '@Styled/utilities';
import { rgba } from 'polished';
import React, { FC } from 'react';
import styled from 'styled-components';
import { AttendancesCount } from '../Types/Attendaces.types';

type Props = {
	count: AttendancesCount;
};

type Time = {
	[key: string]: number;
};

const AttendanceCount: FC<Props> = ({ count }) => {
	const findTime = (num: number) => {
		if (num < 1) {
			return '0';
		}
		const qualifier = (num: number) => (num > 1 ? '' : '');
		const numToStr = (num: number, unit: string) =>
			num > 0 ? `${num}${unit} ${qualifier(num)}` : '';
		const oneMinute = 60;
		const oneHour = oneMinute * 60;
		const oneDay = oneHour * 24;
		const oneYear = oneDay * 365;
		const times: Time = {
			year: ~~(num / oneYear),
			day: ~~((num % oneYear) / oneDay),
			hour: ~~((num % oneDay) / oneHour),
			minute: ~~((num % oneHour) / oneMinute),
		};
		let str = '';
		for (let [key, value] of Object.entries(times)) {
			str += numToStr(times[key], key[0]);
		}
		const arr = str.trim().split(' ');
		const res: Array<any> = [];
		arr.forEach((x, i) => {
			res.push(x);
		});
		return res.slice(0, 3).join(' ');
	};
	return (
		<Container>
			<AttendedContainer
				alignItems="center"
				justifyContent="space-between"
				width="100%"
			>
				<Text color="#5ac0fc">Attended</Text>
				<Score>{count?.attended_courses}</Score>
			</AttendedContainer>
			<NotAttendedContainer
				alignItems="center"
				justifyContent="space-between"
				width="100%"
			>
				<Text color="#ff3100">Not Attended</Text>
				<Score>{count?.not_attended_courses}</Score>
			</NotAttendedContainer>
			<CompletedContainer
				alignItems="center"
				justifyContent="space-between"
				width="100%"
			>
				<Text color="#00d66b">Completed</Text>
				<Score>{count?.completed_courses}</Score>
			</CompletedContainer>
			<LearningTimeContainer
				alignItems="center"
				justifyContent="center"
				width="100%"
				flexDirection="column"
				gridGap="0.5rem"
			>
				<Text color="#6C63FF">Learning Time</Text>
				<Score>{findTime(Number(count?.time))}</Score>
			</LearningTimeContainer>
		</Container>
	);
};

const Container = styled(FlexLayout)`
	place-items: center;
	align-items: center;
	justify-content: space-between;
	gap: 1rem;
	padding: 0 1rem;
	margin-bottom: 3rem;
`;

const BaseContainer = styled(FlexLayout)`
	padding: 0 1.25rem;
	border-radius: 5px;
	height: 5.063rem;
`;
const AttendedContainer = styled(BaseContainer)`
	background: ${rgba('#5AC0FC', 0.1)};
	width: 15.313rem;
`;
const NotAttendedContainer = styled(BaseContainer)`
	background: ${rgba('#ff3100', 0.1)};
	width: 13.688rem;
`;
const CompletedContainer = styled(BaseContainer)`
	background: ${rgba('#00d66b', 0.1)};
	width: 13.688rem;
`;
const LearningTimeContainer = styled(BaseContainer)`
	background: ${rgba('#6C63FF', 0.1)};
	width: 13.688rem;
`;

const Text = styled.p<{ color: string }>`
	font-size: 1.25rem;
	font-weight: bold;
	color: ${({ color }) => color};
`;
const Score = styled.p`
	font-size: 1.75rem;
	font-weight: 600;
	color: #251b33;
`;

export default AttendanceCount;
