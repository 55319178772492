import { useTypedSelector } from '@Features/store';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { videoUnitGetSingle } from '../../Slices/Video.slice';
import EditVideoInfo from './Components/EditVideoInfo';

const EditVideo = () => {
	const { id } = useParams<{ id: string }>();
	const {
		currentEntity,
		loaders: { getSingle },
	} = useTypedSelector((state) => state.VideoUnit);

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(
			videoUnitGetSingle({
				id: Number(id),
			})
		);
	}, []);

	return <>{currentEntity && getSingle === 'fulfilled' && <EditVideoInfo />}</>;
};

export default EditVideo;
