import api from '@Services/api';
import { TimelineLog } from '@Services/students/students.res.types';
import { AxiosResponse } from 'axios';
import {
	CRUDBase,
	DeleteSingleEntityPayload,
	GetAllMeta,
	GetSingleEntityPayload,
} from 'src/base/services/services.base';

interface TimelineTabCRUD extends CRUDBase<TimelineLog, TimelineLog> {}
export class TimelineTabRequester implements TimelineTabCRUD {
	private static instance: TimelineTabRequester;
	static getInstance() {
		if (!TimelineTabRequester.instance) {
			TimelineTabRequester.instance = new TimelineTabRequester();
		}
		return TimelineTabRequester.instance;
	}
	baseURL = 'user/profile';
	getAll(
		payload?: GetAllMeta
	): Promise<AxiosResponse<ApiResponse<TimelineLog[]>>>;
	getAll(payload: number): Promise<AxiosResponse<ApiResponse<TimelineLog[]>>>;
	async getAll(
		payload?: any
	): Promise<import('axios').AxiosResponse<ApiResponse<TimelineLog[]>>> {
		return await api.post(`${this.baseURL}/${payload.id}/timeline`, payload);
	}
	get(id: string): Promise<AxiosResponse<ApiResponse<TimelineLog>>>;
	get(id: number): Promise<AxiosResponse<ApiResponse<TimelineLog>>>;
	get(
		id: string | number,
		payload: GetSingleEntityPayload
	): Promise<AxiosResponse<ApiResponse<TimelineLog>>>;
	async get(
		id: any,
		payload?: any
	): Promise<AxiosResponse<ApiResponse<TimelineLog>>> {
		return await api.post(this.baseURL, { uuid: id });
	}

	async create(
		payload: TimelineLog
	): Promise<AxiosResponse<ApiResponse<TimelineLog>>> {
		return await api.post(`${this.baseURL}/create`, payload);
	}
	async update(
		payload: TimelineLog
	): Promise<AxiosResponse<ApiResponse<TimelineLog>>> {
		return await api.put(`${this.baseURL}/update`, payload);
	}
	delete(id: number): Promise<AxiosResponse<ApiResponse<any>>>;
	delete(id: string): Promise<AxiosResponse<ApiResponse<any>>>;
	delete(
		id: string | number,
		payload: DeleteSingleEntityPayload
	): Promise<AxiosResponse<ApiResponse<TimelineLog>>>;
	async delete(
		id: any,
		payload?: any
	): Promise<AxiosResponse<ApiResponse<any>>> {
		return await api.delete(`${this.baseURL}/delete`, {
			data: {
				uuid: id,
			},
		});
	}
}
