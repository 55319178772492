import { useEffect, useState } from 'react';

interface Props {
	format?: 'dd/MM/yyyy' | 'dd-MM-YYYY';
	time?: '12-HOUR' | '24-HOUR';
	split?: boolean;
	date?: Date;
}

const DateTimeFormatter: React.FC<Props> = ({
	format = 'dd/MM/yyyy',
	time = '12-HOUR',
	split = false,
	date,
}) => {
	const [formattedDate, setDate] = useState('');
	useEffect(() => {
		if (date) {
			const _ = new Date(date).toLocaleString(navigator.language, {
				hour12: time === '12-HOUR',
				hour: '2-digit',
				minute: '2-digit',
				day: '2-digit',
				month: '2-digit',
				year: '2-digit',
			});
			setDate(_);
		}
	}, [date, time]);
	return <span>{formattedDate.toLocaleUpperCase()}</span>;
};

export default DateTimeFormatter;
