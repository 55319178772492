import { BaseSliceController } from '@Base/slices/slices.base';
import { Courses, Course } from '@Services/students/students.res.types';
import { CourseTabRequester } from '../Services/CourseTab.Requester';

export class CoursesTabSlice extends BaseSliceController<
	Course,
	'CoursesTab',
	Course
> {
	private static instance: CoursesTabSlice;
	static getInstance() {
		if (!CoursesTabSlice.instance) {
			CoursesTabSlice.instance = new CoursesTabSlice();
		}
		return CoursesTabSlice.instance;
	}
	constructor() {
		super('CoursesTab', CourseTabRequester.getInstance());
	}
}

export const { coursesTabGetAll } = CoursesTabSlice.getInstance().getActions();
