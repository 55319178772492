import React from 'react';
import {
	FormControl,
	FormControlProps,
	Select,
	TextField,
	TextFieldProps,
} from '@mui/material';
import {
	Controller,
	useController,
	Control,
	FieldValues,
	Path,
	UseControllerProps,
} from 'react-hook-form';
import styled from 'styled-components';
type Props<T = any> = Omit<FormControlProps, 'name' | 'control'> &
	Omit<TextFieldProps, 'name' | 'control'> & {
		// control: any;
		// name: string;
	} & UseControllerProps;
export type SelectControllerProps = Props;
const SelectController = ({
	name,
	control,
	children,
	...props
}: React.PropsWithChildren<Props>) => {
	return (
		<StyledFormControl>
			<Controller
				name={name}
				control={control}
				render={({ field: { ...inputProps } }) => {
					return (
						<TextField {...inputProps} {...props} select>
							{children}
						</TextField>
					);
				}}
			/>
		</StyledFormControl>
	);
};

export default SelectController;

const StyledFormControl = styled(FormControl)`
	width: 100%;
`;
