import { rgba } from 'polished';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import AppSettingsApi from './Services/AppSettings.requester';
import SettingsForm from './SettingsForm';
import { AppSetting as SettingsType } from './Types/AppSetting.types';

const AppSetting = () => {
	const [settings, setSettings] = useState<SettingsType>();
	const [loading, setLoading] = useState<boolean>(false);

	const getSettings = async () => {
		const {
			data: { data },
		} = await AppSettingsApi.getAppSettings();
		setSettings(data);
	};

	useEffect(() => {
		getSettings();
		setLoading(true);
	}, []);

	return (
		<Container>
			{settings && loading && <SettingsForm data={settings} />}
		</Container>
	);
};

const Container = styled.div`
	min-height: 100vh;
	padding: 0 2rem;
	border: 1px solid ${rgba("#5AC0FC", 0.25)}
`;

export default AppSetting;
