import { Button, ButtonProps } from '@mui/material';
import { ExtraPropsBase, getVariant } from '@Styled/theme';
import React from 'react';
import styled from 'styled-components';
import { variant } from 'styled-system';

type Props = {} & ButtonProps & ExtraPropsBase;
const RoundButton: React.FC<Props> = ({ children, ...props }) => {
	return <StyledButton {...props}>{children}</StyledButton>;
};

export default RoundButton;
const StyledButton = styled(Button)<ExtraPropsBase>`
	border-radius: 23px;
	font-size: 0.875rem;
	font-weight: 500;
	font-family: 'Montserrat';

	${({ variant }) =>
		getVariant({
			prop: 'edcolor',
			variantPath: `buttons.${variant ? variant : 'text'}`,
		})}
`;
