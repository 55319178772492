import React, {
	createContext,
	PropsWithChildren,
	ReactNode,
	useContext,
} from 'react';

export type DataContextType<T> = {
	inSequence: Array<T> | undefined;
	outSequence: Array<T> | undefined;
	setInSequence: React.Dispatch<T[] | undefined>;
	setOutSequence: React.Dispatch<T[] | undefined>;
	current?: number;
	setCurrent?: (value: number) => void;
};

type PropsChildren<P> = DataContextType<P> & { children?: ReactNode };

type IContextType<T> = DataContextType<T> | null;
const DataContext = createContext<IContextType<any>>(null);

export const DataProvider = <T,>({
	children,
	inSequence,
	outSequence,
	setInSequence,
	setOutSequence,

	current,
	setCurrent,
}: PropsChildren<T>) => {
	return (
		<>
			<DataContext.Provider
				value={{
					current,
					inSequence,
					outSequence,
					setInSequence,
					setOutSequence,
					setCurrent,
				}}
			>
				{children}
			</DataContext.Provider>
		</>
	);
};

export const useSequenceProvider = <T,>() =>
	useContext(DataContext as React.Context<IContextType<T>>);

export const useDataProvider = <T,>() => {
	const data = useContext(DataContext as React.Context<IContextType<T>>);
	if (!data) {
		throw new Error('No DataProvider found !!!');
	}
	return { ...data };
};
