import { NamedExtraReducersReturn } from '@Types/slices';
import { BaseSliceController } from '../../../base/slices/slices.base';
import { RechargeCodesRequester } from '../Services/RechargeCodes.req';
import { RechargeCode } from '../Types/RechargeCodes.types';

export class RechargeCodesSlice extends BaseSliceController<
	RechargeCode,
	'RechargeCodes'
> {
	private static instance: RechargeCodesSlice;
	static getInstance() {
		if (!RechargeCodesSlice.instance) {
			RechargeCodesSlice.instance = new RechargeCodesSlice();
		}
		return RechargeCodesSlice.instance;
	}

	constructor() {
		super('RechargeCodes', RechargeCodesRequester.getInstance());
	}
}

export const {
	rechargeCodesDeleteById,
	rechargeCodesExportData,
	rechargeCodesGenerate,
	rechargeCodesGetAll,
	rechargeCodesToggleActive,
} = RechargeCodesSlice.getInstance().getActions();
