import React, { useEffect } from 'react';
import { Route, Switch, useParams, useRouteMatch } from 'react-router-dom';
import Webinars from './Webinar';
import AddSlot from './Pages/AddSlot/AddSlot';
import EditSlot from './Pages/EditSlot/EditSlot';
import { webinarsGetSingle } from './Slices/Webinar.slice';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from '@Features/store';
import RegisteredTable from './Pages/RegisteredUsers/RegisteredTable';
const WebinarRoute = () => {
	const { id } = useParams<{ id: string; label: string }>();

	const { url } = useRouteMatch();
	const dispatch = useDispatch();
	const {
		currentEntity,
		loaders: { getSingle },
	} = useTypedSelector((state) => state.Webinars);
	useEffect(() => {
		if (id) {
			dispatch(webinarsGetSingle({ id: Number(id) }));
		}
	}, []);

	return (
		<>
			<Switch>
				<Route exact path={`${url}`}>
					{currentEntity && getSingle === 'fulfilled' && <Webinars />}
				</Route>
				<Route path={`${url}/add/slot`}>
					<AddSlot />
				</Route>
				<Route path={`${url}/edit/slot`}>
					<EditSlot />
				</Route>
				<Route path={`${url}/registeredUsers/:id`}>
					<RegisteredTable />
				</Route>
			</Switch>
		</>
	);
};

export default WebinarRoute;
