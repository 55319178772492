import React, { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

type QueryEditProps<T> = {
	[key in keyof T]?: string | undefined | null;
};

type QueryDeleteProps<T> = Array<keyof T> | keyof T;
export function useQueryString<T>() {
	const { search, pathname } = useLocation();
	const { push } = useHistory();

	const params: T = useMemo<T>(() => {
		const query = new URLSearchParams(search);
		const _paramsArr: Array<Array<string>> = Array.from(query);
		const _obj = {} as any;
		let values = {};
		_paramsArr.forEach((param: Array<string>) => {
			if (param) {
				_obj[param[0]] = param[1];
			}
		});
		return _obj as T;
	}, [search]);
	const edit = (props: QueryEditProps<T>) => {
		return new Promise<void>(function (resolve, reject) {
			const _params = new URLSearchParams(search);
			Object.keys(props).forEach((key) => {
				if (props[key as keyof T]) {
					_params.set(key, props[key as keyof T] ?? '');
					// if (_params.has(key)) {
					//   _params.set(key, props[key] ?? "");
					// } else {
					// }
				}
			});
			push({
				pathname: pathname,
				search: `?${_params.toString()}`,
			});
			resolve();
		});
	};

	const remove = (props: QueryDeleteProps<T> | QueryDeleteProps<T>[]) => {
		const _params = new URLSearchParams(search);
		if (Array.isArray(props)) {
			props.forEach((prop) => {
				if (_params.has(prop.toString())) _params.delete(prop.toString());
			});
		} else {
			if (_params.has(props.toString())) _params.delete(props.toString());
		}
		push({
			pathname: pathname,
			search: `?${_params.toString()}`,
		});
	};

	const getAll = () => {
		const params = new URLSearchParams(search);
		let returnObj: any = {};
		params.forEach((value, key) => {
			returnObj[key] = value;
		});
		return returnObj as T;
	};
	return {
		params,
		edit,
		remove,
		getAll,
	};
}

export type QueryStringBase = {
	page: number;
	perPage: number;
};
