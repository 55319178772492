import api from '@Services/api';
import { AxiosResponse } from 'axios';
import {
	CRUDBase,
	DeleteSingleEntityPayload,
	GenerateCardMeta,
	GenerateCodesMeta,
	GetAllMeta,
	GetSingleEntityPayload,
} from 'src/base/services/services.base';
import { TestsCount } from '../Types/Tests.types';

interface StudentTestsCountCRUD extends CRUDBase<TestsCount, TestsCount> {}
export class StudentTestsCountRequester implements StudentTestsCountCRUD {
	delete(id: number): Promise<AxiosResponse<ApiResponse<any>>>;
	delete(id: string): Promise<AxiosResponse<ApiResponse<any>>>;
	delete(
		id: string | number,
		payload?: DeleteSingleEntityPayload | undefined
	): Promise<AxiosResponse<ApiResponse<any>>>;
	delete(
		id: unknown,
		payload?: unknown
	): Promise<AxiosResponse<ApiResponse<any>>> {
		throw new Error('Method not implemented.');
	}
	export?(payload: GetAllMeta): Promise<AxiosResponse<string>> {
		throw new Error('Method not implemented.');
	}
	getAll(
		payload?: GetAllMeta | undefined
	): Promise<AxiosResponse<ApiResponse<TestsCount[]>>>;
	getAll(payload: number): Promise<AxiosResponse<ApiResponse<TestsCount[]>>>;
	getAll(payload?: unknown): Promise<AxiosResponse<ApiResponse<TestsCount[]>>> {
		throw new Error('Method not implemented.');
	}
	generate?(
		payload: GenerateCodesMeta | GenerateCardMeta
	): Promise<AxiosResponse<string>> {
		throw new Error('Method not implemented.');
	}
	toggleActive?(
		payload: TestsCount
	): Promise<AxiosResponse<ApiResponse<TestsCount>>> {
		throw new Error('Method not implemented.');
	}
	process?(id: number): Promise<AxiosResponse<ApiResponse<any>>> {
		throw new Error('Method not implemented.');
	}
	create(
		payload: Omit<TestsCount, 'id'>
	): Promise<AxiosResponse<ApiResponse<TestsCount>>> {
		throw new Error('Method not implemented.');
	}
	update(payload: TestsCount): Promise<AxiosResponse<ApiResponse<TestsCount>>> {
		throw new Error('Method not implemented.');
	}
	private static instance: StudentTestsCountRequester;
	static getInstance() {
		if (!StudentTestsCountRequester.instance) {
			StudentTestsCountRequester.instance = new StudentTestsCountRequester();
		}
		return StudentTestsCountRequester.instance;
	}
	baseURL = 'classroom/analytics/tests/';
	get(id: string): Promise<AxiosResponse<ApiResponse<TestsCount>>>;
	get(id: number): Promise<AxiosResponse<ApiResponse<TestsCount>>>;
	get(
		id: string | number,
		payload: GetSingleEntityPayload
	): Promise<AxiosResponse<ApiResponse<TestsCount>>>;
	async get(
		id: any,
		tId?: any,
		payload?: any
	): Promise<AxiosResponse<ApiResponse<TestsCount>>> {
		return await api.post(`${this.baseURL}count`, {
			classroom_id: id,
			student_id: tId,
		});
	}
}
