import { lighten, transparentize } from 'polished';
import styled from 'styled-components';

export const FabButtonLabel = styled.label`
	color: #adadad;
	font-size: 0.875rem;
`;

export const PageWrapper = styled.div`
	padding: 0 6.25rem;
`;

type DroppableProps = {
	isDraggingOver?: boolean;
};
export const TabDroppable = styled.div<DroppableProps>`
	padding: ${(props) => (props.isDraggingOver ? '1rem' : '')};
	border-radius: 5px;
	border: ${(props) =>
		!props.isDraggingOver ? '' : `1px dashed ${transparentize(0.9, '#000')}`};

	background-color: ${(props) =>
		props.isDraggingOver
			? transparentize(0.95, props.theme.palette.purple?.main ?? '')
			: ''};
`;
export const SectionDroppable = styled.div<DroppableProps>`
	min-height: ${(props) => (props.isDraggingOver ? '10vh' : '')};
	height: fit-content;
	margin: 0.5rem 0;
	padding: ${(props) => (props.isDraggingOver ? '1rem' : '')};
	border-radius: 5px;
	border: ${(props) =>
		!props.isDraggingOver ? '' : `1px dashed ${transparentize(0.9, '#000')}`};
	background-color: ${(props) =>
		props.isDraggingOver
			? transparentize(0.95, props.theme.palette.primary.main ?? '')
			: ''};
`;

export const CourseDroppable = styled.div<DroppableProps>`
	/* min-height: 10vh; */
	min-height: ${(props) => (props.isDraggingOver ? '10vh' : '')};

	/* height: fit-content; */
	margin: 0.5rem 0;
	padding: ${(props) => (props.isDraggingOver ? '0.5rem' : '')};
	border-radius: 5px;
	border: ${(props) =>
		!props.isDraggingOver ? '' : `1px dashed ${transparentize(0.9, '#000')}`};
	background-color: ${(props) =>
		props.isDraggingOver
			? transparentize(0.95, props.theme.palette.orange?.main ?? '')
			: ''};
`;

export const TabDraggable = styled.div`
	/* border: 2px solid green; */
	/* padding: 1rem; */
`;

export const AccordionTitle = styled.label`
	font-size: 0.875rem;
	min-width: 7.125rem;
	margin: 0 3.75rem 0 1rem;
`;
