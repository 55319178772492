import {
	getAllClassroomEnrollments,
	getAllClassroomsAnalyticsDatatable,
	getAllClassroomsGraph,
	getCourseGraph,
} from '@Features/classrooms/classroomSlice';
import { useTypedSelector } from '@Features/store';
import {
	Icon,
	Paper,
	PaperProps,
	Button,
	ButtonGroup,
	IconButton,
	TextField,
	TableRow,
	TableCell,
	Box,
	SvgIcon,
	MenuItem,
	Checkbox,
	TextFieldProps,
} from '@mui/material';
import {
	deepPurple,
	green,
	grey,
	lightGreen,
	orange,
	red,
	yellow,
} from '@mui/material/colors';
import {
	AllClassroomsGraphPayload,
	EnrollmentType,
	GraphPayload,
	GraphSlice,
	GraphSliceType,
} from '@Services/classrooms/classrooms.res.types';
import { rgba } from 'polished';
import React, {
	Component,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import MultipleLinesChart from '@Components/UI/Charts/MultipleLinesChart/MultipleLinesChart';
import { object, mixed, array, date, number } from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import MUISelectController, {
	OptionKeyPair,
} from '@Components/UI/Inputs/MUISelectController/MUISelectController';
import {
	FlexItem,
	FlexLayout,
	LongTextWrapper,
	Spacer,
} from '@Styled/utilities';
import MonthRangePicker from '@Components/UI/Pickers/MonthRangePicker/MonthRangePicker';
import YearRangePicker from '@Components/UI/Pickers/YearRangePicker/YearRangePicker';
// import { StaticDateRangePicker } from '@mui/x-date-pickers-pro';
import '@mui/lab';
import DateRangePickerPopup, {
	DateRangeOption,
} from '@Components/UI/Pickers/DateRangePickerPopup/DateRangePickerPopup';
import MainTable from '@Components/MainTable';
import { TableHeader } from '@Features/generics/generics.type';
import NoResults from '@Components/NoResults/NoResults';
import { WrappedTableCell } from '@Components/UI/Tables/Tables.styled';
import RoundButton from '@Components/UI/Buttons/RoundButton';
import { exportAllClassroomsAnalytics } from '@Features/classrooms/exportSlice';
import {
	AnalyticsCardsContainer,
	enrollmentIcons,
	EnrollmentsCard,
	EnrollmentTypeCard,
	FormActionsWrapper,
	FormControlsWrapper,
	StyledIconButton,
	TypeInfo,
	TypeName,
	TypeValue,
	FormControlLabel,
} from '@Components/UI/Analytics/Analytics.styled';
import { AnalyticsTotal } from '@Components/UI/Analytics/Assets/Icons/Icons';
import AddClassroomButton from '../../Components/AddClassroomButton';
import { useHistory, useLocation } from 'react-router-dom';
import EdFormControl from '@Components/UI/Inputs/EdFormControl/EdFormControl';
import SelectChip from '@Components/UI/Inputs/SelectChip/SelectChip';
import { AnalyticsSlices } from '@Pages/ClassRooms/Constants/Classroom.constants';
import { onPageChangeRequestMeta } from '@Components/MainTable/MainTable.types';
import ClassroomsUnitsAnalytics from '../ClassroomsUnitsAnalytics/ClassroomsUnitsAnalytics';
const SCHEMA = object().shape({
	slice: mixed<GraphSliceType>().required(),
	classroom_ids: array().of(number()),
	dates: array(),
});

export type FormType = {
	slice: GraphSliceType;
	classroom_ids: number[];
	dates: any;
	test?: any;
};

const TABLE_HEADS: TableHeader[] = [
	{
		displayName: 'Classroom',
		fieldName: 'classroom_name',
		canSort: false,
		width: '20%',
	},
	{ displayName: 'Wallet', fieldName: 'wallet', canSort: false },
	{ displayName: 'ScratchCards', fieldName: 'scratchcards', canSort: false },
	{ displayName: 'Free', fieldName: 'free', canSort: false },
	{ displayName: 'Admin', fieldName: 'admin', canSort: false },
	{ displayName: 'Instructor', fieldName: 'instructor', canSort: false },
	{ displayName: 'Total', fieldName: 'total', canSort: false },
];

const ClassroomsAnalytics: React.FC = () => {
	const dispatch = useDispatch();
	const { pathname, search } = useLocation();
	const history = useHistory();
	const [doExport, setDoExport] = useState(false);
	const {
		analytics: {
			all: {
				enrollments,
				graph,
				datatable: { data, filters, meta },
			},
		},
	} = useTypedSelector((state) => state.classroom);
	const {
		Exportloaders: { exportFileLoader },
	} = useTypedSelector((state) => state.exports);
	const { dropdownClassrooms } = useTypedSelector((state) => state.dropdowns);
	const defaultValues = useMemo<FormType>(() => {
		const startDate = new Date();
		startDate.setDate(startDate.getDate() - 14);
		return {
			classroom_ids: [],
			slice: 'day',
			dates: [startDate, new Date()],
		};
	}, []);
	const {
		control,
		watch,
		register,
		setValue,
		handleSubmit,
		reset,
		getValues,
		formState: { isValid, isDirty },
	} = useForm<FormType>({
		resolver: yupResolver(SCHEMA),
		defaultValues: defaultValues,
		mode: 'all',
	});

	useEffect(() => {
		register('dates');
		dispatch(getAllClassroomEnrollments());
		dispatch(
			getAllClassroomsGraph({
				classroom_ids: [],
				from: defaultValues.dates[0],
				to: defaultValues.dates[1],
				slice: 'day',
			})
		);
	}, []);

	const pickTypeColor = useCallback((type: EnrollmentType) => {
		if (type === 'admin') {
			return red[500];
		}
		if (type === 'wallet') {
			return deepPurple[500];
		}
		if (type === 'scratchcard') {
			return orange[500];
		}
		if (type === 'free') {
			return yellow[600];
		}
		if (type === 'instructor') {
			return green['A700'];
		}
	}, []);

	const getGraph = useCallback((args: AllClassroomsGraphPayload) => {
		dispatch(getAllClassroomsGraph(args));
	}, []);

	const watchSliceType = watch('slice');
	const watchClassrooms = watch('classroom_ids');
	const handleFilterForm = (data: FormType) => {
		getGraph({
			...data,
			from: data.dates[0],
			to: data.dates[1],
		});
	};

	const [dateRange, setDateRangeOption] =
		useState<DateRangeOption>('last 2 weeks');

	const handleDateRangeChange = (type: DateRangeOption) => {
		setDateRangeOption(type);
		if (type === 'custom') return;
		const startDate = new Date();
		const endDate = new Date();
		if (type === 'last 2 weeks') {
			startDate.setDate(startDate.getDate() - 14);
		}
		if (type === 'last month') {
			startDate.setMonth(startDate.getMonth() - 1);
		}
		if (type === 'last 3 months') {
			startDate.setMonth(startDate.getMonth() - 2);
		}
		setValue('dates', [startDate, endDate], {
			shouldValidate: true,
			shouldDirty: true,
		});
	};

	const [open, setOpen] = useState(false);
	const [anchorEl, setAnchorEl] = useState<HTMLElement>();

	const handleDatatableRequest = useCallback(
		({
			activePage,
			endDate,
			filters,
			query,
			sortBy,
			startDate,
			perPage,
		}: onPageChangeRequestMeta) => {
			if (doExport) {
				dispatch(
					exportAllClassroomsAnalytics({
						filters: filters,
						query: query,
						from: startDate,
						to: endDate,
						sortBy: sortBy,
						page: activePage,
						perPage,
					})
				);
				setDoExport(false);
			} else {
				dispatch(
					getAllClassroomsAnalyticsDatatable({
						page: activePage,
						perPage: perPage,
						filters: filters,
						from: startDate,
						to: endDate,
						sortBy: sortBy,
						query: query,
					})
				);
			}
		},
		[dispatch, doExport]
	);

	const onDeleteValue = (control: 'classroom_ids', value: number) => {
		const values = getValues()[control];
		if (!values) return;
		values.splice(
			values.findIndex((_) => _ === value),
			1
		);
		setValue(control, values);
	};

	const resetForm = () => {
		reset({
			classroom_ids: [],
			slice: 'day',
			dates: defaultValues.dates,
		});
		setDateRangeOption('last 2 weeks');
		register('dates');

		getGraph({
			classroom_ids: [],
			slice: 'day',
			from: defaultValues.dates[0],
			to: defaultValues.dates[1],
		});
	};

	return (
		<>
			{enrollments && (
				<AnalyticsCardsContainer>
					<EnrollmentsCard elevation={10}>
						<AnalyticsTotal />
						<TypeInfo>
							<span>{enrollments?.purchase_methods_total}</span>
							<label>Enrollments</label>
						</TypeInfo>
					</EnrollmentsCard>
					{enrollments?.purchase_methods?.map((en) => {
						return (
							<EnrollmentTypeCard color={pickTypeColor(en.purchase_method)}>
								<TypeInfo>
									{enrollmentIcons?.[en.purchase_method]}
									<TypeValue>{en.purchase_method_count}</TypeValue>
								</TypeInfo>
								<TypeName>{en.purchase_method}</TypeName>
							</EnrollmentTypeCard>
						);
					})}
				</AnalyticsCardsContainer>
			)}

			<Box width="100%" display="flex" justifyContent="flex-end">
				<RoundButton
					onClick={() => setDoExport(true)}
					startIcon={<Icon>south</Icon>}
					variant="contained"
				>
					Export
				</RoundButton>
				<Spacer mx={'0.625rem'} />
				<AddClassroomButton />
			</Box>

			<MainTable
				tableFilters={filters ?? []}
				RowsperPage={meta?.per_page}
				onPageChangeRequest={handleDatatableRequest}
				totalPages={meta?.last_page ?? 0}
				total={meta?.total}
				pageNum={meta?.current_page}
				tableHeads={TABLE_HEADS}
				renderItems={() => {
					if (data.length <= 0)
						return (
							<TableRow>
								<TableCell colSpan={TABLE_HEADS.length}>
									<NoResults />
								</TableCell>
							</TableRow>
						);
					return data.map((en) => {
						return (
							<TableRow>
								<WrappedTableCell align="center">
									{en.classroom_name}
								</WrappedTableCell>
								<TableCell align="center">
									{en.purchase_methods[0].purchase_method_count}
								</TableCell>
								<TableCell align="center">
									{en.purchase_methods[1].purchase_method_count}
								</TableCell>
								<TableCell align="center">
									{en.purchase_methods[2].purchase_method_count}
								</TableCell>
								<TableCell align="center">
									{en.purchase_methods[3].purchase_method_count}
								</TableCell>
								<TableCell align="center">
									{en.purchase_methods[4].purchase_method_count}
								</TableCell>
								<TableCell align="center">
									{en.purchase_methods_total}
								</TableCell>
							</TableRow>
						);
					});
				}}
			/>
			<FormControlsWrapper>
				<FormControlLabel>Select Classroom(s)</FormControlLabel>
				<EdFormControl
					width="12rem"
					select
					multiple
					SelectProps={{
						multiple: true,
						renderValue: (selected) => {
							return (
								<>
									{watchClassrooms.map((_) => {
										const cl = dropdownClassrooms.find((cl) => _ === cl.id);
										return <>{cl?.label},</>;
									})}
								</>
							);
						},
					}}
					label={''}
					name="classroom_ids"
					control={control}
				>
					{dropdownClassrooms &&
						dropdownClassrooms.map((_, index) => {
							return (
								<MenuItem value={_.id} key={`${index}-${_.id}`}>
									<Checkbox checked={watchClassrooms?.includes(_.id)} />
									<div>{_.label}</div>
								</MenuItem>
							);
						})}
				</EdFormControl>
				<Spacer mx={14} />

				<EdFormControl select name="slice" control={control}>
					{AnalyticsSlices.map((_) => {
						return (
							<MenuItem key={_.value} value={_.value}>
								{_.label}
							</MenuItem>
						);
					})}
				</EdFormControl>
				<Spacer mx={14} />

				<StyledIconButton
					onClick={(e: React.MouseEvent<HTMLElement>) => {
						setOpen(!open);
						setAnchorEl(e.currentTarget);
					}}
				>
					<Icon>event</Icon>
				</StyledIconButton>
				<DateRangePickerPopup
					open={open}
					anchorEl={anchorEl}
					onClose={() => {
						setOpen(false);
					}}
					onChange={handleDateRangeChange}
					rangeOptionValue={dateRange}
				>
					<>
						<DateRangePickerPopup.Body>
							{(watchSliceType === 'day' || watchSliceType === 'week') && (
								<Controller
									control={control}
									name="dates"
									render={({ field: { value, onChange, ref, onBlur } }) => {
										return (
											<div></div>
											// <StaticDateRangePicker
											//   calendars={2}
											//   showToolbar={false}
											//   onChange={onChange}
											//   value={value}
											//   ref={ref}
											//   renderInput={(
											//     startProps: TextFieldProps,
											//     endProps: TextFieldProps
											//   ) => (
											//     <>
											//       <TextField {...startProps} />
											//       <TextField {...endProps} />
											//     </>
											//   )}
											// />
										);
									}}
								/>
							)}

							{watchSliceType === 'month' && (
								<MonthRangePicker control={control} name="dates" />
							)}
							{watchSliceType === 'year' && (
								<YearRangePicker control={control} name="dates" />
							)}
						</DateRangePickerPopup.Body>
					</>
				</DateRangePickerPopup>
			</FormControlsWrapper>
			{/* <FormActionsWrapper> */}
			<FlexLayout alignItems="flex-end">
				<FlexItem flex="1">
					<>
						{watchClassrooms.map((_) => {
							const cl = dropdownClassrooms.find((cl) => cl.id === _);
							return (
								<SelectChip
									onDelete={() => onDeleteValue('classroom_ids', _)}
									label={cl?.label}
								/>
							);
						})}
					</>
				</FlexItem>
				<Spacer mx="0.5rem" />
				<FlexLayout>
					<Button
						disabled={!isDirty}
						onClick={() => resetForm()}
						variant="outlined"
					>
						Reset
					</Button>
					<Spacer mx={'1rem'} />
					<Button
						disabled={!isValid}
						onClick={handleSubmit(handleFilterForm)}
						variant="contained"
					>
						Apply
					</Button>
				</FlexLayout>
			</FlexLayout>
			{/* </FormActionsWrapper> */}

			<MultipleLinesChart
				sliceType={watchSliceType}
				colors={(slice: GraphSlice) => {
					return pickTypeColor(slice.id as EnrollmentType);
				}}
				data={graph}
			/>
			<ClassroomsUnitsAnalytics />
		</>
	);
};

export default ClassroomsAnalytics;
