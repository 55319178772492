import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTypedSelector } from '@Features/store';
import { webContentsGetSingle } from '../../Slice/WebContent.slices';
import EditWebContentInfo from './EditWebContentInfo';

const EditWebContent = () => {
	const { id } = useParams<{ id: string }>();
	const {
		currentEntity,
		loaders: { getSingle },
	} = useTypedSelector((state) => state.WebContents);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(
			webContentsGetSingle({
				id: Number(id),
			})
		);
	}, [dispatch]);

	return (
		<>{currentEntity && getSingle === 'fulfilled' && <EditWebContentInfo />}</>
	);
};

export default EditWebContent;
