import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import addQueryString from '@Utils/addQueryString';
import qs from 'querystring';
import { TableHeader } from '@Features/generics/generics.type';
import TableSortLabel from '@mui/material/TableSortLabel';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
interface Props {
	head: string | TableHeader;
	setSortBy: any;
	sortBy: any;
	renderCustomHeads?: boolean;
	customHead?: string;
	canSort?: boolean;
	showArrow?: boolean;
}

enum SORTING {
	NONE,
	ASC,
	DESC,
}

const SortingIcons: React.FC<Props> = ({
	head,
	setSortBy,
	sortBy,
	customHead,
	canSort = true,
	renderCustomHeads,
	showArrow = false,
}) => {
	const location = useLocation();
	const history = useHistory();
	const [sorting, setSorting] = useState<SORTING>(SORTING.NONE);

	const clickHandler = () => {
		if (typeof head === 'object' && head.canSort === false) return;
		let search = '';
		const _field = typeof head == 'object' ? head.fieldName : head;
		search = addQueryString(location.search, 'field', _field as string);

		if (sortBy?.field !== _field) {
			setSorting(SORTING.ASC);
			setSortBy({ field: _field, direction: 'asc' });
			search = addQueryString(search, 'direction', 'asc');
			history.push({ search });
			return;
		}

		if (sorting === SORTING.NONE) {
			setSorting(SORTING.ASC);
			setSortBy({ field: head, direction: 'asc' });
			search = addQueryString(search, 'direction', 'asc');
		} else if (sorting === SORTING.ASC) {
			setSorting(SORTING.DESC);
			setSortBy({ field: _field, direction: 'desc' });
			search = addQueryString(search, 'direction', 'desc');
		} else {
			setSorting(SORTING.NONE);
			setSortBy(null);
			search = addQueryString(search, 'direction', '');
			search = addQueryString(search, 'field', '');
		}
		history.push({ search });
	};

	useEffect(() => {
		let queryStrings = qs.parse(location.search.replace('?', ''));
		if (queryStrings?.field && queryStrings?.direction) {
			setSortBy({
				field: queryStrings?.field,
				direction: queryStrings?.direction,
			});

			switch (queryStrings?.direction) {
				case 'asc':
					setSorting(SORTING.ASC);
					break;
				case 'desc':
					setSorting(SORTING.DESC);
					break;
				default:
					setSorting(SORTING.NONE);
			}
		}
	}, [location.search, setSortBy]);

	const getHeader = () => {
		if (typeof head === 'object') return head.displayName;
		return head;
	};

	if (head !== 'Actions') {
		return (
			<TableSortLabel
				onClick={clickHandler}
				active={
					typeof head === 'object' && sortBy?.field === head.fieldName
						? true
						: false
				}
				hideSortIcon={!canSort}
				IconComponent={
					sorting === SORTING.ASC ? ArrowDownwardIcon : ArrowUpwardIcon
				}
			>
				{renderCustomHeads ? customHead : getHeader()}
			</TableSortLabel>
		);
	} else {
		return null;
	}
};
export default SortingIcons;
