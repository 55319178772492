import React from 'react';
import styled from 'styled-components';
import EduactCardIcon from '@Pages/Students/StudentTabs/Info/assets/eduact-card-icon.svg';
import Avatar from '@Pages/Students/StudentTabs/Info/assets/avatar.png';
import EdIcon from '@Components/UI/Utilities/EdIcon/EdIcon';
import EduactCardBg from '@Pages/Students/StudentTabs/Info/assets/card_bg.jpg';
import { useTypedSelector } from '@Features/store';
import { rgba } from 'polished';
import QRCode from '@eduact/ed-components/DataDisplay/QRCode';
import { SingleStudentData } from '@Services/students/students.res.types';
import { Box } from '@eduact/ed-system';
import { ClassroomEnrollments } from '@Services/classrooms/classrooms.res.types';

type Props = {
	student: ClassroomEnrollments;
};
const StudentCardId: React.FC<Props> = ({ student }) => {
	const { educational_info, educational_type } = useTypedSelector(
		(state) => state.educational
	);

	const years = React.useMemo(() => {
		const flatYears = educational_info
			.filter((_) => _.id)
			.map(({ educationYears }) => educationYears)
			.flat();
		return flatYears.filterDups('id');
	}, [educational_info]);

	const sections = React.useMemo(() => {
		const flatYears = educational_info
			.map(({ educationYears }) => educationYears)
			.flat()
			.filterDups('id')
			.filter((_) => _?.id);

		return flatYears
			.map(({ educationSections }) => educationSections)
			.flat()
			.filterDups('id');
	}, [educational_info]);
	return (
		<StyledOuterContainer>
			<StyledInnerContainer>
				<StyledCardHeaderContainer>
					<StyledStudentIDText>Student ID</StyledStudentIDText>

					<img
						src={EduactCardIcon}
						width={'30px'}
						height="30px"
						alt="eduact icon"
					/>
				</StyledCardHeaderContainer>

				<StyledBodyOuterContainer>
					<StyledBodyInnerContainer>
						<StyledBodyContentContainer>
							<StyledProfilePhoto
								alt="avatar"
								src={student.user.profile_photo ?? Avatar}
							/>

							<StyledBodyContentInnerContainer>
								<Box mb="4px">
									<StyledNameText>
										{`${student.user.first_name} ${student.user.last_name}`}
									</StyledNameText>
									<p>
										username:{' '}
										<span
											style={{
												fontWeight: 500,
											}}
										>
											{student.user.username}
										</span>
									</p>
								</Box>

								<div>
									<StyledPhoneText>
										<StyledIcon>phone</StyledIcon>
										{student.user.phone_number}
									</StyledPhoneText>
									<StyledEmail>
										<StyledIcon>mail</StyledIcon>
										{student.user.email}
									</StyledEmail>
								</div>
							</StyledBodyContentInnerContainer>
						</StyledBodyContentContainer>
						<StyledFooterContainer>
							<StyledFooterInnerContainer>
								<p>Year:</p>
								<StyledDataFooterText>
									{years
										?.find(
											(_: any) => _?.id === student.student.education_year_id
										)
										?.name?.replaceAll('_', ' ')}
								</StyledDataFooterText>
								<p>Branch:</p>
								<StyledDataFooterText>
									{
										sections.find(
											(_: any) => _.id === student.student.education_section_id
										)?.name
									}
								</StyledDataFooterText>
								<p>Group:</p>
								<StyledDataFooterText>
									{student.group?.name}
								</StyledDataFooterText>
							</StyledFooterInnerContainer>
							<QRCode width={72} value={student.user.username} />
						</StyledFooterContainer>
					</StyledBodyInnerContainer>
					{/* <StyledBackgroundImage src={EduactCardBg} alt="card-bg" style={{}} /> */}
				</StyledBodyOuterContainer>
			</StyledInnerContainer>
		</StyledOuterContainer>
	);
};
const StyledOuterContainer = styled.div`
	/* width: 351.49606299px; */

	width: 9.3cm;
	margin-top: 30px;
	margin-bottom: 30px;
	height: 5.8cm;
	/* aspect-ratio: 1.672; */
	display: flex;
	flex-direction: column;
	/* width :460px; */
	/* height: 275px; */
	/* margin-top: 40px; */
	/* margin-right: 20px; */
	border-radius: 10px;
	/* overflow: hidden; */
	position: relative;
	box-shadow: rgba(50, 50, 93, 0.25) 0 13px 27px -5px,
		rgba(0, 0, 0, 0.3) 0 8px 16px -8px;
	isolation: isolate;
	&::after {
		content: '';
		width: calc(100% + 0.5cm);
		/* width: 120%; */
		/* height: 120%; */
		height: calc(100% + 0.5cm);
		/* background-color: red; */
		position: absolute;
		top: 50%;
		left: 50%;
		z-index: -1;
		transform: translate(-50%, -50%);
		border: 2px dashed;
		/* border-style: ; */
		border-radius: inherit;
	}
	box-sizing: content-box;

	* {
		box-sizing: content-box;
	}
`;

const StyledInnerContainer = styled.div`
	width: 9.3cm;
	height: 5.8cm;
	display: flex;
	flex-direction: column;
	border-radius: 10px;
	overflow: hidden;
	position: relative;
	border-radius: 10px;
`;
const StyledCardHeaderContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: #5ac0fc;
	color: #fff;
	padding: 0 20px;
	position: relative;
	z-index: 2;
`;
const StyledStudentIDText = styled.p`
	font-size: 28px;
	font-weight: 600;
	margin: 10px 0;
`;
const StyledBodyOuterContainer = styled.div`
	position: relative;
	/* height: 280px; */
	/* width: 460px; */
	/* width : 100%; */
	/* height : 100%; */
`;
const StyledBodyInnerContainer = styled.div`
	/* position: absolute; */
	padding: 5px 20px;
	/* width: 90%; */
`;
const StyledBodyContentContainer = styled.div`
	display: flex;
	gap: 12px;
	align-items: flex-start;
	margin-bottom: 1ch;
`;
const StyledBodyContentInnerContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 6px 0;
	font-size: 0.825rem;
`;
const StyledProfilePhoto = styled.img`
	width: 114px;
	/* height: 114px; */
	width: 50px;
	aspect-ratio: 1 / 1;
	object-fit: contain;
	z-index: 9999;
	position: relative;
`;
const StyledNameText = styled.p`
	margin-bottom: 3px;
	font-weight: 500;
`;
const StyledPhoneText = styled.p`
	margin-bottom: 3px;
	font-weight: 500;
	color: #2196f3;
	display: flex;
	gap: 5px;
	align-items: center;
`;
const StyledIcon = styled(EdIcon)`
	color: gray;
	position: relative;
	/* top: 6px; */
	font-size: 14px;
	margin-right: 2px;
`;
const StyledEmail = styled.p`
	color: #2196f3;
	text-decoration: underline;
	display: flex;
	align-items: center;
	gap: 5px;
`;
const StyledFooterContainer = styled.div`
	display: flex;
	justify-content: space-between;
	/* padding: 16px 0; */
`;
const StyledFooterInnerContainer = styled.div`
	display: grid;
	grid-template-columns: 60px 100%;
	grid-template-rows: repeat(3, 1fr);
	p {
		font-size: 14px;
	}
`;
const StyledDataFooterText = styled.p`
	font-size: 10px;
	font-weight: 500;
`;
const StyledQRContainer = styled.div`
	background-color: white;
	z-index: 3;
`;
const StyledBackgroundImage = styled.img`
	position: absolute;
	bottom: 48px;
	z-index: 1;
	width: 460px;
	background: ${rgba('#5AC0FC', 0.1)};
`;
export default StudentCardId;
