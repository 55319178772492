import React, { useMemo } from 'react';
import styled from 'styled-components';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { Tooltip } from '@mui/material';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { useTypedSelector } from '@Features/store';

const PreviousNextSlots = () => {
	const { id } = useParams<{ id: string }>();
	const { currentEntity } = useTypedSelector((state) => state.Webinars);
	const history = useHistory();
	const { path } = useRouteMatch();

	const sorted = currentEntity?.webinarSlots?.map((_) => {
		return { ..._, start_time: new Date(_.start_time) };
	});
	const sortedSlots = sorted?.sort(
		(objA, objB) => objA.start_time.getTime() - objB.start_time.getTime()
	);

	const handleBackButtonClick = () => {
		if (slotIndex === undefined) return;
		if (!sortedSlots) return;
		const prevSlot = sortedSlots[slotIndex - 1];
		const _path = path.replace(':id', prevSlot.id.toString());
		history.replace({ pathname: _path });
	};

	const handleNextButtonClick = () => {
		if (slotIndex === undefined) return;
		if (!sortedSlots) return;
		const nextSlot = sortedSlots[slotIndex + 1];
		const _path = path.replace(':id', nextSlot.id.toString());
		history.replace({ pathname: _path });
	};

	const isLast = useMemo(() => {
		if (!currentEntity || !sortedSlots) return;
		const index = sortedSlots?.findIndex((_) => _.id === Number(id));
		return index === sortedSlots?.length - 1;
	}, [currentEntity, id]);

	const isFirst = useMemo(() => {
		if (!currentEntity || !sortedSlots) return;
		const index = sortedSlots?.findIndex((_) => _.id === Number(id));
		return index === 0;
	}, [currentEntity, id]);

	const slotIndex = useMemo(() => {
		if (!currentEntity || !sortedSlots) return;
		const index = sortedSlots?.findIndex((_) => _.id === Number(id));
		return index;
	}, [currentEntity, id]);

	const prevSlot = useMemo(() => {
		if (slotIndex === undefined) return;
		if (!currentEntity || !sortedSlots) return;
		const prev = sortedSlots[slotIndex]?.id - 1;
		const current = sortedSlots?.find((_) => _.id === prev);
		return current;
	}, [id]);

	const currentSlot = useMemo(() => {
		if (!currentEntity || !sortedSlots) return;
		const current = sortedSlots?.find((_) => _.id === Number(id));
		return current;
	}, [id]);

	const nextSlot = useMemo(() => {
		if (slotIndex === undefined) return;
		if (currentEntity === undefined) return;
		if (!currentEntity || !sortedSlots) return;
		const nextId = sortedSlots[slotIndex]?.id + 1;
		const next = sortedSlots?.find((_) => _.id === nextId);

		return next;
	}, [currentEntity, slotIndex]);

	return (
		<>
			<TopSection>
				<SlotName>{currentSlot?.name}</SlotName>
				<NavigationBox>
					<Tooltip
						title={
							<>
								{prevSlot !== undefined && (
									<PreviousSlot> {prevSlot?.name} </PreviousSlot>
								)}
							</>
						}
						placement="left"
					>
						<IconButton
							disabled={isFirst}
							onClick={handleBackButtonClick}
							aria-label="previous page"
						>
							<KeyboardArrowLeft />
						</IconButton>
					</Tooltip>
					<Tooltip
						title={
							<>
								{nextSlot !== undefined && (
									<PreviousSlot> {nextSlot?.name} </PreviousSlot>
								)}
							</>
						}
						placement="right"
					>
						<IconButton
							disabled={isLast}
							onClick={handleNextButtonClick}
							aria-label="next page"
						>
							<KeyboardArrowRight />
						</IconButton>
					</Tooltip>
				</NavigationBox>
			</TopSection>
		</>
	);
};

const NavigationBox = styled(Box)`
	display: flex;
	justify-content: end;
`;

const TopSection = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const SlotName = styled.div`
	font-size: 1rem;
	font-weight: 500;
`;

const PreviousSlot = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 10px;
	font-size: 1.2rem;
`;

export default PreviousNextSlots;
