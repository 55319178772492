import { Stack } from '@mui/material';
import {
	CancelBtn,
	SaveBtn,
} from '@Pages/Roles/pages/AddRole/Components/addRoleInfo';
import React from 'react';
import { FormState } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { InstructorForm } from '../Schema/Instructor.schema';
import { Flatten } from '@Utils/types/Flatten';

type Props = {
	getValues: any;
	onSave: (form: Flatten<InstructorForm>) => void;
	formState?: FormState<InstructorForm>;
	isDirty?: boolean;
	isValid?: boolean;
};

const GroupedBtns: React.FC<Props> = ({
	getValues,
	onSave,
	formState,
	isDirty,
	isValid,
}) => {
	const history = useHistory();

	return (
		<>
			<Stack spacing={2} direction="row-reverse">
				<SaveBtn
					variant="contained"
					disabled={
						formState
							? !formState.isDirty || !formState.isValid
							: !isDirty || !isValid
					}
					onClick={() => {
						const data = getValues();
						onSave(data);
					}}
				>
					SAVE
				</SaveBtn>
				<CancelBtn
					variant="contained"
					disabled={formState ? !formState.isDirty : !isDirty}
					onClick={() => history.push('/dashboard/instructors')}
				>
					CANCEL
				</CancelBtn>
			</Stack>
		</>
	);
};

export default GroupedBtns;
