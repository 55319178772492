import EdButton from '@Components/UI/Buttons/EdButton/EdButton';
import { Menu, MenuItem, MenuItemProps, MenuProps } from '@mui/material';
import React, { FC, ReactElement, useState } from 'react';

type Props = {
	ButtonProps?: React.ComponentProps<typeof EdButton>;
	renderButton: () => React.ReactElement<React.ComponentProps<typeof EdButton>>;
	renderMenu: () => React.ReactElement<
		React.ComponentProps<typeof EdInnerMenu>
	>;
	onChange?: (value: any) => void;
};

const EdInnerMenu: FC<
	Partial<MenuProps> & {
		children: Array<
			React.ReactElement<React.ComponentProps<typeof EdInnerMenuItem>>
		>;
	}
> = ({ children, ...props }) => {
	return (
		<Menu open={false} {...props}>
			{children}
		</Menu>
	);
};
const EdInnerMenuItem: FC<Partial<MenuItemProps>> = ({
	children,
	...props
}) => {
	return (
		<MenuItem onClick={() => {}} {...props}>
			{children}
		</MenuItem>
	);
};
interface EdMenuComposition {
	Menu: React.FC<
		Partial<MenuProps> & {
			children: Array<
				React.ReactElement<React.ComponentProps<typeof EdInnerMenuItem>>
			>;
		}
	>;
	MenuItem: React.FC<Partial<MenuItemProps>>;
}
const EdMenu: FC<Props> & EdMenuComposition = ({
	ButtonProps,
	renderButton,
	renderMenu,
	onChange,
}) => {
	const [anchorEl, setAnchorEl] = useState<HTMLElement | undefined>();
	const [value, setValue] = useState<any>();

	const handleOnChange = React.useCallback(() => {
		onChange && onChange(value);
		setAnchorEl(undefined);
	}, [value]);
	const getButton = () => {
		if (React.isValidElement(renderButton())) {
			const props = renderButton().props;
			return React.cloneElement(renderButton(), {
				...props,
				onClick: (e: React.MouseEvent<HTMLButtonElement>) => {
					ButtonProps && ButtonProps.onClick && ButtonProps?.onClick(e);
					setAnchorEl(e.currentTarget);
				},
			});
		}
	};
	const getMenu = () => {
		return React.cloneElement(renderMenu(), {
			...renderMenu(),
			anchorEl: anchorEl,
			open: anchorEl ? true : false,
			onClose: () => setAnchorEl(undefined),
			children: getMenuChidlren(renderMenu().props.children),
		});
	};

	const getMenuChidlren = (
		children: React.ReactElement<React.ComponentProps<typeof EdInnerMenuItem>>[]
	) => {
		return React.Children.map(children, (child) => {
			const { key, props, type } = child;
			return React.cloneElement(child, {
				key,
				...props,
				onClick: (e: React.MouseEvent<HTMLLIElement>) => {
					setValue(child.props.value);
					child.props.onClick && child.props.onClick(e);
					handleOnChange();
				},
			});
		});
	};
	return (
		<div>
			<>{getButton()}</>
			<>{getMenu()}</>
		</div>
	);
};

EdMenu.Menu = EdInnerMenu;
EdMenu.MenuItem = EdInnerMenuItem;

export default EdMenu;
