import React from 'react';
import IPsTab from '@Pages/IPs';
import { RouteGroup } from '@Constants/routes';

export const IPsRoute: RouteGroup = {
	isRouted: true,
	isParent: true,
	title: 'IP Addresses',
	icon: 'language',
	route: 'ips',
	code: 'v-ips',
	component: <IPsTab />,
	routes: [],
};
