import React, { useState } from 'react';
import { FlexLayout, GridLayout, Spacer } from '@Styled/utilities';
import styled from 'styled-components';
import { useTypedSelector } from '@Features/store';
import moment from 'moment';
import { Button, MenuItem, OutlinedInput } from '@mui/material';
import Select from '@mui/material/Select';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { InvoicesRequester } from '../../Services/Invoices.req';
import { invoicesGetSingle } from '@Pages/Invoices/Slices/Invoices.slice';
import { useSnackbar } from '@Providers/useSnackbar';
import { grey } from '@mui/material/colors';
import FormControl from '@mui/material/FormControl';
import { fontStyle, FontStyleProps } from 'styled-system';
import { StatusTypes } from '@Pages/Invoices/Constants/invoices.constants';

const InvoicesCard = () => {
	const { id } = useParams<{ id: string }>();
	const { currentEntity } = useTypedSelector((state) => state.Invoices);
	const auth = useTypedSelector((state) => state.auth);
	const hasEditPermission =
		auth.user.type === 'super' || auth.permissions.has('u-inv');

	const [invoiceStatus, setInvoiceStatus] = useState<string>();
	const dispatch = useDispatch();
	const { displaySnackbar } = useSnackbar();

	const handleChange = (event: any) => {
		const {
			target: { value },
		} = event;
		setInvoiceStatus(value);
	};

	const onSave = async () => {
		if (currentEntity?.status === undefined) return;
		if (invoiceStatus === undefined) return;
		try {
			await InvoicesRequester.getInstance().updateInvoice(
				Number(id),
				invoiceStatus
			);
			displaySnackbar('success', `Status Updated Successfully`);
			dispatch(invoicesGetSingle({ id: Number(id) }));
		} catch (e) {
			const msg = e.response?.data.message || 'Unable to Update Invoice ';
			displaySnackbar('error', msg);
		}
	};
	return (
		<>
			<FlexLayoutWrapper flexDirection="column">
				<Title>Invoice</Title>
				<GridLayout>
					<Text>Total Price</Text>
					<Info fontStyle={'italic'}> {currentEntity?.total_price} EGP </Info>
				</GridLayout>
				<GridLayout>
					<Text>Price</Text>
					<Info fontStyle={'italic'}> {currentEntity?.price} EGP </Info>
				</GridLayout>
				<GridLayout>
					<Text>Tax</Text>
					<Info fontStyle={'italic'}>
						{currentEntity?.tax} &nbsp; &nbsp; EGP
					</Info>
				</GridLayout>
				<GridLayout>
					<Text>Discount</Text>
					<Info fontStyle={'italic'}>
						{currentEntity?.discount} &nbsp; &nbsp; EGP
					</Info>
				</GridLayout>
				<Spacer mb="4rem" />
				<GridLayout>
					<Text>Invoice Reference</Text>
					<Info> {currentEntity?.invoice_ref} </Info>
				</GridLayout>
				<GridLayout>
					<Text>Invoice Date</Text>
					<Info>
						{moment(currentEntity?.created_at).format('DD-MM-YY')}
						&nbsp; &nbsp;
						<TimeInfo>
							{moment(currentEntity?.created_at).format('kk:mm')}
						</TimeInfo>
					</Info>
				</GridLayout>
				<GridLayout>
					<Text>Type</Text>
					<Info> {currentEntity?.type} </Info>
				</GridLayout>
				<GridLayout>
					<Text>Status</Text>
					<FormControl disabled={!hasEditPermission}>
						<SelectStatus
							labelId="course_status"
							id="demo-multiple-name"
							value={invoiceStatus ?? currentEntity?.status}
							onChange={handleChange}
							input={<OutlinedInput />}
							size="small"
							displayEmpty={true}
						>
							{StatusTypes &&
								StatusTypes?.map((_, index) => {
									return (
										<MenuItem key={index} value={_}>
											{_}
										</MenuItem>
									);
								})}
						</SelectStatus>
					</FormControl>
				</GridLayout>
				{hasEditPermission && (
					<FlexLayout justifyContent="end">
						<SaveBtn
							color="primary"
							variant="contained"
							onClick={() => onSave()}
						>
							Save
						</SaveBtn>
					</FlexLayout>
				)}
			</FlexLayoutWrapper>
		</>
	);
};

export const FlexLayoutWrapper = styled(FlexLayout)`
	border-radius: 10px;
	box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
	padding: 2.5rem 3rem 1.688rem 1.875rem;
	margin-top: 1rem;
	margin-bottom: 1.813rem;
	gap: 1.4rem;
`;

export const Title = styled.p`
	font-size: 1.5rem;
	color: ${(props) => props.theme.palette.darkPurple.main};
	font-weight: 600;
	margin-bottom: 4rem;
`;

export const Text = styled.p`
	font-weight: 500;
	font-size: 1.125rem;
	color: ${(props) => props.theme.palette.darkPurple.main};
`;

export const Info = styled.p<FontStyleProps>`
	font-weight: 500;
	font-size: 1.125rem;
	/* font-style: italic; */
	color: ${grey['500']};
	display: flex;
	${fontStyle}
`;
export const TimeInfo = styled(Info)`
	color: ${(props) => props.theme.palette.primary.main};
`;

const SaveBtn = styled(Button)`
	border-radius: 24px;
	padding: 0.563rem 2.563rem 0.563rem 2.5rem;
	margin-top: 2rem;
`;

const SelectStatus = styled(Select)`
	width: 7.688rem;
	color: #2196f3;

	fieldset {
		border-radius: 4px;
		border: solid 1px #2196f3;

		&:hover {
			border-color: #2196f3;
		}
	}

	.MuiSelect-icon {
		fill: #2196f3;
	}
`;

export default InvoicesCard;
