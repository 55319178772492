import { AxiosResponse } from 'axios';
export default async function createThunkBody<ResponseType>(
	requesterFunc: Promise<AxiosResponse<ResponseType>>,
	thunkApi: any,
	fallbackErrorMsg: string
) {
	try {
		const { data } = await requesterFunc;
		return data;
	} catch (error) {
		const msg = error.response?.data.message || fallbackErrorMsg;
		return thunkApi.rejectWithValue(msg);
	}
}
