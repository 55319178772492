import { BaseSliceController } from '@Base/slices/slices.base';
import { StudentTestsCountRequester } from '../Services/TestsCountRequester';
import { TestsCount } from '../Types/Tests.types';

export class StudentTestsCountSlice extends BaseSliceController<
	TestsCount,
	'StudentTestsCount',
	TestsCount
> {
	private static instance: StudentTestsCountSlice;
	static getInstance() {
		if (!StudentTestsCountSlice.instance) {
			StudentTestsCountSlice.instance = new StudentTestsCountSlice();
		}
		return StudentTestsCountSlice.instance;
	}
	constructor() {
		super('StudentTestsCount', StudentTestsCountRequester.getInstance());
	}
}

export const { studentTestsCountGetSingle } =
	StudentTestsCountSlice.getInstance().getActions();
