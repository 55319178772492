import api from '@Services/api';
import {
	GetNotificationsClassroomsResponseType,
	GetNotificationsResponseType,
} from '@Services/notifications/notification.api.types';

export const getNotificationsClassroomsRequest = () =>
	api.get<GetNotificationsClassroomsResponseType>('/dropdown/classrooms');

export const getNotificationsTestsRequest = () =>
	api.get<GetNotificationsResponseType>('/notifiable-units');

export const updateNotificationsTestsRequest = (tests: any[]) =>
	api.put<ApiResponse<any>>('/notifiable-units', { units: tests });

export const getNotificationAnalyticsRequest = async (id: number) =>
	await api.get(`notifiable-units/history/${id}`);
