import React from 'react';
import { useTypedSelector } from '@Features/store';
import { useSnackbar } from '@Providers/useSnackbar';
import { useDispatch } from 'react-redux';
import {
	AdmissionQuestionPayload,
	ClassroomAdmissionForm,
	ClassroomAdmissionPayload,
} from '@Pages/ClassRooms/Types/ClassroomAdmission.types';
import { yupResolver } from '@hookform/resolvers/yup';
import { ADMISSION_SCHEMA } from '@Pages/ClassRooms/Schema/ClassroomAdmission.schema';
import { cloneDeep } from 'lodash';
import { useForm, FormProvider } from 'react-hook-form';
import { ClassroomAdmissionRequester } from '@Pages/ClassRooms/Services/ClassroomAdmission/RequestsHandler/ClassroomAdmission.req';
import { getClassroomByLabel } from '@Features/classrooms/classroomSlice';
import styled from 'styled-components';
import EdFormControl from '@Components/UI/Inputs/EdFormControl/EdFormControl';
import { GridLayout } from '@Styled/utilities';
import { Button } from '@mui/material';
import QuestionsRHF from './QuestionsRHF';

type Props = {
	onNext?: (payload: ClassroomAdmissionPayload) => void;
	mode?: 'edit' | 'create';
};

const ClassroomAdmissionPage: React.FC<Props> = ({ onNext, mode }) => {
	const admission = useTypedSelector((state) => state.classroomAdmissionForm);
	const { currentClassroom } = useTypedSelector((state) => state.classroom);
	const dispatch = useDispatch();

	const methods = useForm<ClassroomAdmissionForm>({
		mode: 'all',
		reValidateMode: 'onChange',
		resolver: yupResolver(ADMISSION_SCHEMA),
		defaultValues: mode === 'edit' ? cloneDeep(admission) : {},
	});

	const { displaySnackbar } = useSnackbar();
	const onSave = async (formData: ClassroomAdmissionForm) => {
		const questions = formData.questions.map(
			(q, index) =>
				({
					...q,
					order: index + 1,
					options: q.options?.map((opt) => opt.option),
				} as AdmissionQuestionPayload)
		);

		if (currentClassroom) {
			try {
				const {
					data: {
						data: { id },
					},
				} = await ClassroomAdmissionRequester.getInstance().create({
					classroom_id: currentClassroom.id,
					admissionForm: formData.admissionForm,
					questions: questions,
				});
				displaySnackbar('success', 'Admission created successfully');
				dispatch(getClassroomByLabel({ label: currentClassroom.label }));
				methods.setValue('id', id);
			} catch (error) {}
		} else {
			if (mode === 'create') {
				onNext &&
					onNext({
						admissionForm: formData.admissionForm,
						questions: formData.questions.map(
							(q) =>
								({
									...q,
									options: q.options?.map((o) => o.option),
								} as AdmissionQuestionPayload)
						),
					});
			}
		}
	};
	const onUpdate = async (formData: ClassroomAdmissionForm) => {
		try {
			const questions = formData.questions.map(
				(q, index) =>
					({
						...q,
						order: index + 1,
						options: q.options?.map((opt) => opt.option),
					} as AdmissionQuestionPayload)
			);
			await ClassroomAdmissionRequester.getInstance().update({
				admissionForm: formData.admissionForm,
				id: formData.id,
				questions: questions,
			});
			displaySnackbar('success', 'Admission updated successfully');
		} catch (error) {}
	};
	const { isValid, isDirty } = methods.formState;

	return (
		<>
			<FormProvider {...methods}>
				<SectionLayout>
					<GridLayout gridTemplateColumns={'1fr'} gridGap={'2rem'}>
						<EdFormControl
							required
							control={methods.control}
							name="admissionForm.name"
							label="Title"
						/>
						<EdFormControl
							required
							control={methods.control}
							name="admissionForm.description"
							label="Subtitle"
						/>
					</GridLayout>
				</SectionLayout>
				<AdmissionQuestionsContainer>
					<QuestionsRHF />
				</AdmissionQuestionsContainer>
				<ActionsContainer>
					{(!admission || mode === 'create') && (
						<Button
							onClick={methods.handleSubmit(onSave)}
							disabled={!isValid}
							variant="contained"
						>
							Save
						</Button>
					)}
					{admission && mode === 'edit' && (
						<Button
							variant="contained"
							disabled={!isValid || !isDirty}
							onClick={methods.handleSubmit(onUpdate)}
						>
							Update
						</Button>
					)}
				</ActionsContainer>
			</FormProvider>
		</>
	);
};
const SectionLayout = styled(GridLayout)`
	margin-right: 5.5rem;
	grid-gap: 2rem;
`;
const ActionsContainer = styled.div`
	display: flex;
	justify-content: flex-end;
	margin-top: 8.313rem;
`;
const AdmissionQuestionsContainer = styled.div`
	margin-top: 4.375rem;
`;
export default ClassroomAdmissionPage;
