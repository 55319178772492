import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';
import {
	DropdownsState,
	TestAttemptsPayload,
} from '@Features/dropdowns/dropdownsSlice.types';
import {
	fetchInstructorsDropdownRequest,
	fetchCategoriesDropdownRequest,
	fetchClassroomsDropdownRequest,
	fetchBatchesDropdownRequest,
	fetchCoursesDropdownRequest,
	fetchWalletBatchesDropdownRequest,
	fetchTagsDropdownRequest,
	fetchUnitsDropdownRequest,
	fetchTestAttemptsDropdownRequest,
	fetchClassroomsByInstructorDropdownRequest,
} from '@Services/dropdowns/dropdowns.req';
import { GroupPermissions } from '@Pages/Roles/Types/Roles.types';
import { getPermissionsRequest } from '@Pages/Roles/Services/roles.req';
import { Tag } from '@Pages/ClassRooms/Pages/SingleCourse/Pages/CourseForm/Pages/CourseContent/Pages/Test/Types/TestQuestion';
import { DropdownsClassroomsResponseType } from '@Services/dropdowns/dropdowns.api.types';

export const resetClassroomDropdowns = createAction(
	'resetClassroomDropdown'
);

export const fetchInstructorsDropdown = createAsyncThunk(
	'dropdowns/instructors',
	async () => {
		const response = await fetchInstructorsDropdownRequest();
		return response.data.data;
	}
);

export const fetchCategoriesDropdown = createAsyncThunk(
	'dropdowns/categories',
	async () => {
		const response = await fetchCategoriesDropdownRequest();
		return response.data.data;
	}
);

export const fetchClassroomsDropdown = createAsyncThunk(
	'dropdowns/classrooms',
	async () => {
		const response = await fetchClassroomsDropdownRequest();
		return response.data.data;
	}
);

export const fetchClassroomsByInstructorDropdown = createAsyncThunk(
	'dropdowns/courses/instrcutor',
	async (classroomId: number) => {
		const response = await fetchClassroomsByInstructorDropdownRequest(
			classroomId
		);
		return response.data.data;
	}
);

export const fetchBatchesDropdown = createAsyncThunk(
	'dropdowns/batches',
	async () => {
		const response = await fetchBatchesDropdownRequest();
		return response.data.data;
	}
);

export const fetchWalletBatchesDropdown = createAsyncThunk(
	'dropdowns/wallet-batches',
	async () => {
		const response = await fetchWalletBatchesDropdownRequest();
		return response.data.data;
	}
);

export const fetchCoursesDropdown = createAsyncThunk(
	'dropdowns/courses',
	async (classroomId: number | undefined) => {
		const response = await fetchCoursesDropdownRequest(classroomId);
		return response.data.data;
	}
);

export const fetchUnitsDropdown = createAsyncThunk(
	'dropdowns/units',
	async (courseId: number | undefined) => {
		const response = await fetchUnitsDropdownRequest(courseId);
		return response.data.data;
	}
);

export const fetchTestAttemptsDropdown = createAsyncThunk(
	'dropdowns/test/attempts',
	async (payload: TestAttemptsPayload) => {
		const response = await fetchTestAttemptsDropdownRequest(
			payload.unitId,
			payload.userId
		);
		return response.data.data;
	}
);

export const getAllPermissions = createAsyncThunk<
	ApiResponse<GroupPermissions[]>,
	{ rejectValue: string }
>('permission/all', async (all, thunkApi) => {
	const { data } = await getPermissionsRequest(all);
	return data;
});

export const fetchTagsDropdown = createAsyncThunk<ApiResponse<Tag[]>, void>(
	'tags/all',
	async () => {
		const { data } = await fetchTagsDropdownRequest();
		return data;
	}
);

const initialState: DropdownsState = {
	dropdownInstructors: [],
	dropdownBatches: [],
	dropdownCategories: [],
	dropdownClassrooms: [],
	dropdownCourses: [],
	dropdownWalletBatches: [],
	group_permissions: [],
	dropdownTags: [],
	dropdownUnits: [],
	dropdownTestAttempts: [],
	dropdownClassroomsByInstructor: [],
};

const dropdownsSlice = createSlice({
	name: 'dropdowns',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(
			fetchInstructorsDropdown.fulfilled,
			(state, { payload }) => {
				state.dropdownInstructors = payload;
			}
		);

		builder.addCase(fetchCategoriesDropdown.fulfilled, (state, { payload }) => {
			state.dropdownCategories = payload;
		});

		builder.addCase(fetchClassroomsDropdown.fulfilled, (state, { payload }) => {
			state.dropdownClassrooms = payload;
		});
		builder.addCase(
			fetchClassroomsByInstructorDropdown.fulfilled,
			(state, { payload }) => {
				state.dropdownClassroomsByInstructor = payload;
			}
		);

		builder.addCase(fetchBatchesDropdown.fulfilled, (state, { payload }) => {
			state.dropdownBatches = payload;
		});

		builder.addCase(fetchCoursesDropdown.fulfilled, (state, { payload }) => {
			state.dropdownCourses = payload;
		});
		builder.addCase(
			fetchWalletBatchesDropdown.fulfilled,
			(state, { payload }) => {
				state.dropdownWalletBatches = payload;
			}
		);
		builder.addCase(fetchTagsDropdown.fulfilled, (state, { payload }) => {
			state.dropdownTags = payload.data;
		});
		// permissions
		builder.addCase(getAllPermissions.fulfilled, (state, { payload }) => {
			state.group_permissions = payload.data;
		});
		builder.addCase(fetchUnitsDropdown.fulfilled, (state, { payload }) => {
			state.dropdownUnits = payload;
		});
		builder.addCase(
			fetchTestAttemptsDropdown.fulfilled,
			(state, { payload }) => {
				state.dropdownTestAttempts = payload;
			}
		);
		builder.addCase(resetClassroomDropdowns, (state, { payload }) => {
			state.dropdownCourses = [];
			state.dropdownUnits = [];
		});
	},
});

export default dropdownsSlice.reducer;
