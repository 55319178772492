import api from '@Services/api';
import { AxiosResponse } from 'axios';
import {
	CRUDBase,
	DeleteSingleEntityPayload,
	GetAllMeta,
	GetSingleEntityPayload,
} from 'src/base/services/services.base';
import { FlattenCategoryForm } from '../Schema/CategoryInfo.schema';
import { CategoryResponse } from '../Types/Category.types';
import {
	getAllCategoriesResponse,
	createCategoryResponse,
	createCategoryRequest,
	deleteCategoryResponse,
	updateCategoryRequest,
	updateCategoryResponse,
	getSingleCategory,
} from './Categories.req.types';

// import { CRUDBase } from "@b"

export const GetAllCategories = async (
	page: number,
	perPage: number,
	filters: Array<any>,
	sortBy: any,
	query: string,
	from?: Date,
	to?: Date
) => {
	const reqBody = { page, perPage, filters, sortBy, query, from, to };
	return await api.post<getAllCategoriesResponse>('category/all', reqBody);
};

export const CreateCategory = async (
	name: string,
	code: string,
	icon: string
) => {
	const reqBody: createCategoryRequest = { name, code, icon };
	if (code === '' || !code) delete reqBody.code;
	return await api.post<createCategoryResponse>('category/create', reqBody);
};

export const UpdateCategory = async (
	id: number,
	name: string,
	code: string,
	icon: string
) => {
	const reqBody: updateCategoryRequest = { id, name, code, icon };
	if (code === '' || !code) delete reqBody.code;
	return await api.put<updateCategoryResponse>('category/update', reqBody);
};

export const GetCategory = async (id: number) =>
	await api.post<getSingleCategory>('category', { id });

export const DeleteCategory = async (id: number) =>
	await api.delete<deleteCategoryResponse>('category/delete', { data: { id } });

export class CategoryRequester
	implements CRUDBase<FlattenCategoryForm, CategoryResponse>
{
	baseURL?: string | undefined;
	getAll(
		payload?: GetAllMeta
	): Promise<AxiosResponse<ApiResponse<CategoryResponse[]>>>;
	getAll(
		payload: number
	): Promise<AxiosResponse<ApiResponse<CategoryResponse[]>>>;
	async getAll(
		payload: any
	): Promise<AxiosResponse<ApiResponse<CategoryResponse[]>>> {
		return await api.post('category/all', payload);
	}
	private static instance: CategoryRequester = new CategoryRequester();
	static getInstance(): CategoryRequester {
		if (CategoryRequester.instance) {
			CategoryRequester.instance = new CategoryRequester();
		}
		return CategoryRequester.instance;
	}

	get(id: string): Promise<AxiosResponse<ApiResponse<CategoryResponse>>>;
	get(id: number): Promise<AxiosResponse<ApiResponse<CategoryResponse>>>;
	get(
		id: any,
		payload: GetSingleEntityPayload
	): Promise<AxiosResponse<ApiResponse<CategoryResponse>>>;
	async get(
		id: string | number,
		payload?: any
	): Promise<AxiosResponse<ApiResponse<CategoryResponse>>> {
		return await api.post('category', payload);
	}

	async create(
		payload: FlattenCategoryForm
	): Promise<AxiosResponse<ApiResponse<CategoryResponse>>> {
		return await api.post('category/create', payload);
	}
	async update(
		payload: FlattenCategoryForm
	): Promise<AxiosResponse<ApiResponse<CategoryResponse>>> {
		return await api.put('category/update', payload);
	}

	delete(id: number): Promise<AxiosResponse<ApiResponse<any>>>;
	delete(id: string): Promise<AxiosResponse<ApiResponse<any>>>;
	delete(
		id: string | number,
		payload: DeleteSingleEntityPayload
	): Promise<AxiosResponse<ApiResponse<any>>>;
	async delete(
		id: any,
		payload?: any
	): Promise<AxiosResponse<ApiResponse<any>>> {
		return await api.delete('category/delete', {
			data: payload,
		});
	}
}
