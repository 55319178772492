import { IPsState, IPsPayload } from './ipSlice.types';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import createThunkBody from '@Utils/createThunkBody';
import { GetAllIPs } from '@Services/IPs/IPs.req';
import { getAllIPsResponse } from '@Services/IPs/IPs.req.types';

export const getIPs = createAsyncThunk<
	getAllIPsResponse,
	IPsPayload,
	{ rejectValue: string }
>(
	'IPs/getAllIPs',
	async ({ page, perPage, sortBy, filters, query, from, to }, thunkApi) => {
		return await createThunkBody(
			GetAllIPs(page, perPage, filters, sortBy, query, from, to),
			thunkApi,
			'Error fetching IPs'
		);
	}
);

const initialState: IPsState = {
	IPs: [],
	filters: [],
	total: 0,
	lastPage: 0,
	page: 0,
	perPage: 0,
	loaders: {
		fetchAll: null,
	},
	errors: {
		fetchAll: '',
	},
};

const IPsSlice = createSlice({
	name: 'IPs',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(getIPs.pending, (state) => {
				state.loaders.fetchAll = true;
				state.errors.fetchAll = '';
			})
			.addCase(
				getIPs.fulfilled,
				(state, { payload: { data, meta, message, filters } }) => {
					state.IPs = data;
					state.lastPage = meta?.last_page;
					state.total = meta?.total;
					state.filters = filters || [];
					state.perPage = meta?.per_page;
					state.page = meta?.current_page;
					state.loaders.fetchAll = false;
				}
			)
			.addCase(getIPs.rejected, (state, action) => {
				state.errors.fetchAll = action?.payload || 'Error Fetching IPs ';
				state.loaders.fetchAll = undefined;
			});
	},
});

export default IPsSlice.reducer;
