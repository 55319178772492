import EdButton from '@Components/UI/Buttons/EdButton/EdButton';
import EdIcon from '@Components/UI/Utilities/EdIcon/EdIcon';
import { FlexLayout, Typography } from '@eduact/ed-system';
import { LoginAsRequester } from '@Pages/Students/Services/LoginAs.Requester';
import { StudentsRequester } from '@Pages/Students/Services/Students.Requester';
import { LoginAsStudentResponse } from '@Types/user';
import React, { useEffect, useState } from 'react';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';

type LoginAsStudentProps = {};
const LoginAsStudent: React.VoidFunctionComponent<LoginAsStudentProps> = () => {
	const [userData, setUserData] = useState<
		LoginAsStudentResponse | undefined
	>();
	const { id } = useParams<{ id: string }>();

	useEffect(() => {
		const getUserData = async () => {
			try {
				const {
					data: { data },
				} = await LoginAsRequester.getInstance().loginAs(id);
				setUserData(data);
			} catch (error) {}
		};
		getUserData();
	}, []);
	const studentUrl = process.env.REACT_APP_STUDENT_URL;

	const history = useHistory();
	const backToDashboard = () => {
		history.push('/dashboard/students');
	};
	return (
		<>
			<FlexLayout
				alignItems={'center'}
				gridGap="0.5rem"
				p={'1rem'}
				onClick={backToDashboard}
			>
				<EdButton
					variant="contained"
					startIcon={<EdIcon mIconType="Outlined">undo</EdIcon>}
				>
					Back to dashboard
				</EdButton>
				<Typography>Logged in as</Typography>
				<Typography fontWeight={'bold'}>
					{userData?.user.first_name} {userData?.user.last_name}
				</Typography>
			</FlexLayout>
			{userData && (
				<StyledIframe
					src={`${studentUrl}&studentData=${JSON.stringify(userData)}`}
				/>
			)}
		</>
	);
};

export default LoginAsStudent;

const StyledIframe = styled.iframe`
	width: 100vw;
	height: 100%;
`;
