import MainTable from '@Components/MainTable';
import { getNewsFeeds } from './Slices/NewsFeedSlice';
import { useTypedSelector } from '@Features/store';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';
import AddIcon from '@mui/icons-material/Add';
import { TableHeader } from '@Features/generics/generics.type';
import { useSnackbar } from '@Providers/useSnackbar';
import SidebarContentWrapper from '@Components/SidebarContentWrapper';
import useFilters from '@Hooks/useFilters';
import useSortByURL from '@Hooks/useSortBy';
import useQueryURL from '@Hooks/useQueryUrl';
import { TableCellStyled } from '@Pages/ClassRooms/Pages/SingleCourse/Pages/Users/EnrollmentStudent';
import {
	StyleAddBtn,
	StyleDeleteIcon,
} from '@Pages/ClassRooms/ClassRoomsTable';
import NoResults from '@Components/NoResults/NoResults';
import DateTimeFormatter from '@Components/DateTimeFormatter/DateTimeFormatter';
import { usePageLoader } from '@Providers/LoadingProvider';
import DeleteModel from './components/DeleteModel/DeleteModel';
import { deleteNewsFeedRequest } from './Services/NewsFeed.req';
import { ImgStyle } from '@Pages/Categories/CategoriesTable';
import { MainTableRow } from '@Components/MainTable/MainTable';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddNewsFeedButton from './components/AddNewsFeedButton';
import { onPageChangeRequestMeta } from '@Components/MainTable/MainTable.types';
import EdActiveStatus from '@Components/UI/Utilities/EdActiveStatus/EdActiveStatus';

const TABLE_HEADS: Array<TableHeader> = [
	{ displayName: 'CONTENT', fieldName: 'content' },
	{ displayName: 'REDIRECTION URL', fieldName: 'redirection_url' },
	{ displayName: 'PHOTO', fieldName: 'description', canSort: false },
	{ displayName: 'ACTIVE', fieldName: 'is_active', canSort: false },
	{ displayName: 'CREATED AT', fieldName: 'created_at' },
];

function NewFeeds() {
	const dispatch = useDispatch();
	const history = useHistory();
	const { newsfeeds, lastPage, feedsFilter, loaders, total, perPage, page } =
		useTypedSelector((state) => state.newsFeed);
	const [deletedId, setDeletedId] = useState<any>(null);
	const { displaySnackbar } = useSnackbar();
	const { setIsLoading } = usePageLoader();
	const [openModel, setOpenModel] = useState(false);
	const { selectedFilters } = useFilters();
	const { selectedSortBy } = useSortByURL();
	const { selectedQuery } = useQueryURL();
	const auth = useTypedSelector((state) => state.auth);

	const handleModelOpen = (id: number) => {
		setOpenModel(true);
		setDeletedId(id);
	};

	const handleModelClose = () => {
		setOpenModel(false);
		setDeletedId(null);
	};

	const onDeleteNewsfeed = useCallback(() => {
		setIsLoading(true);
		deleteNewsFeedRequest(deletedId)
			.then(() => {
				displaySnackbar('success', 'Delete Newsfeed successfully');
				dispatch(
					getNewsFeeds({
						page: page,
						perPage: perPage,
						sortBy: selectedSortBy?.direction === null ? {} : selectedSortBy,
						filters: selectedFilters,
						query: selectedQuery,
					})
				);
			})
			.catch((e: any) => {
				const msg = e.response?.data.message || 'Unable to Delete Newsfeed';
				displaySnackbar('error', msg);
			});
		setOpenModel(false);
		setIsLoading(false);
	}, [deletedId, dispatch, displaySnackbar, setIsLoading]);

	const onPageChangeRequest = useCallback(
		({
			activePage,
			endDate,
			filters,
			query,
			sortBy,
			startDate,
			perPage,
		}: onPageChangeRequestMeta) => {
			dispatch(
				getNewsFeeds({
					page: activePage,
					perPage: perPage ?? 10,
					filters,
					sortBy,
					query,
					from: startDate,
					to: endDate,
				})
			);
		},
		[]
	);
	useEffect(() => {
		setIsLoading(!!loaders.fetchAll);
	}, [setIsLoading, loaders.fetchAll]);

	return (
		<SidebarContentWrapper>
			{(auth.user.type === 'super' || auth.permissions.has('c-nws')) && (
				<div className="role_head__container">
					<AddNewsFeedButton />
				</div>
			)}

			<DeleteModel
				openModel={openModel}
				handleModelClose={handleModelClose}
				onConfirmation={onDeleteNewsfeed}
				word="Newsfeed"
			/>

			<MainTable
				onPageChangeRequest={onPageChangeRequest}
				totalPages={lastPage}
				tableFilters={feedsFilter}
				total={Number(total)}
				RowsperPage={Number(perPage)}
				pageNum={Number(page)}
				tableHeads={TABLE_HEADS}
				$hasActions
				renderItems={() => {
					return newsfeeds.length === 0 ? (
						<MainTableRow>
							<TableCell colSpan={TABLE_HEADS.length}>
								<NoResults />
							</TableCell>
						</MainTableRow>
					) : (
						newsfeeds.map((newsFeed) => (
							<MainTableRow
								key={newsFeed.id}
								hover
								sx={{
									cursor: 'pointer',
									'&:active': { backgroundColor: '#6c63ff' },
								}}
								onClick={() => {
									history.push({
										pathname: `/dashboard/newsfeed/edit/newsfeed/${newsFeed.id}`,
									});
								}}
							>
								<TableCellStyled>{newsFeed.content}</TableCellStyled>

								<TableCellStyled>
									<a href={`${newsFeed.redirection_url}`}>
										{newsFeed.redirection_url}
									</a>
								</TableCellStyled>

								<TableCellStyled>
									{(
										<ImgStyle src={newsFeed.photo} alt={newsFeed.description} />
									) || '-'}
								</TableCellStyled>

								<TableCellStyled>
									<EdActiveStatus active={newsFeed.is_active} />
								</TableCellStyled>

								<TableCellStyled>
									{newsFeed.created_at ? (
										<DateTimeFormatter date={newsFeed.created_at} />
									) : (
										'-'
									)}
								</TableCellStyled>

								<TableCell
									sx={{
										padding: '0 1.063rem 0',
										position: 'sticky',
										right: '0',
										background:
											'linear-gradient(260deg, white, white, transparent)',
									}}
								>
									{(auth.user.type === 'super' ||
										auth.permissions.has('d-nws')) && (
										<SpeedDial
											FabProps={{
												disableRipple: true,
												disableTouchRipple: true,
												focusRipple: false,
												disableFocusRipple: true,
												style: {
													borderRadius: '0',
													boxShadow: '0 0 0 0 ',
													backgroundColor: 'transparent',
													color: '#000',
												},
											}}
											direction="left"
											ariaLabel="SpeedDial basic example"
											icon={<MoreVertIcon />}
										>
											<SpeedDialAction
												sx={{
													'&:hover': {
														backgroundColor: 'rgba(90,192,252, 0.25)',
													},
												}}
												key="delete"
												icon={<StyleDeleteIcon />}
												tooltipTitle="Delete"
												id={String(newsFeed.id)}
												onClick={(e) => {
													e.stopPropagation();
													handleModelOpen(newsFeed.id);
												}}
											/>
										</SpeedDial>
									)}
								</TableCell>
							</MainTableRow>
						))
					);
				}}
			/>
		</SidebarContentWrapper>
	);
}

export default NewFeeds;
