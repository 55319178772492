import {
	CRUDBase,
	DeleteSingleEntityPayload,
	GetAllMeta,
	GetSingleEntityPayload,
} from 'src/base/services/services.base';
import { AxiosResponse } from 'axios';
import api from '@Services/api';
import {
	DocumentPayload,
	Document,
} from '@Pages/ClassRooms/Pages/SingleCourse/Pages/CourseForm/Pages/CourseContent/Pages/Documents/Types/Documents.types';

interface DocumentsCRUD extends CRUDBase<DocumentPayload, Document> {}
export class DocumentsRequester implements DocumentsCRUD {
	baseURL = 'document/';
	async export(payload: GetAllMeta): Promise<AxiosResponse<string>> {
		return await api.post(`${this.baseURL}all`, { ...payload, export: true });
	}

	private static instance: DocumentsRequester;

	static getInstance() {
		if (!DocumentsRequester.instance) {
			DocumentsRequester.instance = new DocumentsRequester();
		}
		return DocumentsRequester.instance;
	}

	getAll(payload?: GetAllMeta): Promise<AxiosResponse<ApiResponse<Document[]>>>;
	getAll(payload: number): Promise<AxiosResponse<ApiResponse<Document[]>>>;
	async getAll(
		payload?: any
	): Promise<import('axios').AxiosResponse<ApiResponse<Document[]>>> {
		return await api.post(`${this.baseURL}all`, payload);
	}

	get(id: string): Promise<AxiosResponse<ApiResponse<Document>>>;
	get(id: number): Promise<AxiosResponse<ApiResponse<Document>>>;
	get(
		id: string | number,
		payload: GetSingleEntityPayload
	): Promise<AxiosResponse<ApiResponse<Document>>>;
	async get(
		id: any,
		payload?: any
	): Promise<import('axios').AxiosResponse<ApiResponse<Document>>> {
		return await api.post(`${this.baseURL}get`, { id });
	}

	async create(
		payload: Omit<DocumentPayload, 'document_id'>
	): Promise<AxiosResponse<ApiResponse<Document>>> {
		return await api.post(`${this.baseURL}create`, payload);
	}
	async update(
		payload: DocumentPayload
	): Promise<AxiosResponse<ApiResponse<Document>>> {
		return await api.post(`${this.baseURL}update`, payload);
	}
	delete(id: number): Promise<AxiosResponse<ApiResponse<any>>>;
	delete(id: string): Promise<AxiosResponse<ApiResponse<any>>>;
	delete(
		id: string | number,
		payload: DeleteSingleEntityPayload
	): Promise<AxiosResponse<ApiResponse<any>>>;
	delete(
		id: any,
		payload?: any
	): Promise<import('axios').AxiosResponse<ApiResponse<any>>> {
		throw new Error('Method not implemented.');
	}
}
