import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { HashString } from '@Components/UI/Tabs/RoutedTabs/RoutedTabs.types';
import { Spacer } from '@Styled/utilities';
import RoutedTabs from '@Components/UI/Tabs/Tabs';
import AddAdminInfo from './Components/AddAdminInfo';
import { FormProvider, useForm } from 'react-hook-form';
import { ADD_ADMIN } from '@Pages/Admin/Schema/AdminsInfo.schema';
import { yupResolver } from '@hookform/resolvers/yup';
import AdminRoles from '@Pages/Admin/Components/AdminRoles';
import { Admin, AdminPayload } from '@Pages/Admin/Types/admin.types';
import { AdminRequester } from '@Pages/Admin/Services/AdminRequester/AdminRequester';
import { DASHBOARD_BASE_ROUTE } from '@Constants/routes';
import { useSnackbar } from '@Providers/useSnackbar';
import { RolesData } from '@Pages/Roles/Services/roles.res.types';
import AssignedRoles from '@Pages/Admin/Components/AssignedRoles';
import CasesOutlinedIcon from '@mui/icons-material/CasesOutlined';
import { DevTool } from '@hookform/devtools';
import { useTypedSelector } from '@Features/store';

const AddAdmin: React.FC = () => {
	const { hash } = useLocation();

	const [tabIndex, setTabIndex] = useState<HashString>(hash as HashString);
	const [hasRoles, setHasRoles] = useState(false);
	const [adminFormData, setAdminFormData] = useState<Admin | undefined>();
	const [isValid, setIsValid] = useState(false);
	const [assignedRoles, setAssignedRoles] = useState<RolesData[]>([]);
	const { displaySnackbar } = useSnackbar();

	const history = useHistory();

	const auth = useTypedSelector((state) => state.auth);

	const hasAssignRolePermission =
		auth.user.type === 'super' || auth.permissions.has('asg-rls');

	const onBasicInfoNext = (data: Admin) => {
		setAdminFormData(data);
		history.push({ hash: '#role' });
		setTabIndex('#role');
	};

	const { formState, getValues, control, watch, ...formMethods } =
		useForm<AdminPayload>({
			resolver: yupResolver(ADD_ADMIN),
			mode: 'all',
			defaultValues: {
				type: 'admin',
			},
		});

	const onSave = async (form: AdminPayload) => {
		try {
			const {
				data: { data },
			} = await AdminRequester.getInstance().create(form as AdminPayload);
			displaySnackbar('success', `${data.first_name} Created Successfully`);
			history.push({
				pathname: `${DASHBOARD_BASE_ROUTE}/admins/edit/admin/${data.uuid}`,
				hash: '#info',
			});
		} catch (e) {
			const msg = e.response?.data.message || 'Unable to Add Admin ';
			displaySnackbar('error', msg);
		}
	};

	return (
		<Container>
			<RoutedTabs>
				<RoutedTabs.Tabs
					$hashPrefix="#"
					hashes={['#info', '#role', '#assigned']}
					onChange={(e, value) => setTabIndex(value)}
					orientation="vertical"
					value={tabIndex}
				>
					<RoutedTabs.Item
						value="#info"
						label={<RoutedTabs.ItemTitle>Basic Info</RoutedTabs.ItemTitle>}
						icon={<RoutedTabs.ItemIcon>menu_book</RoutedTabs.ItemIcon>}
					/>
					{hasAssignRolePermission && (
						<RoutedTabs.Item
							value="#role"
							label={<RoutedTabs.ItemTitle>Roles</RoutedTabs.ItemTitle>}
							disabled={!hasRoles || !isValid}
							icon={
								<RoutedTabs.ItemIcon>
									<CasesOutlinedIcon fontSize="large" />
								</RoutedTabs.ItemIcon>
							}
						/>
					)}
					{hasAssignRolePermission && (
						<RoutedTabs.Item
							value="#assigned"
							label={
								<RoutedTabs.ItemTitle>
									{hasRoles && isValid && (
										<AssignedRoles assignedRoles={assignedRoles} />
									)}
								</RoutedTabs.ItemTitle>
							}
							disabled={true}
						/>
					)}
				</RoutedTabs.Tabs>
				<Spacer mx={''} />
				<DevTool control={control} />
				<RoutedTabs.Content value={tabIndex}>
					<RoutedTabs.ContentItem hash="#info">
						<AddAdminInfo
							control={control}
							formState={formState}
							getValues={getValues}
							onNext={onBasicInfoNext}
							watch={watch}
							onHasRolesChange={(val) => setHasRoles(val)}
							onIsValidChange={setIsValid}
						/>
					</RoutedTabs.ContentItem>
					<RoutedTabs.ContentItem hash="#role">
						<FormProvider
							control={control}
							getValues={getValues}
							watch={watch}
							formState={formState}
							{...formMethods}
						>
							<form onSubmit={formMethods.handleSubmit(onSave)}>
								<AdminRoles
									control={control}
									formState={formState}
									getValues={getValues}
									onSave={onSave}
									name="roles"
									setAssignedRoles={setAssignedRoles}
								/>
							</form>
						</FormProvider>
					</RoutedTabs.ContentItem>
				</RoutedTabs.Content>
			</RoutedTabs>
		</Container>
	);
};

const Container = styled.div`
	padding: 1.375rem;
	border-radius: 10px;
	border: 1px solid #5ac0fc;
`;

export default AddAdmin;
