import { BaseSliceController } from '@Base/slices/slices.base';
import { UnitsRequester } from '@Pages/ClassRooms/Pages/SingleCourse/Pages/CourseForm/Pages/CourseContent/Pages/Contents/Services/Unit.req';
import {
	Unit,
	UnitPayload,
} from '@Pages/ClassRooms/Pages/SingleCourse/Pages/CourseForm/Pages/CourseContent/Pages/Contents/Types/Units.types';

export class UnitSlice extends BaseSliceController<
	Unit,
	'Units',
	UnitPayload,
	Unit
> {
	static instance: UnitSlice;

	static getInstance() {
		if (!UnitSlice.instance) {
			UnitSlice.instance = new UnitSlice();
		}
		return UnitSlice.instance;
	}

	constructor() {
		super('Units', UnitsRequester.getInstance());
	}
}

export const {
	unitsUpdate,
	unitsDeleteById,
	unitsGetAll,
	unitsToggleActive,
	unitsAssignFetchAll,
} = UnitSlice.getInstance().getActions();
