import api from '@Services/api';
import { FetchRolesResponse } from '../Features/roleSilce.types';
import { GroupPermissions, Permissions, Role } from '../Types/Roles.types';
import {
	CreateRoleResponse,
	RolesData,
	SingleRoleResponse,
	UpdateRoleResponse,
} from './roles.res.types';
import { RolePayload } from './Roles.types';

export const getAllRolesRequest = async (
	page: number,
	perPage: number,
	filters: Array<any>,
	sortBy: any,
	query: string,
	from?: Date,
	to?: Date
) =>
	await api.post<ApiResponse<Role[]>>('role/fetch', {
		page,
		perPage,
		filters,
		sortBy,
		query,
		from,
		to,
	});

export const getSingleRoleRequest = async (id: number) =>
	await api.post<ApiResponse<Role>>('role/get', { id });

export const getPermissionsRequest = async (data: any) =>
	await api.get<ApiResponse<GroupPermissions[]>>('permission/all', data);

export const deleteRoleRequest = async (id: number) =>
	await api.delete('role/delete', {
		data: {
			id,
		},
	});

export const deactivateRole = async (id: number, active: boolean) =>
	await api.put('role/update', { id, active });

export const createRole = async (
	title: string,
	description: string,
	active: boolean,
	permissions: Array<number>
) =>
	await api.post<CreateRoleResponse>('role/create', {
		title,
		description,
		active,
		permissions,
	});

export const UpdateRole = async (
	id: number,
	title: string,
	description: string,
	permissions: Array<number>
) =>
	await api.put<UpdateRoleResponse>('role/update', {
		id,
		title,
		description,
		permissions,
	});
