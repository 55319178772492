import { BaseSliceController } from '@Base/slices/slices.base';
import { RechargeCode } from '@Pages/RechargeCodes/Types/RechargeCodes.types';
import { RechargeCodeTabRequester } from '../Services/RechargeCode.Requester';

export class RechargeCodeTabSlice extends BaseSliceController<
	RechargeCode,
	'RechargeCodeTab',
	RechargeCode
> {
	private static instance: RechargeCodeTabSlice;
	static getInstance() {
		if (!RechargeCodeTabSlice.instance) {
			RechargeCodeTabSlice.instance = new RechargeCodeTabSlice();
		}
		return RechargeCodeTabSlice.instance;
	}
	constructor() {
		super('RechargeCodeTab', RechargeCodeTabRequester.getInstance());
	}
}

export const { rechargeCodeTabGetAll } =
	RechargeCodeTabSlice.getInstance().getActions();
