import { RechargeCode } from './../Types/RechargeCodes.types';
import {
	CRUDBase,
	DeleteSingleEntityPayload,
	GetAllMeta,
	GetSingleEntityPayload,
} from 'src/base/services/services.base';
import { AxiosResponse } from 'axios';
import api from '@Services/api';
import { HeadersResponse } from '@Services/generics/response';

interface RechargeCodesCRUD extends CRUDBase<any, RechargeCode> {}
export class RechargeCodesRequester implements RechargeCodesCRUD {
	async export(payload: GetAllMeta): Promise<AxiosResponse<string>> {
		return await api.post(`${this.baseURL}all`, { ...payload, export: true });
	}
	private static instance: RechargeCodesRequester;
	static getInstance() {
		if (!RechargeCodesRequester.instance) {
			RechargeCodesRequester.instance = new RechargeCodesRequester();
		}
		return RechargeCodesRequester.instance;
	}
	baseURL = 'recharge-cards/';
	getAll(
		payload?: GetAllMeta
	): Promise<AxiosResponse<ApiResponse<RechargeCode[]>>>;
	getAll(payload: number): Promise<AxiosResponse<ApiResponse<RechargeCode[]>>>;
	async getAll(
		payload?: any
	): Promise<import('axios').AxiosResponse<ApiResponse<RechargeCode[]>>> {
		return await api.post(`${this.baseURL}all`, payload);
	}
	get(id: string): Promise<AxiosResponse<ApiResponse<RechargeCode>>>;
	get(id: number): Promise<AxiosResponse<ApiResponse<RechargeCode>>>;
	get(
		id: string | number,
		payload: GetSingleEntityPayload
	): Promise<AxiosResponse<ApiResponse<RechargeCode>>>;
	get(
		id: any,
		payload?: any
	): Promise<import('axios').AxiosResponse<ApiResponse<RechargeCode>>> {
		throw new Error('Method not implemented.');
	}
	create(
		payload: Omit<any, 'id'>
	): Promise<AxiosResponse<ApiResponse<RechargeCode>>> {
		throw new Error('Method not implemented.');
	}
	update(payload: any): Promise<AxiosResponse<ApiResponse<RechargeCode>>> {
		throw new Error('Method not implemented.');
	}
	delete(id: number): Promise<AxiosResponse<ApiResponse<any>>>;
	delete(id: string): Promise<AxiosResponse<ApiResponse<any>>>;
	delete(
		id: string | number,
		payload: DeleteSingleEntityPayload
	): Promise<AxiosResponse<ApiResponse<any>>>;
	delete(
		id: any,
		payload?: any
	): Promise<import('axios').AxiosResponse<ApiResponse<any>>> {
		return api.delete(`${this.baseURL}delete`, { data: { id } });
	}
}
