import EdCheckbox from '@Components/UI/Inputs/EdCheckbox/EdCheckbox';
import { useTypedSelector } from '@Features/store';
import { TabContext, TabPanel } from '@mui/lab';
import { Tab, Tabs, TextField } from '@mui/material';
import { Box } from '@mui/material/node_modules/@mui/system';
import { FlexLayout, Spacer } from '@Styled/utilities';
import React, { useState, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import { TestPayload } from '../../../../../Types/Test/Test.types';
import { StyledInfoLabel } from '../TestOptions.styled';

const TestOptionsRespondentsSection = () => {
	const { control, watch, setValue } = useFormContext<TestPayload>();
	const showCorrectAnswer = watch('showCorrectAnswer');
	const addMessage = watch('passOrFailMessage');
	const [valueMessage, setValueMessage] = useState<number>(1);
	const {
		currentEntity: { active },
	} = useTypedSelector((state) => state.Tests);
	useEffect(() => {
		if (!showCorrectAnswer) {
			setValue('test.show_correct_if_passed', false);
			setValue('test.show_correct_if_failed', false);
		}
	}, [showCorrectAnswer]);

	return (
		<>
			<StyledInfoLabel> Info for respondents</StyledInfoLabel>
			<FlexLayout px={'1rem'} flexDirection={'column'}>
				<EdCheckbox
					disabled={active}
					control={control}
					name="test.show_score_percentage"
					label="Score in percentages"
				/>
				<EdCheckbox
					disabled={active}
					control={control}
					name="test.show_status"
					label="Show Status"
				/>
				<EdCheckbox
					disabled={active}
					control={control}
					name="test.show_score_value"
					label="Score in points"
				/>
				<EdCheckbox
					disabled={active}
					control={control}
					name="test.show_grade"
					label="Grade range"
				/>
				<EdCheckbox
					disabled={active}
					control={control}
					name="test.test_summary"
					label="Show test summary"
				/>
				<FlexLayout>
					<EdCheckbox
						disabled={active}
						label="Correct answers"
						control={control}
						name="showCorrectAnswer"
					/>
					<Spacer mr="4.375rem" />
					{showCorrectAnswer && (
						<FlexLayout width="350px" justifyContent="space-between">
							<EdCheckbox
								disabled={active}
								control={control}
								name="test.show_correct_if_passed"
								label="Show if passed"
							/>
							<EdCheckbox
								disabled={active}
								control={control}
								name="test.show_correct_if_failed"
								label="Show if failed"
							/>
						</FlexLayout>
					)}
				</FlexLayout>
				<EdCheckbox
					disabled={active}
					control={control}
					label="Pass or fail message"
					name="passOrFailMessage"
				/>
				<Spacer mb="1.375rem" />
				{addMessage && (
					<FlexLayout
						width="23.063rem"
						flexDirection={'column'}
						overflow="hidden"
					>
						<TabContext value={valueMessage.toString()}>
							<Box
								sx={{ borderBottom: 1, borderColor: 'divider' }}
								width="23.063rem"
							>
								<StyledTabList
									onChange={(e, v) => setValueMessage(v)}
									tabIndex={valueMessage}
									value={valueMessage}
								>
									<StyledTab label={<TabMessage isPassed />} value="1" />
									<StyledTab label={<TabMessage />} value="2" />
								</StyledTabList>
							</Box>
							<Spacer mb="0.688rem" />
							<FlexLayout>
								<StyledTabPanel value={'1'} tabIndex={valueMessage}>
									<>
										<Controller
											control={control}
											name="test.message_if_passed"
											render={({ field, fieldState: { error } }) => {
												return (
													<TextField
														disabled={active}
														multiline
														minRows={3}
														maxRows={3}
														fullWidth
														{...field}
													/>
												);
											}}
										/>
									</>
								</StyledTabPanel>
								<StyledTabPanel value={'2'} tabIndex={valueMessage}>
									<Controller
										control={control}
										name="test.message_if_failed"
										render={({ field, fieldState: { error } }) => {
											return (
												<TextField
													disabled={active}
													multiline
													minRows={3}
													maxRows={3}
													{...field}
													fullWidth
												/>
											);
										}}
									/>
								</StyledTabPanel>
							</FlexLayout>
						</TabContext>
					</FlexLayout>
				)}
			</FlexLayout>
		</>
	);
};

export default TestOptionsRespondentsSection;

const StyledTab = styled(Tab).attrs({
	disableRipple: true,
})`
	min-height: unset;
	font-size: 0.875rem;
	padding: 0;
	margin-bottom: 6px;
	color: #000;

	&.Mui-selected {
		color: ${(props) => props.theme.palette.dodgerBlue.main} !important;
	}
`;

const StyledTabList = styled(Tabs)`
	min-height: unset;

	.MuiTabs-flexContainer {
		justify-content: space-between;
	}

	.MuiTabs-indicator {
		background-color: ${(props) =>
			props.theme.palette.dodgerBlue.main} !important;
	}
`;

const TabMessage: React.FC<{ isPassed?: boolean }> = ({ isPassed }) => {
	return (
		<FlexLayout>
			<StyledMessageLabel>Message</StyledMessageLabel>
			<Spacer mx="0.1rem" />
			<StyledMessageSubLabel>
				(if {isPassed ? 'passed' : 'failed'})
			</StyledMessageSubLabel>
		</FlexLayout>
	);
};

const StyledMessageSubLabel = styled.div`
	font-size: 0.875rem;
	text-transform: none;
	color: inherit;
`;
const StyledMessageLabel = styled(StyledMessageSubLabel)`
	font-weight: 600;
`;

const StyledTabPanel = styled(TabPanel)`
	padding: 0;
	width: 100%;
`;
