import { Tag } from '@Pages/ClassRooms/Pages/SingleCourse/Pages/CourseForm/Pages/CourseContent/Pages/Test/Types/TestQuestion';
import api from '@Services/api';
import {
	DropdownsInstructorResponseType,
	DropdownsCategoriesResponseType,
	DropdownsBatchesResponseType,
	DropdownsClassroomsResponseType,
	DropdownsCoursesResponseType,
	DropdownsUnitsResponseType,
	DropdownsTestAttemptsResponseType,
} from '@Services/dropdowns/dropdowns.api.types';

export const fetchInstructorsDropdownRequest = () =>
	api.get<DropdownsInstructorResponseType>('dropdown/instructors');

export const fetchCategoriesDropdownRequest = () =>
	api.get<DropdownsCategoriesResponseType>('dropdown/categories');

export const fetchBatchesDropdownRequest = () =>
	api.get<DropdownsBatchesResponseType>('dropdown/batches');
export const fetchWalletBatchesDropdownRequest = () =>
	api.get<DropdownsBatchesResponseType>('dropdown/recharge-cards-batches');

export const fetchClassroomsDropdownRequest = () =>
	api.get<DropdownsClassroomsResponseType>('dropdown/classrooms');

	export const fetchClassroomsByInstructorDropdownRequest = (iId : number) =>
	api.get<DropdownsClassroomsResponseType>(`dropdown/classrooms/${iId}`);

export const fetchCoursesDropdownRequest = (classroomId: number | undefined) =>
	api.get<DropdownsCoursesResponseType>(`dropdown/courses/${classroomId}`);

export const fetchUnitsDropdownRequest = (courseId: number | undefined) =>
	api.get<DropdownsUnitsResponseType>(`dropdown/units/${courseId}`);

export const fetchTestAttemptsDropdownRequest = (
	unitId: number | undefined,
	userId?: number | undefined
) =>
	api.get<DropdownsTestAttemptsResponseType>(
		`dropdown/test/attempts/${unitId}/${userId}`
	);

export const fetchTagsDropdownRequest = () =>
	api.get<ApiResponse<Tag[]>>(`dropdown/tags`);

export const searchStudentsRequest = (query: string) =>
	api.post<
		ApiResponse<
			{
				email: string;
				first_name: string;
				id: number;
				last_name: string;
				phone_number: string;
				username: string;
			}[]
		>
	>(`user/search`, {
		query,
	});
