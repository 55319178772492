import React, { FC } from 'react';
import styled from 'styled-components';
import AttendanceCount from './Components/AttendanceCount';
import AttendanceTable from './Components/AttendanceTable';
import { AttendancesCount } from './Types/Attendaces.types';

type Props = {
	count: AttendancesCount;
	id: number;
};

const Attendances: FC<Props> = ({ count, id }) => {
	return (
		<>
			<Header>Attendances</Header>
			<AttendanceCount count={count} />
            <AttendanceTable id={Number(id)} />
		</>
	);
};

const Header = styled.p`
	font-size: 1.125rem;
	font-weight: bold;
	color: #6c63ff;
	margin-bottom: 1.063rem;
`;

export default Attendances;
