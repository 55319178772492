import React, { useEffect } from 'react';
import { CardContainer } from '@Pages/ClassRooms/Pages/AddClassroom/AddClassroom';
import EditRoleInfo from './Components/EditRoleInfo';
import { getSingleRole } from '@Pages/Roles/Features/rolesSlice';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from '@Features/store';
import { useParams } from 'react-router-dom';

const EditRole = () => {
	const dispatch = useDispatch();
	const { currentRoles } = useTypedSelector((state) => state.roles);
	const { id } = useParams<{ id: string }>();
	useEffect(() => {
		if (id)
			dispatch(
				getSingleRole({
					id: Number(id),
				})
			);
	}, [id]);

	return <CardContainer>{currentRoles && <EditRoleInfo />}</CardContainer>;
};

export default EditRole;
