import { CourseReportGraphPayload } from './../Types/CourseOverviewReport.types';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
	CourseGraphPayload,
	GraphSlice,
} from '@Services/classrooms/classrooms.res.types';
import CourseOverviewReportRequester from '../Services/CourseOverviewReport.requester';
import { CourseOverviewStats } from '../Types/CourseOverviewReport.types';

export type ReportData = {
	enrolled_users: number;
	completed_users: number;
	not_started_users: number;
	in_progress_users: number;
	completed_percentage: number | null;
	not_started_percentage: number | null;
	in_progress_percentage: number | null;
	average_test_score: number | null;
};
export type ReportPieChartData = {
	completed_percentage: PieChartNode;
	not_started_percentage: PieChartNode;
	in_progress_percentage: PieChartNode;
};
export type PieChartNode = {
	id: string | number;
	value: number;
	label: string;
	color?: string;
};
export type CourseOverviewReportState = {
	loaders: {
		report: StateLoader;
		graph: StateLoader;
	};
	graph?: GraphSlice[];
	report?: ReportData;
};
export type CourseGraphIds = 'enrollment' | 'completed';
type CourseGraphConfigArgs = {
	[key in CourseGraphIds]: string;
};
export const CourseGraphConfig: CourseGraphConfigArgs = {
	completed: '#ff6383',
	enrollment: '#36a2eb',
};
const initialState: CourseOverviewReportState = {
	loaders: {
		report: 'idle',
		graph: 'idle',
	},
};

export const getCourseOverviewReport = createAsyncThunk<
	CourseOverviewStats,
	number
>('reports/course-report', async (args) => {
	const { data } = await CourseOverviewReportRequester.instance.getReport(args);
	return data.data;
});

export const getCourseReportGraph = createAsyncThunk<
	GraphSlice[],
	CourseReportGraphPayload
>('reports/course-graph', async (args: CourseReportGraphPayload) => {
	const { data } = await CourseOverviewReportRequester.instance.getGraph(args);
	return data.data;
});
const courseOverviewSlice = createSlice({
	name: 'reports/course-overview',
	initialState,
	reducers: {},
	extraReducers(builder) {
		builder
			.addCase(getCourseOverviewReport.pending, (state) => {
				state.loaders.report = 'pending';
			})
			.addCase(getCourseOverviewReport.fulfilled, (state, payload) => {
				state.report = payload.payload;
				state.loaders.report = 'idle';
			})
			.addCase(getCourseOverviewReport.rejected, (state) => {
				state.loaders.report = 'rejected';
			});
		builder
			.addCase(getCourseReportGraph.pending, (state) => {
				state.loaders.graph = 'pending';
			})
			.addCase(getCourseReportGraph.fulfilled, (state, payload) => {
				state.graph = payload.payload;
				state.loaders.graph = 'idle';
			})
			.addCase(getCourseReportGraph.rejected, (state) => {
				state.loaders.graph = 'rejected';
			});
	},
});

export default courseOverviewSlice.reducer;
