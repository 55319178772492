import { useTypedSelector } from '@Features/store';
import { CardContainer } from '@Pages/ClassRooms/Pages/AddClassroom/AddClassroom';
import { getSingleInstructor } from '@Pages/Instructors/Slices/instructorsSlice';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import EditInstructoInfo from './Components/EditInstructoInfo';

const EditInstructor = () => {
	const dispatch = useDispatch();
	const { id: uuid } = useParams<{ id: string }>();

	const {
		currentInstructor,
		loaders: { fetchOne },
	} = useTypedSelector((state) => state.instructor);

	useEffect(() => {
		if (uuid) {
			dispatch(getSingleInstructor({ uuid }));
		}
	}, []);

	return (
		<CardContainer>
			{currentInstructor && fetchOne === 'fulfilled' && <EditInstructoInfo />}
		</CardContainer>
	);
};

export default EditInstructor;
