import React from 'react';
import { Stack } from '@mui/material';
import { CancelBtn, SaveBtn } from '../pages/AddRole/Components/addRoleInfo';
import { FormState } from 'react-hook-form';
import { EditForm } from '../schema/RoleInfo.schema';
import { useHistory } from 'react-router-dom';

type Props = {
	getValues?: any;
	onSave?: (form: EditForm) => void;
	formState: FormState<EditForm>;
};

const GroupedBtns: React.FC<Props> = ({ getValues, onSave, formState }) => {
	const history = useHistory();
	return (
		<>
			<Stack sx={{ width: '57.8rem' }} spacing={2} direction="row-reverse">
				<SaveBtn
					variant="contained"
					onClick={() => {
						if (onSave && getValues) {
							const data = getValues();
							onSave(data);
						}
					}}
					type="submit"
					disabled={!formState.isValid || !formState.isDirty}
				>
					SAVE
				</SaveBtn>
				<CancelBtn
					onClick={() => history.push('/dashboard/roles')}
					disabled={!formState.isDirty}
					variant="contained"
				>
					CANCEL
				</CancelBtn>
			</Stack>
		</>
	);
};

export default GroupedBtns;
