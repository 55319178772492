import { useTypedSelector } from '@Features/store';
import { FlexLayout } from '@Styled/utilities';
import React, { FC, useMemo } from 'react';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import IconButton from '@mui/material/IconButton';

type Props = {
	setAttemptId: any;
	attemptId: number | undefined;
};

const PreviousNextSummary: FC<Props> = ({ setAttemptId, attemptId }) => {
	const attempts = useTypedSelector(
		(state) => state.dropdowns.dropdownTestAttempts
	);
	const { currentEntity } = useTypedSelector((state) => state.TestSummary);

	const slotIndex = useMemo(() => {
		if (!currentEntity || !attempts) return;
		return attempts?.findIndex((_) => _.id === attemptId);
	}, [currentEntity, attemptId, attempts]);

	const handleBackButtonClick = () => {
		if (slotIndex === undefined) return;
		if (!attempts) return;
		const prevAttempt = attempts[slotIndex - 1];
		setAttemptId(prevAttempt?.id);
	};

	const handleNextButtonClick = () => {
		if (slotIndex === undefined) return;
		if (!attempts) return;
		const attemptIndex = slotIndex + 1;
		const nextAttempt = attempts[attemptIndex];
		setAttemptId(nextAttempt.id);
	};

	const isLast = useMemo(() => {
		if (!currentEntity || !attempts) return;
		const ind = attempts?.findIndex((_) => _.id === attemptId);
		return ind === attempts?.length - 1;
	}, [currentEntity, attempts, attemptId]);

	const isFirst = useMemo(() => {
		if (!currentEntity || !attempts) return;
		const ind = attempts?.findIndex((_) => _.id === attemptId);
		return ind === 0;
	}, [currentEntity, attempts, attemptId]);

	return (
		<FlexLayout
			alignItems="center"
			justifyContent="space-between"
			width="39.188rem"
			margin="auto"
		>
			<IconButton
				disabled={isFirst}
				onClick={handleBackButtonClick}
				aria-label="previous page"
			>
				<ArrowBackIosNewIcon />
			</IconButton>
			<IconButton
				disabled={isLast}
				onClick={handleNextButtonClick}
				aria-label="next page"
			>
				<ArrowForwardIosIcon />
			</IconButton>
		</FlexLayout>
	);
};

export default PreviousNextSummary;
