import { RouteGroup, routes } from '@Constants/routes';
import React, { useMemo } from 'react';
import { map } from 'lodash';
const useRouteGroup = (baseRoute?: string) => {
	return useMemo(() => {
		let _routeGroups = map(routes, 'routeGroups').flat(1);
		const routeGroup = _routeGroups.find((_) => _.route === baseRoute);
		
		return routeGroup;
	}, [baseRoute]);
};

export default useRouteGroup;
