import EdAnimatedIcon from '@Components/UI/Utilities/EdAnimatedIcon/EdAnimatedIcon';
import EdIcon from '@Components/UI/Utilities/EdIcon/EdIcon';
import { useTypedSelector } from '@Features/store';
import {
	Checkbox,
	FormControlLabel,
	IconButton,
	TextField,
} from '@mui/material';
import { FlexLayout, Spacer } from '@Styled/utilities';
import React, { FC } from 'react';
import { Control, Controller, UseFieldArrayRemove } from 'react-hook-form';
import styled from 'styled-components';
import { TypedTestQuestionPayload } from '../../../Types/TestQuestion';

type Props = {
	index: number;
	control: Control<TypedTestQuestionPayload<'mcq'>>;
	remove: UseFieldArrayRemove;
	canRemoveOption: boolean;
};
const MCQOption: FC<Props> = ({ control, index, remove, canRemoveOption }) => {
	const {
		currentEntity: { active },
	} = useTypedSelector((state) => state.Tests);
	return (
		<StyledFlexLayout mb="2rem">
			<Controller
				control={control}
				name={`multipleChoiceOptions.${index}.choice`}
				render={({ field, fieldState }) => {
					return (
						<TextField
							disabled={active}
							error={fieldState.invalid}
							helperText={fieldState.error?.message}
							size="small"
							{...field}
						/>
					);
				}}
			/>
			<Spacer mx="0.5rem" />
			<Controller
				control={control}
				name={`multipleChoiceOptions.${index}.is_correct`}
				render={({ field: { onChange, value, ...props } }) => {
					return (
						<MCQOptionCheckbox
							disabled={active}
							checked={value}
							{...props}
							control={<Checkbox onChange={(e, v) => onChange(v)} />}
							sx={{ color: '#00e1ff' }}
							label="Correct"
							labelPlacement="end"
						/>
					);
				}}
			/>
			{canRemoveOption && !active && (
				<IconButton onClick={() => remove(index)}>
					<EdIcon>delete</EdIcon>
				</IconButton>
			)}
		</StyledFlexLayout>
	);
};

export default MCQOption;

const MCQOptionCheckbox = styled(FormControlLabel)`
	color: #000;
	.Mui-checked {
		/* color: ${(props) => props.theme.palette.success.main}; */
	}
`;

const StyledFlexLayout = styled(FlexLayout)`
	width: fit-content;
	.MuiIconButton-root {
		visibility: hidden;
	}
	:hover {
		.MuiIconButton-root {
			visibility: visible;
		}
	}
`;
