import api from '@Services/api';
import {
	GetInstructorsResponse,
	GetInstructorResponse,
	CreateInstructorResponse,
	UpdateInstructorResponse,
} from './instructors.res.types';
import {
	getReqType,
	createReqType,
	updateReqType,
} from './instructor.req.types';
import {
	CRUDBase,
	DeleteSingleEntityPayload,
	GetAllMeta,
	GetSingleEntityPayload,
} from 'src/base/services/services.base';
import {
	InstructorPayload,
	InstructorResponse,
} from '../Types/Instructor.types';
import { AxiosResponse } from 'axios';

export class InstructorRequester
	implements CRUDBase<InstructorPayload, InstructorResponse>
{
	baseURL?: string | undefined;

	private static instance: InstructorRequester = new InstructorRequester();
	static getInstance(): InstructorRequester {
		if (InstructorRequester.instance) {
			InstructorRequester.instance = new InstructorRequester();
		}
		return InstructorRequester.instance;
	}

	getAll(
		payload?: GetAllMeta
	): Promise<AxiosResponse<ApiResponse<InstructorResponse[]>>>;
	getAll(
		payload: number
	): Promise<AxiosResponse<ApiResponse<InstructorResponse[]>>>;
	async getAll(
		payload?: any
	): Promise<AxiosResponse<ApiResponse<InstructorResponse[]>>> {
		return await api.post('instructor/all', payload);
	}
	get(id: string): Promise<AxiosResponse<ApiResponse<InstructorResponse>>>;
	get(id: number): Promise<AxiosResponse<ApiResponse<InstructorResponse>>>;
	get(
		id: any,
		payload: GetSingleEntityPayload
	): Promise<AxiosResponse<ApiResponse<InstructorResponse>>>;
	async get(
		id: string | number,
		payload?: any
	): Promise<AxiosResponse<ApiResponse<InstructorResponse>>> {
		return await api.post('instructor', payload);
	}

	async create(
		payload: InstructorPayload
	): Promise<AxiosResponse<ApiResponse<InstructorResponse>>> {
		return await api.post('instructor/create', payload);
	}
	async update(
		payload: InstructorPayload
	): Promise<AxiosResponse<ApiResponse<InstructorResponse>>> {
		return await api.put('instructor/update', payload);
	}

	delete(id: number): Promise<AxiosResponse<ApiResponse<any>>>;
	delete(id: string): Promise<AxiosResponse<ApiResponse<any>>>;
	delete(
		id: string | number,
		payload: DeleteSingleEntityPayload
	): Promise<AxiosResponse<ApiResponse<any>>>;
	async delete(
		id: any,
		payload?: any
	): Promise<AxiosResponse<ApiResponse<any>>> {
		return await api.delete('instructor/delete', {
			data: payload,
		});
	}
}

// export const getAllInstructors = async (
//   page: number,
//   perPage: number,
//   filters: Array<any>,
//   sortBy: any,
//   query: string,
//   from?:Date,
//   to?:Date
// ) =>
//   await api.post<ApiResponse<GetInstructorsResponse[]>>("instructor/all", {
//     page,
//     perPage,
//     filters,
//     sortBy: Object.keys(sortBy).length > 0 ? sortBy : undefined,
//     query,
//     from,
//     to,
//   });

export const getAllInstructors = async (args: GetAllMeta) => {
	return await api.post<ApiResponse<InstructorResponse[]>>(
		'instructor/all',
		args
	);
};

export const CreateInstructor = async (instructor: any) =>
	await api.post<ApiResponse<InstructorResponse>>(
		'instructor/create',
		instructor
	);

export const UpdateInstructor = async (instructor: updateReqType) =>
	await api.put<UpdateInstructorResponse>('instructor/update', instructor);

export const getInstructor = async (uuid: string) =>
	await api.post<GetInstructorResponse>('instructor', { uuid });

export const activateInstructor = async (id: number) =>
	await api.post('instructor/activate', { id });

export const deactivateInstructor = async (id: number) =>
	await api.post('instructor/deactivate', { id });
