import React, { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useImmer } from 'use-immer';
import EducationalInfoMultiSelect from '@Components/EducationalInfoMultiSelect';
import {
	EducationalInfoType,
	validateEducationalInfo,
} from '@Utils/educationInfo';
import * as yup from 'yup';

import {
	Button,
	Card,
	CardBody,
	CardHeader,
	Col,
	Container,
	FormGroup,
	Input,
	Label,
	Row,
} from 'reactstrap';
import FormAlert from '@Pages/Instructors/FormAlert';
import { InstructorFormMode } from '@Pages/Instructors/instructor.types';
import {
	CreateInstructor,
	UpdateInstructor,
} from '@Services/instructors/instructors.req';
import { useDispatch } from 'react-redux';
import {
	fetchAllInstructors,
	fetchInstructor,
} from '@Features/instructors/instructorsSlice';
import { useForm } from 'react-hook-form';
import { usePageLoader } from '@Providers/LoadingProvider';
import { useTypedSelector } from '@Features/store';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import SidebarContentWrapper from '@Components/SidebarContentWrapper';
import ClassroomCard from '@Pages/Instructors/ClassroomCard/ClassroomCard';
import { ClassroomsContainer } from '@Pages/Instructors/ClassroomsContainer.styled';
import { RequiredMark } from '@Pages/Instructors/instructorForm.styled';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import qs from 'querystring';
import {
	educationalsections,
	educationaltype,
	getEducationalInfo,
} from '@Features/classrooms/educationalSlice';
import { useSnackbar } from '@Providers/useSnackbar';
const CustomAlert = withReactContent(Swal);
interface RequiredProps {
	mode: InstructorFormMode;
}

export const RequiredAsterisk: React.FC<RequiredProps> = ({ mode }) => {
	if (mode === InstructorFormMode.ADD) return <RequiredMark> *</RequiredMark>;
	else return null;
};

interface Props {
	mode: InstructorFormMode;
}

interface UrlParams {
	id: string;
}

const InstructorForm: React.FC<Props> = ({ mode }) => {
	const snackBar = useSnackbar();

	const [isPasswordRequired, setIsPasswordRequired] = useState<boolean>(
		mode === InstructorFormMode.ADD
	);

	const [educationalInfoState, setEducationalInfoState] =
		useImmer<EducationalInfoType>({
			educationTypes: [],
			educationYears: [],
			educationLanguages: [],
			educationSections: [],
		});
	const [showEducationalSections, setShowEducationalSections] =
		useState<boolean>(false);

	const phoneRegExp =
		/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
	let schema = null;
	if (isPasswordRequired) {
		schema = yup.object().shape({
			firstname: yup.string().required(),
			lastname: yup.string().required(),
			email: yup
				.string()
				.required('email is required')
				.email('Email is not valid')
				.max(255),
			phone_number: yup
				.string()
				.required('Phone Number is required')
				.matches(phoneRegExp, 'Phone number is not valid')
				.max(11),
			branch_id: yup.string().required('branch id is required'),
			gender: yup.string().required('gender is required'),
			password: yup.string().required(),
			profile_photo: yup
				.string()
				.nullable()
				.notRequired()
				.when({
					is: (value: any) => value?.length,
					then: (rule) => rule.url(),
				}),
			branch_name: yup.string().required(),
			label: yup.string().required('Label is Required'),
			weight: yup
				.number()
				.transform((cv) => (isNaN(cv) ? undefined : cv))
				.typeError('weight should be a number')
				.moreThan(0)
				.nullable()
				.notRequired()
				.when({
					is: (value: any) => value?.length,
					then: (rule) =>
						rule.typeError('weight should be a number').moreThan(0),
				}),
		});
	} else {
		schema = yup.object().shape({
			firstname: yup.string().required(),
			lastname: yup.string().required(),
			email: yup
				.string()
				.required('email is required')
				.email('Email is not valid')
				.max(255),
			phone_number: yup
				.string()
				.required('Phone Number is required')
				.matches(phoneRegExp, 'Phone number is not valid')
				.max(11),
			gender: yup.string().required('gender is required'),
			branch_id: yup.string().required('branch id is required'),
			profile_photo: yup
				.string()
				.nullable()
				.notRequired()
				.when({
					is: (value: any) => value?.length,
					then: (rule) => rule.url(),
				}),
			weight: yup
				.number()
				.transform((cv) => (isNaN(cv) ? undefined : cv))
				.typeError('weight should be a number')
				.moreThan(0)
				.nullable()
				.notRequired()
				.when({
					is: (value: any) => value?.length,
					then: (rule) =>
						rule.typeError('weight should be a number').moreThan(0),
				}),

			branch_name: yup.string().required(),
			label: yup.string().required('Label is Required'),
		});
	}
	const { register, handleSubmit, getValues, formState, setValue, watch } =
		useForm({
			mode: 'onTouched',
			resolver: yupResolver(schema),
		});
	const dispatch = useDispatch();
	const history = useHistory();
	const location = useLocation();
	const { id } = useParams<UrlParams>();
	const { setIsLoading } = usePageLoader();
	const { selectedInstructor } = useTypedSelector((state) => state.instructor);
	const [buttonText, setButtonText] = useState('');

	const [alert, setAlert] = useState({
		isOpen: false,
		message: '',
		type: 'info',
	});

	const { educational_year, educational_language, educational_sections } =
		useTypedSelector((state) => state.educational);

	const watchType = watch('educationTypes');
	const watchYear = watch('educationYears');
	useEffect(() => {
		if (watchType) {
			dispatch(educationaltype(watchType));
		}
	}, [dispatch, JSON.stringify(watchType)]);

	useEffect(() => {
		if (watchType && selectedInstructor) {
			dispatch(educationaltype(watchType));
		}
	}, [dispatch, JSON.stringify(watchType), selectedInstructor]);

	useEffect(() => {
		if (watchYear) {
			dispatch(educationalsections(watchYear));
		}
	}, [JSON.stringify(watchYear), dispatch]);

	useEffect(() => {
		if (educational_year.length > 0 && selectedInstructor) {
			setValue(
				'educationYears',
				selectedInstructor.educationYears.map((_) => _.id?.toString())
			);
		}
	}, [selectedInstructor, educational_year, setValue]);

	useEffect(() => {
		if (educational_language.length > 0 && selectedInstructor) {
			setValue(
				'educationLanguages',
				selectedInstructor.educationLanguages.map((_) => _.id?.toString())
			);
		}
	}, [selectedInstructor, educational_language, setValue]);

	useEffect(() => {
		if (educational_sections.length > 0 && selectedInstructor) {
			setValue(
				'educationSections',
				selectedInstructor.educationSections.map((_) => _.id?.toString())
			);
		}
	}, [selectedInstructor, educational_sections, setValue]);

	useEffect(() => {}, [watchYear]);

	useEffect(() => {
		dispatch(getEducationalInfo());
	}, []);

	useEffect(() => {
		setValue('selectedSection', educational_sections.length > 0);
	}, [educational_sections, setValue]);
	// form values side effect
	useEffect(() => {
		if (selectedInstructor && mode === InstructorFormMode.UPDATE) {
			setValue('username', selectedInstructor?.username ?? '', {
				shouldValidate: true,
			});
			setValue('password', '', { shouldValidate: true });
			setValue('email', selectedInstructor?.email ?? '', {
				shouldValidate: true,
			});
			setValue('firstname', selectedInstructor?.first_name ?? '', {
				shouldValidate: true,
			});
			setValue('lastname', selectedInstructor?.last_name ?? '', {
				shouldValidate: true,
			});
			setValue('phone_number', selectedInstructor?.phone_number ?? '', {
				shouldValidate: true,
			});
			setValue('label', selectedInstructor?.label ?? '', {
				shouldValidate: true,
			});
			setValue('branch_id', selectedInstructor?.branch_id ?? '', {
				shouldValidate: true,
			});
			setValue('branch_name', selectedInstructor?.branch_name ?? '', {
				shouldValidate: true,
			});
			setValue('weight', selectedInstructor?.weight ?? '', {
				shouldValidate: true,
			});
			setValue('gender', selectedInstructor?.gender || '');
			setValue('profile_photo', selectedInstructor?.profile_photo);
			setValue('bio', selectedInstructor?.bio);
			setValue(
				'educationTypes',
				selectedInstructor?.educationTypes.map((_) => _.id?.toString())
			);
		}
		return () => {
			setValue('username', '', { shouldValidate: false });
			setValue('password', '', { shouldValidate: false });
			setValue('email', '', { shouldValidate: false });
			setValue('firstname', '', { shouldValidate: false });
			setValue('lastname', '', { shouldValidate: false });
			setValue('phone_number', '', { shouldValidate: false });
			setValue('label', '');
			setValue('branch_id', '');
			setValue('branch_name', '', { shouldValidate: false });
			setValue('weight', '', { shouldValidate: false });
			setValue('gender', 'male');
			setValue('bio', '', { shouldValidate: false });
			setValue('educationTypes', '', { shouldValidate: false });
			setValue('educationYears', '', { shouldValidate: false });
			setValue('educationLanguages', '', { shouldValidate: false });
			setValue('educationSections', '', { shouldValidate: false });
		};
	}, [selectedInstructor]);

	useEffect(() => {
		if (mode === InstructorFormMode.ADD) {
			setButtonText('Add Instructor');
		} else if (mode === InstructorFormMode.UPDATE) {
			setButtonText('Update Instructor');
		}
		setIsPasswordRequired(mode === InstructorFormMode.ADD);
	}, [mode]);

	useEffect(() => {
		if (mode === InstructorFormMode.UPDATE)
			dispatch(fetchInstructor({ uuid: id }));
	}, [id]);

	useEffect(() => {
		if (selectedInstructor) {
			setEducationalInfoState((draft) => {
				draft.educationTypes = Object.values(
					selectedInstructor.educationTypes
				).map((value) => value.id) as unknown as number[];
				draft.educationYears = Object.values(
					selectedInstructor.educationYears
				).map((value) => value.id) as unknown as number[];
				draft.educationLanguages = Object.values(
					selectedInstructor.educationLanguages
				).map((value) => value.id) as unknown as number[];
				draft.educationSections = Object.values(
					selectedInstructor.educationSections
				).map((value) => value.id) as unknown as number[];
			});
		}
	}, [selectedInstructor]);

	const onSubmit = async (data: any) => {
		if (
			!validateEducationalInfo(educationalInfoState, showEducationalSections)
		) {
			snackBar.displaySnackbar('error', 'educational info validation error');
			return;
		}

		if (!showEducationalSections) {
			setEducationalInfoState((draft) => {
				delete draft.educationSections;
			});
		}

		if (mode === InstructorFormMode.ADD) {
			// handle add request
			const requestBody: any = {
				email: data.email,
				password: data.password,
				phone_number: data.phone_number,
				first_name: data.firstname,
				last_name: data.lastname,
				instructor: {
					branch_id: data.branch_id,
					branch_name: data.branch_name,
					label: data.label,
					educationTypes: data.educationTypes,
					educationYears: data.educationYears,
					educationLanguages: data.educationLanguages,
					educationSections: data.educationSections,
				},
			};
			if (data.weight !== '')
				requestBody.instructor.weight = String(data?.weight);
			if (data.bio) requestBody.instructor.bio = data.bio;
			if (data.profile_photo) requestBody.profile_photo = data.profile_photo;
			if (data.gender !== '') requestBody.gender = data.gender;

			let tempButtonText = buttonText;
			setButtonText('Creating user...');
			try {
				// TODO: append create request body with educational info
				const response = await CreateInstructor({
					...requestBody,
					instructor: { ...requestBody.instructor, ...educationalInfoState },
				});
				CustomAlert.fire({
					icon: 'success',
					title: response.data.message,
				});
				setIsLoading(true);
				await refreshInstructors();
				await dispatch(fetchInstructor({ uuid: response.data.data.uuid }));
				setIsLoading(false);
				history.goBack();
			} catch (e) {
				setAlert({
					isOpen: true,
					type: 'danger',
					message: e.response.data.message,
				});
			} finally {
				setButtonText(tempButtonText);
				setIsLoading(false);
			}
		} else {
			// handle update request
			// TODO: setting the gender as null won't update it if it's already set
			if (selectedInstructor) {
				const requestBody: any = {
					uuid: selectedInstructor.uuid,
					email: data.email,
					phone_number: data.phone_number,
					first_name: data.firstname,
					last_name: data.lastname,
					instructor: {
						branch_id: data.branch_id,
						branch_name: data.branch_name,
						label: data.label,
						// weight: String(data?.weight),
						educationTypes: data.educationTypes,
						educationYears: data.educationYears,
						educationLanguages: data.educationLanguages,
						educationSections: data.educationSections,
						// weight: !data.weight ? null : String(data?.weight),
					},
				};
				if (data.weight !== '')
					requestBody.instructor.weight = String(data?.weight);
				if (data.password !== '') requestBody.password = data.password;
				if (data.bio) requestBody.instructor.bio = data.bio;
				if (data.profile_photo || selectedInstructor.profile_photo)
					requestBody.profile_photo = data.profile_photo;
				if (data.gender !== '') requestBody.gender = data.gender;

				let buttonTextTemp = buttonText;
				setButtonText('Updating instructor...');
				try {
					let result = await CustomAlert.fire({
						title: 'Are you sure you want to update instructor?',
						showCancelButton: true,
						confirmButtonText: `<i class="fa fa-thumbs-up"></i> Confirm `,
						confirmButtonAriaLabel: 'Thumbs up, great!',
						cancelButtonText: '<i class="fa fa-thumbs-down"></i> Cancel',
						cancelButtonAriaLabel: 'Thumbs down',
					});
					if (result.isConfirmed) {
						// TODO: append update request object with educational info
						const response = await UpdateInstructor({
							...requestBody,
							instructor: {
								...requestBody.instructor,
								...educationalInfoState,
							},
						});
						await CustomAlert.fire({
							icon: 'success',
							title: response.data.message,
						});
						setIsLoading(true);
						await refreshInstructors();
						dispatch(fetchInstructor({ uuid: selectedInstructor.uuid }));
						setIsLoading(false);
						history.goBack();
					}
				} catch (e) {
					setAlert({
						isOpen: true,
						type: 'danger',
						message: e.response.data.message,
					});
				} finally {
					setIsLoading(false);
					setButtonText(buttonTextTemp);
				}
			} else {
				setAlert({
					isOpen: true,
					type: 'danger',
					message: 'no selected instructor to update',
				});
			}
		}
	};

	const refreshInstructors = () => {
		const queries = qs.parse(location.search.replace('?', ''));
		const page =
			queries.page && typeof queries.page === 'string'
				? parseInt(queries.page)
				: 1;

		return dispatch(
			fetchAllInstructors({
				page,
				perPage: 10,
				sortBy: {},
				filters: [],
				query: '',
			})
		);
	};

	return (
		<>test</>
		// <SidebarContentWrapper>
		//   <Row>
		//     <Col>
		//       {/* <form
		//         className="mx-auto"
		//         onSubmit={handleSubmit(onSubmit)}
		//       >
		//         <Card className="">
		//           {/* Basic Info Section */}
		//           <CardHeader>
		//             <h3>
		//               {mode === InstructorFormMode.ADD
		//                 ? "Add instructor"
		//                 : "Instructor Info"}
		//             </h3>
		//             <p>Basic Info</p>
		//           </CardHeader>
		//           <CardBody>
		//             <Container>
		//               <Row>
		//                 <Col>
		//                   <FormGroup
		//                     className={classNames({
		//                       "has-danger": errors.firstname?.message,
		//                     })}
		//                   >
		//                     <Label htmlFor="firstname">
		//                       First Name
		//                       <RequiredAsterisk mode={mode} />
		//                     </Label>
		//                     <Input
		//                       type="text"
		//                       name="firstname"
		//                       id="firstname"
		//                       placeholder="first name"
		//                       innerRef={register()}
		//                     />
		//                     {errors.firstname && (
		//                       <span>
		//                         <p className="text-danger">
		//                           {errors.firstname?.message}
		//                         </p>
		//                       </span>
		//                     )}
		//                   </FormGroup>
		//                 </Col>

		//                 <Col>
		//                   <FormGroup
		//                     className={classNames({
		//                       "has-danger": errors.lastname?.message,
		//                     })}
		//                   >
		//                     <Label htmlFor="lastname">
		//                       Last Name
		//                       <RequiredAsterisk mode={mode} />
		//                     </Label>
		//                     <Input
		//                       type="text"
		//                       name="lastname"
		//                       id="lastname"
		//                       placeholder="last name"
		//                       innerRef={register()}
		//                     />
		//                     {errors.lastname && (
		//                       <span>
		//                         <p className="text-danger">
		//                           {errors.lastname?.message}
		//                         </p>
		//                       </span>
		//                     )}
		//                   </FormGroup>
		//                 </Col>
		//               </Row>

		//               <Row>
		//                 <Col>
		//                   <FormGroup
		//                     className={classNames({
		//                       "has-danger": errors.email?.message,
		//                     })}
		//                   >
		//                     <Label htmlFor="email">
		//                       Email address
		//                       <RequiredAsterisk mode={mode} />
		//                     </Label>
		//                     <Input
		//                       type="text"
		//                       name="email"
		//                       id="email"
		//                       placeholder="Enter email"
		//                       innerRef={register()}
		//                     />
		//                     {errors.email && (
		//                       <span>
		//                         <p className="text-danger">
		//                           {errors.email?.message}
		//                         </p>
		//                       </span>
		//                     )}
		//                   </FormGroup>
		//                 </Col>
		//               </Row>

		//               <Row>
		//                 <Col>
		//                   <FormGroup
		//                     className={classNames({
		//                       "has-danger": errors.phone_number?.message,
		//                     })}
		//                   >
		//                     <Label htmlFor="phone_number">
		//                       phone number
		//                       <RequiredAsterisk mode={mode} />
		//                     </Label>
		//                     <Input
		//                       type="text"
		//                       name="phone_number"
		//                       id="phone_number"
		//                       placeholder="phone number"
		//                       maxlength="11"
		//                       innerRef={register()}
		//                     />
		//                     {errors.phone_number && (
		//                       <span>
		//                         <p className="text-danger">
		//                           {errors.phone_number?.message}
		//                         </p>
		//                       </span>
		//                     )}
		//                   </FormGroup>
		//                 </Col>
		//               </Row>

		//               <Row>
		//                 {mode === InstructorFormMode.UPDATE && (
		//                   <Col>
		//                     <FormGroup>
		//                       <Label htmlFor="username">Username</Label>
		//                       <Input
		//                         type="text"
		//                         name="username"
		//                         id="username"
		//                         disabled
		//                         innerRef={register}
		//                       />
		//                       {errors.username && (
		//                         <span>
		//                           <p className="text-danger">
		//                             username is required
		//                           </p>
		//                         </span>
		//                       )}
		//                     </FormGroup>
		//                   </Col>
		//                 )}

		//                 <Col>
		//                   <FormGroup
		//                     className={classNames({
		//                       "has-danger": errors.password?.message,
		//                     })}
		//                   >
		//                     <Label htmlFor="password">
		//                       Password
		//                       <RequiredAsterisk mode={mode} />
		//                     </Label>
		//                     <Input
		//                       type="password"
		//                       name="password"
		//                       id="password"
		//                       placeholder="password"
		//                       innerRef={register()}
		//                     />
		//                     {errors.password && (
		//                       <span>
		//                         <p className="text-danger">
		//                           {errors.password?.message}
		//                         </p>
		//                       </span>
		//                     )}
		//                   </FormGroup>
		//                 </Col>
		//               </Row>

		//               <Row>
		//                 <Col className="col-sm-6">
		//                   <FormGroup
		//                     className={classNames({
		//                       "has-danger": errors.gender?.message,
		//                     })}
		//                   >
		//                     <Label for="gender" className="required">
		//                       Gender
		//                     </Label>
		//                     <Input
		//                       placeholder="Select Gender "
		//                       type="select"
		//                       name="gender"
		//                       id="gender"
		//                       innerRef={register()}
		//                     >
		//                       <option value="">Choose gender</option>
		//                       <option value="male">Male</option>
		//                       <option value="female">Female</option>
		//                     </Input>

		//                     {errors?.gender?.message && (
		//                       <span>
		//                         <p className="text-danger">
		//                           {errors.gender?.message}
		//                         </p>
		//                       </span>
		//                     )}
		//                   </FormGroup>
		//                 </Col>
		//                 <Col className="col-sm-6">
		//                   <FormGroup
		//                     className={classNames({
		//                       "has-danger": errors.weight?.message,
		//                     })}
		//                   >
		//                     <Label for="weight">Weight</Label>
		//                     <Input
		//                       type="number"
		//                       name="weight"
		//                       innerRef={register()}
		//                       id="weight"
		//                       placeholder="Enter Weight"
		//                     />
		//                     {errors?.weight && (
		//                       <span>
		//                         <p className="text-danger">
		//                           {errors.weight?.message}
		//                         </p>
		//                       </span>
		//                     )}
		//                   </FormGroup>
		//                 </Col>
		//               </Row>

		//               <Row>
		//                 <Col>
		//                   <FormGroup
		//                     className={classNames({
		//                       "has-danger": errors.profile_photo?.message,
		//                     })}
		//                   >
		//                     <Label htmlFor="profile_photo">Profile Picture</Label>
		//                     <Input
		//                       id="profile_photo"
		//                       type="text"
		//                       name="profile_photo"
		//                       placeholder="profile picture url"
		//                       innerRef={register({
		//                         pattern:
		//                           /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/,
		//                       })}
		//                     />
		//                     {errors.profile_photo && (
		//                       <span>
		//                         <p className="text-danger">
		//                           {errors?.profile_photo?.message}
		//                         </p>
		//                       </span>
		//                     )}
		//                   </FormGroup>
		//                 </Col>
		//               </Row>

		//               <Row>
		//                 <Col>
		//                   <FormGroup>
		//                     <Label htmlFor="bio">Bio</Label>
		//                     <Input
		//                       id="bio"
		//                       type="textarea"
		//                       name="bio"
		//                       placeholder="bio"
		//                       innerRef={register}
		//                     />
		//                   </FormGroup>
		//                 </Col>
		//               </Row>
		//             </Container>
		//           </CardBody>

		//           {/* LMS Info Section */}
		//           <CardHeader>
		//             <p>LMS Info</p>
		//           </CardHeader>
		//           <CardBody>
		//             <Container>
		//               <Row>
		//                 <Col>
		//                   <FormGroup
		//                     className={classNames({
		//                       "has-danger": errors.label?.message,
		//                     })}
		//                   >
		//                     <Label htmlFor="label">
		//                       Label
		//                       <RequiredAsterisk mode={mode} />
		//                     </Label>
		//                     <Input
		//                       type="text"
		//                       name="label"
		//                       id="label"
		//                       placeholder="label"
		//                       innerRef={register()}
		//                     />
		//                     {errors.label && (
		//                       <span>
		//                         <p className="text-danger">
		//                           {errors.label?.message}
		//                         </p>
		//                       </span>
		//                     )}
		//                   </FormGroup>
		//                 </Col>
		//               </Row>

		//               <Row>
		//                 <Col>
		//                   <FormGroup
		//                     className={classNames({
		//                       "has-danger": errors.branch_id?.message,
		//                     })}
		//                   >
		//                     <Label htmlFor="branch_id">
		//                       branch id
		//                       <RequiredAsterisk mode={mode} />
		//                     </Label>
		//                     <Input
		//                       type="text"
		//                       name="branch_id"
		//                       id="branch_id"
		//                       placeholder="branch id"
		//                       innerRef={register()}
		//                     />
		//                     {errors.branch_id && (
		//                       <span>
		//                         <p className="text-danger">
		//                           {errors.branch_id?.message}
		//                         </p>
		//                       </span>
		//                     )}
		//                   </FormGroup>
		//                 </Col>

		//                 <Col>
		//                   <FormGroup
		//                     className={classNames({
		//                       "has-danger": errors.branch_name?.message,
		//                     })}
		//                   >
		//                     <Label htmlFor="branch_name">
		//                       branch name
		//                       <RequiredAsterisk mode={mode} />
		//                     </Label>
		//                     <Input
		//                       type="text"
		//                       name="branch_name"
		//                       id="branch_name"
		//                       placeholder="branch name"
		//                       innerRef={register()}
		//                     />
		//                     {errors.branch_name && (
		//                       <span>
		//                         <p className="text-danger">
		//                           {errors.branch_name?.message}
		//                         </p>
		//                       </span>
		//                     )}
		//                   </FormGroup>
		//                 </Col>
		//               </Row>

		//               <FormAlert
		//                 {...alert}
		//                 toggle={() =>
		//                   setAlert((prevState) => {
		//                     return { ...prevState, isOpen: !prevState.isOpen };
		//                   })
		//                 }
		//               />
		//             </Container>
		//           </CardBody>
		//           {/* Educational Info */}
		//           <EducationalInfoMultiSelect
		//             educationalInfoState={educationalInfoState}
		//             setEducationalInfoState={setEducationalInfoState}
		//             isEdit={mode === InstructorFormMode.UPDATE}
		//             showEducationalSections={showEducationalSections}
		//             setShowEducationalSections={setShowEducationalSections}
		//             entity={selectedInstructor}
		//           />

		//           <div className="mt-3 flex-center">
		//             <Button color="primary" type="submit" className="mt-2">
		//               {buttonText}
		//             </Button>
		//           </div>
		//         </Card>
		//       </form> */}
		//     </Col>
		//     {mode === InstructorFormMode.UPDATE && (
		//       <Col sm={5}>
		//         <ClassroomsContainer>
		//           {selectedInstructor?.classrooms.map((classroom) => (
		//             <ClassroomCard key={classroom.id} classroom={classroom} />
		//           ))}
		//         </ClassroomsContainer>
		//       </Col>
		//     )}
		//   </Row>
		// </SidebarContentWrapper>
	);
};

export default InstructorForm;
