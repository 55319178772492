import ClassRooms from '@Pages/ClassRooms';
import Students from '@Pages/Students';
import ClassroomsAnalytics from '@Pages/ClassRooms/Pages/ClassroomsAnalytics/ClassroomsAnalytics';
import SingleClassroom from '@Pages/ClassRooms/Pages/SingleClassroom/SingleClassroom';
import ClassRoomSubscriptions from '@Pages/ClassRooms/Pages/SingleClassroom/Components/ClassRoomSubscriptions';
import Instructors from '@Pages/Instructors/InstructorsRoute';
import InstructorForm from '@Pages/Instructors/instructorForm';
import { InstructorFormMode } from '@Pages/Instructors/instructor.types';
import ClassroomAnalytics from '@Pages/ClassRooms/Pages/SingleClassroom/Components/ClassroomAnalytics';
import CategoriesRoute from '@Pages/Categories/CategoriesRoute';
import NewsFeeds from '@Pages/NewsFeeds/NewsFeeds';
import AddClassroom from '@Pages/ClassRooms/Pages/AddClassroom/AddClassroom';
import EditClassroom from '@Pages/ClassRooms/Pages/EditClassroom/EditClassroom';
import CourseForm from '@Pages/ClassRooms/Pages/SingleCourse/Pages/CourseForm/CourseForm';
import Admins from '@Pages/Admins';
import Curriculum from '@Pages/ClassRooms/Pages/SingleClassroom/Components/Curriculum/Curriculum';
import Roles from '@Pages/Roles';
import AddRole from '@Pages/Roles/pages/AddRole/AddRole';
import EditRole from '@Pages/Roles/pages/EditRole/EditRole';
import AddCategory from '@Pages/Categories/Pages/AddCategory/AddCategory';
import EditCategory from '@Pages/Categories/Pages/EditCategory/EditCategory';
import AddNewsFeed from '@Pages/NewsFeeds/Pages/AddNewsFeed/AddNewsFeed';
import EditNewsFeed from '@Pages/NewsFeeds/Pages/EditNewsFeed/EditNewsFeed';
import AddAdmin from '@Pages/Admins/Pages/AddAdmin/AddAdmin';
import EditAdmin from '@Pages/Admins/Pages/EditAdmin/EditAdmin';
import { AdminsRouteGroup } from '@Pages/Admin/Constants/routes';
import { InstructorsRouteGroup } from '@Pages/Instructors/Constants/routes';
import { RechargeCodesRoute } from '@Pages/RechargeCodes/Constants/routes';
import { ScratchCardsRoute } from '@Pages/ScratchCards/Constants/routes';
import { SingleCourseRoute } from '@Pages/ClassRooms/Pages/SingleCourse/Constants/routes';
import { InvoicesRoute } from '@Pages/Invoices/Constants/routes';
import { IPsRoute } from '@Pages/IPs/Constants/routes';
import SequenceManagement from '@Pages/ClassRooms/Pages/SingleClassroom/Components/SequenceManagement/SequenceManagement';
import Info from '@Pages/Students/StudentTabs/Info/Info';
import Wallet from '@Pages/Students/StudentTabs/Wallet/Wallet';
import ClassRoomTab from '@Pages/Students/StudentTabs/ClassRooms/ClassRoomTab';
import CoursesTab from '@Pages/Students/StudentTabs/Courses/Courses';
import Invoices from '@Pages/Students/StudentTabs/Invoices';
import SingleStudentInvoice from '@Pages/Students/StudentTabs/Invoices/Components/SingleStudentInvoice';
import RechargeCodes from '@Pages/Students/StudentTabs/RechargeCodes';
import ScratchCodesTable from '@Pages/Students/StudentTabs/ScratchCards/ScratchCodesTable';
import Timeline from '@Pages/Students/StudentTabs/Timeline';
import { ImportsRoute } from '@Pages/Imports/Constants/routes';
import Tests from '@Pages/ClassRooms/Pages/SingleCourse/Pages/CourseForm/Pages/CourseReports/Components/Tests/Tests';
import SingleTestTable from '@Pages/ClassRooms/Pages/SingleCourse/Pages/CourseForm/Pages/CourseReports/Components/Tests/SingleTestTable';
import StudentClassroomReport from '@Pages/Students/StudentTabs/ClassRooms/Pages';
import Devices from '@Pages/Students/StudentTabs/Devices';
import AppSetting from '@Pages/Settings';
import { Groups } from '@Pages/ClassRooms/Pages/Groups/Groups';
import { GroupRouteGroup } from '@Pages/ClassRooms/Pages/Groups/constants/groups.routes';
import { NotesRouteGroup } from '@Pages/Instructors/Pages/Assistants copy/Constants/routes';
import { PromoCodesRoute } from '@Pages/PromoCodes/Constants/routes';

export type ExtraPropsIdentifier = 'id' | 'label';
export type BreadcrumbRouteHash =
	| 'classroom'
	| 'course'
	| 'role'
	| 'category'
	| 'newsfeed'
	| 'admins'
	| 'instructor'
	| 'students'
	| 'info'
	| 'groups';
export type ExtraProps = {
	[key in ExtraPropsIdentifier]?: string;
};
export type SingleRouteDisplay = {
	title: string;
	route: string;
	routePrefix?: ExtraPropsIdentifier;
};
export type SingleRoute = SingleRouteDisplay & {
	code: string;
	forceNavigate?: boolean;
	component: JSX.Element | React.ReactNode;
	offMenu?: boolean;
	isExact?: boolean;
	icon?: string;
};
export type HashedSingleRoute = SingleRoute & { hash: string };
export type RouteGroup = SingleRoute & {
	routes: Array<SingleRoute | RouteGroup | HashedSingleRoute>;
	isRouted?: boolean;
	isParent?: boolean;
	customRouting?: boolean;
	overviewRoute?: string;
};
export type RouteSegment = {
	title: string;
	routeGroups: RouteGroup[];
};

export const DASHBOARD_BASE_ROUTE = '/dashboard';

const AppSettings: RouteGroup = {
	code: 'v-set',
	icon: 'settings',
	component: <AppSetting />,
	title: 'settings',
	route: 'settings',
	isRouted: true,
	isParent: true,
	routes: [],
};

export const AddClassroomRouteGroup: SingleRoute = {
	code: '',
	route: 'create/classroom',
	title: 'Create',
	component: <AddClassroom />,
	offMenu: true,
	isExact: true,
	icon: '',
};

export const EditClassroomRouteGroup: SingleRoute = {
	code: '',
	component: <EditClassroom />,
	route: 'edit',
	offMenu: true,
	title: '',
};
export const AddCourseRouteGroup: SingleRoute = {
	code: '',
	component: <CourseForm />,
	route: 'create/course',
	offMenu: true,
	title: '',
};
export const EditCourseRouteGroup: SingleRoute = {
	code: '',
	component: <CourseForm />,
	// route: "edit/course/:id",
	route: ':id/edit',
	offMenu: true,
	title: '',
};

export const SingleClassroomRoute: RouteGroup = {
	code: 'v-cr',
	icon: '',
	route: 'classroom/:label',
	isRouted: true,
	offMenu: true,
	isParent: true,
	component: <SingleClassroom />,
	title: 'Single Classroom',
	customRouting: true,
	routePrefix: 'label',
	routes: [
		AddCourseRouteGroup,
		// EditCourseRouteGroup,
		SingleCourseRoute,
		EditClassroomRouteGroup,
		{
			title: 'Curriculum',
			code: 'v-cur-cl',
			component: <Curriculum />,
			route: 'curriculum',
		},
		{
			code: 'v-seq-cl',
			component: <SequenceManagement />,
			route: 'sequence',
			title: 'sequence',
		} as SingleRoute,
		{
			code: 'v-enrl-cl',
			component: <ClassRoomSubscriptions />,
			route: 'users',
			title: 'Users',
		} as SingleRoute,
		{
			code: 'v-anlt-cl',
			component: <ClassroomAnalytics />,
			route: 'analytics',
			title: 'Analytics',
		} as SingleRoute,
		GroupRouteGroup,
	],
};

export const AddNewsFeedRoute: SingleRoute = {
	code: '',
	route: 'create/newsfeed',
	title: 'create newsfeed',
	component: <AddNewsFeed />,
	offMenu: true,
	icon: '',
};

export const EditNewsFeedRoute: SingleRoute = {
	code: '',
	route: 'edit/newsfeed/:id',
	title: 'edit newsfeed',
	component: <EditNewsFeed />,
	offMenu: true,
	icon: '',
};

export const NewsFeedsRoute: RouteGroup = {
	code: 'v-nws',
	icon: 'feed',
	component: <NewsFeeds />,
	title: 'newsfeed',
	route: 'newsfeed',
	isRouted: true,
	isParent: true,
	routes: [AddNewsFeedRoute, EditNewsFeedRoute],
};

export const StudentReportsRoute = {
	title: 'STUDENT REPORTS',
	icon: 'contact_page',
	route: 'student-reports',
	code: '',
	isRouted: false,
	routes: [],
	component: <div> STUDENT REPORTS </div>,
};

export const CourseReportsRoute = {
	title: 'COURSE REPORTS',
	icon: 'summarize',
	route: 'course-reports',
	code: '',
	isRouted: false,
	routes: [],
	component: <div> Course REPORTS </div>,
};

export const ClassroomReportsRoute = {
	title: 'CLASSROOM REPORTS',
	icon: 'payments',
	route: 'classroom-reports',
	code: '',
	isRouted: false,
	routes: [],
	component: <div> Classroom REPORTS </div>,
};

export const AddAdminRoute: SingleRoute = {
	code: '',
	component: <AddAdmin />,
	route: 'create/admins',
	offMenu: true,
	title: '',
};
export const EditAdminRoute: SingleRoute = {
	code: '',
	component: <EditAdmin />,
	route: 'edit/admins/:id',
	offMenu: true,
	isExact: true,
	title: '',
};
export const AdminsRoute: RouteGroup = {
	code: '',
	icon: 'assignment_ind',
	component: <Admins />,
	title: 'ADMINS',
	route: 'admins',
	isRouted: true,
	isParent: true,
	routes: [AddAdminRoute, EditAdminRoute],
};

export const AddRoleRoute: SingleRoute = {
	code: '',
	route: 'create/role',
	title: 'Create role',
	component: <AddRole />,
	offMenu: true,
	icon: '',
};

export const EditRoleRoute: SingleRoute = {
	code: '',
	route: 'edit/role/:id',
	title: 'edit role',
	component: <EditRole />,
	offMenu: true,
	icon: '',
};

export const RolesRoutes: RouteGroup = {
	code: 'v-rls',
	icon: 'policy',
	component: <Roles />,
	title: 'Roles',
	route: 'roles',
	isRouted: true,
	isParent: true,
	routes: [AddRoleRoute, EditRoleRoute],
};

export const AddCategoryRoute: SingleRoute = {
	code: '',
	route: 'create/category',
	title: '',
	component: <AddCategory />,
	offMenu: true,
	icon: '',
};

export const EditCategoryRoute: SingleRoute = {
	code: '',
	route: 'edit/category/:id',
	title: 'edit category',
	component: <EditCategory />,
	offMenu: true,
	icon: '',
};

export const CategoriesRoutes: RouteGroup = {
	title: 'Categories',
	route: 'category',
	code: 'v-ctg',
	icon: 'category',
	component: <CategoriesRoute />,
	isRouted: true,
	isParent: true,
	routes: [AddCategoryRoute, EditCategoryRoute],
};

export const InstructorRoute = {
	title: 'Instructors',
	route: 'instructors',
	code: '',
	icon: 'group',
	component: <Instructors />,
	isRouted: true,
	isParent: true,
	routes: [
		{
			title: 'add',
			route: 'add',
			code: '',
			offMenu: true,
			component: <InstructorForm mode={InstructorFormMode.ADD} />,
		} as SingleRoute,
		NotesRouteGroup,
	],
};

const StudentWallet: SingleRoute = {
	title: 'wallet',
	route: 'wallet',
	component: <Wallet />,
	icon: '',
	code: 'v-wlt-st',
};
const StudentDevices: SingleRoute = {
	title: 'devices',
	route: 'device',
	component: <Devices />,
	icon: '',
	code: '',
};
const StudentReport: RouteGroup = {
	title: 'report',
	route: ':id',
	component: <StudentClassroomReport />,
	icon: '',
	code: 'exp-st',
	isRouted: true,
	isParent: true,
	routes: [],
};
export const StudentClassroom: RouteGroup = {
	title: 'classrooms',
	route: 'classroom',
	icon: '',
	code: 'v-cl-st',
	component: <ClassRoomTab />,
	routes: [StudentReport],
	isRouted: true,
	isParent: true,
};
const StudentCourse: SingleRoute = {
	title: 'courses',
	route: 'courses',
	component: <CoursesTab />,
	icon: '',
	code: 'v-cr-st',
};
const StudentRechargeCode: SingleRoute = {
	title: 'Recharge Codes',
	route: 'rechargcards',
	component: <RechargeCodes />,
	icon: '',
	code: 'v-rch-st',
};
const StudentTimeLine: SingleRoute = {
	title: 'Timeline',
	route: 'timeline',
	component: <Timeline />,
	icon: '',
	code: 'v-tml-st',
};
const StudentScratchCode: SingleRoute = {
	title: 'Scratch Codes',
	route: 'scratchcard',
	component: <ScratchCodesTable />,
	icon: '',
	code: 'v-sc-st',
};
export const SingleInvoiceRoute: SingleRoute = {
	code: 'v-inv-st',
	component: <SingleStudentInvoice />,
	// component: <div>Single Invoice Test</div>,
	route: ':invoiceId',
	offMenu: true,
	title: '',
};
export const StudentInvoice: RouteGroup = {
	title: 'invoices',
	route: 'invoice',
	component: <Invoices />,
	icon: '',
	code: 'v-inv-st',
	isRouted: true,
	isParent: true,
	routes: [SingleInvoiceRoute],
};
export const StudentInfo: RouteGroup = {
	code: '',
	route: 'student/:id',
	title: 'Info',
	overviewRoute: 'info',
	icon: '',
	offMenu: true,
	isParent: true,
	isRouted: true,
	component: <Info />,
	routes: [
		StudentWallet,
		StudentTimeLine,
		StudentClassroom,
		StudentCourse,
		StudentInvoice,
		StudentRechargeCode,
		StudentScratchCode,
		StudentDevices,
	],
};
const SingleTestReport: SingleRoute = {
	icon: '',
	code: '',
	route: '/:id',
	title: 'Single Test Report',
	component: <SingleTestTable />,
	offMenu: true,
};
export const TestsReport: RouteGroup = {
	code: '',
	route: '/reports/test',
	title: 'Test',
	icon: '',
	isRouted: true,
	isParent: true,
	component: <Tests />,
	routes: [SingleTestReport],
};

export const StudentTabs: RouteGroup = {
	title: 'Students',
	icon: 'groups',
	route: 'students',
	code: 'v-st',
	isRouted: true,
	offMenu: true,
	isParent: true,
	component: <Students />,
	routes: [StudentInfo],
};

export const routes: Array<RouteSegment> = [
	{
		title: 'Student Managment',
		routeGroups: [StudentTabs, InvoicesRoute, IPsRoute, ImportsRoute],
	},
	{
		title: 'Classroom Management',
		routeGroups: [
			{
				title: 'Classrooms',
				route: 'classrooms',
				code: 'v-cl',
				icon: 'auto_stories',
				component: <ClassRooms />,
				isRouted: true,
				isParent: true,
				routes: [
					{
						title: 'Analytics',
						route: 'analytics',
						code: 'v-anlt-cl',
						component: <ClassroomsAnalytics />,
					},
					SingleClassroomRoute,
					AddClassroomRouteGroup,
					// CoursesRoute,
				],
			},
			InstructorsRouteGroup,
			CategoriesRoutes,
		],
	},
	{
		title: 'CODES GENERATION',
		routeGroups: [ScratchCardsRoute, RechargeCodesRoute, PromoCodesRoute],
	},

	{
		title: 'REPORTS',
		routeGroups: [
			ClassroomReportsRoute,
			CourseReportsRoute,
			StudentReportsRoute,
		],
	},
	{
		title: 'GENERAL SETTINGS',
		routeGroups: [AdminsRouteGroup, RolesRoutes, NewsFeedsRoute, AppSettings],
	},
];
