import MainTable from '@Components/MainTable';
import NoResults from '@Components/NoResults/NoResults';
import {
	getAllCourses,
	getEnrolledCount,
	resetCurrentCourse,
} from '@Features/classrooms/classroomSlice';
import { useTypedSelector } from '@Features/store';
import { usePageLoader } from '@Providers/LoadingProvider';
import { useSnackbar } from '@Providers/useSnackbar';
import {
	deleteCourseRequest,
	updateCourse,
} from '@Services/classrooms/classrooms.req';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import qs from 'querystring';
import { getEnrolledAnaysis } from '@Features/classrooms/EnrolledSlice';
import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { StyleAddBtn } from '@Pages/ClassRooms/ClassRoomsTable';
import { TableHeader } from '@Features/generics/generics.type';
import { MainTableRow } from '@Components/MainTable/MainTable';
import { onPageChangeRequestMeta } from '@Components/MainTable/MainTable.types';
import EdConfirmModal from '@Components/UI/Modals/EdConfirmModal/EdConfirmModal';
import { CourseWithPrerequisites } from '@Services/classrooms/classrooms.res.types';
import EdIcon from '@Components/UI/Utilities/EdIcon/EdIcon';
import styled from 'styled-components';
import { Button, Tooltip } from '@mui/material';
import EdActiveStatus from '@Components/UI/Utilities/EdActiveStatus/EdActiveStatus';

const TABLE_H: Array<TableHeader> = [
	{ displayName: 'NAME', fieldName: 'name', width: '15%' },
	{ displayName: 'SECTION', fieldName: 'section_name', width: '15%' },
	{ displayName: 'PRICE', fieldName: 'price' },
	{ displayName: 'ORDER', fieldName: 'order' },
	{ displayName: 'CODE', fieldName: 'code' },
	{ displayName: 'SCORES', fieldName: 'scores_view', canSort: false },
	{ displayName: 'BUYABLE', fieldName: 'buyable', canSort: false },
	{ displayName: 'ACTIVE', fieldName: 'active', canSort: false },
];

const CoursesTable: React.FC = () => {
	/* hooks */
	const dispatch = useDispatch();
	const history = useHistory();
	const location = useLocation();
	const params = useParams<{ id: string; crid: string }>();
	const { setIsLoading, isLoading } = usePageLoader();
	const { displaySnackbar } = useSnackbar();
	const [currentCourseId, setCurrentCourseId] = useState<number>();
	const [flag, setFlag] = useState(false);
	const [openModel, setOpenModel] = useState(false);
	const [deletedId, setDeletedId] = useState<any>('');

	const {
		errors,
		loaders,
		courses = [],
		EnrolledPurchaseCount = [],
		lastPage,
		coursesFilters,
		totalResults,
		currentClassroom,
		perPage,
		classroomFilters,
		query,
		to,
		from,
		sortBy,
		page,
		coursesSelectedFilters,
	} = useTypedSelector((state) => state.classroom);
	const auth = useTypedSelector((state) => state.auth);

	const [enrolledCounterFlag, setenrolledCounterFlag] = useState(false);

	useEffect(() => {
		if (flag) setFlag(false);
	}, [flag]);

	const onPageChangeRequest = useCallback(
		({
			activePage,
			endDate,
			filters,
			query,
			sortBy,
			startDate,
			perPage,
		}: onPageChangeRequestMeta) => {
			if (currentClassroom) {
				dispatch(
					getAllCourses({
						page: activePage,
						perPage: perPage ?? 10,
						sortBy,
						filters,
						query,
						classroom: currentClassroom.label,
						from: startDate,
						to: endDate,
					})
				);
			}
		},
		[currentClassroom?.label, dispatch]
	);

	useEffect(() => {
		dispatch(resetCurrentCourse());
		dispatch(
			getEnrolledAnaysis({
				classroomId: Number(params.crid),
				flag: false,
			})
		);
	}, []);

	useEffect(() => {
		const date = qs.parse(location.search);
		dispatch(
			getEnrolledAnaysis({
				classroomId: Number(params.crid),
				flag,
			})
		);
		setFlag(false);
	}, [dispatch, params.crid, flag, location.search]);

	useEffect(() => {
		dispatch(getEnrolledCount({ classroomId: Number(params.crid) }));
	}, [dispatch, params.crid]);

	useEffect(() => {
		if (EnrolledPurchaseCount.length)
			setenrolledCounterFlag(
				EnrolledPurchaseCount.some((c) => c.purchase_method_count > 0)
			);
	}, [EnrolledPurchaseCount]);

	const handleTableItemClick = useCallback(
		(id: number) => () => {
			if (currentClassroom) {
				history.push({
					pathname: `/dashboard/classrooms/classroom/${currentClassroom.label}/course/${id}/edit/content`,
				});
			}
		},
		[currentClassroom]
	);

	const onAddCourse = () => {
		if (currentClassroom) {
			history.push({
				pathname: `/dashboard/classrooms/classroom/${currentClassroom.label}/create/course/info`,
			});
		}
	};

	useEffect(() => {
		if (isLoading && loaders.getAllCourses === undefined) {
			displaySnackbar('error', errors.getAllCourses);
		}
	}, [
		loaders.getAllCourses,
		setIsLoading,
		errors.getAllCourses,
		displaySnackbar,
		isLoading,
		dispatch,
	]);

	const handleModelOpen = (id: number) => {
		setOpenModel(true);
		setCurrentCourseId(id);
	};

	// delete confirmation

	const handleModelClose = (close: boolean, confirm?: boolean) => {
		if (confirm) {
			if (currentCourseId === currentClassroom?.current_course) {
				displaySnackbar('error', 'Can not delete current course');
			} else {
				deleteCourseRequest(Number(currentCourseId))
					.then(({ data }) => {
						if (currentClassroom) {
							dispatch(
								getAllCourses({
									page: 1,
									perPage: 10,
									filters: [],
									query: '',
									classroom: currentClassroom.label,
								})
							);
							displaySnackbar('success', data?.message);
						}
					})
					.catch((e) => {
						const msg = e.response?.data.message || 'unable to delete';
						displaySnackbar('error', msg);
					});
			}
		}
		setOpenModel(false);
		setDeletedId(null);
	};

	const refetch = () => {
		console.log({coursesSelectedFilters})
		dispatch(
			getAllCourses({
				page: page,
				perPage: perPage,
				query,
				classroom: currentClassroom?.label as string,
				from,
				to,
				sortBy,
				filters: coursesSelectedFilters,
			})
		);
	};
	const activateCourse = async (course: CourseWithPrerequisites) => {
		try {
			await updateCourse({ active: true }, course?.id);
			refetch();
		} catch (e) {
			const msg = e.response?.data.message;
			displaySnackbar('error', msg);
		}
	};
	const deactivateCourse = async (course: CourseWithPrerequisites) => {
		try {
			await updateCourse({ active: false }, course?.id);
			refetch();
		} catch (e) {
			const msg = e.response?.data.message;
			displaySnackbar('error', msg);
		}
	};

	const buyableCourse = async (course: CourseWithPrerequisites) => {
		try {
			await updateCourse({ buyable: true }, course?.id);
			refetch();
		} catch (e) {
			const msg = e.response?.data.message;
			displaySnackbar('error', msg);
		}
	};

	const unbuyableCourse = async (course: CourseWithPrerequisites) => {
		try {
			await updateCourse({ buyable: false }, course?.id);
			refetch();
		} catch (e) {
			const msg = e.response?.data.message;
			displaySnackbar('error', msg);
		}
	};

	return (
		<>
			<EdConfirmModal
				text="Are you sure you want to delete this item"
				open={openModel}
				handleClose={handleModelClose}
			/>
			{(auth.user.type === 'super' || auth.permissions.has('c-cr')) && (
				<Box
					sx={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'flex-end',
					}}
				>
					<StyleAddBtn
						variant="contained"
						sx={{
							borderRadius: 8,
							fontFamily: ' Montserrat',
							fontSize: '14px',
							fontWeight: '500',
							marginRight: '9px',
						}}
						startIcon={<AddIcon />}
						onClick={onAddCourse}
					>
						Add Course
					</StyleAddBtn>
				</Box>
			)}
			<EdConfirmModal
				text="Are you sure you want to delete this course"
				open={openModel}
				handleClose={handleModelClose}
			/>
			<MainTable
				onPageChangeRequest={onPageChangeRequest}
				totalPages={lastPage}
				tableFilters={coursesFilters}
				total={Number(totalResults)}
				RowsperPage={Number(perPage)}
				pageNum={Number(page)}
				tableHeads={TABLE_H}
				$hasActions={true}
				renderItems={() => {
					if (courses.length <= 0)
						return (
							<MainTableRow>
								<TableCell colSpan={TABLE_H.length}>
									<NoResults />
								</TableCell>
							</MainTableRow>
						);

					return courses.map((course) => (
						<MainTableRow
							onClick={handleTableItemClick(course?.id)}
							hover
							sx={{
								cursor: 'pointer',
							}}
							key={course?.id}
						>
							<TableCell
								sx={{
									fontFamily: 'Roboto',
									fontSize: '1rem',
									padding: '0 1.063rem 0',
									whiteSpace: 'nowrap',
									textOverflow: 'ellipsis',
									overflow: 'hidden',
								}}
							>
								<a title={course?.name || '-'}>{course?.name || '-'}</a>
							</TableCell>

							<TableCell
								sx={{
									fontFamily: 'Roboto',
									fontSize: '1rem',
									padding: '0 1.063rem 0',
									whiteSpace: 'nowrap',
									textOverflow: 'ellipsis',
									overflow: 'hidden',
								}}
							>
								<a title={course?.section?.name || '-'}>
									{course?.section?.name || '-'}
								</a>
							</TableCell>

							<TableCell
								sx={{
									fontFamily: 'Roboto',
									fontSize: '1rem',
									padding: '0 1.063rem 0',
									whiteSpace: 'nowrap',
								}}
							>
								{course?.price || '0'}
							</TableCell>
							<TableCell
								sx={{
									fontFamily: 'Roboto',
									fontSize: '1rem',
									padding: '0 1.063rem 0',
									whiteSpace: 'nowrap',
								}}
							>
								{course?.order || '-'}
							</TableCell>

							<TableCell
								sx={{
									fontFamily: 'Roboto',
									fontSize: '1rem',
									padding: '0 1.063rem 0',
									whiteSpace: 'nowrap',
								}}
							>
								{course?.code || '-'}
							</TableCell>

							<TableCell
								sx={{
									padding: '0 1.063rem 0',
									whiteSpace: 'nowrap',
								}}
							>
								<EdActiveStatus active={course.scores_view} />
							</TableCell>

							<TableCell
								sx={{
									padding: '0 1.063rem 0',
									whiteSpace: 'nowrap',
								}}
							>
								<Tooltip
									placement="top"
									title={
										course?.buyable
											? 'Click to make unbuyable'
											: 'Click to make buyable'
									}
								>
									{!course?.buyable ? (
										<Button
											onClick={(e) => {
												e.stopPropagation();
												buyableCourse(course);
											}}
										>
											<OutLinedCheckIcon>check</OutLinedCheckIcon>
										</Button>
									) : (
										<Button
											onClick={(e) => {
												e.stopPropagation();
												unbuyableCourse(course);
											}}
										>
											<CheckIcon>check</CheckIcon>
										</Button>
									)}
								</Tooltip>
							</TableCell>
							<TableCellStyled>
								<Tooltip
									placement="top"
									title={course?.active ? 'Deactivate' : 'Activate'}
								>
									{!course?.active ? (
										<Button
											onClick={(e) => {
												e.stopPropagation();
												activateCourse(course);
											}}
										>
											<CloseIcon>close</CloseIcon>
										</Button>
									) : (
										<Button
											onClick={(e) => {
												e.stopPropagation();
												deactivateCourse(course);
											}}
										>
											<CheckIcon>check</CheckIcon>
										</Button>
									)}
								</Tooltip>
							</TableCellStyled>

							<TableCell
								sx={{
									padding: '0 1.063rem 0',
									position: 'sticky',
									right: '0',
									background:
										'linear-gradient(260deg, white, white, transparent)',
								}}
							>
								<SpeedDial
									FabProps={{
										disableRipple: true,
										disableTouchRipple: true,
										focusRipple: false,
										disableFocusRipple: true,
										style: {
											borderRadius: '0',
											boxShadow: '0 0 0 0 ',
											backgroundColor: 'transparent',
											color: '#000',
										},
									}}
									direction="left"
									ariaLabel="SpeedDial basic example"
									icon={<MoreVertIcon />}
								>
									{(auth.user.type === 'super' ||
										auth.permissions.has('d-cr')) && (
										<SpeedDialAction
											key="delete"
											sx={{
												'&:hover': {
													backgroundColor: 'rgba(90,192,252, 0.25)',
												},
											}}
											icon={
												<DeleteIcon
													sx={{
														color: '#251b33',
														'&:hover': {
															color: '#5ac0fc',
														},
													}}
												/>
											}
											tooltipTitle="Delete"
											onClick={(e) => {
												e.stopPropagation();
												handleModelOpen(course?.id);
											}}
										/>
									)}
									<SpeedDialAction
										key="clone"
										sx={{
											'&:hover': {
												backgroundColor: 'rgba(90,192,252, 0.25)',
											},
										}}
										icon={
											<FileCopyIcon
												sx={{
													color: '#251b33',
													'&:hover': {
														color: '#5ac0fc',
													},
												}}
											/>
										}
										tooltipTitle="Clone"
									/>
								</SpeedDial>
							</TableCell>
						</MainTableRow>
					));
				}}
			/>
		</>
	);
};

export default CoursesTable;
const EdStyle = styled(EdIcon)`
	width: 26px;
	height: 26px;
	border-radius: 5px;
	display: flex !important;
	justify-content: center;
	align-items: center;
	line-height: 1.28 !important;
	font-weight: bold;
	font-size: 1.7rem;
`;

const CheckIcon = styled(EdStyle)`
	background: #5ac0fc;
	color: #fff;
	border: 2px solid #5ac0fc;
`;
const CloseIcon = styled(EdStyle)`
	background: #ff3100;
	color: #fff;
	border: 2px solid #ff3100;
`;
const TableCellStyled = styled(TableCell)`
	z-index: 999;
`;
const OutLinedCheckIcon = styled(EdStyle)`
	background: transparent;
	border: 2px solid #5ac0fc;
	color: #5ac0fc;
`;
