import { SingleRoute, RouteGroup } from "@Constants/routes";
import {  Notes } from "@mui/icons-material";
import AddNote from "../Pages/AddNote/AddNote";
import EditNote from "../Pages/EditNote/EditNote";


export const AddNoteRoute: SingleRoute = {
	code: '',
	route: 'create/note',
	component: <AddNote />,
	offMenu: true,
	title: '',
};
export const EditNoteRoute: SingleRoute = {
	code: '',
	component: <EditNote />,
	route: 'edit/note/:id',
	offMenu: true,
	isExact: true,
	title: '',
};

export const NotesRouteGroup: RouteGroup = {
	code: 'notes',
	component: <Notes />,
	route: 'notes',
	title: 'Notes',
	icon: 'auto_stories',
	routes: [AddNoteRoute, EditNoteRoute],
	isRouted: true,
	isParent: true,
};