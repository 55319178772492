import { Checkbox, CheckboxProps } from '@mui/material';
import { FlexLayout, Spacer } from '@Styled/utilities';
import React, { FC } from 'react';
import { useController, Control, Path, Controller } from 'react-hook-form';
import styled from 'styled-components';
import { EdFormControlLabel } from '../EdFormControlLabel/EdFormControlLabel';

type HookFormProps<T> =
	| {
			control: any;
			name: Path<T>;
	  }
	| { control?: any; name?: any };
type EdCheckboxProps = {
	label?: string;
} & CheckboxProps;
type Props<T> = HookFormProps<T> & EdCheckboxProps;

const EdCheckbox = <T extends {}>({
	control,
	name,
	label,
	...props
}: React.PropsWithChildren<Props<T>>) => {
	if (!name || !control)
		return (
			<FlexLayout alignItems={'center'}>
				<Checkbox style={{ marginLeft: -9, marginRight: -9 }} {...props} />
				<Spacer mx={'0.5rem'} />
				<CheckboxLabel>{label}</CheckboxLabel>
			</FlexLayout>
		);

	return (
		<Controller
			control={control}
			name={name}
			render={({ field: { onChange, value, ...others } }) => {
				return (
					<FlexLayout alignItems={'center'}>
						<Checkbox
							style={{ marginLeft: -9, marginRight: -9 }}
							checked={value as unknown as any}
							{...others}
							onChange={(e, checked) => onChange(checked)}
							{...props}
						/>
						<Spacer mx={'0.5rem'} />
						<CheckboxLabel>{label}</CheckboxLabel>
					</FlexLayout>
				);
			}}
		/>
	);
};

export default EdCheckbox;

const CheckboxLabel = styled.label`
	font-family: 'Roboto';
`;
