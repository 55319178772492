import { useTypedSelector } from '@Features/store';
import { FlexLayout, GridLayout } from '@Styled/utilities';
import React from 'react';
import { FlexLayoutWrapper, Text, Title, Info } from './InvoicesCard';
const CourseCard = () => {
	const { currentEntity } = useTypedSelector((state) => state.Invoices);

	return (
		<FlexLayoutWrapper flexDirection="column">
			<Title>Course</Title>
			<GridLayout gridTemplateColumns="2fr repeat(2, 1fr)">
				{currentEntity?.courses?.map((_) => {
					return (
						<>
							<FlexLayout flexDirection="column" gridGap="3.125rem">
								<Text> Name </Text>
								<Info> {_?.name} </Info>
							</FlexLayout>
							<FlexLayout flexDirection="column" gridGap="3.125rem">
								<Text> Price </Text>
								<Info> {_?.price} </Info>
							</FlexLayout>
							<FlexLayout flexDirection="column" gridGap="3.125rem">
								<Text> Classroom </Text>
								<Info> {_?.classroom?.title} </Info>
							</FlexLayout>
						</>
					);
				})}
			</GridLayout>
		</FlexLayoutWrapper>
	);
};

export default CourseCard;
