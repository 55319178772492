import { FlexLayout } from '@Styled/utilities';
import { rgba } from 'polished';
import React, { FC } from 'react';
import styled from 'styled-components';
import { TestsCount } from '../Types/Tests.types';

type Props = {
	count: TestsCount;
};

const TestsCounts: FC<Props> = ({ count }) => {
	return (
		<Container>
			<AverageContainer
				alignItems="center"
				justifyContent="space-between"
				width="100%"
			>
				<Text color="#5ac0fc">Average Score</Text>
				<Score>{(count?.average_score * 100).toFixed(1)}%</Score>
			</AverageContainer>
			<PassedContainer
				alignItems="center"
				justifyContent="space-between"
				width="100%"
			>
				<Text color="#00d66b">Passed</Text>
				<Score>{count?.passed_tests}</Score>
			</PassedContainer>
			<FailedContainer
				alignItems="center"
				justifyContent="space-between"
				width="100%"
			>
				<Text color="#ff3100">Failed</Text>
				<Score>{count?.failed_tests}</Score>
			</FailedContainer>
			<NotStartedContainer
				alignItems="center"
				justifyContent="space-between"
				width="100%"
			>
				<Text color="#FF8532">Not Started</Text>
				<Score>{count?.not_started_tests}</Score>
			</NotStartedContainer>
		</Container>
	);
};

const Container = styled(FlexLayout)`
	place-items: center;
	align-items: center;
	justify-content: space-between;
	gap: 1rem;
	padding: 0 1rem;
	margin-bottom: 3rem;
`;
const BaseContainer = styled(FlexLayout)`
	padding: 1.688rem 1.25rem;
	border-radius: 5px;
`;
const AverageContainer = styled(BaseContainer)`
	background: ${rgba('#5AC0FC', 0.1)};
	width: 18.938rem;
`;
const PassedContainer = styled(BaseContainer)`
	background: ${rgba('#00d66b', 0.1)};
	width: 13.688rem;
`;
const FailedContainer = styled(BaseContainer)`
	background: ${rgba('#ff3100', 0.1)};
	width: 13.688rem;
`;
const NotStartedContainer = styled(BaseContainer)`
	background: ${rgba('#FF8532', 0.1)};
	width: 13.688rem;
`;
const Text = styled.p<{ color: string }>`
	font-size: 1.25rem;
	font-weight: bold;
	color: ${({ color }) => color};
`;
const Score = styled.p`
	font-size: 1.75rem;
	font-weight: 600;
	color: #251b33;
`;

export default TestsCounts;
