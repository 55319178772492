import api from '@Services/api';
import { AxiosResponse } from 'axios';
import {
	CRUDBase,
	DeleteSingleEntityPayload,
	GetAllMeta,
	GetSingleEntityPayload,
} from 'src/base/services/services.base';
import { RolesPathsTypes } from '../types/RolesPaths.types';

interface RolesPathsCRUD extends CRUDBase<RolesPathsTypes, RolesPathsTypes> {}
export class RolesPathsRequester implements RolesPathsCRUD {
	private static instance: RolesPathsRequester;
	static getInstance() {
		if (!RolesPathsRequester.instance) {
			RolesPathsRequester.instance = new RolesPathsRequester();
		}
		return RolesPathsRequester.instance;
	}
	baseURL = 'course/rules';
	getAll(
		payload?: GetAllMeta
	): Promise<AxiosResponse<ApiResponse<RolesPathsTypes[]>>>;
	getAll(
		payload: number
	): Promise<AxiosResponse<ApiResponse<RolesPathsTypes[]>>>;
	async getAll(
		payload?: any
	): Promise<import('axios').AxiosResponse<ApiResponse<RolesPathsTypes[]>>> {
		return await api.post(`${this.baseURL}/all`, payload);
	}
	get(id: string): Promise<AxiosResponse<ApiResponse<RolesPathsTypes>>>;
	get(id: number): Promise<AxiosResponse<ApiResponse<RolesPathsTypes>>>;
	get(
		id: string | number,
		payload: GetSingleEntityPayload
	): Promise<AxiosResponse<ApiResponse<RolesPathsTypes>>>;
	async get(
		id: any,
		payload?: any
	): Promise<AxiosResponse<ApiResponse<RolesPathsTypes>>> {
		return await api.post(`${this.baseURL}`, { uuid: id });
	}

	async create(
		payload: RolesPathsTypes
	): Promise<AxiosResponse<ApiResponse<RolesPathsTypes>>> {
		return await api.post(`${this.baseURL}/create`, payload);
	}
	async update(
		payload: RolesPathsTypes
	): Promise<AxiosResponse<ApiResponse<RolesPathsTypes>>> {
		return await api.put(`${this.baseURL}/update`, payload);
	}
	async updatePrerequisite(
		payload: RolesPathsTypes
	): Promise<AxiosResponse<ApiResponse<RolesPathsTypes>>> {
		return await api.post(`course/prerequisite/update`, payload);
	}

	delete(id: number): Promise<AxiosResponse<ApiResponse<any>>>;
	delete(id: string): Promise<AxiosResponse<ApiResponse<any>>>;
	delete(
		id: string | number,
		payload: DeleteSingleEntityPayload
	): Promise<AxiosResponse<ApiResponse<RolesPathsTypes>>>;
	async delete(
		id: any,
		payload?: any
	): Promise<AxiosResponse<ApiResponse<any>>> {
		return await api.delete(`${this.baseURL}/delete`, {
			data: {
				uuid: id,
			},
		});
	}
}
