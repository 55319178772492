import { EdFormControlLabel } from '@Components/UI/Inputs/EdFormControlLabel/EdFormControlLabel';
import { useTypedSelector } from '@Features/store';
import { Slider, Stack, TextField } from '@mui/material';
import { FlexLayout, Spacer } from '@Styled/utilities';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import { TestPayload } from '../../../../../Types/Test/Test.types';

const TestOptionsMainSection = () => {
	const { control } = useFormContext<TestPayload>();
	const {
		currentEntity: { active },
	} = useTypedSelector((state) => state.Tests);
	return (
		<FlexLayout width={'43.563rem'} flexDirection="column">
			<FlexLayout mb="2rem" alignItems="center">
				<EdFormControlLabel>Duration</EdFormControlLabel>
				<Controller
					control={control}
					name="test.duration"
					render={({ field: { value, ...others }, fieldState: { error } }) => {
						return (
							<StyledSlider
								{...others}
								value={value === null ? undefined : value}
								disabled={active}
								size="small"
								aria-label="Small"
								valueLabelDisplay="on"
								max={240}
							/>
						);
					}}
				/>
				<Spacer mx={'0.5rem'} />
				<StyledTimeLabel>minutes</StyledTimeLabel>
			</FlexLayout>
			<FlexLayout mb="2rem">
				<EdFormControlLabel>Start Text</EdFormControlLabel>
				<Controller
					control={control}
					name="test.start_text"
					render={({ field, fieldState: { error } }) => {
						return (
							<StyledTextArea
								disabled={active}
								minRows={3}
								multiline
								{...field}
							/>
						);
					}}
				/>
			</FlexLayout>
		</FlexLayout>
	);
};

export default TestOptionsMainSection;

const StyledSlider = styled(Slider)`
	/* width: 420px; */
	.MuiSlider-valueLabelOpen {
		transform: translateY(100%) scale(1);
		background: transparent;
		color: grey;
		margin-top: 6px;
	}
`;

const StyledTimeLabel = styled.p`
	font-size: 14px;
	color: #979797;
`;

const StyledTextArea = styled(TextField)`
	/* min-height: 5.75rem; */
	flex: 1;
`;
