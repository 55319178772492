import { BaseSliceController } from '@Base/slices/slices.base';
import { StudentDevicesRequester } from '../Services/StudentDevices.requester';
import { Device } from '../Types/StudentDevices.types';

export class StudentDevicesSlice extends BaseSliceController<
	Device,
	'StudentDevices',
	Device
> {
	private static instance: StudentDevicesSlice;
	static getInstance() {
		if (!StudentDevicesSlice.instance) {
			StudentDevicesSlice.instance = new StudentDevicesSlice();
		}
		return StudentDevicesSlice.instance;
	}
	constructor() {
		super('StudentDevices', StudentDevicesRequester.getInstance());
	}
}

export const { studentDevicesGetAll } =
	StudentDevicesSlice.getInstance().getActions();
