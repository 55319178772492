import React, { useState } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import { HashString } from '@Components/UI/Tabs/RoutedTabs/RoutedTabs.types';
import { Spacer } from '@Styled/utilities';
import RoutedTabs from '@Components/UI/Tabs/Tabs';
import AddAdminInfo from './Components/AddAdminInfo';
import { AdminResponse } from '@Pages/Admins/Types/admin.types';

const AddAdmin: React.FC = () => {
	const { path } = useRouteMatch();
	const { hash } = useLocation();
	const [tabIndex, setTabIndex] = useState<HashString>(hash as HashString);
	const [hasRoles, setHasRoles] = useState(false);
	const [adminFormData, setAdminFormData] = useState<
		AdminResponse | undefined
	>();

	const history = useHistory();
	const { pathname } = useLocation();

	const onBasicInfoNext = (data: AdminResponse) => {
		setAdminFormData(data);
		history.push({ hash: '#role' });
		setTabIndex('#role');
	};

	return (
		<>
			<Container>
				<RoutedTabs>
					<RoutedTabs.Tabs
						$hashPrefix="#"
						hashes={['#info', '#role']}
						onChange={(e, value) => setTabIndex(value)}
						orientation="vertical"
						value={tabIndex}
					>
						<RoutedTabs.Item
							value="#info"
							label={<RoutedTabs.ItemTitle>Basic Info</RoutedTabs.ItemTitle>}
							icon={<RoutedTabs.ItemIcon>menu_book</RoutedTabs.ItemIcon>}
						/>
						<RoutedTabs.Item
							value="#role"
							label={<RoutedTabs.ItemTitle>Roles</RoutedTabs.ItemTitle>}
							disabled={!hasRoles}
							icon={<RoutedTabs.ItemIcon>post_add</RoutedTabs.ItemIcon>}
						/>
					</RoutedTabs.Tabs>
					<Spacer mx={'1rem'} />
					<RoutedTabs.Content value={tabIndex}>
						<RoutedTabs.ContentItem hash="#info">
							<AddAdminInfo
							// onNext={onBasicInfoNext}
							/>
						</RoutedTabs.ContentItem>
						<RoutedTabs.ContentItem hash="#role">
							<p>role</p>
						</RoutedTabs.ContentItem>
					</RoutedTabs.Content>
				</RoutedTabs>
			</Container>
		</>
	);
};

const Container = styled.div`
	padding: 1.375rem;
	border-radius: 10px;
	border: 1px solid #5ac0fc;
	height: 100vh;
`;

export default AddAdmin;
