import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import useInvalidateRouteGroup from '@Hooks/Routes/useInvalidateRouteGroup';
import styled from 'styled-components';
import { TestImportsRoute } from './Constants/routes';
import ImportsTable from './ImportsTable';

const ImportsTab: React.FC = () => {
	const { path, url } = useRouteMatch();
	// useInvalidateRouteGroup({
	// 	baseURL: '/dashboard/imports',
	// 	mode: 'include',
	// 	routeGroup: TestImportsRoute,
	// });

	return (
		<StyledContainer>
			<Switch>
				<Route path={url} exact component={ImportsTable} />
			</Switch>
		</StyledContainer>
	);
};
const StyledContainer = styled.div`
	margin-left: 2.625rem;
`;

export default ImportsTab;
