import { AnyAction, createReducer } from '@reduxjs/toolkit';

function isPendingAction(action: AnyAction) {
	return action.type.endsWith('/pending');
}
function isRejectedAction(action: AnyAction) {
	return action.type.endsWith('/rejected');
}

function isFulfilledAction(action: AnyAction) {
	return action.type.endsWith('/fulfilled');
}
export type LoadingState = {
	isLoading: AsyncLoader;
	fetchCount: number;
};
const initState: LoadingState = {
	isLoading: null,
	fetchCount: 0,
};
export const LoadingReducer = createReducer(initState, (builder) => {
	builder
		.addMatcher(isPendingAction, (state, action) => {
			state.isLoading = true;
			state.fetchCount += 1;
		})
		.addMatcher(isRejectedAction, (state) => {
			state.isLoading = undefined;
			state.fetchCount -= 1;
		})
		.addMatcher(isFulfilledAction, (state) => {
			state.isLoading = false;
			state.fetchCount -= 1;
		});
});
