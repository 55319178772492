import { boolean, number, object, string, date } from 'yup';

const re =
	/^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm;
const validURL = /[A-Za-z0-9`~!@#$%^&*()+={};:’”,./<>?| \\\\\ [\]]*/;

export const ADD_SLOT = object({
	start_time: date()
		.typeError('Invalid Date')
		.required('Start Date & Time is required'),
	end_time: string(),
	duration: number().min(1).required(),
	description: string().required(),
	name: string().required(),
	slot_url: string().matches(validURL, 'URL is not valid').required(),
	unlimited: boolean(),
	capacity: number()
		.nullable(true)
		.transform((v, o) => {
			return isNaN(o) ? '' : v;
		})
		.when('unlimited', {
			is: false,
			then: number()
				.typeError('Capacity Must be a number')
				.moreThan(0, 'Capacity must be bigger than zero')
				.required(),
		}),
});
