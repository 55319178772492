import React from 'react';
import styled from 'styled-components';
import { layout, LayoutProps } from 'styled-system';

export const EdFormControlLabel: React.FC<LayoutProps> = ({
	children,
	...props
}) => {
	return (
		<TextFieldWrapper {...props}>
			<StyledTextFieldLabel className="StyledTextFieldLabel">
				{children}
			</StyledTextFieldLabel>
		</TextFieldWrapper>
	);
};

export const EdFormControlError: React.FC<{ error?: any }> = ({
	children,
	error,
}) => {
	return (
		<TextFieldWrapper>
			{error && <StyledTextFieldError>{children}</StyledTextFieldError>}
		</TextFieldWrapper>
	);
};

const TextFieldWrapper = styled.div<LayoutProps>`
	min-width: 12rem;
	padding: 8.5px 0;
	height: 1.4375rem;
	display: grid;
	align-items: center;
	${layout}
`;
const StyledTextFieldLabel = styled.span`
	font-weight: 500;
	white-space: nowrap;
	position: relative;
`;

const StyledTextFieldError = styled.span`
	color: ${(props) => props.theme.palette.error.main};
	white-space: nowrap;
	position: relative;
`;

export const EdInputLabel = styled.div`
	font-weight: 500;
	font-size: 0.875rem;
`;
