import { Box } from '@mui/material';
import { BoxProps } from '@mui/system';
import React, { FC } from 'react';
import styled from 'styled-components';
import { layout, LayoutProps, padding } from 'styled-system';
import { ExtraPropsBase, getVariant } from '@Styled/theme';
type Props = {} & BoxProps & LayoutProps & Pick<ExtraPropsBase, 'edborder'>;
const EdBox: FC<Props> = ({ children, ...props }) => {
	return <StyledEdBox {...props}>{children}</StyledEdBox>;
};
export default EdBox;
const StyledEdBox = styled(Box)<BoxProps & LayoutProps>`
	display: flex;
	border-radius: 0.625rem;
	width: 100%;
	padding: 1.75rem 2.625rem;
	justify-content: space-between;
	border: solid 2px rgba(90, 192, 252, 0.25);
	${layout};
	${padding};
	${() =>
		getVariant({
			prop: 'edborder',
			variantPath: 'edBox',
		})}
`;
