import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Device } from '../Types/StudentDevices.types';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import LaptopIcon from '@mui/icons-material/Laptop';
import { FlexLayout } from '@eduact/ed-system';
import moment from 'moment';
import { Button } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useSnackbar } from '@Providers/useSnackbar';
import EdConfirmModal from '@Components/UI/Modals/EdConfirmModal/EdConfirmModal';
import { logoutSingleDevice } from '../Services/StudentDevices.requester';
import { useTypedSelector } from '@Features/store';
import { studentDevicesGetAll } from '../Slices/StudentDevices.slice';

type Props = {
	device: Device;
	key: number;
};

const DeviceCard: FC<Props> = ({ device, key }) => {
	const { currentEntity, meta, dateRange, query, filters, sortBy } =
		useTypedSelector((state) => state.Students);
	const [openModel, setOpenModel] = useState<boolean>(false);
	const dispatch = useDispatch();
	const { displaySnackbar } = useSnackbar();
	const handleModelOpen = () => {
		setOpenModel(true);
	};

	const handleModelClose = async (close: boolean, confirm?: boolean) => {
		try {
			if (confirm) {
				await logoutSingleDevice(
					device.device_id,
					currentEntity.student.user_id
				).then((e) => {
					displaySnackbar('success', e?.data.message);
				});
				dispatch(
					studentDevicesGetAll({
						page: meta?.first_page ?? 1,
						perPage: meta?.per_page ?? 10,
						sortBy,
						filters,
						query: query as string,
						from: dateRange.from,
						to: dateRange.to,
						id: currentEntity?.student?.user_id,
					})
				);
			}
			setOpenModel(false);
		} catch (e) {
			const msg = e?.data.message || "Couldn't delete device";
			displaySnackbar('error', msg);
		}
	};

	return (
		<>
			<EdConfirmModal
				text="Are you sure you want to delete this device?"
				open={openModel}
				handleClose={handleModelClose}
			/>
			<Card key={key}>
				<FlexLayout
					flexDirection="column"
					gridGap="1.875rem"
					alignItems="center"
					justifyContent="center"
				>
					{device.type === 'mobile' ? (
						<PhoneIcon color="disabled" fontSize="large" />
					) : (
						<LapIcon color="disabled" fontSize="large" />
					)}
					<FlexLayout
						flexDirection="column"
						gridGap="0.75rem"
						alignItems="center"
					>
						<Agent>{device.agent}</Agent>
						<Type>{device.type}</Type>
						<LoggedIn
							flexDirection="column"
							gridGap="0.2rem"
							alignItems="center"
							justifyContent="center"
						>
							<p>Last Logged in</p>
							<p>{moment(device.last_login).format('DD/MM hh:mm A')}</p>
						</LoggedIn>
						<LoggedInCount
							flexDirection="column"
							gridGap="0.2rem"
							alignItems="center"
							justifyContent="center"
						>
							<p>Logged in count</p>
							<p>{device.login_count}</p>
						</LoggedInCount>
						<RemoveButton
							variant="text"
							color="warning"
							onClick={() => handleModelOpen()}
						>
							Remove device
						</RemoveButton>
					</FlexLayout>
				</FlexLayout>
			</Card>
		</>
	);
};

const Card = styled.div`
	border-radius: 15px;
	border: solid 1px #cacaca;
	padding: 3.313rem 6.25rem 1rem;
	box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
`;
const PhoneIcon = styled(SmartphoneIcon)`
	font-size: 120px;
	height: 123px;
`;
const LapIcon = styled(LaptopIcon)`
	font-size: 150px;
	height: 123px;
`;
const Agent = styled.p`
	color: #707070;
	font-weight: normal;
	font-size: 1.125rem;
`;
const Type = styled.p`
	color: #251b33;
	font-weight: 600;
	font-size: 1.5rem;
`;
const LoggedIn = styled(FlexLayout)`
	color: #707070;
	font-weight: normal;
	font-size: 1.125rem;
`;
const LoggedInCount = styled(FlexLayout)`
	color: #707070;
	font-weight: normal;
	font-size: 1.125rem;
	margin-top: 0.5rem;
`;
const RemoveButton = styled(Button)`
	text-decoration: underline;
	font-weight: 600;
	font-size: 1rem;
	margin-top: 0.563rem;
	text-transform: none;
	&:focus {
		outline: 0;
	}
	&:hover {
		background: transparent;
		text-decoration: underline;
	}
`;
export default DeviceCard;
