import { HashString } from '@Components/UI/Tabs/RoutedTabs/RoutedTabs.types';
import RoutedTabs from '@Components/UI/Tabs/Tabs';
import { Spacer } from '@Styled/utilities';
import React, { useContext, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { SpaceProps, BorderProps, space, border } from 'styled-system';
import AddClassroomBasicInfo from './Components/AddClassroomBasicInfo/AddClassroomBasicInfo';
import { ClassroomAdmissionPayload } from '@Pages/ClassRooms/Types/ClassroomAdmission.types';
import { ClassroomRequester } from '@Pages/ClassRooms/Services/Classroom/Requests/Classroom.req';
import { ClassroomPayload } from '@Pages/ClassRooms/Types/Classroom.types';
import { ClassroomAdmissionRequester } from '@Pages/ClassRooms/Services/ClassroomAdmission/RequestsHandler/ClassroomAdmission.req';
import { useSnackbar } from '@Providers/useSnackbar';
import { BreadcrumbContext } from '@Components/UI/Layout/Layout';
import ClassroomAdmissionPage from '../Admission/ClassroomAdmissionPage';
const AddClassroom: React.FC = () => {
	const { hash } = useLocation();
	const [tabIndex, setTabIndex] = useState<HashString>(hash as HashString);
	const [hasAdmission, setHasAdmission] = useState(false);
	const [classroomFormData, setClassroomFormData] = useState<
		ClassroomPayload | undefined
	>();
	const [isClassroomFormValid, setIsClassroomFormValid] = useState(false);
	const history = useHistory();
	const { displaySnackbar } = useSnackbar();
	const { removeLink } = useContext(BreadcrumbContext);
	const onBasicInfoNext = (data: ClassroomPayload) => {
		setClassroomFormData(data);
		history.push({ hash: '#admission' });
		setTabIndex('#admission');
	};
	const onAdmissionNext = async (data: ClassroomAdmissionPayload) => {
		if (!classroomFormData) return;
		try {
			const {
				data: {
					data: { id, label, title },
				},
			} = await ClassroomRequester.getInstance().create(classroomFormData);
			if (id) {
				const {
					data: {
						data: { id: adId },
					},
				} = await ClassroomAdmissionRequester.getInstance().create({
					admissionForm: data.admissionForm,
					questions: data.questions,
					classroom_id: id,
				});
				if (adId) {
					displaySnackbar('success', `${title} Created Successfully`);
					removeLink && removeLink('course');
					history.push({
						pathname: `/dashboard/classrooms/classroom/${label}/edit`,
						hash: '#info',
					});
				}
			}
		} catch (error) {}
	};
	return (
		<CardContainer>
			<RoutedTabs withRouter={false}>
				<RoutedTabs.Tabs
					$hashPrefix="#"
					hashes={['#info', '#admission']}
					onChange={(e, value) => setTabIndex(value)}
					orientation="vertical"
					value={tabIndex}
				>
					<RoutedTabs.Item
						value="#info"
						label={<RoutedTabs.ItemTitle>Basic Info</RoutedTabs.ItemTitle>}
						icon={<RoutedTabs.ItemIcon>menu_book</RoutedTabs.ItemIcon>}
					/>
					<RoutedTabs.Item
						value="#admission"
						label={<RoutedTabs.ItemTitle>Admission</RoutedTabs.ItemTitle>}
						disabled={!hasAdmission || !isClassroomFormValid}
						icon={<RoutedTabs.ItemIcon>post_add</RoutedTabs.ItemIcon>}
					/>
				</RoutedTabs.Tabs>
				<Spacer mx={'1rem'} />
				<RoutedTabs.Content value={tabIndex}>
					<RoutedTabs.ContentItem hash="#info">
						<AddClassroomBasicInfo
							onNext={onBasicInfoNext}
							onHasAdmissionChange={(val) => setHasAdmission(val)}
							onIsValidChange={setIsClassroomFormValid}
						/>
					</RoutedTabs.ContentItem>
					<RoutedTabs.ContentItem hash="#admission">
						<ClassroomAdmissionPage mode="create" onNext={onAdmissionNext} />
					</RoutedTabs.ContentItem>
				</RoutedTabs.Content>
			</RoutedTabs>
		</CardContainer>
	);
};

export default AddClassroom;
export const CardContainer = styled.div<SpaceProps & BorderProps>`
	padding: 1.375rem;
	border-radius: 10px;
	border: 1px solid ${(props) => props.theme.palette.primary.main};
	${space};
	${border}
`;
