import { AppSetting } from './Types/AppSetting.types';
import { yupResolver } from '@hookform/resolvers/yup';
import { Checkbox, MenuItem } from '@mui/material';
import { useSnackbar } from '@Providers/useSnackbar';
import { Divider, FlexLayout, GridLayout } from '@Styled/utilities';
import React, { FC, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { SETTINGS_SCHEMA } from './Schema/AppSettings.schema';
import EdFormControl from '@Components/UI/Inputs/EdFormControl/EdFormControl';
import AppSettingsApi, {
	AppSettingsRequester,
} from './Services/AppSettings.requester';
import { Button } from '@mui/material';
import { useHistory } from 'react-router-dom';

type Props = {
	data: AppSetting;
};



const SettingsForm: FC<Props> = ({ data }) => {
	const { displaySnackbar } = useSnackbar();
	const history = useHistory();

	const defaultValues = React.useMemo<AppSetting | undefined>(() => {
		if (data) {
			const defaultT: AppSetting = {
				aman_accept: data?.aman_accept,
				credit_card_accept: data?.credit_card_accept,
				credit_card_opay: data?.credit_card_opay,
				device_limit: data?.device_limit,
				ewallet_accept: data?.ewallet_accept,
				email_verification_required: data?.email_verification_required,
				ewallet_fawry: data?.ewallet_fawry,
				ewallet_opay: data?.ewallet_opay,
				exams_section: data?.exams_section,
				fawry_fawry: data?.fawry_fawry,
				masary_accept: data?.masary_accept,
				parent_portal: data?.parent_portal,
				phone_verification_required: data?.phone_verification_required,
				recharge_card: data?.recharge_card,
				id: data?.id,
				registration_fees: data?.registration_fees,
				revision_section: data?.revision_section,
				surcharge_registration_fees: data?.surcharge_registration_fees,
				whatsapp_token: data?.whatsapp_token,
				theme: data.theme,
				landing_headline: data.landing_headline,
				landing_subheadline: data.landing_subheadline,
				instructor_section: data.instructor_section,
				subjects_section: data.subjects_section,
				about_us_page: data.about_us_page,
				terms_link: data.terms_link,
				privacy_link: data.privacy_link,
				fb_link: data.fb_link,
				youtube_link: data.youtube_link,
				linkedin_link: data.linkedin_link,
				instagram_link: data.instagram_link,
			};
			return defaultT;
		}
	}, [data]);

	const { control, formState, watch, handleSubmit } = useForm<AppSetting>({
		resolver: yupResolver(SETTINGS_SCHEMA),
		mode: 'all',
		defaultValues: defaultValues,
	});

	const watchEmail = watch('email_verification_required');
	const watchPhone = watch('phone_verification_required');
	const watchRevision = watch('revision_section');
	const watchExam = watch('exams_section');
	const watchRecharge = watch('recharge_card');
	const watchCredit = watch('credit_card_accept');
	const watchFawry = watch('fawry_fawry');
	const watchWallet = watch('ewallet_accept');
	const watchWalletFawry = watch('ewallet_fawry');
	const watchOpay = watch('credit_card_opay');
	const watchAman = watch('aman_accept');
	const watchWalletOpay = watch('ewallet_opay');
	const watchMasay = watch('masary_accept');

	const onSave = async (form: AppSetting) => {
		try {
			
			await AppSettingsRequester.getInstance().update(form);
			displaySnackbar('success', `Settings Updated successfully`);
		} catch (e) {
			const msg = e?.data.message || 'Unable to Update Settings ';
			displaySnackbar('error', msg);
		}
	};

	return (
		<SectionLayout
			gridGap={'2rem'}
			gridTemplateColumns={{ sm: 'repeat(1,1fr)' }}
			pb="8rem"
			minHeight="100vh"
			width="80%"
			key={data.id}
		>
			<Divider label="Sign up process" mb="0" pb="0" />
			<FlexLayout
				display="flex"
				alignItems="center"
				gridGap="0.938rem"
				mt="2.625rem"
			>
				<Controller
					name="email_verification_required"
					control={control}
					render={({ field }) => (
						<FlexLayout display="flex" alignItems="center" gridGap="0.938rem">
							<StatusBox
								disabled={true}
								checked={watchEmail ? true : false}
								{...field}
								id="Status"
								defaultChecked={false}
							/>
							<LabelStyle>Email verification required</LabelStyle>
						</FlexLayout>
					)}
				/>
			</FlexLayout>
			<FlexLayout
				display="flex"
				alignItems="center"
				gridGap="0.938rem"
				mt="1.188rem"
			>
				<Controller
					name="phone_verification_required"
					control={control}
					render={({ field }) => (
						<FlexLayout
							display="flex"
							alignItems="center"
							gridGap="0.938rem"
							mt="0.625rem"
						>
							<StatusBox
								disabled={true}
								checked={watchPhone ? true : false}
								{...field}
								id="Status"
								defaultChecked={false}
							/>
							<LabelStyle>Phone verification required</LabelStyle>
						</FlexLayout>
					)}
				/>
			</FlexLayout>
			<FlexLayout justifyContent="space-between" alignItems="center">
				<EdFormControl
					control={control}
					width="4.794rem"
					type="number"
					inputMode="numeric"
					name={`registration_fees`}
					label="Registeration Fees"
					required
				/>
				<EdFormControlStyle
					control={control}
					width="7.794rem"
					type="number"
					inputMode="numeric"
					name={`surcharge_registration_fees`}
					label="Surcharge Registeration fees"
					required
				/>
			</FlexLayout>
			<Divider label="Home Page" mb="0" pb="0" />
			<FlexLayout
				display="flex"
				alignItems="center"
				gridGap="0.938rem"
				mt="2.625rem"
			>
				<Controller
					name="revision_section"
					control={control}
					render={({ field }) => (
						<FlexLayout display="flex" alignItems="center" gridGap="0.938rem">
							<StatusBox
								checked={watchRevision ? true : false}
								{...field}
								id="Status"
								defaultChecked={false}
							/>
							<LabelStyle>Revision section</LabelStyle>
						</FlexLayout>
					)}
				/>
			</FlexLayout>
			<FlexLayout
				display="flex"
				alignItems="center"
				gridGap="0.938rem"
				mt="1.188rem"
			>
				<Controller
					name="exams_section"
					control={control}
					render={({ field }) => (
						<FlexLayout
							display="flex"
							alignItems="center"
							gridGap="0.938rem"
							mt="0.625rem"
						>
							<StatusBox
								checked={watchExam ? true : false}
								{...field}
								id="Status"
								defaultChecked={false}
							/>
							<LabelStyle>Exam section</LabelStyle>
						</FlexLayout>
					)}
				/>
			</FlexLayout>
			<Divider label="Messaging" />
			<EdFormControl
				control={control}
				type="text"
				name={`whatsapp_token`}
				label="Whats app token"
				required
			/>
			<Divider label="Payment Methods" />
			<SectionLayout
				gridGap={'2.188rem !important'}
				gridTemplateColumns={{ sm: 'repeat(2,1fr)' }}
			>
				<FlexLayout
					display="flex"
					alignItems="center"
					gridGap="0.938rem"
					width="100%"
				>
					<Controller
						name="recharge_card"
						control={control}
						render={({ field }) => (
							<FlexLayout display="flex" alignItems="center" gridGap="0.938rem">
								<StatusBox
									checked={watchRecharge ? true : false}
									{...field}
									id="Status"
									defaultChecked={false}
								/>
								<LabelStyle>Recharge Card</LabelStyle>
							</FlexLayout>
						)}
					/>
				</FlexLayout>
				<FlexLayout
					display="flex"
					alignItems="center"
					gridGap="0.938rem"
					width="100%"
				>
					<Controller
						name="credit_card_accept"
						control={control}
						render={({ field }) => (
							<FlexLayout display="flex" alignItems="center" gridGap="0.938rem">
								<StatusBox
									checked={watchCredit ? true : false}
									{...field}
									id="Status"
									defaultChecked={false}
								/>
								<LabelStyle>Credit Card Accept</LabelStyle>
							</FlexLayout>
						)}
					/>
				</FlexLayout>
				<FlexLayout display="flex" alignItems="center" gridGap="0.938rem">
					<Controller
						name="fawry_fawry"
						control={control}
						render={({ field }) => (
							<FlexLayout display="flex" alignItems="center" gridGap="0.938rem">
								<StatusBox
									checked={watchFawry ? true : false}
									{...field}
									id="Status"
									defaultChecked={false}
								/>
								<LabelStyle>Fawry Fawry</LabelStyle>
							</FlexLayout>
						)}
					/>
				</FlexLayout>
				<FlexLayout display="flex" alignItems="center" gridGap="0.938rem">
					<Controller
						name="ewallet_accept"
						control={control}
						render={({ field }) => (
							<FlexLayout display="flex" alignItems="center" gridGap="0.938rem">
								<StatusBox
									checked={watchWallet ? true : false}
									{...field}
									id="Status"
									defaultChecked={false}
								/>
								<LabelStyle>E Wallet Accept</LabelStyle>
							</FlexLayout>
						)}
					/>
				</FlexLayout>
				<FlexLayout display="flex" alignItems="center" gridGap="0.938rem">
					<Controller
						name="ewallet_fawry"
						control={control}
						render={({ field }) => (
							<FlexLayout display="flex" alignItems="center" gridGap="0.938rem">
								<StatusBox
									checked={watchWalletFawry ? true : false}
									{...field}
									id="Status"
									defaultChecked={false}
								/>
								<LabelStyle>E Wallet Fawry</LabelStyle>
							</FlexLayout>
						)}
					/>
				</FlexLayout>
				<FlexLayout display="flex" alignItems="center" gridGap="0.938rem">
					<Controller
						name="credit_card_opay"
						control={control}
						render={({ field }) => (
							<FlexLayout display="flex" alignItems="center" gridGap="0.938rem">
								<StatusBox
									checked={watchOpay ? true : false}
									{...field}
									id="Status"
									defaultChecked={false}
								/>
								<LabelStyle>Credit Card Opay</LabelStyle>
							</FlexLayout>
						)}
					/>
				</FlexLayout>
				<FlexLayout display="flex" alignItems="center" gridGap="0.938rem">
					<Controller
						name="aman_accept"
						control={control}
						render={({ field }) => (
							<FlexLayout display="flex" alignItems="center" gridGap="0.938rem">
								<StatusBox
									checked={watchAman ? true : false}
									{...field}
									id="Status"
									defaultChecked={false}
								/>
								<LabelStyle>Aman Accept</LabelStyle>
							</FlexLayout>
						)}
					/>
				</FlexLayout>
				<FlexLayout display="flex" alignItems="center" gridGap="0.938rem">
					<Controller
						name="ewallet_opay"
						control={control}
						render={({ field }) => (
							<FlexLayout display="flex" alignItems="center" gridGap="0.938rem">
								<StatusBox
									checked={watchWalletOpay ? true : false}
									{...field}
									id="Status"
									defaultChecked={false}
								/>
								<LabelStyle>E Wallet Opay</LabelStyle>
							</FlexLayout>
						)}
					/>
				</FlexLayout>
				<FlexLayout display="flex" alignItems="center" gridGap="0.938rem">
					<Controller
						name="masary_accept"
						control={control}
						render={({ field }) => (
							<FlexLayout display="flex" alignItems="center" gridGap="0.938rem">
								<StatusBox
									checked={watchMasay ? true : false}
									{...field}
									id="Status"
									defaultChecked={false}
								/>
								<LabelStyle>Masary Accept</LabelStyle>
							</FlexLayout>
						)}
					/>
				</FlexLayout>
			</SectionLayout>
			<Divider label="Devices" />
			<EdFormControl
				control={control}
				width="4.794rem"
				type="number"
				inputMode="numeric"
				name={`device_limit`}
				label="Trusted devices limit"
				required
			/>
			<Divider label="Theme" />
			<EdFormControl
				title="Theme"
				label={'Theme'}
				select
				control={control}
				name="theme"
			>
				<MenuItem value="primary">Primary</MenuItem>
				<MenuItem value="secondary">Secondary</MenuItem>
			</EdFormControl>

			<Divider label="Home Page" />
			<SectionLayout
				gridGap={'2.188rem !important'}
				gridTemplateColumns={{ sm: 'repeat(2,1fr)' }}
			>
				<EdFormControl
					label="Headline"
					control={control}
					name="landing_headline"
				/>
				<EdFormControl
					label="Sub Headline"
					control={control}
					name="landing_subheadline"
				/>
			</SectionLayout>

			<Divider label="Sections" />
			<SectionLayout
				gridGap={'2.188rem !important'}
				gridTemplateColumns={{ sm: 'repeat(2,1fr)' }}
			>
				<Controller
					name="instructor_section"
					control={control}
					render={({ field }) => (
						<FlexLayout display="flex" alignItems="center" gridGap="0.938rem">
							<StatusBox
								checked={field.value}
								{...field}
								defaultChecked={false}
							/>
							<LabelStyle>Instructors Section</LabelStyle>
						</FlexLayout>
					)}
				/>
				<Controller
					name="subjects_section"
					control={control}
					render={({ field }) => (
						<FlexLayout display="flex" alignItems="center" gridGap="0.938rem">
							<StatusBox
								checked={field.value}
								{...field}
								defaultChecked={false}
							/>
							<LabelStyle>Subjects Section</LabelStyle>
						</FlexLayout>
					)}
				/>
			</SectionLayout>
			<Divider label="Social Media" />
			<SectionLayout
				gridGap={'2.188rem !important'}
				gridTemplateColumns={{ sm: 'repeat(2,1fr)' }}
			>
				<EdFormControl label="Facebook" control={control} name="fb_link" />
				<EdFormControl label="Youtube" control={control} name="youtube_link" />
				<EdFormControl
					label="Linkedin"
					control={control}
					name="linkedin_link"
				/>
				<EdFormControl
					label="Instagram"
					control={control}
					name="instagram_link"
				/>
			</SectionLayout>

			<Divider label="Terms & Privacy" />
			<SectionLayout
				gridGap={'2.188rem !important'}
				gridTemplateColumns={{ sm: 'repeat(2,1fr)' }}
			>
				<EdFormControl
					label="Terms & Conditions"
					control={control}
					name="terms_link"
				/>
				<EdFormControl label="Privacy" control={control} name="privacy_link" />
			</SectionLayout>

			<FlexLayout justifyContent="flex-end" gridGap="0.8rem" mt="2rem">
				<Button
					variant="contained"
					color="warning"
					onClick={() => history.push('/')}
				>
					Cancel
				</Button>
				<Button
					onClick={handleSubmit(onSave, (e) => console.log(e))}
					variant="contained"
				>
					Save
				</Button>
			</FlexLayout>
		</SectionLayout>
	);
};

const SectionLayout = styled(GridLayout)`
	grid-gap: 2rem;
`;
const StatusBox = styled(Checkbox)`
	padding: 0;
	svg,
	input {
		width: 20px;
		height: 20px;
	}
`;
const LabelStyle = styled.label`
	font-size: 0.9rem;
	color: ${(props) => props.theme.palette.cadet.main};
	font-weight: normal;
	margin-top: 2px;
`;
const EdFormControlStyle = styled(EdFormControl)`
	.MuiInputBase-root {
		margin-inline-start: 3rem !important;
	}
`;

export default SettingsForm;
