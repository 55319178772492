import React, { useMemo } from 'react';
import { InputLabel, styled, TextField } from '@mui/material';
import {
	DatePicker,
	DatePickerProps,
	LocalizationProvider,
} from '@mui/x-date-pickers';
import '@mui/lab';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Controller } from 'react-hook-form';
import { RangePickerProps } from '@Components/UI/Pickers/Pickers.types';
import { orange } from '@mui/material/colors';
import { FlexLayout } from '@Styled/utilities';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

type Props = {
	disabled?: boolean;
};
const PickerDate: React.FC<
	RangePickerProps & Partial<DatePickerProps<any, any>> & Props
> = ({ control, name, disabled }) => {
	const minDate = useMemo(() => {
		const tempDate = new Date();
		tempDate.getDate();
		return tempDate;
	}, []);

	return (
		<Controller
			control={control}
			name={name}
			render={({ field: { value, ...controlProps }, fieldState }) => {
				return (
					<FlexStyle alignItems="center" justifyContent="space-between">
						<LocalizationProvider dateAdapter={AdapterDayjs}>
							<DatePicker
								value={value ?? null}
								{...controlProps}
								maxDate={minDate}
								views={['day', 'month', 'year']}
								disabled={disabled}
								renderInput={(params: any) => (
									<>
										<LabelStyle> Birth date </LabelStyle>
										<TextField {...params} error={fieldState.invalid} />
									</>
								)}
							/>
						</LocalizationProvider>
					</FlexStyle>
				);
			}}
		/>
	);
};

const FlexStyle = styled(FlexLayout)`
	.MuiFormControl-root {
		margin: 0 8px;
	}
	.MuiOutlinedInput-root {
		height: 34px;
		width: 16.25rem;
	}
`;

const LabelStyle = styled(InputLabel)`
	font-size: 1rem;
	font-weight: 500;
	color: #251b33;
	span {
		color: ${orange['500']};
	}
`;

export default PickerDate;
