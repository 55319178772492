import DateTimeFormatter from '@Components/DateTimeFormatter/DateTimeFormatter';
import MainTable from '@Components/MainTable';
import { MainTableRow } from '@Components/MainTable/MainTable';
import { onPageChangeRequestMeta } from '@Components/MainTable/MainTable.types';
import NoResults from '@Components/NoResults/NoResults';
import RoundButton from '@Components/UI/Buttons/RoundButton';
import EdConfirmModal from '@Components/UI/Modals/EdConfirmModal/EdConfirmModal';
import EdActiveStatus from '@Components/UI/Utilities/EdActiveStatus/EdActiveStatus';
import EdIcon from '@Components/UI/Utilities/EdIcon/EdIcon';
import EdSpeedDial, {
	EdSpeedDialAction,
} from '@Components/UI/Utilities/EdSpeedDial/EdSpeedDial';
import { useTypedSelector } from '@Features/store';
import { Button, TableCell, Tooltip } from '@mui/material';
import DeleteWalletModeule from '@Pages/RechargeCodes/components/DeleteWalletModeule';
import GenerateWalletCodesModule from '@Pages/RechargeCodes/components/GenerateWalletCodesModule';
import {
	rechargeCodesDeleteById,
	rechargeCodesExportData,
	rechargeCodesGetAll,
} from '@Pages/RechargeCodes/Slices/RechargeCodes.slice';
import { RechargeCode } from '@Pages/RechargeCodes/Types/RechargeCodes.types';
import { usePageLoader } from '@Providers/LoadingProvider';
import { useSnackbar } from '@Providers/useSnackbar';
import { FlexLayout } from '@Styled/utilities';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

const Table_Heads: Array<TableHeader> = [
	{
		displayName: 'Code',
		fieldName: 'code',
	},
	{
		fieldName: 'batch',
		displayName: 'batch',
	},
	{
		displayName: 'value',
		fieldName: 'value',
	},
	{
		displayName: 'used',
		fieldName: 'user_id',
	},
	{
		displayName: 'used at',
		fieldName: 'updated_at',
	},
	{
		displayName: 'email',
		fieldName: 'email',
	},
	{
		displayName: 'created at',
		fieldName: 'created_at',
	},
];
const RechargeCodesTable = () => {
	const dispatch = useDispatch();
	const [doExport, setDoExport] = useState(false);
	const { setIsLoading } = usePageLoader();
	const { displaySnackbar } = useSnackbar();

	const {
		meta,
		filters,
		fetchAll,
		dateRange,
		selectedFilters,
		query,
		sortBy,
		loaders,
		requestStatus,
		errors,
	} = useTypedSelector((state) => state.RechargeCodes);
	const auth = useTypedSelector((state) => state.auth);
	const [openGenerateModal, setOpenGenerateModal] = useState(false);
	const [openDeleteModel, setOpenDeleteModel] = useState(false);
	const onPageRequestCallback = useCallback(
		({
			activePage,
			endDate,
			filters,
			query,
			sortBy,
			startDate,
			export: exportFlag,
			perPage,
		}: onPageChangeRequestMeta) => {
			dispatch(
				rechargeCodesGetAll({
					filters,
					page: activePage,
					perPage: perPage ?? 10,
					query,
					sortBy,
					from: startDate,
					to: endDate,
				})
			);
		},
		[dispatch]
	);

	const handleDeleteModelClose = () => {
		setOpenDeleteModel(false);
	};

	const [toBeDeleteCode, setToBeDeletedCode] = useState<
		RechargeCode | undefined
	>();
	const onDeleteCode = useCallback((code: RechargeCode) => {
		setToBeDeletedCode(code);
	}, []);

	const onConfirmDeleteCode = (close: boolean, confirm?: boolean) => {
		if (confirm && toBeDeleteCode?.id)
			dispatch(rechargeCodesDeleteById(toBeDeleteCode.id));
		setToBeDeletedCode(undefined);
	};

	useEffect(() => {
		if (loaders.export) {
			setIsLoading(true);
		}
		if (loaders.export === undefined) setIsLoading(false);
	}, [loaders.export, errors.export, setIsLoading]);
	useEffect(() => {
		if (requestStatus.export === 'DONE') {
			displaySnackbar('success', 'Exported Successfully');
		}
		if (requestStatus.export === 'FAILED') {
			displaySnackbar('error', 'Can Not Export!');
		}
	}, [displaySnackbar, requestStatus.export]);

	useEffect(() => {
		if (doExport) setDoExport(false);
	}, [doExport]);

	return (
		<div>
			<EdConfirmModal
				open={toBeDeleteCode ? true : false}
				handleClose={onConfirmDeleteCode}
				text="Are you sure to delete recharge code"
			/>
			<GenerateWalletCodesModule
				handleModelClose={setOpenGenerateModal}
				onConfirmation={setOpenGenerateModal}
				openModel={openGenerateModal}
			/>
			<DeleteWalletModeule
				onSuccessCallback={onPageRequestCallback}
				onConfirmation={handleDeleteModelClose}
				openModel={openDeleteModel}
				handleModelClose={handleDeleteModelClose}
			/>
			<FlexLayout justifyContent="flex-end">
				{(auth.user.type === 'super' || auth.permissions.has('exp-rchc')) && (
					<RoundButton
						onClick={() => {
							if (rechargeCodesExportData)
								dispatch(
									rechargeCodesExportData({
										filters: selectedFilters,
										page: meta?.current_page ?? 1,
										perPage: meta?.per_page ?? 10,
										query: query ?? '',
										sortBy,
										from: dateRange.from,
										to: dateRange.to,
									})
								);
						}}
						sx={{ marginRight: '1rem' }}
						startIcon={<EdIcon>south</EdIcon>}
						variant="contained"
					>
						Export
					</RoundButton>
				)}
				{(auth.user.type === 'super' || auth.permissions.has('gen-rchc')) && (
					<RoundButton
						edcolor="purple"
						startIcon={<EdIcon>add</EdIcon>}
						variant="contained"
						onClick={() => setOpenGenerateModal(true)}
					>
						Generate codes
					</RoundButton>
				)}
			</FlexLayout>
			<MainTable
				renderItems={() => {
					if (fetchAll.length === 0) {
						return (
							<MainTableRow>
								<TableCell colSpan={Table_Heads.length}>
									<NoResults />
								</TableCell>
							</MainTableRow>
						);
					} else {
						return fetchAll.map((re, index) => {
							return (
								<MainTableRow>
									<Tooltip title={re.code} placement="top">
										<TableCell>{re.code}</TableCell>
									</Tooltip>
									<TableCell>{re.batch}</TableCell>
									<TableCell>{re.value}</TableCell>
									<TableCell>
										<EdActiveStatus active={re.user_id ? true : false} />
									</TableCell>
									<TableCell>
										{re.user_id !== null ? (
											<DateTimeFormatter date={re.updated_at} />
										) : (
											'-'
										)}
									</TableCell>

									<Tooltip title={re.email} placement="top">
										<TableCell>{re.email}</TableCell>
									</Tooltip>
									<Tooltip
										title={<DateTimeFormatter date={re.created_at} />}
										placement="top"
									>
										<TableCell>
											<DateTimeFormatter date={re.created_at} />
										</TableCell>
									</Tooltip>
									<TableCell>
										{(auth.user.type === 'super' ||
											auth.permissions.has('d-rchc')) && (
											<EdSpeedDial ariaLabel="Actions">
												<EdSpeedDialAction
													onClick={() => onDeleteCode(re)}
													key="delete"
													tooltipTitle="Delete"
													icon={<EdIcon>delete</EdIcon>}
													aria-label=""
												/>
											</EdSpeedDial>
										)}
									</TableCell>
								</MainTableRow>
							);
						});
					}
				}}
				tableFilters={filters}
				tableHeads={Table_Heads}
				totalPages={meta?.last_page ?? 0}
				onPageChangeRequest={onPageRequestCallback}
				RowsperPage={meta?.per_page}
				pageNum={meta?.current_page}
				$hasActions
				total={meta?.total}
			/>
			{(auth.user.type === 'super' || auth.permissions.has('d-rchc')) && (
				<FlexLayout justifyContent="flex-end">
					<Button
						onClick={() => setOpenDeleteModel(true)}
						color="warning"
						variant="contained"
					>
						Delete Codes
					</Button>
				</FlexLayout>
			)}
		</div>
	);
};

export default RechargeCodesTable;
