import {
	CRUDBase,
	DeleteSingleEntityPayload,
	GenerateCardMeta,
	GenerateCodesMeta,
	GetAllMeta,
	GetSingleEntityPayload,
} from '@Base/services/services.base';
import api from '@Services/api';
import { AxiosResponse } from 'axios';
import { TestPayload, Test } from '../../../../Types/Test/Test.types';

interface TestCRUD extends CRUDBase<TestPayload, Test> {
	activateTest(id: number): Promise<AxiosResponse<ApiResponse<any>>>;
	deActivateTest(id: number): Promise<AxiosResponse<ApiResponse<any>>>;
}
export class TestRequester implements TestCRUD {
	public static instance: TestRequester = new TestRequester();
	baseURL = 'test/';

	async activateTest(id: number): Promise<AxiosResponse<ApiResponse<any>>> {
		return await api.post(`${this.baseURL}activate`, { id });
	}
	async deActivateTest(id: number): Promise<AxiosResponse<ApiResponse<any>>> {
		return await api.post(`${this.baseURL}deactivate`, { id });
	}
	export?(payload: GetAllMeta): Promise<AxiosResponse<string>> {
		throw new Error('Method not implemented.');
	}
	generate?(
		payload: GenerateCodesMeta | GenerateCardMeta
	): Promise<AxiosResponse<string>> {
		throw new Error('Method not implemented.');
	}
	toggleActive?(
		payload: TestPayload
	): Promise<AxiosResponse<ApiResponse<Test>>> {
		throw new Error('Method not implemented.');
	}
	process?(id: number): Promise<AxiosResponse<ApiResponse<any>>> {
		throw new Error('Method not implemented.');
	}
	get(id: string): Promise<AxiosResponse<ApiResponse<Test>>>;
	get(id: number): Promise<AxiosResponse<ApiResponse<Test>>>;
	get(
		id: string | number,
		payload?: GetSingleEntityPayload
	): Promise<AxiosResponse<ApiResponse<Test>>>;
	async get(id: any, payload?: any): Promise<AxiosResponse<ApiResponse<Test>>> {
		return await api.post(`${this.baseURL}get`, { id });
	}
	getAll(payload?: GetAllMeta): Promise<AxiosResponse<ApiResponse<Test[]>>>;
	getAll(payload: number): Promise<AxiosResponse<ApiResponse<Test[]>>>;
	getAll(payload?: any): Promise<AxiosResponse<ApiResponse<Test[]>>> {
		throw new Error('Method not implemented.');
	}
	async create(
		payload: Omit<TestPayload, 'id'>
	): Promise<AxiosResponse<ApiResponse<Test>>> {
		return await api.post(`${this.baseURL}create`, payload);
	}
	async update(
		payload: TestPayload
	): Promise<AxiosResponse<ApiResponse<Test>>> {
		return await api.post(`${this.baseURL}update`, payload);
	}
	delete(id: number): Promise<AxiosResponse<ApiResponse<any>>>;
	delete(id: string): Promise<AxiosResponse<ApiResponse<any>>>;
	delete(
		id: string | number,
		payload?: DeleteSingleEntityPayload
	): Promise<AxiosResponse<ApiResponse<any>>>;
	delete(id: any, payload?: any): Promise<AxiosResponse<ApiResponse<any>>> {
		throw new Error('Method not implemented.');
	}
}
