import MainTable from '@Components/MainTable';
import { MainTableRow } from '@Components/MainTable/MainTable';
import { onPageChangeRequestMeta } from '@Components/MainTable/MainTable.types';
import NoResults from '@Components/NoResults/NoResults';
import { useTypedSelector } from '@Features/store';
import { Chip, TableCell, Tooltip } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { invoicesTabGetAll } from './Slices/Invoices.Slice';
import fawryImage from '@Pages/Invoices/Assets/FawryImage.png';
import visaImage from '@Pages/Invoices/Assets/VisaImage.png';
import amanImage from '@Pages/Invoices/Assets/AmanImage.png';
import walletImage from '@Pages/Invoices/Assets/WalletImage.png';
import masaryImage from '@Pages/Invoices/Assets/MasaryImage.png';
import vodafonImage from '@Pages/Invoices/Assets/VodafoneImage.png';
import DateTimeFormatter from '@Components/DateTimeFormatter/DateTimeFormatter';
import EdSpeedDial, {
	EdSpeedDialAction,
} from '@Components/UI/Utilities/EdSpeedDial/EdSpeedDial';
import EdIcon from '@Components/UI/Utilities/EdIcon/EdIcon';
import EdConfirmModal from '@Components/UI/Modals/EdConfirmModal/EdConfirmModal';
import useFilters from '@Hooks/useFilters';
import useQueryURL from '@Hooks/useQueryUrl';
import { useHistory } from 'react-router-dom';
import { processInvoice } from '@Pages/Invoices/Services/Invoices.req';

const TABLE_HEADS = [
	{ displayName: 'Transaction ID', fieldName: 'transaction_id' },
	{ displayName: 'Invoice ref', fieldName: 'invoice_ref' },
	{ displayName: 'Type', fieldName: 'type' },
	{ displayName: 'Status', fieldName: 'status' },
	{ displayName: 'Method', fieldName: 'method' },
	{
		displayName: 'Created at',
		fieldName: 'created_at',
	},
	{ displayName: 'Expiry Date', fieldName: 'expiry_date' },
	{
		displayName: 'Price',
		fieldName: 'price',
	},
	{
		displayName: 'Tax',
		fieldName: 'tax',
	},
];
const InvoicesTable = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const { selectedFilters } = useFilters();
	const { selectedQuery } = useQueryURL();
	const [processId, setProcessId] = useState<any>(null);
	const [openProcessByRowModel, setOpenProcessByRowModel] =
		useState<boolean>(false);
	const { currentEntity } = useTypedSelector((state) => state.Students);
	const { fetchAll, meta, filters } = useTypedSelector(
		(state) => state.InvoicesTab
	);

	const onPageChangeRequest = useCallback(
		({
			activePage,
			endDate,
			filters,
			query,
			sortBy,
			startDate,
			perPage,
		}: onPageChangeRequestMeta) => {
			if (currentEntity?.id)
				dispatch(
					invoicesTabGetAll({
						page: activePage,
						perPage: perPage ?? 10,
						sortBy,
						filters,
						query,
						from: startDate,
						to: endDate,
						id: currentEntity?.id as any,
					})
				);
		},
		[dispatch, currentEntity?.id]
	);
	const handleProcessClick = (id: number) => {
		setProcessId(id);
		setOpenProcessByRowModel(true);
	};
	const handleProcessModelClose = async (
		close: boolean,
		confirmation?: boolean
	) => {
		setOpenProcessByRowModel(close);
		if (confirmation) {
			await processInvoice(processId);
			dispatch(
				invoicesTabGetAll({
					page: 1,
					perPage: 10,
					sortBy: '',
					filters: selectedFilters,
					query: selectedQuery,
					id: currentEntity?.id as any,
				})
			);
		}
	};

	return (
		<>
			<EdConfirmModal
				open={openProcessByRowModel}
				handleClose={handleProcessModelClose}
				text={'Are you sure you want to process invoice'}
			/>
			<MainTable
				total={meta?.total ?? 0}
				dateFilter
				pageNum={meta?.current_page}
				tableFilters={filters}
				tableHeads={TABLE_HEADS}
				totalPages={meta?.last_page ?? 0}
				RowsperPage={Number(meta?.per_page)}
				$hasActions
				onPageChangeRequest={onPageChangeRequest}
				renderItems={() => {
					if (fetchAll?.length === 0)
						return (
							<MainTableRow>
								<TableCell colSpan={TABLE_HEADS.length}>
									<NoResults />
								</TableCell>
							</MainTableRow>
						);
					return fetchAll?.map((invoice) => {
						return (
							<MainTableRow
								key={invoice.id}
								hover
								onClick={() => {
									history.push({
										pathname: `invoice/${invoice.id}`,
									});
								}}
							>
								<TableCell>{invoice.transaction.id}</TableCell>
								<Tooltip title={invoice.invoice_ref} placement="top">
									<TableCell>{invoice.invoice_ref}</TableCell>
								</Tooltip>
								<TableCell>
									{invoice?.type?.toLowerCase() === 'purchase' ? (
										<StyledOutlinedChip
											label={invoice.type}
											variant="outlined"
										/>
									) : invoice?.type?.toLowerCase() === 'recharge' ? (
										<StyledChip label={invoice.type} />
									) : invoice?.type?.toLowerCase() === 'registration' ? (
										<RegisterationChip label={invoice.type} />
									) : (
										<p>-</p>
									)}
								</TableCell>
								<TableCell>
									{invoice.status?.toLowerCase() === 'paid' ? (
										<StyledStatusText textColor={'#00d66b'}>
											{invoice.status}
										</StyledStatusText>
									) : invoice.status?.toLowerCase() === 'new' ||
									  invoice.status?.toLowerCase() === 'pending' ? (
										<StyledStatusText textColor={'#2196f3'}>
											{invoice.status}
										</StyledStatusText>
									) : ['expired', 'unpaid', 'failed'].includes(
											invoice.status?.toLowerCase()
									  ) ? (
										<StyledStatusText textColor={'#ff3100'}>
											{invoice.status}
										</StyledStatusText>
									) : (
										<p>-</p>
									)}
								</TableCell>
								<TableCell>
									{invoice &&
									invoice?.transaction?.method?.toUpperCase() === 'WALLET' ? (
										<StyledImage src={walletImage} />
									) : invoice &&
									  invoice?.transaction?.method?.toUpperCase() === 'CARD' ? (
										<StyledImage src={visaImage} />
									) : invoice.transaction?.method?.toUpperCase() ===
									  'PAYATMASARY' ? (
										<StyledImage src={masaryImage} />
									) : invoice.transaction?.method?.toUpperCase() ===
									  'PAYATFAWRY' ? (
										<StyledImage src={fawryImage} />
									) : invoice.transaction?.method?.toUpperCase() ===
									  'EWALLET' ? (
										<StyledImage src={vodafonImage} />
									) : invoice.transaction?.method?.toUpperCase() ===
									  'PAYATAMAN' ? (
										<StyledImage src={amanImage} />
									) : (
										<p>-</p>
									)}
								</TableCell>
								<Tooltip
									title={
										<DateTimeFormatter date={invoice.transaction.created_at} />
									}
									placement="top"
								>
									<TableCell>
										<DateTimeFormatter date={invoice.transaction.created_at} />
									</TableCell>
								</Tooltip>
								<Tooltip
									title={
										<DateTimeFormatter
											date={new Date(invoice.transaction.expiry_date)}
										/>
									}
									placement="top"
								>
									<TableCell>
										<DateTimeFormatter
											date={new Date(invoice.transaction.expiry_date)}
										/>
									</TableCell>
								</Tooltip>
								<Tooltip title={`${invoice?.price} EGP`} placement="top">
									<TableCell>{`${invoice?.price} EGP` || '-'}</TableCell>
								</Tooltip>
								<Tooltip title={`${invoice?.tax} EGP`} placement="top">
									<TableCell>{`${invoice?.tax} EGP` || '-'}</TableCell>
								</Tooltip>
								<TableCell>
									{invoice.status.toLowerCase() !== 'paid' && (
										<EdSpeedDial ariaLabel="Actions" sx={{ width: '76px' }}>
											<EdSpeedDialAction
												onClick={(e) => {
													e.stopPropagation();
													handleProcessClick(invoice.id);
												}}
												key="process"
												tooltipTitle="process"
												icon={<EdIcon>keyboard_double_arrow_right</EdIcon>}
												aria-label=""
											/>
										</EdSpeedDial>
									)}
								</TableCell>
							</MainTableRow>
						);
					});
				}}
			/>
		</>
	);
};

const StyledOutlinedChip = styled(Chip)`
	color: ${(props) => props.theme.palette?.purple?.main};
	border: ${(props) => props.theme.palette?.purple?.main} 1px solid;
	font-weight: 600;
	height: 22px;
`;
const StyledChip = styled(Chip)`
	background-color: ${(props) => props.theme.palette?.purple?.main};
	color: white;
	font-weight: 600;
	height: 22px;
`;
const RegisterationChip = styled(Chip)`
	background-color: ${(props) => props.theme.palette?.mayaBlue.main};
	color: white;
	font-weight: 600;
	height: 22px;
`;
const StyledImage = styled.img`
	width: 66px;
	height: 34px;
`;
const StyledStatusText = styled.p<{ textColor: string }>`
	color: ${(props) => props.textColor};
	font-weight: 600;
`;
export default InvoicesTable;
