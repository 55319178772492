import api from '@Services/api';
import { getAllIPsResponse } from './IPs.req.types';

export const GetAllIPs = async (
	page: number,
	perPage: number,
	filters: Array<any>,
	sortBy: any,
	query: string,
	from?: Date,
	to?: Date
) => {
	const reqBody = { page, perPage, filters, sortBy, query, from, to };
	return await api.post<getAllIPsResponse>('user/ip/fetch', reqBody);
};
