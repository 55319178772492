import {
	CRUDBase,
	DeleteSingleEntityPayload,
	GetAllMeta,
	GetSingleEntityPayload,
} from '@Base/services/services.base';
import api from '@Services/api';
import { AxiosResponse } from 'axios';
import { Tag } from '../../Types/Tags';

export class TagsRequester implements CRUDBase<Tag> {
	public static instance: TagsRequester = new TagsRequester();
	baseURL = 'test/tag/';
	getAll(payload?: GetAllMeta): Promise<AxiosResponse<ApiResponse<Tag[]>>>;
	getAll(payload: number): Promise<AxiosResponse<ApiResponse<Tag[]>>>;
	async getAll(
		payload?: any
	): Promise<import('axios').AxiosResponse<ApiResponse<Tag[]>>> {
		return await api.post(`${this.baseURL}get`, payload);
	}
	get(id: string): Promise<AxiosResponse<ApiResponse<Tag>>>;
	get(id: number): Promise<AxiosResponse<ApiResponse<Tag>>>;
	get(
		id: string | number,
		payload?: GetSingleEntityPayload
	): Promise<AxiosResponse<ApiResponse<Tag>>>;
	async get(
		id: any,
		payload?: any
	): Promise<import('axios').AxiosResponse<ApiResponse<Tag>>> {
		return await api.post(`${this.baseURL}get`, { id });
	}
	async create(
		payload: Tag
		// payload: Omit<Tag, "id">
	): Promise<AxiosResponse<ApiResponse<Tag>>> {
		return await api.post(`${this.baseURL}create`, payload);
	}
	async update(payload: Tag): Promise<AxiosResponse<ApiResponse<Tag>>> {
		return await api.put(`${this.baseURL}update`, payload);
	}
	delete(id: number): Promise<AxiosResponse<ApiResponse<any>>>;
	delete(id: string): Promise<AxiosResponse<ApiResponse<any>>>;
	delete(
		id: string | number,
		payload?: DeleteSingleEntityPayload
	): Promise<AxiosResponse<ApiResponse<any>>>;
	async delete(
		id: any,
		payload?: any
	): Promise<import('axios').AxiosResponse<ApiResponse<any>>> {
		return await api.delete(`${this.baseURL}delete`, { data: { id } });
	}
}
