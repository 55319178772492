import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
export const MenuCloseIcon: React.FC<SvgIconProps> = ({ ...props }) => (
	<SvgIcon width="24px" {...props}>
		<g>
			<rect fill="none" height="24" width="24" />
		</g>
		<g>
			<g>
				<polygon points="17.59,18 19,16.59 14.42,12 19,7.41 17.59,6 11.59,12" />
				<polygon points="11,18 12.41,16.59 7.83,12 12.41,7.41 11,6 5,12" />
			</g>
		</g>
	</SvgIcon>
);
