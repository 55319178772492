import { useTypedSelector } from '@Features/store';
import { Button, FormControl, MenuItem, Modal, TextField } from '@mui/material';
import { FlexLayout, Spacer } from '@Styled/utilities';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import {
	CourseSingleTest,
	CourseTestsSummary,
} from '../../../Types/TestReports.types';
import { testSummaryGetSingle } from '../../../Slices/TestSummary.slice';
import Answers from './TestSummary/Answers';
import PreviousNextSlots from './PreviousNextSlots';
import { DropDownTestAttempts } from '@Features/dropdowns/dropdownsSlice.types';
import moment from 'moment';

type Props = {
	openModel: boolean;
	handleModelClose: React.Dispatch<boolean>;
	user: CourseSingleTest | undefined;
	testInfo: CourseTestsSummary | undefined;
	attempts: Array<DropDownTestAttempts> | undefined;
	setUserId: any;
};

const TestAttemptModal: FC<Props> = ({
	openModel,
	handleModelClose,
	user,
	testInfo,
	attempts,
	setUserId,
}) => {
	const [attemptId, setAttemptId] = useState<number | undefined>(
		attempts?.[0]?.id
	);

	const dispatch = useDispatch();
	const {
		currentEntity,
		loaders: { getSingle },
	} = useTypedSelector((state) => state.TestSummary);

	useEffect(() => {
		if (attemptId) {
			dispatch(testSummaryGetSingle({ id: attemptId }));
		}
	}, [attemptId]);

	const renderStatus = (status: string) => {
		let color: string;
		switch (status) {
			case 'passed':
				return (color = '#00d66b');
			case 'failed':
				return (color = '#ff3100');
			default:
				return;
		}
	};

	const percentage = useMemo(() => {
		if (!currentEntity) return;
		const score = currentEntity?.attempt?.score as number;
		const overallScore = currentEntity?.attempt?.overall_score as number;
		return Math.round((score * 100) / overallScore).toFixed(1);
	}, [currentEntity]);

	useEffect(() => {
		setAttemptId(user?.id);
	}, [user?.id]);

	return (
		<Modal
			open={openModel}
			onClose={() => {
				handleModelClose(false);
			}}
		>
			<ModelContainer>
				<Container>
					<FlexLayout
						gridGap="1.5rem"
						alignItems="baseline"
						width="fit-content"
						mb="0.875rem"
					>
						<Name>{testInfo?.name}</Name>
						<Status
							background={
								currentEntity &&
								renderStatus(currentEntity?.attempt?.status as string)
							}
						>
							{currentEntity?.attempt?.status}
						</Status>
					</FlexLayout>
					<FlexLayout
						alignItems="center"
						justifyContent="space-between"
						mb="1.475rem"
					>
						<FlexLayout
							alignItems="center"
							justifyContent="start"
							gridGap="5.781rem"
						>
							<Paragraph>
								test taken on :{' '}
								{!currentEntity?.attempt?.created_at ? (
									'-'
								) : (
									<Span>
										{moment(currentEntity?.attempt?.created_at).format(
											'DD/MM/yyyy, hh:mm A'
										)}
									</Span>
								)}
							</Paragraph>
							<Paragraph>
								test score :{' '}
								<Span>
									{currentEntity?.attempt?.score}/
									{currentEntity?.attempt?.overall_score} ({' '}
									<Percentage>{percentage}%</Percentage> )
								</Span>
							</Paragraph>
						</FlexLayout>
						<FormControl variant="outlined">
							<TextField
								select
								onChange={(e) => {
									setAttemptId(Number(e.target.value));
								}}
								value={attemptId}
								size="small"
							>
								{attempts?.map((attempt, index) => (
									<MenuItem key={`${index}`} value={attempt?.id}>
										Attempt #{index + 1} -
										{moment(currentEntity?.attempt?.created_at).format(
											'DD/MM/yyyy, hh:mm A'
										)}
									</MenuItem>
								))}
							</TextField>
						</FormControl>
					</FlexLayout>
					<FlexLayout
						alignItems="center"
						justifyContent="start"
						gridGap="1.981rem"
					>
						<Paragraph>
							username :<Span>{user?.username}</Span>
						</Paragraph>
						<Paragraph>
							first name :<Span>{user?.first_name}</Span>
						</Paragraph>
						<Paragraph>
							last name :<Span>{user?.last_name}</Span>
						</Paragraph>
						<Paragraph>
							phone number :<Span>{user?.phone_number}</Span>
						</Paragraph>
						<Paragraph>
							parent phone :<Span>{user?.parent_phone}</Span>
						</Paragraph>
					</FlexLayout>
					<Space marginTop="1.263rem" />
					{currentEntity && getSingle === 'fulfilled' && (
						<Answers data={currentEntity} />
					)}
				</Container>
				<NavigateContainer>
					<PreviousNextSlots
						setAttemptId={setAttemptId}
						attemptId={attemptId}
					/>
					<FlexLayout justifyContent="flex-end" mt="0.875rem">
						<Button
							variant="text"
							color="warning"
							onClick={() => {
								handleModelClose(false);
								setUserId(undefined);
							}}
						>
							Close
						</Button>
						<Button variant="text" color="secondary" disabled>
							Download
						</Button>
					</FlexLayout>
				</NavigateContainer>
			</ModelContainer>
		</Modal>
	);
};

const ModelContainer = styled.div`
	width: 82.313rem;
	margin: 2px 47px 254px 104px;
	border-radius: 10px;
	background-color: #ffffff;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
`;
const Container = styled.div`
	padding: 3.188rem 2.313rem 1rem;
`;
const Name = styled.p`
	font-size: 1.375rem;
	font-weight: 600;
`;
const Status = styled.p<{ background: string | undefined }>`
	background: ${({ background }) => background};
	height: 1.75rem;
	width: 5.875rem;
	font-size: 1rem;
	font-weight: 600;
	color: #fff;
	border-radius: 5px;
	padding-bottom: 0.1rem;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 auto;
`;
const Paragraph = styled.p`
	font-size: 1rem;
	font-weight: 600;
	color: #2f2e41;
	text-transform: capitalize;
`;
const Span = styled.span`
	font-size: 1rem;
	font-weight: 500;
	color: #2f2e41;
`;
const Percentage = styled.span`
	font-style: italic;
`;
const Space = styled(Spacer)`
	border-bottom: 1px solid #e4e4e4;
`;
const NavigateContainer = styled(FlexLayout)`
	height: 7rem;
	box-shadow: 0 -3px 6px 0 rgba(0, 0, 0, 0.16);
	background-color: #f5f5f5;
	flex-direction: column;
	padding: 0.8rem 3.25rem 1rem 3.25rem;
`;
export default TestAttemptModal;
