import { Font } from '@Assets/globalStyles';
import {
	Color as COLOR,
	PaletteColor,
	SimplePaletteColorOptions,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { createTheme, Theme } from '@mui/material/styles';
import { rgba, shade, transparentize } from 'polished';
import React from 'react';
import { CSSPseudos } from 'styled-components';
import { variant } from 'styled-system';
export type ExtraColor =
	| 'cadet'
	| 'slateBlue'
	| 'mayaBlue'
	| 'darkPurple'
	| 'dodgerBlue'
	| 'uranianBlue'
	| 'purple'
	| 'tuftsBlue'
	| 'orange'
	| 'yellow'
	| 'platinum'
	| 'malachite';

export const Colors = {
	primary: '#5AC0FC',
	light: '#FFF',
	dark: '#000',
	cultured: '#F9FAFC',
	darkCultured: '#F3F3F3',
	silver: '#c4c4c4',
	darkSilver: '#757575',
	platinum: '#e6e6e6',
	purple: '#6C63FF',
	maxBluePurple: '#B5B1FF',
	independence: '#3f3d56',
	spanishGray: '#979797',
	princetonOrange: '#FF8532',
	lavender: '#F0EFFF',
	yellow: '#ffdd6d',
	orange: '#F55F44',
	red: '#dc3545',
	purpleNavy: '#575A89',
	green: '#00d66b',
	lightRed: '#ff3100',
};

type ColorType = 'color' | `${string}Color` | 'border';
export type ExtraPropsBase = CSSPseudos & {
	[Key in keyof React.CSSProperties &
		string as `ed${Key}`]?: Key extends ColorType
		? ExtraColor
		: React.CSSProperties[Key];
};

export interface ExtraVariantBase extends React.CSSProperties, CSSPseudos {
	backgroundColor?: string;
	color?: string;
	'&:hover'?: {
		backgroundColor?: string;
	};
}

type ColorVariant = ExtraVariantBase & {
	color: PaletteColor['main'];
	'.Mui-checked': {
		color: PaletteColor['main'];
	};
};
type FilledButtonProps = ExtraVariantBase & {
	backgroundColor: PaletteColor['main'];
	color: string;
	'&:hover': {
		backgroundColor: string;
	};
};

type OutlinedButtonProps = ExtraVariantBase & {
	backgroundColor: PaletteColor['main'];
	border: string;
	color: string;
	'&:hover': {
		backgroundColor: string;
		border: string;
	};
};
type IconButtonProps = ExtraVariantBase & {
	color: string;
	'&:hover': {
		backgroundColor: string;
	};
};

type EdFabProps = ExtraVariantBase & {
	backgroundColor: string;
	color: string;
	'&:hover': {
		backgroundColor: string;
	};
};

type EdBoxProps = ExtraVariantBase & {
	backgroundColor: string;
	border: string;
};

type EdAppBarProps = ExtraVariantBase & {
	color: string;
};

interface ExtraThemeOptions {
	buttons: {
		contained: {
			[key in ExtraColor]?: FilledButtonProps;
		};
		text: {
			[Key in ExtraColor]?: ExtraVariantBase;
		};
		outlined: {
			[Key in ExtraColor]?: OutlinedButtonProps;
		};
		icon: {
			[Key in ExtraColor]?: IconButtonProps;
		};
	};
	color: {
		[Key in ExtraColor]?: ColorVariant;
	};
	fabs: {
		default: {
			[Key in ExtraColor]?: EdFabProps;
		};
	};
	appbars: {
		[Key in ExtraColor]?: EdAppBarProps;
	};
	edBox: {
		[Key in ExtraColor]?: EdBoxProps;
	};
}

type PaletteOptionsColor = SimplePaletteColorOptions & Partial<COLOR>;

declare module '@mui/material' {}
declare module '@mui/material/styles' {
	interface ThemeOptions extends ExtraThemeOptions {}

	interface Palette {
		cadet: Palette['primary'] & Color;
		slateBlue: Palette['primary'] & Color;
		mayaBlue: Palette['primary'] & Color;
		darkPurple: Palette['primary'] & Color;
		dodgerBlue: Palette['primary'] & Color;
		uranianBlue?: Palette['primary'] & Color;
		purple?: Palette['primary'] & Color;
		tuftsBlue?: Palette['primary'] & Color;
		orange?: Palette['primary'] & Color;
		platinum?: Palette['primary'] & Color;
		malachite?: Palette['primary'] & Color;
	}

	interface PaletteOptions {
		cadet?: PaletteOptionsColor;
		slateBlue?: PaletteOptionsColor;
		mayaBlue?: PaletteOptionsColor;
		darkPurple?: PaletteOptionsColor;
		dodgerBlue?: PaletteOptionsColor;
		uranianBlue?: PaletteOptionsColor;
		purple?: PaletteOptionsColor;
		tuftsBlue?: PaletteOptionsColor;
		// color?: PaletteOptionsColor;
		paltinum?: PaletteOptionsColor;
		malachite?: PaletteOptionsColor;
	}

	interface TypographyVariantsOptions {
		fontFamily?: React.CSSProperties['fontFamily'] | Font;
	}
}

declare module 'styled-components' {
	export interface DefaultTheme extends Theme {}
}

type ExtraPalette = {
	[Key in ExtraColor | 'primary']: PaletteOptionsColor;
};
const extraPalatteOptions: ExtraPalette = {
	cadet: {
		'900': '#2f2e41',
		'800': '#525065',
		'700': '#727086',
		'600': '#87859c',
		'500': '#b2b0c7',
		'400': '#cfcde5',
		'300': '#eeecff',
		'200': '#f3f0ff',
		'100': '#f8f5ff',
		'50': '#fdfaff',
		main: '#2f2e41',
	},
	slateBlue: {
		'900': '#3700bd',
		'800': '#3e16d6',
		'700': '#4529e1',
		'600': '#4e37ee',
		'500': '#5241fb',
		'400': '#6b63ff',
		'300': '#8682ff',
		'200': '#aaa7ff',
		'100': '#cdc9ff',
		'50': '#ebeaff',
		main: '#6b63ff',
	},
	mayaBlue: {
		'900': '#2a539b',
		'800': '#3073be',
		'700': '#3384d3',
		'600': '#3697e8',
		'500': '#38a5f7',
		'400': '#40b3fb',
		'300': '#5ac1fc',
		'200': '#87d2fe',
		'100': '#f8f5ff',
		'50': '#fdfaff',
		main: '#5ac1fc',
	},
	darkPurple: {
		'900': '#251b33',
		'800': '#473c56',
		'700': '#675a76',
		'600': '#7b6e8b',
		'500': '#a597b5',
		'400': '#c4b6d5',
		'300': '#e8d9f9',
		'200': '#f6e7ff',
		'100': '#feeeff',
		'50': '#fff4ff',
		main: '#251b33',
	},
	dodgerBlue: {
		'500': '#2194f3',
		main: '#2194f3',
	},
	primary: {
		'300': '#5AC0FC',
		main: '#5AC0FC',
	},
	uranianBlue: {
		'100': '#ACDFFD',
		main: '#ACDFFD',
	},
	purple: {
		main: '#6C63FF',
		A400: shade(0.3, '#6C63FF'),
	},
	tuftsBlue: {
		'50': '',
		'100': '#badcf9',
		'200': '#8ec6f5',
		'300': '#62b0f0',
		'400': '#419fee',
		'500': '#2090eb',
		'600': '#1d82dd',
		'700': '#1870ca',
		'800': '#145fb8',
		'900': '#0c4299',
		main: '#2090eb',
	},
	orange: {
		'50': '#fffee8',
		'100': '#fff9c6',
		'200': '#fff4a0',
		'300': '#ffef7a',
		'400': '#ffea5d',
		'500': '#ffe441',
		'600': '#ffda47',
		'700': '#ffc442',
		'800': '#ffac3c',
		'900': '#FF8532',
		main: '#FF8532',
	},
	yellow: {
		400: '#ffd037',
		main: '#ffd037',
	},
	platinum: {
		main: '#ebebeb',
		'100': '#f3f3f3',
		'200': '#ebebeb',
		'300': '#dcdcdc',
		'400': '#b8b8b8',
		'50': '#f9f9f9',
		'500': '#999999',
		'600': '#707070',
		'700': '#5d5d5d',
		'800': '#3e3e3e',
		'900': '#1d1d1d',
	},
	malachite: {
		400: '#00D66B',
		main: '#00D66B',
	},
};
export const theme = createTheme({
	breakpoints: {
		values: {
			xs: 0,
			sm: 600,
			md: 900,
			lg: 1440,
			xl: 1824,
		},
	},
	components: {
		MuiButton: {
			styleOverrides: {
				containedPrimary: {
					color: grey[100],
				},
			},
		},
		MuiSelect: {
			styleOverrides: {
				select: {
					minWidth: '10rem',
				},
				multiple: {
					minWidth: '10rem',
				},
			},
		},
		MuiFab: {
			styleOverrides: {
				primary: {
					color: '#FFF',
				},
			},
		},
		MuiTextField: {
			styleOverrides: {
				root: {
					position: 'relative',
				},
			},
		},
		MuiFormHelperText: {
			styleOverrides: {
				root: {
					position: 'absolute',
					top: '100%',
				},
			},
		},
	},
	typography: {
		fontFamily: 'Montserrat',
		body1: {
			fontFamily: 'Montserrat',
		},
		allVariants: {
			fontFamily: 'Montserrat',
		},
	},
	palette: extraPalatteOptions,
	buttons: {
		contained: {
			cadet: {
				backgroundColor: extraPalatteOptions.cadet.main,
				color: '#FFF',
				'&:hover': {
					backgroundColor: shade(0.3, extraPalatteOptions.cadet.main),
				},
			},
			purple: {
				backgroundColor: extraPalatteOptions.purple.main,
				color: '#FFF',
				'&:hover': {
					backgroundColor: shade(0.3, extraPalatteOptions.purple.main),
				},
			},
			tuftsBlue: {
				backgroundColor: extraPalatteOptions.tuftsBlue.main,
				color: '#FFF',
				'&:hover': {
					backgroundColor: shade(0.3, extraPalatteOptions.tuftsBlue.main),
				},
			},
			dodgerBlue: {
				backgroundColor: extraPalatteOptions.dodgerBlue.main,
				color: '#FFF',
				'&:hover': {
					backgroundColor: shade(0.3, extraPalatteOptions.dodgerBlue.main),
				},
			},
		},
		text: {
			purple: {
				color: extraPalatteOptions.purple.main,
				'&:hover': {
					backgroundColor: transparentize(
						0.96,
						extraPalatteOptions.purple.main
					),
				},
			},
		},
		outlined: {
			cadet: {
				backgroundColor: 'transparent',
				border: `1px solid ${transparentize(
					0.5,
					extraPalatteOptions.cadet.main
				)}`,
				color: extraPalatteOptions.cadet.main,
				'&:hover': {
					border: `1px solid ${extraPalatteOptions.cadet.main}`,
					backgroundColor: `${rgba(extraPalatteOptions.cadet.main, 0.04)}`,
				},
			},
		},
		icon: {
			yellow: {
				color: extraPalatteOptions.yellow.main,
				'&:hover': {
					backgroundColor: `${rgba(extraPalatteOptions.yellow.main, 0.04)}`,
				},
			},
		},
	},
	color: {
		// primary: {
		//   ".Mui-checked": {
		//     color: "",
		//   },
		//   color: "",
		// },
	},
	fabs: {
		default: {
			purple: {
				backgroundColor: extraPalatteOptions.purple.main,
				color: '#FFF',
				'&:hover': {
					backgroundColor: extraPalatteOptions.purple.main,
				},
			},
			orange: {
				backgroundColor: extraPalatteOptions.orange.main,
				color: '#FFF',
				'&:hover': {
					backgroundColor: extraPalatteOptions.orange.main,
				},
			},
		},
	},
	appbars: {},
	edBox: {
		platinum: {
			border: `solid 2px ${extraPalatteOptions.platinum.main}`,
			backgroundColor: '#FFF',
		},
	},
});

export type Color = keyof typeof Colors;

type VariantArgs = {
	prop: keyof ExtraPropsBase;
	// variantPath: Path<ExtraThemeOptions>;
	variantPath: string;
};
export const getVariant = ({ prop, variantPath }: VariantArgs) => {
	return variant({
		prop,
		scale: variantPath,
	});
};
