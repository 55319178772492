import {
	NavMenuContext,
	BreadcrumbContext,
} from '@Components/UI/Layout/Layout';
import { SingleClassroomRoute } from '@Constants/routes';
import { getClassroomByLabel } from '@Features/classrooms/classroomSlice';
import { useTypedSelector } from '@Features/store';
import useInvalidateRouteGroup from '@Hooks/Routes/useInvalidateRouteGroup';
import useRouteGroup from '@Hooks/Routes/useRouteGroup';
import { ClassroomProvider } from '@Providers/ClassroomProvider/ClassroomProvider';
import { Spacer } from '@Styled/utilities';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
	Route,
	Switch,
	useLocation,
	useParams,
	useRouteMatch,
} from 'react-router-dom';
import styled from 'styled-components';
import CoursesTable from './Components/CoursesTable';
const SingleClassroom: React.FC = () => {
	const dispatch = useDispatch();
	const { label } = useParams<{ label: string }>();
	const { addLink, removeLink } = useContext(BreadcrumbContext);
	const { path, url } = useRouteMatch();
	const [loaded, setLoaded] = useState(false);

	const { isCurrent } = useInvalidateRouteGroup({
		mode: 'include',
		routeGroup: SingleClassroomRoute,
		baseURL: url,
		routeSearchValue: 'classroom',
		routePlacement: 2,
		extraProps: {
			label: label,
		},
	});

	useEffect(() => {
		if (label) {
			dispatch(getClassroomByLabel({ label }));
		}
	}, []);

	const { currentClassroom, currentCourse } = useTypedSelector(
		(state) => state.classroom
	);
	useEffect(() => {
		if (!url || !currentClassroom) return;
		if (isCurrent) {
			removeLink?.('course');
		}

		addLink?.({
			route: url,
			title: currentClassroom.title,
			routeHash: 'classroom',
		});
		setLoaded(true);
		return () => {
			removeLink?.('classroom');
		};
	}, [isCurrent, currentClassroom, url]);

	return (
		<ClassroomProvider loaded={loaded}>
			<div>
				{currentClassroom && (
					<SingleClassroomTitleWrapper>
						<Spacer marginBottom={'2.813rem'} mx={'2.125rem'} />
						{currentClassroom && (
							<ClassroomTitle>{currentClassroom.title}</ClassroomTitle>
						)}
						{currentCourse && <CourseTitle>{currentCourse.name}</CourseTitle>}
					</SingleClassroomTitleWrapper>
				)}
				<Switch>
					<Route exact path={path}>
						<CoursesTable />
					</Route>
					{SingleClassroomRoute.routes.map((route) => {
						return (
							<Route key={route.route} path={`${path}/${route.route}`}>
								{route.component}
							</Route>
						);
					})}
				</Switch>
			</div>
		</ClassroomProvider>
	);
};

export default SingleClassroom;

const SingleClassroomTitleWrapper = styled.div`
	margin-top: 2.875rem;
	margin-bottom: 2.313rem;
	align-items: center;
	display: flex;
`;

const ClassroomTitle = styled.label`
	font-size: 1.25rem;
	font-weight: bold;
`;
const CourseTitle = styled(ClassroomTitle)`
	font-weight: 400;
	margin: 0 1rem;
`;
