import RoutedTabs from '@Components/UI/Tabs/Tabs';
import EdBox from '@Components/UI/Utilities/EdBox/EdBox';
import { useTypedSelector } from '@Features/store';
import { Divider, FlexLayout, Spacer } from '@Styled/utilities';
import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import AddQuestionList from '../Components/AddQuestionList/AddQuestionList';
import TestQuestions from './TestQuestions';
import TestModels from './TestModels/TestModels';
import TestOptions from './TestOptions/TestOptions';
import TestOverviewHeader from '../Components/TestOverviewHeader/TestOverviewHeader';
import { RouteProvider } from '@Providers/Routes/RoutesProvider';
import TestNotifications from '@Pages/ClassRooms/Pages/SingleCourse/Pages/CourseForm/Pages/CourseContent/Pages/Test/Pages/TestNotifications/TestNotifications';
import ImportsTab from './TestImports/ImportsTab';

export const TestOverview: React.FC = () => {
	const { currentEntity } = useTypedSelector((state) => state.Tests);
	const { url } = useRouteMatch();
	const auth = useTypedSelector((state) => state.auth);
	return (
		<RouteProvider path={`${url}/questions`}>
			<FlexLayout px="4rem">
				<EdBox paddingLeft="0" display="flex" flexDirection="column">
					<RoutedTabs baseURL={url} width="100%" withRouter>
						<RoutedTabs.Tabs
							onChange={(e, val) => console.log({ val })}
							value={'questions'}
							hashes={['questions', 'options']}
							$hashPrefix="#"
							orientation="vertical"
						>
							<RoutedTabs.Item
								value="questions"
								icon={
									<RoutedTabs.ItemIcon className="material-icons-outlined">
										quiz
									</RoutedTabs.ItemIcon>
								}
								label={
									<RoutedTabs.ItemTitle>Test Questions</RoutedTabs.ItemTitle>
								}
							/>
							<RoutedTabs.Item
								value="options"
								icon={
									<RoutedTabs.ItemIcon className="material-icons-outlined">
										settings_suggest
									</RoutedTabs.ItemIcon>
								}
								label={
									<RoutedTabs.ItemTitle>Test Options</RoutedTabs.ItemTitle>
								}
							/>
							<RoutedTabs.Item
								value="models"
								icon={
									<RoutedTabs.ItemIcon className="material-icons-outlined">
										style
									</RoutedTabs.ItemIcon>
								}
								label={<RoutedTabs.ItemTitle>Test Models</RoutedTabs.ItemTitle>}
							/>
							<RoutedTabs.Item
								value="imports"
								icon={
									<RoutedTabs.ItemIcon className="material-icons-outlined">
										style
									</RoutedTabs.ItemIcon>
								}
								label={<RoutedTabs.ItemTitle>Test Imports</RoutedTabs.ItemTitle>}
							/>
							<RoutedTabs.Item
								value="notifications"
								icon={
									<RoutedTabs.ItemIcon className="material-icons-outlined">
										notifications
									</RoutedTabs.ItemIcon>
								}
								label={
									<RoutedTabs.ItemTitle>
										Test Notifications
									</RoutedTabs.ItemTitle>
								}
							/>
							<Spacer my="0.625rem" />
							<Divider />
							{(auth.user.type === 'super' || auth.permissions.has('c-unt')) &&
								!currentEntity.active && <AddQuestionList />}
						</RoutedTabs.Tabs>
						<Spacer mx="1rem" />
						<FlexLayout flexDirection="column" flex="1">
							<RoutedTabs.Content value="questions">
								<RoutedTabs.ContentItem hash="" alwaysVisible>
									<TestOverviewHeader />
								</RoutedTabs.ContentItem>

								<RoutedTabs.ContentItem hash="questions">
									<div>{currentEntity && <TestQuestions />}</div>
								</RoutedTabs.ContentItem>
								<RoutedTabs.ContentItem hash="models">
									<div>{currentEntity && <TestModels />}</div>
								</RoutedTabs.ContentItem>
								<RoutedTabs.ContentItem hash="options">
									<div>{currentEntity && <TestOptions />}</div>
								</RoutedTabs.ContentItem>
								<RoutedTabs.ContentItem hash="notifications">
									<div>{currentEntity && <TestNotifications />}</div>
								</RoutedTabs.ContentItem>
								<RoutedTabs.ContentItem hash="imports">
									<div>{currentEntity && <ImportsTab />}</div>
								</RoutedTabs.ContentItem>
							</RoutedTabs.Content>
						</FlexLayout>
					</RoutedTabs>
				</EdBox>
			</FlexLayout>
		</RouteProvider>
	);
};

export default TestOverview;
