import React, { useMemo } from 'react';
import {
	Checkbox,
	Collapse,
	List,
	ListItemButton,
	ListItemIcon,
	ListItemText,
} from '@mui/material';
import { Permissions } from '@Pages/Roles/Types/Roles.types';
import styled from 'styled-components';

type PermissionProps = {
	permission: Permissions;
	onSelect: (selected: boolean) => void;
	selected: boolean;
	open: boolean;
	disabled?: boolean;
};

const Collapsable: React.FC<PermissionProps> = ({
	onSelect,
	permission,
	open,
	selected,
	disabled,
}) => {
	return (
		<>
			<Collapse in={open}>
				<List disablePadding>
					<ListButton
						sx={{ pl: 4 }}
						onClick={() => {
							onSelect(!selected);
						}}
						dense
					>
						<ListItemIcon>
							<Checkbox
								edge="end"
								checked={selected}
								// tabIndex={-1}
								disabled={disabled}
								inputProps={{
									'aria-labelledby': `checkbox-list-label-${permission?.id}`,
								}}
							/>
						</ListItemIcon>
						<ListText key={permission.id} primary={permission.name} />
					</ListButton>
				</List>
			</Collapse>
		</>
	);
};

export default Collapsable;

const ListText = styled(ListItemText)`
	color: #251b33;
	font-size: 0.875rem;
`;

const ListButton = styled(ListItemButton)`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: row;
	.MuiCheckbox-root {
		min-width: 0rem;
	}
`;
