import EdFormControl from '@Components/UI/Inputs/EdFormControl/EdFormControl';
import { yupResolver } from '@hookform/resolvers/yup';
import { Checkbox } from '@mui/material';
import {
	CancelBtn,
	SaveBtn,
} from '@Pages/Roles/pages/AddRole/Components/addRoleInfo';
import { useSnackbar } from '@Providers/useSnackbar';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';
import { Slot } from '../../../Types/Webinar.types';
import TimeDatePicker from '../../../Components/DateTimePicker/TimeDatePicker';
import DurationSlider from '../../../Components/DurationSlider';
import { ADD_SLOT } from '../../../Schema/SlotSchema/Slot.schema';
import { WebinarSlotsRequester } from '../../../Services/Slots/SlotRequester';
import {
	EdFormControlStyle,
	FlexLayoutStyle,
	LabelStyle,
	Space,
	StackStyle,
} from '../../AddSlot/AddSlot';
import { FlexLayout } from '@Styled/utilities';
import WebinarUnit from '../../../Components/WebinarUnit';
import moment from 'moment';
import { webinarsGetSingle } from '../../../Slices/Webinar.slice';
import { useTypedSelector } from '@Features/store';
import { useDispatch } from 'react-redux';

const EditSlotInfo: React.FC = () => {
	const dispatch = useDispatch();
	const { displaySnackbar } = useSnackbar();
	const history = useHistory();
	const { state } = useLocation<Slot>();
	const { currentEntity } = useTypedSelector((state) => state.Webinars);
	const { getValues, control, formState, watch, setValue } = useForm<Slot>({
		resolver: yupResolver(ADD_SLOT),
		mode: 'all',
		defaultValues: state as Slot,
	});

	useEffect(() => {
		setValue(`capacity`, state?.capacity as number);
		getValues(`capacity`) === null || undefined
			? setValue('unlimited', true)
			: setValue('unlimited', false);
	}, [getValues, setValue, state?.capacity]);

	const onSave = async (form: Slot) => {
		try {
			const {
				data: { data },
			} = await WebinarSlotsRequester.getInstance().update(form);
			dispatch(webinarsGetSingle({ id: currentEntity?.id as number }));
			history.goBack();
			displaySnackbar('success', `Slot updated successfully`);
		} catch (e) {
			const msg = e.response?.data.message || 'Unable to updated Slot ';
			displaySnackbar('error', msg);
		}
	};

	const watchUnlimited = watch('unlimited');

	useEffect(() => {
		if (watchUnlimited) {
			setValue('capacity', null, { shouldValidate: true });
		}
	}, [watchUnlimited]);

	const currentTime = moment().format();
	const isCurrent: boolean =
		currentTime >= moment(state?.start_time).format() &&
		currentTime < moment(state?.end_time).format();

	const isEditFrom = true && isCurrent;

	return (
		<FlexLayoutStyle>
			<WebinarUnit />
			<EdFormControl
				control={control}
				required
				name={`name`}
				label="Slot Name"
			/>
			<Space flexDirection="row">
				<TimeDatePicker
					isEditFrom={isEditFrom}
					control={control}
					name={`start_time`}
				/>
			</Space>

			<EdFormControl
				control={control}
				required
				name={`description`}
				label="Description"
				multiline
			/>
			<EdFormControl
				control={control}
				required
				name={`slot_url`}
				label="Slot URL"
			/>

			<FlexLayout flexDirection="row">
				<EdFormControlStyle
					control={control}
					width="4.75rem"
					disabled={isCurrent ?? watchUnlimited}
					type="number"
					inputMode="numeric"
					name={`capacity`}
					label="Capacity"
					required
					style={{
						backgroundColor: watchUnlimited
							? '#f3f3f3'
							: isCurrent
							? '#f3f3f3'
							: '',
					}}
				/>
				<Controller
					name={`unlimited`}
					control={control}
					render={({ field }) => (
						<FlexLayout
							display="flex"
							justifyContent="space-between"
							alignItems="center"
						>
							<Checkbox
								checked={watchUnlimited ? true : false}
								disabled={isCurrent}
								{...field}
								id="Unlimited"
							/>
							<LabelStyle>Unlimited</LabelStyle>
						</FlexLayout>
					)}
				/>
			</FlexLayout>
			<DurationSlider
				name={`duration`}
				control={control}
				isEditFrom={isEditFrom}
				// isCurrent={isCurrent}
			/>
			<StackStyle
				sx={{ marginTop: '2.625rem' }}
				spacing={2}
				direction="row-reverse"
			>
				<SaveBtn
					variant="contained"
					disabled={!formState.isDirty}
					onClick={() => {
						onSave(getValues());
					}}
				>
					SAVE
				</SaveBtn>
				<CancelBtn
					variant="contained"
					disabled={!formState.isDirty}
					onClick={() => history.goBack()}
				>
					CANCEL
				</CancelBtn>
			</StackStyle>
		</FlexLayoutStyle>
	);
};

export default EditSlotInfo;
