import api from '@Services/api';
import { AxiosResponse } from 'axios';
import {
	CRUDBase,
	DeleteSingleEntityPayload,
	GetAllMeta,
	GetSingleEntityPayload,
} from 'src/base/services/services.base';
import { FlattenNewsFeedForm } from '../Schema/NewsFeed.schema';
import { NewsFeedsRes } from '../Types/NewsFeed.types';
import {
	DeleteNewsFeedResponse,
	NewsFeedsResponse,
	UpdateNewsFeedResponse,
} from './NewsFeed.res.types';

export class NewsFeedRequester
	implements CRUDBase<FlattenNewsFeedForm, NewsFeedsRes>
{
	baseURL?: string | undefined;
	getAll(
		payload?: GetAllMeta
	): Promise<AxiosResponse<ApiResponse<NewsFeedsRes[]>>>;
	getAll(payload: number): Promise<AxiosResponse<ApiResponse<NewsFeedsRes[]>>>;
	async getAll(
		payload: any
	): Promise<AxiosResponse<ApiResponse<NewsFeedsRes[]>>> {
		return await api.post('newsfeed/all', payload);
	}
	private static instance: NewsFeedRequester = new NewsFeedRequester();
	static getInstance(): NewsFeedRequester {
		if (NewsFeedRequester.instance) {
			NewsFeedRequester.instance = new NewsFeedRequester();
		}
		return NewsFeedRequester.instance;
	}

	get(id: string): Promise<AxiosResponse<ApiResponse<NewsFeedsRes>>>;
	get(id: number): Promise<AxiosResponse<ApiResponse<NewsFeedsRes>>>;
	get(
		id: any,
		payload: GetSingleEntityPayload
	): Promise<AxiosResponse<ApiResponse<NewsFeedsRes>>>;
	async get(
		id: string | number,
		payload?: any
	): Promise<AxiosResponse<ApiResponse<NewsFeedsRes>>> {
		return await api.post('newsfeed', payload);
	}

	async create(
		payload: FlattenNewsFeedForm
	): Promise<AxiosResponse<ApiResponse<NewsFeedsRes>>> {
		return await api.post('newsfeed/create', payload);
	}
	async update(
		payload: FlattenNewsFeedForm
	): Promise<AxiosResponse<ApiResponse<NewsFeedsRes>>> {
		return await api.put('newsfeed/update', payload);
	}

	delete(id: number): Promise<AxiosResponse<ApiResponse<any>>>;
	delete(id: string): Promise<AxiosResponse<ApiResponse<any>>>;
	delete(
		id: string | number,
		payload: DeleteSingleEntityPayload
	): Promise<AxiosResponse<ApiResponse<any>>>;
	async delete(
		id: any,
		payload?: any
	): Promise<AxiosResponse<ApiResponse<any>>> {
		return await api.delete('newsfeed/delete', {
			data: payload,
		});
	}
}

interface getReqType {
	page: number;
	perPage: number;
	sortBy?: any;
	filters: Array<any>;
	query: string;
	from?: Date;
	to?: Date;
}

export const newsFeedsGetRequest = async (
	page: number,
	perPage: number,
	filters: any,
	sortBy: any,
	query: string,
	from?: Date,
	to?: Date
) => {
	const data: getReqType = { page, perPage, filters, query, from, to };
	return await api.post<NewsFeedsResponse>('newsfeed/all', data);
};

export const updateNewsFeedRequest = async (
	id: number,
	content: string,
	description: string,
	photo: string,
	redirection_url: string,
	is_active: boolean
) =>
	await api.put<UpdateNewsFeedResponse>('newsfeed/update', {
		id,
		content,
		description,
		photo,
		redirection_url,
		is_active,
	});

export const deleteNewsFeedRequest = async (id: number) =>
	await api.delete<DeleteNewsFeedResponse>('newsfeed/delete', {
		data: { id },
	});
