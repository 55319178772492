import MainTable from '@Components/MainTable';
import { onPageChangeRequestMeta } from '@Components/MainTable/MainTable.types';
import {
	DataCard,
	DataCardLabel,
	DataCardValue,
} from '@Components/UI/Cards/DataCard/DataCard';
import { FlexLayout } from '@eduact/ed-system';
import { useTypedSelector } from '@Features/store';
import { Spacer } from '@Styled/utilities';
import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import StudentWebinarsCount from './Components/StudentWebinarsCount';
import StudentWebinarsTable from './Components/StudentWebinarsTable';
import { studentWebinarsGetAll } from './Slices/StudentWebinars.slice';
import { studentWebinarsCountGetSingle } from './Slices/WebinarsCount.slice';

type Props = {
	classroom_id: number;
};
const StudentWebinars: React.VoidFunctionComponent<Props> = ({
	classroom_id,
}) => {
	const { currentEntity: Student } = useTypedSelector(
		(state) => state.Students
	);
	const dispatch = useDispatch();
	const { currentEntity } = useTypedSelector(
		(state) => state.StudentWebinarsCount
	);

	const onPageChangeRequest = useCallback(
		({
			activePage,
			filters,
			perPage,
			query,
			sortBy,
			startDate,
			endDate,
		}: onPageChangeRequestMeta) => {
			dispatch(
				studentWebinarsGetAll({
					filters,
					page: activePage,
					perPage: perPage ?? 10,
					query,
					sortBy,
					classroom_id,
					from: startDate,
					to: endDate,
					student_id: Student.id,
				})
			);
		},
		[]
	);
	return (
		<div>
			<Header>Webinars</Header>
			<StudentWebinarsCount classroom_id={classroom_id} />
            <Spacer mb={'3rem'} />
			<StudentWebinarsTable classroom_id={classroom_id} />
		</div>
	);
};

export default StudentWebinars;

const Header = styled.p`
	font-size: 1.125rem;
	font-weight: bold;
	color: #6c63ff;
	margin-bottom: 1.063rem;
`;
