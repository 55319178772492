import EdButton from '@Components/UI/Buttons/EdButton/EdButton';
import EdFormControl from '@Components/UI/Inputs/EdFormControl/EdFormControl';
import EdBox from '@Components/UI/Utilities/EdBox/EdBox';
import { useAppThunkDispatch, useTypedSelector } from '@Features/store';
import { FormContainerStyle } from '@Pages/ClassRooms/Pages/SingleCourse/Pages/CourseForm/Form.Styles';
import { AddGroupSchema } from '@Pages/ClassRooms/Schema/Group.schema';
import { groupsCreate } from '@Pages/ClassRooms/Slices/Groups/Groups.slice';
import { useRouteProvider } from '@Providers/Routes/RoutesProvider';
import { useSnackbar } from '@Providers/useSnackbar';
import { FlexLayout, Typography } from '@eduact/ed-system';
import { DevTool } from '@hookform/devtools';
import { yupResolver } from '@hookform/resolvers/yup';
import { Modal } from '@mui/material';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import axios, { AxiosError } from 'axios';
// import { RaisedButton } from '@eduact/ed-components';
export const AddGroup = () => {
	const { currentClassroom } = useTypedSelector((state) => state.classroom);
	const {
		control,
		formState: { isValid, isDirty,errors },
		handleSubmit,
		setValue,
	} = useForm({
		resolver: yupResolver(AddGroupSchema),
		defaultValues: {
			classroom_id: currentClassroom?.id,
			name: '',
			code: '',
			description: '',
		},
	});

	useEffect(() => {
		setValue('classroom_id', currentClassroom?.id);
	}, [currentClassroom?.id]);

	const dispatch = useAppThunkDispatch();
	const router = useRouteProvider();
	const { displaySnackbar } = useSnackbar();
	const handleAdd = handleSubmit(
		async (data) => {
			try {
				const response = await dispatch(groupsCreate(data as any)).unwrap();
				router?.goToParent();
				displaySnackbar('success', response.message);
			} catch (error) {
				if (error.data) {
					displaySnackbar('error', error?.data?.message);
				}
			}
		},
		(e) => console.log(e)
	);

	const parent = useRouteProvider();

	return (
		<Modal
			open={true}
			sx={{ display: 'grid', placeContent: 'center' }}
			onClose={() => parent?.goToParent()}
		>
			<EdBox
				width={'40vw'}
				minHeight={'20vh'}
				style={{ backgroundColor: '#FFF' }}
			>
				<DevTool control={control} />
				<FlexLayout
					flexDirection={'column'}
					height={'100%'}
					width={'100%'}
					justifyContent={'space-between'}
				>
					<FlexLayout flexDirection={'column'} gridGap={'2rem'}>
						<Typography fontSize={'1.5rem'} fontWeight={'bold'}>
							Add Group
						</Typography>
						<EdFormControl
							control={control}
							name="name"
							required
							placeholder="Name"
							label="Name"
						/>
						<EdFormControl
							required
							control={control}
							name="code"
							placeholder="Code"
							label="Code"
						/>
						<EdFormControl
							multiline
							minRows={4}
							control={control}
							name="description"
							placeholder="Description"
							label="Description"
						/>
					</FlexLayout>
					<FlexLayout justifyContent={'flex-end'}>
						<EdButton
							onClick={handleAdd}
							disabled={!isDirty || !isValid}
							variant="contained"
						>
							Submit
						</EdButton>
					</FlexLayout>
				</FlexLayout>
			</EdBox>
		</Modal>
	);
};
