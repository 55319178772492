import { BaseSliceController } from '@Base/slices/slices.base';
import { walletLog } from '@Services/students/students.res.types';
import { WalletLogsRequester } from '../Services/WalletLogs.Requester';

export class WalletLogsSlice extends BaseSliceController<
	walletLog,
	'WalletLogs',
	walletLog
> {
	private static instance: WalletLogsSlice;
	static getInstance() {
		if (!WalletLogsSlice.instance) {
			WalletLogsSlice.instance = new WalletLogsSlice();
		}
		return WalletLogsSlice.instance;
	}
	constructor() {
		super('WalletLogs', WalletLogsRequester.getInstance());
	}
}

export const { walletLogsGetAll } = WalletLogsSlice.getInstance().getActions();
