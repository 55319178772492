import { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
const MySwal = withReactContent(Swal);

interface Args {
	onConfirm(): void;
	withLoader?: boolean;
	message?: string;
}

const useConfirmModal = ({
	onConfirm,
	withLoader = false,
	message = 'Are you sure you want to proceed?',
}: Args) => {
	const [show, setShow] = useState(false);
	useEffect(() => {
		if (show) {
			MySwal.fire({
				title: message,
				showCancelButton: true,
				// showDenyButton: true,
				confirmButtonText: `<i class="fa fa-thumbs-up"></i> Confirm `,
				confirmButtonAriaLabel: 'Thumbs up, great!',
				cancelButtonText: '<i class="fa fa-thumbs-down"></i> Cancel',
				cancelButtonAriaLabel: 'Thumbs down',
			}).then((result) => {
				if (result.isConfirmed) {
					onConfirm();
				}
				if (result.isDenied) {
					setShow(false);
				}
				if (result.isDismissed) {
					setShow(false);
				}
			});
		}
	}, [show, onConfirm]);

	return {
		show,
		setShow,
	};
};

export default useConfirmModal;
