import React from 'react';
import styled from 'styled-components';
import AddCategoryInfo from './Components/AddCategoryInfo';

const AddCategory = () => {
	return (
		<Container>
			<AddCategoryInfo />
		</Container>
	);
};

const Container = styled.div`
	padding: 1.375rem;
	border-radius: 10px;
	border: 1px solid #5ac0fc;
	height: 100vh;
`;

export default AddCategory;
