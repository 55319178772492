import { yupResolver } from '@hookform/resolvers/yup';
import {
	AddNewsFeedForm,
	ADD_NEWSFEED,
} from '@Pages/NewsFeeds/Schema/NewsFeed.schema';
import { useSnackbar } from '@Providers/useSnackbar';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { NewsFeedRequester } from '@Pages/NewsFeeds/Services/NewsFeed.req';
import { DASHBOARD_BASE_ROUTE } from '@Constants/routes';
import { Wrapper } from '@Pages/Categories/Pages/AddCategory/Components/AddCategoryInfo';
import EdFormControl from '@Components/UI/Inputs/EdFormControl/EdFormControl';
import EdUploadFile from '@Components/UI/Inputs/EdUploadFile/EdUploadFile';
import { Icon } from '@mui/material';
import GroupedBtns from '@Pages/NewsFeeds/components/GroupedBtns';
import styled from 'styled-components';

const AddNewsFeedInfo: React.FC = () => {
	const history = useHistory();
	const { displaySnackbar } = useSnackbar();

	const { getValues, control, formState } = useForm<AddNewsFeedForm>({
		resolver: yupResolver(ADD_NEWSFEED),
		mode: 'all',
		defaultValues: {},
	});

	const onSave = async (form: AddNewsFeedForm) => {
		try {
			const {
				data: { data },
			} = await NewsFeedRequester.getInstance().create(form);
			displaySnackbar(
				'success',
				`NewsFeed ${data.content} created successfully`
			);
			history.push(`${DASHBOARD_BASE_ROUTE}/newsfeed/edit/newsfeed/${data.id}`);
		} catch (e) {
			const msg = e.response?.data.message || 'Unable to Create newsfeed ';
			displaySnackbar('error', msg);
		}
	};

	return (
		<Wrapper>
			<SectionStyle>
				<EdFormControl
					control={control}
					required
					name="content"
					label="Content"
				/>
				<EdUploadFile
					folder="icons/subjects"
					uploadLabel="UPLOAD"
					uploadIcon={<Icon>add</Icon>}
					control={control}
					name="photo"
					label="Desktop Photo"
					aspectRation={58 / 11}
					optimize={false}
					required
				/>
			</SectionStyle>
			<SectionStyle>
				<UrlStyleWrapper>
					<EdFormControl
						control={control}
						required
						name="redirection_url"
						label="Redirection URL"
						type="url"
					/>
				</UrlStyleWrapper>
				<EdUploadFile
					folder="icons/subjects"
					uploadLabel="UPLOAD"
					uploadIcon={<Icon>add</Icon>}
					control={control}
					name="photo_sm"
					label="Mobile Photo"
					aspectRation={72 / 43}
					optimize={false}
					required
				/>
			</SectionStyle>

			<EdFormControl control={control} switch name="is_active" label="Active" />

			<GroupedBtns
				onSave={onSave}
				getValues={getValues}
				formState={formState}
			/>
		</Wrapper>
	);
};

export const SectionStyle = styled.div`
	display: flex;
	justify-content: start;
	flex-wrap: wrap;
	gap: 90px;
`;

export const UrlStyleWrapper = styled.div``;

export default AddNewsFeedInfo;
