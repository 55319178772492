import React, { useMemo } from 'react';
import styled from 'styled-components';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';

import {
	ParentNotificationOptions,
	TestNotification,
	StudentNotificationOptions,
} from '@Pages/ClassRooms/Pages/SingleCourse/Pages/CourseForm/Pages/CourseContent/Types/Test/Test.types';
import { Box, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { FlexLayout, Spacer } from '@Styled/utilities';
import { useForm, Controller } from 'react-hook-form';
import { useAppThunkDispatch, useTypedSelector } from '@Features/store';
import { DevTool } from '@hookform/devtools';
import {
	testsAssignCurrentEntity,
	testsGetSingle,
	testsUpdate,
} from '@Pages/ClassRooms/Pages/SingleCourse/Pages/CourseForm/Pages/CourseContent/Pages/Test/Slice/TestSlice';
import { useSnackbar } from '@Providers/useSnackbar';
import { useRouteProvider } from '@Providers/Routes/RoutesProvider';
import { testNotificationUpdate } from '../../Slice/TestNotifaction/TestNotifaction.slice';

type OptionParentNotification = {
	id: number;
	label: string;
	value: ParentNotificationOptions;
};

type OptionStudentNotification = {
	id: number;
	label: string;
	value: StudentNotificationOptions;
};

const TestNotifications: React.FC = () => {
	const { currentEntity } = useTypedSelector((state) => state.Tests);

	const defaultValues = useMemo<TestNotification | undefined>(() => {
		if (currentEntity) {
			const dv: TestNotification = {
				id: currentEntity?.id,
				student_notification_options:
					currentEntity?.student_notification_options,
				parent_notification_options: currentEntity?.parent_notification_options,
				include_previous_attempts: currentEntity?.include_previous_attempts,
			};
			return dv;
		}
	}, []);

	const { control, handleSubmit, setValue, watch, formState } = useForm<TestNotification>({
		mode: 'all',
		defaultValues: defaultValues,
	});

	const watchStudentOptions = watch('student_notification_options');
	const watchParentOptions = watch('parent_notification_options');
	const watchIncludePreviousAttempts = watch('include_previous_attempts');

	const dispatch = useAppThunkDispatch();
	const { displaySnackbar } = useSnackbar();
	const methods = useRouteProvider();

	let studentConnectionMethods: Array<OptionStudentNotification> = [
		{
			id: 1,
			label: 'SMS',
			value: 'sms',
		},
		{
			id: 2,
			label: "What's app",
			value: 'whatsapp',
		},
		{
			id: 3,
			label: 'Email',
			value: 'email',
		},
	];

	let parentConnectionMethods: Array<OptionParentNotification> = [
		{
			id: 1,
			label: 'SMS',
			value: 'sms',
		},
		{
			id: 2,
			label: "What's app",
			value: 'whatsapp',
		},
	];

	const handleSelectAllStudentMethods = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		if (event.target.checked)
			setValue('student_notification_options', ['sms', 'whatsapp', 'email'], {
				shouldDirty: true,
			});
		else setValue('student_notification_options', [], { shouldDirty: true });
	};

	const handleSelectAllParentMethods = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		if (event.target.checked)
			setValue('parent_notification_options', ['sms', 'whatsapp'], {
				shouldDirty: true,
			});
		else setValue('parent_notification_options', [], { shouldDirty: true });
	};
	const handelSingleParentMethod = (
		event: React.ChangeEvent<HTMLInputElement>,
		value: ParentNotificationOptions
	) => {
		const temp: Array<ParentNotificationOptions> =
			new Array<ParentNotificationOptions>();
		if (event.target.checked) {
			if (watchParentOptions) {
				for (let i = 0; i < watchParentOptions.length; i++)
					temp.push(watchParentOptions[i]);
			}
			temp.push(value);
		} else {
			if (watchParentOptions) {
				for (let i = 0; i < watchParentOptions.length; i++)
					if (watchParentOptions[i] !== value) temp.push(watchParentOptions[i]);
			}
		}
		setValue('parent_notification_options', temp, {
			shouldDirty: true,
			shouldValidate: true,
		});
	};
	const handelSingleStudentMethod = (
		event: React.ChangeEvent<HTMLInputElement>,
		value: StudentNotificationOptions
	) => {
		const temp: Array<StudentNotificationOptions> =
			new Array<StudentNotificationOptions>();
		if (event.target.checked) {
			if (watchStudentOptions) {
				for (let i = 0; i < watchStudentOptions.length; i++)
					temp.push(watchStudentOptions[i]);
			}
			temp.push(value);
		} else {
			if (watchStudentOptions) {
				for (let i = 0; i < watchStudentOptions.length; i++)
					if (watchStudentOptions[i] !== value)
						temp.push(watchStudentOptions[i]);
			}
		}
		setValue('student_notification_options', temp, {
			shouldDirty: true,
			shouldValidate: true,
		});
	};

	const onSave = async (form: TestNotification) => {
		try {
			await dispatch(testNotificationUpdate(form)).unwrap();

			if (form.id) dispatch(testsGetSingle({ id: Number(form.id) }));
			displaySnackbar('success', 'Updated Test Notifications successfully');
		} catch (error) {
			displaySnackbar('error', "Active test can't be updated");
		}
	};

	return (
		<StyledContainer>
			<Box sx={{ display: 'flex', alignItems: 'center' }}>
				<Controller
					control={control}
					name={'student_notification_options'}
					render={({ field }) => {
						return (
							<FormControlLabel
								label="Student"
								{...field}
								control={
									<Checkbox
										checked={
											watchStudentOptions?.length ===
											studentConnectionMethods.length
										}
										onChange={handleSelectAllStudentMethods}
										indeterminate={
											watchStudentOptions &&
											watchStudentOptions?.length > 0 &&
											watchStudentOptions?.length <
												studentConnectionMethods.length
										}
									/>
								}
							/>
						);
					}}
				/>
			</Box>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					marginLeft: '30px',
					marginBottom: '30px',
				}}
			>
				{studentConnectionMethods.map((method) => (
					<FormControlLabel
						label={method.label}
						control={
							<Checkbox
								checked={
									watchStudentOptions
										? watchStudentOptions.includes(method.value)
										: false
								}
								onChange={(e) => {
									handelSingleStudentMethod(e, method.value);
								}}
							/>
						}
					/>
				))}
			</Box>

			<Box sx={{ display: 'flex', alignItems: 'center' }}>
				<Controller
					control={control}
					name={'parent_notification_options'}
					render={({ field }) => {
						return (
							<FormControlLabel
								label="Parent"
								{...field}
								control={
									<Checkbox
										checked={
											watchParentOptions?.length ===
											parentConnectionMethods.length
										}
										onChange={handleSelectAllParentMethods}
										indeterminate={
											watchParentOptions &&
											watchParentOptions?.length > 0 &&
											watchParentOptions?.length <
												parentConnectionMethods.length
										}
									/>
								}
							/>
						);
					}}
				/>
			</Box>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					marginLeft: '30px',
					marginBottom: '30px',
				}}
			>
				{parentConnectionMethods.map((method) => (
					<FormControlLabel
						label={method.label}
						control={
							<Checkbox
								checked={
									watchParentOptions
										? watchParentOptions.includes(method.value)
										: false
								}
								onChange={(e) => {
									handelSingleParentMethod(e, method.value);
								}}
							/>
						}
					/>
				))}
			</Box>

			<Box marginTop="45px">
				<StyleQuestion>Include previous attempts?</StyleQuestion>
				<Box marginLeft="30px" width="200px">
					<Controller
						name={'include_previous_attempts'}
						control={control}
						render={({ field }) => {
							return (
								<RadioGroup
									{...field}
									row
									defaultValue={
										watchIncludePreviousAttempts
											? watchIncludePreviousAttempts
											: false
									}
									style={{ flex: 1.5, justifyContent: 'space-between' }}
									onChange={(event, value) => {
										setValue('include_previous_attempts', value === 'true', {
											shouldDirty: true,
											shouldValidate: true,
										});
									}}
								>
									<FormControlLabel
										value={'true'}
										label="Yes"
										control={<Radio />}
									/>
									<FormControlLabel
										value={'false'}
										label="No"
										control={<Radio />}
									/>
								</RadioGroup>
							);
						}}
					/>
				</Box>
			</Box>
			<FlexLayout justifyContent="flex-end">
				<Button
					variant="contained"
					color="warning"
					disabled={!formState.isDirty}
					onClick={() => {
						methods?.goToParent();
					}}
				>
					Cancel
				</Button>
				<Spacer mx="0.5rem" />
				<Button
					variant="contained"
					disabled={!formState.isDirty || !formState.isValid}
					onClick={handleSubmit(onSave, (e) => console.log(e))}
				>
					Save
				</Button>
			</FlexLayout>
			<DevTool control={control} />
		</StyledContainer>
	);
};
const StyledContainer = styled.form`
	margin-left: 2.625rem;
	align-items: center;
`;
const StyleQuestion = styled.p`
	font-weight: bold;
	margin-bottom: 30px;
`;

export default TestNotifications;
