import api from '@Services/api';
import { HeadersResponse } from '@Services/generics/response';
import { AxiosResponse } from 'axios';
import {
	CRUDBase,
	DeleteSingleEntityPayload,
	GenerateCardMeta,
	GetAllMeta,
	GetSingleEntityPayload,
} from 'src/base/services/services.base';
import { PromoCodesUsage } from '../Types/PromoCodes.types';

export class PromoCodesUsageRequester implements CRUDBase<PromoCodesUsage> {
	private static instance: PromoCodesUsageRequester;
	static getInstance() {
		if (!PromoCodesUsageRequester.instance) {
			PromoCodesUsageRequester.instance = new PromoCodesUsageRequester();
		}
		return PromoCodesUsageRequester.instance;
	}
	baseURL = 'promocodes/';
	getAll(
		payload?: GetAllMeta
	): Promise<AxiosResponse<ApiResponse<PromoCodesUsage[]>>>;
	getAll(payload: number): Promise<AxiosResponse<ApiResponse<PromoCodesUsage[]>>>;
	async getAll(
		payload?: any
	): Promise<AxiosResponse<ApiResponse<PromoCodesUsage[]>>> {
		return await api.post(`${this.baseURL}usage`, payload);
	}
	get(id: string): Promise<AxiosResponse<ApiResponse<PromoCodesUsage>>>;
	get(id: number): Promise<AxiosResponse<ApiResponse<PromoCodesUsage>>>;
	get(
		id: string | number,
		payload: GetSingleEntityPayload
	): Promise<AxiosResponse<ApiResponse<PromoCodesUsage>>>;
	async get(
		id: any,
		payload?: any
	): Promise<import('axios').AxiosResponse<ApiResponse<PromoCodesUsage>>> {
		return await api.post(`${this.baseURL}get`, { id });
	}
	async create(
		payload: Omit<PromoCodesUsage, 'id'>
	): Promise<AxiosResponse<ApiResponse<PromoCodesUsage>>> {
		return await api.post(`${this.baseURL}`, payload);
	}
	async update(
		payload: PromoCodesUsage
	): Promise<AxiosResponse<ApiResponse<PromoCodesUsage>>> {
		return await api.put(`${this.baseURL}`, payload);
	}
	delete(id: number): Promise<AxiosResponse<ApiResponse<any>>>;
	delete(id: string): Promise<AxiosResponse<ApiResponse<any>>>;
	delete(
		id: string | number,
		payload: DeleteSingleEntityPayload
	): Promise<AxiosResponse<ApiResponse<any>>>;
	async delete(
		id: any,
		payload?: any
	): Promise<import('axios').AxiosResponse<ApiResponse<any>>> {
		return await api.delete(`${this.baseURL}`, { data: { id } });
	}
	async export(payload: GetAllMeta): Promise<AxiosResponse<string>> {
		return await api.post(`${this.baseURL}all`, { ...payload, export: true });
	}
	async generate?(payload: GenerateCardMeta): Promise<AxiosResponse<string>> {
		return await api.post(`${this.baseURL}`, payload);
	}
}
