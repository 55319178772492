import api from '@Services/api';
import { AxiosResponse } from 'axios';
import {
	CRUDBase,
	DeleteSingleEntityPayload,
	GetAllMeta,
	GetSingleEntityPayload,
} from 'src/base/services/services.base';
import { Assistants, Note } from '../Types/Notes.types';

interface AssistantsCRUD extends CRUDBase<Note, Note> {}
export class NotesRequester implements AssistantsCRUD {
	private static instance: NotesRequester;
	static getInstance() {
		if (!NotesRequester.instance) {
			NotesRequester.instance = new NotesRequester();
		}
		return NotesRequester.instance;
	}
	baseURL = 'link-items';
	getAll(payload?: GetAllMeta): Promise<AxiosResponse<ApiResponse<Note[]>>>;
	getAll(payload: number): Promise<AxiosResponse<ApiResponse<Note[]>>>;
	async getAll(
		payload?: any
	): Promise<import('axios').AxiosResponse<ApiResponse<Note[]>>> {
		return await api.post(`${this.baseURL}/fetch`, payload);
	}
	get(id: string): Promise<AxiosResponse<ApiResponse<Note>>>;
	get(id: number): Promise<AxiosResponse<ApiResponse<Note>>>;
	get(
		id: string | number,
		payload: GetSingleEntityPayload
	): Promise<AxiosResponse<ApiResponse<Note>>>;
	async get(id: any, payload?: any): Promise<AxiosResponse<ApiResponse<Note>>> {
		return await api.post(`${this.baseURL}/get`, { id });
	}

	async create(payload: Note): Promise<AxiosResponse<ApiResponse<Note>>> {
		return await api.post(`${this.baseURL}`, payload);
	}
	async update(payload: Note): Promise<AxiosResponse<ApiResponse<Note>>> {
		return await api.put(`${this.baseURL}`, payload);
	}
	async toggleActive(
		assistant: Note
	): Promise<AxiosResponse<ApiResponse<Note>>> {
		return await api.put(`${this.baseURL}/update`, {
			...assistant,
			// is_active: !assistant.instructor.is_active,
		});
	}
	delete(id: number): Promise<AxiosResponse<ApiResponse<any>>>;
	delete(id: string): Promise<AxiosResponse<ApiResponse<any>>>;
	delete(
		id: string | number,
		payload: DeleteSingleEntityPayload
	): Promise<AxiosResponse<ApiResponse<Note>>>;
	async delete(
		id: any,
		payload?: any
	): Promise<AxiosResponse<ApiResponse<any>>> {
		return await api.delete(`${this.baseURL}/`, {data : payload});
	}
}
