import MainTable from '@Components/MainTable';
import NoResults from '@Components/NoResults/NoResults';
import SidebarContentWrapper from '@Components/SidebarContentWrapper';
import {
	getCourseProgress,
	getCourseUsers,
} from '@Features/classrooms/classroomSlice';
import { useTypedSelector } from '@Features/store';
import { usePageLoader } from '@Providers/LoadingProvider';
import { useSnackbar } from '@Providers/useSnackbar';
import sanitizeTableValue from '@Utils/sanitizeTableData';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ToggleButtonGroup, ToggleButton, Tooltip } from '@mui/material';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import TableCell from '@mui/material/TableCell';
import Chip from '@mui/material/Chip';
import styled from 'styled-components';
import { FlexLayout, Spacer } from '@Styled/utilities';
import { StyleAddBtn } from '@Pages/ClassRooms/ClassRoomsTable';
import { MainTableRow } from '@Components/MainTable/MainTable';
import { TableHeader } from '@Features/generics/generics.type';
import { onPageChangeRequestMeta } from '@Components/MainTable/MainTable.types';
import EdConfirmModal from '@Components/UI/Modals/EdConfirmModal/EdConfirmModal';
import { UsersRequester } from './Services/Userprogress/UserProgressRequester';
import UserSyncModel from './ProgressModels/UserSyncModel';
import ProgressTable from './ProgressTable/ProgressTable';
import UsersTable from './UsersTable/UsersTable';

const TABLE_HEADS: Array<TableHeader> = [
	{ displayName: 'FIRST NAME', fieldName: 'first_name' },
	{ displayName: 'LAST NAME', fieldName: 'last_name' },
	{ displayName: 'EMAIL', fieldName: 'email', width: '15%' },
	{ displayName: 'USERNAME', fieldName: 'username', width: '12%' },
	{ displayName: 'METHOD', fieldName: 'purchase_method', canSort: false },
	{ displayName: 'ENROLLED ON ', fieldName: 'created_at', width: '12%' },
	{ displayName: 'STATUS', fieldName: ' ', canSort: false },
];
const progressTableHeaders: Array<TableHeader> = [
	{ displayName: 'FIRST NAME', fieldName: 'first_name', width: '10%' },
	{ displayName: 'LAST NAME', fieldName: 'last_name', width: '10%' },
	{ displayName: 'EMAIL', fieldName: 'email', width: '13%' },
	{ displayName: 'USERNAME', fieldName: 'username', width: '10%' },
	{ displayName: 'Expire at', fieldName: 'expire_at', width: '10%' },
	{ displayName: 'Expired', fieldName: 'expired', width: '8%' },
	{ displayName: 'status', fieldName: 'status', width: '5%' },
	{ displayName: 'PROGRESS', fieldName: 'progress', width: '15%' },
	{ displayName: 'ENROLLED ON ', fieldName: 'created_at', width: '12%' },
	{ displayName: 'LEARNING TIME ', fieldName: 'learning_time', width: '11%' },
	{ displayName: '', fieldName: '', width: '20%' },
];

const EnrollmentStudents: React.FC = () => {
	const dispatch = useDispatch();
	const params = useParams<{ id: string; name: string; crid: string }>();
	const { setIsLoading, isLoading } = usePageLoader();
	const { displaySnackbar } = useSnackbar();
	const {
		errors,
		loaders,
		courseUsers = [],
		lastPage,
		perPage,
		page,
		coursesFilters,
		totalResults,
	} = useTypedSelector((state) => state.classroom);

	const [checked, setChecked] = useState<boolean>(false);
	const [openResetAllModel, setOpenResetAllModel] = useState<boolean>(false);
	const [openUserSyncAllModel, setOpenUserSyncAllModel] =
		useState<boolean>(false);

	const onPageChangeUsersRequest = useCallback(
		({
			activePage,
			endDate,
			filters,
			query,
			sortBy,
			startDate,
			perPage,
		}: onPageChangeRequestMeta) => {
			dispatch(
				getCourseUsers({
					page: activePage,
					perPage: perPage ?? 10,
					sortBy,
					filters,
					query,
					courseId: Number(params.id),
					from: startDate,
					to: endDate,
				})
			);
		},
		[dispatch, params.id]
	);

	const onPageChangProgressRequest = useCallback(
		({
			activePage,
			endDate,
			filters,
			query,
			sortBy,
			startDate,
			perPage,
		}: onPageChangeRequestMeta) => {
			dispatch(
				getCourseProgress({
					page: activePage,
					perPage: perPage ?? 10,
					sortBy,
					filters,
					query,
					courseId: Number(params.id),
					enrolled: true,
					from: startDate,
					to: endDate,
				})
			);
		},
		[dispatch, params.id]
	);

	/* handling get all loader */
	useEffect(() => {
		if (!isLoading && loaders.getCourseUsers === true) setIsLoading(true);
		if (isLoading && loaders.getCourseUsers === false) setIsLoading(false);
		if (isLoading && loaders.getCourseUsers === undefined) {
			setIsLoading(false);
			displaySnackbar('error', errors.getCourseUsers);
		}
	}, [
		loaders.getCourseUsers,
		setIsLoading,
		errors.getCourseUsers,
		displaySnackbar,
		isLoading,
	]);

	const handleModelResetAllClose = async (
		close: boolean,
		confirm?: boolean
	) => {
		if (confirm) {
			try {
				await UsersRequester.getInstance().resetAllStudent(Number(params.id));
				displaySnackbar('success', `Reset All Users successfully`);
				dispatch(
					getCourseProgress({
						page: 1,
						perPage: 10,
						sortBy: '',
						filters: [],
						query: '',
						courseId: Number(params.id),
						enrolled: true,
					})
				);
			} catch (e) {
				const msg = e.response?.data.message || 'Unable to Reset All Users ';
				displaySnackbar('error', msg);
			}
		}
		setOpenResetAllModel(false);
	};

	return (
		<SidebarContentWrapper>
			<EdConfirmModal
				text="Are you sure you want to reset all users?"
				open={openResetAllModel}
				handleClose={handleModelResetAllClose}
			/>
			<Spacer marginBottom={'2.813rem'} mx={'2.125rem'} />
			<FlexLayout
				justifyContent="space-between"
				alignItems="center"
				width="100%"
				marginBottom="1.625rem"
			>
				<ButtonGroup value={checked} exclusive aria-label="text alignment">
					<UserButton
						value={false}
						aria-label="Users"
						onClick={() => {
							setChecked(false);
						}}
					>
						Users
					</UserButton>
					<UserButton
						value={true}
						aria-label="Progress"
						onClick={() => {
							setChecked(true);
						}}
					>
						Progress
					</UserButton>
				</ButtonGroup>
				{checked ? (
					<FlexLayout gridGap="1rem" alignItems="center" marginRight="1rem">
						<Button
							variant="outlined"
							color="warning"
							onClick={() => setOpenResetAllModel(true)}
						>
							RESET ALL
						</Button>
						<SyncButton
							variant="outlined"
							onClick={() => setOpenUserSyncAllModel(true)}
						>
							SYNCRONIZE ALL
						</SyncButton>
					</FlexLayout>
				) : (
					<StyleAddBtn
						variant="contained"
						sx={{
							borderRadius: 8,
							fontFamily: ' Montserrat',
							fontSize: '14px',
							fontWeight: '500',
							marginRight: '9px',
						}}
						startIcon={<AddIcon />}
					>
						Add Course
					</StyleAddBtn>
				)}
			</FlexLayout>

			<UserSyncModel
				handleModelClose={setOpenUserSyncAllModel}
				openModel={openUserSyncAllModel}
				id={Number(params.id)}
			/>

			<MainTable
				onPageChangeRequest={
					checked ? onPageChangProgressRequest : onPageChangeUsersRequest
				}
				totalPages={lastPage}
				tableFilters={coursesFilters}
				total={Number(totalResults)}
				RowsperPage={Number(perPage)}
				pageNum={Number(page)}
				tableHeads={checked ? progressTableHeaders : TABLE_HEADS}
				renderItems={() => {
					if (courseUsers.length <= 0)
						return (
							<MainTableRow>
								<TableCell
									colSpan={
										checked ? progressTableHeaders.length : TABLE_HEADS.length
									}
								>
									<NoResults />
								</TableCell>
							</MainTableRow>
						);

					return courseUsers.map((enrolled) => (
						<MainTableRow
							key={enrolled.email + enrolled.first_name}
							hover
							sx={{
								height: '52px',
								cursor: 'pointer',
								'&:active': { backgroundColor: '#6c63ff' },
							}}
						>
							<TableCellStyled>
								{sanitizeTableValue(enrolled.first_name) || '-'}
							</TableCellStyled>

							<TableCellStyled>
								{sanitizeTableValue(enrolled.last_name) || '-'}
							</TableCellStyled>

							<Tooltip
								title={sanitizeTableValue(enrolled.email)}
								placement="top"
							>
								<TableCell
									sx={{
										fontFamily: 'Roboto',
										fontSize: '1rem',
										padding: '0 1.063rem 0',
										whiteSpace: 'nowrap',
										color: '#303f9f',
										textOverflow: 'ellipsis',
										textDecoration: 'underline',
										textDecorationColor: '#303f9f',
										textUnderlineOffset: '0.4em',
										textDecorationThickness: '1px',
										overflow: 'hidden',
									}}
								>
									<a title={sanitizeTableValue(enrolled.email) || '-'}>
										{sanitizeTableValue(enrolled.email) || '-'}
									</a>
								</TableCell>
							</Tooltip>

							<TableCellStyled>
								<Chip
									label={sanitizeTableValue(enrolled.username)}
									sx={{ fontSize: '13px' }}
								/>
							</TableCellStyled>

							{checked ? (
								<>
									<ProgressTable enrolled={enrolled} />
								</>
							) : (
								<>
									<UsersTable enrolled={enrolled} />
								</>
							)}
						</MainTableRow>
					));
				}}
			/>
		</SidebarContentWrapper>
	);
};

export default EnrollmentStudents;

export const TableCellStyled = styled(TableCell)`
	font-family: Roboto;
	font-size: 1rem;
	padding: 0 1.063rem 0;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
`;

const ButtonGroup = styled(ToggleButtonGroup)`
	align-items: center;
`;

const SyncButton = styled(Button)`
	color: #6c63ff;
	border: 1px solid #6c63ff;
`;
const UserButton = styled(ToggleButton)`
	background: #6e7577;
	color: #fff;
	font-size: 0.875rem;
	font-weight: normal;
	height: 2.7rem;
	transition: all 0.1s ease-in-out;
	&:hover {
		background: #6e7577;
		color: #fff;
		transition: all 0.1s ease-in-out;
	}
	&.Mui-selected,
	&.Mui-selected:hover {
		background: #5ac0fc;
		height: 3rem;
		color: #fff;
		transition: all 0.1s ease-in-out;
	}
`;
