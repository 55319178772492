import { SectionPayload } from '@Pages/ClassRooms/Types/ClassroomTabs.types';
import api from '@Services/api';
import { AxiosResponse } from 'axios';
import {
	CRUDBase,
	DeleteSingleEntityPayload,
	GetAllMeta,
	GetSingleEntityPayload,
} from 'src/base/services/services.base';

export class ClassroomSectionsRequester
	implements CRUDBase<SectionPayload, SectionPayload>
{
	baseURL = 'section/';
	getAll(
		payload?: GetAllMeta
	): Promise<AxiosResponse<ApiResponse<SectionPayload[]>>>;
	getAll(
		payload: number
	): Promise<AxiosResponse<ApiResponse<SectionPayload[]>>>;
	getAll(payload?: any): Promise<AxiosResponse<ApiResponse<SectionPayload[]>>> {
		return api.post(`${this.baseURL}fetch`, payload);
	}
	get(id: string): Promise<AxiosResponse<ApiResponse<SectionPayload>>>;
	get(id: number): Promise<AxiosResponse<ApiResponse<SectionPayload>>>;
	get(
		id: string | number,
		payload: GetSingleEntityPayload
	): Promise<AxiosResponse<ApiResponse<SectionPayload>>>;
	get(
		id: any,
		payload?: any
	): Promise<AxiosResponse<ApiResponse<SectionPayload>>> {
		throw new Error('Method not implemented.');
	}
	create(
		payload: Omit<SectionPayload, 'id'>
	): Promise<AxiosResponse<ApiResponse<SectionPayload>>> {
		return api.post(`${this.baseURL}create`, payload);
	}
	update(
		payload: SectionPayload
	): Promise<AxiosResponse<ApiResponse<SectionPayload>>> {
		return api.patch(`${this.baseURL}update`, payload);
	}
	delete(id: number): Promise<AxiosResponse<ApiResponse<any>>>;
	delete(id: string): Promise<AxiosResponse<ApiResponse<any>>>;
	delete(
		id: string | number,
		payload: DeleteSingleEntityPayload
	): Promise<AxiosResponse<ApiResponse<any>>>;
	async delete(
		id: any,
		payload?: any
	): Promise<AxiosResponse<ApiResponse<any>>> {
		return await api.delete(`${this.baseURL}delete`, {
			data: {
				id,
			},
		});
	}

	private static instance: ClassroomSectionsRequester;

	static getInstance() {
		if (!ClassroomSectionsRequester.instance) {
			ClassroomSectionsRequester.instance = new ClassroomSectionsRequester();
		}
		return this.instance;
	}
}
