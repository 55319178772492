import { IFetchAll } from '@Features/generics/generics.type';
import api from '@Services/api';

const entity_base_url = 'recharge-cards/';
export const getAllWalletCodesReq = async (args: IFetchAll) =>
	await api.post(`${entity_base_url}all`, args);

export const generateWalletCodes = async (args: {
	quantity: number;
	value: number;
}) => await api.post(`${entity_base_url}generate`, args);

export const deleteWalletCodeReq = async (args: { id: number }) =>
	await api.delete(`${entity_base_url}delete`, { data: args });

export const deleteWalletCode = async (id: number) =>
	await api.delete(`${entity_base_url}delete`, { data: { id } });

export const deleteWalletCodeByBatchReq = async (args: {
	batch_number: number;
}) => await api.delete(`${entity_base_url}delete/by-batch`, { data: args });
