import api from '@Services/api';
import { ClassRoom } from '@Services/students/students.res.types';
import { AxiosResponse } from 'axios';
import {
	CRUDBase,
	DeleteSingleEntityPayload,
	GetAllMeta,
	GetSingleEntityPayload,
} from 'src/base/services/services.base';

interface ClassRoomTabCRUD extends CRUDBase<ClassRoom, ClassRoom> {}
export class ClassRoomTabRequester implements ClassRoomTabCRUD {
	create(
		payload: Omit<ClassRoom, 'id'>
	): Promise<AxiosResponse<ApiResponse<ClassRoom>>> {
		throw new Error('Method not implemented.');
	}
	update(payload: ClassRoom): Promise<AxiosResponse<ApiResponse<ClassRoom>>> {
		throw new Error('Method not implemented.');
	}
	private static instance: ClassRoomTabRequester;
	static getInstance() {
		if (!ClassRoomTabRequester.instance) {
			ClassRoomTabRequester.instance = new ClassRoomTabRequester();
		}
		return ClassRoomTabRequester.instance;
	}
	baseURL = 'user/profile';
	getAll(
		payload?: GetAllMeta
	): Promise<AxiosResponse<ApiResponse<ClassRoom[]>>>;
	getAll(payload: number): Promise<AxiosResponse<ApiResponse<ClassRoom[]>>>;
	async getAll(
		payload?: any
	): Promise<import('axios').AxiosResponse<ApiResponse<ClassRoom[]>>> {
		return await api.post(`${this.baseURL}/${payload.id}/classrooms`, payload);
	}
	get(id: string): Promise<AxiosResponse<ApiResponse<ClassRoom>>>;
	get(id: number): Promise<AxiosResponse<ApiResponse<ClassRoom>>>;
	get(
		id: string | number,
		payload: GetSingleEntityPayload
	): Promise<AxiosResponse<ApiResponse<ClassRoom>>>;
	async get(
		id: any,
		payload?: any
	): Promise<AxiosResponse<ApiResponse<ClassRoom>>> {
		return await api.post(`${this.baseURL}`, { uuid: id });
	}
	delete(id: number): Promise<AxiosResponse<ApiResponse<any>>>;
	delete(id: string): Promise<AxiosResponse<ApiResponse<any>>>;
	delete(
		id: string | number,
		payload: DeleteSingleEntityPayload
	): Promise<AxiosResponse<ApiResponse<ClassRoom>>>;
	async delete(
		id: any,
		payload?: any
	): Promise<AxiosResponse<ApiResponse<any>>> {
		return await api.delete(`${this.baseURL}/delete`, {
			data: {
				uuid: id,
			},
		});
	}
}
