import {
	Autocomplete,
	Box,
	Button,
	Modal,
	OutlinedInput,
	TextField,
} from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import styled from 'styled-components';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useDispatch } from 'react-redux';
import {
	generateScratchCards,
	getScratchCards,
} from '@Features/scratchCards/scratchCardsSlice';
import {
	fetchBatchesDropdown,
	fetchClassroomsByInstructorDropdown,
} from '@Features/dropdowns/dropdownsSlice';
import { useAppThunkDispatch, useTypedSelector } from '@Features/store';
import EdNumericInput from '@Components/UI/Inputs/EdNumericInput/EdNumericInput';
import { Divider, Spacer } from '@Styled/utilities';
import {
	promoCodesCreate,
	promoCodesGenerate,
	promoCodesUpdate,
} from '../Slices/PromoCodes.slice';
import useFilters from '@Hooks/useFilters';
import useQueryURL from '@Hooks/useQueryUrl';
import { Switch } from '@mui/material';
import { DevTool } from '@hookform/devtools';
import { FlexLayout, GridLayout } from '@eduact/ed-system';
import { CreatePromoCode, PromoCode } from '../Types/PromoCodes.types';
import EdFormControl, {
	TextFieldLabel,
} from '@Components/UI/Inputs/EdFormControl/EdFormControl';
import EdBox from '@Components/UI/Utilities/EdBox/EdBox';
import { EdFormControlLabel } from '@Components/UI/Inputs/EdFormControlLabel/EdFormControlLabel';
import { DatePicker } from '@mui/x-date-pickers';
import { useRouteProvider } from '@Providers/Routes/RoutesProvider';
import { debounce, intersection, omit, xor, xorBy, xorWith } from 'lodash';
import { useSnackbar } from '@Providers/useSnackbar';
import { searchStudentsRequest } from '@Services/dropdowns/dropdowns.req';
import SelectChip from '@Components/UI/Inputs/SelectChip/SelectChip';

type Props = {
	data?: { id: number } & PromoCode;
};

export type CourseFormData = {
	classroom_id: string;
	course_id: number | null;
	quantity: number;
	scheme: string;
	instructor_id: number | null;
	value: number;
};

const SCHEMA = yup.object({
	id: yup.number().nullable(true),
	name: yup.string().optional(),
	code: yup.string().when('id', {
		is: (id: any) => id !== undefined,
		then: (s) => s.nullable(true).max(10),
		otherwise: (s) => s.required().max(10),
	}),
	description: yup.string().nullable(true),
	max_uses: yup.number().nullable(true),
	max_uses_per_user: yup.number().nullable(true),
	value: yup.number().required(),
	value_type: yup.mixed().oneOf(['percentage', 'flat']).required(),
	value_cap: yup
		.number()
		.nullable(true)
		.transform((v) => {
			return isNaN(v) ? null : v;
		}),
	min_amount_to_apply: yup.number().nullable(true),
	expires_at: yup
		.date()
		.nullable(true)
		.transform((value) => (value === undefined ? null : value)),
	user_restriction: yup.boolean().optional(),
	instructor_id: yup
		.number()
		.nullable(true)
		.transform((value) => (isNaN(value) ? null : value)),
	classroom_id: yup
		.number()
		.nullable(true)
		.transform((value) => (isNaN(value) ? null : value)),
	course_id: yup
		.number()
		.nullable(true)
		.transform((value) => (isNaN(value) ? null : value)),
	restricted_to_new_users: yup.boolean().nullable(true),
	users: yup.array(),
});
const GenerateCodes = ({ data }: Props) => {
	const dispatch = useAppThunkDispatch();
	const {
		formState: { isValid, errors, isDirty },
		getValues,
		watch,
		reset,
		control,
		handleSubmit,
		setValue,
	} = useForm<CreatePromoCode>({
		resolver: yupResolver(SCHEMA),
		mode: 'onTouched',
		defaultValues: {
			...omit(data, 'code', 'users'),
			users: data?.users ?? [],
		} ?? { expires_at: null },
	});

	const {
		dropdownInstructors,
		dropdownClassrooms,
		dropdownCourses,
		dropdownClassroomsByInstructor,
	} = useTypedSelector((state) => state.dropdowns);

	const classroom_id = watch('classroom_id');
	const courses = useMemo(() => {
		setValue('course_id', null as any);
		return dropdownClassrooms.find((_) => _.id === classroom_id)?.courses ?? [];
	}, [classroom_id]);

	const instructor_id = watch('instructor_id');

	useEffect(() => {
		if (!instructor_id) return;
		dispatch(fetchClassroomsByInstructorDropdown(instructor_id));
	}, [instructor_id]);

	const { displaySnackbar } = useSnackbar();
	const onSubmitForm = async (formData: CreatePromoCode) => {
		try {
			if (data) {
				console.log(formData.users.map((_) => _.id));
				const resp = await dispatch(
					promoCodesUpdate({
						...formData,
						users: formData.users.map((_) => _.id),
					} as any)
				).unwrap();
				displaySnackbar('success', resp.message);
			} else {
				const resp = await dispatch(
					promoCodesCreate({
						...formData,
						users: formData.users.map((_) => _.id),
					} as any)
				).unwrap();
				displaySnackbar('success', resp.message);
			}
			onClose();
		} catch (error) {
			displaySnackbar('error', error?.data?.error);
		}
	};

	const parent = useRouteProvider();
	const onClose = () => {
		parent?.goToParent();
	};

	const students = watch('users');

	const [openAutoComplete, setOpenAutoComplete] = useState(false);
	const [query, setQuery] = useState('');
	const handleAutoComplete = async (value: string) => {
		if (value.length < 3) {
			setOptions([]);
			return;
		}
		const { data } = await searchStudentsRequest(value);
		const mapped = data.data.map((_) => ({ id: _.id, username: _.username }));
		const intersectioned = xorBy(mapped, getValues().users, (_) => _.id);
		console.log(intersectioned);
		setOptions(intersectioned);
	};
	const debounceFn = useCallback(debounce(handleAutoComplete, 1000), []);

	const handleQuery = (value: string) => {
		setQuery(value);
		debounceFn(value);
	};
	const [options, setOptions] = useState<
		Array<{ id: number; username: string }>
	>([]);

	const loading = openAutoComplete && options.length === 0;

	const value_type = watch('value_type');

	const user_restriction = watch('user_restriction');

	console.log({ user_restriction });

	return (
		<>
			<DevTool control={control} />
			<Modal
				open={true}
				onClose={onClose}
				sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
			>
				<FlexLayout>
					<EdBox
						minWidth={'60vw'}
						minHeight={'60vh'}
						bgcolor={'white'}
						width="fit-content"
						maxWidth={'75vw'}
						height="fit-content"
					>
						<form onSubmit={handleSubmit(onSubmitForm, (e) => console.log(e))}>
							<FlexLayout mb="1rem">
								<TitleStyled id="modal-modal-title">Generate Codes</TitleStyled>
							</FlexLayout>
							<Divider label="Info" />
							<GridLayout
								gridGap={'1.5rem'}
								gridTemplateColumns={{
									sm: 'repeat(1,1fr)',
									md: 'repeat(2,1fr)',
									lg: 'repeat(3,1fr)',
								}}
							>
								<EdFormControl control={control} name="name" label={'Name'} />
								{!data && (
									<EdFormControl
										required
										inputProps={{ maxLength: 10 }}
										control={control}
										name="code"
										label={'Code'}
									/>
								)}
								<EdFormControl
									control={control}
									name="value_type"
									label={'Type'}
									required
									select
								>
									<MenuItem value="percentage">Percentage</MenuItem>
									<MenuItem value="flat">Flat</MenuItem>
								</EdFormControl>
								<Controller
									control={control}
									name="value"
									render={({ field }) => (
										<FlexLayout>
											<TextFieldLabel required>Value</TextFieldLabel>
											<EdNumericInput size="small" fullWidth {...field} />
										</FlexLayout>
									)}
								/>
								{value_type === 'percentage' && (
									<Controller
										control={control}
										name="value_cap"
										render={({ field: { ref, ...field } }) => (
											// render={({ field }) => (
											<FlexLayout>
												<EdFormControlLabel>Value Cap</EdFormControlLabel>
												<EdNumericInput size="small" fullWidth {...field} />
											</FlexLayout>
										)}
									/>
								)}
								<EdFormControl
									control={control}
									name="description"
									label={'Description'}
									multiline
									minRows={2}
								/>
							</GridLayout>
							<Divider label="Usage" />
							<GridLayout
								gridGap={'1.5rem'}
								gridTemplateColumns={{
									sm: 'repeat(1,1fr)',
									md: 'repeat(2,1fr)',
									lg: 'repeat(3,1fr)',
								}}
							>
								<Controller
									control={control}
									name="max_uses"
									render={({ field: { ref, ...field } }) => (
										<FlexLayout>
											<EdFormControlLabel>Max usage</EdFormControlLabel>
											<EdNumericInput size="small" fullWidth {...field} />
										</FlexLayout>
									)}
								/>
								<Controller
									control={control}
									name="max_uses_per_user"
									render={({ field: { ref, ...field } }) => (
										<FlexLayout>
											<EdFormControlLabel>
												Max usage per user
											</EdFormControlLabel>
											<EdNumericInput size="small" fullWidth {...field} />
										</FlexLayout>
									)}
								/>
								<Controller
									control={control}
									name="min_amount_to_apply"
									render={({ field: { ref, ...field } }) => (
										// render={({ field }) => (
										<FlexLayout>
											<EdFormControlLabel>Min Value</EdFormControlLabel>
											<EdNumericInput size="small" fullWidth {...field} />
										</FlexLayout>
									)}
								/>

								<Controller
									control={control}
									name="expires_at"
									render={({ field: { onChange, value, ...field } }) => {
										const _value = value ?? null;
										return (
											<FlexLayout>
												<EdFormControlLabel>Expires At</EdFormControlLabel>
												<DatePicker
													{...field}
													value={_value}
													onChange={(v) => onChange(v)}
													renderInput={(params) => (
														<TextField {...params} size="small" />
													)}
												/>
											</FlexLayout>
										);
									}}
								/>
							</GridLayout>
							<Divider label="Target" />
							<GridLayout
								gridGap={'1.5rem'}
								gridTemplateColumns={{
									sm: 'repeat(1,1fr)',
									md: 'repeat(2,1fr)',
									lg: 'repeat(3,1fr)',
								}}
							>
								<EdFormControl
									control={control}
									name="instructor_id"
									label={'Instructor'}
									select
								>
									<MenuItem selected value={''}>
										None
									</MenuItem>
									{dropdownInstructors.map((inst) => (
										<MenuItem
											key={`${inst.first_name}-${inst.id}`}
											value={inst.id}
										>
											{inst.first_name} {inst.last_name}
										</MenuItem>
									))}
								</EdFormControl>
								<EdFormControl
									control={control}
									name="classroom_id"
									label={'Classroom'}
									select
								>
									<MenuItem selected value={''}>
										None
									</MenuItem>

									{dropdownClassroomsByInstructor.map((inst) => (
										<MenuItem key={`${inst.label}-${inst.id}`} value={inst.id}>
											{inst.title} ({inst.label})
										</MenuItem>
									))}
								</EdFormControl>
								<EdFormControl
									control={control}
									name="course_id"
									label={'Course'}
									select
								>
									<MenuItem selected value={''}>
										None
									</MenuItem>
									{courses.map((inst) => (
										<MenuItem key={`${inst.name}-${inst.id}`} value={inst.id}>
											{inst.name}
										</MenuItem>
									))}
								</EdFormControl>
								<EdFormControl
									control={control}
									name="user_restriction"
									label={'User restriction'}
									switch
								/>

								{user_restriction && (
									<Controller
										control={control}
										name="users"
										render={({ field }) => (
											<FlexLayout flexDirection={'column'} width={'100%'}>
												<FlexLayout>
													<EdFormControlLabel>Students</EdFormControlLabel>
													<Autocomplete
														size="small"
														fullWidth
														multiple
														open={openAutoComplete}
														onOpen={() => setOpenAutoComplete(true)}
														onClose={() => setOpenAutoComplete(false)}
														options={options}
														getOptionLabel={(option) => option.username}
														loading={loading}
														onChange={(e, value) => {
															console.log(value)
															field.onChange(value);
														}}
														onInputChange={(e, v) => handleQuery(v)}
														inputValue={query}
														value={field.value}
														renderTags={() => <></>}
														renderInput={({ ...params }) => (
															<TextField {...params} />
														)}
													/>
												</FlexLayout>

												<FlexLayout flexWrap={'wrap'}>
													{students.map((i, index) => {
														return (
															<>
																<SelectChip
																	onDelete={() =>
																		// onDeleteValue('educationLanguages', i)
																		setValue(
																			'users',
																			xorBy(students, [i], (_) => _.id)
																		)
																	}
																	label={i.username}
																/>
															</>
														);
													})}
												</FlexLayout>
											</FlexLayout>
										)}
									/>
								)}
								<EdFormControl
									control={control}
									name="restricted_to_new_users"
									label={'New users restriction'}
									switch
								/>
							</GridLayout>
							<ButtonsContainer>
								<Button
									variant="text"
									color="inherit"
									sx={{
										fontFamily: 'Roboto',
										fontSize: '14px',
										padding: '6px 16px',
										color: '#6c63ff',
										backgroundColor: '#fff',
										outline: 'none',
									}}
									disabled={!isValid || !isDirty}
									type="submit"
								>
									Submit
								</Button>
								<Button
									variant="text"
									color="warning"
									sx={{
										fontFamily: 'Roboto',
										fontSize: '14px',
										padding: '6px 16px',
										color: '#ff9800',
										outline: 'none',
										backgroundColor: '#fff',
									}}
									disabled={!isDirty}
									onClick={() => {
										reset();
									}}
								>
									Cancel
								</Button>
							</ButtonsContainer>
						</form>
					</EdBox>
				</FlexLayout>
			</Modal>
		</>
	);
};

export default GenerateCodes;

export const Container = styled(Box)`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	min-width: 23.625rem;
	min-height: 21rem;
	border-radius: 4px;
	border: 0;
	box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.38);
	margin: 39px 80px 50px 11.4px;
	padding: 12px 0 0;
	background-color: #ffffff;
	outline: none;
`;

export const TitleStyled = styled.span`
	font-weight: 500;
	font-family: Roboto;
	text-align: left;
	font-size: 1rem;
	color: rgba(0, 0, 0, 0.6);
`;

export const ButtonsContainer = styled(Box)`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	margin-bottom: 0.5rem;
	outline: none;
	margin-top: 1.625rem;
`;

export const RadioGroupStyled = styled(RadioGroup)`
	display: flex;
	flex-direction: row;
	.MuiTypography-root {
		font-family: Roboto;
		font-size: 0.875rem;
		color: rgba(0, 0, 0, 0.87);
	}
	.MuiRadio-root.Mui-checked {
		color: #6c63ff;
	}
`;

export const RadioStyled = styled(Radio)``;

export const SelectStyled = styled(Select)`
	width: 16.2rem;
`;

export const InputStyled = styled(OutlinedInput)`
	width: 16.2rem;
`;

export const CheckboxStyled = styled(FormControlLabel)`
	padding-left: 1.5rem;
	.MuiTypography-root {
		font-size: 0.75rem;
		font-family: Roboto;
		font-weight: normal;
	}
`;
