import React, { useState } from 'react';
import { useTypedSelector } from '@Features/store';
import { yupResolver } from '@hookform/resolvers/yup';
import { UpdateRole } from '@Pages/Roles/Services/roles.req';
import { DevTool } from '@hookform/devtools';
import EdFormControl from '@Components/UI/Inputs/EdFormControl/EdFormControl';
import { EDIT } from '@Pages/Roles/schema/RoleInfo.schema';
import { useForm } from 'react-hook-form';
import { useSnackbar } from '@Providers/useSnackbar';
import { EditForm } from '@Pages/Roles/schema/RoleInfo.schema';
import {
	Container,
	InputFormContainer,
} from '../../AddRole/Components/addRoleInfo';
import SearchInput from '@Pages/Roles/Components/SearchInput';
import CollapseContainer from '@Pages/Roles/Components/CollapseContainer';
import GroupedBtns from '@Pages/Roles/Components/GroupedBtns';
import { GroupPermissions } from '@Pages/Roles/Types/Roles.types';
import { useImmer } from 'use-immer';
import { getSingleRole } from '@Pages/Roles/Features/rolesSlice';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

const EditRoleInfo = () => {
	const { id: roleId } = useParams<{ id: string }>();
	const dispatch = useDispatch();
	const { displaySnackbar } = useSnackbar();
	const [searchInput, setSearchInput] = useState('');
	const [filteredResults, setFilteredResults] = useImmer<GroupPermissions[]>(
		[]
	);
	const { currentRoles } = useTypedSelector((state) => state.roles);
	const { group_permissions } = useTypedSelector((state) => state.dropdowns);
	const auth = useTypedSelector((state) => state.auth);
	const enabled = auth.user.type === 'super' || auth.permissions.has('u-rls');

	const defaultValues = React.useMemo<EditForm>(() => {
		if (currentRoles) {
			const defaultT: EditForm = {
				id: currentRoles.id,
				description: currentRoles.description,
				title: currentRoles.title,
				active: currentRoles.active,
				permissions: currentRoles?.permissions?.map((_) => _.id),
			};
			return defaultT;
		} else {
			return {} as EditForm;
		}
	}, []);

	const { formState, control, getValues } = useForm<EditForm>({
		resolver: yupResolver(EDIT),
		mode: 'all',
		defaultValues: defaultValues,
	});

	const onSave = async (form: EditForm) => {
		const { id, title, description, permissions } = form;
		const {
			data: { data },
		} = await UpdateRole(id, title, description, permissions as Array<number>);
		dispatch(
			getSingleRole({
				id: Number(roleId),
			})
		);
		displaySnackbar('success', `Role ${data.title} Updated Successfully`);
	};

	return (
		<>
			<Container>
				<InputFormContainer>
					<EdFormControl
						control={control}
						required
						name="title"
						label="Roles name"
						disabled={!enabled}
					/>
					<EdFormControl
						control={control}
						multiline
						required
						width={'100%'}
						formNoValidate
						name="description"
						label="Roles description"
						disabled={!enabled}
					/>
				</InputFormContainer>

				<SearchInput
					group_permissions={group_permissions}
					setSearchInput={setSearchInput}
					setFilteredResults={setFilteredResults}
					searchInput={searchInput}
				/>

				<CollapseContainer
					group_permissions={group_permissions}
					searchInput={searchInput}
					filteredResults={filteredResults}
					name="permissions"
					control={control}
					disabled={!enabled}
				/>
				{enabled && (
					<GroupedBtns
						onSave={onSave}
						getValues={getValues}
						formState={formState}
					/>
				)}
				<DevTool control={control} />
			</Container>
		</>
	);
};

export default EditRoleInfo;
