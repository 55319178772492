import useInvalidateRouteGroup from '@Hooks/Routes/useInvalidateRouteGroup';
import React, { useContext, useEffect } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { AdminsRouteGroup } from './Constants/routes';
import AdminsTable from './Pages/AdminsTable/AdminsTable';
import { BreadcrumbContext } from '@Components/UI/Layout/Layout';
import useRouteGroup from '@Hooks/Routes/useRouteGroup';

const Admins = () => {
	const { url, path } = useRouteMatch();
	const routeGroup = useRouteGroup('admins');
	const { isCurrent } = useInvalidateRouteGroup({
		baseURL: '/dashboard/admins',
		mode: 'end',
		routeGroup,
		routePlacement: 2,
	});

	const { removeLink } = useContext(BreadcrumbContext);
	useEffect(() => {
		if (isCurrent) {
			removeLink && removeLink('admins');
		}
	}, [isCurrent]);

	return (
		<>
			<Switch>
				{routeGroup && !Array.isArray(routeGroup) && (
					<>
						<Route exact path={url}>
							<AdminsTable />
						</Route>

						{routeGroup.routes.map((_) => {
							return (
								<Route strict path={`${path}/${_.route}`}>
									{_.component}
								</Route>
							);
						})}
					</>
				)}
			</Switch>
		</>
	);
};

export default Admins;
