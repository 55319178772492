import MainTable from '@Components/MainTable';
import { MainTableRow } from '@Components/MainTable/MainTable';
import { onPageChangeRequestMeta } from '@Components/MainTable/MainTable.types';
import NoResults from '@Components/NoResults/NoResults';
import EdIcon from '@Components/UI/Utilities/EdIcon/EdIcon';
import EdSpeedDial, {
	EdSpeedDialAction,
} from '@Components/UI/Utilities/EdSpeedDial/EdSpeedDial';
import { useAppThunkDispatch, useTypedSelector } from '@Features/store';
import AddClassroomButton from '@Pages/ClassRooms/Components/AddClassroomButton';
import { groupsGetAll } from '@Pages/ClassRooms/Slices/Groups/Groups.slice';
import { Button, TableCell } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Tooltip } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import {
	assignStudentToGroup,
	groupStudentsGetAll,
	removeStudentGroup,
} from '@Pages/ClassRooms/Slices/GroupStudents/GroupStudents.slice';
import IconButton from '@Components/IconButton/IconButton';

const TABLE_HEADS: Array<TableHeader> = [
	{ displayName: 'Name', fieldName: 'name' },
	{ displayName: 'Email', fieldName: 'email', canSort: true },
	{ displayName: 'Username', fieldName: 'username', canSort: true },
];
export const StudentsTable = () => {
	const dispatch = useAppThunkDispatch();

	const { currentClassroom } = useTypedSelector((state) => state.classroom);
	const { currentEntity } = useTypedSelector((state) => state.Groups);

	const [refetch, setRefetch] = useState(false);
	const onPageRequestChange = useCallback(
		({
			activePage,
			endDate,
			filters,
			query,
			sortBy,
			startDate,
			perPage,
			export: exportData,
		}: onPageChangeRequestMeta) => {
			if (currentClassroom && currentEntity.id) {
				dispatch(
					groupStudentsGetAll({
						page: activePage,
						filters: filters,
						perPage: perPage ?? 10,
						query,
						export: exportData ?? false,
						sortBy: sortBy,
						from: startDate,
						to: endDate,
						classroom_id: currentClassroom?.id,
						group_id: currentEntity.id,
					})
				);
				setRefetch(false);
			}
		},
		[dispatch, currentClassroom, currentEntity.id]
	);

	const { fetchAll, filters, meta, selectedFilters, dateRange, query, sortBy } =
		useTypedSelector((state) => state.GroupStudents);

	const history = useHistory();

	const handleAssignStudent = async (user_id: number) => {
		try {
			const resp = await dispatch(
				assignStudentToGroup({ user_id, group_id: currentEntity.id } as any)
			).unwrap();
			setRefetch(true);
		} catch (error) {}
	};
	const handleRemoveStudent = async (user_id: number) => {
		try {
			const resp = await dispatch(
				removeStudentGroup({ user_id, group_id: currentEntity.id } as any)
			).unwrap();
			setRefetch(true);
		} catch (error) {}
	};
	return (
		<>
			{/* <div className="classroom_head__container">
				<AddGroupButton />
			</div> */}
			<MainTable
				tableFilters={filters}
				tableHeads={TABLE_HEADS}
				totalPages={meta?.last_page ?? 0}
				onPageChangeRequest={onPageRequestChange}
				RowsperPage={meta?.per_page}
				$hasActions
				refetch={refetch}
				pageNum={meta?.current_page}
				total={meta?.total}
				renderItems={() => {
					if (fetchAll.length === 0) {
						return (
							<MainTableRow>
								<TableCell colSpan={TABLE_HEADS.length}>
									<NoResults />
								</TableCell>
							</MainTableRow>
						);
					} else {
						return fetchAll.map((code) => {
							return (
								<MainTableRow>
									<TableCell>
										{code.first_name} {code.last_name}
									</TableCell>
									<TableCell>{code.email}</TableCell>
									<TableCell>{code.username}</TableCell>
									<TableCell>
										{code.group_id !== currentEntity.id && (
											<Button
												variant="contained"
												size="small"
												onClick={() => handleAssignStudent(code.id)}
											>
												<EdIcon>person_add</EdIcon>
											</Button>
										)}
										{code.group_id === currentEntity.id && (
											<Button
												variant="contained"
												size="small"
												color="error"
												onClick={() => handleRemoveStudent(code.id)}
											>
												<EdIcon>person_remove</EdIcon>
											</Button>
										)}
									</TableCell>
								</MainTableRow>
							);
						});
					}
				}}
			/>
		</>
	);
};
