import { BaseSliceController } from '@Base/slices/slices.base';
import { ScratchCard } from '@Pages/ScratchCards/Types/ScratchCards.types';
import { ScratchCardsTabRequester } from '../Services/ScratchCards.Requester';

export class ScratchCodeTabSlice extends BaseSliceController<
	ScratchCard,
	'ScratchCodeTab',
	ScratchCard
> {
	private static instance: ScratchCodeTabSlice;
	static getInstance() {
		if (!ScratchCodeTabSlice.instance) {
			ScratchCodeTabSlice.instance = new ScratchCodeTabSlice();
		}
		return ScratchCodeTabSlice.instance;
	}
	constructor() {
		super('ScratchCodeTab', ScratchCardsTabRequester.getInstance());
	}
}

export const { scratchCodeTabGetAll } =
	ScratchCodeTabSlice.getInstance().getActions();
