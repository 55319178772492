import React from 'react';
import useInvalidateRouteGroup from '@Hooks/Routes/useInvalidateRouteGroup';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { IPsRoute } from '@Pages/IPs/Constants/routes';
import IPsTable from '@Pages/IPs/IPsTable';
import styled from 'styled-components';

const IPsTab: React.FC = () => {
	const { path, url } = useRouteMatch();
	useInvalidateRouteGroup({
		baseURL: '/dashboard/ips',
		mode: 'include',
		routeGroup: IPsRoute,
	});

	return (
		<StyledContainer>
			<Switch>
				<Route path={url} exact component={IPsTable} />
			</Switch>
		</StyledContainer>
	);
};
const StyledContainer = styled.div`
	margin-top: 70px;
`;

export default IPsTab;
