import React, { useContext, useEffect } from 'react';
import { GroupRouteGroup } from './constants/groups.routes';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import { GroupsTable } from './pages/GroupsTable/GroupsTable';
import { RouteProvider } from '@Providers/Routes/RoutesProvider';
import { NavMenuContext } from '@Components/UI/Layout/Layout';
import { SingleClassroomRoute } from '@Constants/routes';
import useInvalidateRouteGroup from '@Hooks/Routes/useInvalidateRouteGroup';

export const Groups = () => {
	const { url, path, isExact } = useRouteMatch();
	const { setCurrentRouteGroup, setBaseURL } = useContext(NavMenuContext);

	useEffect(() => {
		setCurrentRouteGroup?.(SingleClassroomRoute);
	}, []);
	return (
		<RouteProvider path={url}>
			<Switch>
				<Route exact path={url}>
					<GroupsTable />
				</Route>
				{GroupRouteGroup.routes.map((_) => {
					return (
						<Route strict path={`${path}/${_.route}`}>
							{_.component}
						</Route>
					);
				})}
			</Switch>
		</RouteProvider>
	);
};
