import { BaseSliceController } from '@Base/slices/slices.base';
import { Assistants } from '../Types/Instructor.types';
import { AssistantsRequester } from '../Services/Assistants.requester';

export class AssistantsSlice extends BaseSliceController<
	Assistants,
	'Assistants',
	Assistants
> {
	private static instance: AssistantsSlice;
	static getInstance() {
		if (!AssistantsSlice.instance) {
			AssistantsSlice.instance = new AssistantsSlice();
		}
		return AssistantsSlice.instance;
	}
	constructor() {
		super('Assistants', AssistantsRequester.getInstance());
	}
}

export const { assistantsGetAll } = AssistantsSlice.getInstance().getActions();