import DragButton from '@Components/UI/Buttons/EdDragButton/EdDragButton';
import TagChip from '@Components/UI/Chips/TagChip/TagChip';
import EdIcon from '@Components/UI/Utilities/EdIcon/EdIcon';
import {
	Accordion,
	AccordionSummary,
	Divider,
	AccordionDetails,
	IconButton,
	Tooltip,
	Menu,
	MenuItem,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { FlexLayout, Spacer } from '@Styled/utilities';
import React, {
	FC,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import {
	getQuestion,
	TestQuestion,
	TestQuestionPayload,
	TestQuestionType,
	TypedTestQuestion,
} from '../../Types/TestQuestion';
import GAPAccordion from './GAPAccordion';
import MCQAccordion from './MCQAccordion';
import { QuestionContent } from './QuestionAccordion.styled';
import OrderingAccordion from '@Pages/ClassRooms/Pages/SingleCourse/Pages/CourseForm/Pages/CourseContent/Pages/Test/Components/QuestionAccordion/OrderingAccordion';
import { Tag } from '../../Types/Tags';
import useIsInViewport from '@Hooks/useIsInViewport';
import { useAppThunkDispatch, useTypedSelector } from '@Features/store';
import { testQuestionsDeleteById } from '../../Slice/TestQuestions/TestQuestionsSlice';
import { useSnackbar } from '@Providers/useSnackbar';
import EssayAccordion from './EssayAccordion';
type Props = {
	question: TestQuestion;
	type: TestQuestionType;
	dragHandlers?: DraggableProvidedDragHandleProps | null;
	index: number;
	isExpanded?: boolean;
};

const QuestionAccordion: FC<Props> = ({
	type,
	question,
	dragHandlers,
	index,
	isExpanded,
}) => {
	const [expanded, setExpanded] = useState(false);
	const dispatch = useAppThunkDispatch();
	const history = useHistory();
	const { url } = useRouteMatch();
	const getQuestionText = useMemo(() => {
		var text = question.content.replace(/(<([^>]+)>)/gi, '');
		const splittedText = text.split('\n');
		if (splittedText[0] === '') return 'Expand to view the question';
		return splittedText[0];
	}, [question]);

	const {
		currentEntity: { active },
	} = useTypedSelector((state) => state.Tests);

	const handleQuestionNavigate = () => {
		history.push(`${url}/${type}/${question.id}`);
	};

	useEffect(() => {
		setExpanded(isExpanded ? true : false);
	}, [isExpanded]);
	const [menuEl, setMenuEl] = React.useState<null | HTMLElement>(null);

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.stopPropagation();
		setMenuEl(event.currentTarget);
	};
	const handleClose = () => {
		setMenuEl(null);
	};
	const open = Boolean(menuEl);

	const { displaySnackbar } = useSnackbar();
	const handleRemoveQuestion = () => {
		try {
			dispatch(testQuestionsDeleteById(question.id)).unwrap();
			displaySnackbar('success', 'Question removed successfully');
		} catch (error) {
			displaySnackbar('error', "Couldn't remove question");
		}
	};
	return (
		<>
			<Menu
				anchorEl={menuEl}
				open={open}
				onClose={handleClose}
				transformOrigin={{
					horizontal: 'right',
					vertical: 'top',
				}}
			>
				<StyledMenuItem onClick={handleClose}>Clone</StyledMenuItem>
				<StyledMenuItem
					onClick={(e) => {
						e.stopPropagation();
						handleRemoveQuestion();
						handleClose();
					}}
				>
					Remove
				</StyledMenuItem>
			</Menu>
			<StyledAccordion expanded={expanded}>
				<AccordionSummary
					onClick={(e) => {
						e.stopPropagation();
						e.preventDefault();
						handleQuestionNavigate();
					}}
					expandIcon={
						<IconButton
							onClick={(e) => {
								e.stopPropagation();
								setExpanded(!expanded);
							}}
						>
							<EdIcon>expand_more</EdIcon>
						</IconButton>
					}
				>
					<FlexLayout justifyContent="space-between" width="100%">
						<FlexLayout
							justifyContent="space-between"
							flex="2"
							alignItems="center"
						>
							<FlexLayout alignItems="center">
								{dragHandlers && !active && (
									<DragButton dragHandlers={dragHandlers} />
								)}
								<QuestionNumber>Q{index + 1}.</QuestionNumber>
								<QuestionContent>
									{expanded ? '' : getQuestionText}
								</QuestionContent>
								<FlexLayout mx="1rem">
									<QuestionsTags tags={question.tags} />
								</FlexLayout>
							</FlexLayout>

							<FlexLayout>
								<TypeLabel>Type</TypeLabel>
								<Spacer mx="0.5rem" />
								<TypeValueLabel>
									{question.type === 'ordering' ? 'order' : question.type}
								</TypeValueLabel>
							</FlexLayout>
						</FlexLayout>

						<FlexLayout
							flex="0.5"
							justifyContent="flex-end"
							alignItems="center"
						>
							<Spacer mx="0.5rem" />
							<Divider orientation="vertical" />
							<Spacer mx="0.5rem" />
							<FlexLayout>
								<TypeLabel>Points</TypeLabel>
								<Spacer mx="0.5rem" />
								<TypeValueLabel>{question.weight}</TypeValueLabel>
							</FlexLayout>
							<Spacer mx="0.5rem" />

							<IconButton disabled={active} onClick={handleClick}>
								<EdIcon>more_vert</EdIcon>
							</IconButton>
						</FlexLayout>
					</FlexLayout>
				</AccordionSummary>
				<StyledAccordionDetails>
					{type === 'mcq' && getQuestion(type, question) && (
						<>
							<div>
								<MCQAccordion
									question={
										getQuestion(type, question) as TypedTestQuestion<'mcq'>
									}
								/>
							</div>
						</>
					)}
					{type === 'gap' && (
						<>
							<GAPAccordion question={question as TypedTestQuestion<'gap'>} />
						</>
					)}
					{type === 'ordering' && (
						<>
							<OrderingAccordion
								question={question as TypedTestQuestion<'ordering'>}
							/>
						</>
					)}
					{type === 'essay' && (
						<EssayAccordion question={question as TypedTestQuestion<'essay'>} />
					)}
				</StyledAccordionDetails>
			</StyledAccordion>
		</>
	);
};

export default QuestionAccordion;

const QuestionNumber = styled.p`
	font-weight: bold;
	margin: 0 1rem;
	/* white-space: nowrap; */
`;

const StyledAccordion = styled(Accordion)`
	flex: 1;
	margin: 1rem 0 !important;
	/* .Mui-expanded {
    margin-top: 1rem;
    margin-bottom: 1rem;
  } */
`;

const StyledAccordionDetails = styled(AccordionDetails)`
	overflow: auto;
	padding-right: 2.313rem;
`;

const TypeLabel = styled.div`
	color: ${grey['400']};
	font-weight: 500;
`;
const TypeValueLabel = styled.div`
	font-weight: 600;
	text-transform: uppercase;
`;

const StyledMenuItem = styled(MenuItem)`
	min-width: 10.25rem;
`;

type QuestionsTagsProps = {
	tags: Tag[];
};
const QuestionsTags: React.FC<QuestionsTagsProps> = ({ tags }) => {
	const firstTag = useMemo<Tag | undefined>(() => {
		if (tags.length === 0) return;
		return tags[0];
	}, [tags]);
	const ref = useRef<HTMLDivElement>(
		null
	) as React.MutableRefObject<HTMLDivElement>;

	const toPopupTags = useMemo(() => {
		if (!firstTag) return;
		const mockArr = Array.from(tags);
		mockArr.splice(0, 1);
		if (mockArr.length === 0) return;
		return mockArr;
	}, [firstTag, tags]);

	return (
		<>
			<TagsContainer>
				<>
					{toPopupTags && toPopupTags.length > 0 && (
						<PopupTags ref={ref}>
							{toPopupTags.map((tag, index) => {
								return (
									<PopupChip
										$length={toPopupTags.length}
										$index={index}
										bgColor={tag.theme}
										icon={<EdIcon>local_offer</EdIcon>}
										label={tag.name}
									/>
								);
							})}
						</PopupTags>
					)}
				</>
				{firstTag && (
					<StyledTagChip
						sx={{ zIndex: 8 }}
						bgColor={firstTag.theme}
						icon={<EdIcon>local_offer</EdIcon>}
						label={firstTag.name}
					/>
				)}
			</TagsContainer>
		</>
	);
};

const PopupTags = styled.div`
	position: absolute;
	padding: 0.5rem;
	border-radius: 5px;
	background: #fff;
	z-index: 9999;
	bottom: 100%;
	display: flex;
	transition: all cubic-bezier(0.65, 0.05, 0.36, 1) 300ms;
	flex-direction: column;
	grid-row-gap: 0.5rem;
	margin-bottom: 0.5rem;
	transform: scaleY(0) scaleX(1) translateX(-6%);
	transform-origin: bottom center;
	box-shadow: 0px 2px 4px 1px #00000021;
`;
const PopupChip = styled(TagChip)<{ $index: number; $length: number }>`
	transform: ${(props) =>
		`translateY(${(props.$length - props.$index) * 120}%) translateX(${
			(props.$length - props.$index) * 0
		}%)`};
	transition: all cubic-bezier(0.65, 0.05, 0.36, 1) 400ms;
	bottom: 100%;
`;

const TagsContainer = styled.div`
	position: relative;

	&:hover {
		${PopupChip} {
			transform: unset;
		}
		${PopupTags} {
			transform: scaleY(1) translateX(-6%);
			opacity: 1;
		}
	}
`;
const StyledTagChip = styled(TagChip)`
	cursor: pointer;
	z-index: 999;
`;
