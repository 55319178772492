import { keyframes } from 'styled-components';

export const ShakeKeyframe = keyframes`
     0% {
		    transform: translate(3px, 0);
		  }
		  50% {
		    transform: translate(-3px, 0);
		  }
		  100% {
		    transform: translate(0, 0);
        }
        `;

export const FillKeyframe = keyframes`
		0%{
			.extra-layer {
				transform: translate(-100%);
			}
		}
		50%{
			.extra-layer {
				transform: translate(-50%);
			}
		}
				100%{
			.extra-layer {
				transform: translate(0%);
			}
		}
		`;
