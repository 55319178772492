import api from '@Services/api';
import { Invoice } from '@Pages/Invoices/Types/Invoices.types';
import {
	CRUDBase,
	DeleteSingleEntityPayload,
	GetAllMeta,
	GetSingleEntityPayload,
} from 'src/base/services/services.base';
import { AxiosResponse } from 'axios';

interface InvoicesCRUD extends CRUDBase<any, Invoice> {}
export class InvoicesRequester implements InvoicesCRUD {
	async export(payload: GetAllMeta): Promise<AxiosResponse<string>> {
		return await api.post(`${this.baseURL}/all`, { ...payload, export: true });
	}
	private static instance: InvoicesRequester;
	static getInstance() {
		if (!InvoicesRequester.instance) {
			InvoicesRequester.instance = new InvoicesRequester();
		}
		return InvoicesRequester.instance;
	}
	baseURL = 'invoice';
	getAll(payload?: GetAllMeta): Promise<AxiosResponse<ApiResponse<Invoice[]>>>;
	getAll(payload: number): Promise<AxiosResponse<ApiResponse<Invoice[]>>>;
	async getAll(
		payload?: any
	): Promise<import('axios').AxiosResponse<ApiResponse<Invoice[]>>> {
		return await api.post(`${this.baseURL}/all`, payload);
	}
	get(id: string): Promise<AxiosResponse<ApiResponse<Invoice>>>;
	get(id: number): Promise<AxiosResponse<ApiResponse<Invoice>>>;
	get(
		id: string | number,
		payload: GetSingleEntityPayload
	): Promise<AxiosResponse<ApiResponse<Invoice>>>;

	async get(
		id: any,
		payload?: any
	): Promise<import('axios').AxiosResponse<ApiResponse<Invoice>>> {
		return await api.post(`${this.baseURL}`, { id });
	}
	create(
		payload: Omit<any, 'id'>
	): Promise<AxiosResponse<ApiResponse<Invoice>>> {
		throw new Error('Method not implemented.');
	}
	update(payload: any): Promise<AxiosResponse<ApiResponse<Invoice>>> {
		throw new Error('Method not implemented.');
	}
	delete(id: number): Promise<AxiosResponse<ApiResponse<any>>>;
	delete(id: string): Promise<AxiosResponse<ApiResponse<any>>>;
	delete(
		id: string | number,
		payload: DeleteSingleEntityPayload
	): Promise<AxiosResponse<ApiResponse<any>>>;
	delete(
		id: any,
		payload?: any
	): Promise<import('axios').AxiosResponse<ApiResponse<any>>> {
		throw new Error('Method not implemented.');
	}
	async process(id: number): Promise<AxiosResponse<ApiResponse<any>>> {
		return await api.post(`${this.baseURL}/process`, { id });
	}
	async updateInvoice(
		id: number,
		status: string
	): Promise<AxiosResponse<ApiResponse<any>>> {
		return await api.put(this.baseURL, { id, status });
	}
}

export const updateInvoiceStatus = async (id: number, status: string) =>
	await api.post<ApiResponse<Invoice>>('/invoice', {
		id,
		status,
	});
export const processInvoice = async (id: number) =>
	await api.post<ApiResponse<Invoice>>('/invoice/process', {
		id,
	});
