import React, { FC } from 'react';
import EdIcon from '../EdIcon/EdIcon';

type Props = {
	active?: boolean;
};
const EdActiveStatus: FC<Props> = ({ active }) => {
	return <EdIcon>{active ? 'check' : 'close'}</EdIcon>;
};

export default EdActiveStatus;
