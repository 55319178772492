import { Chip, ChipProps } from '@mui/material';
import styled from 'styled-components';

type onDeleteCallback = (control: string, value: number) => void;
type SelectChipProps = {
	onDelete?: onDeleteCallback;
} & ChipProps;

const SelectChip: React.FC<SelectChipProps> = ({ ...props }) => {
	return (
		<StyledChip
			size="small"
			variant="outlined"
			sx={{
				margin: '0.25rem 0',
				marginRight: '0.25rem',
			}}
			color="primary"
			onMouseDown={(e) => e.stopPropagation()}
			{...props}
		/>
	);
};

export default SelectChip;

const StyledChip = styled(Chip)`
	span {
		overflow: unset;
	}
`;
