import { IconButton, TextField, Tooltip } from '@mui/material';
import { useSnackbar } from '@Providers/useSnackbar';
import { FlexLayout, Spacer } from '@Styled/utilities';
import React, { FC, useState } from 'react';
import { TagsRequester } from '../Services/Tags/TagsRequester';
import styled from 'styled-components';
import EdAnimatedIcon from '@Components/UI/Utilities/EdAnimatedIcon/EdAnimatedIcon';
import { fetchTagsDropdown } from '@Features/dropdowns/dropdownsSlice';
import { useDispatch } from 'react-redux';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';

type Props = {
	setAddedId?: any;
};

const AddTag: FC<Props> = ({ setAddedId }) => {
	const [value, setValue] = useState<string>('');
	const [isDirty, setIsDirty] = useState<boolean>(false);
	const [edit, setEdit] = useState<boolean>(false);
	const dispatch = useDispatch();
	const { displaySnackbar } = useSnackbar();
	const addNewTag = async () => {
		try {
			const {
				data: { data },
			} = await TagsRequester.instance.create({
				name: value as string,
				theme: '#5AC0FC',
				id: null,
			});
			setAddedId(data?.id);
			setEdit(false);
			setValue('');
			dispatch(fetchTagsDropdown());
			displaySnackbar('success', 'Tag added successfully');
		} catch (error) {
			displaySnackbar('error', "Couldn't add tag");
		}
	};

	return (
		<>
			{!edit && (
				<Tooltip title="Add Tag" placement="top">
					<Note fontSize="medium" onClick={() => setEdit(true)} />
				</Tooltip>
			)}
			{edit && (
				<FlexLayout alignItems="start">
					<TextFieldStyle
						value={value}
						onChange={(e) => {
							setValue(e.target.value);
							setIsDirty(true);
						}}
					/>
					{isDirty && (
						<>
							<Spacer mx="0.5rem" />
							<FlexLayout alignItems="center" mx="2rem" gridGap="3.125rem">
								<IconButton
									type="submit"
									onClick={() => addNewTag()}
									color="primary"
									size="medium"
								>
									<EdAnimatedIcon animation="shake">
										<DoneIcon />
									</EdAnimatedIcon>
								</IconButton>
								<IconButton
									color="inherit"
									type="submit"
									size="medium"
									onClick={() => {
										setIsDirty(false);
										setEdit(false);
										setValue('');
									}}
								>
									<EdAnimatedIcon animation="shake">
										<CloseIcon />
									</EdAnimatedIcon>
								</IconButton>
							</FlexLayout>
						</>
					)}
				</FlexLayout>
			)}
		</>
	);
};

export default AddTag;

const TextFieldStyle = styled(TextField)`
	.MuiInputBase-input {
		height: 0.5rem;
	}
	.MuiOutlinedInput-notchedOutline {
		height: 2.7rem;
	}
`;

const Note = styled(AddCircleOutlineOutlinedIcon)`
	cursor: pointer;
	font-size: 2rem;
	margin-top: 5px;
	&:hover {
		color: #5ac0fc;
		transition: 0.45s ease-in-out;
	}
`;
