import { useTypedSelector } from '@Features/store';
import { FlexLayout, GridLayout, Spacer } from '@Styled/utilities';
import { report } from 'process';
import React from 'react';
import {
	DataCard,
	DataCardLabel,
	DataCardValue,
} from './CourseReportLayout.styled';
import OverviewGraph from './OverviewGraph';
import { ResponsivePie } from '@nivo/pie';
import OverviewPieChart from './OverviewPieChart';
const CourseReportLayout = () => {
	const { report } = useTypedSelector((state) => state.CourseReports);
	return (
		<FlexLayout width="100%" flexDirection={'column'}>
			<FlexLayout width={'100%'} gridGap={'1rem'}>
				<FlexLayout flex="1" flexDirection={'column'}>
					<DataCard color="primary">
						<DataCardLabel fontWeight={'bold'} fontSize={'1.25rem'}>
							Enrolled Students
						</DataCardLabel>
						<DataCardValue flex={'1'} alignItems="center" fontSize={'1.75rem'}>
							{report?.enrolled_users}
						</DataCardValue>
					</DataCard>
					<Spacer mb="1rem" />
					<GridLayout gridGap={'0.5rem'} gridTemplateColumns={'repeat(3,1fr)'}>
						<DataCard color="malachite">
							<DataCardLabel>Completed</DataCardLabel>
							<DataCardValue>{report?.completed_users}</DataCardValue>
						</DataCard>
						<DataCard color="purple">
							<DataCardLabel>In Progress</DataCardLabel>
							<DataCardValue>{report?.in_progress_users}</DataCardValue>
						</DataCard>
						<DataCard color="warning">
							<DataCardLabel>Not Started</DataCardLabel>
							<DataCardValue>{report?.not_started_users}</DataCardValue>
						</DataCard>
					</GridLayout>
				</FlexLayout>
				<DataCard color="cadet">
					<Spacer mx="2.75rem">
						<FlexLayout flexDirection={'column'} alignItems="center">
							<DataCardValue fontSize="2.25rem">
								{((report?.average_test_score ?? 0) * 100).toFixed(1)}%
							</DataCardValue>
							<DataCardLabel>Tests Average Score</DataCardLabel>
						</FlexLayout>
					</Spacer>
				</DataCard>
			</FlexLayout>

			<FlexLayout width={'100%'} gridGap={'1rem'}>
				<FlexLayout flex="1">
					<OverviewGraph />
				</FlexLayout>
				<FlexLayout>
					<OverviewPieChart />
				</FlexLayout>
			</FlexLayout>
		</FlexLayout>
	);
};

export default CourseReportLayout;
