import { GetAllCategories } from '../Services/Categories.req';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import createThunkBody from '@Utils/createThunkBody';
import {
	deleteCategoryResponse,
	getAllCategoriesResponse,
	updateCategoryResponse,
} from '../Services/Categories.req.types';
import {
	FetchAllCategoriesPayload,
	CategoriesState,
	DeleteCategoryPayload,
	UpdateCategoryPayload,
} from './CategoriesSlice.Types';
import {
	DeleteCategory as DeleteCategoryRequest,
	UpdateCategory,
} from '../Services/Categories.req';
import { CategoryResponse } from '../Types/Category.types';
import { CategoryRequester } from '../Services/Categories.req';

//side effects
export const FetchAllCategories = createAsyncThunk<
	getAllCategoriesResponse,
	FetchAllCategoriesPayload,
	{ rejectValue: string }
>(
	'categories/fetch',
	async ({ page, perPage, sortBy, filters, query, from, to }, thunkApi) => {
		const { data } = await GetAllCategories(
			page,
			perPage,
			filters,
			sortBy,
			query,
			from,
			to
		);
		return data;
	}
);

export const DeleteCategory = createAsyncThunk<
	deleteCategoryResponse,
	DeleteCategoryPayload,
	{ rejectValue: string }
>('categories/delete', async ({ id }, thunkApi) => {
	return await createThunkBody(
		DeleteCategoryRequest(id),
		thunkApi,
		'Error fetching categories'
	);
});

export const UpdateCategoryAsyncThunk = createAsyncThunk<
	updateCategoryResponse,
	UpdateCategoryPayload,
	{ rejectValue: string }
>('categories/update', async ({ id, name, code, icon }, thunkApi) => {
	return await createThunkBody(
		UpdateCategory(id, name, code, icon),
		thunkApi,
		'Error updating category'
	);
});

export const getSingleCategory = createAsyncThunk<
	ApiResponse<CategoryResponse>,
	{ id: number },
	{ rejectValue: string }
>('/category', async ({ id }) => {
	const { data } = await CategoryRequester.getInstance().get(id, { id });
	return data;
});

const initialState: CategoriesState = {
	categories: [],
	currentCategory: null,
	page: 0,
	perPage: 0,
	lastPage: 0,
	total: 0,
	categoriesFilters: [],
	loaders: {
		fetchAll: null,
		delete: null,
		getOne: null,
	},
	errors: {
		fetchAll: '',
		delete: '',
		getOne: '',
	},
};

// slice
const categorySlice = createSlice({
	name: 'category',
	initialState,
	reducers: {},
	extraReducers(builder) {
		// fetch all categories builder
		builder
			.addCase(FetchAllCategories.pending, (state) => {
				state.loaders.fetchAll = true;
				state.errors.fetchAll = '';
			})
			.addCase(
				FetchAllCategories.fulfilled,
				(state, { payload: { data, filters, message, meta } }) => {
					state.loaders.fetchAll = false;
					state.categories = data;
					state.lastPage = meta?.last_page;
					state.categoriesFilters = filters || [];
					state.total = meta?.total;
					state.page = meta?.current_page;
					state.perPage = meta?.per_page;
					state.currentCategory = null;
				}
			)
			.addCase(FetchAllCategories.rejected, (state, { payload }) => {
				state.loaders.fetchAll = false;
				state.errors.fetchAll = payload || 'error in fetching all categories';
			});

		// single category
		builder
			.addCase(getSingleCategory.pending, (state) => {
				state.loaders.getOne = true;
				state.errors.getOne = '';
			})
			.addCase(getSingleCategory.fulfilled, (state, { payload }) => {
				state.loaders.getOne = 'fulfilled';
				state.currentCategory = payload.data;
			})
			.addCase(getSingleCategory.rejected, (state, { payload }) => {
				state.loaders.fetchAll = undefined;
				state.errors.getOne =
					payload || 'could not get category, please try again!';
			});

		// delete category builder
		builder
			.addCase(DeleteCategory.pending, (state) => {
				state.loaders.delete = true;
				state.errors.delete = '';
			})
			.addCase(DeleteCategory.fulfilled, (state, { payload }) => {
				state.loaders.delete = false;
			})
			.addCase(DeleteCategory.rejected, (state, { payload }) => {
				state.loaders.delete = false;
				state.errors.delete = payload || '';
			});
	},
});
export default categorySlice.reducer;
