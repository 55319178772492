import api from '@Services/api';
import {
	FetchTabsResponse,
	CreateOrUpdateOrDeleteTabResponse,
	CreateTabRequestPayload,
	UpdateManyTabsRequestPayload,
	UpdateManyTabsResponse,
} from '@Services/classrooms/classroomTabs.api.types';

export const fetchAllTabs = (classroomId: number) =>
	api.get<FetchTabsResponse>(`/classroom/tabs/fetch/${classroomId}`);

export const deleteTabRequest = (tabId: number) =>
	api.delete<CreateOrUpdateOrDeleteTabResponse>('/classroom/tabs/delete', {
		data: { id: tabId },
	});

export const createTabRequest = (tab: CreateTabRequestPayload) =>
	api.post<CreateOrUpdateOrDeleteTabResponse>('/classroom/tabs/create', tab);

export const updateManyTabsRequest = (tabs: UpdateManyTabsRequestPayload) =>
	api.patch<UpdateManyTabsResponse>('/classroom/tabs/update-many', { tabs });
