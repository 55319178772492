import EdFormControl from '@Components/UI/Inputs/EdFormControl/EdFormControl';
import EdUploadFile from '@Components/UI/Inputs/EdUploadFile/EdUploadFile';
import SelectChip from '@Components/UI/Inputs/SelectChip/SelectChip';
import { useTypedSelector } from '@Features/store';
import { yupResolver } from '@hookform/resolvers/yup';
import { Checkbox, Icon, MenuItem } from '@mui/material';
import { ChipFlexLayout } from '@Pages/ClassRooms/Components/AddEditClassroom.styled';
import {
	InstructorForm,
	FULL_SCHEMA,
} from '@Pages/Instructors/Schema/Instructor.schema';
import { useSnackbar } from '@Providers/useSnackbar';
import { Divider, Spacer } from '@Styled/utilities';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import {
	SectionLayout,
	UrlStyleWrapper,
} from '../../AddInstructor/Components/AddInstructorInfo';
import { getEducationalInfo } from '@Features/classrooms/educationalSlice';
import { EducationalInfoControlType } from '@Pages/ClassRooms/Types/Classroom.types';
import GroupedBtns from '@Pages/Instructors/components/GroupedBtns';
import { useFormUpdater } from '@Hooks/Forms/useFormUpdater';
import { Flatten } from '@Utils/types/Flatten';
import { InstructorRequester } from '@Pages/Instructors/Services/instructors.req';
import { DevTool } from '@hookform/devtools';
import { getSingleInstructor } from '@Pages/Instructors/Slices/instructorsSlice';
import {
	useHistory,
	useLocation,
	useParams,
	useRouteMatch,
} from 'react-router-dom';
import { GenderTypes } from '@Pages/Admin/Constants/admin.constants';
import { HashString } from '@Components/UI/Tabs/RoutedTabs/RoutedTabs.types';
import RoutedTabs from '@Components/UI/Tabs/Tabs';
import AssistantsTable from '../../Assistants/AssistantsTable';
import Notes from '../../Assistants copy/Notes';
import { Box } from '@eduact/ed-system';

const EditInstructoInfo: React.FC = () => {
	const { id: uuid } = useParams<{ id: string }>();
	const { hash } = useLocation();
	const { url } = useRouteMatch();
	const history = useHistory();
	// const [tabIndex, setTabIndex] = useState<HashString>(hash as HashString);
	const dispatch = useDispatch();
	const { displaySnackbar } = useSnackbar();
	const { educational_info, educational_type } = useTypedSelector(
		(state) => state.educational
	);
	const { currentInstructor } = useTypedSelector((state) => state.instructor);
	const auth = useTypedSelector((state) => state.auth);
	const hasEditPermission =
		auth.user.type === 'super' || auth.permissions.has('u-inst');
	const defaultValues = React.useMemo<InstructorForm>(() => {
		if (currentInstructor) {
			const defaults: InstructorForm = {
				first_name: currentInstructor.first_name,
				last_name: currentInstructor.last_name,
				email: currentInstructor.email,
				phone_number: currentInstructor.phone_number,
				password: currentInstructor.password,
				gender: currentInstructor.gender,
				profile_photo: currentInstructor.profile_photo,
				uuid: currentInstructor.uuid,
				language: 'Arabic',
				type: 'online',
				instructor: {
					is_active: currentInstructor?.instructor?.is_active,
					bio: currentInstructor?.instructor?.bio,
					label: currentInstructor?.instructor?.label,
					weight: currentInstructor?.instructor?.weight,
					fb_link: currentInstructor?.instructor?.fb_link,
					youtube_link: currentInstructor?.instructor?.youtube_link,
					website_link: currentInstructor?.instructor?.website_link,
					educationLanguages:
						currentInstructor?.instructor?.educationLanguages?.map(
							(_) => _.id
						) as number[],
					educationSections:
						currentInstructor?.instructor?.educationSections?.map(
							(_) => _.id
						) as number[],
					educationTypes: currentInstructor?.instructor?.educationTypes?.map(
						(_) => _.id
					) as number[],
					educationYears: currentInstructor?.instructor?.educationYears?.map(
						(_) => _.id
					) as number[],
				},
			};
			return defaults;
		} else {
			return {} as InstructorForm;
		}
	}, []);

	useEffect(() => {
		dispatch(getEducationalInfo());
	}, []);

	const { formState, watch, control, setValue, getValues } =
		useForm<InstructorForm>({
			resolver: yupResolver(FULL_SCHEMA),
			mode: 'all',
			defaultValues: defaultValues,
		});

	const watchTypes = watch('instructor.educationTypes', []);
	const watchYears = watch('instructor.educationYears', []);
	const watchLanguages = watch('instructor.educationLanguages', []);
	const watchSections = watch('instructor.educationSections', []);

	const isChildrenDisabled = React.useMemo(() => {
		return !watchTypes || (watchTypes && watchTypes.length === 0);
	}, [watchTypes]);

	const [updater] = useFormUpdater({
		data: defaultValues,
		setValue: setValue,
		keys: [
			'instructor.educationYears',
			'instructor.educationLanguages',
			'instructor.educationTypes',
			'instructor.educationSections',
		],
	});
	useEffect(() => {
		updater();
	}, [defaultValues]);

	const years = React.useMemo(() => {
		if (!watchTypes || watchTypes.length === 0) {
			setValue('instructor.educationYears', []);
			return [];
		}
		let flatYears = educational_info
			.filter((_) => _.id && watchTypes.includes(_.id))
			.map(({ educationYears }) => educationYears)
			.flat();
		flatYears = flatYears.filterDups('id');

		return flatYears;
	}, [watchTypes?.length, educational_info]);

	const languages = React.useMemo(() => {
		if (!watchTypes || watchTypes.length === 0) {
			setValue('instructor.educationLanguages', []);
			return [];
		}
		let flatLanguages = educational_info
			.filter((_) => _.id && watchTypes.includes(_.id))
			.map(({ educationLanguages }) => educationLanguages)
			.flat();
		flatLanguages = flatLanguages.filterDups('id');

		return flatLanguages;
	}, [watchTypes?.length, educational_info]);

	const sections = React.useMemo(() => {
		if (!watchYears) return;
		if (watchYears.length === 0) {
			setValue('instructor.educationSections', []);
			return [];
		}
		let flatYears = educational_info
			.map(({ educationYears }) => educationYears)
			.flat()
			.filterDups('id')
			.filter((_) => watchYears.includes(_.id ?? -1));

		let flatSections = flatYears
			.map(({ educationSections }) => educationSections)
			.flat()
			.filterDups('id');

		return flatSections;
	}, [watchYears?.length, educational_info]);

	const onDeleteValue = (
		control: EducationalInfoControlType,
		value: number
	) => {
		const values: Array<number> | undefined = getValues().instructor[control];
		if (!values) return;
		values.splice(
			values.findIndex((_) => _ === value),
			1
		);
		setValue(`instructor.${control}`, values);
	};

	const onSave = async (form: Flatten<InstructorForm>) => {
		try {
			const {
				data: { data },
			} = await InstructorRequester.getInstance().update(form);
			dispatch(getSingleInstructor({ uuid }));
			displaySnackbar('success', `Instructor Updated Successfully`);
		} catch (e) {
			const msg = e.response?.data.message;
			displaySnackbar('error', msg);
		}
	};
	const { isDirty, isValid } = formState;

	// useEffect(() => {
	// 	if (performance.navigation.type == 1) {
	// 		history.push({
	// 			pathname: `${url}`,
	// 			hash: '#info',
	// 		});
	// 	}
	// 	return () => {
	// 		performance.navigation.type == null;
	// 	};
	// }, [performance.navigation.type]);

	const tabIndex = useMemo<HashString>(() => {
		if (!hash) return 'info';
		return hash as HashString;
	}, [hash]);

	return (
		<>
			<RoutedTabs baseURL={url} withRouter>
				<RoutedTabs.Tabs
					$hashPrefix="#"
					hashes={['info', 'assistants']}
					onChange={() => {}}
					orientation="vertical"
					value={tabIndex}
				>
					<RoutedTabs.Item
						value="info"
						label={<RoutedTabs.ItemTitle>Basic Info</RoutedTabs.ItemTitle>}
						icon={<RoutedTabs.ItemIcon>menu_book</RoutedTabs.ItemIcon>}
					/>
					<RoutedTabs.Item
						value="assistants"
						label={<RoutedTabs.ItemTitle>Assistants</RoutedTabs.ItemTitle>}
						icon={<RoutedTabs.ItemIcon>group</RoutedTabs.ItemIcon>}
					/>
					<RoutedTabs.Item
						value="notes"
						label={<RoutedTabs.ItemTitle>Notes</RoutedTabs.ItemTitle>}
						icon={<RoutedTabs.ItemIcon>notes</RoutedTabs.ItemIcon>}
					/>
				</RoutedTabs.Tabs>
				<Spacer mx={''} />
				<RoutedTabs.Content value={tabIndex}>
					<RoutedTabs.ContentItem hash="info">
						<SectionLayout
							gridGap={'2rem'}
							gridTemplateColumns={{ sm: 'repeat(2,1fr)' }}
						>
							<EdFormControl
								control={control}
								required
								name="first_name"
								label="First Name"
								disabled={!hasEditPermission}
							/>
							<EdFormControl
								control={control}
								required
								name="last_name"
								label="Last Name"
								disabled={!hasEditPermission}
							/>
							<EdFormControl
								control={control}
								required
								name="email"
								label="Email"
								disabled={!hasEditPermission}
							/>
							<EdFormControl
								control={control}
								required
								name="phone_number"
								label="Phone Number"
								disabled={!hasEditPermission}
							/>
							<EdFormControl
								control={control}
								name="password"
								label="Password"
								disabled={!hasEditPermission}
							/>
							<EdFormControl
								control={control}
								select
								name="gender"
								label="Gender"
								disabled={!hasEditPermission}
							>
								{GenderTypes.map((_, index) => {
									return (
										<MenuItem
											key={`${index}-${_}`}
											value={_}
										>{`${_}`}</MenuItem>
									);
								})}
							</EdFormControl>

							<EdFormControl
								control={control}
								width="4.75rem"
								type="number"
								inputMode="numeric"
								name="instructor.weight"
								label="Weight"
								disabled={!hasEditPermission}
							/>
							<UrlStyleWrapper>
								<EdUploadFile
									folder="profilepictures/instructors"
									uploadLabel="UPLOAD"
									uploadIcon={<Icon>add</Icon>}
									control={control}
									aspectRation={1}
									name="profile_photo"
									label="Profile Photo"
									required
									optimize={false}
									disabled={!hasEditPermission}
								/>
							</UrlStyleWrapper>
							<EdFormControl
								control={control}
								multiline
								name="instructor.bio"
								label="Bio"
								disabled={!hasEditPermission}
							/>
							<EdFormControl
								control={control}
								switch
								name="instructor.is_active"
								label="Active"
								disabled={!hasEditPermission}
							/>
							<EdFormControl
								control={control}
								required
								name="instructor.label"
								label="Label"
								disabled={!hasEditPermission}
							/>
						</SectionLayout>
						<Divider label="Educational Info." />
						<SectionLayout>
							<EdFormControl
								control={control}
								required
								select
								name="instructor.educationTypes"
								multiple
								width="17rem"
								disabled={!hasEditPermission}
								renderValues={(value) => {
									return (
										<div>
											{watchTypes &&
												watchTypes?.map((id) => {
													const name = educational_type.find(
														(_) => _.value === id
													)?.name;
													return (
														<SelectChip
															disabled={!hasEditPermission}
															onDelete={() => {
																onDeleteValue('educationTypes', id);
															}}
															label={name}
														/>
													);
												})}
										</div>
									);
								}}
								SelectProps={{
									multiple: true,
									renderValue: (selected) => {
										return (
											<ChipFlexLayout>
												{watchTypes &&
													watchTypes.map((id) => {
														const name = educational_type.find(
															(_) => _.value === id
														)?.name;
														return <>{name},</>;
													})}
											</ChipFlexLayout>
										);
									},
								}}
								label="Type"
							>
								{educational_type.map((_, index) => {
									return (
										<MenuItem value={_.value}>
											<Checkbox checked={watchTypes?.includes(_.value)} />
											{_.name}
										</MenuItem>
									);
								})}
							</EdFormControl>

							<EdFormControl
								control={control}
								required
								disabled={isChildrenDisabled || !hasEditPermission}
								select
								multiple
								name="instructor.educationYears"
								width="17rem"
								renderValues={(value) => {
									return (
										<div>
											{watchYears &&
												watchYears?.map((id) => {
													const name = years.find((_) => _.id === id)?.name;
													return (
														<SelectChip
															disabled={!hasEditPermission}
															onDelete={() => {
																onDeleteValue('educationYears', id);
															}}
															label={name}
														/>
													);
												})}
										</div>
									);
								}}
								SelectProps={{
									multiple: true,
									renderValue: (selected) => {
										return (
											<ChipFlexLayout>
												{watchYears &&
													watchYears.map((i, index) => {
														const name = years.find((_) => _.id === i)?.name;
														return <>{name},</>;
													})}
											</ChipFlexLayout>
										);
									},
								}}
								label={'Year'}
							>
								{years?.map((_, index) => {
									return (
										<MenuItem key={`ed-year-${_.id}`} value={_.id}>
											<Checkbox
												checked={
													_.id !== undefined &&
													watchYears &&
													watchYears.includes(_.id)
												}
											/>

											{_.name}
										</MenuItem>
									);
								})}
							</EdFormControl>

							<EdFormControl
								control={control}
								required
								disabled={isChildrenDisabled || !hasEditPermission}
								select
								multiple
								width="17rem"
								renderValues={(selected) => {
									return (
										<div>
											{watchLanguages &&
												watchLanguages.map((i, index) => {
													const name = languages.find((_) => _.id === i)?.name;
													return (
														<>
															<SelectChip
																disabled={!hasEditPermission}
																onDelete={() => {
																	onDeleteValue('educationLanguages', i);
																}}
																label={name}
															/>
														</>
													);
												})}
										</div>
									);
								}}
								SelectProps={{
									multiple: true,
									renderValue: (selected) => {
										return (
											<ChipFlexLayout>
												{watchLanguages &&
													watchLanguages.map((i, index) => {
														const name = languages.find(
															(_) => _.id === i
														)?.name;
														return <>{name},</>;
													})}
											</ChipFlexLayout>
										);
									},
								}}
								name="instructor.educationLanguages"
								label="Language"
							>
								{languages?.map((_, index) => {
									return (
										<MenuItem key={`ed-lng-${_.id}`} value={_.id}>
											<Checkbox
												checked={
													_.id !== undefined &&
													watchLanguages &&
													watchLanguages.includes(_.id)
												}
											/>
											{_.name}
										</MenuItem>
									);
								})}
							</EdFormControl>

							{sections && sections.length > 0 && (
								<EdFormControl
									width="17rem"
									control={control}
									required
									disabled={isChildrenDisabled}
									select
									multiple
									renderValues={(selected) => {
										return (
											<div>
												{watchSections &&
													watchSections.map((i, index) => {
														const name = sections.find((_) => _.id === i)?.name;
														return (
															<>
																<SelectChip
																	onDelete={() => {
																		onDeleteValue('educationSections', i);
																	}}
																	label={name}
																/>
															</>
														);
													})}
											</div>
										);
									}}
									SelectProps={{
										multiple: true,
										renderValue: (selected) => {
											return (
												<ChipFlexLayout>
													{watchSections &&
														watchSections.map((i, index) => {
															const name = sections.find(
																(_) => _.id === i
															)?.name;
															return <>{name},</>;
														})}
												</ChipFlexLayout>
											);
										},
									}}
									name="instructor.educationSections"
									label={'Section'}
								>
									{sections?.map((_, index) => {
										return (
											<MenuItem key={`ed-lng-${_.id}`} value={_.id}>
												<Checkbox
													checked={
														_.id !== undefined &&
														watchSections &&
														watchSections.includes(_.id)
													}
												/>
												{_.name}
											</MenuItem>
										);
									})}
								</EdFormControl>
							)}
						</SectionLayout>
						<Divider label="Media Links." />
						<SectionLayout>
							<EdFormControl
								control={control}
								name="instructor.fb_link"
								label="Facebook"
								type="url"
								disabled={!hasEditPermission}
							/>
							<EdFormControl
								control={control}
								name="instructor.youtube_link"
								label="Youtube"
								type="url"
								disabled={!hasEditPermission}
							/>
							<EdFormControl
								control={control}
								name="instructor.website_link"
								label="Website Link"
								type="url"
								disabled={!hasEditPermission}
							/>
						</SectionLayout>
						{hasEditPermission && (
							<GroupedBtns
								onSave={onSave}
								getValues={getValues}
								isDirty={isDirty}
								isValid={isValid}
							/>
						)}
					</RoutedTabs.ContentItem>
					<RoutedTabs.ContentItem hash="assistants">
						<AssistantsTable
							id={currentInstructor?.id}
							instructorName={`${currentInstructor?.first_name} ${currentInstructor?.last_name}`}
						/>
					</RoutedTabs.ContentItem>
					<RoutedTabs.ContentItem hash="notes">
						<Spacer mr={'2rem'}>
							<Notes />
						</Spacer>
					</RoutedTabs.ContentItem>
				</RoutedTabs.Content>
			</RoutedTabs>
		</>
	);
};

export default EditInstructoInfo;
