import { BaseSliceController } from '@Base/slices/slices.base';
import { TestSummaryRequester } from '../Services/TestSummary.requester';
import { Attempt } from '../Types/TestSummary.types';

export class TestSummarySlice extends BaseSliceController<
	Attempt,
	'TestSummary',
	Attempt
> {
	static instance: TestSummarySlice;

	static getInstance() {
		if (!TestSummarySlice.instance) {
			TestSummarySlice.instance = new TestSummarySlice();
		}
		return TestSummarySlice.instance;
	}

	constructor() {
		super('TestSummary', TestSummaryRequester.getInstance());
	}
}

export const { testSummaryGetSingle } =
	TestSummarySlice.getInstance().getActions();
