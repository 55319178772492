import styled, { css } from 'styled-components';
import {
	flexbox,
	FlexboxProps,
	fontStyle,
	FontStyleProps,
	grid,
	GridProps,
	layout,
	LayoutProps,
	space,
	SpaceProps,
	typography,
	TypographyProps,
} from 'styled-system';
const Spacer = styled.div<SpaceProps>`
	${space}
`;
const LongTextWrapper = css`
	white-space: nowrap;
	/* text-emphasis: dot; */
	text-overflow: ellipsis;
	overflow: hidden;
`;
const GridLayout = styled.div<LayoutProps & GridProps & SpaceProps>`
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	${grid};
	${layout};
	${space};
`;
const FlexLayout = styled.div<
	FlexboxProps & LayoutProps & GridProps & SpaceProps
>`
	display: flex;
	${flexbox}
	${layout}
  ${grid}
  ${space}
`;
const FlexItem = styled.div<FlexboxProps>`
	${flexbox}
`;
const GridItem = styled.div<GridProps>`
	${grid}
`;
const Divider = styled.div<{ label?: string } & FontStyleProps & SpaceProps>`
	/* background: ${(props) => props.theme.palette.uranianBlue?.main}; */
	height: 2px;
	margin: 2.375rem 0.688rem;
	position: relative;
	font-style: italic;
	box-shadow: 0 1px 0 0 ${(props) => props.theme.palette.uranianBlue?.main};
	${fontStyle};
	${space}
	::before {
		content: ${(props) => (props.label ? `"${props.label}"` : '')};
		font-size: 0.875rem;
		background: #fff;
		position: absolute;
		top: 50%;
		left: 5%;
		transform: translateY(-50%);
		padding: 0 0.5rem;
		color: #707070;
	}
`;

const VerticalDivider = styled.div<{ label?: string }>`
	background: ${(props) => props.theme.palette.uranianBlue?.main};
	/* height: 2px; */
	width: 2px;
	margin: 2.375rem 0.688rem;
	position: relative;
	::before {
		content: ${(props) => (props.label ? `"${props.label}"` : '')};
		font-size: 0.875rem;
		background: #fff;
		position: absolute;
		top: 50%;
		left: 5%;
		transform: translateY(-50%);
		padding: 0 0.5rem;
		color: #707070;
	}
`;

const Text = styled.span<TypographyProps>`
	${typography}
`;
export {
	Spacer,
	LongTextWrapper,
	GridLayout,
	Divider,
	GridItem,
	FlexLayout,
	FlexItem,
	VerticalDivider,
	Text,
};
