import { BaseSliceController } from '@Base/slices/slices.base';
import { TestImport } from '../Types/Imports.types';
import { TestImportsRequester } from '../Services/Imports.req';

export class TestImportsSlice extends BaseSliceController<
	TestImport,
	'TestImports'
> {
	private static instance: TestImportsSlice;
	static getInstance() {
		if (!TestImportsSlice.instance) {
			TestImportsSlice.instance = new TestImportsSlice();
		}
		return TestImportsSlice.instance;
	}
	constructor() {
		super('TestImports', TestImportsRequester.getInstance());
	}
}

export const { testImportsGetAll } =
	TestImportsSlice.getInstance().getActions();
