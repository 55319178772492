import {
	CRUDBase,
	DeleteSingleEntityPayload,
	GenerateCardMeta,
	GenerateCodesMeta,
	GetAllMeta,
	GetSingleEntityPayload,
} from '@Base/services/services.base';
import { Group } from '@Pages/ClassRooms/Types/Groups.types';
import api from '@Services/api';
import { AxiosResponse } from 'axios';

export class GroupsRequester implements CRUDBase<Group> {
	export?(payload: GetAllMeta): Promise<AxiosResponse<string>> {
		throw new Error('Method not implemented.');
	}
	getAll(
		payload?: GetAllMeta | undefined
	): Promise<AxiosResponse<ApiResponse<Group[]>>>;
	getAll(payload: number): Promise<AxiosResponse<ApiResponse<Group[]>>>;
	async getAll(
		payload?: unknown
	): Promise<import('axios').AxiosResponse<ApiResponse<Group[]>>> {
		return await api.post(`classroom/${this.baseURL}fetch`, payload);
	}
	get(id: string): Promise<AxiosResponse<ApiResponse<Group>>>;
	get(id: number): Promise<AxiosResponse<ApiResponse<Group>>>;
	get(
		id: string | number,
		tId?: string | number | undefined,
		payload?: GetSingleEntityPayload | undefined
	): Promise<AxiosResponse<ApiResponse<Group>>>;
	async get(
		id: unknown,
		tId?: unknown,
		payload?: unknown
	): Promise<import('axios').AxiosResponse<ApiResponse<Group>>> {
		return await api.post(`${this.baseURL}get`, { id: id });
	}
	async create(
		payload: Omit<Group, 'id'>
	): Promise<AxiosResponse<ApiResponse<Group>>> {
		return await api.post(`${this.baseURL}`, payload);
	}
	update(payload: Group): Promise<AxiosResponse<ApiResponse<Group>>> {
		return api.put(this.baseURL, payload);
	}
	delete(id: number): Promise<AxiosResponse<ApiResponse<any>>>;
	delete(id: string): Promise<AxiosResponse<ApiResponse<any>>>;
	delete(
		id: string | number,
		payload?: DeleteSingleEntityPayload | undefined
	): Promise<AxiosResponse<ApiResponse<any>>>;
	async delete(
		id: unknown,
		payload?: unknown
	): Promise<import('axios').AxiosResponse<ApiResponse<any>>> {
		return await api.delete(`${this.baseURL}`, { data: payload });
	}
	generate?(
		payload: GenerateCodesMeta | GenerateCardMeta
	): Promise<AxiosResponse<string>> {
		throw new Error('Method not implemented.');
	}
	toggleActive?(payload: Group): Promise<AxiosResponse<ApiResponse<Group>>> {
		throw new Error('Method not implemented.');
	}
	process?(id: number): Promise<AxiosResponse<ApiResponse<any>>> {
		throw new Error('Method not implemented.');
	}
	private static instance: GroupsRequester;
	static getInstance() {
		if (!GroupsRequester.instance) {
			GroupsRequester.instance = new GroupsRequester();
		}
		return GroupsRequester.instance;
	}
	baseURL = 'groups/';
}
