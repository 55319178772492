import { StudentInfo } from '@Constants/routes';
import { useTypedSelector } from '@Features/store';
import useInvalidateRouteGroup from '@Hooks/Routes/useInvalidateRouteGroup';
import { studentsGetSingle } from '@Pages/Students/Slice/Students.Slice';
import { useSnackbar } from '@Providers/useSnackbar';
import { getEducationalInfo } from '@Services/educationalInfo/educationalInfo.req';
import { EducationalInfoResponseData } from '@Services/educationalInfo/educationalInfo.types';
import { FlexLayout, GridLayout } from '@Styled/utilities';
import React, { FC, useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Switch, useParams, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import { useImmer } from 'use-immer';
import EditStudentForm from './EditStudentForm';
import StudentCardID from '@Pages/Students/StudentTabs/Info/StudentCardID';
import { BreadcrumbContext } from '@Components/UI/Layout/Layout';
import useRouteGroup from '@Hooks/Routes/useRouteGroup';

type Props = {};

const Info: FC<Props> = () => {
	const dispatch = useDispatch();
	const { id } = useParams<{ id: string }>();
	const {
		currentEntity,
		loaders: { getSingle },
	} = useTypedSelector((state) => state.Students);

	useEffect(() => {
		dispatch(studentsGetSingle({ id }));
	}, [id]);

	const {} = currentEntity ? currentEntity : {};

	const { displaySnackbar } = useSnackbar();
	const [educationalInfoData, setEducationalInfoData] =
		useImmer<EducationalInfoResponseData>([]);

	const loadEducationalTypes = async () => {
		try {
			const {
				data: { data },
			} = await getEducationalInfo();
			setEducationalInfoData(data);
		} catch (e) {
			displaySnackbar(
				'error',
				e.response.data.message ?? 'Error in fetching educational info'
			);
		}
	};

	// get educational info
	useEffect(() => {
		loadEducationalTypes();
	}, []);
	const { url, path } = useRouteMatch();
	const { removeLink, addLink } = useContext(BreadcrumbContext);
	const { setURL, setCurrentRouteGroup, isCurrent } = useInvalidateRouteGroup({
		routeGroup: StudentInfo,
		baseURL: `${url}/info`,
		routePlacement: 2,
		mode: 'end',
		extraProps: {
			id,
		},
	});

	useEffect(() => {
		if (!url || !currentEntity) return;
		if (isCurrent) {
			removeLink?.('students');
		}
		addLink?.({
			route: url,
			title: `${currentEntity?.first_name} ${currentEntity?.last_name}`,
			routeHash: 'info',
		});
		return () => {
			removeLink?.('students');
		};
	}, [isCurrent, currentEntity, url]);

	useEffect(() => {
		if (currentEntity) {
			setURL(url);
			setCurrentRouteGroup?.(StudentInfo);
		}
	}, [currentEntity, setURL, setCurrentRouteGroup, url]);

	return (
		<div>
			{StudentInfo && !Array.isArray(StudentInfo) && (
				<Switch>
					<Route path={path} exact>
						{currentEntity && getSingle === 'fulfilled' && (
							<FlexLayout
								alignItems="start"
								justifyContent="space-between"
								gridGap="1rem"
							>
								<EditStudentForm />
								<StudentCardID />
							</FlexLayout>
						)}
					</Route>
					{StudentInfo.routes.map((route, index) => {
						return (
							<Route
								path={`${path}/${route.route}`}
								key={`${route.route}-${index}`}
							>
								{route.component}
							</Route>
						);
					})}
				</Switch>
			)}
		</div>
	);
};

export const SectionLayout = styled(GridLayout)`
	margin-right: 0;
	grid-gap: 1rem;
`;

export default Info;
