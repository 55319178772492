import React, { useCallback, useEffect, useState } from 'react';
import MainTable from '@Components/MainTable';
import { fetchAllInstructors } from '@Pages/Instructors/Slices/instructorsSlice';
import { usePageLoader } from '@Providers/LoadingProvider';
import { useTypedSelector } from '@Features/store';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import NoResults from '@Components/NoResults/NoResults';
import DateTimeFormatter from '@Components/DateTimeFormatter/DateTimeFormatter';
import Avatar from '@mui/material/Avatar';
import TableCell from '@mui/material/TableCell';
import Button from '@mui/material/Button';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import Tooltip from '@mui/material/Tooltip';
import { TableHeader } from '@Features/generics/generics.type';
import { useSnackbar } from '@Providers/useSnackbar';
import ActivationModel from './components/ActiviationModel/ActivationModel';
import SidebarContentWrapper from '@Components/SidebarContentWrapper';
import useFilters from '@Hooks/useFilters';
import useQueryURL from '@Hooks/useQueryUrl';
import {
	activateInstructor,
	deactivateInstructor,
} from '@Services/instructors/instructors.req';
import { TableCellStyled } from '@Pages/ClassRooms/Pages/SingleCourse/Pages/Users/EnrollmentStudent';
import { StyledAvatarGrouped } from '@Pages/ClassRooms/ClassRoomsTable';
import { MainTableRow } from '@Components/MainTable/MainTable';
import { TooltipTitle } from '@Utils/TooltipTitle';
import AddInstructorButton from './components/AddInstructorBtn';
import { onPageChangeRequestMeta } from '@Components/MainTable/MainTable.types';
import EdActiveStatus from '@Components/UI/Utilities/EdActiveStatus/EdActiveStatus';

const TABLE_HEADS: Array<TableHeader> = [
	{ displayName: 'FIRST NAME', fieldName: 'first_name', canSort: true },
	{ displayName: 'LAST NAME', fieldName: 'last_name' },
	{ displayName: 'LABEL', fieldName: 'label' },
	{ displayName: 'YEAR', fieldName: 'branch_id', canSort: false },
	{ displayName: 'SECTION', fieldName: 'branch_name', canSort: false },
	{ displayName: 'WEIGHT', fieldName: 'weight' },
	{ displayName: 'ACTIVE', fieldName: 'active', canSort: false },
	{ displayName: 'CREATED AT', fieldName: 'created_at' },
	{ displayName: 'STATUS', fieldName: 'active', canSort: false },
];

interface Props {}

const InstructorsTable: React.FC<Props> = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const { displaySnackbar } = useSnackbar();
	const { setIsLoading } = usePageLoader();
	const { instructors, lastPage, loaders, filters, total, perPage, page } =
		useTypedSelector((state) => state.instructor);
	const auth = useTypedSelector((state) => state.auth);
	const hasActivePermission =
		auth.user.type === 'super' || auth.permissions.has('act-inst');
	const hasDeActivePermission =
		auth.user.type === 'super' || auth.permissions.has('dact-inst');

	const [activate, setActivateInsructor] = useState<boolean>(false);
	const [instructorId, setInsrtuctorId] = useState<any>(null);
	const [openModel, setOpenModel] = useState(false);
	const { selectedFilters } = useFilters();
	const { selectedQuery } = useQueryURL();

	const eduYears = [
		{ name: 'First_Secondary', color: '#C7C5F4', breif: ' 1st' },
		{ name: 'Second_Secondary', color: '#A7A2F1', breif: '2nd' },
		{ name: 'Third_Secondary', color: '#5AC0FC', breif: '3rd' },
		{ name: 'Grade_10', color: '#6C63FF', breif: 'G10' },
		{ name: 'Grade_11', color: '#FF8532', breif: 'G11' },
		{ name: 'Grade_12', color: '#FFD037', breif: 'G12' },
		{ name: 'Third_Preparatory', color: '#5AC0FC', breif: '3rd' },
	];

	const eduSections = [
		{ name: 'Mathematics', color: '#6C63FF', breif: 'Math' },
		{ name: 'Science', color: '#FF8532', breif: 'Sci' },
		{ name: 'Literature', color: '#5AC0FC', breif: 'Lit' },
	];

	const handleModelOpen = (id: number, active?: boolean) => {
		setOpenModel(true);
		setActivateInsructor(active ?? false);
		setInsrtuctorId(id);
	};

	const handleModelClose = () => {
		setOpenModel(false);
		setActivateInsructor(false);
	};

	const onActivateInstructor = useCallback(() => {
		setIsLoading(true);
		if (!activate) {
			activateInstructor(instructorId)
				.then(() => {
					displaySnackbar('success', 'Activated Instructor successfully');
					dispatch(
						fetchAllInstructors({
							page,
							perPage,
							sortBy: '',
							filters: selectedFilters,
							query: selectedQuery,
						})
					);
				})
				.catch((e: any) => {
					const msg =
						e.response?.data.message || 'Unable to Activated Instructor ';
					displaySnackbar('error', msg);
				});
		} else {
			deactivateInstructor(instructorId)
				.then(() => {
					displaySnackbar('success', 'Deactivated Instructor successfully');
					dispatch(
						fetchAllInstructors({
							page,
							perPage,
							sortBy: '',
							filters: selectedFilters,
							query: selectedQuery,
						})
					);
				})
				.catch((e: any) => {
					const msg =
						e.response?.data.message || 'Unable to Deactivated Instructor ';
					displaySnackbar('error', msg);
				});
		}
		setOpenModel(false);
		setIsLoading(false);
	}, [activate, setIsLoading, dispatch, instructorId, displaySnackbar]);

	useEffect(() => {
		setIsLoading(!!loaders.fetchAll);
	}, [setIsLoading, loaders.fetchAll]);

	const handlePagination = useCallback(
		({
			activePage,
			endDate,
			filters,
			query,
			sortBy,
			startDate,
			perPage,
		}: onPageChangeRequestMeta) => {
			dispatch(
				fetchAllInstructors({
					page: activePage,
					perPage: perPage ?? 10,
					sortBy,
					filters,
					query,
					from: startDate,
					to: endDate,
				})
			);
		},
		[]
	);

	const handleRowClick = async (uuid?: string) => {
		if (!uuid) return;
		history.push(`/dashboard/instructors/update/${uuid}`);
	};

	return (
		<SidebarContentWrapper>
			{(auth.user.type === 'super' || auth.permissions.has('c-inst')) && (
				<div className="role_head__container">
					<AddInstructorButton />
				</div>
			)}
			<ActivationModel
				text="Instructor"
				openModel={openModel}
				handleModelClose={handleModelClose}
				ban={activate}
				onConfirmation={onActivateInstructor}
			/>

			<MainTable
				onPageChangeRequest={handlePagination}
				totalPages={lastPage}
				tableFilters={filters}
				total={Number(total)}
				RowsperPage={Number(perPage)}
				pageNum={Number(page)}
				tableHeads={TABLE_HEADS}
				renderItems={() => {
					if (instructors.length === 0) {
						return (
							<MainTableRow>
								<TableCell colSpan={TABLE_HEADS.length}>
									<NoResults />
								</TableCell>
							</MainTableRow>
						);
					} else {
						return instructors.map((_instructor) => (
							<MainTableRow
								key={_instructor.uuid}
								hover
								sx={{
									cursor: 'pointer',
									'&:active': { backgroundColor: '#6c63ff' },
								}}
								onClick={() => {
									history.push({
										pathname: `/dashboard/instructors/edit/instructor/${_instructor.uuid}/info`,
									});
								}}
							>
								<Tooltip title={_instructor?.first_name} placement="top">
									<TableCellStyled
										onClick={() => handleRowClick(_instructor?.uuid)}
									>
										{_instructor?.first_name || '-'}
									</TableCellStyled>
								</Tooltip>
								<Tooltip title={_instructor?.last_name} placement="top">
									<TableCellStyled
										onClick={() => handleRowClick(_instructor.uuid)}
									>
										{_instructor?.last_name || '-'}
									</TableCellStyled>
								</Tooltip>
								<Tooltip title={_instructor?.instructor?.label} placement="top">
									<TableCellStyled
										onClick={() => handleRowClick(_instructor.uuid)}
									>
										{_instructor?.instructor?.label || '-'}
									</TableCellStyled>
								</Tooltip>
								<TableCellStyled
									onClick={() => handleRowClick(_instructor.uuid)}
								>
									<Tooltip
										title={TooltipTitle(
											_instructor?.instructor?.educationYears
										)}
										placement="top"
									>
										<StyledAvatarGrouped max={2}>
											{_instructor?.instructor?.educationYears.map((edu: any) =>
												eduYears.map(
													(i: any) =>
														i.name === edu.name && (
															<Avatar
																alt="education year"
																key={edu.id}
																sx={{
																	backgroundColor: `${i.color}`,
																	fontSize: '10px',
																	fontFamily: 'Roboto',
																}}
															>
																{i.breif}
															</Avatar>
														)
												)
											)}
										</StyledAvatarGrouped>
									</Tooltip>
								</TableCellStyled>

								<TableCellStyled
									onClick={() => handleRowClick(_instructor.uuid)}
								>
									<Tooltip
										title={TooltipTitle(
											_instructor?.instructor?.educationSections
										)}
										placement="top"
									>
										<StyledAvatarGrouped max={2}>
											{_instructor?.instructor?.educationSections.map(
												(edu: any) =>
													eduSections.map(
														(i: any) =>
															i.name === edu.name && (
																<Avatar
																	alt="education year"
																	key={edu.id}
																	sx={{
																		backgroundColor: `${i.color}`,
																		fontSize: '10px',
																		fontFamily: 'Roboto',
																	}}
																>
																	{i.breif}
																</Avatar>
															)
													)
											)}
										</StyledAvatarGrouped>
									</Tooltip>
								</TableCellStyled>

								<TableCellStyled
									onClick={() => handleRowClick(_instructor.uuid)}
								>
									{_instructor?.instructor?.weight || '-'}
								</TableCellStyled>
								<TableCell
									sx={{
										padding: '0 1.063rem 0',
										whiteSpace: 'nowrap',
									}}
									onClick={() => handleRowClick(_instructor.uuid)}
								>
									<EdActiveStatus active={_instructor.instructor.is_active} />
								</TableCell>
								<Tooltip
									title={<DateTimeFormatter date={_instructor?.created_at} />}
									placement="top"
								>
									<TableCell
										sx={{
											fontFamily: 'Roboto',
											fontSize: '1rem',
											padding: '0 1.063rem 0',
										}}
										onClick={() => handleRowClick(_instructor.uuid)}
									>
										{_instructor.created_at ? (
											<DateTimeFormatter date={_instructor?.created_at} />
										) : (
											'-'
										)}
									</TableCell>
								</Tooltip>
								<TableCell
									sx={{
										fontFamily: 'Roboto',
										fontSize: '1rem',
										padding: '0 1.063rem 0',
									}}
								>
									<Tooltip
										placement="left"
										title={
											_instructor?.instructor?.is_active
												? 'Deactivate Instructor'
												: 'Activate Instructor'
										}
									>
										<Button
											id="instructoBanUnBan"
											disabled={
												(!hasActivePermission &&
													!_instructor?.instructor?.is_active) ||
												(!hasDeActivePermission &&
													_instructor?.instructor.is_active)
											}
											onClick={(e) => {
												e.stopPropagation();
												handleModelOpen(
													_instructor?.id,
													_instructor?.instructor?.is_active
												);
											}}
										>
											{!_instructor?.instructor?.is_active ? (
												<AccountBoxIcon
													sx={{
														color: '#f74343',
														height: '26px',
														width: '26px',
													}}
												/>
											) : (
												<AccountBoxIcon
													sx={{
														color: '#5ac0fc',
														height: '26px',
														width: '26px',
													}}
												/>
											)}
										</Button>
									</Tooltip>
								</TableCell>
							</MainTableRow>
						));
					}
				}}
			/>
		</SidebarContentWrapper>
	);
};

export default InstructorsTable;
