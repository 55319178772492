import React, { FC } from 'react';
import EdIcon from '@Components/UI/Utilities/EdIcon/EdIcon';
type Props = {
	expired: boolean;
};
const ExpiredStatus: FC<Props> = ({ expired }) => {
	return !expired ? (
		<EdIcon mIconType="Round">done</EdIcon>
	) : (
		<EdIcon mIconType="Round">close</EdIcon>
	);
};

export default ExpiredStatus;
