import EdBox from '@Components/UI/Utilities/EdBox/EdBox';
import { Dialog, DialogContent, IconButton } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import { FlexLayout } from '@Styled/utilities';
import EdIcon from '@Components/UI/Utilities/EdIcon/EdIcon';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import TagsTable from './TagsTable';
import {
	RouteProvider,
	useRouteProvider,
} from '@Providers/Routes/RoutesProvider';
import TagForm from './TagForm';
const TagsModal = () => {
	const { path } = useRouteMatch();
	const methods = useRouteProvider();

	return (
		<TagsModalDialog open={true}>
			<TagsModalContent>
				<CloseButton>
					<IconButton
						onClick={() => {
							methods?.goToParent();
						}}
					>
						<EdIcon>close</EdIcon>
					</IconButton>
				</CloseButton>
				<TagsLabel></TagsLabel>
				<TagsWrapper>
					<TagsBox padding="0" edborder="platinum">
						<TagsTableTitle>Name and Color</TagsTableTitle>
						<RouteProvider path={path}>
							<Switch>
								<Route path={`${path}`} exact>
									<TagsTable />
								</Route>
								<Route path={`${path}/edit/:id`}>
									<TagForm />
								</Route>
							</Switch>
						</RouteProvider>
					</TagsBox>
				</TagsWrapper>
			</TagsModalContent>
		</TagsModalDialog>
	);
};

export default TagsModal;

const TagsModalContent = styled(DialogContent)`
	max-height: 80vh;
	min-width: 45.625rem;
	max-width: 45.625rem;
`;

const TagsModalDialog = styled(Dialog)`
	.MuiDialog-paper {
		max-width: unset;
		max-height: unset;
		padding: 6.25rem 2.5rem 1.875rem;
	}
`;

const TagsLabel = styled.div`
	font-size: 1.125rem;
	font-weight: bold;
	margin-bottom: 1.875rem;
`;

const TagsWrapper = styled.div`
	padding: 0 7rem;
`;

const TagsBox = styled(EdBox)`
	display: flex;
	flex-direction: column;
`;

const TagsTableTitle = styled.div`
	padding: 1.563rem;
	font-weight: 600;
	color: ${(props) => props.theme.palette.darkPurple.main};
	border-bottom: 2px solid ${(props) => props.theme.palette?.platinum?.main};
`;

const CloseButton = styled(FlexLayout)`
	padding: 1rem;
	position: absolute;
	top: 0;
	right: 0;
`;
