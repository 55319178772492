import MainTable from '@Components/MainTable';
import { MainTableRow } from '@Components/MainTable/MainTable';
import { onPageChangeRequestMeta } from '@Components/MainTable/MainTable.types';
import NoResults from '@Components/NoResults/NoResults';
import EdActiveStatus from '@Components/UI/Utilities/EdActiveStatus/EdActiveStatus';
import EdIcon from '@Components/UI/Utilities/EdIcon/EdIcon';
import EdSpeedDial, {
	EdSpeedDialAction,
	SpeedDialCell,
} from '@Components/UI/Utilities/EdSpeedDial/EdSpeedDial';
import { TableHeader } from '@Features/generics/generics.type';
import { useTypedSelector } from '@Features/store';
import { styled, TableCell, Tooltip } from '@mui/material';
import AddAdminButton from '@Pages/Admin/Components/AddAdminButton/AddAdminButton';
import {
	adminsDeleteById,
	adminsGetAll,
	adminsToggleActive,
} from '@Pages/Admin/Slice/AdminSlice';
import { Admin } from '@Pages/Admin/Types/admin.types';
import { useSnackbar } from '@Providers/useSnackbar';
import { FlexLayout } from '@Styled/utilities';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import EdConfirmModal from '@Components/UI/Modals/EdConfirmModal/EdConfirmModal';

const TABLE_HEADS: Array<TableHeader> = [
	{ displayName: 'First Name', fieldName: 'first_name' },
	{ displayName: 'Last Name', fieldName: 'last_name' },
	{ displayName: 'email', fieldName: 'email' },
	{ displayName: 'username', fieldName: 'username' },
	{ displayName: 'phone', fieldName: 'phone' },
	{ displayName: 'active ', fieldName: 'active' },
];

const AdminsTable = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const { displaySnackbar } = useSnackbar();
	const [openModel, setOpenModel] = useState(false);
	const [deletedUuid, setDeletedUuid] = useState<any>('');

	const { fetchAll, filters, meta, requestStatus } = useTypedSelector(
		(state) => state.Admins
	);

	const auth = useTypedSelector((state) => state.auth);

	const handleModelOpen = (uuid: string) => {
		setOpenModel(true);
		setDeletedUuid(uuid);
	};
	const handleModelClose = (close: boolean, confirm?: boolean) => {
		try {
			if (confirm) {
				dispatch(adminsDeleteById(deletedUuid));
				displaySnackbar('success', 'Admin delete successfully');
			}
			setOpenModel(false);
			setDeletedUuid(null);
		} catch (e) {
			const msg = e.response?.data.message || "Couldn't delete admin";
			displaySnackbar('error', msg);
		}
	};

	const onToggleActive = (
		e: React.MouseEvent<HTMLDivElement>,
		admin: Admin
	) => {
		try {
			e.stopPropagation();
			dispatch(
				adminsToggleActive({ ...admin, roles: admin?.roles?.map((_) => _?.id) })
			);
			displaySnackbar(
				'success',
				`Admin ${admin?.active ? 'Deactivate' : 'Activate'} successfully`
			);
		} catch (error) {}
	};

	useEffect(() => {
		if (requestStatus.toggleActive === 'DONE') {
			displaySnackbar('success', 'Admin updated successfully');
		} else if (requestStatus.toggleActive === 'FAILED') {
			displaySnackbar('error', "Couldn't update admin");
		}
	}, [requestStatus.toggleActive]);

	const onPageChangeRequest = useCallback(
		({
			activePage,
			endDate,
			filters,
			query,
			sortBy,
			startDate,
			perPage,
		}: onPageChangeRequestMeta) => {
			dispatch(
				adminsGetAll({
					page: activePage,
					filters: filters,
					perPage: perPage ?? 10,
					query: query,
					sortBy: sortBy,
					from: startDate,
					to: endDate,
				})
			);
		},
		[]
	);

	return (
		<div>
			{(auth.user.type === 'super' || auth.permissions.has('c-adm')) && (
				<FlexLayout justifyContent="flex-end">
					<AddAdminButton />
				</FlexLayout>
			)}

			<EdConfirmModal
				text="Are you sure you want to delete this Admins"
				open={openModel}
				handleClose={handleModelClose}
			/>

			<MainTable
				total={meta?.total ?? 0}
				dateFilter
				pageNum={meta?.current_page}
				tableFilters={filters}
				tableHeads={TABLE_HEADS}
				totalPages={meta?.last_page ?? 0}
				RowsperPage={Number(meta?.per_page)}
				$hasActions
				onPageChangeRequest={onPageChangeRequest}
				renderItems={() => {
					if (fetchAll.length <= 0)
						return (
							<MainTableRow>
								<TableCell colSpan={TABLE_HEADS.length}>
									<NoResults />
								</TableCell>
							</MainTableRow>
						);
					else {
						return fetchAll.map((admin, index) => {
							return (
								<MainTableRow
									key={admin.uuid}
									hover
									onClick={() => {
										history.push({
											pathname: `/dashboard/admins/edit/admin/${admin.uuid}`,
											hash: '#info',
										});
									}}
								>
									<TableCellStyle>{admin.first_name}</TableCellStyle>
									<TableCellStyle>{admin.last_name}</TableCellStyle>
									<Tooltip title={admin.email} placement="top">
										<TableCellStyle>{admin.email}</TableCellStyle>
									</Tooltip>
									<Tooltip title={admin.username} placement="top">
										<TableCellStyle>{admin.username}</TableCellStyle>
									</Tooltip>
									<TableCellStyle>{admin.phone_number}</TableCellStyle>
									<TableCell>
										<EdActiveStatus active={admin.active} />
									</TableCell>
									<SpeedDialCell>
										{(auth.user.type === 'super' ||
											auth.permissions.has('u-adm') ||
											auth.permissions.has('d-adm')) && (
											<EdSpeedDial ariaLabel="SpeedDial basic example">
												{(auth.user.type === 'super' ||
													auth.permissions.has('d-adm')) && (
													<EdSpeedDialAction
														key="delete"
														icon={<EdIcon>delete</EdIcon>}
														tooltipTitle="Delete"
														onClick={(e) => {
															e.stopPropagation();
															handleModelOpen(admin?.uuid);
														}}
													/>
												)}
												{(auth.user.type === 'super' ||
													auth.permissions.has('u-adm')) && (
													<EdSpeedDialAction
														key="edit"
														icon={
															admin?.active ? (
																<EdIcon>block</EdIcon>
															) : (
																<EdIcon>check</EdIcon>
															)
														}
														tooltipTitle={
															admin?.active ? 'Deactivate' : 'Activate'
														}
														onClick={(e) => onToggleActive(e, admin)}
													/>
												)}
											</EdSpeedDial>
										)}
									</SpeedDialCell>
								</MainTableRow>
							);
						});
					}
				}}
			/>
		</div>
	);
};

const TableCellStyle = styled(TableCell)`
	font-size: 1rem;
	padding: 0 1.063rem 0;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
`;

export default AdminsTable;
