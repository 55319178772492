import api from '@Services/api';
import {
	CRUDBase,
	DeleteSingleEntityPayload,
	GetAllMeta,
	GetSingleEntityPayload,
} from 'src/base/services/services.base';
import { AxiosResponse } from 'axios';
import {
	Unit,
	UnitPayload,
} from '@Pages/ClassRooms/Pages/SingleCourse/Pages/CourseForm/Pages/CourseContent/Pages/Contents/Types/Units.types';

type UnitReorderPayload = {
	courseId: number;
	units: any[];
};
interface UnitsCRUD extends CRUDBase<any, Unit> {
	reorderUnits(payload: UnitReorderPayload): Promise<AxiosResponse<any>>;
}

export class UnitsRequester implements UnitsCRUD {
	async reorderUnits(payload: UnitReorderPayload): Promise<AxiosResponse<any>> {
		return await api.post(`${this.baseURL}update`, payload);
	}
	baseURL = 'units/';

	async export(payload: GetAllMeta): Promise<AxiosResponse<string>> {
		return await api.post(`${this.baseURL}all`, { ...payload, export: true });
	}

	private static instance: UnitsRequester;

	static getInstance() {
		if (!UnitsRequester.instance) {
			UnitsRequester.instance = new UnitsRequester();
		}
		return UnitsRequester.instance;
	}

	getAll(payload?: GetAllMeta): Promise<AxiosResponse<ApiResponse<Unit[]>>>;
	getAll(payload: number): Promise<AxiosResponse<ApiResponse<Unit[]>>>;
	async getAll(payload?: any): Promise<AxiosResponse<ApiResponse<Unit[]>>> {
		return await api.post(`${this.baseURL}all`, { course_id: payload });
	}

	get(id: string): Promise<AxiosResponse<ApiResponse<Unit>>>;
	get(id: number): Promise<AxiosResponse<ApiResponse<Unit>>>;
	get(
		id: string | number,
		payload: GetSingleEntityPayload
	): Promise<AxiosResponse<ApiResponse<Unit>>>;
	get(
		id: any,
		payload?: any
	): Promise<import('axios').AxiosResponse<ApiResponse<Unit>>> {
		throw new Error('Method not implemented.');
	}

	create(payload: Omit<any, 'id'>): Promise<AxiosResponse<ApiResponse<Unit>>> {
		throw new Error('Method not implemented.');
	}

	async update(
		payload: UnitPayload
	): Promise<AxiosResponse<ApiResponse<Unit>>> {
		return await api.post(`${this.baseURL}update/${payload.id}`, payload);
	}

	async toggleActive(
		payload: UnitPayload
	): Promise<AxiosResponse<ApiResponse<Unit>>> {
		return await api.post(`${this.baseURL}update/${payload.id}`, payload);
	}

	delete(id: number): Promise<AxiosResponse<ApiResponse<any>>>;
	delete(id: string): Promise<AxiosResponse<ApiResponse<any>>>;
	delete(
		id: string | number,
		payload: DeleteSingleEntityPayload
	): Promise<AxiosResponse<ApiResponse<any>>>;
	async delete(
		id: any,
		payload?: any
	): Promise<AxiosResponse<ApiResponse<any>>> {
		return await api.delete(`${this.baseURL}delete`, {
			data: { id },
		});
	}
}
