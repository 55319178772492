import { array, boolean, number, object, string, StringSchema } from 'yup';
import { InstructorLanguage, InstructorType } from '../Types/Instructor.types';

const emailValidation =
	/^(([^<>()[\]\\.,;:\s@"]+(\.[^ <>()[\]\\., ;: \s @"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const phoneValidation = /^01[0-2,5]{1}[0-9]{8}$/;
const re =
	/^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm;

class InstructorLanguageSchema extends StringSchema<InstructorLanguage> {
	static create() {
		return new InstructorLanguageSchema();
	}
}

class InstructorTypeSchema extends StringSchema<InstructorType> {
	static create() {
		return new InstructorTypeSchema();
	}
}

const SCHEMA_PART_1 = object({
	uuid: string(),
	email: string()
		.required()
		.matches(emailValidation, 'E-mail is not valid')
		.required(),
	password: string().when('uuid', {
		is: (id: string) => {
			return id !== undefined;
		},
		then: string(),
		otherwise: string().required(),
	}),
	phone_number: string()
		.matches(phoneValidation, 'must be 01xxxxxxxx')
		.required(),
	first_name: string().required().min(2),
	last_name: string().required().min(2),
	gender: string().nullable(true),
	profile_photo: string().required(),
	language: InstructorLanguageSchema.create(),
	type: InstructorTypeSchema.create(),
	instructor: object({
		educationTypes: array(number().required()).min(1).required(),
		educationYears: array(number().required()).min(1).required(),
		educationLanguages: array(number().required()).min(1).required(),
		educationSections: array(number().required()),
		weight: number().positive().nullable(true),
		label: string().required(),
		bio: string().nullable(true),
		is_active: boolean().default(true),
		fb_link: string().matches(re, 'facebook url is not valid').nullable(true),
		youtube_link: string()
			.matches(re, 'youtube url is not valid')
			.nullable(true),
		website_link: string()
			.matches(re, 'website link url is not valid')
			.nullable(true),
	}),
});

export const FULL_SCHEMA = SCHEMA_PART_1;

export type InstructorForm = typeof SCHEMA_PART_1.__outputType;
