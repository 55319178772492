import { Section, Course } from '@Pages/ClassRooms/Types/ClassroomTabs.types';
import React, { FC, useContext, useState } from 'react';
import {
	Control,
	useController,
	useForm,
	useFieldArray,
} from 'react-hook-form';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import {
	CURRICULUM_FORM,
	SECTION_SCHEMA,
} from '@Pages/ClassRooms/Schema/ClassroomCurriculum.schema';
import DragButton from '@Components/UI/Buttons/EdDragButton/EdDragButton';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	IconButton,
	Paper,
	TextField,
} from '@mui/material';
import styled from 'styled-components';
import { AccordionTitle, CourseDroppable } from '../../Curriculum.styled';
import { yupResolver } from '@hookform/resolvers/yup';
import {
	FlexItem,
	FlexLayout,
	GridItem,
	GridLayout,
	Spacer,
} from '@Styled/utilities';
import EdIcon from '@Components/UI/Utilities/EdIcon/EdIcon';
import { grey } from '@mui/material/colors';
import EdCollapseButton from '@Components/UI/Buttons/EdCollapseButton/EdCollapseButton';
import EdAnimatedIcon from '@Components/UI/Utilities/EdAnimatedIcon/EdAnimatedIcon';
import { ClassroomSectionsRequester } from '@Pages/ClassRooms/Services/ClassroomTabs/ClassroomSections.req';
import { lighten } from 'polished';
import CourseForm from '../CourseForm/CourseForm';
import CourseEditForm from '../CourseEditForm/CourseEditForm';
import {
	CurriculumContext,
	CurriculumCourseContext,
	CurriculumSectionContext,
} from '../../Context/Curriculum.context';
import { useSnackbar } from '@Providers/useSnackbar';
import EdConfirmModal from '@Components/UI/Modals/EdConfirmModal/EdConfirmModal';
import { useTypedSelector } from '@Features/store';

type Props = {
	section: Section;
	control: Control<CURRICULUM_FORM>;
};
const SectionAccordion: FC<Props> = ({ section, control }) => {
	const [opened, setOpened] = useState(false);
	const [modalOpened, setModalOpened] = useState(false);

	const {
		dragHandlers,
		updateSection,
		index,
		isDragging,
		removeSection,
		tabIndex,
	} = useContext(CurriculumSectionContext);

	const { control: SectionControl, reset } = useForm<Section>({
		resolver: yupResolver(SECTION_SCHEMA),
		mode: 'all',
		defaultValues: {
			name: section.name,
		},
	});

	const { field, fieldState } = useController({
		control: SectionControl,
		name: 'name',
	});
	const { displaySnackbar } = useSnackbar();
	const auth = useTypedSelector((state) => state.auth);
	const hasEditPermission =
		auth.user.type === 'super' || auth.permissions.has('u-cur-cl');

	const onConfirm = async () => {
		const { data, status } =
			await ClassroomSectionsRequester.getInstance().update({
				...section,
				name: field.value,
			});
		if (status === 200) {
			displaySnackbar('success', 'Edited section successfully');
			updateSection(index, data.data as Section);
			reset({ name: data.data.name });
		}
	};

	const onDelete = () => {
		setModalOpened(true);
	};
	const onDeleteConfirm = async (open: boolean, confirm?: boolean) => {
		if (confirm) {
			if (!section.id) return;
			const { status } = await ClassroomSectionsRequester.getInstance().delete(
				section.id
			);
			if (status === 200) {
				displaySnackbar(
					'success',
					`Section ${section.name} deleted successfully`
				);
				removeSection(index);
			}
		}
		setModalOpened(open);
	};

	const { fields, append, update, remove } = useFieldArray({
		control,
		name: `tabs.${tabIndex}.sections.${index}.courses`,
		keyName: 'coursefId',
	});

	const onAddCourse = (course: Course) => {
		append(course);
	};

	return (
		<div>
			<EdConfirmModal
				text={`Are you sure to delete section ${section.name} ?`}
				open={modalOpened}
				handleClose={onDeleteConfirm}
			/>
			<StyledSectionAccordion isDragging={isDragging} expanded={opened}>
				<AccordionSummary>
					<EdCollapseButton
						collapsed={!opened}
						onClick={() => setOpened(!opened)}
					/>
					<FlexLayout alignItems="center">
						<SectionTitle>Section {section.order}</SectionTitle>
						<SectionSummaryPaper>
							<FlexLayout alignItems="center">
								{dragHandlers && <DragButton dragHandlers={dragHandlers} />}
								<Spacer mx="0.5rem" />
								<TextField
									fullWidth
									size="small"
									{...field}
									disabled={!hasEditPermission}
								/>
							</FlexLayout>
						</SectionSummaryPaper>
						<FlexItem>
							{fieldState.isDirty && (
								<IconButton onClick={onConfirm} color="warning">
									<EdAnimatedIcon animation="shake">
										check_circle_outline
									</EdAnimatedIcon>
								</IconButton>
							)}
						</FlexItem>
						{(auth.user.type === 'super' ||
							auth.permissions.has('d-cur-cl')) && (
							<IconButton onClick={onDelete}>
								<EdIcon>delete</EdIcon>
							</IconButton>
						)}
					</FlexLayout>
				</AccordionSummary>
				<AccordionDetails>
					<GridLayout gridTemplateColumns={'15% 1fr'}>
						<CourseForm
							control={control}
							section={section}
							sectionIndex={index}
							tabIndex={tabIndex}
							onAddCourse={onAddCourse}
							disabled={!hasEditPermission}
						/>

						<GridItem gridColumn={'2/3'}>
							<Droppable
								type="COURSES"
								droppableId={`TAB-${tabIndex}-SECTION-${index}`}
							>
								{(
									{ droppableProps, innerRef, placeholder },
									{ isDraggingOver }
								) => {
									return (
										<CourseDroppable
											isDraggingOver={isDraggingOver}
											{...droppableProps}
											ref={innerRef}
										>
											{placeholder}

											<div>
												{fields.map((course, cIndex) => {
													return (
														<Draggable
															draggableId={`COURSE-${course.id}`}
															key={`COURSE-${course.id}`}
															index={cIndex}
														>
															{(
																{ draggableProps, innerRef, dragHandleProps },
																{ isDragging }
															) => {
																return (
																	<div
																		style={{ border: '1px solid red' }}
																		{...draggableProps}
																		ref={innerRef}
																	>
																		<CurriculumCourseContext.Provider
																			key={`COURSE-CONTEXT-${cIndex}`}
																			value={{
																				index: cIndex,
																				tabIndex,
																				sectionIndex: index,
																				isDragging,
																				dragHandlers: dragHandleProps,
																				appendCourse: append,
																				removeCourse: remove,
																				updateCourse: update,
																			}}
																		>
																			<CourseEditForm
																				key={`COURSE-${cIndex}-FORM`}
																				control={control}
																				course={course}
																			/>
																		</CurriculumCourseContext.Provider>
																	</div>
																);
															}}
														</Draggable>
													);
												})}
											</div>
										</CourseDroppable>
									);
								}}
							</Droppable>
						</GridItem>
					</GridLayout>

					{/* {section.classroom_id && (
            <CourseAccordion
              tabIndex={tabIndex}
              section={section}
              sectionIndex={index}
              control={control}
            />
          )} */}
				</AccordionDetails>
			</StyledSectionAccordion>
		</div>
	);
};

export default SectionAccordion;

const StyledSectionAccordion = styled(Accordion)<{ isDragging?: boolean }>`
	margin: 1rem 0;
	padding: 0.5rem 0 1rem 0.406rem;
	border: 1px solid ${(props) => props.theme.palette.grey[600]};
	background: ${grey[100]};
	border: ${(props) => (props.isDragging ? 'none' : '')};
	background-color: ${(props) =>
		props.isDragging
			? lighten(0.25, props.theme.palette.primary?.main ?? '')
			: ''};
	box-shadow: none;
	.MuiAccordionSummary-root {
		padding: 0;
	}
	&.Mui-expanded:last-of-type {
		margin: 1rem 0;
	}
`;

const SectionTitle = styled(AccordionTitle)`
	margin: 0;
`;

const SectionSummaryPaper = styled(Paper)`
	padding: 1rem;
	min-width: 27rem;
	margin: 0 0.5rem;
`;
