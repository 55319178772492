// @flow
import useInvalidateRouteGroup from '@Hooks/Routes/useInvalidateRouteGroup';
import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { RechargeCodesRoute } from './Constants/routes';
import RechargeCodesTable from './Pages/RechargeCodesTable/RechargeCodesTable';
type Props = {};
const RechargeCodes = (props: Props) => {
	const { path, url } = useRouteMatch();

	useInvalidateRouteGroup({
		baseURL: '/dashboard/recharge-codes',
		mode: 'include',
		routeGroup: RechargeCodesRoute,
	});
	return (
		<div>
			<div>
				<Switch>
					<Route path={url} exact component={RechargeCodesTable} />
				</Switch>
			</div>
		</div>
	);
};

export default RechargeCodes;
