import { array, boolean, number, object, string } from 'yup';

const ModelQuestionSchema = object({
	test_question_id: number(),
	order: number(),
});
export const AddTestModelSchema = object({
	test_id: number(),
	name: string().required(),
	shuffle_questions: boolean().default(false),
	shuffle_answers: boolean().default(false),
	questions: array(number().required()).default([]).min(1).required(),
});

export const EditTestModelSchema = AddTestModelSchema.concat(
	object({
		id: number().required(),
	})
);

export type TestModelPayload = {
	id: number;
	test_id: number;
	name: string;
	shuffle_questions: boolean;
	shuffle_answers: boolean;
	questions: Array<number>;
};
