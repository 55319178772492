import { Palette, Color } from '@mui/material';
import { transparentize } from 'polished';
import styled from 'styled-components';
import {
	FlexboxProps,
	flexbox,
	TypographyProps,
	typography,
} from 'styled-system';

export const DataCard = styled.div<{ color?: keyof Palette } & FlexboxProps>`
	height: 100%;
	box-sizing: border-box;
	display: flex;
	justify-content: space-between;
	align-items: center;
	--color: ${(props) => {
		if (!props.color) return '#FFF';
		const color = props.theme.palette[props.color] as Palette['primary'] &
			Color;
		return color.main;
	}};
	background: ${(props) => {
		if (!props.color) return '#FFF';
		const color = props.theme.palette[props.color] as Palette['primary'] &
			Color;
		const transparentColor = transparentize(0.9, color.main);
		return transparentColor;
	}};
	color: var(--color);
	padding: 1rem 0.688rem;
	border-radius: 5px;
	${flexbox}
`;
export const DataCardLabel = styled.label<TypographyProps>`
	color: currentColor;
	font-weight: 600;
	font-size: 1rem;
	${typography}
`;
export const DataCardValue = styled.label<TypographyProps & FlexboxProps>`
	/* flex: 1; */
	display: flex;
	justify-content: center;
	font-weight: 600;
	font-size: 1.25rem;
	color: #000;
	${typography};
	${flexbox};
`;
