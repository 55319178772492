import { BreadcrumbContext } from '@Components/UI/Layout/Layout';
import useInvalidateRouteGroup from '@Hooks/Routes/useInvalidateRouteGroup';
import useRouteGroup from '@Hooks/Routes/useRouteGroup';
import React, { useContext, useEffect } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import NewFeeds from './NewFeeds';

const NewsFeeds = () => {
	const { url, path } = useRouteMatch();
	const routeGroup = useRouteGroup('newsfeed');
	const { isCurrent } = useInvalidateRouteGroup({
		routeGroup: routeGroup,
		mode: 'include',
		routePlacement: 2,
		baseURL: '/dashboard/newsfeed',
	});

	const { removeLink } = useContext(BreadcrumbContext);
	useEffect(() => {
		if (isCurrent) {
			removeLink && removeLink('newsfeed');
		}
	}, [isCurrent]);

	return (
		<Switch>
			{routeGroup && !Array.isArray(routeGroup) && (
				<>
					<Route exact path={url}>
						<NewFeeds />
					</Route>

					{routeGroup.routes.map((_) => {
						return (
							<Route strict path={`${path}/${_.route}`}>
								{_.component}
							</Route>
						);
					})}
				</>
			)}
		</Switch>
	);
};

export default NewsFeeds;
