import PageLoader from '@Components/PageLoader';
import { useTypedSelector } from '@Features/store';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
const LoadingContext = createContext<{
	isLoading: boolean;
	setIsLoading(loading: boolean): void;
}>({ isLoading: false, setIsLoading: () => {} });

const LoadingProvider: React.FC = ({ children }) => {
	if (process.env.NODE_ENV === 'development') {
	}
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const { isLoading: isLoadingRed, fetchCount } = useTypedSelector(
		(state) => state.Loading
	);

	const isLoadingProvided = useMemo(() => {
		console.log({ isLoading });
		return isLoading || fetchCount > 0;
	}, [isLoading, fetchCount]);

	return (
		<LoadingContext.Provider
			value={{
				setIsLoading,
				isLoading,
			}}
		>
			{isLoadingProvided && <PageLoader />}
			{/* <PageLoader /> */}
			{children}
		</LoadingContext.Provider>
	);
};

export default LoadingProvider;

export const usePageLoader = () => {
	const context = useContext(LoadingContext);
	// if (!context) throw new Error('No LoadingProvider found !!!');
	return context;
};
