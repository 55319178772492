import { BaseSliceController } from '@Base/slices/slices.base';
import { TestNotificationRequester } from '@Pages/ClassRooms/Pages/SingleCourse/Pages/CourseForm/Pages/CourseContent/Pages/Test/Services/TestNotification/TestNotificationRequester';
import { TestNotification } from '../../../../Types/Test/Test.types';

export class TestNotificationSlice extends BaseSliceController<
	TestNotification,
	'TestNotification'
> {
	public static instance: TestNotificationSlice = new TestNotificationSlice();

	constructor() {
		super('TestNotification', TestNotificationRequester.instance);
	}
}

export const { testNotificationUpdate } =
	TestNotificationSlice.instance.getActions();
