import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { InitialStateType } from '@Features/classrooms/tabsSlice.types';
import {
	SectionType,
	TabType,
	ValidationType,
} from '@Pages/ClassRooms/Tabs/Tabs.types';
import { fetchAllTabs } from '@Services/classrooms/classroomTabs.req';

const initialState: InitialStateType = {
	tabs: [],
};

export const fetchTabs = createAsyncThunk(
	'classroom-tabs/fetch-all',
	async (classroomId: number, thunkAPI) => {
		const response = await fetchAllTabs(classroomId);
		return response.data.data.map((tab) => {
			tab.initialValue = tab.name;
			tab.initialOrder = tab.order;
			tab.sections = tab.sections.map((section) => {
				section.initialValue = section.name;
				section.initialOrder = section.order;
				return section;
			});
			return tab;
		});
	}
);

export const tabsSlice = createSlice({
	name: 'classrooms-tabs',
	initialState,
	reducers: {
		appendTab: (state, action: PayloadAction<TabType>) => {
			state.tabs.push(action.payload);
		},
		appendSection: (
			state,
			{
				payload: { tabOrder, section },
			}: PayloadAction<{ tabOrder: number; section: SectionType }>
		) => {
			state.tabs[tabOrder - 1].sections.push(section);
		},
		deleteTab: (state, action: PayloadAction<number>) => {
			state.tabs = Array.from(
				state.tabs.filter((value) => value.order !== action.payload)
			);
			state.tabs.forEach((tab, index) => (tab.order = index + 1));
		},
		deleteSection: (
			state,
			{
				payload: { tabOrder, sectionOrder },
			}: PayloadAction<{ tabOrder: number; sectionOrder: number }>
		) => {
			state.tabs[tabOrder - 1].sections = Array.from(
				state.tabs[tabOrder - 1].sections.filter(
					(section) => section.order !== sectionOrder
				)
			);
			state.tabs[tabOrder - 1].sections.forEach(
				(section, index) => (section.order = index + 1)
			);
		},
		swapTabs: (
			state,
			{
				payload: { direction, order },
			}: PayloadAction<{ direction: 'up' | 'down'; order: number }>
		) => {
			if (direction === 'up' && order !== 1) {
				const tempTab = state.tabs[order - 1]; // current tab
				tempTab.order = order - 1;
				state.tabs[order - 1] = state.tabs[order - 2];
				state.tabs[order - 1].order = state.tabs[order - 1].order + 1;
				state.tabs[order - 2] = tempTab;
			} else if (direction === 'down' && order !== state.tabs.length) {
				const tempTab = state.tabs[order - 1]; // current tab
				tempTab.order = order + 1;
				state.tabs[order - 1] = state.tabs[order];
				state.tabs[order - 1].order = state.tabs[order - 1].order - 1;
				state.tabs[order] = tempTab;
			}
		},
		swapSections: (
			state,
			{
				payload: { direction, tabOrder, sectionOrder },
			}: PayloadAction<{
				direction: 'up' | 'down';
				tabOrder: number;
				sectionOrder: number;
			}>
		) => {
			const currentTab = state.tabs[tabOrder - 1];
			const sections = currentTab.sections;
			if (direction === 'up' && sectionOrder !== 1) {
				const tempSection = sections[sectionOrder - 1];
				tempSection.order = sectionOrder - 1;
				sections[sectionOrder - 1] = sections[sectionOrder - 2];
				sections[sectionOrder - 1].order = sections[sectionOrder - 1].order + 1;
				sections[sectionOrder - 2] = tempSection;
			} else if (
				direction === 'down' &&
				sectionOrder !== currentTab.sections.length
			) {
				const tempSection = sections[sectionOrder - 1];
				tempSection.order = sectionOrder + 1;
				sections[sectionOrder - 1] = sections[sectionOrder];
				sections[sectionOrder - 1].order = sections[sectionOrder - 1].order - 1;
				sections[sectionOrder] = tempSection;
			}
		},
		setTabName: (
			state,
			{
				payload: { newName, order },
			}: PayloadAction<{ newName: string; order: number }>
		) => {
			const index = order - 1;
			const currentTab = state.tabs[index];
			currentTab.name = newName;
			currentTab.touched = true;
		},
		setSectionName: (
			state,
			{
				payload: { newName, tabOrder, sectionOrder },
			}: PayloadAction<{
				newName: string;
				tabOrder: number;
				sectionOrder: number;
			}>
		) => {
			const currentTab = state.tabs[tabOrder - 1];
			const currentSection = currentTab.sections[sectionOrder - 1];
			currentSection.name = newName;
			currentSection.touched = true;
		},
		setTabValidation: (
			state,
			{
				payload: { newValidation, order },
			}: PayloadAction<{ newValidation: ValidationType; order: number }>
		) => {
			state.tabs.forEach((tab, index) => {
				if (tab.order === order) state.tabs[index].validation = newValidation;
			});
		},
		setSectionValidation: (
			state,
			{
				payload: { newValidation, tabOrder, sectionOrder },
			}: PayloadAction<{
				newValidation: ValidationType;
				tabOrder: number;
				sectionOrder: number;
			}>
		) => {
			const tab = state.tabs[tabOrder - 1];
			const section = tab.sections[sectionOrder - 1];
			section.validation = newValidation;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(fetchTabs.fulfilled, (state, action) => {
			state.tabs = action.payload;
		});
	},
});

export const {
	appendTab,
	deleteTab,
	swapTabs,
	setTabName,
	setTabValidation,
	appendSection,
	deleteSection,
	setSectionName,
	swapSections,
	setSectionValidation,
} = tabsSlice.actions;

export default tabsSlice.reducer;
