import { RouteGroup } from '@Constants/routes';
import RechargeCodes from '../RechargeCodes';

export const RechargeCodesRoute: RouteGroup = {
	isRouted: true,
	isParent: true,
	title: 'Recharge Codes',
	icon: 'credit_card',
	route: 'recharge-codes',
	code: 'v-rchc',
	component: <RechargeCodes />,
	routes: [],
};
