import { onPageChangeRequestMeta } from '@Components/MainTable/MainTable.types';
import EdTableAppBar, {
	QueryStringAppBar,
} from '@Components/UI/AppBars/EdTableAppBar/EdTableAppBar';
import EdButton from '@Components/UI/Buttons/EdButton/EdButton';
import EdIcon from '@Components/UI/Utilities/EdIcon/EdIcon';
import { useTypedSelector } from '@Features/store';
import { useQueryString } from '@Hooks/useQueryString/useQueryString';
import {
	Button,
	Divider,
	IconButton,
	Tooltip,
	Typography,
} from '@mui/material';
import { useSnackbar } from '@Providers/useSnackbar';
import { FlexLayout, Spacer, Text } from '@Styled/utilities';
import { booleanString } from '@Utils/BooleanString';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
	DragDropContext,
	Draggable,
	Droppable,
	DropResult,
	ResponderProvided,
} from 'react-beautiful-dnd';
import { useDispatch } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import { TestQuestionsRequester } from '../Services/TestQuestions/TestQuestionsRequester';
import {
	testQuestionsAssignFetchAll,
	testQuestionsGetAll,
} from '../Slice/TestQuestions/TestQuestionsSlice';
import QuestionAccordion from './QuestionAccordion/QuestionAccordion';

const TestQuestionsTable: FC = () => {
	const { fetchAll, filters, meta } = useTypedSelector(
		(state) => state.TestQuestions
	);
	const { path } = useRouteMatch();
	const { edit, remove, params } = useQueryString<QueryStringAppBar>();
	const { currentEntity } = useTypedSelector((state) => state.Tests);
	const dispatch = useDispatch();
	const history = useHistory();
	useEffect(() => {
		remove('perPage');
	}, []);

	const [resetReorder, setResetReorder] = useState<boolean>(false);

	const onPageRequestUpdate = useCallback(
		(args: onPageChangeRequestMeta) => {
			dispatch(
				testQuestionsGetAll({
					page: args.activePage ?? 1,
					perPage: args.perPage ?? 10,
					filters: [...args.filters, { test_id: currentEntity?.id }],
					query: args.query,
					sortBy: { field: 'order', direction: 'asc' },
				})
			);
		},
		[resetReorder]
	);

	const [needReorder, setNeedReorder] = useState(false);
	const handleOnDragEnd = (result: DropResult, provided: ResponderProvided) => {
		if (result.destination) {
			if (currentEntity.active) return;
			const list = Array.from(fetchAll);
			list.splice(
				result.destination.index,
				0,
				list.splice(result.source.index, 1)[0]
			);
			setNeedReorder(true);
			dispatch(testQuestionsAssignFetchAll(list));
		}
	};

	const { displaySnackbar } = useSnackbar();
	const handleSaveOrder = async () => {
		try {
			const {} = await TestQuestionsRequester.instance.reorder({
				questions: fetchAll.map((_, index) => ({
					test_question_id: _.id,
					order: index + 1,
				})),
			});
			displaySnackbar('success', 'Reordered Questions successfully');
			setNeedReorder(false);
		} catch (error) {
			displaySnackbar('error', "Couldn't reorder questions");
		}
	};

	const isExpanded = useMemo(() => {
		return booleanString(params.expand);
	}, [params.expand]);

	return (
		<>
			<EdTableAppBar
				position="sticky"
				top="0"
				zIndex="99"
				title={meta?.total === 0 ? 'No Questions' : `${meta?.total} Questions`}
				renderExtraActions={() => {
					return (
						<>
							<EdButton
								onClick={() => history.push(`${path}/tags`)}
								startIcon={<EdIcon>settings</EdIcon>}
								color="inherit"
							>
								Manage Tags
							</EdButton>
							<StyledDivider orientation="vertical" variant="middle" />
							{meta?.test_overall_score && meta.test_overall_score > 0 ? (
								<>
									<div>
										Total Score:{' '}
										<Text fontWeight={'600'}>{meta.test_overall_score}</Text>
									</div>
								</>
							) : (
								<>No Score</>
							)}
							<Spacer mx="0.75rem" />
							<Tooltip title="Expand All" placement="top" followCursor>
								<div>
									<StyledIconButton
										$expanded={isExpanded}
										onClick={() => {
											edit({ expand: String(!isExpanded) });
										}}
									>
										<EdIcon>keyboard_double_arrow_down</EdIcon>
									</StyledIconButton>
								</div>
							</Tooltip>
						</>
					);
				}}
				filters={filters}
				onPageRequest={onPageRequestUpdate}
			/>
			<Spacer mb="2.813rem" />
			<Spacer px="2.625rem">
				<DragDropContext onDragEnd={handleOnDragEnd}>
					<Droppable droppableId="QUESTIONS">
						{({ droppableProps, innerRef, placeholder }) => {
							return (
								<FlexLayout
									flexDirection="column"
									{...droppableProps}
									ref={innerRef}
								>
									{placeholder}

									{fetchAll.map((qus, index) => {
										return (
											<Draggable
												draggableId={`question-${index}`}
												key={`question-${index}`}
												index={index}
											>
												{({ draggableProps, innerRef, dragHandleProps }) => {
													return (
														<FlexLayout
															width="100%"
															ref={innerRef}
															{...draggableProps}
														>
															<QuestionAccordion
																isExpanded={isExpanded}
																type={qus.type}
																index={index}
																question={qus}
																dragHandlers={dragHandleProps}
															/>
														</FlexLayout>
													);
												}}
											</Draggable>
										);
									})}
								</FlexLayout>
							);
						}}
					</Droppable>
				</DragDropContext>
				{meta?.total !== undefined && meta.total > fetchAll.length && (
					<Button
						onClick={() => {
							if (!meta?.per_page) return;
							edit({
								perPage: (meta.per_page + 10).toString(),
							});
						}}
						fullWidth
					>
						Load More
					</Button>
				)}
				{needReorder && (
					<FlexLayout justifyContent={'flex-end'}>
						<Button
							onClick={() => setResetReorder(!resetReorder)}
							variant="contained"
							color="warning"
						>
							Cancel
						</Button>
						<Spacer mx="0.5rem" />
						<EdButton
							onClick={handleSaveOrder}
							variant="contained"
							edcolor="dodgerBlue"
						>
							Save
						</EdButton>
					</FlexLayout>
				)}
			</Spacer>
		</>
	);
};

export default TestQuestionsTable;

const StyledDivider = styled(Divider)`
	height: 2.5rem;
	margin-right: 0.75rem;
	margin-left: 0.75rem;
`;

const StyledIconButton = styled(IconButton)<{ $expanded: boolean }>`
	transform: ${(props) => (props.$expanded ? 'rotate(180deg)' : '')};
	transition: all ease-in-out 200ms;
`;
