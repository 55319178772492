import { useTypedSelector } from '@Features/store';
import { ResponsivePie } from '@nivo/pie';

import React, { useMemo } from 'react';
import {
	ReportPieChartData,
	PieChartNode,
} from '../../Slices/ReportOverview.slice';
const OverviewPieChart = () => {
	const { report } = useTypedSelector((state) => state.CourseReports);
	const pieChartData = useMemo<PieChartNode[] | undefined>(() => {
		if (!report) return;
		const data: ReportPieChartData = {
			completed_percentage: {
				id: 'completed_percentage',
				label: 'Completed',
				value: report.completed_percentage ?? 0,
				color: 'rgba(39, 221, 130, 0.5)',
			},
			in_progress_percentage: {
				id: 'in_progress_percentage',
				label: 'In Progress',
				value: report.in_progress_percentage ?? 0,
				color: 'rgba(107, 99, 255, 0.5)',
			},
			not_started_percentage: {
				id: 'not_started_percentage',
				label: 'Not Started',
				value: report.not_started_percentage ?? 0,
				color: 'rgba(255, 132, 50, 0.5)',
			},
		};
		return Object.values(data);
	}, [report]);

	const hasData = useMemo(() => {
		return pieChartData?.reduce((p, c) => {
			return c.value + p;
		}, 0);
	}, [pieChartData]);
	return (
		<div
			style={{
				height: '40vh',
				width: '13vw',
			}}
		>
			{!!(pieChartData && hasData && hasData > 0) && (
				<ResponsivePie
					data={pieChartData}
					innerRadius={0.5}
					padAngle={0.7}
					margin={{ left: 20, right: 20 }}
					activeOuterRadiusOffset={8}
					colors={{ datum: 'data.color' }}
					enableArcLinkLabels={false}
					isInteractive
					fit
					valueFormat={(value) => (value === 0 ? '' : `${value.toFixed(1)}%`)}
					animate
					legends={[
						{
							anchor: 'bottom',
							direction: 'column',
							justify: false,
							translateX: 0,
							itemsSpacing: 5,
							itemWidth: 100,
							itemHeight: 18,
							itemTextColor: '#000',
							itemDirection: 'left-to-right',
							itemOpacity: 1,
							symbolSize: 18,
							symbolShape: 'circle',
						},
					]}
				/>
			)}
		</div>
	);
};

export default OverviewPieChart;
