import { BaseSliceController } from '@Base/slices/slices.base';
import { SequenceManagementRequester } from '../Services/Sequence.requester';
import { SequenceManagement } from '../types/sequence.types';

export class SequenceManagementSlice extends BaseSliceController<
	SequenceManagement,
	'SequenceManagement',
	SequenceManagement
> {
	static instance = new SequenceManagementSlice();

	constructor() {
		super('SequenceManagement', SequenceManagementRequester.getInstance());
	}
}

export const { sequenceManagementGetAll } =
	SequenceManagementSlice.instance.getActions();
