import React, { useMemo } from 'react';
import EdAnimatedIcon from '@Components/UI/Utilities/EdAnimatedIcon/EdAnimatedIcon';
import PodcastsIcon from '@mui/icons-material/Podcasts';
import { FlexLayout, Spacer } from '@Styled/utilities';
import EdFormControl from '@Components/UI/Inputs/EdFormControl/EdFormControl';
import { IconButton, InputAdornment } from '@mui/material';
import EdIcon from '@Components/UI/Utilities/EdIcon/EdIcon';
import { useAppThunkDispatch, useTypedSelector } from '@Features/store';
import { WebinarPayload } from '../Types/Webinar.types';
import { useController, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { cloneDeep } from 'lodash';
import { object, string } from 'yup';
import { webinarsUpdate } from '../Slices/Webinar.slice';
import { useSnackbar } from '@Providers/useSnackbar';

const SCHEMA = object({
	name: string(),
});

const WebinarUnit = () => {
	const { currentEntity } = useTypedSelector((state) => state.Webinars);
	const { displaySnackbar } = useSnackbar();
	const dispatch = useAppThunkDispatch();

	const defaultValues = useMemo(() => {
		return {
			webinar_id: currentEntity?.id,
			unit: currentEntity?.unit,
			webinar: currentEntity,
		} as WebinarPayload;
	}, []);

	const { control, handleSubmit } = useForm<WebinarPayload>({
		resolver: yupResolver(SCHEMA),
		defaultValues: cloneDeep(defaultValues),
	});
	const { fieldState } = useController({
		control,
		name: 'unit.name',
	});

	const onSave = async (form: WebinarPayload) => {
		try {
			await dispatch(webinarsUpdate(form)).unwrap();
			displaySnackbar('success', 'Updated Unit name successfully');
		} catch (error) {
			displaySnackbar('error', "Couldn't update unit name");
		}
	};

	return (
		<FlexLayout alignItems="center">
			<EdFormControl
				size="small"
				InputProps={{
					startAdornment: (
						<InputAdornment position="start">
							<PodcastsIcon />
						</InputAdornment>
					),
				}}
				control={control}
				label="Unit Name"
				name="unit.name"
			/>
			{fieldState.isDirty && (
				<>
					<Spacer mx="0.5rem" />
					<IconButton onClick={handleSubmit(onSave)} type="submit">
						<EdAnimatedIcon animation="shake">
							<EdIcon>check_circle_outline</EdIcon>
						</EdAnimatedIcon>
					</IconButton>
				</>
			)}
		</FlexLayout>
	);
};

export default WebinarUnit;
