import { IconButton, Tooltip } from '@mui/material';
import React from 'react';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';

type Props = {
	show?: boolean;
	handleClick: () => void;
};
const TableFiltersButton: React.FC<Props> = ({ show, handleClick }) => {
	return (
		<React.Fragment>
			<Tooltip title="Toggle Filter">
				<IconButton
					sx={{
						backgroundColor: 'rgb(90, 192, 252,0.25) ',
						color: show ? '#5ac0fc' : '#6c63ff',
					}}
					aria-label="date filter"
					id="filters"
					onClick={() => handleClick()}
				>
					<FilterAltOutlinedIcon />
				</IconButton>
			</Tooltip>
		</React.Fragment>
	);
};
export default TableFiltersButton;
