import React, { FC, useContext, useState } from 'react';
import {
	Section,
	Tab,
	TabPayload,
} from '@Pages/ClassRooms/Types/ClassroomTabs.types';
import {
	Draggable,
	DraggableProvidedDragHandleProps,
	Droppable,
} from 'react-beautiful-dnd';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	IconButton,
	TextField,
} from '@mui/material';
import EdIcon from '@Components/UI/Utilities/EdIcon/EdIcon';
import { FlexItem, FlexLayout, Spacer } from '@Styled/utilities';
import { grey } from '@mui/material/colors';
import {
	useController,
	useFieldArray,
	useForm,
	useFormContext,
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { TAB_SCHEMA } from '@Pages/ClassRooms/Schema/ClassroomCurriculum.schema';
import EdFab from '@Components/UI/Utilities/EdFab/EdFab';
import { FabButtonLabel, SectionDroppable } from '../../Curriculum.styled';
import { CURRICULUM_FORM } from '../../../../../../Schema/ClassroomCurriculum.schema';
import { ClassroomTabsRequester } from '@Pages/ClassRooms/Services/ClassroomTabs/ClassroomTabs.req';
import EdAnimatedIcon from '@Components/UI/Utilities/EdAnimatedIcon/EdAnimatedIcon';
import {
	CurriculumContext,
	CurriculumSectionContext,
} from '../../Context/Curriculum.context';
import { ClassroomSectionsRequester } from '@Pages/ClassRooms/Services/ClassroomTabs/ClassroomSections.req';
import SectionAccordion from '../SectionAccordion/SectionAccordion';
import DragButton from '@Components/UI/Buttons/EdDragButton/EdDragButton';
import EdCollapseButton from '@Components/UI/Buttons/EdCollapseButton/EdCollapseButton';
import { lighten } from 'polished';
import { DevTool } from '@hookform/devtools';
import { useTypedSelector } from '@Features/store';
import styled from 'styled-components';

import EdConfirmModal from '@Components/UI/Modals/EdConfirmModal/EdConfirmModal';
import { useSnackbar } from '@Providers/useSnackbar';
type Props = {
	tab: Tab;
	dragHandlers?: DraggableProvidedDragHandleProps | null;
	index: number;
	isDragging?: boolean;
};
const TabAccordion: FC<Props> = ({ tab, dragHandlers, index, isDragging }) => {
	const { control } = useFormContext<CURRICULUM_FORM>();
	const [opened, setOpened] = useState(false);
	const { updateTab, deleteTab } = useContext(CurriculumContext);
	const { control: TabControl, reset } = useForm<TabPayload>({
		resolver: yupResolver(TAB_SCHEMA),
		defaultValues: { ...tab },
		mode: 'all',
	});
	const auth = useTypedSelector((state) => state.auth);
	const hasEditPermission =
		auth.user.type === 'super' || auth.permissions.has('u-cur-cl');
	const { field, fieldState } = useController({
		control: TabControl,
		name: `name`,
	});
	const { fields, append, update, remove } = useFieldArray({
		control,
		name: `tabs.${index}.sections`,
		keyName: 'sectionfId',
	});
	const [modalOpened, setModalOpened] = useState(false);
	const { displaySnackbar } = useSnackbar();

	const onConfirm = async () => {
		const {
			data: { data },
			status,
		} = await ClassroomTabsRequester.getInstance().update({
			...tab,
			id: tab.id,
			name: field.value,
		});
		if (status === 200) {
			updateTab && updateTab(index, { ...data, fdId: tab.fdId });
			reset({ ...data }, { keepDirty: false });
			displaySnackbar('success', 'Edited Tab Successfully');
		}
	};

	const onDelete = async () => {
		setModalOpened(true);
	};
	const onConfirmDelete = async (close: boolean, confirm?: boolean) => {
		if (confirm) {
			if (!tab.id) return;
			const { status } = await ClassroomTabsRequester.getInstance().delete(
				tab.id
			);
			if (status === 200) {
				displaySnackbar('success', `${tab.name} deleted successfully`);
				deleteTab && deleteTab(index);
			}
		}
		setModalOpened(close);
	};

	const onAddSection = async () => {
		const {
			data: { data },
			status,
		} = await ClassroomSectionsRequester.getInstance().create({
			classroom_id: tab.classroom_id,
			classroom_tab_id: tab.id ?? 0,
			name: `Section ${fields.length + 1}`,
			order: fields.length + 1,
			courses: [],
		});
		if (status === 200) {
			displaySnackbar('success', `${data.name} created successfully`);
			append(data as Section);
		}
	};

	const onRemove = (index: number) => {
		remove(index);
	};

	return (
		<div>
			<EdConfirmModal
				handleClose={onConfirmDelete}
				text={`Are you sure to delete ${tab.name}?`}
				open={modalOpened}
			/>
			<StyledAccordion isDragging={isDragging} expanded={opened}>
				<AccordionSummary disableRipple={true} disableTouchRipple={true}>
					<FlexLayout width="100%">
						<FlexItem flex="1">
							<EdCollapseButton
								collapsed={!opened}
								onClick={() => setOpened(!opened)}
							/>
							<FlexLayout alignItems="center">
								{dragHandlers && <DragButton dragHandlers={dragHandlers} />}
								<TabTitle>Tab {tab.order}</TabTitle>
								<TextField
									{...field}
									error={fieldState.invalid}
									size="small"
									disabled={!hasEditPermission}
								/>

								<Spacer mx="1rem" />
							</FlexLayout>
						</FlexItem>
						<FlexItem>
							<IconButton onClick={onDelete}>
								<EdIcon>delete</EdIcon>
							</IconButton>
							{fieldState.isDirty && hasEditPermission && (
								<IconButton onClick={onConfirm} color="warning">
									<EdAnimatedIcon animation="shake">
										<EdIcon>check_circle_outline</EdIcon>
									</EdAnimatedIcon>
								</IconButton>
							)}
						</FlexItem>
					</FlexLayout>
				</AccordionSummary>
				<AccordionDetails>
					{(auth.user.type === 'super' || auth.permissions.has('c-cur-cl')) && (
						<FlexLayout alignItems="center">
							<EdFab onClick={onAddSection} size="small" color="primary">
								<EdIcon fontSize="medium">add</EdIcon>
							</EdFab>
							<Spacer mx={'0.5rem'} />
							<FabButtonLabel>Add Section</FabButtonLabel>
						</FlexLayout>
					)}
					<Droppable
						type="SECTIONS"
						key={`TAB-${index}-DROP-AREA`}
						droppableId={`TAB-${index}`}
					>
						{(
							{ droppableProps, innerRef, placeholder },
							{ isDraggingOver }
						) => {
							return (
								<div {...droppableProps} ref={innerRef}>
									{placeholder}

									<SectionDroppable isDraggingOver={isDraggingOver}>
										{fields.map((section, sIndex) => {
											return (
												<Draggable
													draggableId={`SECTION-${
														section.id ?? section.sectionfId
													}`}
													index={sIndex}
													// key={`SECTION-${section.id ?? section.sectionfId}`}
													key={`${section.sectionfId}`}
												>
													{(
														{ draggableProps, innerRef, dragHandleProps },
														{ isDragging }
													) => {
														return (
															<div ref={innerRef} {...draggableProps}>
																<CurriculumSectionContext.Provider
																	key={`SECTION-CONTEXT-${sIndex}`}
																	value={{
																		tabIndex: index,
																		appendSection: () => {},
																		updateSection: update,
																		removeSection: onRemove,
																		dragHandlers: dragHandleProps,
																		isDragging,
																		index: sIndex,
																	}}
																>
																	<SectionAccordion
																		control={control}
																		section={section}
																	/>
																</CurriculumSectionContext.Provider>
															</div>
														);
													}}
												</Draggable>
											);
										})}
									</SectionDroppable>
								</div>
							);
						}}
					</Droppable>
				</AccordionDetails>
			</StyledAccordion>
		</div>
	);
};

export default TabAccordion;

const StyledAccordion = styled(Accordion)<{ isDragging?: boolean }>`
	margin: 2rem 0;
	background-color: ${grey['100']};

	background-color: ${(props) =>
		props.isDragging
			? lighten(0.25, props.theme.palette.purple?.main ?? '')
			: ''};

	.MuiAccordionSummary-root.Mui-focusVisible {
		background-color: ${grey['100']};
	}

	&.MuiAccordionDetails-root:first-of-type {
		padding: 2.5rem 1.75rem 3.75rem 3.563rem;
	}

	&.Mui-expanded:last-of-type {
		margin: 2rem 0;
	}
`;

const TabTitle = styled.label`
	font-size: 0.875rem;
	margin: 0 3.75rem 0 1rem;
`;
