import {
	SpeedDial,
	SpeedDialAction,
	SpeedDialActionProps,
	SpeedDialProps,
	TableCell,
} from '@mui/material';
import React, { FC } from 'react';
import styled from 'styled-components';
import EdIcon from '../EdIcon/EdIcon';
import { lighten, rgba } from 'polished';

type Props = {
	children:
		| React.ReactElement<typeof EdSpeedDialAction>
		| Array<React.ReactElement<typeof EdSpeedDialAction>>
		| Array<any>;
} & SpeedDialProps;
const EdSpeedDial: FC<Props> = ({
	children,
	direction = 'left',
	FabProps = {
		disableRipple: true,
		disableTouchRipple: true,
		focusRipple: false,
		disableFocusRipple: true,
	},
	icon = <EdIcon>more_vert</EdIcon>,
	...props
}) => {
	return (
		<StyledSpeedDial
			icon={icon}
			FabProps={FabProps}
			direction={direction}
			{...props}
		>
			{children}
		</StyledSpeedDial>
	);
};

type ActionProps = { $disabled?: boolean } & SpeedDialActionProps;
export const EdSpeedDialAction: FC<ActionProps> = ({ $disabled, ...props }) => {
	return <StyledSpedDialAction $disabled={$disabled} {...props} />;
};

export default EdSpeedDial;

const StyledSpedDialAction = styled(SpeedDialAction)<ActionProps>`
	pointer-events: ${(props) => (props.$disabled ? 'none ' : '')};
	:hover {
		background-color: ${(props) =>
			// rgba(props.theme.palette.primary.main, 0.25)};
			lighten(0.25,props.theme.palette.primary.main)};
	}
`;
const StyledSpeedDial = styled(SpeedDial).attrs<SpeedDialProps>({
	direction: 'left',
})`
	.MuiSpeedDial-fab {
		border-radius: 0;
		box-shadow: 0 0 0 0;
		background-color: transparent;
		color: #000;
	}
`;

export const SpeedDialCell = styled(TableCell)`
	overflow: unset;
	width: fit-content;
	max-width: unset;
`;
