import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components';
import MainTable from '@Components/MainTable';
import TableCell from '@mui/material/TableCell';
import NoResults from '@Components/NoResults/NoResults';
import { getIPs } from '@Features/IPs/ipsSlice';
import { useTypedSelector } from '@Features/store';
import { useDispatch } from 'react-redux';
import { TableHeader } from '@Features/generics/generics.type';
import { MainTableRow } from '@Components/MainTable/MainTable';
import { onPageChangeRequestMeta } from '@Components/MainTable/MainTable.types';
import { FetchAllInvoices } from '@Features/invoices/invoicesSlice';
import DateTimeFormatter from '@Components/DateTimeFormatter/DateTimeFormatter';
import EdEmptyValue from '@Components/UI/Utilities/EdEmptyValue/EdEmptyValue';

const tableHeaders: Array<TableHeader> = [
	{ displayName: 'IP Address', fieldName: 'ip_address' },
	{ displayName: 'Username', fieldName: 'username' },
	{ displayName: 'Email', fieldName: 'email' },
	{ displayName: 'First name', fieldName: 'first_name' },
	{ displayName: 'Last name', fieldName: 'last_name' },
	{ displayName: 'Phone', fieldName: 'phone_number' },
	{ displayName: 'Created at', fieldName: 'created_at' },
];

const IPsTable: React.FC = () => {
	const dispatch = useDispatch();
	const {
		IPs = [],
		lastPage,
		filters,
		loaders,
		total,
		perPage,
		page,
	} = useTypedSelector((state) => state.IPs);

	useEffect(() => {
		dispatch(
			getIPs({
				page: 1,
				perPage: 10,
				filters: [],
				sortBy: {},
				query: '',
			})
		);
	}, [dispatch]);

	const onPageChangeRequest = useCallback(
		({
			activePage,
			filters,
			sortBy,
			query,
			startDate,
			endDate,
			perPage,
		}: onPageChangeRequestMeta) => {
			dispatch(
				getIPs({
					page: activePage,
					filters,
					sortBy,
					query,
					from: startDate,
					to: endDate,
					perPage: perPage ?? 10,
				})
			);
		},
		[dispatch]
	);
	return (
		<StyledContainer>
			<MainTable
				tableFilters={filters}
				onPageChangeRequest={onPageChangeRequest}
				totalPages={lastPage}
				total={total}
				RowsperPage={perPage}
				pageNum={page}
				tableHeads={tableHeaders}
				renderItems={() => {
					if (IPs.length === 0)
						return (
							<MainTableRow>
								<TableCell colSpan={tableHeaders.length}>
									<NoResults />
								</TableCell>
							</MainTableRow>
						);
					return IPs.map((ip) => (
						<MainTableRow key={ip?.id} hover>
							<TableCell>{ip?.ip_address || '-'}</TableCell>
							<TableCell>{ip?.username || '-'}</TableCell>
							<TableCell>
								<EdEmptyValue value={ip.email} />
							</TableCell>
							<TableCell>{ip?.first_name || '-'}</TableCell>
							<TableCell>{ip?.last_name || '-'}</TableCell>
							<TableCell>{ip?.phone_number || '-'}</TableCell>
							<TableCell>
								<DateTimeFormatter date={ip?.created_at} />
							</TableCell>
						</MainTableRow>
					));
				}}
			/>
		</StyledContainer>
	);
};

const StyledContainer = styled.div`
	margin-top: 50px;
`;
export default IPsTable;
