import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
	AdminState,
	AdminPayload,
	DeleteAdminPayload,
	CreateAdminPayload,
	UpdateAdminPayload,
	SingleAdminPayload,
} from './adminSlice.types';
import {
	AdminsResponse,
	DeleteResponse,
	CreateResponse,
	UpdateResponse,
	UpdateAdminPermissionsPayload,
	UpdateAdminPermissionsResponse,
	SingleAdminResponse,
} from '@Services/admins/admins.res.types';
import {
	adminsGetRequest,
	createAdminRequest,
	updateAdminRequest,
	deleteAdminRequest,
	updateAdminPermissionsRequest,
	adminGetRequest,
} from '@Services/admins/admins.req';

export const getAdmins = createAsyncThunk<
	AdminsResponse,
	AdminPayload,
	{ rejectValue: string }
>(
	'admins/getAdmins',
	async ({ page, perPage, filters, sortBy, query, from, to }, thunkApi) => {
		try {
			const { data } = await adminsGetRequest(
				page,
				perPage,
				filters,
				sortBy,
				query,
				from,
				to
			);
			return data;
		} catch (err: any) {
			const msg = err.response?.data.message || 'failed';
			return thunkApi.rejectWithValue(msg);
		}
	}
);
export const getAdmin = createAsyncThunk<
	SingleAdminResponse,
	SingleAdminPayload,
	{ rejectValue: string }
>('admins/getAdmin', async ({ uuid }, thunkApi) => {
	try {
		const { data } = await adminGetRequest(uuid);
		return data;
	} catch (err: any) {
		const msg = err.response?.data.message || 'failed';
		return thunkApi.rejectWithValue(msg);
	}
});

export const createAdmin = createAsyncThunk<
	CreateResponse,
	CreateAdminPayload,
	{ rejectValue: string }
>(
	'admins/createAdmin',
	async (
		{ first_name, last_name, type, email, phone_number, password, permissions },
		thunkApi
	) => {
		try {
			const { data } = await createAdminRequest(
				first_name,
				last_name,
				type,
				email,
				phone_number,
				password,
				permissions
			);
			return data;
		} catch (err: any) {
			const msg = err.response?.data.message || 'failed';
			return thunkApi.rejectWithValue(msg);
		}
	}
);
export const deleteAdmin = createAsyncThunk<
	DeleteResponse,
	DeleteAdminPayload,
	{ rejectValue: string }
>('admins/deleteAdmin', async ({ uuid }, thunkApi) => {
	try {
		const { data } = await deleteAdminRequest(uuid);
		return { ...data, uuid };
	} catch (err: any) {
		const msg = err.response?.data.message || 'failed';
		return thunkApi.rejectWithValue(msg);
	}
	/*
 return await createThunkBody(
    deleteAdminRequest(uuid),
    thunkApi,
    "Error Delete Admin"
  );

  */
});

export const updateAdminPermissions = createAsyncThunk<
	UpdateAdminPermissionsResponse,
	UpdateAdminPermissionsPayload,
	{ rejectValue: string }
>('admins/updateAdminPermissions', async ({ uuid, permissions }, thunkApi) => {
	try {
		const { data } = await updateAdminPermissionsRequest(uuid, permissions);
		return data;
	} catch (err: any) {
		const msg = err.response?.data.message || 'failed';
		return thunkApi.rejectWithValue(msg);
	}
});

export const updateAdmin = createAsyncThunk<
	UpdateResponse,
	UpdateAdminPayload,
	{ rejectValue: string }
>(
	'admins/updateAdmin',
	async (
		{ uuid, first_name, last_name, email, phone_number, password, gender },
		thunkApi
	) => {
		try {
			const { data } = await updateAdminRequest(
				uuid,
				first_name,
				last_name,
				email,
				phone_number,
				gender,
				password
			);
			return data;
		} catch (err: any) {
			const msg = err.response?.data.message || 'failed';
			return thunkApi.rejectWithValue(msg);
		}
	}
);

const initialState: AdminState = {
	admins: [],
	lastPage: 0,
	currentAdmin: null,
	total: 0,
	adminsFilter: [],
	loaders: {
		fetchAll: null,
		updatePermissions: null,
		addAdmin: null,
		updateAdmin: null,
		getAdmin: null,
		deleteAdmin: null,
	},
	errors: {
		fetchAll: '',
		updatePermissions: '',
		addAdmin: '',
		updateAdmin: '',
		getAdmin: '',
		deleteAdmin: '',
	},
};

const AdminSlice = createSlice({
	name: 'admins',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(getAdmins.pending, (state) => {
				state.loaders.fetchAll = true;
				state.errors.fetchAll = '';
			})
			.addCase(
				getAdmins.fulfilled,
				(state, { payload: { data, filters, message, meta } }) => {
					state.admins = data;
					state.loaders.fetchAll = false;
					state.lastPage = meta.last_page;
					state.total = meta.total;
					state.adminsFilter = filters || [];
				}
			)

			/*
    .addCase(getAdmins.fulfilled, (state, { payload: { data, filters, message, meta } }) => {
        state.admins = data;
        state.loaders.fetchAll = false;
        state.lastPage =meta.last_page;
        state.total = meta.total;
        state.adminsFilter = filters || [];
      })
*/

			.addCase(getAdmins.rejected, (state, action) => {
				state.errors.fetchAll = action.payload;
				state.loaders.fetchAll = undefined;
			});
		builder
			.addCase(updateAdminPermissions.pending, (state) => {
				state.loaders.updatePermissions = true;
				state.errors.updatePermissions = '';
			})
			.addCase(updateAdminPermissions.fulfilled, (state, { payload }) => {
				const index = state.admins.findIndex(
					(a) => payload.data.uuid == a.uuid
				);

				state.admins[index] = payload?.data;
				state.loaders.updatePermissions = false;
			})
			.addCase(updateAdminPermissions.rejected, (state, action) => {
				state.errors.updatePermissions = action.payload;
				state.loaders.updatePermissions = undefined;
			});
		builder
			.addCase(createAdmin.pending, (state) => {
				state.loaders.addAdmin = true;
				state.errors.addAdmin = '';
			})
			.addCase(createAdmin.fulfilled, (state, { payload }) => {
				state.loaders.addAdmin = false;
			})
			.addCase(createAdmin.rejected, (state, action) => {
				state.errors.addAdmin = action.payload;
				state.loaders.addAdmin = undefined;
			});
		builder
			.addCase(updateAdmin.pending, (state) => {
				state.loaders.updateAdmin = true;
				state.errors.updateAdmin = '';
			})
			.addCase(updateAdmin.fulfilled, (state, { payload }) => {
				state.loaders.updateAdmin = false;
			})
			.addCase(updateAdmin.rejected, (state, action) => {
				state.errors.updateAdmin = action.payload;
				state.loaders.updateAdmin = undefined;
			});
		builder
			.addCase(getAdmin.pending, (state) => {
				state.loaders.getAdmin = true;
				state.errors.getAdmin = '';
			})
			.addCase(getAdmin.fulfilled, (state, { payload }) => {
				state.currentAdmin = payload?.data;
				state.loaders.getAdmin = false;
			})
			.addCase(getAdmin.rejected, (state, action) => {
				state.errors.getAdmin = action.payload;
				state.loaders.getAdmin = undefined;
			});

		builder
			.addCase(deleteAdmin.pending, (state) => {
				state.loaders.deleteAdmin = true;
				state.errors.deleteAdmin = '';
			})
			.addCase(deleteAdmin.fulfilled, (state, { payload }) => {
				//state.currentAdmin = payload?.data;
				state.admins = state.admins.filter(
					(admin) => admin.uuid !== payload?.uuid
				);
				state.loaders.deleteAdmin = false;
			})
			.addCase(deleteAdmin.rejected, (state, action) => {
				state.errors.deleteAdmin = action.payload;
				state.loaders.deleteAdmin = undefined;
			});
	},
});

export default AdminSlice.reducer;
// export const {getAdmins} = AdminSlice.actions
