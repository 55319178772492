import { BaseSliceController } from '@Base/slices/slices.base';
import { ClassRoom } from '@Services/students/students.res.types';
import { ClassRoomTabRequester } from '../Services/ClassRoomsTab.Requester';

export class ClassRoomTabSlice extends BaseSliceController<
	ClassRoom,
	'ClassRoomTab',
	ClassRoom
> {
	private static instance: ClassRoomTabSlice;
	static getInstance() {
		if (!ClassRoomTabSlice.instance) {
			ClassRoomTabSlice.instance = new ClassRoomTabSlice();
		}
		return ClassRoomTabSlice.instance;
	}
	constructor() {
		super('ClassRoomTab', ClassRoomTabRequester.getInstance());
	}
}

export const { classRoomTabGetAll } =
	ClassRoomTabSlice.getInstance().getActions();
