export const UnitsOrder = [
	{ id: false, text: 'In any order' },
	{ id: true, text: 'In sequential order' },
];

export const CourseCompletion = [
	{ id: 'all', text: 'All units are completed' },
	{ id: 'subset', text: 'Selected units are completed' },
	{ id: 'percentage', text: 'Percentage of units are completed' },
];
export const PrerequisiteCriteriaDropdown = [
	{ id: 'purchase', text: 'Upon Purchase' },
	{ id: 'complete', text: 'Upon Completion' },
];
