import { FormControlLabel } from '@mui/material';
import { styled } from '@mui/system';
import { FlexLayout, Spacer } from '@Styled/utilities';
import React, { FC } from 'react';
import { TypedTestQuestion } from '../../Types/TestQuestion';
import {
	QuestionContent,
	QuestionContentExpanded,
} from './QuestionAccordion.styled';

type Props = {
	question: TypedTestQuestion<'gap'>;
};
const GAPAccordion: FC<Props> = ({ question }) => {
	return (
		<FlexLayout flexDirection="column">
			<QuestionContentExpanded>
				<div dangerouslySetInnerHTML={{ __html: question.content }} />
			</QuestionContentExpanded>
			<Spacer mb="2rem" />
			<FlexLayout flexDirection="column"></FlexLayout>
		</FlexLayout>
	);
};

export default GAPAccordion;

const MCQAnswerLabel = styled(FormControlLabel)<{ is_correct: boolean }>`
	width: 100%;
	overflow: hidden;
	background-color: ${(props) => (props.is_correct ? '#e5fbf0' : '')};
	padding: 0 0.5rem;
	border-radius: 4px;
`;
