import React from 'react';
import WalletEditor from './WalletEditor';

const Wallet: React.FC = () => {
	return (
		<div>
			<WalletEditor />
		</div>
	);
};

export default Wallet;
