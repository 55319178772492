import { Button, ButtonProps } from '@mui/material';
import { ExtraPropsBase, getVariant } from '@Styled/theme';
import React from 'react';
import styled from 'styled-components';
import { layout, LayoutProps, variant } from 'styled-system';

const EdButton: React.FC<
	ButtonProps & Pick<ExtraPropsBase, 'edcolor'> & LayoutProps
> = ({ edcolor, children, ...props }) => {
	return (
		<StyledEdButton edcolor={edcolor} {...props}>
			{children}
		</StyledEdButton>
	);
};

export default EdButton;

const StyledEdButton = styled(Button)<ButtonProps & ExtraPropsBase>`
	${layout};
	${({ variant }) =>
		getVariant({
			prop: 'edcolor',
			variantPath: `buttons.${variant ?? 'text'}`,
		})}
`;
