import DateTimeFormatter from '@Components/DateTimeFormatter/DateTimeFormatter';
import MainTable from '@Components/MainTable';
import { MainTableRow } from '@Components/MainTable/MainTable';
import { onPageChangeRequestMeta } from '@Components/MainTable/MainTable.types';
import NoResults from '@Components/NoResults/NoResults';
import { useTypedSelector } from '@Features/store';
import {
	Button,
	SpeedDial,
	SpeedDialAction,
	TableCell,
	Tooltip,
} from '@mui/material';
import { usePageLoader } from '@Providers/LoadingProvider';
import { useSnackbar } from '@Providers/useSnackbar';
import { FlexLayout } from '@Styled/utilities';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import styled from 'styled-components';
import { studentsExportData, studentsGetAll } from '../Slice/Students.Slice';
import EdActiveStatus from '@Components/UI/Utilities/EdActiveStatus/EdActiveStatus';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EdIcon from '@Components/UI/Utilities/EdIcon/EdIcon';
import EdSpeedDial, {
	EdSpeedDialAction,
} from '@Components/UI/Utilities/EdSpeedDial/EdSpeedDial';

const TABLE_HEADS = [
	{ displayName: 'username', fieldName: 'username', width: '10%' },
	{ displayName: 'firstname', fieldName: 'first_name', width: '10%' },
	{ displayName: 'lastname', fieldName: 'last_name', width: '10%' },
	{ displayName: 'phone no.', fieldName: 'phone_number', width: '10%' },
	{ displayName: 'email', fieldName: 'email' },
	{ displayName: 'wallet', fieldName: 'amount' },
	{
		displayName: 'phone',
		fieldName: 'phone_verified',
		canSort: false,
	},
	{
		displayName: 'profile',
		fieldName: 'profile_completion',
		canSort: false,
	},
	{ displayName: 'email', fieldName: 'email_verified' },
	{
		displayName: 'fees',
		fieldName: 'fee_paid',
		canSort: false,
	},
	{ displayName: 'registered on', fieldName: 'created_at' },
];

type Props = {
	setBar: any;
};
const StudentsTable: React.FC<Props> = ({ setBar }) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const { setIsLoading } = usePageLoader();
	const [doExport, setDoExport] = useState(false);
	const auth = useTypedSelector((state) => state.auth);
	const { fetchAll, loaders, meta, requestStatus, filters, errors } =
		useTypedSelector((state) => state.Students);
	const { displaySnackbar } = useSnackbar();
	const [flag, setFlag] = useState(false);

	const onPageChangeRequest = useCallback(
		({
			activePage,
			endDate,
			filters,
			query,
			sortBy,
			startDate,
			perPage,
		}: onPageChangeRequestMeta) => {
			if (doExport === true) {
				dispatch(
					studentsExportData({
						page: activePage,
						perPage: perPage ?? 10,
						sortBy,
						filters,
						query,
						from: startDate,
						to: endDate,
					})
				);
				setFlag(false);
			} else {
				dispatch(
					studentsGetAll({
						page: activePage,
						perPage: perPage ?? 10,
						sortBy,
						filters,
						query,
						from: startDate,
						to: endDate,
					})
				);
			}
		},
		[dispatch, doExport]
	);

	const handleTableItemClick = useCallback(
		(id: string) => () => {
			history.push(`/dashboard/students/student/${id}`);
		},
		[history]
	);

	useEffect(() => {
		if (loaders.fetchAll === 'fulfilled') setIsLoading(true);
		setBar(true);
		if (loaders.fetchAll === 'rejected') setIsLoading(false);
	}, [loaders.fetchAll, setIsLoading]);

	useEffect(() => {
		if (loaders.export) {
			setIsLoading(true);
		}
		if (loaders.export === undefined) setIsLoading(false);
	}, [loaders.export, errors.export, setIsLoading]);
	useEffect(() => {
		if (requestStatus.export === 'DONE') {
			displaySnackbar('success', 'Exported Successfully');
		}
		if (requestStatus.export === 'FAILED') {
			displaySnackbar('error', 'Can Not Export!');
		}
	}, [displaySnackbar, requestStatus.export]);

	useEffect(() => {
		if (doExport) setDoExport(false);
	}, [doExport]);

	const handleLoginAsStudent = (uuid: string) => {
		history.push(`/student/${uuid}`);
	};

	return (
		<>
			{(auth.user.type === 'super' || auth.permissions.has('exp-st')) && (
				<FlexLayout alignItems="center" justifyContent="flex-end">
					<ExportButton
						variant="contained"
						color="primary"
						startIcon={<ArrowDownwardIcon fontSize="small" />}
						onClick={() => setDoExport(true)}
					>
						Export
					</ExportButton>
				</FlexLayout>
			)}
			<MainTable
				total={meta?.total ?? 0}
				dateFilter
				pageNum={meta?.current_page}
				tableFilters={filters}
				tableHeads={TABLE_HEADS}
				totalPages={meta?.last_page ?? 0}
				RowsperPage={Number(meta?.per_page)}
				$hasActions
				onPageChangeRequest={onPageChangeRequest}
				renderItems={() => {
					if (fetchAll.length === 0)
						return (
							<MainTableRow>
								<TableCell colSpan={TABLE_HEADS.length}>
									<NoResults />
								</TableCell>
							</MainTableRow>
						);
					return fetchAll.map((student) => {
						return (
							<MainTableRow
								key={student.uuid}
								hover
								onClick={handleTableItemClick(student.uuid)}
							>
								<Tooltip title={student.username} placement="top">
									<TableCell>{student.username}</TableCell>
								</Tooltip>
								<Tooltip title={student.first_name} placement="top">
									<TableCell>{student.first_name}</TableCell>
								</Tooltip>
								<Tooltip title={student.last_name} placement="top">
									<TableCell>{student.last_name}</TableCell>
								</Tooltip>
								<Tooltip title={student.phone_number} placement="top">
									<TableCell>{student.phone_number}</TableCell>
								</Tooltip>
								<Tooltip title={student.email} placement="top">
									<TableCellStyle>{student.email}</TableCellStyle>
								</Tooltip>
								<TableCell>{student?.student?.wallet?.amount}</TableCell>
								<TableCell>
									<EdActiveStatus active={student.phone_verified} />
								</TableCell>
								<TableCell>
									<EdActiveStatus active={student.student.profile_complete} />
								</TableCell>
								<TableCell>
									<EdActiveStatus active={student.email_verified} />
								</TableCell>
								<TableCell>
									<EdActiveStatus active={student.student.registration_paid} />
								</TableCell>
								<Tooltip
									title={<DateTimeFormatter date={student.created_at} />}
									placement="top"
								>
									<TableCell>
										<DateTimeFormatter date={student.created_at} />
									</TableCell>
								</Tooltip>

								<TableCell
									sx={{
										padding: '0 1.063rem 0',
										position: 'sticky',
										right: '0',
										background:
											'linear-gradient(260deg, white, white, transparent)',
									}}
								>
									<EdSpeedDial
										direction="left"
										ariaLabel="Login as student"
										icon={<MoreVertIcon />}
									>
										<EdSpeedDialAction
											key="edit"
											icon={<EdIcon>login</EdIcon>}
											tooltipTitle="Login as student"
											tooltipPlacement="left"
											onClick={(e) => {
												e.stopPropagation();
												handleLoginAsStudent(student.uuid);
											}}
										/>
									</EdSpeedDial>
								</TableCell>
							</MainTableRow>
						);
					});
				}}
			/>
		</>
	);
};

const TableCellStyle = styled(TableCell)`
	font-family: Roboto;
	font-size: 1rem;
	padding: 0 1.063rem 0;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
`;

const ExportButton = styled(Button)`
	border-radius: 24px;
	box-shadow: 0 5px 6px 0 rgba(0, 0, 0, 0.38);
	margin-bottom: 1rem;
`;

export default StudentsTable;
