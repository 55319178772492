import { LocalizationProvider } from '@mui/x-date-pickers';
import '@mui/lab';
import React, { useEffect, useMemo } from 'react';
import { Controller, Path, useController, useForm } from 'react-hook-form';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { RangePickerProps } from '../Pickers.types';
import { StaticDatePicker } from '@mui/x-date-pickers';
import '@mui/lab';
import { TextField } from '@mui/material';
import { DatePickersWrapper } from '../Pickers.styled';
import { FormType } from '@Pages/ClassRooms/Pages/ClassroomsAnalytics/ClassroomsAnalytics';
type MonthPickerForm = {
	monthFrom: Date;
	monthTo: Date;
};
const MonthRangePicker: React.FC<RangePickerProps> = ({ control, name }) => {
	const {
		field: { onChange, value },
	} = useController<FormType>({
		control: control,
		name: name as Path<FormType>,
	});

	const defaultValues = useMemo(() => {
		return {
			monthFrom: value && value[0] ? value[0] : new Date(),
			monthTo: value && value[1] ? value[1] : new Date(),
		};
	}, []);

	const { watch, setValue, ...formProps } = useForm<MonthPickerForm>({
		defaultValues: defaultValues,
		mode: 'all',
	});
	const watchChanges = watch();
	useEffect(() => {
		const { monthFrom, monthTo } = watchChanges;
		let _monthFrom = monthFrom;
		let _monthTo = monthTo;
		if (monthTo)
			_monthTo = new Date(monthTo.getFullYear(), monthTo.getMonth() + 1, 0);
		if (monthFrom) _monthFrom.setDate(1);
		onChange([_monthFrom, _monthTo]);
	}, [watchChanges.monthFrom, watchChanges.monthTo]);

	const getFromValue = useMemo(() => {
		if (value && value[0]) return value[0];
		return new Date();
	}, [value]);
	const getToValue = useMemo(() => {
		if (value && value[1]) return value[1];
		return new Date();
	}, [value]);

	return (
		<LocalizationProvider dateAdapter={AdapterDateFns}>
			<DatePickersWrapper>
				<Controller
					control={formProps.control}
					name="monthFrom"
					render={({ field: { ref, onChange } }) => {
						return (
							<StaticDatePicker
								minDate={new Date('1/1/2018')}
								maxDate={null}
								ref={ref}
								onChange={onChange}
								value={getFromValue}
								views={['year', 'month']}
								renderInput={({ ...props }) => <TextField {...props} />}
								showToolbar={false}
							/>
						);
					}}
				/>
				<Controller
					control={formProps.control}
					name="monthTo"
					render={({ field: { ref, onChange } }) => {
						return (
							<StaticDatePicker
								minDate={new Date('1/1/2018')}
								maxDate={null}
								ref={ref}
								onChange={onChange}
								value={getToValue}
								views={['year', 'month']}
								renderInput={({ ...props }) => <TextField {...props} />}
								showToolbar={false}
							/>
						);
					}}
				/>
			</DatePickersWrapper>
		</LocalizationProvider>
	);
};

export default MonthRangePicker;
