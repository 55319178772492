import React, { useContext, useEffect } from 'react';
import useInvalidateRouteGroup from '@Hooks/Routes/useInvalidateRouteGroup';
import useRouteGroup from '@Hooks/Routes/useRouteGroup';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import CategoriesTable from './CategoriesTable';
import { BreadcrumbContext } from '@Components/UI/Layout/Layout';

const CategoriesRoute = () => {
	const routeGroup = useRouteGroup('category');

	const { path, url } = useRouteMatch();
	const { isCurrent, invalidatedBaseURL } = useInvalidateRouteGroup({
		mode: 'end',
		baseURL: '/dashboard/category',
		routeGroup: routeGroup,
		routePlacement: 2,
	});
	const { removeLink } = useContext(BreadcrumbContext);
	useEffect(() => {
		if (isCurrent) {
			removeLink && removeLink('category');
		}
	}, [isCurrent]);

	return (
		<>
			<Switch>
				{routeGroup && !Array.isArray(routeGroup) && (
					<>
						<Route exact path={url}>
							<CategoriesTable />
						</Route>

						{routeGroup.routes.map((_) => {
							return (
								<Route strict path={`${path}/${_.route}`}>
									{_.component}
								</Route>
							);
						})}
					</>
				)}
			</Switch>
		</>
	);
};

export default CategoriesRoute;
