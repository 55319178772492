import {
	getSingleStudentRequest,
	importStudentPerClassroom,
	updateStudentRequest,
} from '@Services/students/students.req';
import {
	ImportPayload,
	SingleStudentData,
	SingleStudentResponse,
	StudentInStudents,
	StudentPersonalData,
} from '@Services/students/students.res.types';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getAllStudentsRequest } from '@Services/students/students.req';
import { GetAllStudentsResponse } from '@Services/students/students.res.types';
import createThunkBody from '@Utils/createThunkBody';
import {
	FetchAllStudentsPayload,
	GetStudentByIdPayload,
	StudentSliceState,
} from './studentSlice.types';
import { GenericSlice } from '@Features/generics/generics.type';
import { AxiosError } from 'axios';
import { HeadersResponse } from '@Services/generics/response';

// mapping object
const columnHeadToField: any = {
	name: 'first_name',
	'creation date': 'created_at',
	phone: 'phone_number',
	verified: 'email_verified',
};

// side effects
export const fetchAllStudents = createAsyncThunk<
	HeadersResponse<SingleStudentData[]>,
	FetchAllStudentsPayload,
	{ rejectValue: string }
>(
	'user/all',
	async ({ page, perPage, sortBy, filters, query, from, to }, thunkApi) => {
		if (
			Object.keys(sortBy).length !== 0 &&
			sortBy?.field in columnHeadToField
		) {
			sortBy.field = columnHeadToField[sortBy.field];
		}

		const { data, headers } = await getAllStudentsRequest({
			filters,
			from: from,
			page,
			perPage,
			query,
			sortBy,
			to: to,
		});

		return {
			response_data: data,
			headers,
		};
	}
);

export const getStudentById = createAsyncThunk<
	SingleStudentResponse,
	GetStudentByIdPayload,
	{ rejectValue: string }
>('students/getById', async ({ uuid }, thunkApi) => {
	return await createThunkBody<SingleStudentResponse>(
		getSingleStudentRequest(uuid),
		thunkApi,
		'Error getting student'
	);
});

export const updateStudent = createAsyncThunk<
	StudentPersonalData,
	any,
	{ rejectValue: string }
>('students/updateOne', async (payload, thunkApi) => {
	return await createThunkBody<StudentPersonalData>(
		updateStudentRequest(payload),
		thunkApi,
		'Error updating student'
	);
});

export const importFile = createAsyncThunk<
	any,
	ImportPayload,
	{
		rejectValue: string;
	}
>('students/import', async ({ formData, type }, { rejectWithValue }) => {
	try {
		const { data } = await importStudentPerClassroom(type, formData);
		return data;
	} catch (error) {
		let err: AxiosError = error;
		return rejectWithValue(err.response?.data.message);
	}
});

const initialState: StudentSliceState = {
	students: [],
	studentsFilters: [],
	currentStudent: null,
	lastPage: 0,
	page: 0,
	perPage: 0,
	total: 0,
	export: false,
	loaders: {
		fileImport: false,
		fetchAll: null,
		getStudent: null,
		updateStudent: null,
		fileExport: null,
	},
	errors: {
		fetchAll: '',
		getStudent: '',
		updateStudent: '',
		fileImport: '',
		fileExport: '',
	},
	status: {
		fileImport: null,
	},
};

const studentSlice = createSlice({
	name: 'student',
	initialState,
	reducers: {},
	extraReducers(builder) {
		/* fetch all students case */
		builder
			.addCase(fetchAllStudents.pending, (state, { meta: { arg } }) => {
				if (arg) {
					state.loaders.fileExport = true;
					state.errors.fileExport = '';
				} else {
					state.loaders.fetchAll = true;
					state.errors.fetchAll = '';
				}
			})
			.addCase(
				fetchAllStudents.fulfilled,
				(state, { payload: { response_data, headers } }) => {
					const { data, filters, meta } = response_data;
					if (data) {
						state.loaders.fetchAll = false;
						// state?.students = data;
						state.lastPage = meta?.last_page;
						state.studentsFilters = filters || [];
						//  state.currentStudent = currentStudent || [];
						state.total = meta?.total;
						state.page = meta?.current_page;
						state.perPage = meta?.per_page;
					} else {
						state.loaders.fileExport = false;
						var dataStr = 'data:text/csv;charset=utf-8,' + response_data;
						var downloadAnchorNode = document.createElement('a');
						downloadAnchorNode.setAttribute('href', dataStr);
						downloadAnchorNode.setAttribute(
							'download',
							headers['content-disposition']?.split('=')[1] ?? 'file'
						);
						document.body.appendChild(downloadAnchorNode); // required for firefox
						downloadAnchorNode.click();
						downloadAnchorNode.remove();
					}
				}
			)
			.addCase(
				fetchAllStudents.rejected,
				(state, { payload, meta: { arg } }) => {
					if (arg) {
						state.loaders.fileExport = undefined;
						state.errors.fileExport = payload || '';
					} else {
						state.loaders.fetchAll = undefined;
						state.errors.fetchAll = payload || '';
					}
				}
			);

		/* get single student case */
		builder
			.addCase(getStudentById.pending, (state) => {
				state.loaders.getStudent = true;
				state.errors.getStudent = '';
			})
			.addCase(
				getStudentById.fulfilled,
				(state, { payload: { data, filters, message, meta } }) => {
					const { address } = data.student;
					// state.currentStudent = {
					//   uuid: data.uuid,
					//   email: data.email,
					//   username: data.username,
					//   phone_number: data.phone_number,
					//   first_name: data.first_name,
					//   middle_name: data.middle_name,
					//   last_name: data.last_name,
					//   lms_id: data.lms_id,
					//   gender: data.gender,
					//   profile_photo: data.profile_photo,
					//   birth_date: data.birth_date,
					//   phone_verified: data.phone_verified,
					//   email_verified: data.email_verified,
					//   created_at: data.created_at,
					//   updated_at: data.updated_at,
					//   cart: data.cart,
					//   walletLogs: data.wallet_logs,
					//   timelineLogs: data.timeline_logs,
					//   courses: data.courses,
					//   // scratchCards: data.scratchCards,
					//   // student: {
					//   //   education_language_id: data.student.education_language_id,
					//   //   education_section_id: data.student.education_section_id,
					//   //   education_type_id: data.student.education_type_id,
					//   //   education_year_id: data.student.education_year_id,

					//   //   parent2_phone: data.student.parent2_phone,
					//   //   parent1_phone: data.student.parent1_phone,
					//   //   parent1_relation: data.student.parent1_relation,
					//   //   parent2_relation: data.student.parent2_relation,
					//   //   profile_complete: data.student.profile_complete,
					//   //   school: data.student.school,
					//   //   ssn: data.student.ssn,
					//   //   created_at: data.student.created_at,
					//   //   updated_at: data.student.updated_at,
					//   //   user_id: data.student.user_id || 0,
					//   //   wallet: {
					//   //     amount: data.student.wallet.amount,
					//   //     currency: data.student.wallet.currency,
					//   //     created_at: data.student.wallet.created_at,
					//   //     updated_at: data.student.wallet.updated_at,
					//   //   },
					//   //   addresses: {
					//   //     building_number: data.student?.address?.building_number,
					//   //     floor: data.student?.address?.floor,
					//   //     apartment: data.student?.address?.apartment,
					//   //     street: data.student?.address?.street,
					//   //     governorate: data.student?.address?.governorate,
					//   //     city: data.student?.address?.city,
					//   //     country: data.student?.address?.country,
					//   //     postal_code: data.student?.address?.postal_code,
					//   //     created_at: data.student?.address?.created_at,
					//   //     updated_at: data.student?.address?.updated_at,
					//   //   },
					//   //   classrooms: data.student.classrooms,
					//   //   recharge_cards: data.student.recharge_cards,
					//   //   invoices: data.student.invoices,
					//   //   ips: data.student.ips,
					//   //   scratchCards: data.student.cards,
					//   // },
					// };
					state.loaders.getStudent = false;
				}
			)
			.addCase(getStudentById.rejected, (state, { payload }) => {
				state.loaders.getStudent = undefined;
				state.errors.getStudent = payload || '';
			});

		/* update student case */
		builder
			.addCase(updateStudent.pending, (state) => {
				state.loaders.updateStudent = true;
				state.errors.updateStudent = '';
			})
			.addCase(updateStudent.fulfilled, (state, { payload }) => {
				state.loaders.updateStudent = false;
				// state.currentStudent = payload.data;
			})
			.addCase(updateStudent.rejected, (state, { payload }) => {
				state.loaders.updateStudent = undefined;
				state.errors.updateStudent = payload || '';
			});
		builder
			.addCase(importFile.pending, (state, { type, payload }) => {
				state.loaders.fileImport = true;
				state.status.fileImport = null;
			})
			.addCase(importFile.fulfilled, (state, { payload }) => {
				state.loaders.fileImport = false;
				state.status.fileImport = 'DONE';
			})
			.addCase(importFile.rejected, (state, { payload }) => {
				state.loaders.fileImport = undefined;
				state.errors.fileImport = payload || '';
				state.status.fileImport = 'FAILED';
			});
	},
});

export default studentSlice.reducer;
