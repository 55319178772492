import React from 'react';
import CollapedPermissions from './CollapedPermissions';
import { GroupPermissions } from '@Pages/Roles/Types/Roles.types';
import { Control, useController } from 'react-hook-form';

type Props = {
	group_permissions: Array<GroupPermissions> | undefined;
	searchInput: string;
	filteredResults: GroupPermissions[];
	control: any;
	name: string;
	disabled?: boolean;
};

const CollapseContainer: React.FC<Props> = ({
	group_permissions,
	searchInput,
	filteredResults,
	control,
	name,
	disabled,
}) => {
	const {
		field: { onChange, value, onBlur, ref },
	} = useController({
		control,
		name,
	});

	return (
		<>
			<div onBlur={onBlur} ref={ref}>
				{filteredResults &&
					filteredResults?.map((_) => {
						const { id, permissions } = _;
						let selectedValue: Array<any> = [...new Set(value)];
						const group = group_permissions?.find((y) => y.id === _.id);
						const isSelected =
							selectedValue &&
							permissions.length > 0 &&
							permissions.every((val) =>
								selectedValue.find((_: number) => _ === val.id)
							);
						return (
							<>
								<div key={`permission_group-${id}`}>
									{group && (
										<CollapedPermissions
											groupPermissions={group}
											disabled={disabled}
											search={searchInput}
											filteredResults={filteredResults}
											onSelect={(selected: boolean) => {
												const values: Array<number> = selectedValue;
												if (selected) {
													if (!values) {
														onChange(permissions?.map((_) => _.id));
													} else {
														onChange(
															values.concat(permissions?.map((_) => _.id))
														);
													}
												} else {
													permissions.forEach((prem) => {
														const index = values?.findIndex(
															(_) => _ === prem.id
														);
														if (index !== undefined) {
															values?.splice(index, 1);
														}
													});
													onChange(values);
												}
											}}
											selected={isSelected}
											onPermissionSelect={(selected, perValue) => {
												const values: Array<number> = selectedValue;
												if (!values && selected) {
													onChange([perValue]);
													return;
												}
												if (selected) {
													if (!values?.find((_) => _ === perValue)) {
														onChange([...values, perValue]);
													}
												} else {
													const index = values?.findIndex(
														(_) => _ === perValue
													);
													values.splice(index, 1);
													onChange(values);
												}
											}}
											currentPermission={selectedValue ?? []}
										/>
									)}
								</div>
							</>
						);
					})}
			</div>
		</>
	);
};

export default CollapseContainer;
