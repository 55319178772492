import React, { FC } from 'react';
import styled from 'styled-components';

type Props = {
	isLink?: boolean;
	value: any;
};
const EdEmptyValue: FC<Props> = ({ value, isLink = true }) => {
	return (
		<>
			{value && (
				<StyledValue isLink={isLink}>
					<a title={value}>{value}</a>
				</StyledValue>
			)}
			{!value && <div> - </div>}
		</>
	);
};

export default EdEmptyValue;

const StyledValue = styled.div<{ isLink?: boolean }>`
	text-decoration: underline;
	text-overflow: ellipsis;
	overflow: hidden;
	a {
		color: ${(props) => (!props.isLink ? '#000' : 'blue')};
	}
`;
