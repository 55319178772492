import { BaseSliceController } from '@Base/slices/slices.base';
import { GroupsRequester } from '@Pages/ClassRooms/Services/Groups/GroupsSlice.requester';
import { Group } from '@Pages/ClassRooms/Types/Groups.types';

export class GroupsSlice extends BaseSliceController<Group, 'Groups'> {
	private static instance: GroupsSlice;
	static getInstance() {
		if (!GroupsSlice.instance) {
			GroupsSlice.instance = new GroupsSlice();
		}
		return GroupsSlice.instance;
	}
	constructor() {
		super('Groups', GroupsRequester.getInstance());
	}
}

export const { groupsGetAll, groupsCreate, groupsGetSingle, groupsUpdate ,groupsDeleteById} =
	GroupsSlice.getInstance().getActions();
