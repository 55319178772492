import { EnrolledUser } from '@Pages/ClassRooms/Pages/SingleCourse/Pages/Users/Types/UserProgress.types';
import api from '@Services/api';
import { AxiosResponse } from 'axios';

import {
	CRUDBase,
	DeleteSingleEntityPayload,
	GetAllMeta,
	GetSingleEntityPayload,
} from 'src/base/services/services.base';
import { CourseTestsSummary } from '../../../CourseForm/Pages/CourseReports/Types/TestReports.types';

interface UserCRUD extends CRUDBase<EnrolledUser, EnrolledUser> {}
export class UsersRequester implements UserCRUD {
	getAll(
		payload?: GetAllMeta
	): Promise<AxiosResponse<ApiResponse<EnrolledUser[]>>>;
	getAll(payload: number): Promise<AxiosResponse<ApiResponse<EnrolledUser[]>>>;
	getAll(payload?: any): Promise<AxiosResponse<ApiResponse<EnrolledUser[]>>> {
		throw new Error('Method not implemented.');
	}
	delete(id: number): Promise<AxiosResponse<ApiResponse<any>>>;
	delete(id: string): Promise<AxiosResponse<ApiResponse<any>>>;
	delete(
		id: string | number,
		payload?: DeleteSingleEntityPayload
	): Promise<AxiosResponse<ApiResponse<any>>>;
	delete(id: any, payload?: any): Promise<AxiosResponse<ApiResponse<any>>> {
		throw new Error('Method not implemented.');
	}
	create(
		payload: Omit<EnrolledUser, 'id'>
	): Promise<AxiosResponse<ApiResponse<EnrolledUser>>> {
		throw new Error('Method not implemented.');
	}
	update(
		payload: EnrolledUser
	): Promise<AxiosResponse<ApiResponse<EnrolledUser>>> {
		throw new Error('Method not implemented.');
	}

	private static instance: UsersRequester;
	static getInstance() {
		if (!UsersRequester.instance) {
			UsersRequester.instance = new UsersRequester();
		}
		return UsersRequester.instance;
	}
	baseURL = '/progress/course';
	get(id: string): Promise<AxiosResponse<ApiResponse<EnrolledUser>>>;
	get(id: number): Promise<AxiosResponse<ApiResponse<EnrolledUser>>>;
	get(
		id: string | number,
		payload?: GetSingleEntityPayload
	): Promise<AxiosResponse<ApiResponse<EnrolledUser>>>;
	async get(
		id: any,
		tId?: any,
		payload?: any
	): Promise<AxiosResponse<ApiResponse<EnrolledUser>>> {
		return await api.post(`${this.baseURL}/units`, {
			course_id: id,
			user_id: tId,
			payload,
		});
	}
	async completeStudent(
		course_id: number,
		user_ids: Array<number>
	): Promise<AxiosResponse<ApiResponse<EnrolledUser>>> {
		return await api.post(`${this.baseURL}/complete`, { course_id, user_ids });
	}
	async syncronizeStudent(
		course_id: number,
		user_ids: Array<number>
	): Promise<AxiosResponse<ApiResponse<EnrolledUser>>> {
		return await api.post(`${this.baseURL}/sync`, { course_id, user_ids });
	}
	async resetStudent(
		course_id: number,
		user_ids: Array<number>
	): Promise<AxiosResponse<ApiResponse<EnrolledUser>>> {
		return await api.post(`${this.baseURL}/reset`, { course_id, user_ids });
	}
	async resetAllStudent(
		course_id: number
	): Promise<AxiosResponse<ApiResponse<EnrolledUser>>> {
		return await api.post(`${this.baseURL}/reset`, { course_id });
	}
	async resetTest(
		unit_id: number
	): Promise<AxiosResponse<ApiResponse<CourseTestsSummary>>> {
		return await api.post(`${this.baseURL}/units/reset`, { unit_id });
	}
	async syncronizeAllStudent(
		course_id: number,
		skipCompleted: boolean
	): Promise<AxiosResponse<ApiResponse<EnrolledUser>>> {
		return await api.post(`${this.baseURL}/sync`, { course_id, skipCompleted });
	}
	async toggleUnits(
		unit_id: number,
		user_id: number
	): Promise<AxiosResponse<ApiResponse<EnrolledUser>>> {
		return await api.post(`${this.baseURL}/units/toggle`, { user_id, unit_id });
	}
}

export const updateEnrollment = async (
	course_id: number,
	user_ids: Array<number>,
	expiration_date: Date
) =>
	await api.post<ApiResponse<EnrolledUser>>('course/enrollment/update', {
		course_id,
		user_ids,
		expiration_date,
	});
