import { BaseSliceController } from '@Base/slices/slices.base';
import { WebinarSlotsRequester } from '../Services/Slots/SlotRequester';
import { WebinarSlotsPayload, Slot } from '../Types/Webinar.types';

export class WebinarSlotSlice extends BaseSliceController<
	WebinarSlotsPayload,
	'Slots',
	WebinarSlotsPayload,
	Slot
> {
	private static instance: WebinarSlotSlice;
	static getInstance() {
		if (!WebinarSlotSlice.instance) {
			WebinarSlotSlice.instance = new WebinarSlotSlice();
		}
		return WebinarSlotSlice.instance;
	}
	constructor() {
		super('Slots', WebinarSlotsRequester.getInstance());
	}
}

export const {
	slotsDeleteById,
	slotsExportData,
	slotsGenerate,
	slotsGetAll,
	slotsGetSingle,
	slotsToggleActive,
	slotsUpdate,
} = WebinarSlotSlice.getInstance().getActions();
