import { HashedSingleRoute, RouteGroup } from '@Constants/routes';
import CourseForm from '../CourseForm';
import { Test } from '../Pages/CourseContent/Pages/Test/Test';
const TestRouteGroup: RouteGroup = {
	code: '',
	// component: <Test />,
	component: <div>Test</div>,
	route: 'test',
	routes: [],
	title: 'Test',
};

export const CourseFormRouteGroup: RouteGroup = {
	title: 'Edit Course',
	route: 'edit',
	icon: '',
	code: '',
	offMenu: true,
	isRouted: false,
	component: <CourseForm />,
	// component: <CourseForm />,
	// routes: [TestRouteGroup],
	routes: [],
};
