import { LocalizationProvider } from '@mui/x-date-pickers';
import '@mui/lab';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useImmer } from 'use-immer';
import { ptBR } from 'date-fns/locale';
import {
	BreadcrumbRouteHash,
	ExtraProps,
	RouteGroup,
	SingleRouteDisplay,
} from '../../../constants/routes';
import RoutesLayout from './Components/RoutesLayout';

type NavMenuContextType = {
	currentRouteGroup?: RouteGroup;
	setCurrentRouteGroup?: (routeGroup: RouteGroup) => void;
	setBaseURL?: React.Dispatch<string>;
	baseURL?: string;
	routeCallback?: Function;
	setRouteCallback?: React.Dispatch<Function>;
	extraProps?: ExtraProps;
	setExtraProps?: React.Dispatch<ExtraProps>;
};
export type BreadcrumbRoute = {
	routeHash: BreadcrumbRouteHash;
} & SingleRouteDisplay;
type BreadcrumbsContextType = {
	addLink?: (value: BreadcrumbRoute) => void;
	removeLink?: (hash: BreadcrumbRouteHash) => void;
	extraLink: BreadcrumbRoute[];
	removeAll: () => void;
};
export const NavMenuContext = createContext<NavMenuContextType>({});
export const BreadcrumbContext = createContext<BreadcrumbsContextType>({
	extraLink: [],
	removeAll: () => {},
});
const Layout = () => {
	const [currentRouteGroup, setCurrentRouteGroup] = useState<RouteGroup>();
	const [baseURL, setBaseURL] = useState<string>();
	const handleRouteGroupChange = (route: RouteGroup) => {
		setCurrentRouteGroup(route);
	};
	const [routeCallback, setRouteCallback] = useState<Function>();

	const [extraProps, setExtraProps] = useState<ExtraProps>();
	const [extraBreadcrumbLinks, setExtraBreadcrumbLinks] = useImmer<
		BreadcrumbRoute[]
	>([]);

	const addLink = (value: BreadcrumbRoute) => {
		setExtraBreadcrumbLinks((prev) => {
			const _item = prev.find((_) => _.routeHash === value.routeHash);
			if (_item) {
				_item.route = value.route;
				_item.title = value.title;
			} else {
				prev.push(value);
			}
		});
	};
	const removeLink = (hash: BreadcrumbRouteHash) => {
		setExtraBreadcrumbLinks((prev) => {
			const _itemIndex = prev.findIndex((_) => _.routeHash === hash);
			if (_itemIndex !== undefined) {
				prev.splice(_itemIndex, 1);
			}
		});
	};

	const removeAll = () => {
		setExtraBreadcrumbLinks([]);
	};

	return (
		<BreadcrumbContext.Provider
			value={{
				extraLink: extraBreadcrumbLinks,
				addLink: addLink,
				removeLink: removeLink,
				removeAll,
			}}
		>
			<NavMenuContext.Provider
				value={{
					currentRouteGroup: currentRouteGroup,
					setCurrentRouteGroup: handleRouteGroupChange,
					baseURL: baseURL,
					setBaseURL: setBaseURL,
					routeCallback: routeCallback,
					setRouteCallback: setRouteCallback,
					extraProps: extraProps,
					setExtraProps: setExtraProps,
				}}
			>
				<RoutesLayout />
			</NavMenuContext.Provider>
		</BreadcrumbContext.Provider>
	);
};

export default Layout;

export const useBreadcrumb = () => useContext(BreadcrumbContext);
