import { createContext, useContext } from 'react';
import React from 'react';
type ClassroomContextArgs = {
	loaded: boolean;
};

const ClassroomContext = createContext<ClassroomContextArgs | null>(null);

export const ClassroomProvider: React.FC<ClassroomContextArgs> = ({
	children,
	loaded,
}) => {
	return (
		<ClassroomContext.Provider value={{ loaded }}>
			{children}
		</ClassroomContext.Provider>
	);
};

export const useClassroomProvider = () => {
	const context = useContext(ClassroomContext);
	if (!context) throw new Error('No ClassroomProvider found !!!');
	return context;
};
