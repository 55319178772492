import { BreadcrumbContext } from '@Components/UI/Layout/Layout';
import useInvalidateRouteGroup from '@Hooks/Routes/useInvalidateRouteGroup';
import useRouteGroup from '@Hooks/Routes/useRouteGroup';
import React, { useContext, useEffect } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import InstructorsTable from './instructorsTable';

const Instructors: React.FC = () => {
	const routeGroup = useRouteGroup('instructors');

	const { path, url } = useRouteMatch();
	const { isCurrent, invalidatedBaseURL } = useInvalidateRouteGroup({
		mode: 'end',
		baseURL: '/dashboard/instructors',
		routeGroup: routeGroup,
		routePlacement: 2,
	});
	const { removeLink } = useContext(BreadcrumbContext);
	useEffect(() => {
		if (isCurrent) {
			removeLink && removeLink('instructor');
		}
	}, [isCurrent]);

	return (
		<Switch>
			{routeGroup && !Array.isArray(routeGroup) && (
				<>
					<Route exact path={url}>
						<InstructorsTable />
					</Route>
					{routeGroup &&
						routeGroup.routes.length > 0 &&
						routeGroup.routes.map((route) => (
							<Route key={`${route.route}`} path={`${path}/${route.route}`}>
								{route.component}
							</Route>
						))}
				</>
			)}
		</Switch>
	);
};

export default Instructors;
