import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
	GetSingleInvoice,
	UpdateInvoice,
	ProcessInvoice,
} from '@Services/invoices/invoices.req';
import { GetInvoiceResponse } from '@Services/invoices/invoices.req.types';
import { usePageLoader } from '@Providers/LoadingProvider';
import { useSnackbar } from '@Providers/useSnackbar';
import { Table } from 'reactstrap';
import { useForm } from 'react-hook-form';
import NormalSweetAlert from '@Utils/normalSweetAlert';
import SidebarContentWrapper from '@Components/SidebarContentWrapper';

import ConfirmationSweetAlert from '@Utils/confirmationSweetAlert';

interface Params {
	id: string;
}

interface FormData {
	status: string;
}

const InvoicesForm = () => {
	const history = useHistory();
	const params = useParams<Params>();
	const [currentInvoice, setCurrentInvoice] = useState<GetInvoiceResponse>();
	const { setIsLoading } = usePageLoader();
	const { setValue } = useForm<FormData>();

	useEffect(() => {
		const { id } = params;
		loadInvoice(parseInt(id));
	}, []);

	const loadInvoice = async (id: number) => {
		setIsLoading(true);
		try {
			const response = await GetSingleInvoice(id);
			setCurrentInvoice(response.data);
			setValue('status', response.data.data.status);
		} catch (e) {
			NormalSweetAlert(e.response.data.message, 'error');
			history.goBack();
		} finally {
			setIsLoading(false);
		}
	};

	const onSubmit = async (data: FormData) => {
		try {
			if (currentInvoice) {
				await UpdateInvoice(currentInvoice.data.id, data.status);
				await NormalSweetAlert(
					'Invoice status is updated successfully',
					'success'
				);
				history.goBack();
			}
		} catch (e) {
			NormalSweetAlert(e.response.data.message, 'error');
		}
	};

	const handleProcess = async () => {
		if (currentInvoice) {
			ConfirmationSweetAlert({
				title: `Are you sure you want to process invoice: ${currentInvoice.data.invoice_ref}?`,
				onConfirm: async () => {
					try {
						const response = await ProcessInvoice(currentInvoice?.data.id);
						NormalSweetAlert(response.data.message, 'success');
						history.goBack();
					} catch (e) {
						NormalSweetAlert(e.response.data.message, 'error');
					}
				},
			});
		} else {
			NormalSweetAlert('No selected invoice to process', 'error');
		}
	};

	return <SidebarContentWrapper></SidebarContentWrapper>;
};

export default InvoicesForm;
