import { IconButton, Menu, MenuItem } from '@mui/material';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Slot } from '../Types/Webinar.types';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useDispatch } from 'react-redux';
import { slotsDeleteById } from '../Slices/Slot.slice';
import EventIcon from '@mui/icons-material/Event';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import { useHistory, useRouteMatch } from 'react-router-dom';
import moment from 'moment';
import { useTypedSelector } from '@Features/store';
import { webinarsGetSingle } from '../Slices/Webinar.slice';
import { FlexLayout, Spacer } from '@Styled/utilities';
import EdConfirmModal from '@Components/UI/Modals/EdConfirmModal/EdConfirmModal';

type Props = {
	slot: Slot;
	index: number;
};

const SlotCard: React.FC<Props> = ({ slot, index }) => {
	const history = useHistory();
	const { url } = useRouteMatch();

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [openModel, setOpenModel] = useState(false);
	const [deletedId, setDeletedId] = useState<any>('');
	const open = Boolean(anchorEl);
	const { currentEntity } = useTypedSelector((state) => state.Webinars);
	const {
		requestStatus: { deleteById },
	} = useTypedSelector((state) => state.Slots);

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		event.stopPropagation();
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const dispatch = useDispatch();

	useEffect(() => {
		if (!currentEntity || !currentEntity.id) return;
		if (deleteById === 'DONE')
			dispatch(
				webinarsGetSingle({
					id: currentEntity.id,
				})
			);
	}, [deleteById]);

	const redirect = (e: React.MouseEvent<HTMLElement>, data: Slot) => {
		e.stopPropagation();
		history.push(`${url}/registeredUsers/${data.id}`);
	};

	const handleModelOpen = (id: number) => {
		setOpenModel(true);
		setDeletedId(id);
	};
	const handleModelClose = (close: boolean, confirm?: boolean) => {
		if (confirm) {
			dispatch(slotsDeleteById(deletedId));
		}
		setOpenModel(false);
		setDeletedId(null);
	};

	return (
		<>
			<EdConfirmModal
				text="Are you sure you want to delete this Slot"
				open={openModel}
				handleClose={handleModelClose}
			/>

			<CardWrapper
				onClick={(e) => {
					redirect(e, slot);
				}}
			>
				<Title>{slot.name}</Title>
				<Text>
					<EventIcon color="disabled" />
					{moment(slot.start_time).format('ddd. DD/MM')}
				</Text>
				<Text>
					<AccessTimeIcon color="disabled" />
					{moment(slot.start_time).format('kk:mm a')}
				</Text>
				<Text>
					<FlexLayout alignItems="flex-end">
						<HourglassBottomIcon color="disabled" /> {slot.duration}
						<Spacer mx="0.2rem" />
						<span>min</span>
					</FlexLayout>
				</Text>
				<Text>
					<HowToRegIcon color="disabled" /> {slot.students_count}/
					{slot.capacity === null ? 'Unlimited' : slot.capacity}
				</Text>
				<IconButton
					aria-label="more"
					id="long-button"
					aria-controls={open ? 'long-menu' : undefined}
					aria-expanded={open ? 'true' : undefined}
					aria-haspopup="true"
					onClick={(e) => {
						e.stopPropagation();
						handleClick(e);
					}}
				>
					<MoreVertIcon />
				</IconButton>
				<Menu
					id="long-menu"
					MenuListProps={{
						'aria-labelledby': 'long-button',
					}}
					anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
					transformOrigin={{ vertical: -13, horizontal: 80 }}
					anchorEl={anchorEl}
					open={open}
					onClick={(e) => {
						e.stopPropagation();
					}}
					onClose={handleClose}
					PaperProps={{
						style: {
							maxHeight: 48 * 4.5,
							width: '10.25rem',
						},
					}}
				>
					<MenuItem
						key="edit"
						onClick={(e) => {
							history.push({ pathname: `${url}/edit/slot`, state: slot });
						}}
					>
						Edit
					</MenuItem>
					<MenuItem key="clone" disabled={true}>
						Clone
					</MenuItem>
					<MenuItem
						key="delete"
						onClick={() => {
							handleModelOpen(slot.id);
						}}
					>
						Delete
					</MenuItem>
				</Menu>
			</CardWrapper>
		</>
	);
};

const CardWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	height: 3.813rem;
	border-radius: 5px;
	box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
	background-color: #fff;
	padding: 0.625rem 0.9rem;
	margin-bottom: 1.5rem;
	cursor: pointer;
	display: grid;
	grid-auto-flow: column;
	grid-template-columns: repeat(5, 1fr);
	justify-items: flex-start;
`;
const Title = styled.div`
	font-size: 1rem;
	font-weight: 600;
	color: ${(props) => props.theme.palette.cadet.main};
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	min-width: 6rem;
	width: 90%;
`;
const Text = styled.div`
	font-size: 1rem;
	font-weight: 600;
	color: ${(props) => props.theme.palette.darkPurple.main};
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 0.375rem;
	span {
		font-size: 0.625rem;
	}
`;

export default SlotCard;
