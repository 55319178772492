import RoundButton from '@Components/UI/Buttons/RoundButton';
import EdIcon from '@Components/UI/Utilities/EdIcon/EdIcon';
import { useTypedSelector } from '@Features/store';
import { FlexLayout } from '@Styled/utilities';
import React from 'react';
import styled from 'styled-components';
import ReportsTabs from './Components/ReportsTabs';

const ReportsOverview = () => {
	const { currentCourse } = useTypedSelector((state) => state.classroom);

	return (
		<>
			<FlexLayout justifyContent="space-between" alignItems="center" mb="2rem">
				<Name> {currentCourse?.name} </Name>
				{/* <ExportButton
          startIcon={<EdIcon fontSize="small">arrow_downward</EdIcon>}
          variant="contained"
        >
          EXPORT
        </ExportButton> */}
			</FlexLayout>
			<ReportsTabs />
		</>
	);
};

const Name = styled.p`
	color: #6c63ff;
	font-size: 1.125rem;
	font-weight: bold;
`;
const ExportButton = styled(RoundButton)`
	width: 7.375rem;
	height: 2.75rem;
	box-shadow: 0 5px 6px 0 rgba(0, 0, 0, 0.38);
`;

export default ReportsOverview;
