import React from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import EdFormControl from '@Components/UI/Inputs/EdFormControl/EdFormControl';
import { yupResolver } from '@hookform/resolvers/yup';
import { DevTool } from '@hookform/devtools';
import {
	AddCategoryForm,
	ADD_CATEGORY,
} from '@Pages/Categories/Schema/CategoryInfo.schema';
import { CategoryRequester } from '@Pages/Categories/Services/Categories.req';
import { DASHBOARD_BASE_ROUTE } from '@Constants/routes';
import { useSnackbar } from '@Providers/useSnackbar';
import { useHistory } from 'react-router-dom';
import { Icon, MenuItem } from '@mui/material';
import EdUploadFile from '@Components/UI/Inputs/EdUploadFile/EdUploadFile';
import { useTypedSelector } from '@Features/store';
import GroupedBtn from '@Pages/Categories/Components/GroupedBtn';
import { useDispatch } from 'react-redux';
import { fetchCategoriesDropdown } from '@Features/dropdowns/dropdownsSlice';

const AddCategoryInfo: React.FC = () => {
	const history = useHistory();
	const { displaySnackbar } = useSnackbar();
	const { dropdownCategories } = useTypedSelector((state) => state.dropdowns);

	const dispatch = useDispatch();
	const { getValues, control, formState } = useForm<AddCategoryForm>({
		resolver: yupResolver(ADD_CATEGORY),
		mode: 'all',
		defaultValues: {},
	});

	const onSave = async (form: AddCategoryForm) => {
		try {
			const {
				data: { data },
			} = await CategoryRequester.getInstance().create(form);
			displaySnackbar('success', `Category ${data.name} created successfully`);
			dispatch(fetchCategoriesDropdown());
			history.push(`${DASHBOARD_BASE_ROUTE}/category/edit/category/${data.id}`);
		} catch (e) {
			const msg = e.response?.data.message || 'Unable to Create Category ';
			displaySnackbar('error', msg);
		}
	};

	return (
		<Wrapper>
			<Section>
				<EdFormControl control={control} required name="name" label="Name" />
				<EdFormControl
					control={control}
					select
					name="parent_id"
					label="Parent ID"
				>
					<MenuItem value={NaN}>None</MenuItem>
					{dropdownCategories?.map((_) => {
						return <MenuItem value={_.id + 1}>{`${_.name}`}</MenuItem>;
					})}
				</EdFormControl>
			</Section>
			<Section>
				<EdFormControl control={control} required name="code" label="Code" />
				<EdUploadFile
					folder="icons/subjects"
					uploadLabel="UPLOAD"
					uploadIcon={<Icon>add</Icon>}
					control={control}
					name="icon"
					label="Icon"
					aspectRation={16 / 16}
				/>
			</Section>
			<GroupedBtn
				onSave={onSave}
				getValues={getValues}
				formState={formState}
				edit={false}
			/>
			<DevTool control={control} />
		</Wrapper>
	);
};

export const Wrapper = styled.div`
	display: flex;
	gap: 1rem;
	flex-direction: column;
	padding-left: 1.5rem;
	padding-top: 1rem;
	max-width: 860px;
	@media screen and (min-width: 1600px) {
		max-width: 1030px;
	}
	.MuiOutlinedInput-root {
		width: 17.8rem;
		height: 2.7rem;
	}
`;

export const Section = styled.div`
	display: flex;
	justify-content: start;
	flex-wrap: wrap;
	gap: 90px;
	margin-bottom: 2rem;
`;

export default AddCategoryInfo;
