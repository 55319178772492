import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import createThunkBody from '@Utils/createThunkBody';
import {
	getAllRolesRequest,
	getSingleRoleRequest,
} from '../Services/roles.req';
import { FetchRolesResponse } from '../Services/roles.res.types';
import { Role } from '../Types/Roles.types';
import { RolesSliceState, GetAllRolesPayload } from './roleSilce.types';

const SLICE_BASE_NAME = 'roles';
/* SIDE EFFECTS */
const columnHeadToField: any = {
	name: 'title',
	category: '',
};

/* roles */

export const getAllRoles = createAsyncThunk<
	FetchRolesResponse,
	GetAllRolesPayload,
	{ rejectValue: string }
>(
	'roles/getAll',
	async ({ page, perPage, sortBy, filters, query, from, to }, thunkApi) => {
		return await createThunkBody<ApiResponse<Role[]>>(
			getAllRolesRequest(page, perPage, filters, sortBy, query, from, to),
			thunkApi,
			'Error fetching Roles'
		);
	}
);

export const getSingleRole = createAsyncThunk<
	ApiResponse<Role>,
	{ id: number },
	{ rejectValue: string }
>('roles/get', async ({ id }) => {
	const { data } = await getSingleRoleRequest(id);
	return data;
});

/* SLICE */
const initialState: RolesSliceState = {
	/* roles */
	roles: [],
	permissions: [],
	rolesFilters: [],
	lastPage: 0,
	totalResults: 0,
	currentRoles: null,
	perPage: 0,
	page: 0,
	name: '',
	loaders: {
		getAll: null,
		getOne: null,
		getAllRoles: null,
	},
	errors: {
		getAll: '',
		getOne: '',
		getAllRoles: '',
	},
};

const rolesSlice = createSlice({
	name: SLICE_BASE_NAME,
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// all role
		builder
			.addCase(getAllRoles.pending, (state) => {
				state.loaders.getAll = true;
				state.errors.getAll = '';
			})
			.addCase(getAllRoles.fulfilled, (state, { payload }) => {
				state.loaders.getAll = false;
				state.roles = payload.data;
				state.rolesFilters = payload.filters;
				state.lastPage = payload.meta.last_page;
				state.totalResults = payload.meta.total;
				state.perPage = payload.meta.per_page;
				state.page = payload.meta.current_page;
				state.currentRoles = null;
			})
			.addCase(getAllRoles.rejected, (state, { payload }) => {
				state.loaders.getAll = undefined;
				state.errors.getAll =
					payload || 'could not get roles, please try again!';
			});
		//single role
		builder
			.addCase(getSingleRole.pending, (state) => {
				state.loaders.getOne = true;
				state.errors.getOne = '';
			})
			.addCase(getSingleRole.fulfilled, (state, { payload }) => {
				state.loaders.getOne = false;
				state.currentRoles = payload.data;
			})
			.addCase(getSingleRole.rejected, (state, { payload }) => {
				state.loaders.getAllRoles = undefined;
				state.errors.getOne =
					payload || 'could not get role, please try again!';
			});
	},
});

export default rolesSlice.reducer;
