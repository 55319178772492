import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function useFilters<T>() {
	type FilterKey = keyof T;
	let usp = new URLSearchParams(document.location.search);
	let { search } = useLocation();
	const [selectedFilters, setSelectedFilters] = useState<
		Array<{ FilterKey: string }>
	>([]);
	let arr: any = [];
	useEffect(() => {
		usp.forEach(function (value, key) {
			if (key.includes('FILTER')) {
				const keyName = key.split('FILTER_');
				arr.push({ [keyName[1]]: value });
			}
		});
		setSelectedFilters(arr);
	}, [search]);
	return {
		selectedFilters,
	};
}

export default useFilters;
