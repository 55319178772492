import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { fetchCoursesDropdown } from '@Features/dropdowns/dropdownsSlice';
import PrerequisitePaths from './PrerequisitePaths';
import { Updater } from 'use-immer';
import {
	ClassroomCourse,
	CourseWithPrerequisites,
} from '@Services/classrooms/classrooms.res.types';
import { MultiSelectOptions } from '../types/RolesPaths.types';

type Props = {
	classroom_id: number | undefined;
	course_id: number | null;
	control: any;
	name: string;
	prerequisitesPaths: MultiSelectOptions[];
	setPrerequisitesPaths: Updater<MultiSelectOptions[]>;
	selectedCourse: (ClassroomCourse & CourseWithPrerequisites) | undefined;
	setSelectedCourse: any;
};

const PrerequisitesCourse: React.FC<Props> = ({
	classroom_id,
	course_id,
	control,
	name,
	prerequisitesPaths,
	setPrerequisitesPaths,
	selectedCourse,
	setSelectedCourse,
}) => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(fetchCoursesDropdown(classroom_id));
	}, []);

	return (
		<PrerequisitePaths
			classroom_id={classroom_id}
			course_id={course_id}
			control={control}
			name={name}
			prerequisitesPaths={prerequisitesPaths}
			setPrerequisitesPaths={setPrerequisitesPaths}
			selectedCourse={selectedCourse}
			setSelectedCourse={setSelectedCourse}
		/>
	);
};

export default PrerequisitesCourse;
