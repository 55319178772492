import {
	DatePickerProps,
	LocalizationProvider,
	StaticDatePicker,
} from '@mui/x-date-pickers';
import '@mui/lab';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { TextField } from '@mui/material';
import React, { FC } from 'react';
import { Controller } from 'react-hook-form';
import { RangePickerProps } from '../Pickers.types';

type Props = {
	control: any;
	name: string;
};
const ReactHookDatePicker: FC<Props & Partial<DatePickerProps<any, any>>> = ({
	control,
	name,
	...props
}) => {
	return (
		// <LocalizationProvider dateAdapter={AdapterDateFns}>
		<Controller
			control={control}
			name={name}
			render={({ field: { value, ...controlProps } }) => {
				return (
					<div>
						<StaticDatePicker
							{...props}
							value={value}
							{...controlProps}
							renderInput={(inputProps: any) => {
								return <TextField {...inputProps} />;
							}}
						/>
					</div>
				);
			}}
		/>
		// </LocalizationProvider>
	);
};

export default ReactHookDatePicker;
