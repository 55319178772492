import { RouteGroup, SingleRoute } from '../../../constants/routes';
import Instructors from '../InstructorsRoute';
import AddInstructor from '../Pages/AddInstructor/AddInstructor';
import EditInstructors from '../Pages/EditInstructor/EditInstructor';

export const AddInstructorRoute: SingleRoute = {
	code: '',
	component: <AddInstructor />,
	route: 'create/instructor',
	offMenu: true,
	title: '',
};
export const EditInstructorRoute: SingleRoute = {
	code: '',
	component: <EditInstructors />,
	route: 'edit/instructor/:id',
	offMenu: true,
	isExact: true,
	title: '',
};
export const InstructorsRouteGroup: RouteGroup = {
	code: 'v-inst',
	component: <Instructors />,
	route: 'instructors',
	title: 'Instructors',
	icon: 'assignment_ind',
	routes: [AddInstructorRoute, EditInstructorRoute],
	isRouted: true,
	isParent: true,
};
