import { Button, Modal } from '@mui/material';
import { FlexLayout, Spacer } from '@Styled/utilities';
import React from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import UnitCard from './UnitCard';
import { useTypedSelector } from '@Features/store';

type Props = {
	handleModelClose: React.Dispatch<boolean>;
	openModel: boolean;
	currentUser: any;
	currentCourse: number;
	setOpenModel: React.Dispatch<boolean>;
};

const UserProgressModel: React.FC<Props> = ({
	handleModelClose,
	openModel,
	currentUser,
	currentCourse,
	setOpenModel,
}) => {
	const { id } = useParams<{ id: string }>();

	const {
		currentEntity,
		loaders: { getSingle },
	} = useTypedSelector((state) => state.EnrolledUsersUnits);

	return (
		<>
			<Modal
				open={openModel}
				onClose={() => {
					handleModelClose(false);
				}}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<ModalContainer>
					<Spacer marginBottom="0.9rem" marginTop="2.5rem" />
					<StudentName>
						{currentUser?.first_name} {currentUser?.last_name}
					</StudentName>
					{currentEntity && getSingle === 'fulfilled' && (
						<UnitCard
							id={currentUser?.id}
							currentCourse={Number(id)}
							setOpenModel={setOpenModel}
						/>
					)}

					<FlexLayout justifyContent="end">
						<CloseButton
							color="warning"
							variant="text"
							onClick={() => handleModelClose(false)}
						>
							Close
						</CloseButton>
					</FlexLayout>
				</ModalContainer>
			</Modal>
		</>
	);
};

export const ModalContainer = styled.div`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 46.063rem;
	border-radius: 10px;
	background-color: #ffffff;
	padding: 1.625rem 1.5rem 0.5rem 1.975rem;
`;

const StudentName = styled.p`
	color: #251b33;
	font-size: 1.25rem;
	font-weight: 600;
	margin-bottom: 2.938rem;
`;

const CloseButton = styled(Button)`
	font-weight: 500;
	font-size: 0.875rem;
	margin-top: 1.9rem;
	margin-bottom: 0.4rem;
`;

export default UserProgressModel;
