import { ColorsMap } from '@Pages/ClassRooms/Pages/SingleCourse/Pages/CourseForm/Pages/CourseContent/Pages/Test/Constants/TagColors';
import { FlexLayout, Spacer } from '@Styled/utilities';
import React, { cloneElement, createElement, PropsWithChildren } from 'react';
import { Control, useController, Path } from 'react-hook-form';
import styled from 'styled-components';
import { renderToStaticMarkup, renderToString } from 'react-dom/server';
import EdIcon from '@Components/UI/Utilities/EdIcon/EdIcon';
import { Check } from '@mui/icons-material';
import { EdFormControlError } from '../EdFormControlLabel/EdFormControlLabel';

const reactSvgComponentToMarkupString = (component: any) => {
	const componentString = `data:image/svg+xml,${encodeURIComponent(
		renderToStaticMarkup(cloneElement(component))
	)}`;
	return componentString;
};

type Props<T extends object> = {
	control: any;
	name: Path<T>;
	label?: string;
};
const EdColorPicker = <T extends object>({
	control,
	name,
	label,
}: PropsWithChildren<Props<T>>) => {
	const { field, fieldState, formState } = useController({
		control,
		name,
	});

	return (
		<>
			{label && (
				<>
					<InputLabel>{label}</InputLabel>
					<Spacer mb="1rem" />
				</>
			)}

			<FlexLayout
				ref={field.ref}
				onBlur={field.onBlur}
				gridGap={'1rem'}
				flexWrap="wrap"
				justifyContent={'space-between'}
			>
				{ColorsMap.map((color, index) => {
					return (
						<ColorThumbnail
							key={`ed-color-picker-${color.name}-${index}`}
							$isSelected={field.value === color.color}
							$bgColor={color.color}
							onClick={() => field.onChange(color.color)}
						>
							<ColorThumbnailSymbol>
								<EdIcon fontSize="small">check</EdIcon>
							</ColorThumbnailSymbol>
						</ColorThumbnail>
					);
				})}
			</FlexLayout>
			<EdFormControlError error={fieldState.error?.message} />
		</>
	);
};

export default EdColorPicker;

const InputLabel = styled.div`
	font-weight: 500;
	font-size: 0.875rem;
`;

const ColorThumbnailSymbol = styled.div`
	width: 100%;
	height: 100%;
	display: grid;
	opacity: 0;
	place-items: center;
`;
const ColorThumbnail = styled.div<{
	$bgColor: string;
	$isSelected: boolean;
}>`
	width: 3.5rem;
	height: 1.875rem;
	border-radius: 5px;
	background-color: ${(props) => props.$bgColor};
	cursor: pointer;
	transition: all ease-in-out 200ms;
	filter: ${(props) => (props.$isSelected ? 'brightness(0.7)' : '')};
	:hover {
		filter: brightness(0.7);
		${ColorThumbnailSymbol} {
			opacity: ${(props) => (props.$isSelected ? '1' : '0')};
		}
	}
	position: relative;
	${ColorThumbnailSymbol} {
		opacity: ${(props) => (props.$isSelected ? '1' : '0')};
	}
`;
