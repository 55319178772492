import React from 'react';
import { useTypedSelector } from '@Features/store';
import styled from 'styled-components';
import AddSlotButton from './Components/AddSlotButton';
import { FlexLayout } from '@Styled/utilities';
import SlotCard from './Components/SlotCard';
import WebinarUnit from './Components/WebinarUnit';

const Webinars = () => {
	const {
		currentEntity,
		loaders: { getSingle },
	} = useTypedSelector((state) => state.Webinars);

	const sorted = currentEntity?.webinarSlots?.map((_) => {
		return { ..._, start_time: new Date(_.start_time) };
	});
	const sortedSlots = sorted?.sort(
		(objA, objB) => objA.start_time.getTime() - objB.start_time.getTime()
	);

	return (
		<>
			{currentEntity && getSingle === 'fulfilled' && (
				<WebinarContainer>
					<div className="wrapper">
						<WebinarUnit />
						<FlexLayout justifyContent="flex-end">
							<AddSlotButton />
						</FlexLayout>
						<div>
							{sortedSlots?.map((_, index) => {
								return <SlotCard slot={_} index={index} />;
							})}
						</div>
					</div>
				</WebinarContainer>
			)}
		</>
	);
};

export const WebinarContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 2.563rem;
	.wrapper {
		display: flex;
		flex-direction: column;
		gap: 5.625rem;
		.webinar-style {
			display: flex;
			align-items: center;
			gap: 5rem;
			color: #251b33;
			font-size: 1rem;
			font-weight: 500;
		}
		.webinar {
			display: flex;
			align-items: center;
			gap: 0.875rem;
		}
	}
`;

export default Webinars;
