import { BaseSliceController } from '@Base/slices/slices.base';
import { AssignmentRequester } from '../Services/Assignment.requester';
import { Assignments } from '../Types/Assignment.types';

export class AssignmentsSlice extends BaseSliceController<
	Assignments,
	'Assignments',
	Assignments
> {
	private static instance: AssignmentsSlice;
	static getInstance() {
		if (!AssignmentsSlice.instance) {
			AssignmentsSlice.instance = new AssignmentsSlice();
		}
		return AssignmentsSlice.instance;
	}
	constructor() {
		super('Assignments', AssignmentRequester.getInstance());
	}
}

export const {
	assignmentsCreate,
	assignmentsGetSingle,
	assignmentsUpdate,
} = AssignmentsSlice.getInstance().getActions();
