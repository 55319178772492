import api from '@Services/api';
import { AxiosResponse } from 'axios';
import {
	CRUDBase,
	DeleteSingleEntityPayload,
	GetAllMeta,
	GetSingleEntityPayload,
} from 'src/base/services/services.base';
import { FlattenAdminForm } from '../Schema/AdminsInfo.schema';
import { AdminResponse } from '../Types/admin.types';
import {
	AdminsResponse,
	DeleteResponse,
	CreateResponse,
	UpdateResponse,
	UpdateAdminPermissionsResponse,
	SingleAdminResponse,
} from './Admins.res.types';

interface getReqType {
	page: number;
	perPage: number;
	sortBy?: any;
	filters: Array<any>;
	query?: string;
	from?: Date;
	to?: Date;
}

export class AdminRequester
	implements CRUDBase<FlattenAdminForm, AdminResponse>
{
	baseURL?: string | undefined;
	getAll(
		payload?: GetAllMeta
	): Promise<AxiosResponse<ApiResponse<AdminResponse[]>>>;
	getAll(payload: number): Promise<AxiosResponse<ApiResponse<AdminResponse[]>>>;
	async getAll(
		payload: any
	): Promise<AxiosResponse<ApiResponse<AdminResponse[]>>> {
		return await api.post('admin/all', payload);
	}
	private static instance: AdminRequester = new AdminRequester();
	static getInstance(): AdminRequester {
		if (AdminRequester.instance) {
			AdminRequester.instance = new AdminRequester();
		}
		return AdminRequester.instance;
	}

	get(id: string): Promise<AxiosResponse<ApiResponse<AdminResponse>>>;
	get(id: number): Promise<AxiosResponse<ApiResponse<AdminResponse>>>;
	get(
		id: any,
		payload: GetSingleEntityPayload
	): Promise<AxiosResponse<ApiResponse<AdminResponse>>>;
	async get(
		id: string | number,
		payload?: any
	): Promise<AxiosResponse<ApiResponse<AdminResponse>>> {
		return await api.post('admin', payload);
	}

	async create(
		payload: FlattenAdminForm
	): Promise<AxiosResponse<ApiResponse<AdminResponse>>> {
		return await api.post('admin/create', payload);
	}
	async update(
		payload: FlattenAdminForm
	): Promise<AxiosResponse<ApiResponse<AdminResponse>>> {
		return await api.put('admin/update', payload);
	}

	delete(id: number): Promise<AxiosResponse<ApiResponse<any>>>;
	delete(id: string): Promise<AxiosResponse<ApiResponse<any>>>;
	delete(
		id: string | number,
		payload: DeleteSingleEntityPayload
	): Promise<AxiosResponse<ApiResponse<any>>>;
	async delete(
		id: any,
		payload?: any
	): Promise<AxiosResponse<ApiResponse<any>>> {
		return await api.delete('admin/delete', {
			data: payload,
		});
	}
}

export const adminsGetRequest = async (
	page: number,
	perPage: number,
	filters: any,
	sortBy: any,
	query?: string,
	from?: Date,
	to?: Date
) => {
	const data: getReqType = { page, perPage, filters, query, from, to };
	if (Object.keys(sortBy).length !== 0) data.sortBy = sortBy;

	return await api.post<AdminsResponse>('all', data);
};
export const deleteAdminRequest = async (uuid: string) =>
	await api.delete<DeleteResponse>('delete', {
		data: { uuid },
	});

export const createAdminRequest = async (
	first_name: string,
	last_name: string,
	type: string,
	email: string,
	phone_number: string,
	password: string,
	permissions: Array<number>
) =>
	await api.post<CreateResponse>('create', {
		first_name,
		last_name,
		type,
		email,
		phone_number,
		password,
		permissions,
	});

export const updateAdminRequest = async (
	uuid: string,
	first_name: string,
	last_name: string,
	email: string,
	phone_number: string,

	gender: string,
	password?: string
) =>
	await api.put<UpdateResponse>('update', {
		uuid,
		first_name,
		last_name,
		email,
		phone_number,
		password,
		gender,
	});

export const updateAdminPermissionsRequest = async (
	uuid: string | undefined,
	permissions: number[]
) =>
	await api.post<UpdateAdminPermissionsResponse>('permissions', {
		uuid,
		permissions,
	});

export const adminGetRequest = async (uuid: string | undefined) =>
	await api.post<SingleAdminResponse>('', {
		uuid,
	});
