import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

type SortBy = {
	direction: string | null;
	field: string | null;
};

function useSortByURL() {
	let usp = new URLSearchParams(document.location.search);
	let { search } = useLocation();
	const [selectedSortBy, setSelectedSortBy] = useState<SortBy | undefined>(
		undefined
	);

	useEffect(() => {
		const direction = usp.get('direction');
		const field = usp.get('field');
		setSelectedSortBy({
			...selectedSortBy,
			direction: direction,
			field: field,
		});
	}, [search]);

	return {
		selectedSortBy,
	};
}

export default useSortByURL;
