import { BaseSliceController } from '@Base/slices/slices.base';
import { PromoCodesRequester } from '../Services/PromoCodes.req';
import { PromoCode, PromoCodesUsage } from '../Types/PromoCodes.types';
import { PromoCodesUsageRequester } from '../Services/PromoCodesUsage.req';

export class PromoCodesUsageSlice extends BaseSliceController<
	PromoCodesUsage,
	'PromoCodesUsage'
> {
	private static instance: PromoCodesUsageSlice;
	static getInstance() {
		if (!PromoCodesUsageSlice.instance) {
			PromoCodesUsageSlice.instance = new PromoCodesUsageSlice();
		}
		return PromoCodesUsageSlice.instance;
	}
	constructor() {
		super('PromoCodesUsage', PromoCodesUsageRequester.getInstance());
	}
}

export const { promoCodesUsageGetAll } = PromoCodesUsageSlice.getInstance().getActions();
