import { Button, ButtonGroup, Popover } from '@mui/material';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

enum DateRangeOptions {
	'last 2 weeks' = 'last 2 weeks',
	'last month' = 'last month',
	'last 3 months' = 'last 3 months',
	'custom' = 'custom',
}

export type DateRangeOption = keyof typeof DateRangeOptions;

interface DateRangePickerPopupComposition {
	Body: React.FC;
}
type Props = {
	children?: JSX.Element | JSX.Element[] | undefined;
	open: boolean;
	anchorEl?: HTMLElement;
	onClose: (event: Event) => void;
	onChange?: (type: DateRangeOption) => void;
	customComponent?: JSX.Element;
	rangeOptionValue?: DateRangeOption;
};
const DateRangePickerPopupBody: React.FC = ({ children }) => <>{children}</>;
const DateRangePickerPopup: React.FC<Props> &
	DateRangePickerPopupComposition = ({
	children,
	onClose,
	open,
	anchorEl,
	onChange,
	rangeOptionValue = 'last 2 weeks',
}) => {
	const [rangeOption, setRangeOption] = useState<DateRangeOption>(
		rangeOptionValue ?? 'last 2 weeks'
	);

	return (
		<div>
			<Popover
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				anchorEl={anchorEl}
				open={open}
				onClose={onClose}
				disablePortal={true}
				keepMounted
			>
				<PopupWrapper>
					<ButtonGroup fullWidth>
						{Object.values(DateRangeOptions).map((key, index) => {
							return (
								<Button
									variant={key === rangeOptionValue ? 'contained' : 'outlined'}
									onClick={() => {
										onChange && onChange(key);
									}}
									key={`${key}-${index}`}
								>
									{key}
								</Button>
							);
						})}
					</ButtonGroup>
					{rangeOptionValue === 'custom' && children}
				</PopupWrapper>
			</Popover>
		</div>
	);
};

DateRangePickerPopup.Body = DateRangePickerPopupBody;
export default DateRangePickerPopup;

const PopupWrapper = styled.div`
	/* min-height: 20vh; */
	padding: 1rem;
	min-width: 640px;
`;
