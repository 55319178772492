import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import AddAssignment from './Pages/AddAssignment/AddAssignment';
import EditAssignment from './Pages/EditAssignment/EditAssignment';

const Assignment = () => {
	const { path } = useRouteMatch();

	return (
		<>
			<React.Suspense fallback={<div>loading</div>}>
				<Switch>
					<Route path={`${path}`} exact>
						<AddAssignment />
					</Route>
					<Route path={`${path}/:id`} exact>
						<EditAssignment />
					</Route>
				</Switch>
			</React.Suspense>
		</>
	);
};

export default Assignment;
