import api from '@Services/api';
import { LoginAsStudentResponse } from '@Types/user';
import { AxiosResponse } from 'axios';

interface ILoginAs {
	loginAs(
		uuid: string
	): Promise<AxiosResponse<ApiResponse<LoginAsStudentResponse>>>;
}
export class LoginAsRequester implements ILoginAs {
	baseURL = 'user';
	private static instance: LoginAsRequester;

	static getInstance() {
		if (!LoginAsRequester.instance) {
			LoginAsRequester.instance = new LoginAsRequester();
		}
		return LoginAsRequester.instance;
	}
	async loginAs(
		uuid: string
	): Promise<AxiosResponse<ApiResponse<LoginAsStudentResponse>>> {
		return await api.post(`${this.baseURL}/loginas`, { uuid });
	}
}
