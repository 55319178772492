import { FlexLayout, GridLayout } from '@Styled/utilities';
import React, { FC } from 'react';
import styled from 'styled-components';
import { Report } from '../../../Types/TestReports.types';

type Props = {
	report: Report | undefined;
};

const ReportCards: FC<Props> = ({ report }) => {
	const average = () => {
		if (!report?.average_score) return 0;
		return (report?.average_score * 100).toFixed(1);
	};

	return (
		<Container>
			<Grid gridGap="0.5rem">
				<FlexLayout flexDirection="column" gridGap="0.5rem">
					<TotalTest>
						<p>Total Test Attempts</p>
						<span>{report?.attempts_count}</span>
					</TotalTest>
					<FlexLayout gridGap="0.5rem">
						<TotalStudent>
							<p>Total Test Students</p>
							<span>{report?.student_count}</span>
						</TotalStudent>
						<TotalPassed>
							<p>Total Passed Students</p>
							<span>{report?.passed_student_count}</span>
						</TotalPassed>
					</FlexLayout>
				</FlexLayout>
				<Average>
					<span>{average()}%</span>
					<p>Average Score</p>
				</Average>
			</Grid>
		</Container>
	);
};

const Container = styled.div`
	margin-bottom: 3.25rem;
`;
const Grid = styled(GridLayout)`
	grid-template-columns: 74% 26%;
`;
const TotalTest = styled(FlexLayout)`
	background: RGBA(90, 192, 252, 0.1);
	padding: 0 2.813rem 0 1.313rem;
	height: 5.063rem;
	border-radius: 5px;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
	width: auto;
	p {
		color: #5ac0fc;
		font-size: 1.25rem;
		font-weight: bold;
	}
	span {
		color: #251b33;
		font-size: 1.75rem;
		font-weight: 600;
	}
`;
const TotalStudent = styled(FlexLayout)`
	background: rgba(108, 99, 255, 0.1);
	padding: 0 2.813rem 0 1.313rem;
	height: 5.063rem;
	border-radius: 5px;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
	width: 100%;
	p {
		color: #6c63ff;
		font-size: 1.25rem;
		font-weight: bold;
	}
	span {
		color: #251b33;
		font-size: 1.75rem;
		font-weight: 600;
	}
`;
const TotalPassed = styled(FlexLayout)`
	background: RGBA(0, 214, 107, 0.1);
	padding: 0 1.25rem;
	height: 5.063rem;
	border-radius: 5px;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
	width: 100%;
	p {
		color: #00d66b;
		font-size: 1.25rem;
		font-weight: bold;
	}
	span {
		color: #251b33;
		font-size: 1.75rem;
		font-weight: 600;
	}
`;
const Average = styled(FlexLayout)`
	background: RGBA(37, 27, 51, 0.1);
	flex-direction: column;
	justify-content: center !important;
	padding: 0 1.625rem;
	height: 100%;
	border-radius: 5px;
	align-items: center;
	flex-wrap: wrap;
	gap: 1rem;
	p {
		color: #251b33;
		font-size: 1rem;
		font-weight: bold;
	}
	span {
		color: #251b33;
		font-size: 2rem;
		font-weight: 600;
		margin-bottom: -15px;
	}
`;
export default ReportCards;
