import DateTimeFormatter from '@Components/DateTimeFormatter/DateTimeFormatter';
import MainTable from '@Components/MainTable';
import { MainTableRow } from '@Components/MainTable/MainTable';
import { onPageChangeRequestMeta } from '@Components/MainTable/MainTable.types';
import NoResults from '@Components/NoResults/NoResults';
import RoundButton from '@Components/UI/Buttons/RoundButton';
import EdConfirmModal from '@Components/UI/Modals/EdConfirmModal/EdConfirmModal';
import EdActiveStatus from '@Components/UI/Utilities/EdActiveStatus/EdActiveStatus';
import EdEmptyValue from '@Components/UI/Utilities/EdEmptyValue/EdEmptyValue';
import EdIcon from '@Components/UI/Utilities/EdIcon/EdIcon';
import EdSpeedDial, {
	EdSpeedDialAction,
} from '@Components/UI/Utilities/EdSpeedDial/EdSpeedDial';
import { useTypedSelector } from '@Features/store';
import { Button, TableCell, Tooltip } from '@mui/material';
import DeleteModule from '@Pages/ScratchCards/components/DeleteModeule';
import GenerateCodes from '@Pages/ScratchCards/components/GenerateCode';
import {
	scratchCardsDeleteById,
	scratchCardsExportData,
	scratchCardsGetAll,
} from '@Pages/ScratchCards/Slices/ScratchCards.slice';
import { useSnackbar } from '@Providers/useSnackbar';
import { FlexLayout } from '@Styled/utilities';
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

const TABLE_HEADS: Array<TableHeader> = [
	{ displayName: 'SERIAL', fieldName: 'serial', canSort: true },
	{ displayName: 'CODE', fieldName: 'code', canSort: true },
	{ displayName: 'BATCH', fieldName: 'batch' },
	{ displayName: 'CLASSROOM ', fieldName: 'title' },
	{ displayName: 'COURSE ', fieldName: 'name' },
	{ displayName: 'INSTRUCTOR ', fieldName: 'instructor' },
	{ displayName: 'VALUE ', fieldName: 'value' },
	{ displayName: 'USED', fieldName: 'user_id', canSort: false },
	{ displayName: 'USED AT', fieldName: 'updated_at', width: '12%' },
	{ displayName: 'EMAIL', fieldName: 'email', width: '10%' },
	{ displayName: 'CREATED AT', fieldName: 'created_at', width: '12%' },
];
const ScratchCardsTable = () => {
	const dispatch = useDispatch();
	const onPageRequestChange = useCallback(
		({
			activePage,
			endDate,
			filters,
			query,
			sortBy,
			startDate,
			perPage,
			export: exportData,
		}: onPageChangeRequestMeta) => {
			dispatch(
				scratchCardsGetAll({
					page: activePage,
					filters: filters,
					perPage: perPage ?? 10,
					query,
					export: exportData ?? false,
					sortBy: sortBy,
					from: startDate,
					to: endDate,
				})
			);
		},
		[dispatch]
	);

	const { fetchAll, filters, meta, selectedFilters, dateRange, query, sortBy } =
		useTypedSelector((state) => state.ScratchCards);
	const auth = useTypedSelector((state) => state.auth);

	const { dropdownClassrooms } = useTypedSelector((state) => state.dropdowns);

	const [openDeleteModel, setOpenDeleteModel] = useState(false);
	const [openModel, setOpenModel] = useState(false);
	const [toBeDeleted, setToBeDelete] = useState<number | undefined>();

	const handleDeleteModelOpen = () => {
		setOpenDeleteModel(true);
	};
	const handleModelClose = () => {
		setOpenModel(false);
	};
	const handleDeleteModelClose = () => {
		setOpenDeleteModel(false);
	};

	const handleDeleteClick = (id?: number) => {
		setOpenConfirmModal(true);
		setToBeDelete(id);
	};

	const [openConfirmModal, setOpenConfirmModal] = useState(false);
	const { displaySnackbar } = useSnackbar();

	const handleCloseDeleteModal = (close: boolean, confirm?: boolean) => {
		setOpenConfirmModal(close);
		if (confirm && toBeDeleted) {
			try {
				dispatch(scratchCardsDeleteById(toBeDeleted));
				displaySnackbar('success', 'Scratch card deleted successfully');
			} catch (error) {}
		}
		setToBeDelete(undefined);
	};
	return (
		<div>
			<GenerateCodes
				classRooms={dropdownClassrooms}
				openModel={openModel}
				handleModelClose={handleModelClose}
				onConfirmation={handleModelClose}
			/>
			<DeleteModule
				onConfirmation={handleDeleteModelClose}
				openModel={openDeleteModel}
				handleModelClose={handleDeleteModelClose}
			/>

			<EdConfirmModal
				open={openConfirmModal}
				handleClose={handleCloseDeleteModal}
				text={'Are you sure to delete scratch card ?'}
			/>
			<FlexLayout justifyContent="flex-end">
				{(auth.user.type === 'super' || auth.permissions.has('exp-schc')) && (
					<RoundButton
						onClick={() => {
							dispatch(
								scratchCardsExportData({
									filters: selectedFilters,
									page: meta?.current_page ?? 1,
									perPage: meta?.per_page ?? 10,
									query: query ?? '',
									sortBy,
									from: dateRange.from,
									to: dateRange.to,
								})
							);
						}}
						sx={{ marginRight: '1rem' }}
						startIcon={<EdIcon>south</EdIcon>}
						variant="contained"
					>
						Export
					</RoundButton>
				)}
				{(auth.user.type === 'super' || auth.permissions.has('gen-schc')) && (
					<RoundButton
						edcolor="purple"
						startIcon={<EdIcon>add</EdIcon>}
						variant="contained"
						onClick={() => setOpenModel(true)}
					>
						Generate codes
					</RoundButton>
				)}
			</FlexLayout>
			<MainTable
				tableFilters={filters}
				tableHeads={TABLE_HEADS}
				totalPages={meta?.last_page ?? 0}
				onPageChangeRequest={onPageRequestChange}
				RowsperPage={meta?.per_page}
				$hasActions
				pageNum={meta?.current_page}
				total={meta?.total}
				renderItems={() => {
					if (fetchAll.length === 0) {
						return (
							<MainTableRow>
								<TableCell colSpan={TABLE_HEADS.length}>
									<NoResults />
								</TableCell>
							</MainTableRow>
						);
					} else {
						return fetchAll.map((code) => {
							return (
								<MainTableRow>
									<Tooltip title={code.serial} placement="top">
										<TableCell>{code.serial}</TableCell>
									</Tooltip>
									<Tooltip title={code.code} placement="top">
										<TableCell>{code.code}</TableCell>
									</Tooltip>
									<TableCell>{code.batch}</TableCell>
									<Tooltip title={code.title} placement="top">
										<TableCell>{code.title}</TableCell>
									</Tooltip>
									<TableCell>{code.name ?? '-'}</TableCell>
									<TableCell>{code.instructor_label ?? '-'}</TableCell>
									<TableCell>{code.value ?? '-'}</TableCell>
									<TableCell>
										<EdActiveStatus
											active={code.user_id !== null ? true : false}
										/>
									</TableCell>
									<Tooltip
										title={<DateTimeFormatter date={code.updated_at} />}
										placement="top"
									>
										<TableCell>
											{code.user_id !== null ? (
												<DateTimeFormatter date={code.updated_at} />
											) : (
												'-'
											)}
										</TableCell>
									</Tooltip>
									<TableCell>
										<EdEmptyValue value={code.email} />
									</TableCell>
									<Tooltip
										title={<DateTimeFormatter date={code.created_at} />}
										placement="top"
									>
										<TableCell>
											<DateTimeFormatter date={code.created_at} />
										</TableCell>
									</Tooltip>
									<TableCell>
										{(auth.user.type === 'super' ||
											auth.permissions.has('d-schc')) && (
											<EdSpeedDial ariaLabel="Actions">
												<EdSpeedDialAction
													onClick={() => handleDeleteClick(code.id)}
													key="delete"
													tooltipTitle="Delete"
													icon={<EdIcon>delete</EdIcon>}
													aria-label=""
												/>
											</EdSpeedDial>
										)}
									</TableCell>
								</MainTableRow>
							);
						});
					}
				}}
			/>
			{(auth.user.type === 'super' || auth.permissions.has('d-schc')) && (
				<FlexLayout justifyContent="flex-end">
					<Button
						variant="contained"
						color="warning"
						onClick={handleDeleteModelOpen}
					>
						Delete Codes
					</Button>
				</FlexLayout>
			)}
		</div>
	);
};

export default ScratchCardsTable;
