import { yupResolver } from '@hookform/resolvers/yup';
import { Button, TextField } from '@mui/material';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { EDIT_TAG_SCHEMA } from '../../Schema/Tag.schema';
import { Tag, HexCode } from '../../Types/Tags';
import { useParams } from 'react-router-dom';
import { useAppThunkDispatch, useTypedSelector } from '@Features/store';
import { tagsGetSingle, tagsUpdate } from '../../Slice/Tags/TagsSlice';
import EdColorPicker from '@Components/UI/Inputs/EdColorPicker/EdColorPicker';
import styled from 'styled-components';
import { DevTool } from '@hookform/devtools';
import { FlexLayout, Spacer } from '@Styled/utilities';
import EdButton from '@Components/UI/Buttons/EdButton/EdButton';
import { useGotoParentRoute } from '@Providers/Routes/RoutesProvider';
import { useSnackbar } from '@Providers/useSnackbar';
import { EdInputLabel } from '@Components/UI/Inputs/EdFormControlLabel/EdFormControlLabel';
const TagForm = () => {
	const { id } = useParams<{ id: string }>();
	const dispatch = useAppThunkDispatch();

	const {
		currentEntity,
		loaders: { getSingle },
	} = useTypedSelector((state) => state.Tags);

	useEffect(() => {
		dispatch(tagsGetSingle({ id }));
	}, []);

	return <>{currentEntity && getSingle === 'fulfilled' && <TagFormInfo />}</>;
};

export default TagForm;

const TagFormInfo = () => {
	const { currentEntity } = useTypedSelector((state) => state.Tags);
	const {
		control,
		formState: { isDirty, isValid },
		handleSubmit,
	} = useForm<Tag>({
		mode: 'all',
		resolver: yupResolver(EDIT_TAG_SCHEMA),
		defaultValues: {
			id: currentEntity.id,
			name: currentEntity.name,
			theme: currentEntity?.theme as HexCode,
		},
	});

	const goToParent = useGotoParentRoute();
	const dispatch = useAppThunkDispatch();
	const { displaySnackbar } = useSnackbar();

	const onSave = async (tag: Tag) => {
		try {
			await dispatch(tagsUpdate(tag)).unwrap();
			displaySnackbar('success', `Updated Tag successfully`);
			goToParent();
		} catch (error) {
			displaySnackbar('error', "Couldn't update tag");
		}
	};
	return (
		<FormWrapper>
			<DevTool placement="top-left" control={control} />
			<Controller
				control={control}
				name="name"
				render={({ field }) => {
					return (
						<>
							<EdInputLabel>Name</EdInputLabel>
							<Spacer mb="1rem" />
							<TextField size="small" {...field} />
						</>
					);
				}}
			/>
			<Spacer mb="1.563rem" />
			<EdColorPicker label="Select a color" control={control} name="theme" />
			<Spacer mb="1.563rem" />
			<FlexLayout
				gridGap="1rem"
				alignItems={'center'}
				width="100%"
				justifyContent={'flex-end'}
			>
				<Button onClick={() => goToParent()} color="warning">
					Cancel
				</Button>
				<EdButton
					onClick={handleSubmit(onSave)}
					disabled={!isValid || !isDirty}
					edcolor="purple"
				>
					Save
				</EdButton>
			</FlexLayout>
		</FormWrapper>
	);
};
const FormWrapper = styled.div`
	padding: 1.094rem 1.563rem;
`;
