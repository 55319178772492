import { number, object, string } from 'yup';
import { Flatten } from '@Utils/types/Flatten';

export const ADD_CATEGORY = object({
	parent_id: number()
		.transform((v, o) => {
			if (!v) return null;
			return o;
		})
		.nullable(true),
	name: string().required(),
	code: string().required(),
	icon: string().required(),
});

export const EDIT_CATEGORY = object({
	id: number().required(),
}).concat(ADD_CATEGORY);

export const FULL_SCHEMA = ADD_CATEGORY.concat(EDIT_CATEGORY);
export type AddCategoryForm = typeof ADD_CATEGORY.__outputType;
export type EditCategoryForm = typeof EDIT_CATEGORY.__outputType;

export type FlattenCategoryForm = Flatten<AddCategoryForm>;
