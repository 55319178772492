import { array, boolean, number, object, string } from 'yup';

const emailValidation =
	/^(([^<>()[\]\\.,;:\s@"]+(\.[^ <>()[\]\\., ;: \s @"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const phoneValidation = /^01[0-2,5]{1}[0-9]{8}$/;

export const INFO_SCHEMA = object({
	first_name: string().required(),
	last_name: string().required(),
	username: string().required(),
	birth_date: string().nullable(true),
	email: string()
		.required()
		.matches(emailValidation, 'E-mail is not valid')
		.required(),
	gender: string().nullable(true),
	email_verified: boolean().notRequired(),
	phone_verified: boolean().notRequired(),
	student: object({
		education_type_id: number().min(1, 'Field Required').nullable(true),
		education_year_id: number().min(1, 'Field Required').nullable(true),
		education_language_id: number().min(1, 'Field Required').nullable(true),
		education_section_id: number().nullable(true),
		profile_complete: boolean().notRequired(),
		registration_paid: boolean().notRequired(),
		// parent_phone: string().matches(phoneValidation, "must be 01xxxxxxxx"),
		parent_phone: string()
			.matches(phoneValidation, 'must be 01xxxxxxxx')
			.nullable(true)
			.transform((v, o) => (v === '' ? null : v)),
		parent_relation: string().nullable(true),
		school: string().nullable(true),
		address: object({
			governorate: number()
				.required('Field Required')
				.typeError('Field Required'),
			city: number().nullable(true),
		}),
	}),
	phone_number: string()
		.matches(phoneValidation, 'must be 01xxxxxxxx')
		.required(),
});
