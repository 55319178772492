import { useTypedSelector } from '@Features/store';
import { adminsGetSingle } from '@Pages/Admin/Slice/AdminSlice';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import EditAdminBasicInfo from './EditAdminBasicInfo';

const EditAdmin = () => {
	const {
		currentEntity,
		loaders: { getSingle },
	} = useTypedSelector((state) => state.Admins);
	const dispatch = useDispatch();
	const { id: uuid } = useParams<{ id: string }>();

	useEffect(() => {
		if (uuid) {
			dispatch(adminsGetSingle({ id: uuid }));
		}
	}, []);

	return (
		<Container>
			{currentEntity && getSingle === 'fulfilled' && <EditAdminBasicInfo />}
		</Container>
	);
};

const Container = styled.div`
	padding: 1.375rem;
	border-radius: 10px;
	border: 1px solid #5ac0fc;
`;
export default EditAdmin;
