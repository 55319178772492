import React, { useRef } from 'react';
import { FlexLayout, Spacer } from '@Styled/utilities';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Content } from '../../Types/WebContent.types';
import { CONTENT_SCHEMA } from '../../Schema/webContent.schema';
import {
	Button,
	ButtonGroup,
	Grow,
	InputAdornment,
	MenuItem,
	MenuList,
	Paper,
	Popper,
} from '@mui/material';
import { WebContentRequester } from '../../Services/WebContent.requester';
import { useSnackbar } from '@Providers/useSnackbar';
import { useHistory } from 'react-router-dom';
import { useTypedSelector } from '@Features/store';
import EdFormControl from '@Components/UI/Inputs/EdFormControl/EdFormControl';
import { ContentConfig } from '../../../../Constants/CourseContent.constants';
import { DevTool } from '@hookform/devtools';
import TinyMceControl from '@Components/UI/TinyMceControl/TinyMceControl';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import styled from 'styled-components';

const AddWebContent = () => {
	const editorRef = useRef<any>(null);
	const { displaySnackbar } = useSnackbar();
	const history = useHistory();
	const { currentCourse } = useTypedSelector((state) => state.classroom);
	const { control, formState, getValues, reset } = useForm<Content>({
		mode: 'all',
		resolver: yupResolver(CONTENT_SCHEMA),
		defaultValues: {
			unit: {
				type_id: 4,
				course_id: currentCourse?.id,
			},
		},
	});

	const [open, setOpen] = React.useState(false);
	const anchorRef = React.useRef<HTMLDivElement>(null);

	const handleToggle = () => {
		setOpen((prevOpen) => !prevOpen);
	};

	const handleClose = (event: Event) => {
		if (anchorRef.current?.contains(event.target as HTMLElement)) {
			return;
		}
		setOpen(false);
	};

	const onSave = async (form: Content) => {
		try {
			const {
				data: { data },
			} = await WebContentRequester.instance.create({
				...form,
			});
			displaySnackbar('success', 'Created Web Content successfully');
			history.goBack();
		} catch (e) {
			const msg = e.response?.data.message || 'Unable to Create Web Content ';
			displaySnackbar('error', msg);
		}
	};

	const handleReset = () => {
		reset({
			unit: {
				type_id: 4,
				course_id: currentCourse?.id,
				name: '',
			},
			webContent: {
				content: '',
			},
		});
	};

	const onSaveAddAnother = async (form: Content) => {
		try {
			const {
				data: { data },
			} = await WebContentRequester.instance.create({
				...form,
			});
			displaySnackbar('success', 'Created Web Content successfully');
			handleReset();
		} catch (e) {
			const msg = e.response?.data.message || 'Unable to Create Web Content ';
			displaySnackbar('error', msg);
		}
	};

	return (
		<FlexLayout flexDirection="column" px="2.625rem" flex="1">
			<DevTool control={control} />
			<EdFormControl
				size="small"
				InputProps={{
					startAdornment: (
						<InputAdornment position="start">
							{ContentConfig['webcontent'].icon}
						</InputAdornment>
					),
				}}
				control={control}
				label="Unit Name"
				name="unit.name"
			/>
			<Spacer mb="1.625rem" />

			<FlexLayout flex="1">
				<Controller
					control={control}
					name="webContent.content"
					render={({ field: { value, onChange, ...props } }) => {
						return (
							<TinyMceControl
								{...props}
								value={value}
								onEditorChange={(e) => onChange(e)}
								onInit={(evt, editor) => (editorRef.current = editor)}
							/>
						);
					}}
				/>
			</FlexLayout>
			<FlexLayout mt="2.688rem" mb="5.688rem" justifyContent="flex-end">
				<Button
					variant="contained"
					color="warning"
					onClick={() => history.goBack()}
				>
					Cancel
				</Button>
				<Spacer mx="0.5rem" />
				<ButtonGroup
					variant="contained"
					ref={anchorRef}
					aria-label="split button"
				>
					<Button
						onClick={() => {
							const data = getValues();
							onSave(data);
						}}
						disabled={!formState.isDirty || !formState.isValid}
						variant="contained"
					>
						Save
					</Button>
					<DropButton
						size="small"
						aria-controls={open ? 'split-button-menu' : undefined}
						aria-expanded={open ? 'true' : undefined}
						onClick={handleToggle}
						variant="contained"
						disabled={!formState.isDirty || !formState.isValid}
					>
						<ArrowDropDownIcon />
					</DropButton>
				</ButtonGroup>
				<Popper
					sx={{
						zIndex: 1,
					}}
					open={open}
					anchorEl={anchorRef.current}
					role={undefined}
					transition
					disablePortal
				>
					{({ TransitionProps, placement }) => (
						<Grow
							{...TransitionProps}
							style={{
								transformOrigin:
									placement === 'bottom' ? 'center top' : 'center bottom',
							}}
						>
							<Paper>
								<ClickAwayListener onClickAway={handleClose}>
									<MenuList id="split-button-menu" autoFocusItem>
										<MenuItem
											key={1}
											onClick={() => {
												const data = getValues();
												onSaveAddAnother(data);
											}}
										>
											Save and add another
										</MenuItem>
									</MenuList>
								</ClickAwayListener>
							</Paper>
						</Grow>
					)}
				</Popper>
			</FlexLayout>
		</FlexLayout>
	);
};

export const DropButton = styled(Button)`
	min-width: 30px !important;
	padding: 0;
`;

export default AddWebContent;
