import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
	NewsFeedState,
	NewsFeedPayload,
	UpdatePayload,
	DeletePayload,
} from './NewsFeedSlice.Types';
import {
	CreateNewsFeedResponse,
	DeleteNewsFeedResponse,
	NewsFeedResponse,
	NewsFeedsResponse,
	UpdateNewsFeedResponse,
} from '../Services/NewsFeed.res.types';
import {
	deleteNewsFeedRequest,
	newsFeedsGetRequest,
	updateNewsFeedRequest,
	NewsFeedRequester,
} from '../Services/NewsFeed.req';
import { NewsFeedsRes } from '../Types/NewsFeed.types';

const columnHeadToField: any = {
	content: 'content',
	redirection_url: 'redirection_url',
};

export const getNewsFeeds = createAsyncThunk<
	NewsFeedsResponse,
	NewsFeedPayload,
	{ rejectValue: string }
>(
	'newsFeeds/getNewsFeeds',
	async ({ page, perPage, filters, sortBy, query, from, to }, thunkApi) => {
		try {
			const { data } = await newsFeedsGetRequest(
				page,
				perPage,
				filters,
				sortBy,
				query,
				from,
				to
			);

			return data;
		} catch (err) {
			const msg = err.response?.data.message || 'failed';
			return thunkApi.rejectWithValue(msg);
		}
	}
);

export const updateFeed = createAsyncThunk<
	UpdateNewsFeedResponse,
	UpdatePayload,
	{ rejectValue: string }
>(
	'newsFeeds/updateFeed',
	async (
		{ id, content, description, photo, redirection_url, is_active },
		thunkApi
	) => {
		try {
			const { data } = await updateNewsFeedRequest(
				id,
				content,
				description,
				photo,
				redirection_url,
				is_active
			);
			return data;
		} catch (err) {
			const msg = err.response?.data.message || 'failed';
			return thunkApi.rejectWithValue(msg);
		}
	}
);

export const deleteFeed = createAsyncThunk<
	DeleteNewsFeedResponse,
	DeletePayload,
	{ rejectValue: string }
>('newsFeeds/deleteFeed', async ({ id }, thunkApi) => {
	try {
		const { data } = await deleteNewsFeedRequest(id);
		return { ...data, id };
	} catch (err) {
		const msg = err.response?.data.message || 'failed';
		return thunkApi.rejectWithValue(msg);
	}
});

export const getSingleNewsFeed = createAsyncThunk<
	ApiResponse<NewsFeedsRes>,
	{ id: number },
	{ rejectValue: string }
>('/newsfeed', async ({ id }) => {
	const { data } = await NewsFeedRequester.getInstance().get(id, { id });
	return data;
});

const initialState: NewsFeedState = {
	newsfeeds: [],
	lastPage: 0,
	currentFeed: null,
	total: 0,
	page: 0,
	perPage: 0,
	feedsFilter: [],
	loaders: {
		fetchAll: null,
		addFeed: null,
		updateFeed: null,
		getFeed: null,
		deleteFeed: null,
	},
	errors: {
		fetchAll: '',
		addFeed: '',
		updateFeed: '',
		getFeed: '',
		deleteFeed: '',
	},
};

const NewsFeedSlice = createSlice({
	name: 'newsFeeds',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(getNewsFeeds.pending, (state) => {
				state.loaders.fetchAll = true;
				state.errors.fetchAll = '';
			})
			.addCase(
				getNewsFeeds.fulfilled,
				(state, { payload: { data, meta, message, filters } }) => {
					state.newsfeeds = data;
					state.lastPage = meta?.last_page;
					state.total = meta?.total;
					state.page = meta?.current_page;
					state.perPage = meta?.per_page;
					state.feedsFilter = filters || [];
					state.loaders.fetchAll = false;
					state.currentFeed = null;
				}
			)
			.addCase(getNewsFeeds.rejected, (state, action) => {
				state.errors.fetchAll = action.payload;
				state.loaders.fetchAll = undefined;
			});
		builder
			.addCase(updateFeed.pending, (state) => {
				state.loaders.updateFeed = true;
				state.errors.updateFeed = '';
			})
			.addCase(updateFeed.fulfilled, (state, { payload }) => {
				state.loaders.updateFeed = false;
			})
			.addCase(updateFeed.rejected, (state, action) => {
				state.errors.updateFeed = action.payload;
				state.loaders.updateFeed = undefined;
			});

		builder
			.addCase(deleteFeed.pending, (state) => {
				state.loaders.deleteFeed = true;
				state.errors.deleteFeed = '';
			})
			.addCase(deleteFeed.fulfilled, (state, { payload }) => {
				state.newsfeeds = state.newsfeeds.filter(
					(feed) => feed.id !== payload?.id
				);
				state.loaders.deleteFeed = false;
			})
			.addCase(deleteFeed.rejected, (state, action) => {
				state.errors.deleteFeed = action.payload;
				state.loaders.deleteFeed = undefined;
			});

		builder
			.addCase(getSingleNewsFeed.pending, (state) => {
				state.loaders.getFeed = true;
				state.errors.getFeed = '';
			})
			.addCase(getSingleNewsFeed.fulfilled, (state, { payload }) => {
				state.currentFeed = payload.data;
				state.loaders.getFeed = 'fulfilled';
			})
			.addCase(getSingleNewsFeed.rejected, (state, action) => {
				state.errors.getFeed = action.payload;
				state.loaders.getFeed = undefined;
			});
	},
});

export default NewsFeedSlice.reducer;
