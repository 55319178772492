import { RouteGroup, SingleRoute } from '@Constants/routes';
import ScratchCards from '../PromoCodes';
import GenerateCodes from '../components/GenerateCode';
import { UpdatePromoCode } from '../Pages/UpdatePromoCode/UpdatePromoCode';
import { UsageTable } from '../components/UsageTable/UsageTable';

export const PromoCodesRoute: RouteGroup = {
	isRouted: true,
	title: 'Promo Codes',
	route: 'promo-codes',
	icon: 'local_offer',
	code: 'v-schc',
	component: <ScratchCards />,
	routes: [
		{ route: 'create', component: <GenerateCodes /> } as SingleRoute,
		{ route: 'update/:id', component: <UpdatePromoCode /> } as SingleRoute,
		{ route: ':id', component: <UsageTable /> } as SingleRoute,
	],
};
