import { number } from 'prop-types';
import { getAllHistory } from './../../services/history/history.req';
import { GenericSlice, IFetchAll } from './../generics/generics.type';
import {
	ActionReducerMapBuilder,
	createAsyncThunk,
	createSlice,
} from '@reduxjs/toolkit';
import EdResponse, {
	BaseModel,
	HeadersResponse,
} from '@Services/generics/response';
import {
	deleteWalletCodeReq,
	generateWalletCodes,
	getAllWalletCodesReq,
} from '@Services/walletCodes/walletCodes.req';
import { WalletCode, WalletCodesState } from './walletCodesSlice.types';

const SLICE_NAME = 'walletCodes';

const initalState: WalletCodesState = {
	data: [],
	codes: '',
	currentItem: null,
	dataFilters: [],
	errors: {
		fetchAll: '',
		generate: '',
		delete: '',
		fileExport: '',
	},
	loaders: {
		delete: null,
		fetchAll: null,
		fileExport: null,
		generate: null,
	},
	lastPage: 0,
	export: false,
	tableHeaders: [
		{ displayName: 'CODE', fieldName: 'code', canSort: true },
		{ displayName: 'BATCH', fieldName: 'batch', canSort: true },
		{ displayName: 'Value', fieldName: 'value', canSort: true },
		{ displayName: 'Used', fieldName: 'created_at', canSort: false },
		{ displayName: 'Used At', fieldName: 'updated_at', canSort: true },
		{ displayName: 'Email', fieldName: 'email', canSort: true },
		{ displayName: 'Created At', fieldName: 'created_at', canSort: true },
	],
	from: undefined,
	page: undefined,
	perPage: undefined,
	query: '',
	sortBy: '',
	total: 0,
};

export const fetchAllWalletCodes = createAsyncThunk<
	HeadersResponse<WalletCode[]>,
	IFetchAll,
	{}
>(`${SLICE_NAME}/getAll`, async (args: IFetchAll) => {
	if (
		Object.keys(args.sortBy).length !== 0 &&
		initalState.tableHeaders.find((_) => _.displayName === args.sortBy?.field)
	) {
		args.sortBy.field = initalState.tableHeaders.find(
			(_) => _.displayName === args.sortBy?.field
		)?.fieldName;
	}
	if (Object.keys(args.sortBy).length === 0) delete args.sortBy;
	const { data, headers } = await getAllWalletCodesReq(args);
	return { response_data: data, headers };
});

export const GenerateWalletCodes = createAsyncThunk<
	string,
	{ quantity: number; value: number },
	{ rejectValue: string }
>(
	`${SLICE_NAME}/generateScratchCard`,
	async ({ quantity, value }, thunkApi) => {
		try {
			const { data } = await generateWalletCodes({
				quantity: quantity,
				value: value,
			});
			return data;
		} catch (err: any) {
			const msg = err.response?.data.message || 'failed';
			return thunkApi.rejectWithValue(msg);
		}
	}
);
export const deleteWalletCode = createAsyncThunk<
	EdResponse<WalletCode>,
	{ id: number },
	{ rejectValue: string }
>(`${SLICE_NAME}/delete`, async ({ id }, thunkApi) => {
	try {
		const { data } = await deleteWalletCodeReq({ id: id });
		return { ...data, id };
	} catch (error: any) {
		const msg = error.response?.data.message || 'failed';
		return thunkApi.rejectWithValue(msg);
	}
});
const WalletCodesSlice = createSlice({
	initialState: initalState,
	name: SLICE_NAME,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(fetchAllWalletCodes.pending, (state) => {
				state.loaders.fetchAll = true;
			})
			.addCase(
				fetchAllWalletCodes.fulfilled,
				(state, { payload: { response_data, headers } }) => {
					const { data, filters, meta } = response_data;
					if (data) {
						state.data = data;
						state.lastPage = meta?.last_page;
						state.dataFilters = filters || [];
						//  state.currentStudent = currentStudent || [];
						state.total = meta?.total;
						state.page = meta?.current_page;
						state.perPage = meta?.per_page;
						state.loaders.fetchAll = false;
					} else {
						var dataStr = 'data:text/csv;charset=utf-8,' + response_data;
						var downloadAnchorNode = document.createElement('a');
						downloadAnchorNode.setAttribute('href', dataStr);
						downloadAnchorNode.setAttribute(
							'download',
							headers['content-disposition'].split('=')[1]
						);
						document.body.appendChild(downloadAnchorNode); // required for firefox
						downloadAnchorNode.click();
						downloadAnchorNode.remove();
						state.loaders.fileExport = false;
					}
				}
			)
			.addCase(fetchAllWalletCodes.rejected, (state, { payload, error }) => {
				state.errors.fetchAll = error.message || '';
			});
		builder
			.addCase(GenerateWalletCodes.pending, (state) => {
				state.loaders.generate = true;
			})
			.addCase(GenerateWalletCodes.fulfilled, (state, { payload }) => {
				state.codes = payload;
				state.loaders.generate = false;
			})
			.addCase(GenerateWalletCodes.rejected, (state, { payload }) => {
				state.errors.generate = payload;
				state.loaders.generate = undefined;
			});

		builder
			.addCase(deleteWalletCode.pending, (state) => {
				state.loaders.delete = true;
			})
			.addCase(
				deleteWalletCode.fulfilled,
				(
					state,
					{
						payload: {
							data: { id },
						},
					}
				) => {
					state.data = state.data.filter(
						(_) => _.id?.toString() !== id?.toString()
					);
					state.loaders.delete = false;
				}
			)
			.addCase(deleteWalletCode.rejected, (state, action) => {
				state.errors.delete = action.payload;
				state.loaders.delete = undefined;
			});
	},
});

export default WalletCodesSlice.reducer;
