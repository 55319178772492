import React from 'react';
import { CardContainer } from '@Pages/ClassRooms/Pages/AddClassroom/AddClassroom';
import AddRoleInfo from './Components/addRoleInfo';

const AddRole = () => {
	return (
		<CardContainer>
			<AddRoleInfo />
		</CardContainer>
	);
};

export default AddRole;
