import api from '@Services/api';
import {
	GetInstructorsResponse,
	GetInstructorResponse,
	CreateInstructorResponse,
	UpdateInstructorResponse,
} from '@Services/instructors/instructors.res.types';
import {
	getReqType,
	createReqType,
	updateReqType,
} from '@Services/instructors/instructor.req.types';

export const getAllInstructors = async (
	page: number,
	perPage: number,
	filters: Array<any>,
	sortBy: any,
	query: string,
	from?: Date,
	to?: Date
) => {
	const data: getReqType = { page, perPage, filters, query, from, to };
	return await api.post<GetInstructorsResponse>('instructor/all', data);
};

export const CreateInstructor = async (instructor: createReqType) =>
	await api.post<CreateInstructorResponse>('instructor/create', instructor);

export const UpdateInstructor = async (instructor: updateReqType) =>
	await api.put<UpdateInstructorResponse>('instructor/update', instructor);

export const getInstructor = async (uuid: string) =>
	await api.post<GetInstructorResponse>('instructor', { uuid });

export const activateInstructor = async (id: number) =>
	await api.post('instructor/activate', { id });

export const deactivateInstructor = async (id: number) =>
	await api.post('instructor/deactivate', { id });
