import RoundButton from '@Components/UI/Buttons/RoundButton';
import { Icon } from '@mui/material';
import { NavMenuContext } from '@Components/UI/Layout/Layout';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';

type Props = {} & React.ComponentProps<typeof RoundButton>;
const AddClassroomButton: React.FC<Props> = ({ ...props }) => {
	const histroy = useHistory();
	const { currentRouteGroup } = useContext(NavMenuContext);
	return (
		<RoundButton
			edcolor="purple"
			onClick={() =>
				histroy.push('/dashboard/classrooms/create/classroom#info')
			}
			startIcon={<Icon>add</Icon>}
			variant="contained"
			{...props}
		>
			Add Classroom
		</RoundButton>
	);
};

export default AddClassroomButton;
