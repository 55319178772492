import React, { useState } from 'react';
import AdminRoles from '@Pages/Admin/Components/AdminRoles';
import EditAdminInfo from './Components/EditAdminInfo';
import { HashString } from '@Components/UI/Tabs/RoutedTabs/RoutedTabs.types';
import { Spacer } from '@Styled/utilities';
import RoutedTabs from '@Components/UI/Tabs/Tabs';
import { useLocation, useParams } from 'react-router-dom';
import { AdminPayload } from '@Pages/Admin/Types/admin.types';
import { FormProvider, useForm } from 'react-hook-form';
import { ADD_ADMIN, AdminForm } from '@Pages/Admin/Schema/AdminsInfo.schema';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTypedSelector } from '@Features/store';
import { AdminRequester } from '@Pages/Admin/Services/AdminRequester/AdminRequester';
import { useSnackbar } from '@Providers/useSnackbar';
import { RolesData } from '@Pages/Roles/Services/roles.res.types';
import AssignedRoles from '@Pages/Admin/Components/AssignedRoles';
import CasesOutlinedIcon from '@mui/icons-material/CasesOutlined';
import { DevTool } from '@hookform/devtools';

const EditAdminBasicInfo = () => {
	const { hash } = useLocation();
	const [tabIndex, setTabIndex] = useState<HashString>(hash as HashString);
	const [hasRoles, setHasRoles] = useState(false);
	const [isValid, setIsValid] = useState(false);
	const [assignedRoles, setAssignedRoles] = useState<RolesData[]>([]);
	const { displaySnackbar } = useSnackbar();

	const { currentEntity } = useTypedSelector((state) => state.Admins);
	const auth = useTypedSelector((state) => state.auth);
	const hasAssignRolePermission =
		auth.user.type === 'super' || auth.permissions.has('asg-rls');

	const defaultValues = React.useMemo<AdminPayload>(() => {
		if (currentEntity) {
			const defaults: AdminPayload = {
				uuid: currentEntity.uuid,
				first_name: currentEntity.first_name,
				last_name: currentEntity.last_name,
				email: currentEntity.email,
				phone_number: currentEntity.phone_number,
				type: 'admin',
				roles: currentEntity?.roles
					?.filter((_) => _.id !== undefined)
					.map((_) => _.id) as number[],
				profile_photo: currentEntity.profile_photo,
				gender: currentEntity.gender,
				active: currentEntity.active,
				password: currentEntity.password,
			};
			return defaults;
		} else {
			return {} as AdminPayload;
		}
	}, []);

	const { formState, getValues, control, watch, ...formMethods } =
		useForm<AdminPayload>({
			resolver: yupResolver(ADD_ADMIN),
			mode: 'all',
			defaultValues,
		});

	const onSave = async (form: AdminPayload) => {
		try {
			const {
				data: { data },
			} = await AdminRequester.getInstance().update(form as AdminPayload);
			displaySnackbar(
				'success',
				`Admin ${data.first_name} Updated Successfully`
			);
		} catch (e) {
			const msg = e.response?.data.message || 'Unable to Edit Admin ';
			displaySnackbar('error', msg);
		}
	};

	return (
		<>
			<DevTool control={control} />
			<RoutedTabs>
				<RoutedTabs.Tabs
					$hashPrefix="#"
					hashes={['#info', '#role', '#assigned']}
					onChange={(e, value) => setTabIndex(value)}
					orientation="vertical"
					value={tabIndex}
				>
					<RoutedTabs.Item
						value="#info"
						label={<RoutedTabs.ItemTitle>Basic Info</RoutedTabs.ItemTitle>}
						icon={<RoutedTabs.ItemIcon>menu_book</RoutedTabs.ItemIcon>}
					/>
					{hasAssignRolePermission && (
						<RoutedTabs.Item
							value="#role"
							label={<RoutedTabs.ItemTitle>Roles</RoutedTabs.ItemTitle>}
							disabled={!hasRoles}
							icon={
								<RoutedTabs.ItemIcon>
									<CasesOutlinedIcon fontSize="large" />
								</RoutedTabs.ItemIcon>
							}
						/>
					)}
					{hasAssignRolePermission && (
						<RoutedTabs.Item
							value="#assigned"
							label={
								<RoutedTabs.ItemTitle>
									{hasRoles && (formState.isDirty || formState.isValid) && (
										<AssignedRoles assignedRoles={assignedRoles} />
									)}
								</RoutedTabs.ItemTitle>
							}
							disabled={true}
						/>
					)}
				</RoutedTabs.Tabs>
				<Spacer mx={''} />
				<RoutedTabs.Content value={tabIndex}>
					<RoutedTabs.ContentItem hash="#info">
						<FormProvider
							formState={formState}
							getValues={getValues}
							control={control}
							watch={watch}
							{...formMethods}
						>
							<form onSubmit={formMethods.handleSubmit(onSave)}>
								<EditAdminInfo
									control={control}
									formState={formState}
									getValues={getValues}
									onSave={onSave}
									watch={watch}
									onHasRolesChange={(val) => setHasRoles(val)}
									onIsValidChange={setIsValid}
								/>
							</form>
						</FormProvider>
					</RoutedTabs.ContentItem>
					<RoutedTabs.ContentItem hash="#role">
						<FormProvider
							formState={formState}
							getValues={getValues}
							control={control}
							watch={watch}
							{...formMethods}
						>
							<form onSubmit={formMethods.handleSubmit(onSave)}>
								<AdminRoles
									control={control}
									formState={formState}
									getValues={getValues}
									onSave={onSave}
									name="roles"
									setAssignedRoles={setAssignedRoles}
								/>
							</form>
						</FormProvider>
					</RoutedTabs.ContentItem>
				</RoutedTabs.Content>
			</RoutedTabs>
		</>
	);
};

export default EditAdminBasicInfo;
