import { RouteGroup, SingleRoute } from '../../../constants/routes';
import Admins from '../Admins';
import AddAdmin from '../Pages/AddAdmin/AddAdmin';
import EditAdmin from '../Pages/EditAdmin/EditAdmin';

export const AddAdminRoute: SingleRoute = {
	code: '',
	component: <AddAdmin />,
	route: 'create/admin',
	offMenu: true,
	title: '',
};
export const EditAdminRoute: SingleRoute = {
	code: '',
	component: <EditAdmin />,
	route: 'edit/admin/:id',
	offMenu: true,
	isExact: true,
	title: '',
};
export const AdminsRouteGroup: RouteGroup = {
	code: 'v-adm',
	component: <Admins />,
	route: 'admins',
	title: 'Admins',
	icon: 'assignment_ind',
	routes: [AddAdminRoute, EditAdminRoute],
	isRouted: true,
	isParent: true,
};
