import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import EdFormControl from '@Components/UI/Inputs/EdFormControl/EdFormControl';
import { yupResolver } from '@hookform/resolvers/yup';
import { ADD_SCHEMA, RoleForm } from '@Pages/Roles/schema/RoleInfo.schema';
import { useForm } from 'react-hook-form';
import { useTypedSelector } from '@Features/store';
import { useDispatch } from 'react-redux';
import { getAllRoles } from '@Pages/Roles/Features/rolesSlice';
import { createRole } from '@Pages/Roles/Services/roles.req';
import { DevTool } from '@hookform/devtools';
import Button from '@mui/material/Button';
import { DASHBOARD_BASE_ROUTE } from '@Constants/routes';
import { useHistory } from 'react-router-dom';
import SearchInput from '@Pages/Roles/Components/SearchInput';
import CollapseContainer from '@Pages/Roles/Components/CollapseContainer';
import GroupedBtns from '@Pages/Roles/Components/GroupedBtns';
import { GroupPermissions } from '@Pages/Roles/Types/Roles.types';
import { useImmer } from 'use-immer';
import { useSnackbar } from '@Providers/useSnackbar';

const AddRoleInfo: React.FC = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const [searchInput, setSearchInput] = useState('');
	const { group_permissions } = useTypedSelector((state) => state.dropdowns);
	const { displaySnackbar } = useSnackbar();

	const [filteredResults, setFilteredResults] = useImmer<GroupPermissions[]>(
		[]
	);

	useEffect(() => {
		setFilteredResults(group_permissions);
	}, [group_permissions]);

	useEffect(() => {
		dispatch(
			getAllRoles({
				page: 1,
				perPage: 10,
				filters: [],
				sortBy: {},
				query: '',
			})
		);
	}, [dispatch]);

	const { getValues, control, formState } = useForm<RoleForm>({
		resolver: yupResolver(ADD_SCHEMA),
		mode: 'all',
		defaultValues: {},
	});

	const onSave = async (form: RoleForm) => {
		try {
			const { title, description, active = true, permissions } = form;
			const {
				data: { data },
			} = await createRole(
				title,
				description,
				active as boolean,
				permissions as Array<number>
			);
			displaySnackbar('success', `Role ${data.title} created successfully`);
			history.push(`${DASHBOARD_BASE_ROUTE}/roles/edit/role/${data.id}`);
		} catch (e) {
			const msg = e.response?.data.message || 'Unable to Add Role ';
			displaySnackbar('error', msg);
		}
	};

	return (
		<Container>
			<InputFormContainer>
				<EdFormControl
					control={control}
					required
					name="title"
					label="Roles name"
				/>
				<EdFormControl
					control={control}
					multiline
					width={'100%'}
					required
					formNoValidate
					name="description"
					label="Roles description"
				/>
			</InputFormContainer>

			<SearchInput
				group_permissions={group_permissions}
				setSearchInput={setSearchInput}
				setFilteredResults={setFilteredResults}
				searchInput={searchInput}
			/>

			<CollapseContainer
				group_permissions={group_permissions}
				searchInput={searchInput}
				filteredResults={filteredResults}
				name="permissions"
				control={control}
			/>

			<GroupedBtns
				onSave={onSave}
				getValues={getValues}
				formState={formState}
			/>

			<DevTool control={control} />
		</Container>
	);
};

export default AddRoleInfo;

export const Container = styled.div`
	display: flex;
	gap: 2.063rem;
	flex-direction: column;
	padding-left: 0.7rem;
`;

export const InputFormContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 2rem;
	margin-bottom: 1.563rem;
	width: 58.563rem;
	.css-zcogjf-MuiInputBase-root-MuiOutlinedInput-root {
		/* width: 18.25rem; */
		height: 2.5rem;
		/* margin: 0 0 0 3.75rem; */
		border-radius: 4px;
		font-family: monospace;
	}
	.css-anday5-MuiInputBase-root-MuiOutlinedInput-root {
		/* width: 45.9rem; */
		height: 8.875rem;
		font-family: monospace;
		/* margin: 0 0 0 3.75rem; */
		border-radius: 4px;
	}
`;

export const SaveBtn = styled(Button)`
	background-color: #2196f3;
	&:hover {
		background-color: #2196f3;
	}
`;

export const CancelBtn = styled(Button)`
	background-color: #ff9800;
	&:hover {
		background-color: #ff9800;
	}
`;
