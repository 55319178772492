import { array, number, object, string, boolean } from 'yup';

export const ADD_SCHEMA = object({
	title: string().required(),
	description: string().required(),
	permissions: array(number().required()).min(1).required(),
	active: boolean(),
});
export const EDIT = object({
	id: number().required(),
}).concat(ADD_SCHEMA);

export const FULL_SCHEMA = ADD_SCHEMA.concat(EDIT);
export type RoleForm = typeof FULL_SCHEMA.__outputType;
export type EditForm = typeof EDIT.__outputType;
