import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import MainTable, { MainTableRow } from '@Components/MainTable/MainTable';
import TableCell from '@mui/material/TableCell';
import NoResults from '@Components/NoResults/NoResults';
import EdEmptyValue from '@Components/UI/Utilities/EdEmptyValue/EdEmptyValue';
import DateTimeFormatter from '@Components/DateTimeFormatter/DateTimeFormatter';
import EdSpeedDial, {
	EdSpeedDialAction,
} from '@Components/UI/Utilities/EdSpeedDial/EdSpeedDial';
import EdIcon from '@Components/UI/Utilities/EdIcon/EdIcon';
import RoundButton from '@Components/UI/Buttons/RoundButton';
import EdConfirmModal from '@Components/UI/Modals/EdConfirmModal/EdConfirmModal';
import { Chip, Tooltip } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from '@Features/store';
import { onPageChangeRequestMeta } from '@Components/MainTable/MainTable.types';
import { FlexLayout } from '@Styled/utilities';
import {
	invoicesGetAll,
	invoicesExportData,
	invoicesProcess,
} from '@Pages/Invoices/Slices/Invoices.slice';
import fawryImage from './Assets/FawryImage.png';
import visaImage from './Assets/VisaImage.png';
import amanImage from './Assets/AmanImage.png';
import walletImage from './Assets/WalletImage.png';
import masaryImage from './Assets/MasaryImage.png';
import vodafonImage from './Assets/VodafoneImage.png';
import { useHistory } from 'react-router-dom';

const tableHeaders: Array<TableHeader> = [
	{
		displayName: 'Email',
		fieldName: 'email',
		width: '15%',
	},
	{
		displayName: 'Username',
		fieldName: 'username',
		width: '8%',
	},
	{
		displayName: 'Invoice ref.',
		fieldName: 'invoice_ref',
		width: '9%',
	},
	{
		displayName: 'Type',
		fieldName: 'type',
		width: '10%',
	},
	{
		displayName: 'Status',
		fieldName: 'status',
		width: '5%',
	},
	{
		displayName: 'Method',
		fieldName: 'method',
	},
	{
		displayName: 'Created at',
		fieldName: 'created_at',
		width: '12%',
	},
	{
		displayName: 'Expiry Date',
		fieldName: 'expiry_date',
		width: '12%',
	},
	{
		displayName: 'Price',
		fieldName: 'price',
		width: '6%',
	},
	{
		displayName: 'Tax',
		fieldName: 'tax',
		width: '6%',
	},
];
const InvoicesTable: React.FC = () => {
	const dispatch = useDispatch();

	const { meta, filters, fetchAll, dateRange, selectedFilters, query, sortBy } =
		useTypedSelector((state) => state.Invoices);
	const auth = useTypedSelector((state) => state.auth);

	const [openProcessByRowModel, setOpenProcessByRowModel] =
		useState<boolean>(false);
	const [processId, setProcessId] = useState<any>(null);
	const history = useHistory();

	const handleProcessClick = (id: number) => {
		setProcessId(id);
		setOpenProcessByRowModel(true);
	};

	const handleProcessModelClose = (close: boolean, confirmation?: boolean) => {
		setOpenProcessByRowModel(close);
		if (confirmation) dispatch(invoicesProcess(processId));
	};

	const onPageRequestCallback = useCallback(
		({
			activePage,
			endDate,
			filters,
			query,
			sortBy,
			startDate,
			export: exportFlag,
			perPage,
		}: onPageChangeRequestMeta) => {
			dispatch(
				invoicesGetAll({
					filters,
					page: activePage,
					perPage: perPage ?? 10,
					query,
					sortBy,
					export: exportFlag ?? false,
					from: startDate,
					to: endDate,
				})
			);
		},
		[]
	);

	return (
		<div>
			<EdConfirmModal
				open={openProcessByRowModel}
				handleClose={handleProcessModelClose}
				text={'Are you sure you want to process invoice'}
			/>
			{(auth.user.type === 'super' || auth.permissions.has('exp-inv')) && (
				<FlexLayout justifyContent="flex-end">
					<RoundButton
						onClick={() => {
							if (invoicesExportData)
								dispatch(
									invoicesExportData({
										filters: selectedFilters,
										page: meta?.current_page ?? 1,
										perPage: meta?.per_page ?? 10,
										query: query ?? '',
										sortBy,
										from: dateRange.from,
										to: dateRange.to,
									})
								);
						}}
						sx={{ marginRight: '1rem' }}
						startIcon={<EdIcon>south</EdIcon>}
						variant="contained"
					>
						Export
					</RoundButton>
				</FlexLayout>
			)}
			<MainTable
				tableFilters={filters}
				tableHeads={tableHeaders}
				totalPages={meta?.last_page ?? 0}
				onPageChangeRequest={onPageRequestCallback}
				RowsperPage={meta?.per_page}
				pageNum={meta?.current_page}
				$hasActions
				total={meta?.total}
				renderItems={() => {
					if (fetchAll.length === 0)
						return (
							<MainTableRow>
								<TableCell colSpan={tableHeaders.length}>
									<NoResults />
								</TableCell>
							</MainTableRow>
						);
					return fetchAll.map((invoice) => {
						return (
							<MainTableRow
								key={invoice?.id}
								hover
								onClick={() => {
									history.push({
										pathname: `/dashboard/invoices/invoice/${invoice.id}`,
									});
								}}
							>
								<Tooltip title={invoice.email} placement="top">
									<TableCell>
										<EdEmptyValue value={invoice.email} />
									</TableCell>
								</Tooltip>
								<Tooltip title={invoice.username} placement="top">
									<TableCell>{invoice?.username || '-'}</TableCell>
								</Tooltip>
								<Tooltip title={invoice.invoice_ref} placement="top">
									<TableCell>{invoice?.invoice_ref || '-'}</TableCell>
								</Tooltip>
								<TableCell>
									{invoice?.type?.toLowerCase() === 'purchase' ? (
										<StyledOutlinedChip
											label={invoice.type}
											variant="outlined"
										/>
									) : invoice?.type?.toLowerCase() === 'recharge' ? (
										<StyledChip label={invoice.type} />
									) : invoice?.type?.toLowerCase() === 'registration' ? (
										<RegisterationChip label={invoice.type} />
									) : (
										<p>-</p>
									)}
								</TableCell>
								<TableCell>
									{invoice.status.toLowerCase() === 'paid' ? (
										<StyledStatusText textColor={'#00d66b'}>
											{invoice.status}{' '}
										</StyledStatusText>
									) : invoice.status.toLowerCase() === 'new' ||
									  invoice.status.toLowerCase() === 'pending' ? (
										<StyledStatusText textColor={'#2196f3'}>
											{invoice.status}{' '}
										</StyledStatusText>
									) : invoice.status.toLowerCase() === 'expired' ||
									  invoice.status.toLowerCase() === 'unpaid' ||
									  invoice.status.toLowerCase() === 'failed' ? (
										<StyledStatusText textColor={'#ff3100'}>
											{invoice.status}{' '}
										</StyledStatusText>
									) : (
										<p>-</p>
									)}
								</TableCell>
								<TableCell>
									{invoice.method.toUpperCase() === 'WALLET' ? (
										<StyledImage src={walletImage} />
									) : invoice.method.toUpperCase() === 'CARD' ? (
										<StyledImage src={visaImage} />
									) : invoice.method.toUpperCase() === 'PAYATMASARY' ? (
										<StyledImage src={masaryImage} />
									) : invoice.method.toUpperCase() === 'PAYATFAWRY' ? (
										<StyledImage src={fawryImage} />
									) : invoice.method.toUpperCase() === 'EWALLET' ? (
										<StyledImage src={vodafonImage} />
									) : invoice.method.toUpperCase() === 'PAYATAMAN' ? (
										<StyledImage src={amanImage} />
									) : (
										<p>-</p>
									)}
								</TableCell>
								<Tooltip
									title={<DateTimeFormatter date={invoice.created_at} />}
									placement="top"
								>
									<TableCell>
										<DateTimeFormatter date={invoice.created_at} />
									</TableCell>
								</Tooltip>
								<Tooltip
									title={
										<DateTimeFormatter date={new Date(invoice.expiry_date)} />
									}
									placement="top"
								>
									<TableCell>
										<DateTimeFormatter date={new Date(invoice.expiry_date)} />
									</TableCell>
								</Tooltip>
								<Tooltip title={invoice?.price} placement="top">
									<TableCell>{invoice?.price + ' EGP' || '-'}</TableCell>
								</Tooltip>
								<Tooltip title={invoice?.tax} placement="top">
									<TableCell>{invoice?.tax + ' EGP' || '-'}</TableCell>
								</Tooltip>
								<TableCell>
									{invoice.status.toLowerCase() != 'paid' &&
										(auth.user.type === 'super' ||
											auth.permissions.has('proc-inv')) && (
											<EdSpeedDial ariaLabel="Actions" sx={{ width: '76px' }}>
												<EdSpeedDialAction
													onClick={(e) => {
														e.stopPropagation();
														handleProcessClick(invoice.id);
													}}
													key="process"
													tooltipTitle="process"
													icon={<EdIcon>keyboard_double_arrow_right</EdIcon>}
													aria-label=""
												/>
											</EdSpeedDial>
										)}
								</TableCell>
							</MainTableRow>
						);
					});
				}}
			/>
		</div>
	);
};

const StyledStatusText = styled.p<{ textColor: string }>`
	color: ${(props) => props.textColor};
	font-weight: 600;
`;
const StyledChip = styled(Chip)`
	background-color: ${(props) => props.theme.palette?.purple?.main};
	color: white;
	font-weight: 600;
	height: 22px;
`;
const StyledOutlinedChip = styled(Chip)`
	color: ${(props) => props.theme.palette?.purple?.main};
	border: ${(props) => props.theme.palette?.purple?.main} 1px solid;
	font-weight: 600;
	height: 22px;
`;
const StyledImage = styled.img`
	width: 66px;
	height: 34px;
`;
const RegisterationChip = styled(Chip)`
	background-color: ${(props) => props.theme.palette?.mayaBlue.main};
	color: white;
	font-weight: 600;
	height: 22px;
`;

export default InvoicesTable;
