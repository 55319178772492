import EdFormControl from '@Components/UI/Inputs/EdFormControl/EdFormControl';
import { Checkbox, MenuItem } from '@mui/material';
import { ChipFlexLayout } from '@Pages/ClassRooms/Components/AddEditClassroom.styled';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import {
	ClassroomCourse,
	CourseWithPrerequisites,
} from '@Services/classrooms/classrooms.res.types';
import SelectChip from '@Components/UI/Inputs/SelectChip/SelectChip';
import { FlexLayout } from '@Styled/utilities';

type Props = {
	selectedCourse: (ClassroomCourse & CourseWithPrerequisites) | undefined;
};

const UnitSelect: React.FC<Props> = ({ selectedCourse }) => {
	const { watch, control } = useFormContext();
	const watchUnits = watch('units', []);
	return (
		<EdFormControlStyle
			control={control}
			select
			name="units"
			multiple
			required
			renderValues={(value) => {
				return (
					<FlexLayout flexWrap="wrap" width="200px">
						{selectedCourse?.units
							?.filter((_) => watchUnits?.includes(_?.id))
							.map((_) => {
								return <SelectChip label={_?.name} />;
							})}
					</FlexLayout>
				);
			}}
			SelectProps={{
				multiple: true,
				renderValue: (selected) => {
					return (
						<ChipFlexLayout>
							{watchUnits?.map((unit: number) => {
								const name = selectedCourse?.units
									?.filter((_) => _?.id === unit)
									.find((_) => _.progress_included === true)?.name;
								return <>{name},</>;
							})}
						</ChipFlexLayout>
					);
				},
			}}
			labelProps={{ display: 'none' }}
		>
			{selectedCourse?.units?.map((_, index) => {
				return (
					<MenuItemStyle value={_.id}>
						<Checkbox checked={watchUnits?.includes(_?.id)} />
						{_.name}
					</MenuItemStyle>
				);
			})}
		</EdFormControlStyle>
	);
};

const EdFormControlStyle = styled(EdFormControl)`
	height: 2.313rem;
	width: 16.25rem;
	fieldset {
		border: solid 1px rgba(0, 0, 0, 0.12);
	}
	.MuiOutlinedInput-root {
		height: 2.313rem;
	}
`;
const MenuItemStyle = styled(MenuItem)`
	font-weight: 500;
	padding-left: 15px;
`;

export default UnitSelect;
