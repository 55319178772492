import {
	CRUDBase,
	DeleteSingleEntityPayload,
	GenerateCardMeta,
	GenerateCodesMeta,
	GetAllMeta,
	GetSingleEntityPayload,
} from '@Base/services/services.base';
import { Group } from '@Pages/ClassRooms/Types/Groups.types';
import api from '@Services/api';
import { AxiosResponse } from 'axios';

export class GroupStudentsRequester implements CRUDBase<GroupStudent> {
	export?(payload: GetAllMeta): Promise<AxiosResponse<string>> {
		throw new Error('Method not implemented.');
	}
	getAll(
		payload?: GetAllMeta | undefined
	): Promise<AxiosResponse<ApiResponse<GroupStudent[]>>>;
	getAll(payload: number): Promise<AxiosResponse<ApiResponse<GroupStudent[]>>>;
	async getAll(
		payload?: unknown
	): Promise<AxiosResponse<ApiResponse<GroupStudent[]>>> {
		return api.post(`${this.baseURL}`, payload);
	}
	get(id: string): Promise<AxiosResponse<ApiResponse<GroupStudent>>>;
	get(id: number): Promise<AxiosResponse<ApiResponse<GroupStudent>>>;
	get(
		id: string | number,
		tId?: string | number | undefined,
		payload?: GetSingleEntityPayload | undefined
	): Promise<AxiosResponse<ApiResponse<GroupStudent>>>;
	get(
		id: unknown,
		tId?: unknown,
		payload?: unknown
	): Promise<AxiosResponse<ApiResponse<GroupStudent>>> {
		throw new Error('Method not implemented.');
	}
	create(
		payload: Omit<GroupStudent, 'id'> | { user_id: number; group_id: number }
	): Promise<AxiosResponse<ApiResponse<GroupStudent>>> {
		return api.post(`${this.baseURL}assign`, payload);
	}
	update(
		payload: GroupStudent
		): Promise<AxiosResponse<ApiResponse<GroupStudent>>> {
		return api.post(`${this.baseURL}remove`, payload);
	}
	delete(id: number): Promise<AxiosResponse<ApiResponse<any>>>;
	delete(id: string): Promise<AxiosResponse<ApiResponse<any>>>;
	delete(
		id: string | number,
		payload?: DeleteSingleEntityPayload | undefined
	): Promise<AxiosResponse<ApiResponse<any>>>;
	delete(
		id: unknown,
		payload?: unknown
	): Promise<AxiosResponse<ApiResponse<any>>> {
		throw new Error('Method not implemented.');
	}
	generate?(
		payload: GenerateCodesMeta | GenerateCardMeta
	): Promise<AxiosResponse<string>> {
		throw new Error('Method not implemented.');
	}
	toggleActive?(
		payload: GroupStudent
	): Promise<AxiosResponse<ApiResponse<GroupStudent>>> {
		throw new Error('Method not implemented.');
	}
	process?(id: number): Promise<AxiosResponse<ApiResponse<any>>> {
		throw new Error('Method not implemented.');
	}
	private static instance: GroupStudentsRequester;
	static getInstance() {
		if (!GroupStudentsRequester.instance) {
			GroupStudentsRequester.instance = new GroupStudentsRequester();
		}
		return GroupStudentsRequester.instance;
	}
	baseURL = 'groups/students/';
}
