import { useTypedSelector } from '@Features/store';
import React, {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { useDispatch } from 'react-redux';
import {
	CourseGraphConfig,
	CourseGraphIds,
	getCourseReportGraph,
} from '../../Slices/ReportOverview.slice';
import { ResponsiveLine } from '@nivo/line';
import { DatumValue, linearGradientDef } from '@nivo/core';
import styled from 'styled-components';
import { rgba } from 'polished';
import { Icon, IconButton, TextField } from '@mui/material';
import { deepPurple } from '@mui/material/colors';
import DateRangePickerPopup, {
	DateRangeOption,
} from '@Components/UI/Pickers/DateRangePickerPopup/DateRangePickerPopup';
import { Controller, useForm } from 'react-hook-form';
import { FormType } from '@Pages/ClassRooms/Pages/ClassroomsAnalytics/ClassroomsAnalytics';
import { yupResolver } from '@hookform/resolvers/yup';
import { array, mixed, object } from 'yup';
import { GraphSliceType } from '@Services/classrooms/classrooms.res.types';
import { number } from 'yup';
import { date } from 'yup';
import MonthRangePicker from '@Components/UI/Pickers/MonthRangePicker/MonthRangePicker';
import YearRangePicker from '@Components/UI/Pickers/YearRangePicker/YearRangePicker';
import EdFormControl from '@Components/UI/Inputs/EdFormControl/EdFormControl';
import { AnalyticsSlices } from '@Pages/ClassRooms/Constants/Classroom.constants';
import { MenuItem } from '@mui/material';
import { FlexLayout, Spacer } from '@Styled/utilities';
import { Button } from '@mui/material';
import { CourseReportGraphPayload } from '../../Types/CourseOverviewReport.types';
import { SideMenuContext } from '@Components/UI/Menu/Menu';

type FormArgs = CourseReportGraphPayload & { dates?: [Date, Date] };
const SCHEMA = object().shape({
	slice: mixed<GraphSliceType>().required(),
	course_id: number(),
	from: date(),
	to: date(),
});
const OverviewGraph = () => {
	const dispatch = useDispatch();
	const { graph } = useTypedSelector((state) => state.CourseReports);
	const { currentCourse } = useTypedSelector((state) => state.classroom);
	const { drawerOpened } = useTypedSelector((state) => state.settings);

	const [open, setOpen] = useState(false);
	const [dateRange, setDateRangeOption] =
		useState<DateRangeOption>('last 2 weeks');

	const [anchorEl, setAnchorEl] = useState<HTMLElement>();
	const defaultValues = useMemo<FormArgs>(() => {
		const startDate = new Date();
		startDate.setDate(startDate.getDate() - 14);
		return {
			id: Number(currentCourse?.id),
			slice: 'day',
			from: startDate,
			to: new Date(),
			dates: [startDate, new Date()],
		};
	}, []);
	const {
		control,
		watch,
		setValue,
		reset,
		handleSubmit,
		formState: { isValid, isDirty, submitCount },
	} = useForm<FormArgs>({
		resolver: yupResolver(SCHEMA),
		defaultValues: defaultValues,
		mode: 'all',
	});

	const modifiedGraphData = useMemo(() => {
		return graph?.map((_) => ({
			..._,
			color: CourseGraphConfig[_.id as CourseGraphIds],
		}));
	}, [graph]);
	const watchSliceType = watch('slice');

	const handleOnReset = () => {
		reset();
		setDateRangeOption('last 2 weeks');
		if (submitCount > 0) {
			getGraph(defaultValues);
		}
	};

	const handleDateRangeChange = (type: DateRangeOption) => {
		setDateRangeOption(type);
		if (type === 'custom') return;
		const startDate = new Date();
		const endDate = new Date();
		if (type === 'last 2 weeks') {
			startDate.setDate(startDate.getDate() - 14);
		}
		if (type === 'last month') {
			startDate.setMonth(startDate.getMonth() - 1);
		}
		if (type === 'last 3 months') {
			startDate.setMonth(startDate.getMonth() - 2);
		}
		setValue('dates', [startDate, endDate], {
			shouldValidate: true,
			shouldDirty: true,
		});
		setValue('from', startDate, { shouldValidate: true, shouldDirty: true });
		setValue('to', endDate, { shouldValidate: true, shouldDirty: true });
	};

	const getGraph = useCallback((args: CourseReportGraphPayload) => {
		dispatch(getCourseReportGraph(args));
	}, []);
	const handleFilterForm = (data: FormArgs) => {
		if (currentCourse) {
			getGraph({
				slice: data.slice,
				from: data.from,
				to: data.to,
				id: currentCourse.id,
			});
		}
	};

	return (
		<FlexLayout flexDirection={'column'} width={'100%'}>
			<FormControlsWrapper>
				<FlexLayout>
					<StyledIconButton
						onClick={(e: React.MouseEvent<HTMLElement>) => {
							setOpen(!open);
							setAnchorEl(e.currentTarget);
						}}
					>
						<Icon>event</Icon>
					</StyledIconButton>
					<Spacer mx={14} />
					<Controller
						control={control}
						name="slice"
						render={({ field }) => (
							<TextField size="small" select {...field}>
								{AnalyticsSlices.map((_) => {
									return (
										<MenuItem key={_.value} value={_.value}>
											{_.label}
										</MenuItem>
									);
								})}
							</TextField>
						)}
					/>

					<DateRangePickerPopup
						open={open}
						anchorEl={anchorEl}
						onClose={() => {
							setOpen(false);
						}}
						onChange={handleDateRangeChange}
						rangeOptionValue={dateRange}
					>
						<>
							<DateRangePickerPopup.Body>
								{watchSliceType === 'month' && (
									<MonthRangePicker control={control as any} name="dates" />
								)}
								{watchSliceType === 'year' && (
									<YearRangePicker control={control as any} name="dates" />
								)}
							</DateRangePickerPopup.Body>
						</>
					</DateRangePickerPopup>
				</FlexLayout>
				<FormActionsWrapper>
					<Button
						disabled={!isDirty}
						onClick={handleOnReset}
						variant="outlined"
					>
						Reset
					</Button>
					<Spacer mx={'1rem'} />
					<Button
						disabled={!isValid || !isDirty}
						onClick={handleSubmit(handleFilterForm)}
						variant="contained"
					>
						Apply
					</Button>
				</FormActionsWrapper>
			</FormControlsWrapper>
			{modifiedGraphData && (
				<div
					style={{
						height: '45vh',
						// width: "100%",
						width: drawerOpened ? '38vw' : '100%',
					}}
				>
					<ResponsiveLine
						data={modifiedGraphData}
						animate
						xScale={{ type: 'point' }}
						lineWidth={2}
						areaOpacity={0.1}
						isInteractive
						useMesh
						enableGridX
						enableGridY
						enableCrosshair
						enableSlices="x"
						sliceTooltip={({ slice }) => {
							console.log({ slice });
							return (
								<div
									style={{
										background: 'white',
										padding: '9px 12px',
										border: '1px solid #ccc',
									}}
								>
									<div>{slice.points[0].data.x}</div>
									{slice.points.map((point) => (
										<div
											key={point.id}
											style={{
												color: point.serieColor,
												padding: '3px 0',
											}}
										>
											<strong>{point.serieId}</strong> [{point.data.yFormatted}]
										</div>
									))}
								</div>
							);
						}}
						axisLeft={{
							format: (e) => Math.floor(e) === e && e,
						}}
						axisBottom={{
							legendPosition: 'middle',
							legend: watchSliceType,
							legendOffset: 75,
							ticksPosition: 'after',
							tickSize: 20,
						}}
						colors={{ datum: 'color' }}
						crosshairType="cross"
						enablePoints
						legends={[
							{
								anchor: 'top-right',
								direction: 'column',
								symbolShape: 'circle',
								translateX: -20,
								itemWidth: 30,
								itemHeight: 20,
							},
						]}
						margin={{ left: 30, bottom: 150, top: 30, right: 30 }}
						enableArea={true}
						yScale={{
							type: 'linear',
							// stacked: true,
							// max: 10,
						}}
						defs={[
							linearGradientDef('gradientA', [
								{ offset: 0, color: 'inherit' },
								{ offset: 100, color: 'inherit', opacity: 0 },
							]),
						]}
						fill={[{ match: '*', id: 'gradientA' }]}
					/>
				</div>
			)}
		</FlexLayout>
	);
};

export default OverviewGraph;

const StyledIconButton = styled(IconButton)`
	background: ${(props) => rgba(props.theme.palette.primary.main, 0.25)};
	color: ${(props) => deepPurple[500]};
	:hover {
		background: ${(props) => rgba(props.theme.palette.primary.main, 0.25)};
	}
`;

export const FormControlsWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 2rem 0;
	margin-top: 4rem;
`;

export const FormActionsWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
`;
