import EdButton from '@Components/UI/Buttons/EdButton/EdButton';
import EdMenu from '@Components/UI/Menus/EdMenu/EdMenu';
import EdIcon from '@Components/UI/Utilities/EdIcon/EdIcon';
import { useTypedSelector } from '@Features/store';
import { ContentConfig } from '@Pages/ClassRooms/Pages/SingleCourse/Pages/CourseForm/Pages/CourseContent/Constants/CourseContent.constants';
import {
	ContentInfo,
	ContentType,
} from '@Pages/ClassRooms/Pages/SingleCourse/Pages/CourseForm/Pages/CourseContent/Types/CourseContent.types';
import { useSnackbar } from '@Providers/useSnackbar';
import React, { FC, useState } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import { ADD_WEBINAR } from '../../Pages/Webinar/Schema/WebinarSchema/Webinar.schema';
import { WebinarRequester } from '../../Pages/Webinar/Services/Webinar/WebinarRequester';
import { Webinar } from '../../Pages/Webinar/Types/Webinar.types';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { TestRequester } from '../../Pages/Test/Services/TestRequester/TestRequester';
import CloneModal from '../../Pages/CloneFromAnotherCourse/CloneModal';

const ContentAddMenu = () => {
	const history = useHistory();
	const [openModal, setOpenModal] = useState<boolean>(false);
	const { pathname, hash } = useLocation();
	const { url, path } = useRouteMatch();
	const { displaySnackbar } = useSnackbar();

	const { currentCourse } = useTypedSelector((state) => state.classroom);
	const baseURL = React.useMemo(() => {
		return `${url}#content/`;
	}, []);

	const handleWebinar = async () => {
		if (!currentCourse) return;

		try {
			const {
				data: { data },
			} = await WebinarRequester.getInstance().create({
				unit: {
					course_id: currentCourse.id,
					type_id: 5,
				},
				webinar: {
					description: 'description',
				},
			});
			displaySnackbar('success', `Webinar ${data.name} created successfully`);
			history.push(`${pathname}/webinar/${data.id}`);
		} catch (e) {
			const msg = e.response?.data.message || 'Unable to Create Webinar ';
			displaySnackbar('error', msg);
		}
	};

	const handleCreateTest = async () => {
		if (!currentCourse) return;
		try {
			const {
				data: {
					data: { id },
				},
			} = await TestRequester.instance.create({
				unit: {
					course_id: currentCourse.id,
					type_id: 3,
				},
				test: {},
			});
			const toPathname = `${pathname.split('edit')[0]}test/${id}/questions`;
			history.push(toPathname);
			displaySnackbar('success', 'Test created successfully');
		} catch (error) {
			displaySnackbar('error', "Couldn't create test");
		}
	};

	const handleDocument = () => {
		history.push(`${pathname}/document`);
	};
	const handleWebContent = () => {
		history.push(`${pathname}/content`);
	};
	const handleVideo = () => {
		history.push(`${pathname}/video`);
	};
	const handleAssignment = () => {
		history.push(`${pathname}/assignment`);
	};

	const openCloneModal = () => {
		setOpenModal(true);
	};

	return (
		<>
			<CloneModal open={openModal} handleModelClose={setOpenModal} />
			<EdMenu
				renderButton={() => (
					<EdButton
						style={{
							fontFamily: 'Roboto',
						}}
						startIcon={<EdIcon>add</EdIcon>}
						edcolor="tuftsBlue"
						variant="contained"
					>
						Add
					</EdButton>
				)}
				renderMenu={() => {
					return (
						<EdMenu.Menu
							disablePortal
							anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
							transformOrigin={{ horizontal: 'right', vertical: 'top' }}
						>
							<EdMenu.MenuItem onClick={handleWebContent}>
								<ContentTypeItem
									type={'webcontent'}
									data={ContentConfig.webcontent}
								/>
							</EdMenu.MenuItem>
							<EdMenu.MenuItem onClick={handleDocument}>
								<ContentTypeItem
									type={'document'}
									data={ContentConfig['document']}
								/>
							</EdMenu.MenuItem>
							<EdMenu.MenuItem onClick={handleCreateTest}>
								<ContentTypeItem type={'test'} data={ContentConfig.test} />
							</EdMenu.MenuItem>
							<EdMenu.MenuItem
								onClick={() => {
									handleWebinar();
								}}
							>
								<ContentTypeItem
									type={'webinar'}
									data={ContentConfig['webinar']}
								/>
							</EdMenu.MenuItem>
							<EdMenu.MenuItem
								onClick={() => {
									handleVideo();
								}}
							>
								<ContentTypeItem type={'video'} data={ContentConfig.video} />
							</EdMenu.MenuItem>
							<EdMenu.MenuItem
								onClick={() => {
									handleAssignment();
								}}
							>
								<ContentTypeItem type={'assignment'} data={ContentConfig.assignment} />
							</EdMenu.MenuItem>
							<EdMenu.MenuItem
								onClick={() => {
									openCloneModal();
								}}
							>
								<ContentTypeItem type={'clone'} data={ContentConfig.clone} />
							</EdMenu.MenuItem>
						</EdMenu.Menu>
					);
				}}
			/>
		</>
	);
};

export default ContentAddMenu;

type Props = {
	data: ContentInfo<ContentType>;
	type: ContentType;
};

const ContentTypeItem: FC<Props> = ({ data, type }) => {
	return (
		<StyledContentTypeItemWrapper>
			{data?.icon}
			<ContentTypeItemTitle>
				{type === 'clone' ? 'Clone from another course' : type}
			</ContentTypeItemTitle>
		</StyledContentTypeItemWrapper>
	);
};

const StyledContentTypeItemWrapper = styled.div`
	font-weight: 600;
	color: ${(props) => props.theme.palette.tuftsBlue?.main};
	display: flex;
	align-items: center;
	width: 13rem;
	padding: 0.3rem 0;
	padding-left: 1.5rem;
`;

const ContentTypeItemTitle = styled.span`
	margin: 0 1.25rem;
	text-transform: capitalize;
	line-break: auto;
	white-space: pre-wrap;
`;
