import { useTypedSelector } from '@Features/store';
import { getSingleNewsFeed } from '@Pages/NewsFeeds/Slices/NewsFeedSlice';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { NewsFeedContainer } from '../AddNewsFeed/AddNewsFeed';
import EditNewsFeedInfo from './Components/EditNewsFeedInfo';

const EditNewsFeed = () => {
	const dispatch = useDispatch();
	const { id } = useParams<{ id: string }>();

	const {
		currentFeed,
		loaders: { getFeed },
	} = useTypedSelector((state) => state.newsFeed);
	useEffect(() => {
		if (id)
			dispatch(
				getSingleNewsFeed({
					id: Number(id),
				})
			);
	}, [id]);

	return (
		<NewsFeedContainer>
			{currentFeed && getFeed === 'fulfilled' && <EditNewsFeedInfo />}
		</NewsFeedContainer>
	);
};

export default EditNewsFeed;
