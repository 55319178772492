import MainTable from '@Components/MainTable';
import { MainTableRow } from '@Components/MainTable/MainTable';
import { onPageChangeRequestMeta } from '@Components/MainTable/MainTable.types';
import NoResults from '@Components/NoResults/NoResults';
import EdSpeedDial, {
	EdSpeedDialAction,
} from '@Components/UI/Utilities/EdSpeedDial/EdSpeedDial';
import { TableHeader } from '@Features/generics/generics.type';
import { useTypedSelector } from '@Features/store';
import { TableCell } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { testsReportsGetAll } from '../../Slices/TestsReports.slice';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { UsersRequester } from '../../../../../Users/Services/Userprogress/UserProgressRequester';
import { useSnackbar } from '@Providers/useSnackbar';
import EdConfirmModal from '@Components/UI/Modals/EdConfirmModal/EdConfirmModal';
import styled from 'styled-components';
import NoTests from './Components/NoTests';
import { useHistory, useRouteMatch } from 'react-router-dom';

const TABLE_HEADS: Array<TableHeader> = [
	{ displayName: 'Test Name', fieldName: 'name', width: '20%' },
	{
		displayName: '#Attempts',
		fieldName: 'attempts_count',
		width: '19%',
	},
	{
		displayName: 'Passed',
		fieldName: 'passed_student_count',
		width: '19%',
	},
	{
		displayName: 'Failed',
		fieldName: 'failed_student_count',
		width: '19%',
	},
	{ displayName: 'AVG.Score ', fieldName: 'average_score' },
];

const TestsTable = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const { path } = useRouteMatch();
	const { displaySnackbar } = useSnackbar();
	const [openResetModel, setOpenResetModel] = useState<boolean>(false);
	const [testId, setTestId] = useState<number | undefined>();

	const { fetchAll, filters, meta, query } = useTypedSelector(
		(state) => state.TestsReports
	);

	const { currentCourse } = useTypedSelector((state) => state.classroom);

	const onPageChangeRequest = useCallback(
		({
			activePage,
			endDate,
			filters,
			query,
			sortBy,
			startDate,
			perPage,
		}: onPageChangeRequestMeta) => {
			dispatch(
				testsReportsGetAll({
					page: activePage,
					filters,
					perPage: perPage as number,
					query,
					sortBy,
					from: startDate,
					to: endDate,
					id: currentCourse?.id,
				})
			);
		},
		[]
	);

	const handleModelResetClose = async (close: boolean, confirm?: boolean) => {
		if (confirm) {
			try {
				if (testId) {
					UsersRequester.getInstance()
						.resetTest(testId)
						.then(({ data }) => {
							dispatch(
								testsReportsGetAll({
									page: meta?.current_page as number,
									perPage: meta?.per_page ?? 10,
									filters,
									sortBy: {},
									query: query as string,
									id: currentCourse?.id,
								})
							);
						});
				}
				displaySnackbar('success', `Reset Test successfully`);
			} catch (e) {
				const msg = e?.data.message;
				displaySnackbar('error', msg);
			}
		}
		setOpenResetModel(false);
	};

	const average = (average_score: number) => {
		if (!average_score) return 0;
		return (average_score * 100).toFixed(1);
	};

	return (
		<>
			<EdConfirmModal
				text="Are you sure you want to reset this Test?"
				open={openResetModel}
				handleClose={handleModelResetClose}
			/>
			{fetchAll?.length <= 0 && query === '' ? (
				<NoTests />
			) : (
				<MainTable
					total={meta?.total ?? 0}
					dateFilter={false}
					pageNum={meta?.current_page}
					tableFilters={filters}
					tableHeads={TABLE_HEADS}
					totalPages={meta?.last_page ?? 0}
					RowsperPage={Number(meta?.per_page)}
					$hasActions
					onPageChangeRequest={onPageChangeRequest}
					renderItems={() => {
						return fetchAll.length <= 0 ? (
							<MainTableRow>
								<TableCell colSpan={TABLE_HEADS.length}>
									<NoResults />
								</TableCell>
							</MainTableRow>
						) : (
							fetchAll?.map((test) => {
								return (
									<MainTableRow
										key={test.id}
										hover
										onClick={() => {
											history.push({
												pathname: `${path}/${test?.id}`,
												state: test?.report,
											});
										}}
									>
										<TableCellStyled>{test?.name}</TableCellStyled>
										<TableCellStyled>
											{test?.report?.attempts_count}
										</TableCellStyled>
										<TableCellStyled>
											{test?.report?.passed_student_count}
										</TableCellStyled>
										<TableCellStyled>
											{test?.report?.failed_student_count}
										</TableCellStyled>
										<TableCellStyled>
											{test?.report?.average_score === null
												? '-'
												: `${average(test?.report?.average_score)}%`}
										</TableCellStyled>
										<TableCell>
											<EdSpeedDial ariaLabel="SpeedDial basic example">
												<EdSpeedDialAction
													key="reset test"
													icon={<RestartAltIcon color="action" />}
													tooltipTitle="Reset test"
													tooltipPlacement="left"
													onClick={(e) => {
														e.stopPropagation();
														setTestId(test?.id);
														setOpenResetModel(true);
													}}
												/>
											</EdSpeedDial>
										</TableCell>
									</MainTableRow>
								);
							})
						);
					}}
				/>
			)}
		</>
	);
};

export const TableCellStyled = styled(TableCell)`
	font-family: Roboto;
	font-size: 0.875rem;
	padding: 0 1.063rems 0;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
`;

export default TestsTable;
