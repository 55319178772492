import { TagColor } from '../Types/Tags';

export const ColorsMap: Array<TagColor> = [
	{ name: 'champagne', color: '#ebdcab' },
	{ name: 'columbia_blue', color: '#c1dbea' },
	{ name: 'honeydew', color: '#cce2d7' },
	{ name: 'melon', color: '#ebb7ab' },
	{ name: 'lavender_blue', color: '#c6c4eb' },
	{ color: '#eccd9f', name: 'deep_champagne' },
	{ name: 'orange_yellow_crayola', color: '#f2d472' },
	{ name: 'aero', color: '#83bdec' },
	{ name: 'aquamarine', color: '#72dda8' },
	{ name: 'middle_red', color: '#f28b72' },
	{ name: 'max_blue_purple', color: '#a8a4f2' },
	{ name: 'sunray', color: '#f2be72' },
];
