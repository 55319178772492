import { ScratchCard } from '@Pages/ScratchCards/Types/ScratchCards.types';
import api from '@Services/api';
import { AxiosResponse } from 'axios';
import {
	CRUDBase,
	DeleteSingleEntityPayload,
	GetAllMeta,
	GetSingleEntityPayload,
} from 'src/base/services/services.base';

interface ScratchCardsTabCRUD extends CRUDBase<ScratchCard, ScratchCard> {}
export class ScratchCardsTabRequester implements ScratchCardsTabCRUD {
	private static instance: ScratchCardsTabRequester;
	static getInstance() {
		if (!ScratchCardsTabRequester.instance) {
			ScratchCardsTabRequester.instance = new ScratchCardsTabRequester();
		}
		return ScratchCardsTabRequester.instance;
	}
	baseURL = 'user/profile';
	getAll(
		payload?: GetAllMeta
	): Promise<AxiosResponse<ApiResponse<ScratchCard[]>>>;
	getAll(payload: number): Promise<AxiosResponse<ApiResponse<ScratchCard[]>>>;
	async getAll(
		payload?: any
	): Promise<import('axios').AxiosResponse<ApiResponse<ScratchCard[]>>> {
		return await api.post(`${this.baseURL}/${payload.id}/cards`, payload);
	}
	get(id: string): Promise<AxiosResponse<ApiResponse<ScratchCard>>>;
	get(id: number): Promise<AxiosResponse<ApiResponse<ScratchCard>>>;
	get(
		id: string | number,
		payload: GetSingleEntityPayload
	): Promise<AxiosResponse<ApiResponse<ScratchCard>>>;
	async get(
		id: any,
		payload?: any
	): Promise<AxiosResponse<ApiResponse<ScratchCard>>> {
		return await api.post(this.baseURL, { uuid: id });
	}

	async create(
		payload: ScratchCard
	): Promise<AxiosResponse<ApiResponse<ScratchCard>>> {
		return await api.post(`${this.baseURL}/create`, payload);
	}
	async update(
		payload: ScratchCard
	): Promise<AxiosResponse<ApiResponse<ScratchCard>>> {
		return await api.put(`${this.baseURL}/update`, payload);
	}
	delete(id: number): Promise<AxiosResponse<ApiResponse<any>>>;
	delete(id: string): Promise<AxiosResponse<ApiResponse<any>>>;
	delete(
		id: string | number,
		payload: DeleteSingleEntityPayload
	): Promise<AxiosResponse<ApiResponse<ScratchCard>>>;
	async delete(
		id: any,
		payload?: any
	): Promise<AxiosResponse<ApiResponse<any>>> {
		return await api.delete(`${this.baseURL}/delete`, {
			data: {
				uuid: id,
			},
		});
	}
}
