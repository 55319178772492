import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function useQueryURL() {
	let usp = new URLSearchParams(document.location.search);
	let { search } = useLocation();
	const [selectedQuery, setSelectedQuery] = useState<string>('');

	useEffect(() => {
		const query = usp.get('query');
		setSelectedQuery(query ?? '');
	}, [search]);
	return {
		selectedQuery,
	};
}

export default useQueryURL;
