import { Icon } from '@mui/material';
import { ContentInfoConfig } from '../Types/CourseContent.types';
import EdIcon from '@Components/UI/Utilities/EdIcon/EdIcon';
export const ContentConfig: ContentInfoConfig = {
	document: {
		type: 'document',
		icon: <EdIcon mIconType="Outlined">description</EdIcon>,
	},
	video: {
		type: 'video',
		icon: <Icon>play_circle_filled</Icon>,
	},
	test: {
		type: 'test',
		icon: <EdIcon mIconType="Outlined">live_help</EdIcon>,
		route: 'test',
	},
	survey: {
		type: 'survey',
		icon: <EdIcon mIconType="Outlined">inventory</EdIcon>,
	},
	assignment: {
		type: 'assignment',
		icon: <EdIcon mIconType="Outlined">ballot</EdIcon>,
	},
	webinar: {
		type: 'webinar',
		icon: <EdIcon>podcasts</EdIcon>,
	},
	webcontent: {
		type: 'webcontent',
		icon: <EdIcon mIconType="Outlined">document_scanner_black_24dp</EdIcon>,
	},
	clone: {
		type: 'clone',
		icon: <EdIcon mIconType="Outlined">copy_all</EdIcon>,
	},
};
