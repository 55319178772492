import { TestsReport } from '@Constants/routes';
import useInvalidateRouteGroup from '@Hooks/Routes/useInvalidateRouteGroup';
import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import SingleTestTable from './SingleTestTable';
import TestsTable from './TestsTable';

const Tests = () => {
	const { url } = useRouteMatch();
	const {} = useInvalidateRouteGroup({
		baseURL: `${url}/reports`,
		routePlacement: 2,
		mode: 'end',
	});

	return (
		<Switch>
			{TestsReport && !Array.isArray(TestsReport) && (
				<>
					<Route exact path={`${url}/test`}>
						<TestsTable />
					</Route>
					<Route path={`${url}/test/:id`}>
						<SingleTestTable />
					</Route>
				</>
			)}
		</Switch>
	);
};

export default Tests;
