import { BaseSliceController } from '@Base/slices/slices.base';
import { Invoice } from '@Pages/Invoices/Types/Invoices.types';
import { InvoicesTabRequester } from '../Services/Invoices.Requester';

export class InvoicesTabSlice extends BaseSliceController<
	Invoice,
	'InvoicesTab',
	Invoice
> {
	private static instance: InvoicesTabSlice;
	static getInstance() {
		if (!InvoicesTabSlice.instance) {
			InvoicesTabSlice.instance = new InvoicesTabSlice();
		}
		return InvoicesTabSlice.instance;
	}
	constructor() {
		super('InvoicesTab', InvoicesTabRequester.getInstance());
	}
}

export const { invoicesTabGetAll, invoicesTabProcess } =
	InvoicesTabSlice.getInstance().getActions();
