import { Chip, ChipProps } from '@mui/material';
import { getContrast } from 'polished';
import React, { FC } from 'react';
import styled from 'styled-components';
import { layout, LayoutProps } from 'styled-system';

type Props = { bgColor: string } & ChipProps & LayoutProps;
const TagChip: FC<Props> = ({ bgColor, ...props }) => {
	return <StyledTagChip $bgColor={bgColor} {...props} />;
};

export default TagChip;
const StyledTagChip = styled(Chip)<{ $bgColor: string } & LayoutProps>`
	font-weight: bold;
	color: #000;
	background-color: ${(props) => props.$bgColor};
	min-width: 8rem;
	max-width: 8rem;
	border-radius: 6px;
	padding: 0 0.5rem;
	justify-content: flex-start;

	color: ${(props) => {
		const isDark = getContrast('#000', props.$bgColor);
		return isDark > 6 ? '#000' : '#FFF';
	}};
	.MuiChip-label {
		overflow: unset;
		overflow: hidden;
		text-overflow: ellipsis;
		height: 100%;
		display: grid;
		place-items: center;
	}
	.MuiChip-icon {
		color: #000;
	}
	${layout}
`;
