import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import AddVideo from './pages/AddVideo/AddVideo';
import EditVideo from './pages/EditVideo/EditVideo';

const VideoUnit = () => {
	const { path } = useRouteMatch();

	return (
		<>
			<React.Suspense fallback={<div>loading</div>}>
				<Switch>
					<Route path={`${path}`} exact>
						<AddVideo />
					</Route>
					<Route path={`${path}/:id`} exact>
						<EditVideo />
					</Route>
				</Switch>
			</React.Suspense>
		</>
	);
};

export default VideoUnit;
