import { lazy } from 'yup';
import { object, string, number } from 'yup';

const regex =
	/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)+(\.(mp4|mkv|flv|3gp|avi|mov|webm))?$/;
const embedLink =
	/<(\"[^\"]*\"|'[^']*'|[^'\">])([a-zA-Z]+)(?:[^>]*[^/]*)((<\/)\w+(>))?>$/;

export const VIDEO_SCHEMA = object({
	unit: object({
		course_id: number().required(),
		type_id: number().required(),
		name: string().required(),
		order: number().notRequired(),
	}),
	video: object({
		name: string().notRequired(),
		uri: lazy((value) =>
			/<(\"[^\"]*\"|'[^']*'|[^'\">])([a-zA-Z]+)(?:[^>]*[^/]*)?>$/.test(value)
				? string()
						.matches(embedLink, 'Embed Link is not valid')
						.required()
						.typeError('Embed Link is required')
				: string()
						.matches(regex, 'Video URL is not valid')
						.required()
						.typeError('Video URL is required')
		),
		duration: number()
			.required()
			.typeError('must be a number')
			.nullable()
			.transform((value, originalValue) =>
				String(originalValue).trim() === '' ? null : value
			),
		max_plays: number()
			.optional()
			.nullable()
			.transform((value, originalValue) =>
				String(originalValue).trim() === '' ? null : value
			),
	}),
	link_schema: string().notRequired(),
});
