import React, { useRef, useState } from 'react';
import { ButtonProps, Button, IconButton, ButtonGroup } from '@mui/material';
import EdIcon from '@Components/UI/Utilities/EdIcon/EdIcon';
import { Menu } from '@mui/material';
import { Divider } from '@mui/material';
type EdButtonMenuProps = {
	MenuItems?: React.ReactElement;
} & ButtonProps;
const EdButtonMenu: React.FC<EdButtonMenuProps> = ({
	MenuItems,
	children,
	...props
}) => {
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const anchorRef = useRef<HTMLDivElement | null>(null);
	const [open, setOpened] = React.useState<boolean>(false);

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(anchorRef.current);
		setOpened(true);
	};
	const handleClose = () => {
		setOpened(false);
		setAnchorEl(null);
	};
	return (
		<div>
			<ButtonGroup ref={anchorRef}>
				<Button {...props}>{children}</Button>
				<Button {...props} size="small" onClick={handleClick}>
					<EdIcon>arrow_drop_down</EdIcon>
				</Button>
				{MenuItems && (
					<Menu
						anchorEl={anchorEl}
						transformOrigin={{ horizontal: 'right', vertical: 'top' }}
						anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
						onClose={handleClose}
						open={open}
					>
						{MenuItems}
					</Menu>
				)}
			</ButtonGroup>
		</div>
	);
};

export default EdButtonMenu;
