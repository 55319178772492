import api from '@Services/api';
import { AxiosResponse } from 'axios';
import { Webinar, WebinarPayload } from '../../Types/Webinar.types';

import {
	CRUDBase,
	DeleteSingleEntityPayload,
	GetAllMeta,
	GetSingleEntityPayload,
} from 'src/base/services/services.base';

interface WebinarCRUD extends CRUDBase<WebinarPayload, Webinar> {}
export class WebinarRequester implements WebinarCRUD {
	private static instance: WebinarRequester;
	static getInstance() {
		if (!WebinarRequester.instance) {
			WebinarRequester.instance = new WebinarRequester();
		}
		return WebinarRequester.instance;
	}
	baseURL = 'webinar';
	getAll(payload?: GetAllMeta): Promise<AxiosResponse<ApiResponse<Webinar[]>>>;
	getAll(payload: number): Promise<AxiosResponse<ApiResponse<Webinar[]>>>;
	async getAll(
		payload?: any
	): Promise<import('axios').AxiosResponse<ApiResponse<Webinar[]>>> {
		return await api.post(`${this.baseURL}/all`, payload);
	}
	get(id: string): Promise<AxiosResponse<ApiResponse<Webinar>>>;
	get(id: number): Promise<AxiosResponse<ApiResponse<Webinar>>>;
	get(
		id: string | number,
		payload: GetSingleEntityPayload
	): Promise<AxiosResponse<ApiResponse<Webinar>>>;
	async get(
		id: any,
		payload?: any
	): Promise<AxiosResponse<ApiResponse<Webinar>>> {
		return await api.post(`/${this.baseURL}/get`, { id });
	}
	async create(
		payload: WebinarPayload
	): Promise<AxiosResponse<ApiResponse<Webinar>>> {
		return await api.post(`${this.baseURL}/create`, payload);
	}
	async update(
		payload: WebinarPayload
	): Promise<AxiosResponse<ApiResponse<Webinar>>> {
		return await api.put(`${this.baseURL}/update`, payload);
	}

	delete(id: number): Promise<AxiosResponse<ApiResponse<any>>>;
	delete(id: string): Promise<AxiosResponse<ApiResponse<any>>>;
	delete(
		id: string | number,
		payload: DeleteSingleEntityPayload
	): Promise<AxiosResponse<ApiResponse<Webinar>>>;
	async delete(
		id: any,
		payload?: any
	): Promise<AxiosResponse<ApiResponse<any>>> {
		return await api.delete(`${this.baseURL}/delete`, {
			data: {
				uuid: id,
			},
		});
	}
}
