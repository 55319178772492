import Swal, { SweetAlertIcon } from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
const CustomSwal = withReactContent(Swal);

const NormalSweetAlert = (
	title?: string | HTMLElement,
	icon?: SweetAlertIcon
) => {
	return CustomSwal.fire({
		title: title || 'Success',
		icon: icon || 'success',
	});
};

export default NormalSweetAlert;
