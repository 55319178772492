import { array, boolean, object, string, number } from 'yup';

export const CONTENT_SCHEMA = object({
	unit: object({
		course_id: number(),
		type_id: number(),
		name: string(),
		order: number(),
	}),
	webContent: object({
		content: string(),
	}),
});
