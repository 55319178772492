import { FormControlLabel, Paper } from '@mui/material';
import { FlexLayout, Spacer } from '@Styled/utilities';
import React, { FC } from 'react';
import { TypedTestQuestion } from '../../Types/TestQuestion';
import { QuestionContent } from './QuestionAccordion.styled';
import { grey } from '@mui/material/colors';
import styled from 'styled-components';

type Props = {
	question: TypedTestQuestion<'ordering'>;
};
const OrderingAccordion: FC<Props> = ({ question }) => {
	return (
		<FlexLayout flexDirection="column">
			<QuestionContent>
				<div dangerouslySetInnerHTML={{ __html: question.content }} />
			</QuestionContent>
			<Spacer mb="2rem" />
			<FlexLayout flexDirection="column">
				{question.orderingOptions.map((ord, index) => {
					return (
						<OrderOptionBox elevation={0} key={`${ord.option}-${index}`}>
							<OrderLabel>{ord.order}</OrderLabel>
							<div>{ord.option}</div>
						</OrderOptionBox>
					);
				})}
			</FlexLayout>
		</FlexLayout>
	);
};

export default OrderingAccordion;

const OrderOptionBox = styled(Paper)`
	background: ${grey['200']};
	margin-bottom: 0.625rem;
	padding: 0.438rem;
	display: flex;
	font-size: 0.875rem;
`;
const OrderLabel = styled.label`
	color: #00d66b;
	margin: 0 0.375rem;
	font-weight: bold;
`;
