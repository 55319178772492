import { BreadcrumbContext } from '@Components/UI/Layout/Layout';

import useInvalidateRouteGroup from '@Hooks/Routes/useInvalidateRouteGroup';
import React, { useContext, useEffect } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import NotesTable from './NotesTable';
import { RouteProvider } from '@Providers/Routes/RoutesProvider';
import { NotesRouteGroup } from './Constants/routes';

const Notes = () => {
	const { url, path } = useRouteMatch();

	return (
		<Switch>
			<RouteProvider path={url}>
				{NotesRouteGroup && (
					<>
						<Route exact path={url}>
							<NotesTable />
						</Route>

						{NotesRouteGroup.routes.map((_: any) => {
							return (
								<Route strict path={`${path}/${_.route}`}>
									{_.component}
								</Route>
							);
						})}
					</>
				)}
			</RouteProvider>
		</Switch>
	);
};

export default Notes;
