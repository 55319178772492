import { RouteGroup } from '@Constants/routes';
import SequenceManagement from '../../SingleClassroom/Components/SequenceManagement/SequenceManagement';
import CourseAnalytics from '../Pages/Analytics/CourseAnalytics';
import { CourseFormRouteGroup } from '../Pages/CourseForm/Constants/routes';
import EnrollmentStudents from '../Pages/Users/EnrollmentStudent';
import SingleCourse from '../SingleCourse';

export const SingleCourseRoute: RouteGroup = {
	isRouted: true,
	code: '',
	route: 'course/:id',
	overviewRoute: 'edit/info',
	component: <SingleCourse />,
	title: 'Course',
	offMenu: true,
	routes: [
		{
			title: 'Users',
			route: 'users',
			icon: '',
			code: 'v-enrl-cl',
			component: <EnrollmentStudents />,
		},
		{
			code: 'v-seq-cl',
			icon: '',
			component: <SequenceManagement />,
			route: 'sequence',
			title: 'sequence',
		},
		{
			title: 'Analytics',
			route: 'analytics',
			icon: '',
			code: 'v-anlt-cr',
			component: <CourseAnalytics />,
		},
		CourseFormRouteGroup,
	],
	isParent: true,
	icon: '',
};
