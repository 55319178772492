import { BaseSliceController } from '@Base/slices/slices.base';
import { NotesRequester } from '../Services/Notes.Requester';
import { Assistants, Note } from '../Types/Notes.types';

export class NotesSlice extends BaseSliceController<Note, 'Notes', Note> {
	private static instance: NotesSlice;
	static getInstance() {
		if (!NotesSlice.instance) {
			NotesSlice.instance = new NotesSlice();
		}
		return NotesSlice.instance;
	}
	constructor() {
		super('Notes', NotesRequester.getInstance());
	}
}

export const { notesGetAll ,notesCreate , notesGetSingle ,notesUpdate , notesDeleteById } = NotesSlice.getInstance().getActions();
