import EdFormControl from '@Components/UI/Inputs/EdFormControl/EdFormControl';
import { useAppThunkDispatch, useTypedSelector } from '@Features/store';
import { FormContainerStyle } from '@Pages/ClassRooms/Pages/SingleCourse/Pages/CourseForm/Form.Styles';
import {
	groupsGetSingle,
	groupsUpdate,
} from '@Pages/ClassRooms/Slices/Groups/Groups.slice';
import React, { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { StudentsTable } from '../../components/StudentsTable';
import { FlexLayout, Spacer } from '@Styled/utilities';
import EdAnimatedIcon from '@Components/UI/Utilities/EdAnimatedIcon/EdAnimatedIcon';
import EdIcon from '@Components/UI/Utilities/EdIcon/EdIcon';
import { Button, IconButton } from '@mui/material';
import { GridLayout } from '@eduact/ed-system';
import { useRouteProvider } from '@Providers/Routes/RoutesProvider';
import { yupResolver } from '@hookform/resolvers/yup';
import { AddGroupSchema } from '@Pages/ClassRooms/Schema/Group.schema';
import {omit} from 'lodash'
export const EditGroup = () => {
	const { groupId } = useParams<{ groupId: string }>();

	const { currentEntity } = useTypedSelector((state) => state.Groups);

	const dispatch = useAppThunkDispatch();

	const defaultValues = useMemo(() => {
		return currentEntity;
	}, []);
	const {
		control,
		handleSubmit,
		formState: { isDirty, isValid },
		reset,
	} = useForm({
		defaultValues,
		resolver : yupResolver(AddGroupSchema)
	});
	const onConfirm = handleSubmit(async (data) => {
		const resp = await dispatch(groupsUpdate(omit(data,'code') as any)).unwrap();
		reset(data as any);
	});
	const parent = useRouteProvider();
	return (
		<FormContainerStyle flexDirection={'column'}>
			<FlexLayout gridGap={'1rem'} width={'100%'} flexDirection={'column'}>
				<GridLayout
					gridTemplateColumns={'repeat(2,1fr)'}
					minWidth={'50%'}
					gridGap={'2rem'}
				>
					<EdFormControl
						control={control}
						name="name"
						placeholder="Name"
						label="Name"
					/>
					{/* <EdFormControl
						required
						control={control}
						name="code"
						placeholder="Code"
						label="Code"
					/> */}
					<EdFormControl
						multiline
						minRows={4}
						control={control}
						name="description"
						placeholder="Description"
						label="Description"
					/>
				</GridLayout>

				<FlexLayout
					justifyContent="flex-end"
					gridGap="1rem"
					// width="75%"
					marginTop="2.938rem"
				>
					<Button
						variant="contained"
						color="warning"
						disabled={!isDirty}
						onClick={() => reset()}
					>
						CANCEL
					</Button>
					<Button
						variant="contained"
						disabled={!isDirty || !isValid}
						onClick={onConfirm}
					>
						Save
					</Button>
				</FlexLayout>
			</FlexLayout>
			<Spacer mb="2rem" />
			<StudentsTable />
		</FormContainerStyle>
	);
};
