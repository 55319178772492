import { FlexLayout, Spacer } from '@Styled/utilities';
import React, { FC } from 'react';
import { TypedTestQuestion } from '../../Types/TestQuestion';
import { QuestionContentExpanded } from './QuestionAccordion.styled';

type Props = {
	question: TypedTestQuestion<'essay'>;
};
const EssayAccordion: FC<Props> = ({ question }) => {
	return (
		<FlexLayout flexDirection="column">
			<QuestionContentExpanded>
				<div dangerouslySetInnerHTML={{ __html: question.content }} />
			</QuestionContentExpanded>
			<Spacer mb="2rem" />
			<FlexLayout flexDirection="column"></FlexLayout>
		</FlexLayout>
	);
};

export default EssayAccordion;
