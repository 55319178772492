import api from '@Services/api';
import { AxiosResponse } from 'axios';
import {
	CRUDBase,
	DeleteSingleEntityPayload,
	GetAllMeta,
	GetSingleEntityPayload,
} from 'src/base/services/services.base';
import { Clone } from '../Types/Clone.types';

interface CloneCRUD extends CRUDBase<Clone, Clone> {}
export class CloneRequester implements CloneCRUD {
	export?(payload: GetAllMeta): Promise<AxiosResponse<string>> {
		throw new Error('Method not implemented.');
	}
	getAll(
		payload?: GetAllMeta | undefined
	): Promise<AxiosResponse<ApiResponse<Clone[]>>>;
	getAll(payload: number): Promise<AxiosResponse<ApiResponse<Clone[]>>>;
	getAll(payload?: unknown): Promise<AxiosResponse<ApiResponse<Clone[]>>> {
		throw new Error('Method not implemented.');
	}
	get(id: string): Promise<AxiosResponse<ApiResponse<Clone>>>;
	get(id: number): Promise<AxiosResponse<ApiResponse<Clone>>>;
	get(
		id: string | number,
		tId?: string | number | undefined,
		payload?: GetSingleEntityPayload | undefined
	): Promise<AxiosResponse<ApiResponse<Clone>>>;
	get(
		id: unknown,
		tId?: unknown,
		payload?: unknown
	): Promise<AxiosResponse<ApiResponse<Clone>>> {
		throw new Error('Method not implemented.');
	}
	create(
		payload: Omit<Clone, 'id'>
	): Promise<AxiosResponse<ApiResponse<Clone>>> {
		throw new Error('Method not implemented.');
	}
	update(payload: Clone): Promise<AxiosResponse<ApiResponse<Clone>>> {
		throw new Error('Method not implemented.');
	}
	delete(id: number): Promise<AxiosResponse<ApiResponse<any>>>;
	delete(id: string): Promise<AxiosResponse<ApiResponse<any>>>;
	delete(
		id: string | number,
		payload?: DeleteSingleEntityPayload | undefined
	): Promise<AxiosResponse<ApiResponse<any>>>;
	delete(
		id: unknown,
		payload?: unknown
	): Promise<AxiosResponse<ApiResponse<any>>> {
		throw new Error('Method not implemented.');
	}
	private static instance: CloneRequester;
	static getInstance() {
		if (!CloneRequester.instance) {
			CloneRequester.instance = new CloneRequester();
		}
		return CloneRequester.instance;
	}
	baseURL = 'clone';
	async clone(
		id: any,
		unit_id: any,
		payload?: any
	): Promise<AxiosResponse<ApiResponse<Clone>>> {
		return await api.post(`${this.baseURL}/unit`, {
			course_id: id,
			unit_id,
		});
	}
}
