import { IconButton, Paper, PaperProps } from '@mui/material';
import { deepPurple, grey } from '@mui/material/colors';
import { EnrollmentIconsMap } from '@Services/classrooms/classrooms.res.types';
import { rgba } from 'polished';
import styled from 'styled-components';
import {
	AnalyticsAdmin,
	AnalyticsFree,
	AnalyticsInstructor,
	AnalyticsScratchCards,
	AnalyticsTotal,
	AnalyticsWallet,
} from './Assets/Icons/Icons';
const StyledPaper = styled(Paper).attrs<PaperProps & { color: string }>({
	elevation: 0,
})`
	padding: 1.25rem;
`;
export const AnalyticsCardsContainer = styled.div`
	display: flex;
	margin-bottom: 3.563rem;
`;
export const TypeName = styled.div`
	font-size: 1.125rem;
	font-weight: 600;
	grid-row: 2/2;
	text-transform: capitalize;
`;
export const TypeInfo = styled.div`
	display: flex;
	justify-content: space-between;
`;
export const TypeValue = styled.div`
	font-size: 1.375rem;
	font-weight: 600;
	color: ${grey[900]};
`;

export const EnrollmentTypeCard = styled(StyledPaper)`
	display: flex;
	min-width: 10.5rem;
	flex-direction: column;
	justify-content: space-between;
	background: ${(props) => (props.color ? rgba(props.color, 0.1) : '')};
	color: ${(props) => props.color};
	max-height: 7.75rem;
	${TypeInfo} {
		color: ${(props) => props.color};
	}
	margin: 0 1rem;
	:first-child {
		margin-left: 0;
	}
	span {
		margin-bottom: 1.25rem;
	}
	svg {
		max-height: 100%;
	}
`;

export const EnrollmentsCard = styled(EnrollmentTypeCard)`
	min-width: 21.25rem;
	background: ${(props) => rgba(props.theme.palette.primary.main, 0.1)};
	font-weight: 600;
	flex-direction: row;
	color: ${(props) => props.theme.palette.primary.main};
	${TypeInfo} {
		flex-direction: column;
		span {
			font-size: 1.875rem;
			color: ${grey[900]};

			margin-bottom: 1rem;
		}
		label {
			font-size: 1.75rem;
		}
	}
	span {
		margin: 0;
		/* margin-right: 1rem; */
		font-size: 4rem;
	}
`;

export const enrollmentIcons: EnrollmentIconsMap = {
	wallet: <AnalyticsWallet />,
	admin: <AnalyticsAdmin />,
	free: <AnalyticsFree />,
	scratchcard: <AnalyticsScratchCards />,
	instructor: <AnalyticsInstructor />,
};

export const FormControlLabel = styled.label`
	font-size: 1.25rem;
	font-weight: 600;
	margin: 0 0.75rem;
	text-transform: unset !important;
`;

export const FormControlsWrapper = styled.div`
	display: flex;
	align-items: center;
	/* justify-content: flex-end; */
	margin: 2rem 0;
	margin-top: 4rem;
`;

export const FormActionsWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
`;

export const StyledIconButton = styled(IconButton)`
	background: ${(props) => rgba(props.theme.palette.primary.main, 0.25)};
	color: ${(props) => deepPurple[500]};
	:hover {
		background: ${(props) => rgba(props.theme.palette.primary.main, 0.25)};
	}
`;
