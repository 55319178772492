import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabList from '@mui/lab/TabList';
import styled from 'styled-components';

export const SpanStyle = styled.span`
	font-family: Montserrat;
	font-size: 1rem;
	margin-left: 1rem;
	font-weight: 500;
`;

export const FormContainerStyle = styled(Box)`
	display: flex;
	border-radius: 0.625rem;
	flex: 1;
	/* padding :1.375rem; */
	padding: 1.75rem 2.625rem;
	/* flex-direction: column; */
	justify-content: space-between;
	border: solid 2px rgba(90, 192, 252, 0.25);
	.MuiTabPanel-root {
		flex: 1;
	}
	.css-paw6vv-MuiTabs-indicator {
		display: none;
	}
`;
export const FormContainerBody = styled(Box)`
	display: flex;
	justify-content: space-between;
	.css-paw6vv-MuiTabs-indicator {
		display: none;
	}

	div:first-child {
		/* width : 100%; */
	}
`;

export const StyleTabBOXContainer = styled(Box)`
	.MuiTabs-indicator.css-10d9dml-MuiTabs-indicator {
		display: block;
	}
`;

export const TabListStyle = styled(TabList)`
	min-width: 20rem;
	display: flex;
	margin-right: 1.5rem;
	.MuiTabs-indicator {
		display: none;
	}
	.MuiButtonBase-root.MuiTab-root.Mui-selected {
		background-color: #f3f3f3;
		.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiTab-iconWrapper {
			font-size: 1.8rem;
			color: #ffffff;
			margin: 0 1rem 0 0;
			padding: 0.813rem 0.75rem 0.75rem 0.813rem;
			background-color: #5ac0fc;
			border-radius: 50%;
		}
	}
	.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiTab-iconWrapper {
		color: #251b33;
		font-size: 1.8rem;
		padding: 0.813rem 0.75rem 0.75rem 0.813rem;
		border-radius: 50%;
		margin: 0 1rem 0 0;
		background-color: #f3f3f3;
	}
	.MuiButtonBase-root.MuiTab-root {
		font-family: Montserrat;
		margin: 1.25rem 0 1.188rem 1rem;
		font-size: 1rem;
		font-weight: 600;
	}
`;

export const IconWrapper = styled(Box)`
	min-width: 4.6rem;
	min-height: 4.6rem;
`;

export const StyleTab = styled(Tab)`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	margin: 0 0 0 2.438rem;
	padding: 0.75rem 3.25rem 0.75rem 0.625rem;
	border-radius: 0.625rem;
`;

export const TitleStyled = styled.label`
	font-size: 1.12rem;
	font-family: Montserrat;
	color: #6c63ff;
	font-weight: bold;
	margin-bottom: 2rem;
`;

export const WrapperStyled = styled.div`
	align-items: center;
	justify-content: center;
	width: 3.6rem;
	height: 3.6rem;
`;
