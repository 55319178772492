import {
	CRUDBase,
	DeleteSingleEntityPayload,
	GenerateCardMeta,
	GenerateCodesMeta,
	GetAllMeta,
	GetSingleEntityPayload,
} from '@Base/services/services.base';
import api from '@Services/api';
import { AxiosResponse } from 'axios';
import {
	StudentWebinarsCount,
	StudentWebinarsCountPayload,
} from '../Types/StudentWebinars.types';

export class WebinarsCountRequester implements CRUDBase<StudentWebinarsCount> {
	baseURL = '/classroom/analytics/webinars/count';

	public static instance: WebinarsCountRequester = new WebinarsCountRequester();
	export?(payload: GetAllMeta): Promise<AxiosResponse<string>> {
		throw new Error('Method not implemented.');
	}
	getAll(
		payload?: GetAllMeta | undefined
	): Promise<AxiosResponse<ApiResponse<StudentWebinarsCount[]>>>;
	getAll(
		payload: number
	): Promise<AxiosResponse<ApiResponse<StudentWebinarsCount[]>>>;
	getAll(
		payload?: unknown
	): Promise<
		import('axios').AxiosResponse<ApiResponse<StudentWebinarsCount[]>>
	> {
		throw new Error('Method not implemented.');
	}
	get(id: string): Promise<AxiosResponse<ApiResponse<StudentWebinarsCount>>>;
	get(id: number): Promise<AxiosResponse<ApiResponse<StudentWebinarsCount>>>;
	get(
		id: string | number,
		tId?: string | number | undefined,
		payload?: GetSingleEntityPayload | undefined
	): Promise<AxiosResponse<ApiResponse<StudentWebinarsCount>>>;
	async get(
		id: unknown,
		tId?: unknown,
		payload?: StudentWebinarsCountPayload
	): Promise<import('axios').AxiosResponse<ApiResponse<StudentWebinarsCount>>> {
		return await api.post(`${this.baseURL}`, payload);
	}
	create(
		payload: Omit<StudentWebinarsCount, 'id'>
	): Promise<AxiosResponse<ApiResponse<StudentWebinarsCount>>> {
		throw new Error('Method not implemented.');
	}
	update(
		payload: StudentWebinarsCount
	): Promise<AxiosResponse<ApiResponse<StudentWebinarsCount>>> {
		throw new Error('Method not implemented.');
	}
	delete(id: number): Promise<AxiosResponse<ApiResponse<any>>>;
	delete(id: string): Promise<AxiosResponse<ApiResponse<any>>>;
	delete(
		id: string | number,
		payload?: DeleteSingleEntityPayload | undefined
	): Promise<AxiosResponse<ApiResponse<any>>>;
	delete(
		id: unknown,
		payload?: unknown
	): Promise<import('axios').AxiosResponse<ApiResponse<any>>> {
		throw new Error('Method not implemented.');
	}
	generate?(
		payload: GenerateCodesMeta | GenerateCardMeta
	): Promise<AxiosResponse<string>> {
		throw new Error('Method not implemented.');
	}
	toggleActive?(
		payload: StudentWebinarsCount
	): Promise<AxiosResponse<ApiResponse<StudentWebinarsCount>>> {
		throw new Error('Method not implemented.');
	}
	process?(id: number): Promise<AxiosResponse<ApiResponse<any>>> {
		throw new Error('Method not implemented.');
	}
}
