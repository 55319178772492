import { Box, Checkbox, FormControlLabel, Modal } from '@mui/material';
import {
	CancelBtn,
	ConfirmBtn,
} from '@Pages/ClassRooms/Pages/SingleCourse/Pages/CourseForm/Pages/CourseContent/Pages/Webinar/Pages/RegisteredUsers/Components/ChangeUserSlotModule';
import { studentsGetSingle } from '@Pages/Students/Slice/Students.Slice';
import { useSnackbar } from '@Providers/useSnackbar';
import { setWalletRequest } from '@Services/students/students.req';
import { FlexLayout } from '@Styled/utilities';
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';

type Props = {
	handleModelClose: React.Dispatch<boolean>;
	openModel: boolean;
	walletAmount: string | number;
	id: string;
	defaultAmount: number;
	setLoading: React.Dispatch<React.SetStateAction<boolean>>;
	setDisplayedAmount: React.Dispatch<React.SetStateAction<number>>;
	setWalletAmount: React.Dispatch<React.SetStateAction<string | number>>;
	setError: React.Dispatch<React.SetStateAction<string>>;
};

const SetModel: React.FC<Props> = ({
	handleModelClose,
	openModel,
	walletAmount,
	id,
	defaultAmount,
	setLoading,
	setDisplayedAmount,
	setWalletAmount,
	setError,
}) => {
	const { displaySnackbar } = useSnackbar();
	const dispatch = useDispatch();

	const [type, setType] = useState<string>('');
	const _setWallet = useCallback(async () => {
		if (walletAmount === '')
			return displaySnackbar('error', 'Amount is required!');
		if (walletAmount === defaultAmount) return;
		try {
			setLoading(true);
			await setWalletRequest(id, Number(walletAmount), '', type);
			setDisplayedAmount(Number(walletAmount));
			setWalletAmount(0);
			setError('');
			setLoading(false);
			displaySnackbar('success', 'wallet is set successfully');
			handleModelClose(false);
			dispatch(studentsGetSingle({ id }));
		} catch (error) {
			const msg =
				error.response?.data.message ||
				"there's a problem updating the amount, please try again!";
			displaySnackbar('error', msg);
		}
	}, [walletAmount, id, displaySnackbar, defaultAmount, dispatch, type]);

	return (
		<Modal
			open={openModel}
			onClose={() => {
				handleModelClose(false);
			}}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Container>
				<Header>Are you sure you want to..?</Header>
				<FlexLayout flexDirection="column" marginTop="20px" marginBottom="20px">
					<FormControlLabel
						control={
							<Checkbox
								checked={type === 'refundable'}
								onClick={() => setType('refundable')}
								icon={<RadioButtonUncheckedIcon />}
								checkedIcon={<RadioButtonCheckedIcon />}
							/>
						}
						label="Set refundable amount"
					/>
					<FormControlLabel
						control={
							<Checkbox
								checked={type === 'nonrefundable'}
								onClick={() => setType('nonrefundable')}
								icon={<RadioButtonUncheckedIcon />}
								checkedIcon={<RadioButtonCheckedIcon />}
							/>
						}
						label="Set non refundable amount"
					/>
				</FlexLayout>
				<FlexLayout justifyContent="flex-end" gridGap="1rem">
					<CancelBtn
						variant="text"
						color="warning"
						onClick={() => {
							handleModelClose(false);
						}}
					>
						NO
					</CancelBtn>
					<ConfirmBtn
						variant="text"
						color="inherit"
						type="submit"
						onClick={() => {
							_setWallet();
						}}
					>
						YES
					</ConfirmBtn>
				</FlexLayout>
			</Container>
		</Modal>
	);
};

export const Container = styled(Box)`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	min-width: 24.125rem;
	border-radius: 4px;
	margin: 33px 9px 3px 11.8px;
	padding: 24px 8px 10px 23px;
	background-color: #ffffff;
	outline: none;
`;

export const Header = styled.p`
	color: rgba(0, 0, 0, 0.6);
	font-size: 14px;
	font-weight: normal;
`;

export default SetModel;
