import { EssayAnswerSchema, TestQuestionType } from '../Types/TestQuestion';

type TestQuestionItem = {
	type: TestQuestionType;
	title: string;
};

type AnswerSchemaType = {
	type: EssayAnswerSchema;
	title: string;
};

export const TestQuestions: Array<TestQuestionItem> = [
	{ type: 'mcq', title: 'Multiple Choice' },
	{ type: 'gap', title: 'Fill the gap' },
	{ type: 'ordering', title: 'Ordering' },
	{ type: 'essay', title: 'Essay' },
	// { type: 'matching', title: 'Matching' },
	// { type: 'dragAndDrop', title: 'Drag-and-drop' },
];

export const AnswerSchema: Array<AnswerSchemaType> = [
	{ type: 'text', title: 'Text' },
	{ type: 'attachment', title: 'Attachment' },
	{ type: '*', title: 'Both' },
];
