import EdFormControl from '@Components/UI/Inputs/EdFormControl/EdFormControl';
import TinyMceControl from '@Components/UI/TinyMceControl/TinyMceControl';
import { useTypedSelector } from '@Features/store';
import { DevTool } from '@hookform/devtools';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, InputAdornment } from '@mui/material';
import { useSnackbar } from '@Providers/useSnackbar';
import { FlexLayout, Spacer } from '@Styled/utilities';
import { Editor } from '@tinymce/tinymce-react';
import React, { useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { ContentConfig } from '../../../../Constants/CourseContent.constants';
import { CONTENT_SCHEMA } from '../../Schema/webContent.schema';
import { WebContentRequester } from '../../Services/WebContent.requester';
import { Content } from '../../Types/WebContent.types';

const EditWebContentInfo = () => {
	const editorRef = useRef<any>(null);
	const { displaySnackbar } = useSnackbar();
	const history = useHistory();

	const { currentEntity } = useTypedSelector((state) => state.WebContents);

	const defaultValues = React.useMemo<Content | undefined>(() => {
		if (currentEntity) {
			const defaultT: Content = {
				id: currentEntity?.webContent?.id,
				unit: {
					name: currentEntity?.unit?.name,
					course_id: currentEntity?.unit?.course_id,
					order: currentEntity?.unit?.order,
					type_id: currentEntity?.unit?.type_id,
				},
				webContent: {
					content: currentEntity?.webContent?.content,
				},
			};
			return defaultT;
		}
	}, []);

	const { control, formState, getValues } = useForm<Content>({
		mode: 'all',
		resolver: yupResolver(CONTENT_SCHEMA),
		defaultValues,
	});

	const onSave = async (form: Content) => {
		try {
			const {
				data: { data },
			} = await WebContentRequester.instance.update({
				...form,
			});
			displaySnackbar('success', `Web Content updated successfully`);
			history.goBack();
		} catch (e) {
			const msg = e.response?.data.message || 'Unable to updated Web Content ';
			displaySnackbar('error', msg);
		}
	};

	return (
		<FlexLayout flexDirection="column" px="2.625rem" flex="1">
			<DevTool control={control} />
			<EdFormControl
				size="small"
				InputProps={{
					startAdornment: (
						<InputAdornment position="start">
							{ContentConfig['webcontent'].icon}
						</InputAdornment>
					),
				}}
				control={control}
				label="Unit Name"
				name="unit.name"
			/>
			<Spacer mb="1.625rem" />
			<FlexLayout flex="1">
				<Controller
					control={control}
					name="webContent.content"
					render={({ field: { value, onChange, ...props } }) => {
						return (
							<TinyMceControl
								{...props}
								value={value}
								onEditorChange={(e) => onChange(e)}
								onInit={(evt, editor) => (editorRef.current = editor)}
							/>
						);
					}}
				/>
			</FlexLayout>
			<FlexLayout mt="1.688rem" justifyContent="flex-end">
				<Button
					variant="contained"
					color="warning"
					onClick={() => history.goBack()}
				>
					Cancel
				</Button>
				<Spacer mx="0.5rem" />
				<Button
					onClick={() => {
						const data = getValues();
						onSave(data);
					}}
					disabled={!formState.isValid || !formState.isDirty}
					variant="contained"
				>
					Save
				</Button>
			</FlexLayout>
		</FlexLayout>
	);
};

export default EditWebContentInfo;
