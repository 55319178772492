import MainTable from '@Components/MainTable';
import { MainTableRow } from '@Components/MainTable/MainTable';
import { onPageChangeRequestMeta } from '@Components/MainTable/MainTable.types';
import NoResults from '@Components/NoResults/NoResults';
import EdSpeedDial, {
	EdSpeedDialAction, SpeedDialCell,
} from '@Components/UI/Utilities/EdSpeedDial/EdSpeedDial';
import { TableHeader } from '@Features/generics/generics.type';
import { useTypedSelector } from '@Features/store';
import { TableCell } from '@mui/material';
import { useSnackbar } from '@Providers/useSnackbar';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
	registeredUsersGetAll,
	registeredUsersDeleteById,
} from '../../Slices/Registered.slice';
import DateTimeFormatter from '@Components/DateTimeFormatter/DateTimeFormatter';
import styled from 'styled-components';
import { RegisteredUser } from '../../Types/Webinar.types';
import { FlexLayout } from '@Styled/utilities';
import WebinarUnit from '../../Components/WebinarUnit';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import DateRangeIcon from '@mui/icons-material/DateRange';
import PersonPinIcon from '@mui/icons-material/PersonPin';
import EdConfirmModal from '@Components/UI/Modals/EdConfirmModal/EdConfirmModal';
import ChangeUserSlotModule from './Components/ChangeUserSlotModule';
import ChangeUserStatusModule from './Components/ChangeUserStatusModule';
import { useParams } from 'react-router-dom';
import PreviousNextSlots from './Components/PreviousNextSlots';
import { rgba } from 'polished';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const TABLE_HEADS: Array<TableHeader> = [
	{ displayName: 'First Name', fieldName: 'first_name' },
	{ displayName: 'Last Name', fieldName: 'last_name' },
	{ displayName: 'User Name', fieldName: 'username' },
	{ displayName: 'email', fieldName: 'email' },
	{ displayName: 'phone no.', fieldName: 'phone_number' },
	{ displayName: 'status ', fieldName: 'status' },
	{ displayName: 'Registered at ', fieldName: 'created_at' },
];

const RegisteredTable = () => {
	const dispatch = useDispatch();
	const [openModel, setOpenModel] = useState(false);
	const [openStatusModel, setOpenStatusModel] = useState(false);
	const [openUserModel, setOpenUserModel] = useState(false);
	const [currentStatus, setCurrentStatus] = useState<RegisteredUser>();
	const [deletedId, setDeletedId] = useState<any>('');
	const { id } = useParams<{ id: string }>();

	const { fetchAll, filters, meta, requestStatus } = useTypedSelector(
		(state) => state.RegisteredUsers
	);
	const { displaySnackbar } = useSnackbar();

	useEffect(() => {
		if (requestStatus.deleteById === 'DONE') {
			displaySnackbar('success', 'User delete successfully');
		} else if (requestStatus.deleteById === 'FAILED') {
			displaySnackbar('error', "Couldn't delete User");
		}
	}, [requestStatus.deleteById]);

	const onPageChangeRequest = useCallback(
		({
			activePage,
			endDate,
			filters,
			query,
			sortBy,
			startDate,
			perPage,
		}: onPageChangeRequestMeta) => {
			dispatch(
				registeredUsersGetAll({
					page: activePage,
					filters,
					perPage: perPage ?? 10,
					query: query,
					sortBy,
					from: startDate,
					to: endDate,
					id: id,
				})
			);
		},
		[id]
	);

	const handleModelOpen = (id: number) => {
		setOpenModel(true);
		setDeletedId(id);
	};

	const handleModelClose = (close: boolean, confirm?: boolean) => {
		if (confirm) {
			debugger;
			dispatch(
				registeredUsersDeleteById({
					id: id,
					payload: { slot_id: id, student_id: deletedId },
				})
			);
		}
		setOpenModel(false);
		setDeletedId(null);
	};

	const renderStatus = (user: RegisteredUser) => {
		switch (user.status) {
			case 'attended':
				return <StatueText color="#00d66b">{user.status}</StatueText>;
			case 'registered':
				return <StatueText color="#2196f3">{user.status}</StatueText>;
			default:
				return <StatueText color="#ff3100">{user.status}</StatueText>;
		}
	};

	return (
		<FlexLayout flexDirection="column" gridGap={39}>
			<WebinarUnit />
			<EdConfirmModal
				text="Are you sure you want remove this user from this slot?"
				open={openModel}
				handleClose={handleModelClose}
			/>
			<ChangeUserSlotModule
				handleModelClose={setOpenUserModel}
				openModel={openUserModel}
			/>
			<ChangeUserStatusModule
				handleModelClose={setOpenStatusModel}
				openModel={openStatusModel}
				currentStatus={currentStatus}
			/>
			<PreviousNextSlots />

			<div>
				<MainTable
					total={meta?.total ?? 0}
					dateFilter
					pageNum={meta?.current_page}
					tableFilters={filters}
					tableHeads={TABLE_HEADS}
					totalPages={meta?.last_page ?? 0}
					RowsperPage={Number(meta?.per_page)}
					$hasActions
					onPageChangeRequest={onPageChangeRequest}
					renderItems={() => {
						if (fetchAll.length <= 0)
							return (
								<MainTableRow>
									<TableCell colSpan={TABLE_HEADS.length}>
										<NoResults />
									</TableCell>
								</MainTableRow>
							);
						else {
							return fetchAll.map((user) => {
								return (
									<MainTableRow key={user.id} hover>
										<TableCell>{user.first_name}</TableCell>
										<TableCell>{user.last_name}</TableCell>
										<TableCell>{user.username}</TableCell>
										<CellStyle>{user.email}</CellStyle>
										<TableCell>{user.phone_number}</TableCell>
										<TableCell>{renderStatus(user)}</TableCell>
										<TableCell>
											<DateTimeFormatter date={user?.created_at} />
										</TableCell>
										<SpeedDialCell>
											<EdSpeedDial ariaLabel="SpeedDial basic example">
												<EdSpeedDialActionStyle
													key="status"
													icon={<PersonPinIcon>status</PersonPinIcon>}
													tooltipTitle="Status"
													onClick={(e) => {
														e.stopPropagation();
														setOpenStatusModel(true);
														setCurrentStatus(user);
													}}
												/>
												<EdSpeedDialActionStyle
													key="slot"
													icon={<DateRangeIcon>slot</DateRangeIcon>}
													tooltipTitle="Slot"
													onClick={(e) => {
														e.stopPropagation();
														setOpenUserModel(true);
													}}
												/>
												<EdSpeedDialActionStyle
													key="delete"
													icon={<PersonRemoveIcon />}
													tooltipTitle="Remove"
													onClick={(e) => {
														e.stopPropagation();
														e.preventDefault();
														handleModelOpen(user.student_id);
													}}
												/>
											</EdSpeedDial>
										</SpeedDialCell>
									</MainTableRow>
								);
							});
						}
					}}
				/>
			</div>
		</FlexLayout>
	);
};

const CellStyle = styled(TableCell)`
	font-family: Roboto;
	font-size: 1rem;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
`;

const StatueText = styled.div<{ color: string }>`
	color: ${({ color }) => color};
	font-weight: 500;
	text-transform: capitalize;
`;

const EdSpeedDialActionStyle = styled(EdSpeedDialAction)`
	&:hover {
		background-color: ${(props) =>
			rgba(props.theme.palette.primary.main, 0.95)};
	}
`;

export default RegisteredTable;
