import React, { useMemo } from 'react';
import { InputLabel, styled, TextField } from '@mui/material';
import {
	DatePickerProps,
	DateTimePicker,
	LocalizationProvider,
} from '@mui/x-date-pickers';
import '@mui/lab';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Controller } from 'react-hook-form';
import { RangePickerProps } from '@Components/UI/Pickers/Pickers.types';
import { orange } from '@mui/material/colors';

type Props = {
	isEditFrom: boolean;
};

const TimeDatePicker: React.FC<
	Props & RangePickerProps & Partial<DatePickerProps<any, any>>
> = ({ control, name, isEditFrom }) => {
	const minDate = useMemo(() => {
		const tempDate = new Date();
		tempDate.getDate();
		return tempDate;
	}, []);

	return (
		<Controller
			control={control}
			name={name}
			render={({ field: { value, ...controlProps }, fieldState }) => {
				return (
					<LocalizationProvider dateAdapter={AdapterDateFns}>
						<DateTimePicker
							disabled={isEditFrom}
							value={value ?? null}
							{...controlProps}
							minDate={minDate}
							minutesStep={5}
							renderInput={(params: any) => {
								return (
									<>
										<LabelStyle required>Start Date & Time</LabelStyle>
										<TextField
											disabled={isEditFrom}
											error={fieldState.invalid}
											helperText={fieldState.error?.message}
											{...params}
										/>
									</>
								);
							}}
						/>
					</LocalizationProvider>
				);
			}}
		/>
	);
};

const LabelStyle = styled(InputLabel)`
	font-size: 1rem;
	font-weight: 700;
	color: ${({ theme }) => theme.palette.darkPurple.main};
	span {
		color: ${orange['500']};
	}
`;

export default TimeDatePicker;
