import { BaseSliceController } from '@Base/slices/slices.base';
import { StudentTestsRequester } from '../Services/StudentTestsRequester';
import { StudentTestsPayload } from '../Types/Tests.types';

export class StudentTestsSlice extends BaseSliceController<
	StudentTestsPayload,
	'StudentTests',
	StudentTestsPayload
> {
	private static instance: StudentTestsSlice;
	static getInstance() {
		if (!StudentTestsSlice.instance) {
			StudentTestsSlice.instance = new StudentTestsSlice();
		}
		return StudentTestsSlice.instance;
	}
	constructor() {
		super('StudentTests', StudentTestsRequester.getInstance());
	}
}

export const { studentTestsGetAll, studentTestsExportData } =
	StudentTestsSlice.getInstance().getActions();
