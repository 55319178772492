import {
	ClassroomAdmissionPayload,
	ClassroomAdmissionResponse,
} from '@Pages/ClassRooms/Types/ClassroomAdmission.types';
import api from '@Services/api';
import { AxiosResponse } from 'axios';
import {
	CRUDBase,
	DeleteSingleEntityPayload,
	GetAllMeta,
	GetSingleEntityPayload,
} from 'src/base/services/services.base';

export class ClassroomAdmissionRequester
	implements CRUDBase<ClassroomAdmissionPayload, ClassroomAdmissionResponse>
{
	baseURL = 'admission/form/';
	getAll(
		payload?: GetAllMeta
	): Promise<AxiosResponse<ApiResponse<ClassroomAdmissionResponse[]>>>;
	getAll(
		payload: number
	): Promise<AxiosResponse<ApiResponse<ClassroomAdmissionResponse[]>>>;
	getAll(
		payload?: any
	): Promise<AxiosResponse<ApiResponse<ClassroomAdmissionResponse[]>>> {
		throw new Error('Method not implemented.');
	}
	private static instance: ClassroomAdmissionRequester =
		new ClassroomAdmissionRequester();
	static getInstance() {
		if (!ClassroomAdmissionRequester.instance) {
			ClassroomAdmissionRequester.instance = new ClassroomAdmissionRequester();
		}
		return ClassroomAdmissionRequester.instance;
	}

	get(
		id: string
	): Promise<AxiosResponse<ApiResponse<ClassroomAdmissionResponse>>>;
	get(
		id: number
	): Promise<AxiosResponse<ApiResponse<ClassroomAdmissionResponse>>>;
	get(
		id: string | number,
		payload: GetSingleEntityPayload
	): Promise<AxiosResponse<ApiResponse<ClassroomAdmissionResponse>>>;
	async get(
		id: any,
		payload?: any
	): Promise<AxiosResponse<ApiResponse<ClassroomAdmissionResponse>>> {
		return await api.post('/admission/form/get', { id: id });
	}
	async create(
		payload: Omit<ClassroomAdmissionPayload, 'id'>
	): Promise<AxiosResponse<ApiResponse<ClassroomAdmissionResponse>>> {
		return await api.post('/admission/form/create/full', payload);
	}
	async update(
		payload: ClassroomAdmissionPayload
	): Promise<AxiosResponse<ApiResponse<ClassroomAdmissionResponse>>> {
		return await api.post('/admission/form/update', payload);
	}
	delete(id: number): Promise<AxiosResponse<ApiResponse<any>>>;
	delete(id: string): Promise<AxiosResponse<ApiResponse<any>>>;
	delete(
		id: string | number,
		payload: DeleteSingleEntityPayload
	): Promise<AxiosResponse<ApiResponse<any>>>;
	async delete(
		id: any,
		payload?: any
	): Promise<import('axios').AxiosResponse<ApiResponse<any>>> {
		return await api.delete('/admission/form/question/remove', {
			data: payload,
		});
	}
	async removeQuestion(payload: RemoveQuestionPayload) {
		return await api.delete(`${this.baseURL}question/remove`, {
			data: payload,
		});
	}
}

type RemoveQuestionPayload = {
	admission_form_id: number;
	question_id: number;
};
