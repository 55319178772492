import { Invoice } from '@Pages/Invoices/Types/Invoices.types';
import api from '@Services/api';
import { AxiosResponse } from 'axios';
import {
	CRUDBase,
	DeleteSingleEntityPayload,
	GetAllMeta,
	GetSingleEntityPayload,
} from 'src/base/services/services.base';

interface InvoicesTabCRUD extends CRUDBase<Invoice, Invoice> {}
export class InvoicesTabRequester implements InvoicesTabCRUD {
	private static instance: InvoicesTabRequester;
	static getInstance() {
		if (!InvoicesTabRequester.instance) {
			InvoicesTabRequester.instance = new InvoicesTabRequester();
		}
		return InvoicesTabRequester.instance;
	}
	baseURL = 'user/profile';
	getAll(payload?: GetAllMeta): Promise<AxiosResponse<ApiResponse<Invoice[]>>>;
	getAll(payload: number): Promise<AxiosResponse<ApiResponse<Invoice[]>>>;
	async getAll(
		payload?: any
	): Promise<import('axios').AxiosResponse<ApiResponse<Invoice[]>>> {
		return await api.post(`${this.baseURL}/${payload.id}/invoices`, payload);
	}

	async export(payload: GetAllMeta): Promise<AxiosResponse<string>> {
		return await api.post(`${this.baseURL}/all`, { ...payload, export: true });
	}
	get(id: string): Promise<AxiosResponse<ApiResponse<Invoice>>>;
	get(id: number): Promise<AxiosResponse<ApiResponse<Invoice>>>;
	get(
		id: string | number,
		payload: GetSingleEntityPayload
	): Promise<AxiosResponse<ApiResponse<Invoice>>>;
	async get(
		id: any,
		payload?: any
	): Promise<AxiosResponse<ApiResponse<Invoice>>> {
		return await api.post(`${this.baseURL}`, { uuid: id });
	}

	async create(payload: Invoice): Promise<AxiosResponse<ApiResponse<Invoice>>> {
		return await api.post(`${this.baseURL}/create`, payload);
	}
	async update(payload: Invoice): Promise<AxiosResponse<ApiResponse<Invoice>>> {
		return await api.put(`${this.baseURL}/update`, payload);
	}
	async process(id: number): Promise<AxiosResponse<ApiResponse<any>>> {
		return await api.post(`${this.baseURL}/process`, { id });
	}
	delete(id: number): Promise<AxiosResponse<ApiResponse<any>>>;
	delete(id: string): Promise<AxiosResponse<ApiResponse<any>>>;
	delete(
		id: string | number,
		payload: DeleteSingleEntityPayload
	): Promise<AxiosResponse<ApiResponse<Invoice>>>;
	async delete(
		id: any,
		payload?: any
	): Promise<AxiosResponse<ApiResponse<any>>> {
		return await api.delete(`${this.baseURL}/delete`, {
			data: {
				uuid: id,
			},
		});
	}
}
