import {
	EduactionYear,
	EduaSections,
} from './../../services/classrooms/classrooms.res.types';
import { getEducationalInfoRequest } from './../../services/classrooms/classrooms.req';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	EduactionType,
	GetEducationalInfoResponse,
	SelectOption,
} from '@Services/classrooms/classrooms.res.types';
import createThunkBody from '@Utils/createThunkBody';
import EdResponse from '@Services/generics/response';

// getting the educational info
export const getEducationalInfo = createAsyncThunk<EdResponse<EduactionType[]>>(
	'educationalinfoData',
	async (thunkApi) => {
		return await createThunkBody<GetEducationalInfoResponse>(
			getEducationalInfoRequest(),
			thunkApi,
			'Error fetching educational info'
		);
	}
);

//
export interface educationalState {
	educational_info: Array<EduactionType>;
	educational_type: Array<SelectOption>;
	educational_year: Array<EduactionYear>;
	educational_language: Array<SelectOption>;
	educational_sections: Array<EduaSections>;
	selected_types: string;
	selected_years: string;
	selected_languages: Array<string>;
	selected_section: Array<string>;
	Educationalloaders: {
		getEducationalInfo: AsyncLoader;
		getEducationaltypes: AsyncLoader;
	};
	errors: {
		getEducationalInfo: string;
	};
}
const initialState: educationalState = {
	educational_info: [],
	educational_type: [],
	educational_year: [],
	educational_language: [],
	educational_sections: [],
	selected_types: '',
	selected_years: '',
	selected_languages: [],
	selected_section: [],
	Educationalloaders: {
		getEducationalInfo: null,
		getEducationaltypes: null,
	},
	errors: {
		getEducationalInfo: '',
	},
};

const educationalInfoSlice = createSlice({
	name: 'educational',
	initialState,
	reducers: {
		educationaltype: (state, { payload }: PayloadAction<any>) => {
			if (!payload) return;
			const _types: any = state.educational_info.find(
				(_) => _.id?.toString() === payload.toString()
			);
			let _years: any = null;
			let _languages: any = null;
			if (_types) {
				_years = _types.educationYears?.map((_: any) => {
					return {
						name: _.name,
						value: _.id,
						educationSections: _.educationSections,
					};
				});
				_languages = _types.educationLanguages?.map((_: any) => {
					return { name: _.name, value: _.id };
				});
			}
			state.educational_year = _years;
			state.educational_language = _languages;
			state.educational_sections = [];
		},

		educationalsections: (state, { payload }: PayloadAction<any>) => {
			if (!payload) return;
			state.educational_sections = [];
			let _years: any = null;
			if (state.educational_year) {
				_years = state.educational_year.find(
					(_) => Number(_?.value) === Number(payload)
				);
			}
			let _sections: any = null;
			if (_years) {
				_sections = _years.educationSections;
			}
			state.educational_sections = _sections;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getEducationalInfo.pending, (state) => {
				state.Educationalloaders.getEducationalInfo = true;
				state.errors.getEducationalInfo = '';
			})
			.addCase(getEducationalInfo.fulfilled, (state, { payload }) => {
				state.educational_info = payload.data;

				const _mappedTypes: SelectOption[] = state.educational_info.map((_) => {
					return { name: _.name, value: _?.id };
				});
				state.educational_type = _mappedTypes;
				state.Educationalloaders.getEducationalInfo = false;
			})
			.addCase(getEducationalInfo.rejected, (state, { payload }) => {
				state.Educationalloaders.getEducationalInfo = undefined;

				state.errors.getEducationalInfo =
					'could not get unenrolled users, please try again!';
			});
	},
});

export const { educationaltype, educationalsections } =
	educationalInfoSlice.actions;
export default educationalInfoSlice.reducer;
