import { boolean, object, string, number } from 'yup';

export const ASSIGNMENT_SCHEMA = object({
	unit: object({
		course_id: number(),
		type_id: number(),
		name: string(),
		order: number(),
		active: boolean(),
	}),
	assignment: object({
		question: string().required(),
		question_type: string(),
		questionType: string(),
		overall_score: number()
			.typeError('Capacity Must be a number')
			.moreThan(0, 'Capacity must be bigger than zero')
			.required(),
		overallScore: number()
			.typeError('Capacity Must be a number')
			.moreThan(0, 'Capacity must be bigger than zero'),
		duration: number()
			.typeError('Capacity Must be a number')
			.moreThan(0, 'Capacity must be bigger than zero')
			.required(),
		show_status: boolean().default(false),
		showStatus: boolean().default(false),
		extension: string().default('pdf'),
	}),
});
