import React from 'react';
import { FieldPath, UseFormSetValue } from 'react-hook-form';
import { get } from 'lodash';
type FormUpdater<T extends {}> = {
	data: T;
	setValue: UseFormSetValue<T>;
	keys?: FieldPath<T> | Array<FieldPath<T>>;
};

export const useFormUpdater = <T extends {}>({
	data,
	setValue,
	keys,
}: FormUpdater<T>) => {
	const updater = React.useCallback(() => {
		if (keys) {
			if (Array.isArray(keys)) {
				keys.forEach((key) => {
					const value = get(data, key);
					setValue(key, value as any);
				});
			} else {
				setValue(keys, get(data as any, keys as any));
			}
		} else {
			Object.keys(data).forEach((key: string) => {
				setValue(key as FieldPath<T>, get(data, key));
			});
		}
	}, [JSON.stringify(data)]);

	return [updater];
};
