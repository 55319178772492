import api from '@Services/api';
import { GraphSlice } from '@Services/classrooms/classrooms.res.types';
import {
	CourseOverviewStats,
	CourseReportGraphPayload,
} from '../Types/CourseOverviewReport.types';

class CourseOverviewReportRequester {
	static instance: CourseOverviewReportRequester =
		new CourseOverviewReportRequester();
	async getReport(id: number) {
		return await api.post<ApiResponse<CourseOverviewStats>>(
			'reports/course/summary',
			{ id }
		);
	}

	async getGraph(payload: CourseReportGraphPayload) {
		return await api.post<ApiResponse<GraphSlice[]>>(
			'reports/course/summary/graph',
			payload
		);
	}
}

export default CourseOverviewReportRequester;
