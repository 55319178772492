import React from 'react';
import InvoicesTab from '@Pages/Invoices';
import { RouteGroup, SingleRoute } from '@Constants/routes';
import SingleInvoice from '../Pages/SingleInvoice/SingleInvoice';

export const SingleInvoiceRoute: SingleRoute = {
	code: '',
	component: <SingleInvoice />,
	route: 'invoice/:id',
	offMenu: true,
	title: '',
};

export const InvoicesRoute: RouteGroup = {
	isRouted: true,
	isParent: true,
	title: 'Invoices',
	icon: 'payments',
	route: 'invoices',
	code: 'v-inv',
	component: <InvoicesTab />,
	routes: [SingleInvoiceRoute],
};
