/* eslint-disable no-extend-native */
export {};

declare global {
	interface String {
		trimString(trimCount?: number): string;
	}
	interface Array<T> {
		filterDups(key: keyof T): T[];
	}

	interface Date {
		isExpired(): boolean;
	}
}
const filterDupsFn = function <T>(this: T[], key: keyof T) {
	const filtered = this.filter(
		(elem, index) => index === this.findIndex((self) => self[key] === elem[key])
	);
	return filtered;
};
const isExpired = function (this: Date) {
	const currentDate = new Date();
	return currentDate > this;
};
if (!Date.prototype.isExpired) {
	window.Date.prototype.isExpired = isExpired;
	Date.prototype.isExpired = isExpired;
}
if (!Array.prototype.filterDups) {
	window.Array.prototype.filterDups = filterDupsFn;
	Array.prototype.filterDups = filterDupsFn;
}
