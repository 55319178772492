import { BaseSliceController } from '@Base/slices/slices.base';
import { StudentWebinarsRequester } from '../Services/StudentWebinars.service';
import { StudentWebinarAnalytic } from '../Types/StudentWebinars.types';

export class StudentWebinarsSlice extends BaseSliceController<
	StudentWebinarAnalytic,
	'StudentWebinars'
> {
	public static instance: StudentWebinarsSlice = new StudentWebinarsSlice();
	constructor() {
		super('StudentWebinars', StudentWebinarsRequester.instance);
	}
}

export const { studentWebinarsGetAll, studentWebinarsExportData } =
	StudentWebinarsSlice.instance.getActions();
