import { Modal } from '@mui/material';
import React, { useState } from 'react';
import styled from 'styled-components';
import { changeUserStatus } from '../../../Services/RegisteredUsers/RegisteredRequester';
import { useParams } from 'react-router-dom';
import {
	ButtonsContainer,
	Container,
	Header,
	CancelBtn,
	ConfirmBtn,
} from './ChangeUserSlotModule';
import { StatusTypes } from '../constants/registeredUsers.constatns';
import { useSnackbar } from '@Providers/useSnackbar';
import { registeredUsersGetAll } from '../../../Slices/Registered.slice';
import { useDispatch } from 'react-redux';
import useQueryURL from '@Hooks/useQueryUrl';
import { RegisteredUser } from '../../../Types/Webinar.types';

type Props = {
	handleModelClose: React.Dispatch<boolean>;
	openModel: boolean;
	currentStatus: RegisteredUser | undefined;
};

const ChangeUserStatusModule: React.FC<Props> = ({
	handleModelClose,
	openModel,
	currentStatus,
}) => {
	const { id } = useParams<{ id: string }>();
	const [active, setActive] = useState<string | undefined>('');
	const [status, setStatus] = useState<string>('');
	const { displaySnackbar } = useSnackbar();
	const dispatch = useDispatch();
	const { selectedQuery } = useQueryURL();

	const changeStatus = async () => {
		try {
			await changeUserStatus(
				Number(id),
				currentStatus?.student_id as number,
				status
			);
			displaySnackbar('success', `Slot Status changed successfully`);
			dispatch(
				registeredUsersGetAll({
					page: 1,
					filters: [],
					perPage: 10,
					query: selectedQuery,
					sortBy: null,
					id: id,
				})
			);
			handleModelClose(false);
		} catch (e) {
			const msg = e.response?.data.message || 'Unable to Change Slot Status';
			displaySnackbar('error', msg);
		}
	};

	return (
		<>
			<Modal
				open={openModel}
				onClose={() => {
					handleModelClose(false);
				}}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Container>
					<Header>Please select on another status for this user</Header>
					<StatusStyle>
						{StatusTypes.map((_, i) => (
							<Text
								bg={active === _ ? 'rgba(0, 0, 0, 0.04)' : null}
								onClick={(e) => {
									setStatus(_);
									setActive(_);
								}}
							>
								{_}
							</Text>
						))}
					</StatusStyle>
					<ButtonsContainer>
						<CancelBtn
							variant="text"
							color="warning"
							onClick={() => {
								handleModelClose(false);
							}}
						>
							Cancel
						</CancelBtn>
						<ConfirmBtn
							variant="text"
							color="inherit"
							type="submit"
							onClick={() => {
								changeStatus();
							}}
						>
							Confirm
						</ConfirmBtn>
					</ButtonsContainer>
				</Container>
			</Modal>
		</>
	);
};

const StatusStyle = styled.div`
	box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.38);
	border-radius: 4px;
	margin-top: 2rem;
	padding-top: 6px;
	padding-bottom: 6px;
`;

const Text = styled.p<{ bg: string | null }>`
	font-size: 1rem;
	cursor: pointer;
	padding: 4px 1rem;
	color: #212121;
	height: 2rem;
	display: flex;
	align-items: center;
	font-weight: 500;
	text-transform: capitalize;
	background: ${({ bg }) => bg};
	&:hover {
		background: rgba(0, 0, 0, 0.04);
	}
`;

export default ChangeUserStatusModule;
