import { number, object, string } from 'yup';

export const ADD_Document = object({
	unit: object({
		course_id: number().required(),
		type_id: number().default(1),
		name: string().required(),
		order: number().nullable(true),
	}),
	document: object({
		uri: string().required(),
		extension: string().default('pdf'),
		size: number(),
		file_name: string(),
	}),
});

export const Edit_Document = ADD_Document.concat(
	object({
		document_id: number().required(),
	})
);

export type AddDocumentForm = typeof ADD_Document.__outputType;
