import { BaseSliceController } from '@Base/slices/slices.base';
import { WebinarRequester } from '../Services/Webinar/WebinarRequester';
import { WebinarPayload, Webinar } from '../Types/Webinar.types';

export class WebinarSlice extends BaseSliceController<
	WebinarPayload,
	'Webinars',
	WebinarPayload,
	Webinar
> {
	private static instance: WebinarSlice;
	static getInstance() {
		if (!WebinarSlice.instance) {
			WebinarSlice.instance = new WebinarSlice();
		}
		return WebinarSlice.instance;
	}
	constructor() {
		super('Webinars', WebinarRequester.getInstance());
	}
}

export const {
	webinarsDeleteById,
	webinarsExportData,
	webinarsGenerate,
	webinarsGetAll,
	webinarsGetSingle,
	webinarsToggleActive,
	webinarsUpdate,
} = WebinarSlice.getInstance().getActions();
