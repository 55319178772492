import {
	CRUDBase,
	DeleteSingleEntityPayload,
	GenerateCardMeta,
	GenerateCodesMeta,
	GetAllMeta,
	GetSingleEntityPayload,
} from '@Base/services/services.base';
import api from '@Services/api';
import { AxiosResponse } from 'axios';
import { TestNotification } from '../../../../Types/Test/Test.types';

export class TestNotificationRequester implements CRUDBase<TestNotification> {
	export?(payload: GetAllMeta): Promise<AxiosResponse<string>> {
		throw new Error('Method not implemented.');
	}
	getAll(
		payload?: GetAllMeta | undefined
	): Promise<AxiosResponse<ApiResponse<TestNotification[]>>>;
	getAll(
		payload: number
	): Promise<AxiosResponse<ApiResponse<TestNotification[]>>>;
	getAll(
		payload?: unknown
	): Promise<AxiosResponse<ApiResponse<TestNotification[]>>> {
		throw new Error('Method not implemented.');
	}
	get(id: string): Promise<AxiosResponse<ApiResponse<TestNotification>>>;
	get(id: number): Promise<AxiosResponse<ApiResponse<TestNotification>>>;
	get(
		id: string | number,
		tId?: string | number | undefined,
		payload?: GetSingleEntityPayload | undefined
	): Promise<AxiosResponse<ApiResponse<TestNotification>>>;
	get(
		id: unknown,
		tId?: unknown,
		payload?: unknown
	): Promise<AxiosResponse<ApiResponse<TestNotification>>> {
		throw new Error('Method not implemented.');
	}
	create(
		payload: Omit<TestNotification, 'id'>
	): Promise<AxiosResponse<ApiResponse<TestNotification>>> {
		throw new Error('Method not implemented.');
	}
	async update(
		payload: TestNotification
	): Promise<AxiosResponse<ApiResponse<TestNotification>>> {
		return await api.post(`${this.baseURL}update`, payload);
	}
	delete(id: number): Promise<AxiosResponse<ApiResponse<any>>>;
	delete(id: string): Promise<AxiosResponse<ApiResponse<any>>>;
	delete(
		id: string | number,
		payload?: DeleteSingleEntityPayload | undefined
	): Promise<AxiosResponse<ApiResponse<any>>>;
	delete(
		id: unknown,
		payload?: unknown
	): Promise<AxiosResponse<ApiResponse<any>>> {
		throw new Error('Method not implemented.');
	}
	generate?(
		payload: GenerateCodesMeta | GenerateCardMeta
	): Promise<AxiosResponse<string>> {
		throw new Error('Method not implemented.');
	}
	toggleActive?(
		payload: TestNotification
	): Promise<AxiosResponse<ApiResponse<TestNotification>>> {
		throw new Error('Method not implemented.');
	}
	process?(id: number): Promise<AxiosResponse<ApiResponse<any>>> {
		throw new Error('Method not implemented.');
	}

	baseURL = 'test/notifications/';
	public static instance: TestNotificationRequester =
		new TestNotificationRequester();
}
