import React from 'react';
import { useTypedSelector } from '@Features/store';
import AddVideoInfo from './Components/AddVideoInfo';

const AddVideo = () => {
	const { currentCourse } = useTypedSelector((state) => state.classroom);

	return <>{currentCourse && <AddVideoInfo />}</>;
};

export default AddVideo;
