import React from 'react';
import styled from 'styled-components';
import EduactCardIcon from '@Pages/Students/StudentTabs/Info/assets/eduact-card-icon.svg';
import Avatar from '@Pages/Students/StudentTabs/Info/assets/avatar.png';
import EdIcon from '@Components/UI/Utilities/EdIcon/EdIcon';
import EduactCardBg from '@Pages/Students/StudentTabs/Info/assets/card_bg.jpg';
import { useTypedSelector } from '@Features/store';
import { rgba } from 'polished';
import QRCode from '@eduact/ed-components/DataDisplay/QRCode';

const StudentCardId: React.FC = () => {
	const { currentEntity } = useTypedSelector((state) => state.Students);

	const { educational_info, educational_type } = useTypedSelector(
		(state) => state.educational
	);

	const years = React.useMemo(() => {
		const flatYears = educational_info
			.filter((_) => _.id)
			.map(({ educationYears }) => educationYears)
			.flat();
		return flatYears.filterDups('id');
	}, [educational_info]);

	const sections = React.useMemo(() => {
		const flatYears = educational_info
			.map(({ educationYears }) => educationYears)
			.flat()
			.filterDups('id')
			.filter((_) => _?.id);

		return flatYears
			.map(({ educationSections }) => educationSections)
			.flat()
			.filterDups('id');
	}, [educational_info]);
	return (
		<StyledOuterContainer>
			<StyledCardHeaderContainer>
				<StyledStudentIDText>Student ID</StyledStudentIDText>

				<img src={EduactCardIcon} alt="eduact icon" />
			</StyledCardHeaderContainer>

			<StyledBodyOuterContainer>
				<StyledBodyInnerContainer>
					<StyledBodyContentContainer>
						<StyledProfilePhoto
							alt="avatar"
							src={currentEntity.profile_photo ?? Avatar}
						/>

						<StyledBodyContentInnerContainer>
							<div>
								<StyledNameText>
									{`${currentEntity.first_name} ${currentEntity.last_name}`}
								</StyledNameText>
								<p>
									username:{' '}
									<span
										style={{
											fontWeight: 500,
										}}
									>
										{currentEntity.username}
									</span>
								</p>
							</div>

							<div>
								<StyledPhoneText>
									<StyledIcon>phone</StyledIcon>
									{currentEntity.phone_number}
								</StyledPhoneText>
								<StyledEmail>
									<StyledIcon>mail</StyledIcon>
									{currentEntity.email}
								</StyledEmail>
							</div>
						</StyledBodyContentInnerContainer>
					</StyledBodyContentContainer>
					<StyledFooterContainer>
						<StyledFooterInnerContainer>
							<p>Diploma:</p>
							<StyledDataFooterText>
								{
									educational_type?.find(
										(_) => _.value === currentEntity.student.education_type_id
									)?.name
								}
							</StyledDataFooterText>
							<p>Year:</p>
							<StyledDataFooterText>
								{years
									?.find(
										(_: any) =>
											_?.id === currentEntity.student.education_year_id
									)
									?.name?.replaceAll('_', ' ')}
							</StyledDataFooterText>
							<p>Branch:</p>
							<StyledDataFooterText>
								{
									sections.find(
										(_: any) =>
											_.id === currentEntity.student.education_section_id
									)?.name
								}
							</StyledDataFooterText>
						</StyledFooterInnerContainer>
						<QRCode width={72} value={currentEntity.username} />
					</StyledFooterContainer>
				</StyledBodyInnerContainer>
				<StyledBackgroundImage src={EduactCardBg} alt="card-bg" style={{}} />
			</StyledBodyOuterContainer>
		</StyledOuterContainer>
	);
};
const StyledOuterContainer = styled.div`
	width: 460px;
	height: 275px;
	margin-top: 40px;
	margin-right: 20px;
	border-radius: 10px;
	overflow: hidden;
	position: relative;
	box-shadow: rgba(50, 50, 93, 0.25) 0 13px 27px -5px,
		rgba(0, 0, 0, 0.3) 0 8px 16px -8px;
`;
const StyledCardHeaderContainer = styled.div`
	display: flex;
	justify-content: space-between;
	background-color: #5ac0fc;
	color: #fff;
	padding: 0 20px;
	position: relative;
	z-index: 2;
`;
const StyledStudentIDText = styled.p`
	font-size: 28px;
	font-weight: 600;
	margin: 10px 0;
`;
const StyledBodyOuterContainer = styled.div`
	position: relative;
	height: 280px;
	width: 460px;
`;
const StyledBodyInnerContainer = styled.div`
	position: absolute;
	padding: 10px 20px;
	width: 90%;
`;
const StyledBodyContentContainer = styled.div`
	display: flex;
	gap: 12px;
`;
const StyledBodyContentInnerContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 6px 0;
`;
const StyledProfilePhoto = styled.img`
	width: 114px;
	height: 114px;
	object-fit: contain;
	z-index: 9999;
	position: relative;
`;
const StyledNameText = styled.p`
	margin-bottom: 3px;
	font-weight: 500;
`;
const StyledPhoneText = styled.p`
	margin-bottom: 3px;
	font-weight: 500;
	color: #2196f3;
`;
const StyledIcon = styled(EdIcon)`
	color: gray;
	position: relative;
	top: 6px;
	margin-right: 2px;
`;
const StyledEmail = styled.p`
	color: #2196f3;
	text-decoration: underline;
`;
const StyledFooterContainer = styled.div`
	display: flex;
	justify-content: space-between;
	padding: 16px 0;
`;
const StyledFooterInnerContainer = styled.div`
	display: grid;
	grid-template-columns: 100px 140px;
	grid-template-rows: repeat(3, 1fr);
`;
const StyledDataFooterText = styled.p`
	font-weight: 500;
`;
const StyledQRContainer = styled.div`
	background-color: white;
	z-index: 3;
`;
const StyledBackgroundImage = styled.img`
	position: absolute;
	bottom: 48px;
	z-index: 1;
	width: 460px;
	background: ${rgba('#5AC0FC', 0.1)};
`;
export default StudentCardId;
