import MainTable from '@Components/MainTable';
import { MainTableRow } from '@Components/MainTable/MainTable';
import { onPageChangeRequestMeta } from '@Components/MainTable/MainTable.types';
import NoResults from '@Components/NoResults/NoResults';
import EdSpeedDial, {
	EdSpeedDialAction,
} from '@Components/UI/Utilities/EdSpeedDial/EdSpeedDial';
import { TableHeader } from '@Features/generics/generics.type';
import { useTypedSelector } from '@Features/store';
import { Chip, TableCell, Tooltip } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { testsReportsGetAll } from '../../Slices/TestsReports.slice';
import { TableCellStyled } from './TestsTable';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import styled from 'styled-components';
import { UsersRequester } from '@Pages/ClassRooms/Pages/SingleCourse/Pages/Users/Services/Userprogress/UserProgressRequester';
import { useSnackbar } from '@Providers/useSnackbar';
import EdConfirmModal from '@Components/UI/Modals/EdConfirmModal/EdConfirmModal';
import ReportCards from './Components/ReportCards';
import { singleTestReportGetAll } from '../../Slices/SingleTestReport.slice';
import DateTimeFormatter from '@Components/DateTimeFormatter/DateTimeFormatter';
import TestAttemptModal from './Components/TestAttemptModal';
import { CourseSingleTest } from '../../Types/TestReports.types';
import { fetchTestAttemptsDropdown } from '@Features/dropdowns/dropdownsSlice';
import { FaWhatsapp } from 'react-icons/fa';
import { theme } from '@Styled/theme';
import { TestReportsRequester } from '../../Services/TestsReports.requester';
import { SingleTestReportRequester } from '../../Services/SingleTestReport.requester';

import { AiOutlineCheck } from 'react-icons/ai';
const TABLE_HEADS: Array<TableHeader> = [
	{ displayName: 'UserName', fieldName: 'name', width: '13%' },
	{
		displayName: 'Name',
		// fieldName: 'first_name',
		// width: '11%',
	},

	{
		displayName: 'Phone',
		fieldName: 'phone',
		width: '12%',
	},
	{ displayName: 'Parent', fieldName: 'parent_phone', width: '11%' },
	{ displayName: '#Attempts', fieldName: 'attempts_count', width: '11%' },
	{ displayName: 'Date', fieldName: 'attempt_date', width: '13%' },
	{
		displayName: 'Score',
		fieldName: 'attempt_score',
		width: '12%',
	},
	{
		displayName: 'Notifications',
		width: '12%',
	},
];

const SingleTestTable = () => {
	const { id } = useParams<{ id: string }>();
	const { displaySnackbar } = useSnackbar();
	const [userId, setUserId] = useState<CourseSingleTest | undefined>();
	const [openResetModel, setOpenResetModel] = useState<boolean>(false);
	const [showModal, setShowModal] = useState<boolean>(false);

	const dispatch = useDispatch();

	const { fetchAll, filters, meta, query } = useTypedSelector(
		(state) => state.TestsReports
	);
	const { currentEntity } = useTypedSelector((state) => state.Tests);
	const { currentCourse } = useTypedSelector((state) => state.classroom);

	const getAllReports = () => {
		dispatch(
			testsReportsGetAll({
				page: meta?.current_page as number,
				perPage: meta?.per_page ?? 10,
				filters,
				sortBy: {},
				query: query as string,
				id: currentCourse?.id,
			})
		);
	};
	const getSingleTest = () => {
		dispatch(
			singleTestReportGetAll({
				page: 1,
				perPage: 10,
				filters: [],
				sortBy: {},
				query: '',
				id,
			})
		);
	};

	useEffect(() => {
		getAllReports();
	}, []);

	const {
		fetchAll: singleTest,
		filters: testFilter,
		meta: testMeta,
	} = useTypedSelector((state) => state.SingleTestReport);

	const reports = fetchAll.find((_) => _.id === Number(id));
	const [refetch, setRefetch] = useState(false);

	const onPageChangeRequest = useCallback(
		({
			activePage,
			endDate,
			filters,
			query,
			sortBy,
			startDate,
			perPage,
		}: onPageChangeRequestMeta) => {
				dispatch(
					singleTestReportGetAll({
						page: activePage,
						filters,
						perPage: perPage ?? 10,
						query,
						sortBy,
						from: startDate,
						to: endDate,
						id,
					})
				);
			setRefetch(false);
		},
		[]
	);

	const renderStatus = (status: string) => {
		let color: string;
		switch (status) {
			case 'passed':
				return (color = '#00d66b');
			case 'failed':
				return (color = '#ff3100');
			default:
				return;
		}
	};

	const handleModelResetClose = async (close: boolean, confirm?: boolean) => {
		if (confirm) {
			try {
				if (userId?.student_id) {
					UsersRequester.getInstance()
						.toggleUnits(Number(id), userId.student_id)
						.then(() => {
							getSingleTest();
							getAllReports();
						});
				}
				displaySnackbar('success', `Reset User successfully`);
			} catch (e) {
				const msg = e?.data.message;
				displaySnackbar('error', msg);
			}
		}
		setOpenResetModel(false);
	};

	const attempts = useTypedSelector(
		(state) => state.dropdowns.dropdownTestAttempts
	);

	useEffect(() => {
		if (reports?.id && userId?.student_id) {
			dispatch(
				fetchTestAttemptsDropdown({
					unitId: reports?.id,
					userId: userId?.student_id,
				})
			);
		}
	}, [reports?.id, userId?.student_id]);

	const handleSendNotification = async (id: number) => {
		if (!currentCourse) return;
		const course_id = currentCourse.id;
		const attempt_id = id;
		const {
			data: { data },
		} = await SingleTestReportRequester.getInstance().notification({
			attempt_id,
			course_id,
		});
		window.open(data.message, '_blank');
		setRefetch(true);
	};

	return (
		<>
			<EdConfirmModal
				text="Are you sure you want to reset this User?"
				open={openResetModel}
				handleClose={handleModelResetClose}
			/>
			<Name>{reports?.name}</Name>
			<ReportCards report={reports?.report} />
			{userId && (
				<TestAttemptModal
					handleModelClose={setShowModal}
					openModel={showModal}
					user={userId}
					testInfo={reports}
					attempts={attempts}
					setUserId={setUserId}
				/>
			)}
			<MainTable
				total={testMeta?.total ?? 0}
				dateFilter
				refetch={refetch}
				pageNum={testMeta?.current_page}
				tableFilters={testFilter}
				tableHeads={TABLE_HEADS}
				totalPages={testMeta?.last_page ?? 0}
				RowsperPage={Number(testMeta?.per_page)}
				$hasActions
				onPageChangeRequest={onPageChangeRequest}
				renderItems={() => {
					return singleTest?.length <= 0 ? (
						<MainTableRow>
							<TableCell colSpan={TABLE_HEADS.length}>
								<NoResults />
							</TableCell>
						</MainTableRow>
					) : (
						singleTest?.map((user, index) => {
							return (
								<MainTableRow
									onClick={() => {
										setUserId(user);
										setShowModal(true);
									}}
									key={user?.id}
									hover
								>
									<Tooltip title={user?.username} placement="top">
										<TableCellStyled>{user?.username}</TableCellStyled>
									</Tooltip>
									<Tooltip
										title={`${user?.first_name} ${user?.last_name}`}
										placement="top"
									>
										<TableCellStyled>
											{user?.first_name} {user?.last_name}
										</TableCellStyled>
									</Tooltip>

									<Tooltip title={user?.phone_number} placement="top">
										<TableCellStyled>{user?.phone_number}</TableCellStyled>
									</Tooltip>
									<Tooltip title={user?.parent_phone} placement="top">
										<TableCellStyled>{user?.parent_phone}</TableCellStyled>
									</Tooltip>
									<TableCellStyled>{user?.attempts_count}</TableCellStyled>

									{!user?.created_at ? (
										'-'
									) : (
										<Tooltip
											title={<DateTimeFormatter date={user?.created_at} />}
											placement="top"
										>
											<TableCellStyled>
												<DateTimeFormatter date={user?.created_at} />
											</TableCellStyled>
										</Tooltip>
									)}
									<TableCellStyled>
										<Status background={renderStatus(user?.status)}>
											{user?.score} / {user?.overall_score}
										</Status>
									</TableCellStyled>
									<TableCellStyled width={'8rem'}>
										<Chip
											color={user.notification_sent ? 'success' : 'default'}
											onClick={(e) => {
												e.stopPropagation();
												handleSendNotification(user.id);
											}}
											icon={
												<FaWhatsapp
													color={
														user.notification_sent
															? theme.palette.grey[200]
															: theme.palette.success.main
													}
													size={18}
												/>
											}
											label={
												user.notification_sent ? <AiOutlineCheck /> : 'Send'
											}
											size="medium"
											sx={{ px: '5px' }}
										/>
									</TableCellStyled>
									<TableCell>
										<EdSpeedDial ariaLabel="SpeedDial basic example">
											<EdSpeedDialAction
												key="reset test"
												icon={<RestartAltIcon color="action" />}
												tooltipTitle="Reset test"
												tooltipPlacement="left"
												onClick={(e) => {
													e.stopPropagation();
													setUserId(user);
													setOpenResetModel(true);
												}}
											/>
										</EdSpeedDial>
									</TableCell>
								</MainTableRow>
							);
						})
					);
				}}
			/>
		</>
	);
};

const Status = styled.p<{ background: string | undefined }>`
	background: ${({ background }) => background};
	height: 1.688rem;
	width: fit-content;
	font-size: 0.875rem;
	font-weight: bold;
	color: #fff;
	border-radius: 16px;
	padding: 0.25rem 1rem;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 auto;
`;
const Name = styled.p`
	color: #251b33;
	font-size: 1.375rem;
	font-weight: 600;
	margin-top: 1.531rem;
	margin-bottom: 1rem;
`;

export default SingleTestTable;
