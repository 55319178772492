import React, { useEffect } from 'react';
import EdFormControl from '@Components/UI/Inputs/EdFormControl/EdFormControl';
import { Control, FormState } from 'react-hook-form';
import EdUploadFile from '@Components/UI/Inputs/EdUploadFile/EdUploadFile';
import { Icon, MenuItem, Stack } from '@mui/material';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import {
	CancelBtn,
	SaveBtn,
} from '@Pages/Roles/pages/AddRole/Components/addRoleInfo';
import { Admin, AdminPayload } from '@Pages/Admin/Types/admin.types';
import { GenderTypes } from '@Pages/Admin/Constants/admin.constants';
import { AdminForm } from '@Pages/Admin/Schema/AdminsInfo.schema';

export type onNextCallback = (formData: Admin) => void;

type Props = {
	control: Control<AdminPayload>;
	getValues: any;
	formState: FormState<AdminForm>;
	onNext: onNextCallback;
	watch: any;
	onHasRolesChange: React.Dispatch<boolean>;
	onIsValidChange?: React.Dispatch<boolean>;
};

const AddAdminInfo: React.FC<Props> = ({
	control,
	getValues,
	formState,
	onNext,
	watch,
	onHasRolesChange,
	onIsValidChange,
}) => {
	const history = useHistory();

	const watchPhoto = watch('profile_photo');
	useEffect(() => {
		if (watchPhoto !== undefined) {
			onHasRolesChange(watchPhoto);
		}
	}, [watchPhoto, onHasRolesChange]);

	useEffect(() => {
		onIsValidChange && onIsValidChange(formState.isValid);
	}, [formState.isValid]);

	return (
		<>
			<GroupStyle>
				<EdFormControl
					control={control}
					required
					name="first_name"
					label="First Name"
				/>
				<EdFormControl
					control={control}
					name="middle_name"
					label="Middle Name"
				/>
				<EdFormControl
					control={control}
					required
					name="last_name"
					label="Last Name"
				/>
			</GroupStyle>
			<SpaceStyle>
				<EdFormControl control={control} required name="email" label="Email" />
				<EdFormControl
					control={control}
					required
					name="password"
					label="Password"
				/>
			</SpaceStyle>
			<SpaceStyle>
				<EdFormControl
					control={control}
					required
					name="phone_number"
					label="Phone Number"
				/>
				<EdFormControl control={control} select name="gender" label="Gender">
					<MenuItem value="">None</MenuItem>
					{GenderTypes.map((_, index) => (
						<MenuItem key={`${index}-${_}`} value={_}>{`${_}`}</MenuItem>
					))}
				</EdFormControl>
			</SpaceStyle>
			<UploadPicStyle>
				<EdUploadFile
					folder="profilepictures/instructors"
					uploadLabel="UPLOAD"
					aspectRation={1 / 1}
					uploadIcon={<Icon>add</Icon>}
					control={control}
					name="profile_photo"
					label="Profile Photo"
					required
				/>
			</UploadPicStyle>
			<GroupStyle>
				<EdFormControl
					control={control}
					switch
					name="active"
					label="Active"
					defaultChecked={true}
				/>
			</GroupStyle>

			<Stack sx={{ marginTop: '2.625rem' }} spacing={2} direction="row-reverse">
				<SaveBtn
					variant="contained"
					disabled={!formState.isValid || !formState.isDirty}
					onClick={() => {
						const data = getValues();
						onNext(data as Admin);
					}}
				>
					NEXT
				</SaveBtn>
				<CancelBtn
					variant="contained"
					disabled={!formState.isDirty}
					onClick={() => history.push('/dashboard/admins')}
				>
					CANCEL
				</CancelBtn>
			</Stack>
		</>
	);
};

export const GroupStyle = styled.div`
	display: flex;
	flex-direction: column;
	gap: 2rem;
`;

export const SpaceStyle = styled.div`
	display: flex;
	justify-content: space-between;
	margin: 2rem 0;
	width: 92%;
`;

export const UploadPicStyle = styled.div`
	margin: 2rem 0;
	width: 28.188rem;
`;

export default AddAdminInfo;
