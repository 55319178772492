import { addMethod } from 'yup';
import { array, boolean, object, string } from 'yup';
import { TestQuestionBaseSchema } from './TestQuestion';
const oneTrue = addMethod(array, 'one_true', function (field, message) {
	return this.test('one_true', function (value) {
		const correct_item = value?.find((_) => _.is_correct);
		if (correct_item) return true;
		return this.createError({
			message,
			path: `${this.path}.${field}`,
		});
	});
});
export const ChoiceSchema = object({
	choice: string().required('Choice is required'),
	is_correct: boolean().default(false).required(),
}).required();
export const MCQ_SCHEMA = TestQuestionBaseSchema.concat(
	object({
		type: string().default('mcq'),
		multipleChoiceOptions: array().of(ChoiceSchema).required().min(2),
	})
);
