import { FlexLayout } from '@Styled/utilities';
import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

const AddEssayQuestion = React.lazy(() => import('./AddEssayQuestion'));
const EditEssayQuestion = React.lazy(() => import('./EditEssayQuestion'));

const EssayQuestion = () => {
	const { path } = useRouteMatch();
	return (
		<FlexLayout flexDirection="column" px="2.625rem" flex="1">
			<React.Suspense fallback={<div>loading...</div>}>
				<Switch>
					<Route path={path} exact>
						<AddEssayQuestion />
					</Route>
					<Route path={`${path}/:id`} exact>
						<EditEssayQuestion />
					</Route>
				</Switch>
			</React.Suspense>
		</FlexLayout>
	);
};

export default EssayQuestion;
