import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const NoResults = () => {
	return (
		<Box
			sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
		>
			<Typography sx={{ fontFamily: 'Roboto', fontSize: '1rem' }}>
				Could not find results
			</Typography>
		</Box>
	);
};

export default NoResults;
