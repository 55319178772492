import { object, string } from 'yup';

export const ADD_TAG_SCHEMA = object({
	name: string().required(),
	theme: string().required(),
});

export const EDIT_TAG_SCHEMA = ADD_TAG_SCHEMA.concat(
	object({
		name: string().required(),
		theme: string().required(),
	})
);
