import { BaseSliceController } from '@Base/slices/slices.base';
import { AppSettingsRequester } from '../Services/AppSettings.requester';
import { AppSetting } from '../Types/AppSetting.types';

export class AppSettingsSlice extends BaseSliceController<
	AppSetting,
	'AppSetting',
	AppSetting
> {
	private static instance: AppSettingsSlice;
	static getInstance() {
		if (!AppSettingsSlice.instance) {
			AppSettingsSlice.instance = new AppSettingsSlice();
		}
		return AppSettingsSlice.instance;
	}
	constructor() {
		super('AppSetting', AppSettingsRequester.getInstance());
	}
}

export const { appSettingUpdate } = AppSettingsSlice.getInstance().getActions();
