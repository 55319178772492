import DateTimeFormatter from '@Components/DateTimeFormatter/DateTimeFormatter';
import MainTable from '@Components/MainTable';
import { MainTableRow } from '@Components/MainTable/MainTable';
import { onPageChangeRequestMeta } from '@Components/MainTable/MainTable.types';
import NoResults from '@Components/NoResults/NoResults';
import { useTypedSelector } from '@Features/store';
import { TableCell, Tooltip } from '@mui/material';
import { studentsGetSingle } from '@Pages/Students/Slice/Students.Slice';
import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { timelineTabGetAll } from './Slices/Timeline.Slices';

const TABLE_HEADS = [
	{ displayName: 'event', fieldName: 'event' },
	{ displayName: 'tag', fieldName: 'tag' },
	{ displayName: 'description', fieldName: 'description' },
	{ displayName: 'created at', fieldName: 'created_at' },
];

const Timeline = () => {
	const { id } = useParams<{ id: string }>();
	const dispatch = useDispatch();
	const { currentEntity } = useTypedSelector((state) => state.Students);
	const { fetchAll, meta, filters } = useTypedSelector(
		(state) => state.TimelineTab
	);

	useEffect(() => {
		dispatch(studentsGetSingle({ id }));
	}, []);

	const onPageChangeRequest = useCallback(
		({
			activePage,
			endDate,
			filters,
			query,
			sortBy,
			startDate,
			perPage,
		}: onPageChangeRequestMeta) => {
			dispatch(
				timelineTabGetAll({
					page: activePage,
					perPage: perPage ?? 10,
					sortBy,
					filters,
					query,
					from: startDate,
					to: endDate,
					id: currentEntity?.id as any,
				})
			);
		},
		[dispatch, currentEntity?.id]
	);

	return (
		<MainTable
			total={meta?.total ?? 0}
			dateFilter
			pageNum={meta?.current_page}
			tableFilters={filters}
			tableHeads={TABLE_HEADS}
			totalPages={meta?.last_page ?? 0}
			RowsperPage={Number(meta?.per_page)}
			$hasActions
			onPageChangeRequest={onPageChangeRequest}
			renderItems={() => {
				if (fetchAll?.length === 0)
					return (
						<MainTableRow>
							<TableCell colSpan={TABLE_HEADS.length}>
								<NoResults />
							</TableCell>
						</MainTableRow>
					);
				return fetchAll?.map((timeline) => {
					return (
						<MainTableRow key={timeline.id} hover>
							<Tooltip title={timeline.event} placement="top">
								<TableCell>{timeline.event}</TableCell>
							</Tooltip>
							<TableCell>{timeline.tag}</TableCell>
							<Tooltip title={timeline.description} placement="top">
								<TableCell>{timeline.description}</TableCell>
							</Tooltip>
							<Tooltip
								title={<DateTimeFormatter date={timeline.created_at} />}
								placement="top"
							>
								<TableCell>
									<DateTimeFormatter date={timeline.created_at} />
								</TableCell>
							</Tooltip>
							<TableCell />
						</MainTableRow>
					);
				});
			}}
		/>
	);
};

export default Timeline;
