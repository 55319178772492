import EdFormControl from '@Components/UI/Inputs/EdFormControl/EdFormControl';
import EdUploadFile from '@Components/UI/Inputs/EdUploadFile/EdUploadFile';
import SelectChip from '@Components/UI/Inputs/SelectChip/SelectChip';
import { DASHBOARD_BASE_ROUTE } from '@Constants/routes';
import { getEducationalInfo } from '@Features/classrooms/educationalSlice';
import { useTypedSelector } from '@Features/store';
import { yupResolver } from '@hookform/resolvers/yup';
import { Checkbox, Icon, MenuItem } from '@mui/material';
import { ChipFlexLayout } from '@Pages/ClassRooms/Components/AddEditClassroom.styled';
import GroupedBtns from '@Pages/Instructors/components/GroupedBtns';
import {
	InstructorForm,
	FULL_SCHEMA,
} from '@Pages/Instructors/Schema/Instructor.schema';
import { InstructorRequester } from '@Pages/Instructors/Services/instructors.req';
import { useSnackbar } from '@Providers/useSnackbar';
import { Divider, GridLayout } from '@Styled/utilities';
import { Flatten } from '@Utils/types/Flatten';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { EducationalInfoControlType } from '@Pages/Instructors/Types/Instructor.types';
import { GenderTypes } from '@Pages/Admin/Constants/admin.constants';

const AddInstructorInfo: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const { displaySnackbar } = useSnackbar();
	const { educational_info, educational_type } = useTypedSelector(
		(state) => state.educational
	);
	useEffect(() => {
		dispatch(getEducationalInfo());
	}, []);
	const { formState, getValues, watch, control, setValue } =
		useForm<InstructorForm>({
			resolver: yupResolver(FULL_SCHEMA),
			mode: 'all',
			defaultValues: {
				instructor: {
					educationLanguages: [],
					educationSections: [],
					educationTypes: [],
					educationYears: [],
				},
			},
		});

	const watchTypes = watch('instructor.educationTypes', []);
	const watchYears = watch('instructor.educationYears', []);
	const watchLanguages = watch('instructor.educationLanguages', []);
	const watchSections = watch('instructor.educationSections', []);

	const isChildrenDisabled = React.useMemo(() => {
		return !watchTypes || (watchTypes && watchTypes.length === 0);
	}, [watchTypes]);

	const years = React.useMemo(() => {
		if (!watchTypes || watchTypes.length === 0) {
			setValue('instructor.educationYears', []);
			return [];
		}
		let flatYears = educational_info
			.filter((_) => _.id && watchTypes.includes(_.id))
			.map(({ educationYears }) => educationYears)
			.flat();
		flatYears = flatYears.filterDups('id');

		return flatYears;
	}, [watchTypes?.length, educational_info]);

	const languages = React.useMemo(() => {
		if (!watchTypes || watchTypes.length === 0) {
			setValue('instructor.educationLanguages', []);
			return [];
		}
		let flatLanguages = educational_info
			.filter((_) => _.id && watchTypes.includes(_.id))
			.map(({ educationLanguages }) => educationLanguages)
			.flat();
		flatLanguages = flatLanguages.filterDups('id');

		return flatLanguages;
	}, [watchTypes?.length, educational_info]);

	const sections = React.useMemo(() => {
		if (!watchYears) return;
		if (watchYears.length === 0) {
			setValue('instructor.educationSections', []);
			return [];
		}
		let flatYears = educational_info
			.map(({ educationYears }) => educationYears)
			.flat()
			.filterDups('id')
			.filter((_) => watchYears.includes(_.id ?? -1));

		let flatSections = flatYears
			.map(({ educationSections }) => educationSections)
			.flat()
			.filterDups('id');

		return flatSections;
	}, [watchYears?.length, educational_info]);

	const onDeleteValue = (
		control: EducationalInfoControlType,
		value: number
	) => {
		const values: Array<number> | undefined = getValues().instructor[control];
		if (!values) return;
		values.splice(
			values.findIndex((_) => _ === value),
			1
		);
		setValue(`instructor.${control}`, values);
	};

	const onSave = async (form: Flatten<InstructorForm>) => {
		try {
			const {
				data: { data },
			} = await InstructorRequester.getInstance().create(form);
			displaySnackbar('success', `${data.first_name} Created Successfully`);
			history.push(
				`${DASHBOARD_BASE_ROUTE}/instructors/edit/instructor/${data.uuid}`
			);
		} catch (e) {
			const msg = e.response?.data.message || 'Unable to Create Instructor ';
			displaySnackbar('error', msg);
		}
	};

	return (
		<>
			<SectionLayout
				gridGap={'2rem'}
				gridTemplateColumns={{ sm: 'repeat(2,1fr)' }}
			>
				<EdFormControl
					control={control}
					required
					name="first_name"
					label="First Name"
				/>
				<EdFormControl
					control={control}
					required
					name="last_name"
					label="Last Name"
				/>
				<EdFormControl control={control} required name="email" label="Email" />
				<EdFormControl
					control={control}
					required
					name="phone_number"
					label="Phone Number"
				/>
				<EdFormControl
					control={control}
					required
					name="password"
					label="Password"
				/>
				<EdFormControl control={control} select name="gender" label="Gender">
					{GenderTypes.map((_, index) => (
						<MenuItem key={`${index}-${_}`} value={_}>{`${_}`}</MenuItem>
					))}
				</EdFormControl>

				<EdFormControl
					control={control}
					width="4.75rem"
					type="number"
					inputMode="numeric"
					name="instructor.weight"
					label="Weight"
				/>
				<UrlStyleWrapper>
					<EdUploadFile
						aspectRation={1}
						folder="profilepictures/instructors"
						uploadLabel="UPLOAD"
						uploadIcon={<Icon>add</Icon>}
						control={control}
						name="profile_photo"
						label="Profile Photo"
						required
						optimize={false}
					/>
				</UrlStyleWrapper>
				<EdFormControl
					control={control}
					multiline
					name="instructor.bio"
					label="Bio"
				/>
				<EdFormControl
					control={control}
					switch
					name="instructor.is_active"
					label="Active"
					defaultChecked={true}
				/>
				<EdFormControl
					control={control}
					required
					name="instructor.label"
					label="Label"
				/>
			</SectionLayout>

			<Divider label="Educational Info." />
			<SectionLayout>
				<EdFormControl
					control={control}
					required
					select
					name="instructor.educationTypes"
					multiple
					width="17rem"
					renderValues={(value) => {
						return (
							<div>
								{watchTypes &&
									watchTypes.map((id) => {
										const name = educational_type.find(
											(_) => _.value === id
										)?.name;
										return (
											<SelectChip
												key={`${id}-type`}
												onDelete={() => {
													onDeleteValue('educationTypes', id);
												}}
												label={name}
											/>
										);
									})}
							</div>
						);
					}}
					SelectProps={{
						multiple: true,
						renderValue: (selected) => {
							return (
								<ChipFlexLayout>
									{watchTypes &&
										watchTypes.map((id) => {
											const name = educational_type.find(
												(_) => _.value === id
											)?.name;
											return <>{name},</>;
										})}
								</ChipFlexLayout>
							);
						},
					}}
					label="Type"
				>
					{educational_type.map((_, index) => {
						return (
							<MenuItem key={`${_.name}-${index}-type`} value={_.value}>
								<Checkbox checked={watchTypes?.includes(_.value)} />
								{_.name}
							</MenuItem>
						);
					})}
				</EdFormControl>

				<EdFormControl
					control={control}
					required
					disabled={isChildrenDisabled}
					select
					multiple
					name="instructor.educationYears"
					width="17rem"
					renderValues={(value) => {
						return (
							<div>
								{watchYears &&
									watchYears.map((id) => {
										const name = years.find((_) => _.id === id)?.name;
										return (
											<SelectChip
												key={`${id}-years`}
												onDelete={() => {
													onDeleteValue('educationYears', id);
												}}
												label={name}
											/>
										);
									})}
							</div>
						);
					}}
					SelectProps={{
						multiple: true,
						renderValue: (selected) => {
							return (
								<ChipFlexLayout>
									{watchYears &&
										watchYears.map((i, index) => {
											const name = years.find((_) => _.id === i)?.name;
											return <>{name},</>;
										})}
								</ChipFlexLayout>
							);
						},
					}}
					label={'Year'}
				>
					{years.map((_, index) => {
						return (
							<MenuItem key={`ed-year-${_.id}`} value={_.id}>
								<Checkbox
									checked={
										_.id !== undefined &&
										watchYears &&
										watchYears.includes(_.id)
									}
								/>

								{_.name}
							</MenuItem>
						);
					})}
				</EdFormControl>

				<EdFormControl
					control={control}
					required
					disabled={isChildrenDisabled}
					select
					multiple
					width="17rem"
					renderValues={(selected) => {
						return (
							<div>
								{watchLanguages &&
									watchLanguages.map((i, index) => {
										const name = languages.find((_) => _.id === i)?.name;
										return (
											<>
												<SelectChip
													key={`${i}-${index}-lng`}
													onDelete={() => {
														onDeleteValue('educationLanguages', i);
													}}
													label={name}
												/>
											</>
										);
									})}
							</div>
						);
					}}
					SelectProps={{
						multiple: true,
						renderValue: (selected) => {
							return (
								<ChipFlexLayout>
									{watchLanguages &&
										watchLanguages.map((i, index) => {
											const name = languages.find((_) => _.id === i)?.name;
											return <>{name},</>;
										})}
								</ChipFlexLayout>
							);
						},
					}}
					name="instructor.educationLanguages"
					label={'Language'}
				>
					{languages.map((_, index) => {
						return (
							<MenuItem key={`ed-lng-${_.id}`} value={_.id}>
								<Checkbox
									checked={
										_.id !== undefined &&
										watchLanguages &&
										watchLanguages.includes(_.id)
									}
								/>
								{_.name}
							</MenuItem>
						);
					})}
				</EdFormControl>

				{sections && sections.length > 0 && (
					<EdFormControl
						width="17rem"
						control={control}
						required
						disabled={isChildrenDisabled}
						select
						multiple
						renderValues={(selected) => {
							return (
								<div>
									{watchSections &&
										watchSections.map((i, index) => {
											const name = sections.find((_) => _.id === i)?.name;
											return (
												<>
													<SelectChip
														key={`${i}-${index}`}
														onDelete={() => {
															onDeleteValue('educationSections', i);
														}}
														label={name}
													/>
												</>
											);
										})}
								</div>
							);
						}}
						SelectProps={{
							multiple: true,
							renderValue: (selected) => {
								return (
									<ChipFlexLayout>
										{watchSections &&
											watchSections.map((i, index) => {
												const name = sections.find((_) => _.id === i)?.name;
												return <>{name},</>;
											})}
									</ChipFlexLayout>
								);
							},
						}}
						name="instructor.educationSections"
						label={'Section'}
					>
						{sections.map((_, index) => {
							return (
								<MenuItem key={`ed-sec-${_.id}`} value={_.id}>
									<Checkbox
										checked={
											_.id !== undefined &&
											watchSections &&
											watchSections.includes(_.id)
										}
									/>
									{_.name}
								</MenuItem>
							);
						})}
					</EdFormControl>
				)}
			</SectionLayout>
			<Divider label="Media Links." />
			<SectionLayout>
				<EdFormControl
					control={control}
					name="instructor.fb_link"
					label="Facebook"
					type="url"
				/>
				<EdFormControl
					control={control}
					name="instructor.youtube_link"
					label="Youtube"
					type="url"
				/>
				<EdFormControl
					control={control}
					name="instructor.website_link"
					label="Website Link"
					type="url"
				/>
			</SectionLayout>
			<GroupedBtns
				onSave={onSave}
				getValues={getValues}
				formState={formState}
			/>
		</>
	);
};

export const SectionLayout = styled(GridLayout)`
	margin-right: 5.5rem;
	grid-gap: 2rem;
`;

export const UrlStyleWrapper = styled.div`
	width: 28.4rem;
`;

export default AddInstructorInfo;
