import { BaseSliceController } from '@Base/slices/slices.base';
import { VideoUnitRequester } from '../Services/Video.requester';
import { VideoType } from '../Types/Video.types';

export class VideoUnitSlice extends BaseSliceController<
	VideoType,
	'VideoUnit',
	VideoType
> {
	private static instance: VideoUnitSlice;
	static getInstance() {
		if (!VideoUnitSlice.instance) {
			VideoUnitSlice.instance = new VideoUnitSlice();
		}
		return VideoUnitSlice.instance;
	}
	constructor() {
		super('VideoUnit', VideoUnitRequester.getInstance());
	}
}

export const {
	videoUnitDeleteById,
	videoUnitCreate,
	videoUnitGetSingle,
	videoUnitUpdate,
} = VideoUnitSlice.getInstance().getActions();
