import api from '@Services/api';
import { AxiosResponse } from 'axios';

import {
	CRUDBase,
	DeleteSingleEntityPayload,
	GetAllMeta,
	GetSingleEntityPayload,
} from 'src/base/services/services.base';
import { VideoType } from '../Types/Video.types';

interface VideoUnitCRUD extends CRUDBase<VideoType, VideoType> {}
export class VideoUnitRequester implements VideoUnitCRUD {
	private static instance: VideoUnitRequester;
	static getInstance() {
		if (!VideoUnitRequester.instance) {
			VideoUnitRequester.instance = new VideoUnitRequester();
		}
		return VideoUnitRequester.instance;
	}
	baseURL = 'video';
	getAll(
		payload?: GetAllMeta | undefined
	): Promise<AxiosResponse<ApiResponse<VideoType[]>>>;
	getAll(payload: number): Promise<AxiosResponse<ApiResponse<VideoType[]>>>;
	getAll(payload?: unknown): Promise<AxiosResponse<ApiResponse<VideoType[]>>> {
		throw new Error('Method not implemented.');
	}
	get(id: string): Promise<AxiosResponse<ApiResponse<VideoType>>>;
	get(id: number): Promise<AxiosResponse<ApiResponse<VideoType>>>;
	get(
		id: string | number,
		payload: GetSingleEntityPayload
	): Promise<AxiosResponse<ApiResponse<VideoType>>>;
	async get(
		id: any,
		payload?: any
	): Promise<AxiosResponse<ApiResponse<VideoType>>> {
		return await api.post(`/${this.baseURL}/get`, { id });
	}
	async create(
		payload: VideoType
	): Promise<AxiosResponse<ApiResponse<VideoType>>> {
		return await api.post(`${this.baseURL}/create`, payload);
	}
	async update(
		payload: VideoType
	): Promise<AxiosResponse<ApiResponse<VideoType>>> {
		return await api.post(`${this.baseURL}/update`, payload);
	}

	delete(id: number): Promise<AxiosResponse<ApiResponse<any>>>;
	delete(id: string): Promise<AxiosResponse<ApiResponse<any>>>;
	delete(
		id: string | number,
		payload: DeleteSingleEntityPayload
	): Promise<AxiosResponse<ApiResponse<VideoType>>>;
	async delete(
		id: any,
		payload?: any
	): Promise<AxiosResponse<ApiResponse<any>>> {
		return await api.delete(`${this.baseURL}/delete`, {
			data: {
				id,
			},
		});
	}
}
