import { LocalizationProvider, StaticDatePicker } from '@mui/x-date-pickers';
import '@mui/lab';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { TextField } from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import { Controller, useController, useForm } from 'react-hook-form';
import { DatePickersWrapper } from '../Pickers.styled';
import { RangePickerProps } from '../Pickers.types';

type YearFormType = {
	yearFrom: Date;
	yearTo: Date;
};
const YearRangePicker: React.FC<RangePickerProps> = ({ control, name }) => {
	const {
		field: { onChange, value },
	} = useController({
		control: control,
		name: name,
	});

	const defaultValues = useMemo<YearFormType>(() => {
		return {
			yearFrom: value && value[0] ? value[0] : '',
			yearTo: value && value[1] ? value[1] : '',
		};
	}, []);

	const { watch, ...formProps } = useForm<YearFormType>({
		defaultValues: defaultValues,
		mode: 'onChange',
	});

	const getFromValue = useMemo(() => {
		if (value && value[0]) return value[0];
		return undefined;
	}, [value]);

	const getToValue = useMemo(() => {
		if (value && value[1]) return value[1];
		return undefined;
	}, [value]);

	const watchChanges = watch();

	useEffect(() => {
		const { yearFrom, yearTo } = watchChanges;
		let _yearFrom = yearFrom;
		if (yearFrom) _yearFrom.setMonth(0, 1);
		let _yearTo = yearTo;
		if (yearTo) _yearTo.setMonth(11, 31);
		onChange([_yearFrom, _yearTo]);
	}, [watchChanges.yearFrom, watchChanges.yearTo]);

	return (
		<LocalizationProvider dateAdapter={AdapterDateFns}>
			<DatePickersWrapper>
				<Controller
					control={formProps.control}
					name="yearFrom"
					render={({ field: { ref, onChange } }) => {
						return (
							<StaticDatePicker
								minDate={new Date('1/1/2018')}
								maxDate={null}
								ref={ref}
								onChange={onChange}
								value={getFromValue}
								views={['year']}
								renderInput={({ ...props }) => <TextField {...props} />}
								showToolbar={false}
							/>
						);
					}}
				/>
				<Controller
					control={formProps.control}
					name="yearTo"
					render={({ field: { ref, onChange } }) => {
						return (
							<StaticDatePicker
								minDate={new Date('1/1/2018')}
								maxDate={null}
								ref={ref}
								onChange={onChange}
								value={getToValue}
								views={['year']}
								renderInput={({ ...props }) => <TextField {...props} />}
								showToolbar={false}
							/>
						);
					}}
				/>
			</DatePickersWrapper>
		</LocalizationProvider>
	);
};

export default YearRangePicker;
