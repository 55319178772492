import { object, string, number, array, boolean } from 'yup';

const numberErrorMessage = 'This field is must be numerical';

export const ROLES_PATHS_SCHEMA = object({
	course_id: number().notRequired(),
	progress_percentage: number()
		.typeError(numberErrorMessage)
		.min(0, 'Minimum at least 0')
		.max(100, 'Allowed maximum is 100')
		.nullable()
		.transform((value) => (value ? value : null)),
	sequential_units: boolean(),
	progress_criteria: string(),
	prerequisite_criteria: string(),
	units: array().of(number().min(1)),
	prerequisites: array(
		number()
			.nullable()
			.transform((value) => (value ? value : null))
	),
});
