import api from '@Services/api';
import { AxiosResponse } from 'axios';
import {
	CRUDBase,
	DeleteSingleEntityPayload,
	GetAllMeta,
	GetSingleEntityPayload,
} from 'src/base/services/services.base';
import { Assignments } from '../Types/Assignment.types';

interface AssignmentCRUD extends CRUDBase<Assignments, Assignments> {}
export class AssignmentRequester implements AssignmentCRUD {
	private static instance: AssignmentRequester;
	static getInstance() {
		if (!AssignmentRequester.instance) {
			AssignmentRequester.instance = new AssignmentRequester();
		}
		return AssignmentRequester.instance;
	}
	baseURL = 'assignment';
	getAll(
		payload?: GetAllMeta
	): Promise<AxiosResponse<ApiResponse<Assignments[]>>>;
	getAll(payload: number): Promise<AxiosResponse<ApiResponse<Assignments[]>>>;
	async getAll(
		payload?: any
	): Promise<import('axios').AxiosResponse<ApiResponse<Assignments[]>>> {
		return await api.post(`${this.baseURL}`, payload);
	}
	get(id: string): Promise<AxiosResponse<ApiResponse<Assignments>>>;
	get(id: number): Promise<AxiosResponse<ApiResponse<Assignments>>>;
	get(
		id: string | number,
		payload: GetSingleEntityPayload
	): Promise<AxiosResponse<ApiResponse<Assignments>>>;
	async get(
		id: any,
		payload?: any
	): Promise<AxiosResponse<ApiResponse<Assignments>>> {
		return await api.post(`${this.baseURL}/get`, { id });
	}
	async create(
		payload: Assignments
	): Promise<AxiosResponse<ApiResponse<Assignments>>> {
		return await api.post(`${this.baseURL}/create`, payload);
	}
	async update(
		payload: Assignments
	): Promise<AxiosResponse<ApiResponse<Assignments>>> {
		return await api.post(`${this.baseURL}/update`, payload);
	}
	delete(id: number): Promise<AxiosResponse<ApiResponse<any>>>;
	delete(id: string): Promise<AxiosResponse<ApiResponse<any>>>;
	delete(
		id: string | number,
		payload: DeleteSingleEntityPayload
	): Promise<AxiosResponse<ApiResponse<Assignments>>>;
	async delete(
		id: any,
		payload?: any
	): Promise<AxiosResponse<ApiResponse<any>>> {
		return await api.delete(`${this.baseURL}/delete`, {
			data: {
				uuid: id,
			},
		});
	}
}
