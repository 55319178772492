import api from '@Services/api';
import {
	GetAllStudentsResponse,
	IMPORT_TYPE,
	SingleStudentResponse,
	StudentPersonalData,
} from './students.res.types';

import { getReqType } from '@Services/students/students.req.types';
import { GetAllRequestProps } from '@Services/generics/response';

export const getAllStudentsRequest = async ({
	// exportFlag,
	filters,
	from,
	page,
	perPage,
	query,
	sortBy,
	to,
}: GetAllRequestProps) => {
	const data: getReqType = {
		page,
		perPage,
		filters,
		query,
		from,
		to,
		// export: exportFlag,
	};
	if (Object.keys(sortBy).length !== 0) data.sortBy = sortBy;

	return await api.post<GetAllStudentsResponse>('user/all', data);
};

export const getSingleStudentRequest = async (uuid: string) =>
	await api.post<SingleStudentResponse>('user', { uuid });

export const updateStudentRequest = async (data: any) =>
	await api.put<StudentPersonalData>('user/update', data);
export const addToWalletRequest = async (
	userId: string,
	amount: number,
	description: string,
	account: string
) =>
	await api.post('user/wallet/add', {
		uuid: userId,
		amount,
		description,
		account,
	});

export const setWalletRequest = async (
	userId: string,
	amount: number,
	description: string,
	account: string
) =>
	await api.post('user/wallet/set', {
		uuid: userId,
		amount,
		description,
		account,
	});

export const deductFromWalletRequest = async (
	userId: string,
	amount: number,
	description: string,
	account: string
) =>
	await api.post('user/wallet/deduct', {
		uuid: userId,
		amount,
		description,
		account,
	});

enum ImportStudentsAPIs {
	BANNED = 'import/banned',
	ENROLLMENT_CLASSROOM = 'import/classroom-enroll',
	ENROLLMENT_COURSE = 'import/course-enroll',
}
export const importStudentPerClassroom = async (
	type: IMPORT_TYPE,
	formData: FormData
) => await api.post(ImportStudentsAPIs[type], formData);
