export enum InstructorFormMode {
	ADD,
	UPDATE,
}

export interface formData {
	username: string;
	email: string;
	password: string;
	firstname: string;
	lastname: string;
	phone_number: string;
	label: string;
	branch_id: string;
	branch_name: string;
	gender: string;
	lms_id: string;
	profile_photo: string;
	bio: string;
	weight: number;
}

export type gender = 'male' | 'female';

export enum FormMode {
	ADD,
	UPDATE,
}
