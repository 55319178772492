import { loginCheck } from '@Features/auth/authSlice';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Reset } from 'styled-reset';
import {
	fetchInstructorsDropdown,
	fetchCategoriesDropdown,
	fetchClassroomsDropdown,
	fetchBatchesDropdown,
	fetchWalletBatchesDropdown,
	getAllPermissions,
	fetchTagsDropdown,
} from '@Features/dropdowns/dropdownsSlice';
import { Router } from 'react-router-dom';
import Layout from '@Components/UI/Layout/Layout';
import { createBrowserHistory } from 'history';
import { theme } from '@Styled/theme';
import { LocalizationProvider } from '@mui/x-date-pickers';
import '@mui/lab';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ApiProvider } from '@Providers/ApiProvider/ApiProvider';
import { useTypedSelector } from '@Features/store';
import { Theme } from '@mui/material';

const history = createBrowserHistory();

if (process.env.NODE_ENV === 'development') {
	console.log(
		'%cEduact Admin Portal',
		'font-weight: bold; font-size: 40px;color: #00fff2; text-shadow: 3px 3px 0 #1fb1d9 , 6px 6px 0 #0ea2e2 , 9px 9px 0 #0877f5 , 12px 12px 0 #054394 , 15px 15px 0 #0238ce , 18px 18px 0 #100491 , 21px 21px 0 rgb(42,21,113)'
	);
}
function App() {
	const routerRef = useRef<null | Router>(null);

	const dispatch = useDispatch();
	const [pers, setPers] = useState<any>([]);
	const { isAuth } = useTypedSelector((state) => state.auth);
	useEffect(() => {
		dispatch(loginCheck());
	}, []);

	useEffect(() => {
		const dispatchDropdowns = () => {
			dispatch(fetchInstructorsDropdown());
			dispatch(fetchCategoriesDropdown());
			dispatch(fetchClassroomsDropdown());
			dispatch(fetchBatchesDropdown());
			dispatch(fetchTagsDropdown());
			dispatch(fetchWalletBatchesDropdown());
			dispatch(getAllPermissions(pers));
		};
		if (isAuth) {
			dispatchDropdowns();
		}
	}, [dispatch, isAuth]);

	return (
		<div className="App">
			<ApiProvider>
				<React.Fragment>
					<Router ref={routerRef} key="router-v5-dom" history={history}>
						<LocalizationProvider dateAdapter={AdapterDateFns}>
							<Reset theme={theme} />
							<Layout />
						</LocalizationProvider>
					</Router>
				</React.Fragment>
			</ApiProvider>
		</div>
	);
}

export default App;
