import { GetEnrolledAnalysisPayload } from './classroomSlice.types';
import { GetEnrolledAnalysisResponse } from './../../services/classrooms/classrooms.res.types';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getEnrolledAnalysisRequest } from '@Services/classrooms/classrooms.req';
import createThunkBody from '@Utils/createThunkBody';

export const getEnrolledAnaysis = createAsyncThunk<
	GetEnrolledAnalysisResponse,
	GetEnrolledAnalysisPayload,
	{ rejectValue: string }
>('enrollment/analysis', async ({ classroomId, flag, from, to }, thunkApi) => {
	return await createThunkBody<GetEnrolledAnalysisResponse>(
		getEnrolledAnalysisRequest(classroomId, flag, from, to),
		thunkApi,
		'Error fetching Enrolled Count Purchase methods'
	);
});

export interface EnrolledAnalysisState {
	EnrolledAnalysis: Array<any>;
	loader: {
		getEnrolledAnalysis: AsyncLoader;
	};
	error: {
		getEnrolledAnalysis: string;
	};
}

/* SLICE */
const initialState: EnrolledAnalysisState = {
	/* classroom */
	EnrolledAnalysis: [],
	loader: {
		getEnrolledAnalysis: null,
	},
	error: {
		getEnrolledAnalysis: '',
	},
};

const enrolledAnalysisSlice = createSlice({
	name: 'classroom',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		/**
		 * @section enrolled analysis
		 */
		builder
			.addCase(getEnrolledAnaysis.pending, (state) => {
				state.loader.getEnrolledAnalysis = true;
				state.error.getEnrolledAnalysis = '';
			})

			.addCase(getEnrolledAnaysis.fulfilled, (state, { payload }) => {
				let { data, filters, message, meta } = payload;
				let dataStr = 'data:text/analytics;charset=utf-8,' + payload;
				if (data) {
					state.EnrolledAnalysis = data;
				} else {
					let downloadAnchorNode = document.createElement('a');
					downloadAnchorNode.setAttribute('href', dataStr);
					downloadAnchorNode.setAttribute('download', 'analytics.csv');
					document.body.appendChild(downloadAnchorNode);
					downloadAnchorNode.click();
					downloadAnchorNode.remove();
				}
				state.loader.getEnrolledAnalysis = false;
			})
			.addCase(getEnrolledAnaysis.rejected, (state, { payload }) => {
				state.loader.getEnrolledAnalysis = undefined;
				state.error.getEnrolledAnalysis =
					payload || 'could not get the analystics, please try again!';
			});
	},
});

export default enrolledAnalysisSlice.reducer;
