import { BaseSliceController } from '@Base/slices/slices.base';
import { GroupStudentsRequester } from '@Pages/ClassRooms/Services/GroupStudents/GroupStudents.requester';
import { GroupsRequester } from '@Pages/ClassRooms/Services/Groups/GroupsSlice.requester';
import { Group } from '@Pages/ClassRooms/Types/Groups.types';

export class GroupStudentsSlice extends BaseSliceController<GroupStudent, 'GroupStudents'> {
	private static instance: GroupStudentsSlice;
	static getInstance() {
		if (!GroupStudentsSlice.instance) {
			GroupStudentsSlice.instance = new GroupStudentsSlice();
		}
		return GroupStudentsSlice.instance;
	}
	constructor() {
		super('GroupStudents', GroupStudentsRequester.getInstance());
	}
}

export const { groupStudentsGetAll , groupStudentsCreate : assignStudentToGroup , groupStudentsUpdate : removeStudentGroup } =
	GroupStudentsSlice.getInstance().getActions();
