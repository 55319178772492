import { Box, Button, Modal, OutlinedInput } from '@mui/material';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import styled from 'styled-components';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useTypedSelector } from '@Features/store';
import { useDispatch } from 'react-redux';
import { deleteScratchcardByBatch } from '@Services/scratchCards/scratchCards.req';
import { getScratchCards } from '@Features/scratchCards/scratchCardsSlice';
import {
	fetchBatchesDropdown,
	fetchWalletBatchesDropdown,
} from '@Features/dropdowns/dropdownsSlice';
import { useSnackbar } from '@Providers/useSnackbar';
import { deleteWalletCodeByBatchReq } from '@Services/walletCodes/walletCodes.req';
import { fetchAllWalletCodes } from '@Features/walletCodes/walletCodesSlice';
import { rechargeCodesGetAll } from '../Slices/RechargeCodes.slice';

type Props = {
	onConfirmation: any;
	handleModelClose: () => void;
	openModel: boolean;
	onSuccessCallback: Function;
};

export type CourseFormData = {
	batch_number: number;
};

const SCHEMA = yup.object().shape({
	batch_number: yup.number().required(),
	checker: yup.boolean(),
});

const DeleteWalletModeule: React.FC<Props> = ({
	handleModelClose,
	onConfirmation,
	openModel,
	onSuccessCallback,
}) => {
	const { meta, dateRange, selectedFilters, query, sortBy } = useTypedSelector(
		(state) => state.RechargeCodes
	);
	const dispatch = useDispatch();
	const {
		formState: { isValid, errors, touchedFields, isDirty },
		watch,
		reset,
		control,
		handleSubmit,
	} = useForm<any>({
		resolver: yupResolver(SCHEMA),
		mode: 'onTouched',
		defaultValues: {
			checker: false,
		},
	});

	const { dropdownWalletBatches: batches } = useTypedSelector(
		(state) => state.dropdowns
	);
	const snackbar = useSnackbar();

	const onSubmitForm = async (batch_number: CourseFormData) => {
		await deleteWalletCodeByBatchReq(batch_number);
		snackbar.displaySnackbar('success', 'Deleted batch successfully ');

		dispatch(
			rechargeCodesGetAll({
				filters: selectedFilters,
				page: meta?.current_page ?? 1,
				perPage: meta?.per_page ?? 10,
				query: query ?? '',
				sortBy,
				from: dateRange.from,
				to: dateRange.to,
				export: false,
			})
		);
		dispatch(fetchWalletBatchesDropdown());
		reset('', {
			keepValues: false,
		});
		handleModelClose();
	};

	return (
		<Modal
			open={openModel}
			onClose={handleModelClose}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Container>
				<form onSubmit={handleSubmit(onSubmitForm)}>
					<Box sx={{ marginLeft: '1.5rem', marginTop: '2.25rem' }}>
						<TitleStyled id="modal-modal-title">
							Delete Codes <SpanStyled>(by batch) </SpanStyled>
						</TitleStyled>
					</Box>

					<Box
						sx={{
							marginLeft: '3.5rem',
							marginRight: '4rem',
							display: 'flex',
							flexDirection: 'column',
						}}
					>
						<Controller
							control={control}
							name="batch_number"
							render={({ field }) => (
								<>
									<LabelStyled>Batch Number</LabelStyled>
									<FormControl size="small" sx={{ marginTop: '1rem' }}>
										<InputLabel id="demo-simple-select-label">
											Batch Number
										</InputLabel>
										<SelectStyled
											labelId="demo-simple-select-label"
											id="demo-simple-select"
											{...field}
											label="Select or search options"
										>
											{batches.map(({ batch }) => (
												<MenuItem key={batch} value={batch}>
													{batch}
												</MenuItem>
											))}
										</SelectStyled>
									</FormControl>
								</>
							)}
						/>
						<Controller
							control={control}
							name="checker"
							render={({ field }) => (
								<CheckboxStyled
									control={<Checkbox />}
									{...field}
									label="All selected options are correct"
								/>
							)}
						/>
					</Box>
					<ButtonsContainer>
						<Button
							variant="text"
							color="inherit"
							disabled={!isValid || !watch('checker')}
							sx={{
								fontFamily: 'Roboto',
								fontSize: '14px',
								padding: '6px 16px',
								color: '#6c63ff',
								backgroundColor: '#fff',
								outline: 'none',
							}}
							type="submit"
						>
							Delete
						</Button>
						<Button
							variant="text"
							color="warning"
							sx={{
								fontFamily: 'Roboto',
								fontSize: '14px',
								padding: '6px 16px',
								color: '#ff9800',
								outline: 'none',
								backgroundColor: '#fff',
							}}
							onClick={() => {
								handleModelClose();
								reset();
							}}
						>
							Cancel
						</Button>
					</ButtonsContainer>
				</form>
			</Container>
		</Modal>
	);
};

export default DeleteWalletModeule;

export const Container = styled(Box)`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	min-width: 23.625rem;
	/* min-height: 21rem; */
	border-radius: 4px;
	border: 0;
	box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.38);
	margin: 39px 80px 50px 11.4px;
	padding: 12px 0 0;
	background-color: #ffffff;
	outline: none;
`;

export const TitleStyled = styled.span`
	font-weight: 500;
	font-family: Roboto;
	text-align: left;
	font-size: 1rem;
	color: rgba(0, 0, 0, 0.6);
`;

export const ButtonsContainer = styled(Box)`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	outline: none;
	margin-top: 1.625rem;
	margin-bottom: 0.5rem;
`;

export const RadioGroupStyled = styled(RadioGroup)`
	display: flex;
	flex-direction: row;
	.MuiTypography-root {
		font-family: Roboto;
		font-size: 0.875rem;
		color: rgba(0, 0, 0, 0.87);
	}
`;

export const SelectStyled = styled(Select)`
	width: 16.2rem;
`;

export const InputStyled = styled(OutlinedInput)`
	width: 16.2rem;
`;

export const CheckboxStyled = styled(FormControlLabel)`
	padding-left: 1.5rem;
	.MuiTypography-root {
		font-size: 0.75rem;
		font-family: Roboto;
		font-weight: normal;
	}
`;

export const SpanStyled = styled.span`
	font-family: Roboto;
	font-size: 1rem;
	color: rgba(0, 0, 0, 0.14);
`;

export const LabelStyled = styled.label`
	font-family: Montserrat;
	margin-top: 1.3rem;
	margin-bottom: 0.294rem;
	font-size: 0.8rem;
`;
