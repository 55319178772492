import { InputLabel, Slider } from '@mui/material';
import { grey, orange } from '@mui/material/colors';
import React from 'react';
import { Control, Controller } from 'react-hook-form';
import styled from 'styled-components';

type Props = {
	control: any;
	name: string;
	isEditFrom: boolean;
};

const DurationSlider: React.FC<Props> = ({ control, name, isEditFrom }) => {
	return (
		<>
			<Controller
				control={control}
				name={name}
				render={({ field }) => (
					<Wrapper>
						<LabelStyle required>Duration</LabelStyle>
						<Slider
							size="small"
							aria-label="Default"
							valueLabelDisplay="auto"
							color="primary"
							{...field}
							defaultValue={0}
							max={240}
							step={5}
							disabled={isEditFrom}
						/>
						<SubLabelStyle>minutes</SubLabelStyle>
					</Wrapper>
				)}
			/>
		</>
	);
};

const Wrapper = styled.div`
	display: flex;
	gap: 2.313rem;
	align-items: center;
`;
const LabelStyle = styled(InputLabel)`
	font-size: 1rem;
	font-weight: 700;
	color: ${({ theme }) => theme.palette.darkPurple.main};
	width: 37%;
	span {
		color: ${orange['500']};
	}
`;
const SubLabelStyle = styled.label`
	color: ${grey['400']};
	font-size: 0.75rem;
	font-weight: normal;
	margin-left: -20px;
`;

export default DurationSlider;
