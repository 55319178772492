import styled from 'styled-components';
import Box from '@mui/material/Box';

export const CenteredLayout = styled.div`
	width: 100vw;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	background-color: #ecebfc;
	overflow: hidden;
`;

export const StyledLoginForm = styled.form`
	/* width: 42.125rem; */
	/* height: 25.188rem; */
	padding: 1.75rem 3.875rem 2.188rem 6.625rem;
	box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
	border: solid 1px #fff;
	background-color: #fff;
	box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);
	border-radius: 10px;

	${({ theme }) => `${theme.breakpoints.up('sm')}{
    min-width:200px;
}`}
	${({ theme }) => `${theme.breakpoints.up('md')}{
    min-width:400px;
}`}

${({ theme }) => `${theme.breakpoints.up('lg')}{
    min-width:500px;

}`}
`;

export const LoginContainer = styled(Box)``;
