import MainTable from '@Components/MainTable';
import NoResults from '@Components/NoResults/NoResults';
import { getAllRoles } from './Features/rolesSlice';
import { useTypedSelector } from '@Features/store';
import { usePageLoader } from '@Providers/LoadingProvider';
import { useSnackbar } from '@Providers/useSnackbar';
import { deleteRoleRequest } from './Services/roles.req';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import TableCell from '@mui/material/TableCell';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import styled from 'styled-components';
import { TableHeader } from '@Features/generics/generics.type';
import { MainTableRow } from '@Components/MainTable/MainTable';
import AddRoleButton from './Components/AddRoleButton';
import DateTimeFormatter from '@Components/DateTimeFormatter/DateTimeFormatter';
import { deactivateRole } from './Services/roles.req';
import { StyleDeleteIcon } from '@Pages/ClassRooms/ClassRoomsTable';
import BlockIcon from '@mui/icons-material/Block';
import CheckComponent from './Components/checkComponent';
import DeleteModel from '@Pages/NewsFeeds/components/DeleteModel/DeleteModel';
import CheckIcon from '@mui/icons-material/Check';
import useQueryURL from '@Hooks/useQueryUrl';
import { onPageChangeRequestMeta } from '@Components/MainTable/MainTable.types';
import ActivationModel from '@Pages/Instructors/components/ActiviationModel/ActivationModel';
import { Tooltip } from '@mui/material';

const TABLE_HEADS: Array<TableHeader> = [
	{ displayName: 'ROLE TITLE', fieldName: 'title' },
	{ displayName: 'ROLE DESCRIPTION', fieldName: 'description' },
	{ displayName: 'ACTIVE', fieldName: 'active' },
	{ displayName: 'CREATED AT', fieldName: 'created_at' },
	{ displayName: 'UPDATED AT', fieldName: 'updated_at' },
];

export interface RouteComponentProps<P> {
	match: match<P>;
	staticContext?: any;
}

export interface match<P> {
	params: P;
	isExact: boolean;
	path: string;
	url: string;
}

const RoleTable: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const { displaySnackbar } = useSnackbar();
	const [openModel, setOpenModel] = useState(false);
	const [activate, setActivateRole] = useState<boolean>(false);
	const [roleId, setRoleId] = useState<any>(null);
	const [openDeleteByRowModel, setOpenDeleteByRowModel] = useState(false);
	const [deletedId, setDeletedId] = useState<any>(null);
	const { setIsLoading, isLoading } = usePageLoader();
	const { selectedQuery } = useQueryURL();

	const {
		errors,
		loaders,
		roles = [],
		lastPage,
		totalResults,
		perPage,
		page,
		rolesFilters,
	} = useTypedSelector((state) => state.roles);

	const auth = useTypedSelector((state) => state.auth);

	const onPageChangeRequest = useCallback(
		({
			activePage,
			endDate,
			filters,
			query,
			sortBy,
			startDate,
			perPage,
		}: onPageChangeRequestMeta) => {
			dispatch(
				getAllRoles({
					page: activePage,
					perPage: perPage ?? 10,
					sortBy,
					filters,
					query,
					from: startDate,
					to: endDate,
				})
			);
		},
		[dispatch]
	);

	useEffect(() => {
		if (!isLoading && loaders.getAll === true) setIsLoading(true);
		if (isLoading && loaders.getAll === false) setIsLoading(false);
		if (isLoading && loaders.getAll === undefined) {
			setIsLoading(false);
			displaySnackbar('error', errors.getAll);
		}
	}, [loaders.getAll, setIsLoading, isLoading, displaySnackbar, errors.getAll]);

	const onDeleteRole = useCallback(() => {
		deleteRoleRequest(deletedId)
			.then(({ data }) => {
				displaySnackbar('success', 'Delete Role Successfuly!');
				setOpenDeleteByRowModel(false);
				dispatch(
					getAllRoles({
						page: 1,
						perPage: 10,
						filters: [],
						sortBy: '',
						query: '',
					})
				);
			})
			.catch((e: any) => {
				const msg = e.response?.data.message || 'unable to delete';
				displaySnackbar('error', msg);
			});
	}, [deletedId, dispatch, displaySnackbar]);

	const handleDeleteByRowModelClose = () => {
		setOpenDeleteByRowModel(false);
		setDeletedId(null);
	};

	const _onDeleteClick = (id: number) => {
		setDeletedId(id);
		setOpenDeleteByRowModel(true);
	};

	const handleModelOpen = (id: number, active?: boolean) => {
		setOpenModel(true);
		setActivateRole(active ?? false);
		setRoleId(id);
	};

	const handleModelClose = () => {
		setOpenModel(false);
		setActivateRole(false);
	};
	const activateRole = useCallback(() => {
		deactivateRole(roleId, activate)
			.then(() => {
				displaySnackbar('success', 'Activated Role successfully');
				dispatch(
					getAllRoles({
						page: 1,
						perPage: 10,
						filters: [],
						sortBy: '',
						query: selectedQuery,
					})
				);
			})
			.catch((e: any) => {
				const msg = e.response?.data.message || 'Unable to Activated Role ';
				displaySnackbar('error', msg);
			});

		setOpenModel(false);
	}, [dispatch, displaySnackbar, selectedQuery, roleId, activate]);

	return (
		<>
			<DeleteModel
				openModel={openDeleteByRowModel}
				handleModelClose={handleDeleteByRowModelClose}
				onConfirmation={onDeleteRole}
				word="Role"
			/>
			<ActivationModel
				text="Role"
				openModel={openModel}
				handleModelClose={handleModelClose}
				ban={activate}
				onConfirmation={activateRole}
			/>
			{(auth.user.type === 'super' || auth.permissions.has('c-rls')) && (
				<div className="role_head__container">
					<AddRoleButton />
				</div>
			)}
			<div>
				<MainTable
					onPageChangeRequest={onPageChangeRequest}
					totalPages={lastPage}
					tableFilters={rolesFilters}
					total={Number(totalResults)}
					RowsperPage={Number(perPage)}
					pageNum={Number(page)}
					tableHeads={TABLE_HEADS}
					$hasActions={true}
					renderItems={() => {
						if (roles.length <= 0)
							return (
								<MainTableRow>
									<TableCell colSpan={TABLE_HEADS.length}>
										<NoResults />
									</TableCell>
								</MainTableRow>
							);
						return roles.map((role) => {
							return (
								<MainTableRow
									key={role.title}
									hover
									onClick={() => {
										history.push({
											pathname: `/dashboard/roles/edit/role/${role.id}`,
										});
									}}
									sx={{
										'&:active': { backgroundColor: '#6c63ff' },
									}}
								>
									<Tooltip title={role.title} placement="top">
										<TableCell
											sx={{
												fontFamily: 'Roboto',
												fontSize: '1rem',
												padding: '0 1.063rem 0',
												whiteSpace: 'nowrap',
												textOverflow: 'ellipsis',
												overflow: 'hidden',
											}}
										>
											<a title={role.title}> {role.title} </a>
										</TableCell>
									</Tooltip>

									<Tooltip title={role.description} placement="top">
										<TableCell
											sx={{
												fontFamily: 'Roboto',
												fontSize: '1rem',
												padding: '0 1.063rem 0',
												whiteSpace: 'nowrap',
											}}
										>
											{role.description}
										</TableCell>
									</Tooltip>
									<TableCell
										sx={{
											fontFamily: 'Roboto',
											fontSize: '1rem',
											padding: '0 1.063rem 0',
											whiteSpace: 'nowrap',
											textOverflow: 'ellipsis',
											overflow: 'hidden',
										}}
									>
										<CheckComponent active={role?.active} />
									</TableCell>

									<TableCell
										sx={{
											fontFamily: 'Roboto',
											fontSize: '1rem',
											padding: '0 1.063rem 0',
											whiteSpace: 'nowrap',
											textOverflow: 'ellipsis',
											overflow: 'hidden',
										}}
									>
										{role.created_at ? (
											<DateTimeFormatter date={role?.created_at} />
										) : (
											'-'
										)}
									</TableCell>

									<TableCell
										sx={{
											fontFamily: 'Roboto',
											fontSize: '1rem',
											padding: '0 1.063rem 0',
											whiteSpace: 'nowrap',
											textOverflow: 'ellipsis',
											overflow: 'hidden',
										}}
									>
										{role.updated_at ? (
											<DateTimeFormatter date={role?.updated_at} />
										) : (
											'-'
										)}
									</TableCell>

									<TableCell
										sx={{
											fontFamily: 'Roboto',
											fontSize: '1rem',
											padding: '0 1.063rem 0',
											whiteSpace: 'nowrap',
											textOverflow: 'ellipsis',
											overflow: 'hidden',
										}}
									>
										{(auth.user.type === 'super' ||
											auth.permissions.has('u-rls') ||
											auth.permissions.has('d-rls')) && (
											<SpeedDial
												FabProps={{
													disableRipple: true,
													disableTouchRipple: true,
													focusRipple: false,
													disableFocusRipple: true,
													style: {
														borderRadius: '0',
														boxShadow: '0 0 0 0 ',
														backgroundColor: 'transparent',
														color: '#000',
													},
												}}
												direction="left"
												ariaLabel="SpeedDial basic example"
												icon={<MoreVertIcon />}
											>
												{(auth.user.type === 'super' ||
													auth.permissions.has('d-rls')) && (
													<SpeedDialAction
														sx={{
															'&:hover': {
																backgroundColor: 'rgba(90,192,252, 0.25)',
															},
														}}
														key="delete"
														icon={<StyleDeleteIcon />}
														tooltipTitle="Delete"
														onClick={(e) => {
															e.stopPropagation();
															_onDeleteClick(role.id);
														}}
													/>
												)}
												{(auth.user.type === 'super' ||
													auth.permissions.has('u-rls')) && (
													<SpeedDialAction
														key="edit"
														sx={{
															'&:hover': {
																backgroundColor: 'rgba(90,192,252, 0.25)',
															},
														}}
														icon={
															role?.active ? <StyleBlockIcon /> : <CheckIcon />
														}
														tooltipTitle={
															role?.active ? 'deactivate' : 'activate'
														}
														onClick={(e) => {
															e.stopPropagation();
															handleModelOpen(role?.id, !role?.active);
															// activateRole(role?.id, !role?.active);
														}}
													/>
												)}
											</SpeedDial>
										)}
									</TableCell>
								</MainTableRow>
							);
						});
					}}
				/>
			</div>
		</>
	);
};

export default RoleTable;

const TableWrapper = styled.div``;

const StyleBlockIcon = styled(BlockIcon)`
	color: #000;

	&:hover {
		color: ${(props) => props.theme.palette.mayaBlue.main};
	}

	font-size: 1.5rem;
`;
