import { RouteGroup } from '@Constants/routes';
import ScratchCards from '../ScratchCards';

export const ScratchCardsRoute: RouteGroup = {
	isRouted: true,
	title: 'Scratch Cards',
	route: 'scratchcards',
	icon: 'content_copy',
	code: 'v-schc',
	routes: [],
	component: <ScratchCards />,
};
