import React, { useState } from 'react';
import { Button, Modal, FormControlLabel, Checkbox } from '@mui/material';
import styled from 'styled-components';
import { FlexLayout, Spacer } from '@Styled/utilities';
import { UsersRequester } from '@Pages/ClassRooms/Pages/SingleCourse/Pages/Users/Services/Userprogress/UserProgressRequester';
import {
	getCourseProgress,
	getCourseUsers,
} from '@Features/classrooms/classroomSlice';
import { useDispatch } from 'react-redux';
import { useSnackbar } from '@Providers/useSnackbar';
import useFilters from '@Hooks/useFilters';
import useSortByURL from '@Hooks/useSortBy';
import useQueryURL from '@Hooks/useQueryUrl';
import { useTypedSelector } from '@Features/store';

type Props = {
	handleModelClose: React.Dispatch<boolean>;
	openModel: boolean;
	id: number;
};

const UserSyncModel: React.FC<Props> = ({
	handleModelClose,
	openModel,
	id,
}) => {
	const dispatch = useDispatch();
	const { displaySnackbar } = useSnackbar();
	const [skipCompleted, setSkipCompleted] = useState<boolean>(true);
	const { selectedFilters } = useFilters();
	const { selectedSortBy } = useSortByURL();
	const { selectedQuery } = useQueryURL();
	const { perPage, page, currentCourse } = useTypedSelector(
		(state) => state.classroom
	);

	const UserSyncAll = async () => {
		try {
			await UsersRequester.getInstance().syncronizeAllStudent(
				id as number,
				skipCompleted
			);
			displaySnackbar('success', `User Syncronized successfully`);
			dispatch(
				getCourseProgress({
					page,
					perPage,
					sortBy: selectedSortBy?.direction === null ? '' : selectedSortBy,
					filters: selectedFilters,
					query: selectedQuery,
					enrolled: true,
					courseId: currentCourse?.id as number,
				})
			);
			handleModelClose(false);
		} catch (e) {
			const msg = e.response?.data.message || 'Unable to Syncronize User ';
			displaySnackbar('error', msg);
		}
	};

	return (
		<>
			<Modal
				open={openModel}
				onClose={() => {
					handleModelClose(false);
				}}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<ModalContainer>
					<Spacer marginBottom="0.9rem" marginTop="1.5rem" />
					<Header>Syncronize</Header>
					<FlexLayout
						flexDirection="column"
						gridGap="2.188rem"
						marginLeft="1.375rem"
						marginTop="2rem"
						marginBottom="3.75rem"
					>
						<Text>
							Are you sure you want to syncronize user progress based on the
							current course material for all users in this course?
						</Text>
						<FormControlLabelStyle
							control={
								<Checkbox
									checked={skipCompleted}
									onClick={() => setSkipCompleted(!skipCompleted)}
								/>
							}
							label="Prevent syncing for users that have completed the course"
						/>
					</FlexLayout>
					<FlexLayout justifyContent="end" alignItems="center" gridGap="1.7rem">
						<CancelButton
							variant="text"
							color="warning"
							onClick={() => handleModelClose(false)}
						>
							Cancel
						</CancelButton>
						<SyncButton variant="text" onClick={() => UserSyncAll()}>
							Syncronize
						</SyncButton>
					</FlexLayout>
				</ModalContainer>
			</Modal>
		</>
	);
};

const ModalContainer = styled.div`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 36rem;
	border-radius: 4px;
	background-color: #ffffff;
	padding: 2rem 1.9rem;
`;

const Header = styled.div`
	color: rgba(0, 0, 0, 0.6);
	font-weight: 500;
	font-size: 1rem;
`;

const Text = styled.p`
	font-size: 1rem;
	color: #251b33;
	font-weight: normal;
	letter-spacing: 0.9px;
	line-height: 1.33;
`;

const FormControlLabelStyle = styled(FormControlLabel)`
	color: rgba(0, 0, 0, 0.87);
	font-size: 0.875rem;
`;

const SyncButton = styled(Button)`
	color: #6c63ff;
	font-weight: 500;
	font-size: 1rem;
`;
const CancelButton = styled(Button)`
	font-size: 1rem;
`;

export default UserSyncModel;
