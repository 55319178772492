//export classrooms
import {
	classroomsExportAll,
	ExportAllClassroomsAnalyticsRequest,
	ExportClassroomAnalyticsRequest,
} from './../../services/classrooms/classrooms.req';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import createThunkBody from '@Utils/createThunkBody';
import { ClassroomsExportPayload } from './classroomSlice.types';
import EdResponse, { HeadersResponse } from '@Services/generics/response';
import { AxiosResponse } from 'axios';

//to export data in classrooms
export const exportClassrooms = createAsyncThunk<
	ApiResponse<any>,
	ClassroomsExportPayload,
	{ rejectValue: string }
>('classroomsExports/exports', async ({ from, to }, thunkAPI) => {
	return await createThunkBody<ApiResponse<any>>(
		classroomsExportAll({ from, to }),
		thunkAPI,
		'Error exporting classrooms data'
	);
});

export const exportAllClassroomsAnalytics = createAsyncThunk<
	AxiosResponse<string>,
	DatatablePayload,
	{ rejectValue: string }
>(`classroomsExports/all_analytics`, async (payload: DatatablePayload) => {
	const data = await ExportAllClassroomsAnalyticsRequest(payload);
	return { ...data };
});

export const exportClassroomAnalytics = createAsyncThunk<
	AxiosResponse<string>,
	ClassroomDatatablePayload,
	{ rejectValue: string }
>(
	`classroomsExports/classroom_analytics`,
	async (payload: ClassroomDatatablePayload) => {
		const data = await ExportClassroomAnalyticsRequest(payload);
		return { ...data };
	}
);

export interface ExportClassroomsState {
	Exportloaders: {
		exportFileLoader: AsyncLoader;
	};
	Exporterrors: {
		exportFileError: string;
	};
}

/* SLICE */
const initialState: ExportClassroomsState = {
	/* classroom */
	Exportloaders: {
		exportFileLoader: null,
	},
	Exporterrors: {
		exportFileError: '',
	},
};

const exportclassRoomSlice = createSlice({
	name: 'classroomsExports',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		/**
		 * @section CLASSROOMS
		 */
		/* getting Educational info case */

		/* get all classrooms case */
		builder
			.addCase(exportClassrooms.pending, (state) => {
				state.Exportloaders.exportFileLoader = true;
				state.Exporterrors.exportFileError = '';
			})
			.addCase(exportClassrooms.fulfilled, (state, { payload }) => {
				if (payload) {
					let dataStr = 'data:text/csv;charset=utf-8,' + payload;
					let downloadAnchorNode = document.createElement('a');
					downloadAnchorNode.setAttribute('href', dataStr);
					downloadAnchorNode.setAttribute(
						'download',
						'classroomsAnalytics.csv'
					);
					document.body.appendChild(downloadAnchorNode);
					downloadAnchorNode.click();
					downloadAnchorNode.remove();
				}
				state.Exportloaders.exportFileLoader = false;
			})
			.addCase(exportClassrooms.rejected, (state, { payload }) => {
				state.Exportloaders.exportFileLoader = undefined;
				state.Exporterrors.exportFileError = 'could not export classrooms data';
			});
		builder.addCase(
			exportAllClassroomsAnalytics.fulfilled,
			(state, { payload }) => {
				if (payload) {
					exportPayload(payload);
				}
			}
		);
		builder.addCase(
			exportClassroomAnalytics.fulfilled,
			(state, { payload }) => {
				if (payload) {
					exportPayload(payload);
				}
			}
		);
	},
});

const exportPayload = (payload: AxiosResponse<string>) => {
	let dataStr = 'data:text/analytics;charset=utf-8,' + payload.data;
	let downloadAnchorNode = document.createElement('a');
	downloadAnchorNode.setAttribute('href', dataStr);
	downloadAnchorNode.setAttribute(
		'download',
		payload.headers['content-disposition']?.split('=')[1] ?? 'file'
	);
	document.body.appendChild(downloadAnchorNode);
	downloadAnchorNode.click();
	downloadAnchorNode.remove();
};
export default exportclassRoomSlice.reducer;
