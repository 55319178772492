import React, { useState } from 'react';
import {
	Control,
	Controller,
	FieldArrayWithId,
	UseFieldArrayAppend,
	UseFieldArrayRemove,
	UseFieldArrayUpdate,
} from 'react-hook-form';
import styled from 'styled-components';
import { TestPayload } from '@Pages/ClassRooms/Pages/SingleCourse/Pages/CourseForm/Pages/CourseContent/Types/Test/Test.types';
import { FlexLayout, Spacer } from '@Styled/utilities';
import { EdFormControlLabel } from '@Components/UI/Inputs/EdFormControlLabel/EdFormControlLabel';
import EdFormControl from '@Components/UI/Inputs/EdFormControl/EdFormControl';
import { Popper, Tooltip } from '@mui/material';
import EdIcon from '@Components/UI/Utilities/EdIcon/EdIcon';
import EdNumericInput from '@Components/UI/Inputs/EdNumericInput/EdNumericInput';
import EdAnimatedIcon from '@Components/UI/Utilities/EdAnimatedIcon/EdAnimatedIcon';
import EdIconButton from '@Components/UI/Buttons/EdIconButton/EdIconButton';
import {
	StyledInnerContainer,
	StyledLabel,
	StyledOuterContainer,
} from './GradesArrayFields.styled';
type Props = {
	index?: number | any;
	control: any;
	remove: UseFieldArrayRemove;
	append: UseFieldArrayAppend<TestPayload, 'test.gradeSchema'>;
	field?: FieldArrayWithId<TestPayload, 'test.gradeSchema', 'id'>;
	fields: FieldArrayWithId<TestPayload, 'test.gradeSchema', 'id'>[];
};
const GradeOptionsForm: React.FC<Props> = ({
	control,
	index,
	remove,
	field,
	fields,
}) => {
	const onDelete = () => {
		let arr: Array<number> = new Array<number>();
		for (let i = index; i < fields.length + 1; i++) {
			arr.push(i);
		}
		remove(arr);
	};

	const [confirmDelete, setConfirmDelete] = useState(false);

	return (
		<div>
			<StyledOuterContainer alignItems={'center'}>
				<StyledInnerContainer>
					<EdFormControlLabel minWidth={'5rem'}>
						Range {index !== undefined ? index + 1 : fields.length + 1}:
					</EdFormControlLabel>
					<FlexLayout alignItems={'center'}>
						<StyledLabel>From {field?.from}</StyledLabel>
						<Controller
							control={control}
							name={`test.gradeSchema.${index}.to`}
							render={({ field, fieldState: { error } }) => {
								return (
									<>
										<StyledLabel>To</StyledLabel>
										<StyledEdNumericInput
											{...field}
											variant="outlined"
											size="small"
											error={error ? true : false}
											placeholder="Value"
											onChange={onDelete}
										/>
										<StyledLabel>%</StyledLabel>
									</>
								);
							}}
						/>
					</FlexLayout>
					<EdFormControl
						labelProps={{ minWidth: '5rem' }}
						width={'11.25rem'}
						control={control}
						name={`test.gradeSchema.${index}.grade`}
						label="Grade:"
						onChange={onDelete}
					/>
				</StyledInnerContainer>
				<Spacer mx={'0.5rem'} />

				<EdIconButton
					edcolor={confirmDelete ? 'yellow' : undefined}
					onClick={() => {
						if (confirmDelete) onDelete();
						else setConfirmDelete(true);
					}}
					onContextMenu={(e) => {
						e.preventDefault();
						if (confirmDelete) setConfirmDelete(false);
						return false;
					}}
				>
					{!confirmDelete && <EdIcon>delete</EdIcon>}
					{confirmDelete && (
						<Tooltip
							PopperComponent={StyledTooltipPopper}
							// followCursor={true}
							placement="right"
							title="Click again to confirm deleting"
						>
							<div>
								<EdAnimatedIcon animation={'shake'}>
									<EdIcon mIconType="TwoTone">error</EdIcon>
								</EdAnimatedIcon>
							</div>
						</Tooltip>
					)}
				</EdIconButton>
			</StyledOuterContainer>
		</div>
	);
};

const StyledEdNumericInput = styled(EdNumericInput)`
	width: 80px;
`;
export default GradeOptionsForm;

const StyledTooltipPopper = styled(Popper)`
	max-width: 8.5rem;
	font-size: 0.75rem;
	line-height: 20px;
`;
