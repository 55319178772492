import api from '@Services/api';
import { AxiosResponse } from 'axios';
import {
	CRUDBase,
	DeleteSingleEntityPayload,
	GetAllMeta,
	GetSingleEntityPayload,
} from 'src/base/services/services.base';
import { AppSetting } from '../Types/AppSetting.types';

interface AppSettingsCRUD extends CRUDBase<AppSetting, AppSetting> {}
export class AppSettingsRequester implements AppSettingsCRUD {
	getAll(
		payload?: GetAllMeta | undefined
	): Promise<AxiosResponse<ApiResponse<AppSetting[]>>>;
	getAll(payload: number): Promise<AxiosResponse<ApiResponse<AppSetting[]>>>;
	getAll(payload?: unknown): Promise<AxiosResponse<ApiResponse<AppSetting[]>>> {
		throw new Error('Method not implemented.');
	}
	get(id: string): Promise<AxiosResponse<ApiResponse<AppSetting>>>;
	get(id: number): Promise<AxiosResponse<ApiResponse<AppSetting>>>;
	get(
		id: string | number,
		tId?: string | number | undefined,
		payload?: GetSingleEntityPayload | undefined
	): Promise<AxiosResponse<ApiResponse<AppSetting>>>;
	get(
		id: unknown,
		tId?: unknown,
		payload?: unknown
	): Promise<AxiosResponse<ApiResponse<AppSetting>>> {
		throw new Error('Method not implemented.');
	}
	delete(id: number): Promise<AxiosResponse<ApiResponse<any>>>;
	delete(id: string): Promise<AxiosResponse<ApiResponse<any>>>;
	delete(
		id: string | number,
		payload?: DeleteSingleEntityPayload | undefined
	): Promise<AxiosResponse<ApiResponse<any>>>;
	delete(
		id: unknown,
		payload?: unknown
	): Promise<AxiosResponse<ApiResponse<any>>> {
		throw new Error('Method not implemented.');
	}
	create(
		payload: Omit<AppSetting, 'id'>
	): Promise<AxiosResponse<ApiResponse<AppSetting>>> {
		throw new Error('Method not implemented.');
	}
	private static instance: AppSettingsRequester;
	static getInstance() {
		if (!AppSettingsRequester.instance) {
			AppSettingsRequester.instance = new AppSettingsRequester();
		}
		return AppSettingsRequester.instance;
	}
	baseURL = 'settings';
	async update(
		payload: AppSetting
	): Promise<AxiosResponse<ApiResponse<AppSetting>>> {
		return await api.put(`${this.baseURL}`, payload);
	}
}

export default class AppSettingsApi {
	public static getAppSettings() {
		return api.get<ApiResponse<AppSetting>>('/settings');
	}
}
