import EdIcon from '@Components/UI/Utilities/EdIcon/EdIcon';
import React, { FC } from 'react';
import styled from 'styled-components';

type CollapseButtonProps = {
	collapsed?: boolean;
} & React.HTMLProps<HTMLDivElement>;
const EdCollapseButton: FC<CollapseButtonProps> = ({ onClick, collapsed }) => {
	return (
		<StyledCollapseButton
			collapsed={collapsed}
			onClick={(e) => onClick && onClick(e)}
		>
			<EdIcon>expand_less</EdIcon>
		</StyledCollapseButton>
	);
};

export default EdCollapseButton;
const StyledCollapseButton = styled.div<{ collapsed?: boolean }>`
	position: absolute;
	top: 0;
	right: 0;
	padding: 0.3rem;
	span {
		transform: ${(props) => (props.collapsed ? 'rotate(180deg)' : '')};
	}
`;
