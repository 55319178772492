import RoundButton from '@Components/UI/Buttons/RoundButton';
import { Icon } from '@mui/material';
import { NavMenuContext } from '@Components/UI/Layout/Layout';
import React, { useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

type Props = {} & React.ComponentProps<typeof RoundButton>;
const AddGroupButton: React.FC<Props> = ({ ...props }) => {
	const histroy = useHistory();
	const { pathname } = useLocation();
	const { currentRouteGroup } = useContext(NavMenuContext);
	return (
		<RoundButton
			edcolor="purple"
			onClick={() => histroy.push(`${pathname}/create`)}
			startIcon={<Icon>add</Icon>}
			variant="contained"
			{...props}
		>
			Add Group
		</RoundButton>
	);
};

export default AddGroupButton;
