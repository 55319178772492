import React from 'react';
import styled from 'styled-components';

const NoTests = () => {
	return (
		<Container>
			<Text>No test attempts has been added to this course yet!</Text>
		</Container>
	);
};

const Container = styled.div`
	width: 25.813rem;
	height: 3rem;
	border-radius: 4px;
	background-color: #e9f5fe;
	display: flex;
	align-items: center;
	justify-content: start;
	margin-top: 1.4rem;
`;
const Text = styled.p`
	font-size: 0.875rem;
	font-weight: 600;
	color: #0d3c61;
	padding: 0.938rem 1rem 0.875rem 1rem;
`;
export default NoTests;
