import { BaseSliceController } from '@Base/slices/slices.base';
import { Invoice } from '@Pages/Invoices/Types/Invoices.types';
import { InvoicesRequester } from '@Pages/Invoices/Services/Invoices.req';

export class InvoicesSlice extends BaseSliceController<Invoice, 'Invoices'> {
	private static instance: InvoicesSlice;
	static getInstance() {
		if (!InvoicesSlice.instance) {
			InvoicesSlice.instance = new InvoicesSlice();
		}
		return InvoicesSlice.instance;
	}
	constructor() {
		super('Invoices', InvoicesRequester.getInstance());
	}
}
export const {
	invoicesGetAll,
	invoicesExportData,
	invoicesProcess,
	invoicesGetSingle,
	invoicesUpdate,
} = InvoicesSlice.getInstance().getActions();
