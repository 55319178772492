import React, { useLayoutEffect } from 'react';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { useHistory, useLocation } from 'react-router-dom';
import addQueryString from '@Utils/addQueryString';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import EventIcon from '@mui/icons-material/Event';
import Popover from '@mui/material/Popover';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useForm } from 'react-hook-form';
import ReactHookDatePicker from '@Components/UI/Pickers/ReactHookDatePicker/ReactHookDatePicker';
import { FlexLayout } from '@Styled/utilities';

function DatePicker() {
	const history = useHistory();
	const location = useLocation();

	const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
		null
	);

	const open = Boolean(anchorEl);
	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const {
		control,
		watch,
		reset: resetForm,
	} = useForm({
		defaultValues: {
			dateFrom: null,
			dateTo: null,
		},
	});

	const watchFrom = watch('dateFrom');
	const watchTo = watch('dateTo');

	useLayoutEffect(() => {
		if (watchFrom) {
			const search = addQueryString(
				location.search,
				'from',
				formatDate(watchFrom)
			);
			history.push({ search });
		}
	}, [watchFrom]);

	useLayoutEffect(() => {
		if (watchTo) {
			const search = addQueryString(location.search, 'to', formatDate(watchTo));
			history.push({ search });
		}
	}, [watchTo]);
	const handleClose = () => {
		setAnchorEl(null);
	};

	const id = open ? 'simple-popover' : undefined;

	const reset = () => {
		let usp = new URLSearchParams(document.location.search);
		usp.delete('from');
		usp.delete('to');
		resetForm();
		const search = usp.toString();
		history.push({ search });
	};

	const minDate = React.useMemo(() => {
		const tempDate = new Date();
		tempDate.setFullYear(tempDate.getFullYear() - 10);
		return tempDate;
	}, []);

	return (
		<>
			<Tooltip title="Date Filter">
				<IconButton
					sx={{
						backgroundColor: 'rgb(90, 192, 252,0.25) ',
						color: open ? '#5ac0fc' : '#6c63ff',
						marginRight: '49px',
					}}
					aria-label="date filter"
					id="Picker"
					onClick={handleClick}
				>
					<EventIcon />
				</IconButton>
			</Tooltip>

			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
			>
				<Box
					sx={{
						width: '33.9px',
						height: '33.9px',
						padding: '5px 5px 5px',
						ml: 2,
						cursor: 'pointer',
						position: 'absolute',
						top: 0,
						right: 0,
					}}
				>
					<i
						className="tim-icons icon-simple-remove justify-self-end  "
						onClick={handleClose}
					></i>
				</Box>
				{/* <LocalizationProvider dateAdapter={AdapterDateFns}> */}
				<FlexLayout>
					<ReactHookDatePicker
						toolbarPlaceholder="From"
						control={control as any}
						name="dateFrom"
						minDate={minDate}
					/>
					<ReactHookDatePicker
						toolbarPlaceholder="To"
						control={control as any}
						name="dateTo"
						minDate={minDate}
					/>
				</FlexLayout>
				{/* </LocalizationProvider> */}
				<Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
					<Button
						variant="contained"
						onClick={reset}
						sx={{
							marginRight: '10px',
							mb: '10px',
							borderRadius: '4px',
							boxShadow: '0 3px 3px 0 rgba(0, 0, 0, 0.38)',
							backgroundColor: '#2196f3 ',
							fontFamily: 'Roboto',
							fontSize: '14px',
							fontWeight: 500,
						}}
					>
						Reset
					</Button>
				</Box>
			</Popover>
		</>
	);
}

export function getFormattedDate(dateVal: any) {
	const date = dateVal.toString().slice(4, 15);
	var month = new Date(Date.parse(date)).getMonth() + 1;
	const year = new Date(Date.parse(date)).getFullYear();
	const day = date.slice(4, 6);
	const formatedDate = `${year}-${
		month.toString().length < 2 ? '0' + month : month
	}-${day}`;
	return formatedDate;
}

export function formatDate(date: any) {
	var d = new Date(date),
		month = '' + (d.getMonth() + 1),
		day = '' + d.getDate(),
		year = d.getFullYear();

	if (month.length < 2) month = '0' + month;
	if (day.length < 2) day = '0' + day;

	return [year, month, day].join('-');
}

export default DatePicker;
