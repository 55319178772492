import { Avatar, Menu, MenuItem } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useTypedSelector } from '@Features/store';
import { useDispatch } from 'react-redux';
import HeaderNavMenu from './HeaderNavMenu';
import HeaderBreadcrumbs from './Breadcrumbs';
import { logout } from '@Features/auth/authSlice';

const Header: React.FC = () => {
	const history = useHistory();
	const dispatch = useDispatch();

	const { user } = useTypedSelector((state) => state.auth);

	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
	const [menuOpen, setMenuOpen] = useState(false);

	const showMenu = useCallback((e: React.MouseEvent) => {
		setAnchorEl(e.currentTarget as HTMLElement);
		setMenuOpen(true);
	}, []);

	const closeMenu = useCallback(() => {
		setAnchorEl(null);
		setMenuOpen(false);
	}, []);

	return (
		<HeaderWrapper>
			<HeaderUpperPart>
				<HeaderNavMenu />
				<UserProfileMenu>
					<UserProfileLabel onClick={showMenu}>
						<Avatar>{user?.username[0]}</Avatar>
						<div>{user?.username}</div>
					</UserProfileLabel>
					<Menu
						onClick={closeMenu}
						onClose={closeMenu}
						anchorEl={anchorEl}
						open={menuOpen}
						anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
					>
						<MenuItem
							onClick={() => {
								dispatch(logout());
								history.push('/login');
							}}
						>
							Logout
						</MenuItem>
					</Menu>
				</UserProfileMenu>
			</HeaderUpperPart>
			<HeaderBreadcrumbs />
		</HeaderWrapper>
	);
};

export default Header;

const UserProfileMenu = styled.div`
	display: flex;
	justify-content: space-between;
`;
const UserProfileLabel = styled.div`
	align-items: center;
	display: flex;
	cursor: pointer;
	.MuiAvatar-root {
		margin: 0 0.75rem;
	}
`;
const HeaderWrapper = styled.div`
	padding: 1.125rem 0.5rem;
`;
const HeaderUpperPart = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: 1.563rem;
`;
