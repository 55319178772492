import {
	AllTabsPayload,
	SectionPayload,
	TabPayload,
	TabResponse,
} from '@Pages/ClassRooms/Types/ClassroomTabs.types';
import api from '@Services/api';
import { AxiosResponse } from 'axios';
import {
	CRUDBase,
	DeleteSingleEntityPayload,
	GetAllMeta,
	GetSingleEntityPayload,
} from 'src/base/services/services.base';

interface ClassroomTabsCRUD extends CRUDBase<TabPayload, TabResponse> {
	updateCurriculum(
		payload: AllTabsPayload
	): Promise<AxiosResponse<ApiResponse<any>>>;
}
export class ClassroomTabsRequester implements ClassroomTabsCRUD {
	baseURL?: string | undefined = 'classroom/tabs/';
	getAll(
		payload?: GetAllMeta
	): Promise<AxiosResponse<ApiResponse<TabResponse[]>>>;
	getAll(payload?: number): Promise<AxiosResponse<ApiResponse<TabResponse[]>>>;
	getAll(payload?: any): Promise<AxiosResponse<ApiResponse<TabResponse[]>>> {
		return api.post(`${this.baseURL}fetch`, {
			classroom_id: payload,
		});
	}
	async updateCurriculum(
		payload: AllTabsPayload
	): Promise<AxiosResponse<ApiResponse<any>>> {
		return api.post(`${this.baseURL}curriculum/update`, payload);
	}
	get(id: string): Promise<AxiosResponse<ApiResponse<TabResponse>>>;
	get(id: number): Promise<AxiosResponse<ApiResponse<TabResponse>>>;
	get(
		id: string | number,
		payload: GetSingleEntityPayload
	): Promise<AxiosResponse<ApiResponse<TabResponse>>>;
	get(
		id: any,
		payload?: any
	): Promise<AxiosResponse<ApiResponse<TabResponse>>> {
		throw new Error('Method not implemented.');
	}
	update(
		payload: TabPayload
	): Promise<AxiosResponse<ApiResponse<TabResponse>>> {
		return api.patch(`${this.baseURL}update`, payload);
	}
	delete(id: number): Promise<AxiosResponse<ApiResponse<any>>>;
	delete(id: string): Promise<AxiosResponse<ApiResponse<any>>>;
	delete(
		id: string | number,
		payload: DeleteSingleEntityPayload
	): Promise<AxiosResponse<ApiResponse<any>>>;
	async delete(
		id: any,
		payload?: any
	): Promise<AxiosResponse<ApiResponse<any>>> {
		return await api.delete(`${this.baseURL}delete`, {
			data: {
				id,
			},
		});
	}

	create(
		payload: Omit<TabPayload, 'id'>
	): Promise<AxiosResponse<ApiResponse<TabResponse>>> {
		return api.post('classroom/tabs/create', payload);
	}
	private static instance: ClassroomTabsRequester;

	static getInstance() {
		if (!ClassroomTabsRequester.instance) {
			ClassroomTabsRequester.instance = new ClassroomTabsRequester();
		}
		return this.instance;
	}
}
