import EdFormControl from '@Components/UI/Inputs/EdFormControl/EdFormControl';
import { useTypedSelector } from '@Features/store';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSnackbar } from '@Providers/useSnackbar';
import { FlexLayout } from '@Styled/utilities';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { VIDEO_SCHEMA } from '../../../Schema/Video.schema';
import { VideoUnitRequester } from '../../../Services/Video.requester';
import { VideoType } from '../../../Types/Video.types';
import { Button, InputAdornment } from '@mui/material';
import { ContentConfig } from '../../../../../Constants/CourseContent.constants';
import VideoLinks from '../../../Components/VideoLinks';
import { EdFormControlDuration } from '../../AddVideo/Components/AddVideoInfo';

const embedLink =
	/<(\"[^\"]*\"|'[^']*'|[^'\">])([a-zA-Z]+)(?:[^>]*[^/]*)((<\/)\w+(>))?>$/;
const regex =
	/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)+(\.(mp4|mkv|flv|3gp|avi|mov|webm))?$/;

const EditVideoInfo = () => {
	const { displaySnackbar } = useSnackbar();
	const history = useHistory();

	const { currentEntity } = useTypedSelector((state) => state.VideoUnit);

	const defaultValues = React.useMemo<VideoType | undefined>(() => {
		if (currentEntity) {
			const defaultT: VideoType = {
				id: currentEntity?.video?.id,
				unit: {
					name: currentEntity?.unit?.name,
					course_id: currentEntity?.unit?.course_id,
					order: currentEntity?.unit?.order,
					type_id: currentEntity?.unit?.type_id,
				},
				video: currentEntity.video,
			};
			return defaultT;
		}
	}, []);

	const { control, formState, getValues, watch, ...formMethods } =
		useForm<VideoType>({
			mode: 'all',
			resolver: yupResolver(VIDEO_SCHEMA),
			defaultValues: defaultValues,
		});

	const onSave = async (form: VideoType) => {
		if (form.video.link_schema === 'upload' && !form.video.uri.match(regex)) {
			displaySnackbar('error', 'Invalid URL!');
		} else if (
			form.video.link_schema === 'url' &&
			!form.video.uri.match(regex)
		) {
			displaySnackbar('error', 'Invalid URL!');
		} else if (
			form.video.link_schema === 'embed' &&
			!form.video.uri.match(embedLink)
		) {
			displaySnackbar('error', 'Invaild Embed Code!');
		} else {
			try {
				await VideoUnitRequester.getInstance().update(form);
				displaySnackbar('success', `Video updated successfully`);
				history.goBack();
			} catch (e) {
				const msg = e.response?.data.message || 'Unable to update Video ';
				displaySnackbar('error', msg);
			}
		}
	};

	return (
		<>
			<FormProvider
				formState={formState}
				getValues={getValues}
				control={control}
				watch={watch}
				{...formMethods}
			>
				<form onSubmit={formMethods.handleSubmit(onSave)}>
					<FlexLayout gridGap="2.5rem" flexDirection="column">
						<EdFormControl
							size="small"
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										{ContentConfig['video'].icon}
									</InputAdornment>
								),
							}}
							control={control}
							label="Unit Name"
							name="unit.name"
						/>
						<VideoLinks />
						<EdFormControlDuration
							label="Duration"
							control={control}
							name="video.duration"
							placeholder="minutes"
						/>
						<EdFormControl
							label="Number of plays"
							control={control}
							name="video.max_plays"
							placeholder="Plays"
						/>
					</FlexLayout>
					<FlexLayout justifyContent="end" gridGap="1rem" marginTop="9rem">
						<Button
							disabled={!formState.isDirty}
							variant="contained"
							color="warning"
						>
							Cancel
						</Button>
						<Button
							color="primary"
							variant="contained"
							disabled={!formState.isDirty}
							onClick={() => {
								onSave(getValues());
							}}
						>
							Save
						</Button>
					</FlexLayout>
				</form>
			</FormProvider>
		</>
	);
};

export default EditVideoInfo;
