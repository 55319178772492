import DateTimeFormatter from '@Components/DateTimeFormatter/DateTimeFormatter';
import MainTable, { MainTableRow } from '@Components/MainTable/MainTable';
import { onPageChangeRequestMeta } from '@Components/MainTable/MainTable.types';
import NoResults from '@Components/NoResults/NoResults';
import { useTypedSelector } from '@Features/store';
import { TableCell } from '@mui/material';
import { studentsGetSingle } from '@Pages/Students/Slice/Students.Slice';
import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { scratchCodeTabGetAll } from './Slices/ScratchCards.Slice';

const TABLE_HEADS = [
	{ displayName: 'classroom name', fieldName: 'classrooms.title' },
	{ displayName: 'course name', fieldName: 'courses.name' },
	{ displayName: 'code', fieldName: 'code' },
	{ displayName: 'batch', fieldName: 'batch' },
	{ displayName: 'used at', fieldName: 'updated_at' },
];

const ScratchCodesTable = () => {
	const dispatch = useDispatch();
	const { id } = useParams<{ id: string }>();
	const { currentEntity } = useTypedSelector((state) => state.Students);
	const { fetchAll, meta, filters } = useTypedSelector(
		(state) => state.ScratchCodeTab
	);
	const onPageChangeRequest = useCallback(
		({
			activePage,
			endDate,
			filters,
			query,
			sortBy,
			startDate,
			perPage,
		}: onPageChangeRequestMeta) => {
			dispatch(
				scratchCodeTabGetAll({
					page: activePage,
					perPage: perPage ?? 10,
					sortBy,
					filters,
					query,
					from: startDate,
					to: endDate,
					id: currentEntity?.id as any,
				})
			);
		},
		[dispatch, currentEntity?.id]
	);

	useEffect(() => {
		dispatch(studentsGetSingle({ id }));
	}, []);

	return (
		<MainTable
			total={meta?.total ?? 0}
			dateFilter
			pageNum={meta?.current_page}
			tableFilters={filters}
			tableHeads={TABLE_HEADS}
			totalPages={meta?.last_page ?? 0}
			RowsperPage={Number(meta?.per_page)}
			$hasActions
			onPageChangeRequest={onPageChangeRequest}
			renderItems={() => {
				if (fetchAll?.length === 0)
					return (
						<MainTableRow>
							<TableCell colSpan={TABLE_HEADS.length}>
								<NoResults />
							</TableCell>
						</MainTableRow>
					);
				return fetchAll?.map((scratch) => {
					return (
						<MainTableRow key={scratch.id} hover>
							<TableCell>{scratch.classroom.title}</TableCell>
							<TableCell>{scratch?.course?.name}</TableCell>
							<TableCell>{scratch.code}</TableCell>
							<TableCell>{scratch.batch}</TableCell>
							<TableCell>
								<DateTimeFormatter date={scratch.updated_at} />
							</TableCell>
							<TableCell />
						</MainTableRow>
					);
				});
			}}
		/>
	);
};

export default ScratchCodesTable;
