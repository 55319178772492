import EdIcon from '@Components/UI/Utilities/EdIcon/EdIcon';
import { useTypedSelector } from '@Features/store';
import { IconButton, TextField } from '@mui/material';
import { FlexLayout, Spacer } from '@Styled/utilities';
import React, { FC } from 'react';
import { Control, Controller, UseFieldArrayRemove } from 'react-hook-form';
import styled from 'styled-components';
import { TypedTestOrderingQuestionPayload } from '../../../Types/TestQuestion';

type Props = {
	index: number | any;
	control: Control<TypedTestOrderingQuestionPayload<'ordering'>>;
	remove: UseFieldArrayRemove;
	canRemoveOption: boolean;
	orderIndex: number;
	setOrderIndex: any;
};

const OrderingOption: FC<Props> = ({
	control,
	index,
	remove,
	canRemoveOption,
	orderIndex,
	setOrderIndex,
}) => {
	const {
		currentEntity: { active },
	} = useTypedSelector((state) => state.Tests);
	return (
		<StyledFlexLayout mb="1rem">
			<Controller
				control={control}
				name={`orderingOptions.${index}.option`}
				render={({ field, fieldState }) => {
					return (
						<TextField
							disabled={active}
							error={fieldState.invalid}
							helperText={fieldState.error?.message}
							size="small"
							{...field}
							placeholder="Answer"
						/>
					);
				}}
			/>
			<Spacer mx="0.5rem" />

			{canRemoveOption && !active && (
				<IconButton
					onClick={() => {
						remove(index);
						setOrderIndex(orderIndex - 1);
					}}
				>
					<EdIcon>delete</EdIcon>
				</IconButton>
			)}
		</StyledFlexLayout>
	);
};

export default OrderingOption;

const StyledFlexLayout = styled(FlexLayout)`
	width: fit-content;
	.MuiIconButton-root {
		visibility: hidden;
	}
	:hover {
		.MuiIconButton-root {
			visibility: visible;
		}
	}
	.MuiFormHelperText-sizeSmall {
		position: initial;
	}
`;
