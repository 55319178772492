import api from '@Services/api';
import { AxiosResponse } from 'axios';
import {
	CRUDBase,
	DeleteSingleEntityPayload,
	GenerateCardMeta,
	GenerateCodesMeta,
	GetAllMeta,
	GetSingleEntityPayload,
} from 'src/base/services/services.base';
import { Device } from '../Types/StudentDevices.types';

interface StudentDevicesCRUD extends CRUDBase<Device, Device> {}
export class StudentDevicesRequester implements StudentDevicesCRUD {
	get(id: string): Promise<AxiosResponse<ApiResponse<Device>>>;
	get(id: number): Promise<AxiosResponse<ApiResponse<Device>>>;
	get(
		id: string | number,
		tId?: string | number | undefined,
		payload?: GetSingleEntityPayload | undefined
	): Promise<AxiosResponse<ApiResponse<Device>>>;
	get(
		id: unknown,
		tId?: unknown,
		payload?: unknown
	): Promise<AxiosResponse<ApiResponse<Device>>> {
		throw new Error('Method not implemented.');
	}
	create(
		payload: Omit<Device, 'id'>
	): Promise<AxiosResponse<ApiResponse<Device>>> {
		throw new Error('Method not implemented.');
	}
	update(payload: Device): Promise<AxiosResponse<ApiResponse<Device>>> {
		throw new Error('Method not implemented.');
	}
	delete(id: number): Promise<AxiosResponse<ApiResponse<any>>>;
	delete(id: string): Promise<AxiosResponse<ApiResponse<any>>>;
	delete(
		id: string | number,
		payload?: DeleteSingleEntityPayload | undefined
	): Promise<AxiosResponse<ApiResponse<any>>>;
	delete(
		id: unknown,
		payload?: unknown
	): Promise<AxiosResponse<ApiResponse<any>>> {
		throw new Error('Method not implemented.');
	}
	private static instance: StudentDevicesRequester;
	static getInstance() {
		if (!StudentDevicesRequester.instance) {
			StudentDevicesRequester.instance = new StudentDevicesRequester();
		}
		return StudentDevicesRequester.instance;
	}
	baseURL = 'user/profile';
	getAll(payload?: GetAllMeta): Promise<AxiosResponse<ApiResponse<Device[]>>>;
	getAll(payload: number): Promise<AxiosResponse<ApiResponse<Device[]>>>;
	async getAll(
		payload?: any
	): Promise<import('axios').AxiosResponse<ApiResponse<Device[]>>> {
		return await api.post(`${this.baseURL}/${payload.id}/devices`, payload);
	}
}

export const logoutAllDevices = async (id: number) =>
	await api.post<ApiResponse<Device>>('devices/logout/all', {
		id,
	});
export const logoutSingleDevice = async (device_id: string, user_id: number) =>
	await api.post<ApiResponse<Device>>('devices/logout/single', {
		device_id,
		user_id,
	});
