import { Fab, FabProps } from '@mui/material';
import { ExtraPropsBase } from '@Styled/theme';
import React, { FC } from 'react';
import styled from 'styled-components';
import { getVariant } from '@Styled/theme';
type Props = {} & ExtraPropsBase & FabProps;
const EdFab: FC<Props> = ({ children, ...props }) => {
	return <StyledFab {...props}>{children}</StyledFab>;
};

export default EdFab;

const StyledFab = styled(Fab)<Props>`
	${({ edbackgroundColor }) =>
		getVariant({
			prop: 'edbackgroundColor',
			variantPath: `fabs.default`,
		})}
`;
