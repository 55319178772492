import api from '@Services/api';
import { CourseWithPrerequisites } from '@Services/classrooms/classrooms.res.types';
import { AxiosResponse } from 'axios';

import {
	CRUDBase,
	DeleteSingleEntityPayload,
	GetAllMeta,
	GetSingleEntityPayload,
} from 'src/base/services/services.base';
import { SequenceManagement } from '../types/sequence.types';

interface SequenceManagementCRUD
	extends CRUDBase<SequenceManagement, SequenceManagement> {}
export class SequenceManagementRequester implements SequenceManagementCRUD {
	private static instance: SequenceManagementRequester;
	static getInstance() {
		if (!SequenceManagementRequester.instance) {
			SequenceManagementRequester.instance = new SequenceManagementRequester();
		}
		return SequenceManagementRequester.instance;
	}
	baseURL = '/classroom';
	getAll(
		payload?: GetAllMeta
	): Promise<AxiosResponse<ApiResponse<SequenceManagement[]>>>;
	getAll(
		payload: number
	): Promise<AxiosResponse<ApiResponse<SequenceManagement[]>>>;
	async getAll(
		payload?: any
	): Promise<import('axios').AxiosResponse<ApiResponse<SequenceManagement[]>>> {
		return await api.get(`${this.baseURL}/${payload.id}/sequence`, payload);
	}
	get(id: string): Promise<AxiosResponse<ApiResponse<SequenceManagement>>>;
	get(id: number): Promise<AxiosResponse<ApiResponse<SequenceManagement>>>;
	get(
		id: string | number,
		payload: GetSingleEntityPayload
	): Promise<AxiosResponse<ApiResponse<SequenceManagement>>>;
	async get(
		id: any,
		payload?: any
	): Promise<AxiosResponse<ApiResponse<SequenceManagement>>> {
		return await api.post(`/${this.baseURL}/get`, { id });
	}
	async create(
		payload: SequenceManagement
	): Promise<AxiosResponse<ApiResponse<SequenceManagement>>> {
		return await api.post(`${this.baseURL}/add`, payload);
	}
	async update(
		payload: SequenceManagement
	): Promise<AxiosResponse<ApiResponse<SequenceManagement>>> {
		return await api.put(`${this.baseURL}/update`, payload);
	}
	async syncUsers(
		label: string
	): Promise<AxiosResponse<ApiResponse<SequenceManagement>>> {
		return await api.post(`${this.baseURL}/students/sync`, { label });
	}
	async setSequence(
		payload: any
	): Promise<AxiosResponse<ApiResponse<SequenceManagement>>> {
		return await api.post(`${this.baseURL}/sequence/set`, payload);
	}

	delete(id: number): Promise<AxiosResponse<ApiResponse<any>>>;
	delete(id: string): Promise<AxiosResponse<ApiResponse<any>>>;
	delete(
		id: string | number,
		payload: DeleteSingleEntityPayload
	): Promise<AxiosResponse<ApiResponse<SequenceManagement>>>;
	async delete(
		id: any,
		payload?: any
	): Promise<AxiosResponse<ApiResponse<any>>> {
		return await api.delete(`${this.baseURL}/remove`, {
			data: payload,
		});
	}
}

export default class SequencesApi {
	public static getSequences(classroom_id: number) {
		return api.get<ApiResponse<CourseWithPrerequisites[]>>(
			`/classroom/${classroom_id}/sequence`
		);
	}
}
