import { Checkbox, FormControlLabel, Modal } from '@mui/material';
import { FlexLayout } from '@Styled/utilities';
import React, { useCallback, useState } from 'react';
import { Container, Header } from './SetModel';
import {
	CancelBtn,
	ConfirmBtn,
} from '@Pages/ClassRooms/Pages/SingleCourse/Pages/CourseForm/Pages/CourseContent/Pages/Webinar/Pages/RegisteredUsers/Components/ChangeUserSlotModule';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { useDispatch } from 'react-redux';
import { useSnackbar } from '@Providers/useSnackbar';
import { studentsGetSingle } from '@Pages/Students/Slice/Students.Slice';
import { deductFromWalletRequest } from '@Services/students/students.req';

type Props = {
	handleModelClose: React.Dispatch<boolean>;
	openModel: boolean;
	walletAmount: string | number;
	id: string;
	setLoading: React.Dispatch<React.SetStateAction<boolean>>;
	setDisplayedAmount: React.Dispatch<React.SetStateAction<number>>;
	setWalletAmount: React.Dispatch<React.SetStateAction<string | number>>;
	setError: React.Dispatch<React.SetStateAction<string>>;
	defaultAmount: number;
};

const DeductModel: React.FC<Props> = ({
	handleModelClose,
	openModel,
	walletAmount,
	id,
	setLoading,
	setDisplayedAmount,
	setWalletAmount,
	setError,
	defaultAmount,
}) => {
	const [type, setType] = useState<string>('');
	const { displaySnackbar } = useSnackbar();
	const dispatch = useDispatch();

	const _deductFromWallet = useCallback(async () => {
		if (!walletAmount) return displaySnackbar('error', 'Amount is required!');
		try {
			setLoading(true);
			await deductFromWalletRequest(id, Number(walletAmount), '', type);
			setDisplayedAmount((prev: any) => prev - Number(walletAmount));
			setWalletAmount(0);
			setError('');
			setLoading(false);
			displaySnackbar('success', 'amount is deducted successfully');
			handleModelClose(false);
			dispatch(studentsGetSingle({ id }));
		} catch (error) {
			const msg =
				error.response?.data.message ||
				"there's a problem updating the amount, please try again!";
			displaySnackbar('error', msg);
		}
	}, [walletAmount, id, defaultAmount, displaySnackbar, type]);

	return (
		<Modal
			open={openModel}
			onClose={() => {
				handleModelClose(false);
			}}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Container>
				<Header>Are you sure you want to..?</Header>
				<FlexLayout flexDirection="column" marginTop="20px" marginBottom="20px">
					<FormControlLabel
						control={
							<Checkbox
								checked={type === 'refundable'}
								onClick={() => setType('refundable')}
								icon={<RadioButtonUncheckedIcon />}
								checkedIcon={<RadioButtonCheckedIcon />}
							/>
						}
						label="Deduct from refundable amount"
					/>
					<FormControlLabel
						control={
							<Checkbox
								checked={type === 'nonrefundable'}
								onClick={() => setType('nonrefundable')}
								icon={<RadioButtonUncheckedIcon />}
								checkedIcon={<RadioButtonCheckedIcon />}
							/>
						}
						label="Deduct from non refundable amount"
					/>
				</FlexLayout>
				<FlexLayout justifyContent="flex-end" gridGap="1rem">
					<CancelBtn
						variant="text"
						color="warning"
						onClick={() => {
							handleModelClose(false);
						}}
					>
						NO
					</CancelBtn>
					<ConfirmBtn
						variant="text"
						color="inherit"
						type="submit"
						onClick={() => {
							_deductFromWallet();
						}}
					>
						YES
					</ConfirmBtn>
				</FlexLayout>
			</Container>
		</Modal>
	);
};

export default DeductModel;
