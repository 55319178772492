import EdFormControl from '@Components/UI/Inputs/EdFormControl/EdFormControl';
import EdIcon from '@Components/UI/Utilities/EdIcon/EdIcon';
import { useTypedSelector } from '@Features/store';
import { yupResolver } from '@hookform/resolvers/yup';
import { AppBar, IconButton, InputAdornment } from '@mui/material';
import React, { useCallback, useEffect } from 'react';
import { useController, useForm } from 'react-hook-form';
import { object, string } from 'yup';
import { Test } from '../../../Types/Test/Test.types';
import { cloneDeep } from 'lodash';
import { DevTool } from '@hookform/devtools';
import EdAnimatedIcon from '@Components/UI/Utilities/EdAnimatedIcon/EdAnimatedIcon';
import { FlexLayout, Spacer } from '@Styled/utilities';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import TestQuestionsTable from '../Components/TestQuestionsTable';
import MCQ from '../Components/Questions/MCQ/MCQ';
import EditMCQ from '../Components/Questions/MCQ/EditMCQ/EditMCQ';
import Ordering from '../Components/Questions/Ordering/Ordering';
import EditOrdering from '../Components/Questions/Ordering/EditOrdering';
import TagsModal from '../Components/TagsModal/TagsModal';
import {
	RouteProvider,
	useRouteProvider,
} from '@Providers/Routes/RoutesProvider';
import EssayQuestion from './EssayQuestion';
const SCHEMA = object({
	name: string(),
});
const GapQuestion = React.lazy(() => import('./GapQuestion/GapQuestion'));

const TestQuestions = () => {
	const { path } = useRouteMatch();
	return (
		<>
			<RouteProvider path={path}>
				<React.Suspense fallback={<div>loading</div>}>
					<Switch>
						<Route path={`${path}`} exact>
							<TestQuestionsTable />
						</Route>
						<Route path={`${path}/tags`}>
							<TagsModal />
						</Route>
						<Route path={`${path}/mcq`} exact>
							<MCQ />
						</Route>
						<Route path={`${path}/mcq/:id`} exact>
							<EditMCQ />
						</Route>
						<Route path={`${path}/gap`}>
							<GapQuestion />
						</Route>
						<Route path={`${path}/essay`}>
							<EssayQuestion />
						</Route>
						<Route path={`${path}/ordering`} exact>
							<Ordering />
						</Route>
						<Route path={`${path}/ordering/:id`} exact>
							<EditOrdering />
						</Route>
					</Switch>
				</React.Suspense>
			</RouteProvider>
		</>
	);
};

export default TestQuestions;
