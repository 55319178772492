import { BaseSliceController } from '@Base/slices/slices.base';
import { RegisteredUsersRequester } from '../Services/RegisteredUsers/RegisteredRequester';
import { UserPayload, RegisteredUser } from '../Types/Webinar.types';

export class RegisteredUsersSlice extends BaseSliceController<
	UserPayload,
	'RegisteredUsers',
	UserPayload,
	RegisteredUser
> {
	private static instance: RegisteredUsersSlice;
	static getInstance() {
		if (!RegisteredUsersSlice.instance) {
			RegisteredUsersSlice.instance = new RegisteredUsersSlice();
		}
		return RegisteredUsersSlice.instance;
	}
	constructor() {
		super('RegisteredUsers', RegisteredUsersRequester.getInstance());
	}
}

export const {
	registeredUsersDeleteById,
	registeredUsersExportData,
	registeredUsersGenerate,
	registeredUsersGetAll,
	registeredUsersGetSingle,
	registeredUsersToggleActive,
	registeredUsersUpdate,
} = RegisteredUsersSlice.getInstance().getActions();
