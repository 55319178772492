import React from 'react';
import {
	Collapse,
	List,
	ListItemButton,
	ListItemIcon,
	ListItemText,
} from '@mui/material';
import styled from 'styled-components';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { PermissionRoles } from '@Pages/Roles/Services/roles.res.types';

type Props = {
	permission: PermissionRoles;
	open: boolean;
};

const CollapedRoles: React.FC<Props> = ({ permission, open }) => {
	return (
		<Collapse in={open}>
			<List disablePadding>
				<ListButton sx={{ pl: 4 }}>
					<ListItemIconStyle>
						<IconStyle />
					</ListItemIconStyle>
					<ListText key={permission.id} primary={permission.name} />
				</ListButton>
			</List>
		</Collapse>
	);
};

export default CollapedRoles;

const IconStyle = styled(FiberManualRecordIcon)`
	font-size: 0.625rem;
	color: #251b33;
`;

const ListItemIconStyle = styled(ListItemIcon)`
	min-width: 1.813rem;
`;

const ListText = styled(ListItemText)`
	color: #251b33;
	font-size: 0.875rem;
	font-weight: 500;
`;

const ListButton = styled(ListItemButton)`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: row;
	.MuiCheckbox-root {
		min-width: 0rem;
	}
`;
