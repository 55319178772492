import { MenuItem, Select } from '@mui/material';
import { booleanString } from '@Utils/BooleanString';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import { UnitsOrder } from '../Constants/UnitOrderCourseCompletion.constants';

const UnitOrderSelect = () => {
	const { control } = useFormContext();

	return (
		<Controller
			render={({ field: { onChange, value, ...fieldProps } }) => {
				return (
					<EdSelect
						{...fieldProps}
						value={value ?? ''}
						onChange={(e: any) => {
							if (e.target.value) {
								const value = booleanString(e.target.value);
								onChange(value);
							}
						}}
					>
						{UnitsOrder &&
							UnitsOrder.map((_) => {
								return (
									<MenuItem key={_.text} value={`${_.id}`}>
										{_.text}
									</MenuItem>
								);
							})}
					</EdSelect>
				);
			}}
			control={control}
			name="sequential_units"
		/>
	);
};

export const EdSelect = styled(Select)`
	height: 2.313rem;
	width: 16.25rem;
	fieldset {
		border: solid 1px rgba(0, 0, 0, 0.12);
	}
`;

export default UnitOrderSelect;
