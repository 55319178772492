import { MenuItem, Select } from '@mui/material';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import { PrerequisiteCriteriaDropdown } from '../Constants/UnitOrderCourseCompletion.constants';

const PrerequisiteCriteria = () => {
	const { control } = useFormContext();

	return (
		<Controller
			control={control}
			name="prerequisite_criteria"
			render={({ field: { onChange, ...fieldProps } }) => {
				return (
					<EdSelect
						native={false}
						{...fieldProps}
						value={fieldProps.value ?? ''}
						onChange={(e: any) => {
							if (e.target.value) {
								const { value } = e.target;
								onChange(value);
							}
						}}
					>
						{PrerequisiteCriteriaDropdown?.map((_) => {
							return (
								<MenuItem key={_.text} value={`${_.id}`}>
									{_.text}
								</MenuItem>
							);
						})}
					</EdSelect>
				);
			}}
		/>
	);
};
export const EdSelect = styled(Select)`
	height: 2.313rem;
	width: 16.25rem;
	fieldset {
		border: solid 1px rgba(0, 0, 0, 0.12);
	}
`;
export default PrerequisiteCriteria;
