import React from 'react';
import LoaderSrc from '@Assets/img/loading_indicator.svg';
import { PageLoaderWrapper } from './pageLoader.styled';
import { RingSpinner } from 'react-spinners-kit';
const PageLoader = () => {
	return (
		<PageLoaderWrapper className=" flex-center">
			<RingSpinner sizeUnit="rem" color="#5AC0FC" size={4} />
		</PageLoaderWrapper>
	);
};

export default React.memo(PageLoader);
