import EdFormControl from '@Components/UI/Inputs/EdFormControl/EdFormControl';
import EdUploadFile from '@Components/UI/Inputs/EdUploadFile/EdUploadFile';
import { Icon, MenuItem } from '@mui/material';
import { GenderTypes } from '@Pages/Admin/Constants/admin.constants';
import { AdminForm } from '@Pages/Admin/Schema/AdminsInfo.schema';
import { AdminPayload } from '@Pages/Admin/Types/admin.types';
import React, { useEffect } from 'react';
import { Control, FormState } from 'react-hook-form';
import {
	GroupStyle,
	SpaceStyle,
	UploadPicStyle,
} from '../../AddAdmin/Components/AddAdminInfo';
import { useTypedSelector } from '@Features/store';
import GroupedBtns from '@Pages/Admin/Components/GroupedBtns';

type Props = {
	control: Control<AdminPayload>;
	getValues: any;
	formState: FormState<AdminForm>;
	watch: any;
	onHasRolesChange: React.Dispatch<boolean>;
	onIsValidChange?: React.Dispatch<boolean>;
	onSave: any;
};

const EditAdminInfo: React.FC<Props> = ({
	control,
	formState,
	watch,
	onHasRolesChange,
	onIsValidChange,
}) => {
	const auth = useTypedSelector((state) => state.auth);
	const hasEditAdminPermission =
		auth.user.type === 'super' || auth.permissions.has('u-adm');

	const watchPhoto = watch('profile_photo');

	useEffect(() => {
		if (watchPhoto !== undefined) {
			onHasRolesChange(watchPhoto);
		}
	}, [watchPhoto, onHasRolesChange]);

	useEffect(() => {
		onIsValidChange && onIsValidChange(formState.isValid);
	}, [formState.isValid]);

	return (
		<>
			<GroupStyle>
				<EdFormControl
					control={control}
					required
					name="first_name"
					label="First Name"
					disabled={!hasEditAdminPermission}
				/>
				<EdFormControl
					control={control}
					name="middle_name"
					label="Middle Name"
					disabled={!hasEditAdminPermission}
				/>
				<EdFormControl
					control={control}
					required
					name="last_name"
					label="Last Name"
					disabled={!hasEditAdminPermission}
				/>
			</GroupStyle>
			<SpaceStyle>
				<EdFormControl
					control={control}
					required
					name="email"
					label="Email"
					disabled={!hasEditAdminPermission}
				/>
				<EdFormControl
					control={control}
					name="password"
					label="Password"
					disabled={!hasEditAdminPermission}
				/>
			</SpaceStyle>
			<SpaceStyle>
				<EdFormControl
					control={control}
					required
					name="phone_number"
					label="Phone Number"
					disabled={!hasEditAdminPermission}
				/>
				<EdFormControl
					control={control}
					select
					name="gender"
					label="Gender"
					disabled={!hasEditAdminPermission}
				>
					<MenuItem value="">None</MenuItem>
					{GenderTypes.map((_, index) => (
						<MenuItem key={`${index}-${_}`} value={_}>{`${_}`}</MenuItem>
					))}
				</EdFormControl>
			</SpaceStyle>
			<GroupStyle></GroupStyle>
			<UploadPicStyle>
				<EdUploadFile
					aspectRation={1}
					folder="profilepictures/instructors"
					uploadLabel="UPLOAD"
					uploadIcon={<Icon>add</Icon>}
					control={control}
					name="profile_photo"
					label="Profile Photo"
					required
					disabled={!hasEditAdminPermission}
				/>
			</UploadPicStyle>

			<GroupStyle>
				<EdFormControl
					control={control}
					switch
					name="active"
					label="Active"
					disabled={!hasEditAdminPermission}
				/>
			</GroupStyle>
			{hasEditAdminPermission && <GroupedBtns formState={formState} />}
		</>
	);
};

export default EditAdminInfo;
