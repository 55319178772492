import { BaseSliceController } from '@Base/slices/slices.base';
import { DocumentsRequester } from '@Pages/ClassRooms/Pages/SingleCourse/Pages/CourseForm/Pages/CourseContent/Pages/Documents/Services/Documents.req';
import {
	Document,
	DocumentPayload,
} from '@Pages/ClassRooms/Pages/SingleCourse/Pages/CourseForm/Pages/CourseContent/Pages/Documents/Types/Documents.types';

export class DocumentsSlice extends BaseSliceController<
	Document,
	'Documents',
	DocumentPayload,
	Document
> {
	static instance: DocumentsSlice;
	static getInstance() {
		if (!DocumentsSlice.instance) {
			DocumentsSlice.instance = new DocumentsSlice();
		}
		return DocumentsSlice.instance;
	}

	constructor() {
		super('Documents', DocumentsRequester.getInstance());
	}
}
export const { documentsUpdate, documentsGetAll, documentsGetSingle } =
	DocumentsSlice.getInstance().getActions();
