import { useTypedSelector } from '@Features/store';
import { useSnackbar } from '@Providers/useSnackbar';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import EdFormControl from '@Components/UI/Inputs/EdFormControl/EdFormControl';
import { yupResolver } from '@hookform/resolvers/yup';
import { DevTool } from '@hookform/devtools';
import {
	EditCategoryForm,
	FULL_SCHEMA,
} from '@Pages/Categories/Schema/CategoryInfo.schema';
import { CategoryRequester } from '@Pages/Categories/Services/Categories.req';
import { Icon, MenuItem } from '@mui/material';
import EdUploadFile from '@Components/UI/Inputs/EdUploadFile/EdUploadFile';
import GroupedBtn from '@Pages/Categories/Components/GroupedBtn';
import { useForm } from 'react-hook-form';
import { Section } from '../../AddCategory/Components/AddCategoryInfo';
import { useDispatch } from 'react-redux';
import { getSingleCategory } from '@Pages/Categories/Slices/CategoriesSlice';
import { getEducationalInfo } from '@Features/classrooms/educationalSlice';

const EditCategoryInfo = () => {
	const { displaySnackbar } = useSnackbar();
	const dispatch = useDispatch();
	const { currentCategory } = useTypedSelector((state) => state.category);
	const { dropdownCategories } = useTypedSelector((state) => state.dropdowns);
	const auth = useTypedSelector((state) => state.auth);
	const hasEditPermission =
		auth.user.type === 'super' || auth.permissions.has('u-ctg');

	useEffect(() => {
		if (currentCategory) {
			dispatch(getEducationalInfo());
		}
	}, [currentCategory]);

	const defaultValues = React.useMemo<EditCategoryForm>(() => {
		if (currentCategory) {
			const defaults: EditCategoryForm = {
				name: currentCategory.name,
				code: currentCategory.code,
				icon: currentCategory.icon,
				parent_id: currentCategory.parent_id,
				id: currentCategory.id,
			};
			return defaults;
		} else {
			return {} as EditCategoryForm;
		}
	}, []);

	const { control, getValues, formState, watch } = useForm<EditCategoryForm>({
		resolver: yupResolver(FULL_SCHEMA),
		mode: 'all',
		defaultValues,
	});

	const onSave = async (form: EditCategoryForm) => {
		try {
			const {
				data: { data },
			} = await CategoryRequester.getInstance().update(form);
			dispatch(getSingleCategory({ id: currentCategory?.id as number }));
			displaySnackbar('success', `Category Updated Successfully`);
		} catch (error) {}
	};
	const isEdit = true;
	return (
		<Container>
			<Section>
				<EdFormControl
					control={control}
					required
					name="name"
					label="Name"
					disabled={!hasEditPermission}
				/>
				<EdFormControl
					control={control}
					select
					name="parent_id"
					label="Parent ID"
					disabled={!hasEditPermission}
				>
					<MenuItem value={NaN}>None</MenuItem>
					{dropdownCategories &&
						dropdownCategories
							.filter((_) => _.id !== currentCategory?.id)
							.map((category) => {
								return (
									<MenuItem value={category.id}>{`${category.name}`}</MenuItem>
								);
							})}
				</EdFormControl>
			</Section>
			<Section>
				<EdFormControl
					control={control}
					required
					name="code"
					label="Code"
					disabled={!hasEditPermission}
				/>
				<EdUploadFile
					folder="icons/subjects"
					uploadLabel="UPLOAD"
					uploadIcon={<Icon>add</Icon>}
					control={control}
					name="icon"
					label="Icon"
					aspectRation={16 / 16}
					disabled={!hasEditPermission}
				/>
			</Section>
			{hasEditPermission && (
				<GroupedBtn
					onSave={onSave}
					getValues={getValues}
					formState={formState}
					edit={isEdit}
				/>
			)}
			<DevTool control={control} />
		</Container>
	);
};

export const Container = styled.div`
	display: flex;
	gap: 1rem;
	flex-direction: column;
	padding-left: 1.5rem;
	padding-top: 1rem;
	max-width: 860px;
	@media screen and (min-width: 1600px) {
		max-width: 1030px;
	}
	.MuiOutlinedInput-root {
		width: 17.8rem;
		height: 2.7rem;
	}
`;

export default EditCategoryInfo;
