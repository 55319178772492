import React, { useMemo, useState } from 'react';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import styled from 'styled-components';
import Collapsable from './Collapsable';
import { GroupPermissions } from '@Pages/Roles/Types/Roles.types';

type Props = {
	groupPermissions: GroupPermissions;
	onSelect: (selected: boolean) => void;
	onPermissionSelect: (selected: boolean, value: number) => void;
	selected: boolean;
	filteredResults: GroupPermissions[];
	search: string;
	currentPermission: Array<number>;
	disabled?: boolean;
};

const CollapedPermissions: React.FC<Props> = ({
	search,
	groupPermissions,
	filteredResults,
	onSelect,
	onPermissionSelect,
	selected: sel,
	currentPermission,
	disabled,
}) => {
	const [open, setOpen] = useState(false);

	const numberOfSelected = useMemo(() => {
		if (
			!groupPermissions.permissions ||
			groupPermissions.permissions.length === 0
		)
			return 0;
		const foundPermissions = groupPermissions.permissions.filter((_) =>
			currentPermission.includes(_.id)
		);
		return foundPermissions.length;
	}, [currentPermission]);

	const handleClick = () => {
		setOpen(!open);
	};

	const _permissions = useMemo(() => {
		return filteredResults.find((_) => _.id === groupPermissions.id)
			?.permissions;
	}, [filteredResults]);

	return (
		<ListWrap>
			<>
				<List
					key={groupPermissions.id}
					component="nav"
					aria-labelledby="nested-list-subheader"
					sx={{
						boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
						backfround: '#fff',
						borderRadius: '5px',
					}}
				>
					<ListItemButton>
						<NameWrapper>
							<ListItemIcon>
								<Checkbox
									edge="start"
									checked={sel ? true : false}
									disabled={disabled}
									onClick={() => {
										onSelect(!sel);
									}}
									inputProps={{
										'aria-labelledby': `checkbox-list-label-${groupPermissions.id}`,
									}}
								/>
							</ListItemIcon>
							<ListItemText
								primary={groupPermissions.title}
								onClick={handleClick}
							/>
						</NameWrapper>
						<SelectedWrapper onClick={handleClick}>
							{open ? (
								<GroupedIcon>
									{numberOfSelected}/{_permissions?.length} Selected
									<ExpandLess />
								</GroupedIcon>
							) : (
								<GroupedIcon>
									{numberOfSelected}/{_permissions?.length} Selected
									<ExpandMore />
								</GroupedIcon>
							)}
						</SelectedWrapper>
					</ListItemButton>
					{_permissions &&
						_permissions?.map((per) => {
							return (
								<div key={`perm-${per.id}`}>
									<Collapsable
										permission={per}
										disabled={disabled}
										open={open}
										onSelect={(selected) =>
											onPermissionSelect(selected, per.id)
										}
										selected={
											currentPermission.find((_) => _ === per.id) ? true : false
										}
									/>
								</div>
							);
						})}
				</List>
			</>
		</ListWrap>
	);
};

export default CollapedPermissions;

const ListWrap = styled.div`
	display: flex;
	flex-direction: column;
	gap: 2rem;
	margin-bottom: 2rem;
	width: 57.625rem;
`;

const GroupedIcon = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 2.188rem;
`;

const NameWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 1.25rem;
	width: 100%;
	.MuiTypography-root {
		font-family: Montserrat;
		font-size: 1rem;
		font-weight: 600;
		color: #251b33;
	}
	.MuiListItemIcon-root {
		min-width: 0rem;
	}
`;

const SelectedWrapper = styled.div`
	display: flex;
	justify-content: end;
	align-items: center;
	flex-direction: row;
	width: 100%;
	color: #251b33;
	font-size: 1rem;
`;
