import { FlexLayout } from '@Styled/utilities';
import React, { FC } from 'react';
import styled from 'styled-components';
import TestAnswer from './TestAnswer/TestAnswer';
import { Attempt } from './TestSummary.types';

type Props = {
	data: Attempt | undefined;
};

const Answers: FC<Props> = ({ data }) => {
	const renderType = (type: string) => {
		switch (type) {
			case 'mcq':
				return 'MCQ';
			case 'gap':
				return 'Fill in the gap';
			case 'ordering':
				return 'Ordering';
			case 'essay':
				return 'Essay';
			default:
				return;
		}
	};

	return (
		<Wrapper gridGap="1.5rem" flexDirection="column">
			{data?.attempt?.questions?.map((question, index) => {
				return (
					<TestAnswer
						type={renderType(question.type)}
						questionNum={'Q'}
						points={'Points'}
						status={data?.attempt.status}
						question={question}
						test={data?.attempt.test}
						index={index}
						notAnswered={'Not Answered'}
					/>
				);
			})}
		</Wrapper>
	);
};

const Wrapper = styled(FlexLayout)`
	max-height: 20rem;
	overflow-y: scroll;
	margin-top: 1.1rem;
	padding: 0 0.1rem;
	&::-webkit-scrollbar {
		display: none;
	}
`;

export default Answers;
