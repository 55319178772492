import { BaseSliceController } from '@Base/slices/slices.base';
import { WebinarsCountRequester } from '../Services/WebinarsCount.service';
import { StudentWebinarsCount } from '../Types/StudentWebinars.types';

export class WebinarsCountSlice extends BaseSliceController<
	StudentWebinarsCount,
	'StudentWebinarsCount'
> {
	public static instance: WebinarsCountSlice = new WebinarsCountSlice();
	constructor() {
		super('StudentWebinarsCount', WebinarsCountRequester.instance);
	}
}

export const { studentWebinarsCountGetSingle } =
	WebinarsCountSlice.instance.getActions();
