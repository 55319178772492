import { useTypedSelector } from '@Features/store';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { assignmentsGetSingle } from '../../Slices/Assignment.Slice';
import EditAssignmentInfo from './EditAssignmentInfo';

const EditAssignment = () => {
	const { id } = useParams<{ id: string }>();
	const dispatch = useDispatch();

	const {
		currentEntity,
		loaders: { getSingle },
	} = useTypedSelector((state) => state.Assignments);

	useEffect(() => {
		dispatch(assignmentsGetSingle({ id: Number(id) }));
	}, []);

	return (
		<>{currentEntity && getSingle === 'fulfilled' && <EditAssignmentInfo />}</>
	);
};

export default EditAssignment;
