import {
	activateInstructor,
	deactivateInstructor,
	getAllInstructors,
	getInstructor,
} from '@Services/instructors/instructors.req';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
	GetInstructorsResponse,
	GetInstructorResponse,
} from '@Services/instructors/instructors.res.types';
import {
	ActiveInstructorPayload,
	DeactiveInstructorPayload,
	FetchAllInstructorsPayload,
	InstructorSliceState,
} from '@Features/instructors/instructorsSlice.types';
import createThunkBody from '@Utils/createThunkBody';

interface FetchInstructorPayload {
	uuid: string;
}

const initialState: InstructorSliceState = {
	instructors: [],
	selectedInstructor: null,
	filters: [],
	lastPage: 0,
	total: 0,
	page: 0,
	perPage: 0,
	loaders: {
		fetchAll: null,
		fetchOne: null,
		activeLoader: null,
		deactiveLoader: null,
	},
	errors: {
		activeError: '',
		deactiveError: '',
		fetchAll: '',
		fetchOne: '',
	},
};

// side effects
export const fetchAllInstructors = createAsyncThunk<
	GetInstructorsResponse,
	FetchAllInstructorsPayload,
	{}
>(
	'instructors/fetch',
	async ({ page, perPage, sortBy, filters, query, from, to }, thunkAPI) => {
		const { data } = await getAllInstructors(
			page,
			perPage,
			filters,
			sortBy,
			query,
			from,
			to
		);
		debugger;

		return data;
	}
);

export const fetchInstructor = createAsyncThunk<
	GetInstructorResponse,
	FetchInstructorPayload,
	{ rejectValue: string }
>('instructor/fetch', async ({ uuid }, thunkAPI) => {
	return await createThunkBody(
		getInstructor(uuid),
		thunkAPI,
		'Error fetching instructor'
	);
});

//active instructor
export const activateSingleInstructor = createAsyncThunk<
	ApiResponse<any>,
	ActiveInstructorPayload,
	{ rejectValue: string }
>('instructor/active', async ({ id }, thunkAPI) => {
	return await createThunkBody<ApiResponse<any>>(
		activateInstructor(id),
		thunkAPI,
		'Error activating instructor'
	);
});
//active instructor
export const deactivateSelectednstructor = createAsyncThunk<
	ApiResponse<any>,
	DeactiveInstructorPayload,
	{ rejectValue: string }
>('instructor/deactive', async ({ id }, thunkAPI) => {
	return await createThunkBody<ApiResponse<any>>(
		deactivateInstructor(id),
		thunkAPI,
		'Error activating instructor'
	);
});

// slice
const instructorSlice = createSlice({
	name: 'instructor',
	initialState,
	reducers: {},
	extraReducers(builder) {
		// fetch all instructors builder
		builder
			.addCase(fetchAllInstructors.pending, (state) => {
				state.loaders.fetchAll = true;
				state.errors.fetchAll = '';
			})

			.addCase(
				fetchAllInstructors.fulfilled,
				(state, { payload: { data, filters, message, meta } }) => {
					state.loaders.fetchAll = false;
					state.instructors = data;
					state.lastPage = meta?.last_page;
					state.filters = filters || [];
					state.total = meta?.total;
					state.page = meta?.current_page;
					state.perPage = meta?.per_page;
				}
			)

			.addCase(fetchAllInstructors.rejected, (state, { payload }) => {
				state.loaders.fetchAll = undefined;
				state.errors.fetchAll = (payload as string) || '';
			});

		// fetch selected instructor
		builder
			.addCase(fetchInstructor.pending, (state) => {
				state.loaders.fetchOne = true;
				state.errors.fetchOne = '';
			})
			.addCase(
				fetchInstructor.fulfilled,
				(state, { payload: { data, filters, message, meta } }) => {
					const _instructor = data[0];
					state.loaders.fetchOne = false;
					state.selectedInstructor = {
						uuid: _instructor.uuid,
						id: _instructor.id,
						lms_id: _instructor.lms_id,
						email: _instructor.email,
						username: _instructor.username,
						phone_number: _instructor.phone_number,
						first_name: _instructor.first_name,
						label: _instructor.instructor.label,
						last_name: _instructor.last_name,
						branch_id: _instructor.instructor.branch_id,
						branch_name: _instructor.instructor.branch_name,
						gender: _instructor.gender,
						profile_photo: _instructor.profile_photo,
						bio: _instructor.instructor.bio,
						weight: _instructor.instructor.weight,
						classrooms: _instructor.instructor.classrooms,
						active: _instructor.instructor.is_active,
						educationLanguages: _instructor.instructor.educationLanguages,
						educationYears: _instructor.instructor.educationYears,
						educationTypes: _instructor.instructor.educationTypes,
						educationSections: _instructor.instructor.educationSections,
					};
				}
			)

			.addCase(fetchInstructor.rejected, (state, { payload }) => {
				state.loaders.fetchOne = undefined;
				state.errors.fetchOne = payload || '';
			});

		//active instructor
		builder
			.addCase(activateSingleInstructor.pending, (state) => {
				state.loaders.activeLoader = true;
				state.errors.activeError = '';
			})
			.addCase(activateSingleInstructor.fulfilled, (state, { payload }) => {
				state.loaders.activeLoader = false;
			})
			.addCase(activateSingleInstructor.rejected, (state, { payload }) => {
				state.loaders.activeLoader = undefined;
				state.errors.activeError = payload || 'could not active instructor';
			});

		//deactive instructor
		builder
			.addCase(deactivateSelectednstructor.pending, (state) => {
				state.loaders.deactiveLoader = true;
				state.errors.deactiveError = '';
			})
			.addCase(deactivateSelectednstructor.fulfilled, (state, { payload }) => {
				state.loaders.deactiveLoader = false;
			})
			.addCase(deactivateSelectednstructor.rejected, (state, { payload }) => {
				state.loaders.deactiveLoader = undefined;
				state.errors.deactiveError =
					payload || 'could not deactivate instructor';
			});
	},
});

export default instructorSlice.reducer;
