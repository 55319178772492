import React from 'react';
import RoundButton from '@Components/UI/Buttons/RoundButton';
import { Icon } from '@mui/material';
import { useHistory } from 'react-router-dom';

type Props = {} & React.ComponentProps<typeof RoundButton>;
const AddRoleButton: React.FC<Props> = ({ ...props }) => {
	const histroy = useHistory();

	return (
		<RoundButton
			edcolor="purple"
			onClick={() => histroy.push('/dashboard/roles/create/role#info')}
			startIcon={<Icon>add</Icon>}
			variant="contained"
			{...props}
		>
			Add Role
		</RoundButton>
	);
};

export default AddRoleButton;
