import { CardContainer } from '@Pages/ClassRooms/Pages/AddClassroom/AddClassroom';
import React from 'react';
import AddInstructorInfo from './Components/AddInstructorInfo';

const AddInstructor = () => {
	return (
		<CardContainer>
			<AddInstructorInfo />
		</CardContainer>
	);
};

export default AddInstructor;
