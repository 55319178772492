import React, { useEffect, useMemo, useState } from 'react';
import {
	Search,
	SearchIconWrapper,
	StyledInputBase,
} from '@Components/MainTable/MainTable.styled';
import smallLoader from '@Assets/img/smallLoader.svg';
import SearchIcon from '@mui/icons-material/Search';
import { Stack } from '@mui/material';
import { RolesData } from '../Services/roles.res.types';
import { GroupPermissions } from '../Types/Roles.types';
import { Updater, useImmer } from 'use-immer';
type Props = {
	group_permissions: GroupPermissions[];
	setSearchInput: (value: any) => void;
	searchInput: string;
	setFilteredResults: Updater<GroupPermissions[]>;
};

const SearchInput: React.FC<Props> = ({
	group_permissions: groups,
	searchInput,
	setSearchInput,
	setFilteredResults,
}) => {
	const [debounceLoading, setDebounceLoading] = useState(false);
	const [search, setSearch] = useState('');

	const results = useMemo(() => {
		let newFiltered = Array.from(groups);

		newFiltered = newFiltered.map((_) => {
			const permissions = _.permissions.filter(
				(permission) =>
					search === '' ||
					permission.name.toLowerCase().includes(search.toLowerCase())
			);
			return { ..._, permissions } as GroupPermissions;
		});
		return newFiltered
			.filter((group) => {
				if (search == '') {
					return group;
				} else if (group?.permissions?.length == 0) {
					return null;
				} else if (
					group.permissions.length > 0 ||
					group.title.toLocaleLowerCase().includes(search.toLocaleLowerCase())
				) {
					return group;
				}
			})
			.map((newGroup) => {
				return newGroup;
			});
	}, [groups, search]);

	useEffect(() => {
		setFilteredResults(results);
	}, [results]);

	return (
		<>
			<Stack
				sx={{ width: '58.563rem', marginBottom: '0.313rem' }}
				direction="row-reverse"
			>
				<Search>
					<SearchIconWrapper>
						{debounceLoading ? (
							<img
								className="pl-1"
								src={smallLoader}
								alt="Loading.."
								width={15}
							/>
						) : (
							<SearchIcon
								sx={{ color: '#6c63ff', width: '18px', height: '18px' }}
							/>
						)}
					</SearchIconWrapper>
					<StyledInputBase
						inputProps={{ 'aria-label': 'search' }}
						placeholder="Search"
						onChange={(e) => setSearch(e.target.value)}
						value={search}
					/>
				</Search>
			</Stack>
		</>
	);
};

export default SearchInput;
