import { boolean, object, string, number } from 'yup';

export const SETTINGS_SCHEMA = object({
	fawry_fawry: boolean(),
	ewallet_fawry: boolean(),
	aman_accept: boolean(),
	masary_accept: boolean(),
	credit_card_accept: boolean(),
	ewallet_accept: boolean(),
	credit_card_opay: boolean(),
	ewallet_opay: boolean(),
	device_limit: number(),
	email_verification_required: boolean().required(),
	exams_section: boolean().required(),
	id: number(),
	parent_portal: boolean(),
	phone_verification_required: boolean().required(),
	recharge_card: boolean(),
	registration_fees: number().required(),
	revision_section: boolean().required(),
	surcharge_registration_fees: number().required(),
	whatsapp_token: string(),
	theme: string(),
	landing_headline: string()
		.nullable(true)
		.transform((_, value) => {
			return value === '' ? null : value;
		}),
	landing_subheadline: string()
		.nullable(true)
		.transform((_, value) => {
			return value === '' ? null : value;
		}),
	instructor_section: boolean().nullable(true),
	subjects_section: boolean().nullable(true),
	about_us_page: boolean().nullable(true),
	terms_link: string().nullable(true).transform((_, value) => {
		return value === '' ? null : value;
	}),
	privacy_link: string().nullable(true).transform((_, value) => {
		return value === '' ? null : value;
	}),
	fb_link: string().nullable(true).transform((_, value) => {
		return value === '' ? null : value;
	}),
	youtube_link: string().nullable(true).transform((_, value) => {
		return value === '' ? null : value;
	}),
	linkedin_link: string().nullable(true).transform((_, value) => {
		return value === '' ? null : value;
	}),
	instagram_link: string().nullable(true).transform((_, value) => {
		return value === '' ? null : value;
	}),
});
