import api from '@Services/api';
import { AxiosResponse } from 'axios';
import {
	CRUDBase,
	DeleteSingleEntityPayload,
	GenerateCardMeta,
	GenerateCodesMeta,
	GetAllMeta,
	GetSingleEntityPayload,
} from 'src/base/services/services.base';
import { StudentTestsPayload } from '../Types/Tests.types';

interface StudentTestsCRUD
	extends CRUDBase<StudentTestsPayload, StudentTestsPayload> {}
export class StudentTestsRequester implements StudentTestsCRUD {
	get(id: string): Promise<AxiosResponse<ApiResponse<StudentTestsPayload>>>;
	get(id: number): Promise<AxiosResponse<ApiResponse<StudentTestsPayload>>>;
	get(
		id: string | number,
		tId?: string | number | undefined,
		payload?: GetSingleEntityPayload | undefined
	): Promise<AxiosResponse<ApiResponse<StudentTestsPayload>>>;
	get(
		id: unknown,
		tId?: unknown,
		payload?: unknown
	): Promise<AxiosResponse<ApiResponse<StudentTestsPayload>>> {
		throw new Error('Method not implemented.');
	}
	delete(id: number): Promise<AxiosResponse<ApiResponse<any>>>;
	delete(id: string): Promise<AxiosResponse<ApiResponse<any>>>;
	delete(
		id: string | number,
		payload?: DeleteSingleEntityPayload | undefined
	): Promise<AxiosResponse<ApiResponse<any>>>;
	delete(
		id: unknown,
		payload?: unknown
	): Promise<AxiosResponse<ApiResponse<any>>> {
		throw new Error('Method not implemented.');
	}
	generate?(
		payload: GenerateCodesMeta | GenerateCardMeta
	): Promise<AxiosResponse<string>> {
		throw new Error('Method not implemented.');
	}
	toggleActive?(
		payload: StudentTestsPayload
	): Promise<AxiosResponse<ApiResponse<StudentTestsPayload>>> {
		throw new Error('Method not implemented.');
	}
	process?(id: number): Promise<AxiosResponse<ApiResponse<any>>> {
		throw new Error('Method not implemented.');
	}
	create(
		payload: Omit<StudentTestsPayload, 'id'>
	): Promise<AxiosResponse<ApiResponse<StudentTestsPayload>>> {
		throw new Error('Method not implemented.');
	}
	update(
		payload: StudentTestsPayload
	): Promise<AxiosResponse<ApiResponse<StudentTestsPayload>>> {
		throw new Error('Method not implemented.');
	}
	private static instance: StudentTestsRequester;
	static getInstance() {
		if (!StudentTestsRequester.instance) {
			StudentTestsRequester.instance = new StudentTestsRequester();
		}
		return StudentTestsRequester.instance;
	}
	baseURL = 'classroom/analytics/tests';
	async export(payload: GetAllMeta): Promise<AxiosResponse<string>> {
		return await api.post(`${this.baseURL}`, { ...payload, export: true });
	}
	getAll(
		payload?: GetAllMeta
	): Promise<AxiosResponse<ApiResponse<StudentTestsPayload[]>>>;
	getAll(
		payload: number
	): Promise<AxiosResponse<ApiResponse<StudentTestsPayload[]>>>;
	async getAll(
		payload?: any
	): Promise<
		import('axios').AxiosResponse<ApiResponse<StudentTestsPayload[]>>
	> {
		return await api.post(`${this.baseURL}`, payload);
	}
}
