import { LinearProgress } from '@mui/material';
import styled from 'styled-components';

type Props = {
	progress: number;
};

const UserProgress: React.FC<Props> = ({ progress }) => {
	return (
		<Container>
			<Label>
				<span>{progress}%</span>
			</Label>
			<LinearProgressStyle value={progress} variant="determinate" />
		</Container>
	);
};

const Container = styled.div`
	display: flex;
	justify-content: center;
	.MuiLinearProgress-root {
		background-color: #afafaf;
	}
`;

const LinearProgressStyle = styled(LinearProgress)`
	width: 8.25rem;
	height: 1.2rem;
	margin: 0 auto;
	border-radius: 10px;
	position: relative;
`;

const Label = styled.div`
	position: absolute;
	z-index: 1;
	text-align: left;
	display: flex;
	align-items: center;
	margin-left: -3.5rem;
	margin-top: 0.08rem;
	@media only screen and (max-width: 1024px) {
		margin-left: -1.5rem;
	}
	@media only screen and (min-width: 1024px) and (max-width: 1440px) {
		margin-left: -2.5rem;
	}
	span {
		width: 100%;
		font-size: 0.75rem;
		color: #fff;
		font-weight: 500;
	}
`;

export default UserProgress;
