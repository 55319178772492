import { useTypedSelector } from '@Features/store';
import { FlexLayout, GridLayout, Spacer } from '@Styled/utilities';
import React from 'react';
import { FlexLayoutWrapper, Title, Text, Info, TimeInfo } from './InvoicesCard';
import moment from 'moment';
import styled from 'styled-components';
import { blue } from '@mui/material/colors';

const TransactionCard = () => {
	const { currentEntity } = useTypedSelector((state) => state.Invoices);
	return (
		<FlexLayoutWrapper flexDirection="column">
			<Title>Transaction</Title>
			<GridLayout>
				<Text>Email </Text>
				<Info> {currentEntity?.student?.user?.email} </Info>
			</GridLayout>
			<GridLayout>
				<Text>Username </Text>
				<Info> {currentEntity?.student?.user?.username} </Info>
			</GridLayout>
			<Spacer mb="4rem" />

			<GridLayout>
				<Text>Transaction Refrence </Text>
				<Info> {currentEntity?.transaction?.transaction_ref} </Info>
			</GridLayout>
			<GridLayout>
				<Text>Provider </Text>
				<Info> {currentEntity?.transaction?.provider} </Info>
			</GridLayout>
			<GridLayout>
				<Text>Provider Refrence </Text>
				<Info> {currentEntity?.transaction?.provider_ref} </Info>
			</GridLayout>
			<GridLayout>
				<Info>{currentEntity?.transaction?.method}</Info>
				<StatusStyle justifyContent="center" alignItems="center">
					{currentEntity?.status}
				</StatusStyle>
			</GridLayout>
			<GridLayout>
				<Info>{currentEntity?.total_price} EGP</Info>
				<Info>
					{moment(currentEntity?.created_at).format('DD-MM-YY')}
					&nbsp; &nbsp;
					<TimeInfo>
						{moment(currentEntity?.created_at).format('kk:mm')}
					</TimeInfo>
				</Info>
			</GridLayout>
		</FlexLayoutWrapper>
	);
};

const StatusStyle = styled(FlexLayout)`
	width: 6.188rem;
	height: 1.5rem;
	font-size: 1.063rem;
	font-weight: 600;
	border-radius: 35px;
	padding: 0.125rem 0.75rem;
	color: ${blue['500']};
	border: 1px solid ${blue['500']};
`;

export default TransactionCard;
