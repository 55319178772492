import { useTypedSelector } from '@Features/store';
import { yupResolver } from '@hookform/resolvers/yup';
import { EDIT_NEWSFEED } from '@Pages/NewsFeeds/Schema/NewsFeed.schema';
import { NewsFeedRequester } from '@Pages/NewsFeeds/Services/NewsFeed.req';
import { useSnackbar } from '@Providers/useSnackbar';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Wrapper } from '@Pages/Categories/Pages/AddCategory/Components/AddCategoryInfo';
import {
	SectionStyle,
	UrlStyleWrapper,
} from '../../AddNewsFeed/Components/AddNewsFeedInfo';
import EdFormControl from '@Components/UI/Inputs/EdFormControl/EdFormControl';
import EdUploadFile from '@Components/UI/Inputs/EdUploadFile/EdUploadFile';
import { Icon, Stack } from '@mui/material';
import { getSingleNewsFeed } from '@Pages/NewsFeeds/Slices/NewsFeedSlice';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
	CancelBtn,
	SaveBtn,
} from '@Pages/Roles/pages/AddRole/Components/addRoleInfo';
import { NewsFeedsRes } from '@Pages/NewsFeeds/Types/NewsFeed.types';

const EditNewsFeedInfo = () => {
	const { displaySnackbar } = useSnackbar();
	const dispatch = useDispatch();
	const history = useHistory();

	const {
		currentFeed,
		loaders: { updateFeed },
	} = useTypedSelector((state) => state.newsFeed);
	const auth = useTypedSelector((state) => state.auth);

	const enabled = auth.user.type === 'super' || auth.permissions.has('u-nws');

	const formValues = React.useMemo<NewsFeedsRes | undefined>(() => {
		if (currentFeed) {
			const values: NewsFeedsRes = {
				id: currentFeed.id,
				content: currentFeed.content,
				redirection_url: currentFeed.redirection_url,
				photo: currentFeed.photo,
				photo_sm: currentFeed.photo_sm,
				is_active: currentFeed.is_active,
			};
			return values;
		}
	}, []);

	const { formState, control, getValues, ...formMethods } =
		useForm<NewsFeedsRes>({
			resolver: yupResolver(EDIT_NEWSFEED),
			mode: 'all',
			defaultValues: formValues,
		});

	const onSave = async (form: NewsFeedsRes) => {
		try {
			const {
				data: { data },
			} = await NewsFeedRequester.getInstance().update(form);
			dispatch(getSingleNewsFeed({ id: currentFeed?.id as number }));
			displaySnackbar(
				'success',
				`NewsFeed ${data.content} Updated Successfully`
			);
		} catch (error) {}
	};

	return (
		<Wrapper>
			<FormProvider
				formState={formState}
				control={control}
				getValues={getValues}
				{...formMethods}
			>
				<form onSubmit={formMethods.handleSubmit(onSave)}>
					<SectionStyle>
						<EdFormControl
							control={control}
							required
							name="content"
							label="Content"
							disabled={!enabled}
						/>
						<EdUploadFile
							folder="icons/subjects"
							uploadLabel="UPLOAD"
							uploadIcon={<Icon>add</Icon>}
							control={control}
							name="photo"
							label="Desktop Photo"
							aspectRation={58 / 11}
							optimize={false}
							disabled={!enabled}
							required
						/>
					</SectionStyle>
					<SectionStyle style={{ marginTop: '30px', marginBottom: '15px' }}>
						<UrlStyleWrapper>
							<EdFormControl
								control={control}
								required
								name="redirection_url"
								label="Redirection URL"
								type="url"
								disabled={!enabled}
							/>
						</UrlStyleWrapper>
						<EdUploadFile
							folder="icons/subjects"
							uploadLabel="UPLOAD"
							uploadIcon={<Icon>add</Icon>}
							control={control}
							name="photo_sm"
							label="Mobile Photo"
							aspectRation={72 / 43}
							optimize={false}
							required
						/>
					</SectionStyle>

					<EdFormControl
						control={control}
						switch
						name="is_active"
						label="Active"
						disabled={!enabled}
					/>
					{enabled && (
						<Stack
							sx={{ marginTop: '2.625rem' }}
							spacing={2}
							direction="row-reverse"
						>
							<SaveBtn
								variant="contained"
								disabled={!formState.isDirty || !formState.isValid}
								onClick={() => {
									onSave(getValues());
								}}
							>
								SAVE
							</SaveBtn>
							<CancelBtn
								variant="contained"
								disabled={!formState.isDirty}
								onClick={() => history.push('/dashboard/newsfeed')}
							>
								CANCEL
							</CancelBtn>
						</Stack>
					)}
				</form>
			</FormProvider>
		</Wrapper>
	);
};

export default EditNewsFeedInfo;
