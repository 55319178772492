import { BaseSliceController } from '@Base/slices/slices.base';
import { ImportsRequester } from '@Pages/Imports/Services/Imports.req';
import { Import } from '@Pages/Imports/Types/Imports.types';

export class ImportsSlice extends BaseSliceController<Import, 'Imports'> {
	private static instance: ImportsSlice;
	static getInstance() {
		if (!ImportsSlice.instance) {
			ImportsSlice.instance = new ImportsSlice();
		}
		return ImportsSlice.instance;
	}
	constructor() {
		super('Imports', ImportsRequester.getInstance());
	}
}

export const { importsGetAll } = ImportsSlice.getInstance().getActions();
