import React, { useCallback, useMemo, useState } from 'react';
import EdIcon from '@Components/UI/Utilities/EdIcon/EdIcon';
import EdButton from '@Components/UI/Buttons/EdButton/EdButton';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	FormControlLabel,
	Icon,
	Radio,
	RadioGroup,
	TextField,
	IconButton,
} from '@mui/material';
import { FlexLayout, Spacer } from '@Styled/utilities';
import styled from 'styled-components';
import {
	useFormContext,
	Controller,
	UseFieldArrayUpdate,
	UseFieldArrayRemove,
} from 'react-hook-form';
import { ClassroomAdmissionForm } from '@Pages/ClassRooms/Types/ClassroomAdmission.types';
import { v4 as uuid } from 'uuid';
import { ClassroomAdmissionRequester } from '@Pages/ClassRooms/Services/ClassroomAdmission/RequestsHandler/ClassroomAdmission.req';
import { useTypedSelector } from '@Features/store';
type Props = {
	index: number;
	question: Question;
	update: UseFieldArrayUpdate<ClassroomAdmissionForm, 'questions'>;
	remove: UseFieldArrayRemove;
};

const isMcqQuestion = (question: BasicQuestion): question is McqQuestion => {
	return question.type === 'mcq';
};

const QuestionSectionRHF: React.FC<Props> = ({
	index,
	question,
	update,
	remove,
}) => {
	const { control, unregister } = useFormContext<ClassroomAdmissionForm>();

	const { id } = useTypedSelector((state) => state.classroomAdmissionForm);
	const [expanded, setExpanded] = useState(false);

	const cantAddOption = useMemo<boolean>(() => {
		if (isMcqQuestion(question) && question.options) {
			return question.options.some((option) => option.option === '');
		}
		return false;
	}, [question]);

	const radioChangeHandler = (value: QuestionType) => {
		update(index, { ...question, type: value, options: [] });
		unregister(`questions.${index}.options`);
	};

	const removeQuestionHandler = async () => {
		try {
			if (question.id) {
				await ClassroomAdmissionRequester.getInstance().delete(
					question.id.toString(),
					{
						question_id: question.id,
						admission_form_id: id,
					}
				);
			}
			remove(index);
		} catch (error) {}
	};

	const removeOptionHandler = (optionIndex: number) => {
		if (!isMcqQuestion(question)) return;
		question.options.splice(optionIndex, 1);
		update(index, { ...question });
	};
	const addOptionHandler = () => {
		if (!isMcqQuestion(question)) return;
		update(index, {
			...question,
			options: [...question.options, { option: '', id: uuid() }],
		});
	};

	const renderMcqHandler = () => {
		if (!isMcqQuestion(question) || !question.options) return null;

		return (
			<OptionsContainer>
				{question.options.map((option, optionIndex) => {
					return (
						<OptionInput>
							<Controller
								key={option.id}
								control={control}
								name={`questions.${index}.options.${optionIndex}.option`}
								render={({ field: { value, ...fieldProps }, fieldState }) => {
									return (
										<TextField
											placeholder={`Option ${optionIndex + 1}`}
											variant="standard"
											error={fieldState.invalid}
											{...fieldProps}
											value={value}
										/>
									);
								}}
							/>
							<Spacer mx={'.75rem'} />
							<Icon
								onClick={() => removeOptionHandler(optionIndex)}
								style={{ marginLeft: '5px', marginTop: '5px' }}
							>
								cancel
							</Icon>
						</OptionInput>
					);
				})}
				<EdButton onClick={addOptionHandler} disabled={cantAddOption}>
					Add option
				</EdButton>
			</OptionsContainer>
		);
	};

	const isExpandable = useMemo(() => {
		return isMcqQuestion(question);
	}, [question]);

	const onExpand = useCallback(() => {
		if (isExpandable) {
			setExpanded(!expanded);
		}
	}, [isExpandable, setExpanded, expanded]);

	return (
		<QuestionWrapper>
			<QuestionNumber>Q {index + 1}.</QuestionNumber>
			<QuestionAccordion expanded={isExpandable && expanded}>
				<QuestionSummary
					expandIcon={
						<Icon
							onClick={onExpand}
							style={{
								opacity: isExpandable ? '1' : '0',
							}}
						>
							expand_more
						</Icon>
					}
				>
					<QuestionContainer>
						<EdIcon style={{ cursor: 'grabbing' }}>height</EdIcon>
						<Controller
							control={control}
							name={`questions.${index}.question`}
							render={({ field: { onChange, ...fieldProps }, fieldState }) => {
								return (
									<QuestionTextField
										placeholder={'Question ' + (index + 1)}
										onChange={(e) => {
											update(index, { ...question, question: e.target.value });
											onChange(e);
										}}
										{...fieldProps}
										error={fieldState.invalid}
										size="small"
									/>
								);
							}}
						/>
						<FlexLayout>
							<Controller
								control={control}
								name={`questions.${index}.type`}
								render={({
									field: { onChange, ...fieldProps },
									fieldState,
								}) => {
									return (
										<RadioGroup
											row
											style={{ flex: 1.5 }}
											{...fieldProps}
											onChange={(event, value) => {
												radioChangeHandler(value as QuestionType);
												onChange(event);
											}}
										>
											<FormControlLabel
												value="mcq"
												label="MCQ"
												control={<Radio />}
											/>
											<FormControlLabel
												value="essay"
												label="Text"
												control={<Radio />}
											/>
											<FormControlLabel
												value="upload"
												label="Upload"
												control={<Radio />}
											/>
										</RadioGroup>
									);
								}}
							/>
						</FlexLayout>
					</QuestionContainer>
				</QuestionSummary>
				{renderMcqHandler()}
			</QuestionAccordion>
			<IconButton onClick={removeQuestionHandler}>
				<EdIcon>delete</EdIcon>
			</IconButton>
		</QuestionWrapper>
	);
};
const QuestionWrapper = styled.div`
	margin-bottom: 1.875rem;
	display: flex;
	align-items: center;

	.Mui-focused,
	.Mui-focusVisible {
		background-color: transparent;
	}
`;
const QuestionSummary = styled(AccordionSummary)``;
const QuestionAccordion = styled(Accordion)`
	flex: 1;
	margin: 0 1.5rem;

	&.Mui-expanded {
		margin: 0 1.5rem;
	}
`;
const QuestionContainer = styled(FlexLayout)`
	width: 100%;
	align-items: center;

	.MuiRadio-root.Mui-checked {
		color: #6c63ff;
	}
`;

const QuestionTextField = styled(TextField)`
	flex: 1;
	margin: 0 1rem;
`;

const OptionsContainer = styled(AccordionDetails)`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding-left: 3.313rem;
`;
const OptionInput = styled.div`
	display: flex;
	align-items: center;
	margin-top: 5px;

	.MuiIcon-root {
		font-size: 14px;
		cursor: pointer;
	}
`;

const QuestionNumber = styled.span`
	font-size: 1rem;
	font-weight: bold;
`;
export default QuestionSectionRHF;
