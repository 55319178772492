import { useTypedSelector } from '@Features/store';
import React, { useEffect, useMemo } from 'react';
import { Control, FormState, Path, useController } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { AdminForm } from '../Schema/AdminsInfo.schema';
import CollapseContainer from './CollapseContainer';
import GroupedBtns from './GroupedBtns';
import { getAllRoles } from '@Pages/Roles/Features/rolesSlice';
import { useDispatch } from 'react-redux';
import { RolesData } from '@Pages/Roles/Services/roles.res.types';
import { AdminPayload } from '../Types/admin.types';
import { Stack } from '@mui/material';
import {
	CancelBtn,
	SaveBtn,
} from '@Pages/Roles/pages/AddRole/Components/addRoleInfo';

type Props = {
	control: Control<AdminPayload>;
	getValues: any;
	formState: FormState<AdminForm>;
	onSave: any;
	name: Path<AdminPayload>;
	setAssignedRoles: (data: Array<RolesData>) => void;
};

const AdminRoles: React.FC<Props> = ({
	control,
	getValues,
	formState,
	onSave,
	name,
	setAssignedRoles,
}) => {
	const history = useHistory();

	const {
		field: { onChange, value, onBlur, ref },
	} = useController({
		control,
		name,
	});
	const dispatch = useDispatch();
	const { roles } = useTypedSelector((state) => state.roles);
	useEffect(() => {
		dispatch(
			getAllRoles({
				page: 1,
				perPage: 10,
				filters: [],
				query: '',
			})
		);
	}, []);

	const selectedValue: Array<any> = useMemo(() => {
		return [...new Set(value as any)];
	}, [value]);

	useEffect(() => {
		const assigned =
			selectedValue &&
			roles?.filter((val) => selectedValue.some((_: number) => _ === val.id));
		setAssignedRoles(assigned);
	}, [roles, setAssignedRoles, selectedValue]);

	return (
		<>
			<HeadingStyle>Admin roles</HeadingStyle>
			<div onBlur={onBlur} ref={ref}>
				{roles
					?.filter((_) => _.active === true)
					.map((_) => {
						const { id } = _;
						const isSelected =
							selectedValue &&
							roles?.length > 0 &&
							roles?.every((val) =>
								selectedValue.find((_: number) => _ === val.id)
							);

						const group = roles?.find((y) => y.id === _.id);
						return (
							<div key={`roles-${id}`}>
								{group && (
									<CollapseContainer
										groupRoles={group}
										onSelect={(selected: boolean) => {
											const values: Array<number> = selectedValue;
											if (selected) {
												if (!values) {
													onChange(roles?.map((_) => _.id));
												} else {
													onChange(values.concat(roles?.map((_) => _.id)));
												}
											} else {
												roles.forEach((role) => {
													const index = values?.findIndex((_) => _ === role.id);
													if (index !== undefined) {
														values?.splice(index, 1);
													}
												});
												onChange(values);
											}
										}}
										selected={isSelected}
										onRoleSelect={(selected, perValue) => {
											const values: Array<number> = selectedValue;
											if (!values && selected) {
												onChange([perValue]);
												return;
											}
											if (selected) {
												if (!values?.find((_) => _ === perValue)) {
													onChange([...values, perValue]);
												}
											} else {
												const index = values?.findIndex((_) => _ === perValue);
												values.splice(index, 1);
												onChange(values);
											}
										}}
										currentRole={selectedValue ?? []}
									/>
								)}
							</div>
						);
					})}
			</div>
			<GroupedBtns formState={formState} />
		</>
	);
};

export const HeadingStyle = styled.div`
	font-size: 1.125rem;
	font-weight: bold;
	color: #6c63ff;
	margin-top: 0.438rem;
	margin-bottom: 2.688rem;
	margin-left: 0.813rem;
`;

export default AdminRoles;
