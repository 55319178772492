import { GetAllInvoices } from '@Services/invoices/invoices.req';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import createThunkBody from '@Utils/createThunkBody';
import { GetAllInvoicesResponse } from '@Services/invoices/invoices.req.types';
import {
	FetchAllInvoicesPayload,
	InvoiceState,
} from '@Features/invoices/invoicesSlice.types';
import { HeadersResponse } from '@Services/generics/response';

// side effects
export const FetchAllInvoices = createAsyncThunk<
	HeadersResponse<any>,
	FetchAllInvoicesPayload,
	{ rejectValue: string }
>(
	'invoices/fetch',
	async (
		{ page, perPage, sortBy, filters, query, from, to, exportFlag },
		thunkApi
	) => {
		const { data, headers } = await GetAllInvoices({
			exportFlag: exportFlag,
			filters: filters,
			from: from,
			page: page,
			perPage: perPage,
			query: query,
			sortBy: sortBy,
			to: to,
		});
		return {
			response_data: data,
			headers,
		};
	}
);

const initialState: InvoiceState = {
	invoices: [],
	lastPage: 0,
	page: 0,
	perPage: 0,
	filters: [],
	total: 0,
	loaders: {
		fetchAll: null,
		fetchOne: null,
		exportFile: null,
	},
	errors: {
		fetchAll: '',
		fetchOne: '',
		exportFile: '',
	},
};

// slice
const invoiceSlice = createSlice({
	name: 'invoice',
	initialState,
	reducers: {},
	extraReducers(builder) {
		builder
			.addCase(FetchAllInvoices.pending, (state, { meta: { arg } }) => {
				if (arg.exportFlag) {
					state.loaders.exportFile = true;
					state.errors.exportFile = '';
				} else {
					state.loaders.fetchAll = true;
					state.errors.fetchAll = '';
				}
			})
			.addCase(
				FetchAllInvoices.fulfilled,
				(state, { payload: { response_data, headers } }) => {
					const { meta, data, filters } = response_data;

					if (data) {
						state.loaders.fetchAll = false;
						state.invoices = data;
						state.page = meta.total;
						state.lastPage = meta.last_page;
						state.perPage = meta.per_page;
						state.filters = filters;
						state.total = meta.total;
					} else {
						state.loaders.exportFile = false;
						var dataStr = 'data:text/csv;charset=utf-8,' + response_data;
						var downloadAnchorNode = document.createElement('a');
						downloadAnchorNode.setAttribute('href', dataStr);
						downloadAnchorNode.setAttribute(
							'download',
							headers['content-disposition']?.split('=')[1] ?? 'file'
						);
						document.body.appendChild(downloadAnchorNode); // required for firefox
						downloadAnchorNode.click();
						downloadAnchorNode.remove();
					}
				}
			)
			.addCase(
				FetchAllInvoices.rejected,
				(state, { payload, meta: { arg } }) => {
					if (arg.exportFlag) {
						state.loaders.exportFile = undefined;
						state.errors.exportFile = payload || '';
					} else {
						state.loaders.fetchAll = undefined;
						state.errors.fetchAll = payload || '';
					}
				}
			);
	},
});

export default invoiceSlice.reducer;
