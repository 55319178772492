import api from '@Services/api';
import { GetAllRequestProps } from '@Services/generics/response';
import {
	GetAllInvoicesResponse,
	GetInvoiceResponse,
	UpdateInvoiceResponse,
} from '@Services/invoices/invoices.req.types';
import { AxiosResponse } from 'axios';

export const GetAllInvoices = async ({
	exportFlag,
	filters,
	from,
	page,
	perPage,
	query,
	sortBy,
	to,
}: GetAllRequestProps): Promise<AxiosResponse> => {
	const reqBody: any = {
		page,
		perPage,
		filters,
		sortBy,
		query,
		from,
		to,
		export: exportFlag,
	};
	return await api.post<GetAllInvoicesResponse>('invoice/all', reqBody);
};

export const GetSingleInvoice = async (id: number) =>
	await api.post<GetInvoiceResponse>('invoice', { id });

export const UpdateInvoice = async (id: number, status: string) =>
	await api.put<UpdateInvoiceResponse>('invoice', { id, status });

export const ProcessInvoice = async (id: number) =>
	await api.post('invoice/process', { id });
