import DateTimeFormatter from '@Components/DateTimeFormatter/DateTimeFormatter';
import MainTable from '@Components/MainTable';
import { MainTableRow } from '@Components/MainTable/MainTable';
import { onPageChangeRequestMeta } from '@Components/MainTable/MainTable.types';
import NoResults from '@Components/NoResults/NoResults';
import RoundButton from '@Components/UI/Buttons/RoundButton';
import EdConfirmModal from '@Components/UI/Modals/EdConfirmModal/EdConfirmModal';
import EdActiveStatus from '@Components/UI/Utilities/EdActiveStatus/EdActiveStatus';
import EdEmptyValue from '@Components/UI/Utilities/EdEmptyValue/EdEmptyValue';
import EdIcon from '@Components/UI/Utilities/EdIcon/EdIcon';
import EdSpeedDial, {
	EdSpeedDialAction,
	SpeedDialCell,
} from '@Components/UI/Utilities/EdSpeedDial/EdSpeedDial';
import { useAppThunkDispatch, useTypedSelector } from '@Features/store';
import { Button, TableCell, Tooltip } from '@mui/material';
import DeleteModule from '@Pages/ScratchCards/components/DeleteModeule';
import GenerateCodes from '@Pages/PromoCodes/components/GenerateCode';
import {
	promoCodesDeleteById,
	promoCodesGetAll,
	promoCodesGenerate,
	promoCodesExportData,
} from '@Pages/PromoCodes/Slices/PromoCodes.slice';
import { useSnackbar } from '@Providers/useSnackbar';
import { FlexLayout } from '@Styled/utilities';
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

const TABLE_HEADS: Array<TableHeader> = [
	{ displayName: 'CODE', fieldName: 'code', canSort: true },
	{ displayName: 'NAME', fieldName: 'name', canSort: true },
	{ displayName: 'VALUE', fieldName: 'value' },
	{ displayName: 'TYPE ', fieldName: 'value_type' },
	{ displayName: 'USAGE', fieldName: 'current_uses' },
	{ displayName: 'MAX USES', fieldName: 'max_uses' },
	{
		displayName: 'MAX USES Per User',
		fieldName: 'max_uses_per_user',
		width: '20%',
	},
	{ displayName: 'MIN AMOUNT', fieldName: 'min_amount_to_apply' },
	{ displayName: 'VALUE CAP ', fieldName: 'value_cap' },
	{ displayName: 'CREATED AT', fieldName: 'created_at' },
];
const PromoCodesTable = () => {
	const dispatch = useAppThunkDispatch();
	const onPageRequestChange = useCallback(
		({
			activePage,
			endDate,
			filters,
			query,
			sortBy,
			startDate,
			perPage,
			export: exportData,
		}: onPageChangeRequestMeta) => {
			dispatch(
				promoCodesGetAll({
					page: activePage,
					filters: filters,
					perPage: perPage ?? 10,
					query,
					export: exportData ?? false,
					sortBy: sortBy,
					from: startDate,
					to: endDate,
				})
			);
		},
		[dispatch]
	);

	const { fetchAll, filters, meta } = useTypedSelector(
		(state) => state.PromoCodes
	);
	const auth = useTypedSelector((state) => state.auth);

	const [openDeleteModel, setOpenDeleteModel] = useState(false);
	const [toBeDeleted, setToBeDelete] = useState<number | undefined>();

	const handleDeleteModelClose = () => {
		setOpenDeleteModel(false);
	};

	const handleDeleteClick = (id?: number) => {
		setOpenConfirmModal(true);
		setToBeDelete(id);
	};

	const handleUpdate = (id?: number) => {
		navigate.push(`${navigate.location.pathname}/update/${id}`);
	};

	const [openConfirmModal, setOpenConfirmModal] = useState(false);
	const { displaySnackbar } = useSnackbar();

	const handleCloseDeleteModal = async (close: boolean, confirm?: boolean) => {
		setOpenConfirmModal(close);
		if (confirm && toBeDeleted) {
			try {
				const resp = await dispatch(promoCodesDeleteById(toBeDeleted)).unwrap();
				displaySnackbar('success', resp.message);
			} catch (error) {
				displaySnackbar('error', error?.data?.message);
			}
		}
		setToBeDelete(undefined);
	};

	const navigate = useHistory();
	return (
		<div>
			<DeleteModule
				onConfirmation={handleDeleteModelClose}
				openModel={openDeleteModel}
				handleModelClose={handleDeleteModelClose}
			/>

			<EdConfirmModal
				open={openConfirmModal}
				handleClose={handleCloseDeleteModal}
				text={'Are you sure to delete proomo code ?'}
			/>
			<FlexLayout justifyContent="flex-end">
				{(auth.user.type === 'super' || auth.permissions.has('gen-schc')) && (
					<RoundButton
						edcolor="purple"
						startIcon={<EdIcon>add</EdIcon>}
						variant="contained"
						onClick={() =>
							navigate.push(`${navigate.location.pathname}/create`)
						}
					>
						Generate codes
					</RoundButton>
				)}
			</FlexLayout>
			<MainTable
				tableFilters={filters}
				tableHeads={TABLE_HEADS}
				totalPages={meta?.last_page ?? 0}
				onPageChangeRequest={onPageRequestChange}
				RowsperPage={meta?.per_page}
				$hasActions
				pageNum={meta?.current_page}
				total={meta?.total}
				renderItems={() => {
					if (fetchAll.length === 0) {
						return (
							<MainTableRow>
								<TableCell colSpan={TABLE_HEADS.length}>
									<NoResults />
								</TableCell>
							</MainTableRow>
						);
					} else {
						return fetchAll.map((code) => {
							return (
								<MainTableRow>
									<Tooltip title={code.code} placement="top">
										<TableCell>{code.code}</TableCell>
									</Tooltip>
									{/* <TableCell>{code.name}</TableCell> */}
									<TableCell>{code.name === null ? '-' : code.name}</TableCell>
									{/* <Tooltip title={code.curr} placement="top"> */}
									<TableCell>{code.value}</TableCell>
									<TableCell>{code.value_type}</TableCell>
									<TableCell>{code.current_uses}</TableCell>
									<TableCell>{code.max_uses}</TableCell>
									<TableCell>{code.max_uses_per_user}</TableCell>
									<TableCell>{code.min_amount_to_apply}</TableCell>
									<TableCell>{code.value_cap}</TableCell>
									<TableCell>
										<DateTimeFormatter date={code.created_at} />
									</TableCell>

									<SpeedDialCell>
										{auth.user.type === 'super' && (
											<EdSpeedDial ariaLabel="Actions">
												<EdSpeedDialAction
													onClick={() =>
														navigate.push(`promo-codes/${code.id}`)
													}
													key="usage"
													tooltipTitle="Usage"
													icon={<EdIcon>person</EdIcon>}
													aria-label=""
												/>
												<EdSpeedDialAction
													onClick={() => handleUpdate(code.id)}
													key="update"
													tooltipTitle="Update"
													icon={<EdIcon>edit</EdIcon>}
													aria-label=""
												/>
												<EdSpeedDialAction
													onClick={() => handleDeleteClick(code.id)}
													key="delete"
													tooltipTitle="Delete"
													icon={<EdIcon>delete</EdIcon>}
													aria-label=""
												/>
											</EdSpeedDial>
										)}
									</SpeedDialCell>
								</MainTableRow>
							);
						});
					}
				}}
			/>
		</div>
	);
};

export default PromoCodesTable;
