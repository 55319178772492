import { array, boolean, number, object, string } from 'yup';
import { Flatten } from '@Utils/types/Flatten';

const emailValidation =
	/^(([^<>()[\]\\.,;:\s@"]+(\.[^ <>()[\]\\., ;: \s @"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const phoneValidation = /^01[0-2,5]{1}[0-9]{8}$/;

export const ADD_ADMIN = object({
	uuid: string(),
	active: boolean().default(true),
	first_name: string().required(),
	last_name: string().required(),
	email: string()
		.required()
		.matches(emailValidation, 'E-mail is not valid')
		.required(),
	phone_number: string()
		.required()
		.matches(phoneValidation, 'must be 01xxxxxxxx')
		.required(),
	password: string().when('uuid', {
		is: (id: string) => {
			return id !== undefined;
		},
		then: string(),
		otherwise: string().required(),
	}),
	type: string().required().default('admin'),
	gender: string()
		.nullable(true)
		.transform((v, o) => {
			return v === '' ? null : v;
		}),
	profile_photo: string().required(),
	roles: array(number().required()),
});

export const FULL_SCHEMA = ADD_ADMIN;
export type AdminForm = typeof ADD_ADMIN.__outputType;

export type FlattenAdminForm = Flatten<AdminForm>;
