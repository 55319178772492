import PrivateRoute from '@Components/ConditionalRoutes/PrivateRoute';
import PublicOnlyRoute from '@Components/ConditionalRoutes/PublicOnlyRoute';
import LoginAsStudent from '@Components/UI/Iframes/LoginAsStudent/LoginAsStudent';
import { useTypedSelector } from '@Features/store';
import Login from '@Pages/Login';
import { RouteProvider } from '@Providers/Routes/RoutesProvider';
import React from 'react';
import {
	BrowserRouter,
	Redirect,
	Route,
	Router,
	RouterProps,
	Switch,
	useHistory,
	useLocation,
	useRouteMatch,
} from 'react-router-dom';
import { urlToHttpOptions } from 'url';
import DashboardLayout from './DashboardLayout';

const RoutesLayout = () => {
	const { isAuth } = useTypedSelector((state) => state.auth);
	const { pathname } = useLocation();
	return (
		<BrowserRouter>
			<Switch>
				<Route exact path={'/'}>
					{isAuth && <Redirect to={pathname} />}
					{!isAuth && <Redirect to="/login" />}
				</Route>
				<PublicOnlyRoute path={'/login'} render={() => <Login />} />
				<Route path={'/student/:id'} component={LoginAsStudent} />
				<PrivateRoute path={'/dashboard'}>
					<DashboardLayout />
				</PrivateRoute>
			</Switch>
		</BrowserRouter>
	);
};

export default RoutesLayout;
