import { BaseSliceController } from '@Base/slices/slices.base';
import { TagsRequester } from '../../Services/Tags/TagsRequester';
import { Tag } from '../../Types/Tags';

export class TagsSlice extends BaseSliceController<Tag, 'Tags'> {
	public static instance: TagsSlice = new TagsSlice();
	constructor() {
		super('Tags', TagsRequester.instance);
	}
}

export const {
	tagsGetAll,
	tagsGetSingle,
	tagsCreate,
	tagsUpdate,
	tagsDeleteById,
} = TagsSlice.instance.getActions();
