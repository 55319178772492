import EdCheckbox from '@Components/UI/Inputs/EdCheckbox/EdCheckbox';
import EdFormControl from '@Components/UI/Inputs/EdFormControl/EdFormControl';
import { EdFormControlLabel } from '@Components/UI/Inputs/EdFormControlLabel/EdFormControlLabel';
import { useTypedSelector } from '@Features/store';
import { Switch } from '@mui/material';
import { FlexLayout, GridLayout } from '@Styled/utilities';
import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { TestPayload } from '../../../../../Types/Test/Test.types';
import { StyledInfoLabel } from '../TestOptions.styled';

const TestOptionsGeneralSection = () => {
	const { control, watch, setValue } = useFormContext<TestPayload>();

	const showPeriod = watch('showTestPeriod');

	const {
		currentEntity: { active },
	} = useTypedSelector((state) => state.Tests);
	return (
		<>
			<StyledInfoLabel m={0} mb={'2.5rem'}>
				Activation options
			</StyledInfoLabel>
			<GridLayout px={'1rem'} mb="2rem" gridColumnGap={'3rem'}>
				<FlexLayout justifyContent={'space-between'}>
					<EdFormControlLabel>Mannual test activation</EdFormControlLabel>
					<Controller
						control={control}
						name="test.active"
						render={({ field: { value } }) => {
							return <Switch checked={value} disabled />;
						}}
					/>
				</FlexLayout>
				<FlexLayout>
					<Controller
						control={control}
						name={'showTestPeriod'}
						render={({ field: { onChange, value, ...fieldProps } }) => (
							<EdCheckbox
								{...fieldProps}
								checked={value}
								disabled={active}
								onChange={(e, checked) => {
									onChange(checked);
									if (!checked) {
										setValue('test.active_end_date', null);
										setValue('test.active_start_date', null);
									}
								}}
							/>
						)}
					/>

					{showPeriod && (
						<FlexLayout
							justifyContent={'space-around'}
							flex={'2'}
							justifyItems={'end'}
						>
							<EdFormControl
								type="datetime-local"
								control={control}
								name="test.active_start_date"
								label={'start'}
								showLabel={false}
								disabled={active}
								width={'8.75rem'}
							/>
							<EdFormControl
								showLabel={false}
								width={'8.75rem'}
								disabled={active}
								type="datetime-local"
								control={control}
								name="test.active_end_date"
								label={'end'}
							/>
						</FlexLayout>
					)}
				</FlexLayout>
			</GridLayout>
		</>
	);
};

export default TestOptionsGeneralSection;
