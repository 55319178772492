import { TextField, TextFieldProps } from '@mui/material';
import React, { FC } from 'react';

type Props = {} & TextFieldProps;
const EdNumericInput: FC<Props> = ({ ...props }) => {
	const onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
		const { key } = e;
		if (isNaN(Number(key)) || key === ' ' || key === '-') {
			e.preventDefault();
		}
	};

	return <TextField {...props} onKeyPress={onKeyPress} />;
};

export default EdNumericInput;
