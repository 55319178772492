import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import EdButton from '@Components/UI/Buttons/EdButton/EdButton';

type Props = {} & React.ComponentProps<typeof EdButton>;

const AddSlotButton: React.FC<Props> = ({ ...props }) => {
	const histroy = useHistory();
	const { url } = useRouteMatch();
	return (
		<EdButton
			edcolor="dodgerBlue"
			onClick={() => {
				histroy.push(`${url}/add/slot`);
			}}
			variant="contained"
			{...props}
			style={{
				height: '2.625rem',
				fontSize: '0.938rem',
				fontWeight: '500',
			}}
		>
			ADD NEW SLOT
		</EdButton>
	);
};

export default AddSlotButton;
