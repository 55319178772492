import { BaseSliceController } from '@Base/slices/slices.base';
import { UsersRequester } from '../Services/Userprogress/UserProgressRequester';
import { EnrolledUser } from '../Types/UserProgress.types';
export class UserUnitsSlice extends BaseSliceController<
	EnrolledUser,
	'EnrolledUsersUnits',
	EnrolledUser
> {
	private static instance: UserUnitsSlice;
	static getInstance() {
		if (!UserUnitsSlice.instance) {
			UserUnitsSlice.instance = new UserUnitsSlice();
		}
		return UserUnitsSlice.instance;
	}
	constructor() {
		super('EnrolledUsersUnits', UsersRequester.getInstance());
	}
}

export const { enrolledUsersUnitsGetSingle } =
	UserUnitsSlice.getInstance().getActions();
