import DateTimeFormatter from '@Components/DateTimeFormatter/DateTimeFormatter';
import MainTable, { MainTableRow } from '@Components/MainTable/MainTable';
import { onPageChangeRequestMeta } from '@Components/MainTable/MainTable.types';
import NoResults from '@Components/NoResults/NoResults';
import { useTypedSelector } from '@Features/store';
import { TableCell } from '@mui/material';
import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { rechargeCodeTabGetAll } from './Slices/RechargeCode.Slices';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { useParams } from 'react-router-dom';
import { studentsGetSingle } from '@Pages/Students/Slice/Students.Slice';

const TABLE_HEADS = [
	{ displayName: 'code', fieldName: 'code' },
	{ displayName: 'batch', fieldName: 'batch' },
	{ displayName: 'value', fieldName: 'value' },
	{ displayName: 'used', fieldName: 'user_id' },
	{ displayName: 'used at', fieldName: 'updated_at' },
];

const RechargeCodes = () => {
	const dispatch = useDispatch();
	const { id } = useParams<{ id: string }>();
	const { currentEntity } = useTypedSelector((state) => state.Students);
	const { fetchAll, meta, filters } = useTypedSelector(
		(state) => state.RechargeCodeTab
	);

	const onPageChangeRequest = useCallback(
		({
			activePage,
			endDate,
			filters,
			query,
			sortBy,
			startDate,
			perPage,
		}: onPageChangeRequestMeta) => {
			dispatch(
				rechargeCodeTabGetAll({
					page: activePage,
					perPage: perPage ?? 10,
					sortBy,
					filters,
					query,
					from: startDate,
					to: endDate,
					id: currentEntity?.id as any,
				})
			);
		},
		[dispatch, currentEntity?.id]
	);

	useEffect(() => {
		dispatch(studentsGetSingle({ id }));
	}, []);

	return (
		<MainTable
			total={meta?.total ?? 0}
			dateFilter
			pageNum={meta?.current_page}
			tableFilters={filters}
			tableHeads={TABLE_HEADS}
			totalPages={meta?.last_page ?? 0}
			RowsperPage={Number(meta?.per_page)}
			$hasActions
			onPageChangeRequest={onPageChangeRequest}
			renderItems={() => {
				if (fetchAll?.length === 0)
					return (
						<MainTableRow>
							<TableCell colSpan={TABLE_HEADS.length}>
								<NoResults />
							</TableCell>
						</MainTableRow>
					);
				return fetchAll?.map((code) => {
					return (
						<MainTableRow key={code.id} hover>
							<TableCell>{code.code}</TableCell>
							<TableCell>{code.batch}</TableCell>
							<TableCell>{code.value}</TableCell>
							<TableCell>
								{code.user_id ? <CheckIconStyle /> : <CloseIcon />}
							</TableCell>
							<TableCell>
								<DateTimeFormatter date={code.updated_at} />
							</TableCell>
							<TableCell />
						</MainTableRow>
					);
				});
			}}
		/>
	);
};

const CheckIconStyle = styled(CheckIcon)`
	background-color: #e0e0e0;
	color: #000;
	height: 15px;
	width: 4.625rem;
	margin: 0 auto;
	display: flex;
	border-radius: 30px;
	padding: 3px 5px;
`;

export default RechargeCodes;
