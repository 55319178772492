import api from '@Services/api';
import { AxiosResponse } from 'axios';
import {
	CRUDBase,
	DeleteSingleEntityPayload,
	GetAllMeta,
	GetSingleEntityPayload,
} from './../../../../../base/services/services.base';
import {
	ClassroomPayload,
	ClassroomResponse,
} from '@Pages/ClassRooms/Types/Classroom.types';

export class ClassroomRequester
	implements CRUDBase<ClassroomPayload, ClassroomResponse>
{
	baseURL?: string | undefined;

	private static instance: ClassroomRequester = new ClassroomRequester();
	static getInstance(): ClassroomRequester {
		if (ClassroomRequester.instance) {
			ClassroomRequester.instance = new ClassroomRequester();
		}
		return ClassroomRequester.instance;
	}

	getAll(
		payload?: GetAllMeta
	): Promise<AxiosResponse<ApiResponse<ClassroomResponse[]>>>;
	getAll(
		payload: number
	): Promise<AxiosResponse<ApiResponse<ClassroomResponse[]>>>;
	async getAll(
		payload?: any
	): Promise<AxiosResponse<ApiResponse<ClassroomResponse[]>>> {
		return await api.post('classroom/all', payload);
	}
	get(id: string): Promise<AxiosResponse<ApiResponse<ClassroomResponse>>>;
	get(id: number): Promise<AxiosResponse<ApiResponse<ClassroomResponse>>>;
	get(
		id: any,
		payload: GetSingleEntityPayload
	): Promise<AxiosResponse<ApiResponse<ClassroomResponse>>>;
	async get(
		id: string | number,
		payload?: any
	): Promise<AxiosResponse<ApiResponse<ClassroomResponse>>> {
		return await api.post('classroom', payload);
	}

	async create(
		payload: ClassroomPayload
	): Promise<AxiosResponse<ApiResponse<ClassroomResponse>>> {
		return await api.post('classroom/create', payload);
	}
	async update(
		payload: ClassroomPayload
	): Promise<AxiosResponse<ApiResponse<ClassroomResponse>>> {
		return await api.put('classroom', payload);
	}

	delete(id: number): Promise<AxiosResponse<ApiResponse<any>>>;
	delete(id: string): Promise<AxiosResponse<ApiResponse<any>>>;
	delete(
		id: string | number,
		payload: DeleteSingleEntityPayload
	): Promise<AxiosResponse<ApiResponse<any>>>;
	async delete(
		id: any,
		payload?: any
	): Promise<AxiosResponse<ApiResponse<any>>> {
		return await api.delete('classroom/delete', {
			data: payload,
		});
	}
}
