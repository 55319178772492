import { array, number, object, string } from 'yup';
import { TestQuestionBaseSchema } from './TestQuestion';
export const ORDERING_SCHEMA = TestQuestionBaseSchema.concat(
	object({
		type: string().default('ordering'),
		orderingOptions: array(
			object({
				option: string().required('option is required'),
				order: number().required(),
			}).required()
		)
			.required()
			.min(2),
	})
);
