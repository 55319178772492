import { BaseSliceController } from '@Base/slices/slices.base';
import { WebContentRequester } from '../Services/WebContent.requester';
import { Content } from '../Types/WebContent.types';

export class webContentSlice extends BaseSliceController<
	Content,
	'WebContents',
	Content
> {
	static instance = new webContentSlice();

	constructor() {
		super('WebContents', WebContentRequester.instance);
	}
}

export const { webContentsGetSingle, webContentsDeleteById } =
	webContentSlice.instance.getActions();
