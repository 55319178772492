import DateTimeFormatter from '@Components/DateTimeFormatter/DateTimeFormatter';
import MainTable, { MainTableRow } from '@Components/MainTable/MainTable';
import { onPageChangeRequestMeta } from '@Components/MainTable/MainTable.types';
import NoResults from '@Components/NoResults/NoResults';
import EdBox from '@Components/UI/Utilities/EdBox/EdBox';
import { useAppThunkDispatch, useTypedSelector } from '@Features/store';
import { promoCodesGetSingle } from '@Pages/PromoCodes/Slices/PromoCodes.slice';
import { promoCodesUsageGetAll } from '@Pages/PromoCodes/Slices/PromoCodesUsage.slice';
import { FlexLayout, Typography } from '@eduact/ed-system';
import { Table, TableCell, Tooltip } from '@mui/material';
import React, { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';

const TABLE_HEADS: Array<TableHeader> = [
	{ displayName: 'NAME', fieldName: 'name', canSort: true },
	{ displayName: 'USERNAME', fieldName: 'current_uses' },
	{ displayName: 'COURSE', fieldName: 'max_uses' },
	{ displayName: 'USED AT', fieldName: 'max_uses' },
];
export const UsageTable = () => {
	const { id } = useParams<{ id: string }>();
	const dispatch = useAppThunkDispatch();

	const {
		currentEntity,
		loaders: { getSingle },
	} = useTypedSelector((s) => s.PromoCodes);
	useEffect(() => {
		dispatch(promoCodesGetSingle({ id: Number(id) }));
	}, []);
	const onPageRequestChange = useCallback(
		({
			activePage,
			endDate,
			filters,
			query,
			sortBy,
			startDate,
			perPage,
			export: exportData,
		}: onPageChangeRequestMeta) => {
			if (id)
				dispatch(
					promoCodesUsageGetAll({
						page: activePage,
						filters: filters,
						perPage: perPage ?? 10,
						query,
						export: exportData ?? false,
						sortBy: sortBy,
						from: startDate,
						to: endDate,
						id: Number(id),
					})
				);
		},
		[dispatch]
	);

	const { fetchAll, filters, meta } = useTypedSelector(
		(state) => state.PromoCodesUsage
	);

	console.log(currentEntity);
	return (
		<div>
			{getSingle === 'fulfilled' && currentEntity && (
				<EdBox width={'fit-content'}>
					<FlexLayout my="1rem" flexDirection={'column'} gridGap={'1rem'}>
						<Typography fontWeight={'bold'}>
							Code : {currentEntity.code}
						</Typography>
						<Typography fontWeight={'bold'}>
							Value : {currentEntity.value}
						</Typography>
						<Typography fontWeight={'bold'}>
							Type : {currentEntity.value_type}
						</Typography>
					</FlexLayout>
				</EdBox>
			)}
			<MainTable
				tableFilters={filters}
				tableHeads={TABLE_HEADS}
				totalPages={meta?.last_page ?? 0}
				onPageChangeRequest={onPageRequestChange}
				RowsperPage={meta?.per_page}
				$hasActions
				pageNum={meta?.current_page}
				total={meta?.total}
				renderItems={() => {
					if (fetchAll.length === 0) {
						return (
							<MainTableRow>
								<TableCell colSpan={TABLE_HEADS.length}>
									<NoResults />
								</TableCell>
							</MainTableRow>
						);
					} else {
						return fetchAll.map((code) => {
							return (
								<MainTableRow>
									<TableCell>
										{code.user.first_name} {code.user.last_name}
									</TableCell>
									<TableCell>{code.user.username}</TableCell>
									<TableCell>{code.course.name}</TableCell>
									<TableCell>
										<DateTimeFormatter date={code.created_at} />
									</TableCell>
									<TableCell></TableCell>
								</MainTableRow>
							);
						});
					}
				}}
			/>
		</div>
	);
};
