import { useTypedSelector } from '@Features/store';
import useFilters from '@Hooks/useFilters';
import useQueryURL from '@Hooks/useQueryUrl';
import useSortByURL from '@Hooks/useSortBy';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import '@mui/lab';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Button, Modal, TextField } from '@mui/material';
import { updateEnrollment } from '@Pages/ClassRooms/Pages/SingleCourse/Pages/Users/Services/Userprogress/UserProgressRequester';
import { useSnackbar } from '@Providers/useSnackbar';
import { FlexLayout } from '@Styled/utilities';
import moment from 'moment';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { coursesTabGetAll } from '../../../Slices/CourseTab.Slices';

type Props = {
	handleModelClose: React.Dispatch<boolean>;
	openModel: boolean;
	currentCourse: number;
	setOpenModel: React.Dispatch<boolean>;
};

const ExpiredModel: React.FC<Props> = ({
	openModel,
	currentCourse,
	setOpenModel,
	handleModelClose,
}) => {
	const dispatch = useDispatch();
	const { displaySnackbar } = useSnackbar();
	const { selectedFilters } = useFilters();
	const { currentEntity } = useTypedSelector((state) => state.Students);
	const { selectedQuery } = useQueryURL();
	const [value, setValue] = useState<Date | null>(null);

	const updateUserEnrollment = async () => {
		try {
			await updateEnrollment(currentCourse, [currentEntity?.id], value as Date);
			displaySnackbar('success', `Course Expired successfully`);
			dispatch(
				coursesTabGetAll({
					page: 1,
					perPage: 10,
					sortBy: '',
					filters: selectedFilters,
					query: selectedQuery,
					id: currentEntity?.id as any,
				})
			);
			handleModelClose(false);
		} catch (e) {
			const msg = e.response?.data.message || 'Unable to Expire Course ';
			displaySnackbar('error', msg);
		}
	};

	return (
		<Modal
			open={openModel}
			onClose={() => {
				handleModelClose(false);
			}}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<ModalContainer>
				<FlexLayout flexDirection="column" gridGap="48px">
					<Header>Please Choose a new expiry date?</Header>
					<LocalizationProvider dateAdapter={AdapterDateFns}>
						<DatePicker
							value={value}
							onChange={(newValue: any) => {
								setValue(newValue);
							}}
							renderInput={(params: any) => <TextField {...params} />}
						/>
					</LocalizationProvider>
					<FlexLayout
						justifyContent="end"
						alignItems="center"
						gridGap="1.7rem"
						marginTop="40px"
					>
						<CancelButton
							variant="text"
							color="warning"
							onClick={() => handleModelClose(false)}
						>
							Cancel
						</CancelButton>
						<SyncButton variant="text" onClick={() => updateUserEnrollment()}>
							Confirm
						</SyncButton>
					</FlexLayout>
				</FlexLayout>
			</ModalContainer>
		</Modal>
	);
};

export const ModalContainer = styled.div`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 22.563rem;
	border-radius: 4px;
	background-color: #ffffff;
	padding: 30px 30px 10px;
`;

const Header = styled.p`
	font-size: 12px;
	font-weight: normal;
	color: #251b33;
`;

const SyncButton = styled(Button)`
	color: #6200ee;
	font-weight: 500;
	font-size: 1rem;
`;
const CancelButton = styled(Button)`
	font-size: 1rem;
`;

export default ExpiredModel;
