import api from '@Services/api';
import {
	AdminsResponse,
	DeleteResponse,
	CreateResponse,
	UpdateResponse,
	UpdateAdminPermissionsResponse,
	SingleAdminResponse,
} from '@Services/admins/admins.res.types';

interface getReqType {
	page: number;
	perPage: number;
	sortBy?: any;
	filters: Array<any>;
	query?: string;
	from?: Date;
	to?: Date;
}

export const adminsGetRequest = async (
	page: number,
	perPage: number,
	filters: any,
	sortBy: any,
	query?: string,
	from?: Date,
	to?: Date
) => {
	const data: getReqType = { page, perPage, filters, query, from, to };
	if (Object.keys(sortBy).length !== 0) data.sortBy = sortBy;

	return await api.post<AdminsResponse>('all', data);
};
export const deleteAdminRequest = async (uuid: string) =>
	await api.delete<DeleteResponse>('delete', {
		data: { uuid },
	});

export const createAdminRequest = async (
	first_name: string,
	last_name: string,
	type: string,
	email: string,
	phone_number: string,
	password: string,
	permissions: Array<number>
) =>
	await api.post<CreateResponse>('create', {
		first_name,
		last_name,
		type,
		email,
		phone_number,
		password,
		permissions,
	});

export const updateAdminRequest = async (
	uuid: string,
	first_name: string,
	last_name: string,
	email: string,
	phone_number: string,

	gender: string,
	password?: string
) =>
	await api.put<UpdateResponse>('update', {
		uuid,
		first_name,
		last_name,
		email,
		phone_number,
		password,
		gender,
	});

export const updateAdminPermissionsRequest = async (
	uuid: string | undefined,
	permissions: number[]
) =>
	await api.post<UpdateAdminPermissionsResponse>('permissions', {
		uuid,
		permissions,
	});

export const adminGetRequest = async (uuid: string | undefined) =>
	await api.post<SingleAdminResponse>('', {
		uuid,
	});
