import { BaseSliceController } from '@Base/slices/slices.base';
import { TimelineLog } from '@Services/students/students.res.types';
import { TimelineTabRequester } from '../Services/Timeline.Requester';

export class TimelineTabSlice extends BaseSliceController<
	TimelineLog,
	'TimelineTab',
	TimelineLog
> {
	private static instance: TimelineTabSlice;
	static getInstance() {
		if (!TimelineTabSlice.instance) {
			TimelineTabSlice.instance = new TimelineTabSlice();
		}
		return TimelineTabSlice.instance;
	}
	constructor() {
		super('TimelineTab', TimelineTabRequester.getInstance());
	}
}

export const { timelineTabGetAll } =
	TimelineTabSlice.getInstance().getActions();
