import api from '@Services/api';
import { AxiosResponse } from 'axios';
import {
	CRUDBase,
	DeleteSingleEntityPayload,
	GetAllMeta,
	GetSingleEntityPayload,
} from 'src/base/services/services.base';
import { CourseTestsSummary } from '../Types/TestReports.types';

interface TestReportsCRUD
	extends CRUDBase<CourseTestsSummary, CourseTestsSummary> {}
export class TestReportsRequester implements TestReportsCRUD {
	private static instance: TestReportsRequester;
	static getInstance() {
		if (!TestReportsRequester.instance) {
			TestReportsRequester.instance = new TestReportsRequester();
		}
		return TestReportsRequester.instance;
	}
	baseURL = 'reports/course';
	getAll(
		payload?: GetAllMeta
	): Promise<AxiosResponse<ApiResponse<CourseTestsSummary[]>>>;
	getAll(
		payload: number
	): Promise<AxiosResponse<ApiResponse<CourseTestsSummary[]>>>;
	async getAll(
		payload?: any
	): Promise<import('axios').AxiosResponse<ApiResponse<CourseTestsSummary[]>>> {
		return await api.post(`${this.baseURL}/tests/summary`, payload);
	}
	get(id: string): Promise<AxiosResponse<ApiResponse<CourseTestsSummary>>>;
	get(id: number): Promise<AxiosResponse<ApiResponse<CourseTestsSummary>>>;
	get(
		id: string | number,
		payload: GetSingleEntityPayload
	): Promise<AxiosResponse<ApiResponse<CourseTestsSummary>>>;
	async get(
		id: any,
		payload?: any
	): Promise<AxiosResponse<ApiResponse<CourseTestsSummary>>> {
		return await api.post(`${this.baseURL}/tests/single`, { id });
	}

	async create(
		payload: CourseTestsSummary
	): Promise<AxiosResponse<ApiResponse<CourseTestsSummary>>> {
		return await api.post(`${this.baseURL}/create`, payload);
	}
	async update(
		payload: CourseTestsSummary
	): Promise<AxiosResponse<ApiResponse<CourseTestsSummary>>> {
		return await api.put(`${this.baseURL}/update`, payload);
	}

	delete(id: number): Promise<AxiosResponse<ApiResponse<any>>>;
	delete(id: string): Promise<AxiosResponse<ApiResponse<any>>>;
	delete(
		id: string | number,
		payload: DeleteSingleEntityPayload
	): Promise<AxiosResponse<ApiResponse<CourseTestsSummary>>>;
	async delete(
		id: any,
		payload?: any
	): Promise<AxiosResponse<ApiResponse<any>>> {
		return await api.delete(`${this.baseURL}/delete`, {
			data: {
				uuid: id,
			},
		});
	}
}
