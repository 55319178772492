import React, { useEffect, useCallback, useState } from 'react';
import MainTable from '@Components/MainTable';
import { useTypedSelector } from '@Features/store';
import { FetchAllCategories } from './Slices/CategoriesSlice';
import { DeleteCategory } from './Services/Categories.req';
import { useHistory } from 'react-router-dom';
import NoResults from '@Components/NoResults/NoResults';
import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';
import AddIcon from '@mui/icons-material/Add';
import { TableHeader } from '@Features/generics/generics.type';
import { useSnackbar } from '@Providers/useSnackbar';
import SidebarContentWrapper from '@Components/SidebarContentWrapper';
import useFilters from '@Hooks/useFilters';
import useSortByURL from '@Hooks/useSortBy';
import useQueryURL from '@Hooks/useQueryUrl';
import { TableCellStyled } from '@Pages/ClassRooms/Pages/SingleCourse/Pages/Users/EnrollmentStudent';
import {
	StyleAddBtn,
	StyleDeleteIcon,
} from '@Pages/ClassRooms/ClassRoomsTable';
import { usePageLoader } from '@Providers/LoadingProvider';
import { useDispatch } from 'react-redux';
import DeleteModel from '@Pages/NewsFeeds/components/DeleteModel/DeleteModel';
import styled from 'styled-components';
import { MainTableRow } from '@Components/MainTable/MainTable';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AddCategoryButton from './Components/AddCategoryButton';
import { onPageChangeRequestMeta } from '@Components/MainTable/MainTable.types';

const TABLE_HEADS: Array<TableHeader> = [
	{ displayName: 'NAME', fieldName: 'name' },
	{ displayName: 'CODE', fieldName: 'code' },
	{ displayName: 'ICON ', fieldName: 'icon', canSort: false },
];

const CategoriesTable = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const { setIsLoading } = usePageLoader();
	const {
		categories,
		loaders,
		page,
		perPage,
		lastPage,
		total,
		categoriesFilters,
	} = useTypedSelector((state) => state.category);
	const auth = useTypedSelector((state) => state.auth);

	const [deletedId, setDeletedId] = useState<any>(null);
	const { displaySnackbar } = useSnackbar();
	const [openModel, setOpenModel] = useState(false);
	const { selectedFilters } = useFilters();
	const { selectedSortBy } = useSortByURL();
	const { selectedQuery } = useQueryURL();

	const handleModelOpen = (id: number) => {
		setOpenModel(true);
		setDeletedId(id);
	};

	const handleModelClose = () => {
		setOpenModel(false);
		setDeletedId(null);
	};

	const onDeleteCategory = useCallback(() => {
		setIsLoading(true);
		DeleteCategory(deletedId)
			.then(() => {
				displaySnackbar('success', 'Delete Category successfully');
				dispatch(
					FetchAllCategories({
						page,
						perPage,
						sortBy: '',
						filters: selectedFilters,
						query: selectedQuery,
					})
				);
			})
			.catch((e: any) => {
				const msg = e.response?.data.message || 'Unable to Delete Category';
				displaySnackbar('error', msg);
			});
		setOpenModel(false);
		setIsLoading(false);
	}, [deletedId, dispatch, displaySnackbar, setIsLoading]);

	useEffect(() => {
		setIsLoading(!!loaders.fetchAll);
	}, [loaders.fetchAll]);

	const handlePagination = useCallback(
		({
			activePage,
			filters,
			sortBy,
			query,
			startDate,
			endDate,
			perPage,
		}: onPageChangeRequestMeta) => {
			dispatch(
				FetchAllCategories({
					page: activePage,
					perPage: perPage ?? 10,
					sortBy,
					filters,
					query,
					from: startDate,
					to: endDate,
				})
			);
		},
		[dispatch]
	);

	return (
		<SidebarContentWrapper>
			{(auth.user.type === 'super' || auth.permissions.has('c-ctg')) && (
				<div className="role_head__container">
					<AddCategoryButton />
				</div>
			)}

			<DeleteModel
				openModel={openModel}
				handleModelClose={handleModelClose}
				onConfirmation={onDeleteCategory}
				word="Category"
			/>

			<MainTable
				onPageChangeRequest={handlePagination}
				totalPages={lastPage}
				tableFilters={categoriesFilters}
				total={Number(total)}
				RowsperPage={Number(perPage)}
				pageNum={Number(page)}
				tableHeads={TABLE_HEADS}
				$hasActions={true}
				renderItems={() => {
					if (categories.length <= 0) {
						return (
							<MainTableRow>
								<TableCell colSpan={TABLE_HEADS.length}>
									<NoResults />
								</TableCell>
							</MainTableRow>
						);
					} else {
						return categories.map((category) => (
							<MainTableRow
								key={category.id}
								hover
								onClick={() => {
									history.push({
										pathname: `/dashboard/category/edit/category/${category.id}`,
									});
								}}
								sx={{
									cursor: 'pointer',
									'&:active': { backgroundColor: '#6c63ff' },
								}}
							>
								<TableCellStyled>{category.name || '-'}</TableCellStyled>
								<TableCellStyled>{category.code || '-'}</TableCellStyled>
								<TableCellStyled>
									{<ImgStyle src={category.icon} alt={category.name} /> || '-'}
								</TableCellStyled>

								<TableCell
									sx={{
										padding: '0 1.063rem 0',
										position: 'sticky',
										right: '0',
										background:
											'linear-gradient(260deg, white, white, transparent)',
									}}
								>
									{(auth.user.type === 'super' ||
										auth.permissions.has('d-ctg')) && (
										<SpeedDial
											FabProps={{
												disableRipple: true,
												disableTouchRipple: true,
												focusRipple: false,
												disableFocusRipple: true,
												style: {
													borderRadius: '0',
													boxShadow: '0 0 0 0 ',
													backgroundColor: 'transparent',
													color: '#000',
												},
											}}
											direction="left"
											ariaLabel="SpeedDial basic example"
											icon={<MoreVertIcon />}
										>
											<SpeedDialAction
												sx={{
													'&:hover': {
														backgroundColor: 'rgba(90,192,252, 0.25)',
													},
												}}
												key="delete"
												icon={<StyleDeleteIcon />}
												tooltipTitle="Delete"
												id="deleteCategory"
												onClick={(e) => {
													e.stopPropagation();
													handleModelOpen(category.id);
												}}
											/>
										</SpeedDial>
									)}
								</TableCell>
							</MainTableRow>
						));
					}
				}}
			/>
		</SidebarContentWrapper>
	);
};

export default CategoriesTable;

export const ImgStyle = styled.img`
	padding: 10px;
	height: 30px;
`;
