import { useTypedSelector } from '@Features/store';
import React, { useEffect, useState } from 'react';
import { Alert, Input } from 'reactstrap';
import { useSnackbar } from '@Providers/useSnackbar';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import { FlexLayout } from '@Styled/utilities';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CreateIcon from '@mui/icons-material/Create';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { useParams } from 'react-router-dom';
import { studentsGetSingle } from '@Pages/Students/Slice/Students.Slice';
import WalletTable from '../WalletTable';
import SetModel from '../Components/SetModel';
import AddModel from '../Components/AddModel';
import DeductModel from '../Components/DeductModel';
import { Tooltip } from '@mui/material';

const WalletEditor = () => {
	const { id } = useParams<{ id: string }>();
	const defaultAmount = useTypedSelector(
		(state) => state.student.currentStudent?.student.wallet.amount || 0
	);

	const {
		currentEntity,
		loaders: { getSingle },
	} = useTypedSelector((state) => state.Students);
	const auth = useTypedSelector((state) => state.auth);

	useEffect(() => {
		dispatch(studentsGetSingle({ id }));
	}, []);

	const { displaySnackbar } = useSnackbar();

	const dispatch = useDispatch();

	const [walletAmount, setWalletAmount] = useState<number | string>('');
	const [, setDisplayedAmount] = useState(defaultAmount);
	const [openSetModel, setOpenSetModel] = useState(false);
	const [openAddModel, setOpenAddModel] = useState(false);
	const [openDeductModel, setOpenDeductModel] = useState(false);
	const [error, setError] = useState('');
	const [, setLoading] = useState(false);

	useEffect(() => {
		setDisplayedAmount(defaultAmount);
	}, [defaultAmount]);

	const addAmount = () => {
		if (walletAmount === '' || walletAmount === 0) {
			return displaySnackbar('error', 'Amount is required!');
		}
		setOpenAddModel(true);
	};
	const setAmount = () => {
		if (walletAmount === '') {
			return displaySnackbar('error', 'Amount is required!');
		}
		setOpenSetModel(true);
	};
	const deductAmount = () => {
		if (walletAmount === '' || walletAmount === 0) {
			return displaySnackbar('error', 'Amount is required!');
		}
		setOpenDeductModel(true);
	};

	return (
		<div>
			{currentEntity && getSingle === 'fulfilled' && (
				<>
					<SetModel
						openModel={openSetModel}
						handleModelClose={setOpenSetModel}
						walletAmount={walletAmount}
						id={id}
						defaultAmount={defaultAmount}
						setLoading={setLoading}
						setDisplayedAmount={setDisplayedAmount}
						setWalletAmount={setWalletAmount}
						setError={setError}
					/>
					<AddModel
						openModel={openAddModel}
						handleModelClose={setOpenAddModel}
						walletAmount={walletAmount}
						id={id}
						setLoading={setLoading}
						setDisplayedAmount={setDisplayedAmount}
						setWalletAmount={setWalletAmount}
						setError={setError}
					/>
					<DeductModel
						openModel={openDeductModel}
						handleModelClose={setOpenDeductModel}
						walletAmount={walletAmount}
						id={id}
						defaultAmount={defaultAmount}
						setLoading={setLoading}
						setDisplayedAmount={setDisplayedAmount}
						setWalletAmount={setWalletAmount}
						setError={setError}
					/>
					<Title>Wallet</Title>
					<Container>
						<FlexLayout justifyContent="space-between" alignItems="start">
							<FlexLayout
								gridGap="1.7rem"
								justifyContent="start"
								alignItems="center"
							>
								<WalletStyle />
								<FlexLayout
									flexDirection="column"
									gridGap="1.9rem"
									alignItems="center"
								>
									<Amount>Amount</Amount>
									<Price>{currentEntity.student.wallet.amount}</Price>
									{error && <Alert color="danger">{error}</Alert>}
								</FlexLayout>
							</FlexLayout>

							<FlexLayout flexDirection="column" gridGap="1rem">
								<FlexLayout justifyContent="space-between" alignItems="center">
									{(auth.user.type === 'super' ||
										auth.permissions.has('add-wlt-st')) && (
										<Tooltip title="Add to wallet" placement="top">
											<AddCircleIconStyle
												onClick={() => {
													addAmount();
												}}
											/>
										</Tooltip>
									)}
									{(auth.user.type === 'super' ||
										auth.permissions.has('set-wlt-st')) && (
										<Tooltip title="Set wallet" placement="top">
											<CreateIconStyle
												onClick={() => {
													setAmount();
												}}
											/>
										</Tooltip>
									)}
									{(auth.user.type === 'super' ||
										auth.permissions.has('dct-wlt-st')) && (
										<Tooltip title="Deduct from wallet" placement="top">
											<RemoveCircleIconStyle
												onClick={() => {
													deductAmount();
												}}
											/>
										</Tooltip>
									)}
								</FlexLayout>
								<InputStyle
									type="text"
									placeholder="Amount"
									onChange={(e: any) => {
										const { value } = e.target;
										if (isNaN(Number(value))) return;
										setWalletAmount(Number(value));
									}}
								/>
							</FlexLayout>
						</FlexLayout>
					</Container>
					<FlexLayout gridGap="8px">
						<RefundableCard>
							<Amount>Refundable</Amount>
							<RefundableAmount>
								{currentEntity?.student?.wallet?.refundable}
							</RefundableAmount>
						</RefundableCard>
						<RefundableCard>
							<Amount>Non refundable</Amount>
							<RefundableAmount>
								{currentEntity?.student?.wallet?.nonrefundable}
							</RefundableAmount>
						</RefundableCard>
					</FlexLayout>
					{(auth.user.type === 'super' || auth.permissions.has('v-wlg-st')) && (
						<WalletTable />
					)}
				</>
			)}
		</div>
	);
};

const Title = styled.div`
	color: #251b33;
	font-size: 20px;
	font-weight: bold;
	margin-bottom: 2rem;
	margin-top: 5rem;
`;

const Container = styled.div`
	background-color: RGBA(90, 192, 252, 0.1);
	padding: 20px 46px 10px 24.2px;
	border-radius: 5px;
	width: 38.875rem;
	height: 8.188rem;
	margin-bottom: 8px;
`;

const WalletStyle = styled(AccountBalanceWalletOutlinedIcon)`
	color: #5ac0fc;
	font-size: 4.531rem;
`;
const Amount = styled.p`
	color: #5ac0fc;
	font-size: 1.25rem;
	font-weight: bold;
`;
const Price = styled.p`
	color: #251b33;
	font-size: 1.75rem;
	font-weight: 600;
`;

const AddCircleIconStyle = styled(AddCircleIcon)`
	width: 2rem;
	height: 2rem;
	color: #5ac0fc;
	cursor: pointer;
`;
const CreateIconStyle = styled(CreateIcon)`
	width: 2rem;
	height: 2rem;
	color: #5ac0fc;
	cursor: pointer;
`;

const RemoveCircleIconStyle = styled(RemoveCircleIcon)`
	width: 2rem;
	height: 2rem;
	color: #5ac0fc;
	cursor: pointer;
`;

const InputStyle = styled(Input)`
	border-radius: 4px;
	border: solid 1px rgba(0, 0, 0, 0.12);
	width: 16.25rem;
	height: 2.125rem;
	padding-left: 5px;

	&:focus-visible {
		outline: none;
	}
`;

const RefundableCard = styled.div`
	width: 19.25rem;
	height: 3.125rem;
	background-color: RGBA(90, 192, 252, 0.1);
	border-radius: 5px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 15px 16px 11px;
`;
const RefundableAmount = styled.p`
	color: #251b33;
	font-size: 20px;
	font-weight: 600;
`;

export default WalletEditor;
