import { HashString } from '@Components/UI/Tabs/RoutedTabs/RoutedTabs.types';
import RoutedTabs from '@Components/UI/Tabs/Tabs';
import { FlexLayout, Spacer } from '@Styled/utilities';
import React, { useEffect, useMemo } from 'react';
import {
	useHistory,
	useLocation,
	useParams,
	useRouteMatch,
} from 'react-router-dom';
import styled from 'styled-components';
import { rgba } from 'polished';
import StudentTests from './Components/Tests/StudentTests';
import { useTypedSelector } from '@Features/store';
import { studentTestsCountGetSingle } from './Components/Tests/Slices/TestsCountSlices';
import { useDispatch } from 'react-redux';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import StudentWebinars from './Components/Webinars/StudentWebinars';
import Attendances from './Components/Attendances/Attendances';
import { studentAttendancesCountGetSingle } from './Components/Attendances/Slices/TestsCountSlices';

const StudentClassroomReport = () => {
	const { id } = useParams<{ id: string }>();
	const params = useParams();

	const dispatch = useDispatch();
	const history = useHistory();
	const { hash } = useLocation();
	const { url } = useRouteMatch();

	const { currentEntity } = useTypedSelector(
		(state) => state.StudentTestsCount
	);

	const { currentEntity: AttendanceCountEntity } = useTypedSelector(
		(state) => state.StudentAttendancesCount
	);
	const {
		currentEntity: StudentEntity,
		loaders: { getSingle },
	} = useTypedSelector((state) => state.Students);

	useEffect(() => {
		if (StudentEntity && getSingle === 'fulfilled') {
			dispatch(
				studentTestsCountGetSingle({
					id,
					tId: StudentEntity?.id,
				})
			);
		}
	}, [StudentEntity]);

	useEffect(() => {
		if (StudentEntity && getSingle === 'fulfilled') {
			dispatch(
				studentAttendancesCountGetSingle({
					id,
					tId: StudentEntity?.id,
				})
			);
		}
	}, [StudentEntity]);

	const tabIndex = useMemo<HashString>(() => {
		if (!hash) return 'attendance';
		return hash as HashString;
	}, [hash]);

	return (
		<>
			<FlexLayout
				justifyContent="flex-start"
				gridGap="0.8rem"
				alignItems="center"
			>
				<BackButton
					alignItems="center"
					justifyContent="center"
					onClick={() => history.goBack()}
				>
					<IconStyle />
				</BackButton>
				<Header>{currentEntity?.classroom_title}</Header>
			</FlexLayout>
			<Container>
				<RoutedTabs baseURL={url} withRouter>
					<RoutedTabs.Tabs
						$hashPrefix="#"
						hashes={['attendance', 'test', 'webinar']}
						onChange={() => {}}
						orientation="vertical"
						value={tabIndex}
					>
						<RoutedTabs.Item
							value="attendance"
							label={<RoutedTabs.ItemTitle>Attendance</RoutedTabs.ItemTitle>}
							icon={
								<RoutedTabs.ItemIcon mIconType="Outlined">
									contact_page
								</RoutedTabs.ItemIcon>
							}
						/>
						<RoutedTabs.Item
							value="test"
							label={<RoutedTabs.ItemTitle>TESTS</RoutedTabs.ItemTitle>}
							icon={
								<RoutedTabs.ItemIcon mIconType="Outlined">
									live_help
								</RoutedTabs.ItemIcon>
							}
						/>
						<RoutedTabs.Item
							value="webinar"
							label={<RoutedTabs.ItemTitle>WEBINARS</RoutedTabs.ItemTitle>}
							icon={
								<RoutedTabs.ItemIcon mIconType="Outlined">
									podcasts
								</RoutedTabs.ItemIcon>
							}
						/>
					</RoutedTabs.Tabs>
					<Spacer mx={'0.625rem'} />
					<RoutedTabs.Content value={tabIndex}>
						<RoutedTabs.ContentItem hash="attendance">
							<Attendances count={AttendanceCountEntity} id={Number(id)} />
						</RoutedTabs.ContentItem>
						<RoutedTabs.ContentItem hash="test">
							<StudentTests count={currentEntity} id={Number(id)} />
						</RoutedTabs.ContentItem>
						<RoutedTabs.ContentItem hash="webinar">
							{StudentEntity && getSingle === 'fulfilled' && (
								<StudentWebinars classroom_id={Number(id)} />
							)}
						</RoutedTabs.ContentItem>
					</RoutedTabs.Content>
				</RoutedTabs>
			</Container>
		</>
	);
};

const Container = styled.div`
	padding: 1.75rem 1.625rem;
	border-radius: 10px;
	border: 1px solid ${rgba('#5ac0fc', 0.25)};
`;

const Header = styled.p`
	font-size: 1.25rem;
	font-weight: bold;
	color: #251b33;
	margin: 1.563rem 0;
`;
export const BackButton = styled(FlexLayout)`
	width: 2rem;
	height: 2rem;
	border-radius: 50%;
	cursor: pointer;
`;

export const IconStyle = styled(ChevronLeftIcon)`
	font-size: 2rem;
	color: ${(props) => props.theme.palette.common.black};
	fill: ${(props) => props.theme.palette.common.black};
	z-index: 999;
`;

export default StudentClassroomReport;
