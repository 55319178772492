import { EdFormControlLabel } from '@Components/UI/Inputs/EdFormControlLabel/EdFormControlLabel';
import EdNumericInput from '@Components/UI/Inputs/EdNumericInput/EdNumericInput';
import SelectController from '@Components/UI/Inputs/SelectController/SelectController';
import { useTypedSelector } from '@Features/store';
import { MenuItem, Switch } from '@mui/material';
import { FlexLayout, GridLayout, Spacer } from '@Styled/utilities';
import React, { useEffect, useMemo, useState } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import { TestPayload } from '../../../../../Types/Test/Test.types';
import GradeOptionsForm from './GradesArrayFields/GradeOptionsForm';
import GradeOptionsStartFrom from './GradesArrayFields/GradeOptionsStartFrom';

const TestOptionsGradingSection = () => {
	const { control, watch, setValue } = useFormContext<TestPayload>();

	const defineRange = watch('defineGradeSchema');
	const { fields, append, remove } = useFieldArray({
		control,
		name: 'test.gradeSchema',
	});

	const watchG = watch('test.gradeSchema');

	const canAddGrade = useMemo(() => {
		const __fields = Array.from(fields);
		if (__fields.length > 0) {
			const lastField = __fields.pop();
			if (lastField && lastField.to >= 99) return false;
		}
		return true;
	}, [fields]);
	const {
		currentEntity: { active },
	} = useTypedSelector((state) => state.Tests);

	useEffect(() => {
		if (defineRange === false) {
			setValue('test.gradeSchema', []);
		}
	}, [defineRange]);

	return (
		<>
			<GridLayout gridColumnGap={'5rem'} pr="1rem">
				<Controller
					control={control}
					name="test.passing_value"
					render={({ field: { ref, ...otherMethods }, fieldState }) => {
						return (
							<FlexLayout mb="1rem">
								<EdFormControlLabel>Passing Value</EdFormControlLabel>
								<EdNumericInput
									disabled={active}
									fullWidth
									size="small"
									error={fieldState.error ? true : false}
									helperText={fieldState.error?.message}
									inputRef={ref}
									{...otherMethods}
								/>
							</FlexLayout>
						);
					}}
				/>
				<FlexLayout>
					<EdFormControlLabel>Unit</EdFormControlLabel>
					<StyledSelect
						disabled={active}
						size="small"
						control={control as any}
						name="test.passing_unit"
					>
						<MenuItem value={'point'}>Point</MenuItem>
						<MenuItem value={'percentage'}>Percentage</MenuItem>
					</StyledSelect>
				</FlexLayout>

				<FlexLayout justifyContent="space-between" alignItems="center">
					<EdFormControlLabel>Define grade ranges</EdFormControlLabel>
					<Controller
						control={control}
						name="defineGradeSchema"
						render={({ field: { onChange, ...others } }) => {
							return (
								<Switch
									disabled={active}
									checked={others.value}
									onChange={(e, checked) => onChange(checked)}
									{...others}
								/>
							);
						}}
					/>
				</FlexLayout>
			</GridLayout>
			<Spacer mb="1rem" />

			{defineRange && (
				<div>
					{fields.map((field, index) => (
						<GradeOptionsForm
							key={field.id}
							control={control as any}
							index={index}
							field={field}
							fields={fields}
							remove={remove}
							append={append}
						/>
					))}
					{canAddGrade && (
						<GradeOptionsStartFrom fields={fields} append={append} />
					)}
				</div>
			)}
		</>
	);
};

export default TestOptionsGradingSection;

const StyledSelect = styled(SelectController)<{ Ml?: string }>`
	/* width: 260px; */
	/* height: 40px; */
	/* margin-left: ${(props) => props.Ml}; */
`;
