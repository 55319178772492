import React, { useCallback, useMemo, useRef, useState } from 'react';
import RoundButton from '@Components/UI/Buttons/RoundButton';
import EdIcon from '@Components/UI/Utilities/EdIcon/EdIcon';
import TableCell from '@mui/material/TableCell';
import NoResults from '@Components/NoResults/NoResults';
import DateTimeFormatter from '@Components/DateTimeFormatter/DateTimeFormatter';
import styled from 'styled-components';
import api from '@Services/api';
import MainTable, { MainTableRow } from '@Components/MainTable/MainTable';
import { TableHeader } from '@Features/generics/generics.type';
import { FlexLayout } from '@Styled/utilities';
import { useTypedSelector } from '@Features/store';
import { onPageChangeRequestMeta } from '@Components/MainTable/MainTable.types';
import { importsGetAll } from '@Pages/Imports/Slice/Imports.slice';
import { useDispatch } from 'react-redux';
import {
	Icon,
	RadioGroup,
	Radio,
	FormControlLabel,
	Button,
} from '@mui/material';
import { useSnackbar } from '@Providers/useSnackbar';
import { CSVLink } from 'react-csv';
import { Import, SuccessSyncData } from './Types/Imports.types';

const tableHeaders: Array<TableHeader> = [
	{
		displayName: 'ID',
		fieldName: 'id',
		width: '10%',
	},
	{
		displayName: 'Status',
		fieldName: 'status',
		width: '10%',
	},
	{
		displayName: 'Imported no.',
		fieldName: 'imported_count',
		width: '10%',
	},
	{
		displayName: 'Failed no.',
		fieldName: 'failed_count',
		width: '10%',
	},
	{
		displayName: 'Description',
		fieldName: 'description',
		width: '24%',
	},
	{
		displayName: 'Import date',
		fieldName: 'created_at',
		width: '12%',
	},
	{
		displayName: 'Imported data',
		fieldName: 'imported_data',
		width: '12%',
	},
	{
		displayName: 'Failed data',
		fieldName: 'failed_data',
		width: '12%',
	},
];

const importDataHeader = [
	{ key: 'username', label: 'User name' },
	{ key: 'id', label: 'ID' },
	{ key: 'flag', label: 'Flag' },
];
const failedDataHeader = [
	{ key: 'username', label: 'User name' },
	{ key: 'id', label: 'ID' },
	{ key: 'reason', label: 'Reason' },
];

const instructorImportDataHeader = [
	{ key: 'code', label: 'Code' },
	{ key: 'label', label: 'Label' },
	{ key: 'group_name', label: 'Group name' },
];
const instructorFailedDataHeader = [
	{ key: 'label', label: 'Label' },
	{ key: 'group_name', label: 'Group name' },
	{ key: 'reason', label: 'Reason' },
];

const ImportsTable: React.FC = () => {
	const { meta, filters, fetchAll, query, dateRange, sortBy } =
		useTypedSelector((state) => state.Imports);
	const [fileValue, setFileValue] = useState<any>(null);
	const [action, setAction] = useState<string>('classroom-enroll');
	const auth = useTypedSelector((state) => state.auth);

	const dispatch = useDispatch();
	const { displaySnackbar } = useSnackbar();
	const inputFileRef = useRef<HTMLInputElement>(null);

	const acceptedFileTypes = action == 'sync' ? '.json' : '.csv';
	const handleChooseFile = () => {
		if (inputFileRef.current) {
			inputFileRef.current.value = '';
			inputFileRef.current.click();
		}
	};

	const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
		if (!e.target.files) return;
		const file = e.target.files.item(0);
		if (!file) return;
		if (
			(action === 'sync' && file.type === 'application/json') ||
			file?.type === 'text/csv'
		)
			setFileValue(file);
		else {
			displaySnackbar('error', 'Accept only csv files');
			setFileValue(null);
		}
	};

	const importFile = () => {
		if (fileValue !== null) {
			const formData = new FormData();
			formData.append('file', fileValue);
			api
				.post(`import/${action}`, formData, {
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				})
				.then(() => {
					dispatch(
						importsGetAll({
							filters,
							page: meta?.current_page ?? 1,
							perPage: meta?.per_page ?? 10,
							query: query ?? '',
							sortBy,
							from: dateRange.from,
							to: dateRange.to,
						})
					);
				})
				.then(() => {
					displaySnackbar('success', 'Please refresh page to see result');
				})
				.catch(function (error) {
					if (error.response)
						displaySnackbar('error', error.response.data.message);
				});
			setFileValue(null);
		} else displaySnackbar('error', 'Please upload file');
	};

	const onPageRequestCallback = useCallback(
		({
			activePage,
			endDate,
			filters,
			query,
			sortBy,
			startDate,
			perPage,
		}: onPageChangeRequestMeta) => {
			dispatch(
				importsGetAll({
					filters,
					page: activePage,
					perPage: perPage ?? 10,
					query,
					sortBy,
					from: startDate,
					to: endDate,
				})
			);
		},
		[]
	);

	const hasImportedData = (item: Import) => {
		if (item.module === 'sync') return !!JSON.parse(item.imported_data);
		return JSON.parse(item.imported_data)?.length > 0;
	};
	const hasFailedData = (item: Import) => {
		if (item.module === 'sync') return !!JSON.parse(item.failed_data);
		return JSON.parse(item.failed_data)?.length > 0;
	};

	return (
		<>
			{(auth.user.type === 'super' || auth.permissions.has('c-imp')) && (
				<>
					<FlexLayout flexDirection="column" mb="40px">
						<input
							type="file"
							accept={acceptedFileTypes}
							hidden
							onChange={handleFileChange}
							ref={inputFileRef}
						/>
						<UploadButton
							onClick={handleChooseFile}
							startIcon={<Icon>upload_file</Icon>}
							variant="outlined"
							color="primary"
						>
							Upload File
						</UploadButton>
						{fileValue && (
							<FlexLayout
								minWidth="250px"
								width="fit-content"
								marginLeft="10px"
								justifyContent="space-between"
							>
								<p style={{ fontSize: '18px' }}>{fileValue.name}</p>
								<EdIcon
									style={{
										color: '#f74343',
										marginLeft: '10px',
										marginTop: '-5px',
										cursor: 'pointer',
									}}
									fontSize="small"
									onClick={() => setFileValue(null)}
								>
									<Icon>delete</Icon>
								</EdIcon>
							</FlexLayout>
						)}
					</FlexLayout>
					<FlexLayout mb="50px">
						<RadioGroup
							row
							defaultValue="classroom-enroll"
							style={{ flex: 1.5 }}
							onChange={(event, value) => {
								setAction(value);
							}}
						>
							<FormControlLabel
								value="classroom-enroll"
								label="Classroom enrollment"
								control={<Radio />}
							/>
							<FormControlLabel
								value="course-enroll"
								label="Course enrollment"
								control={<Radio />}
							/>
							<FormControlLabel
								value="banned"
								label="Classroom ban"
								control={<Radio />}
							/>
							<FormControlLabel
								value="instructor-codes"
								label="Instructor codes"
								control={<Radio />}
							/>
							<FormControlLabel
								value="sync"
								label="Sync Records"
								control={<Radio />}
							/>
						</RadioGroup>
						<FlexLayout justifyContent="flex-end">
							<RoundButton
								onClick={importFile}
								sx={{ marginRight: '1rem' }}
								startIcon={<EdIcon>redo</EdIcon>}
								variant="contained"
							>
								Import
							</RoundButton>
						</FlexLayout>
					</FlexLayout>
				</>
			)}
			<MainTable
				tableFilters={filters}
				tableHeads={tableHeaders}
				totalPages={meta?.last_page ?? 0}
				onPageChangeRequest={onPageRequestCallback}
				RowsperPage={meta?.per_page}
				pageNum={meta?.current_page}
				$hasActions
				total={meta?.total}
				renderItems={() => {
					if (fetchAll.length === 0)
						return (
							<MainTableRow>
								<TableCell colSpan={tableHeaders.length}>
									<NoResults />
								</TableCell>
							</MainTableRow>
						);
					return fetchAll.map((importValue) => {
						return (
							<MainTableRow key={importValue?.id} hover>
								<TableCell>{importValue?.id || '-'}</TableCell>
								<TableCell>{importValue?.status || '-'}</TableCell>
								<TableCell>{importValue?.imported_count || '-'}</TableCell>
								<TableCell>{importValue?.failed_count || '-'}</TableCell>
								<TableCell
									style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
								>
									{importValue?.description || '-'}
								</TableCell>
								<TableCell>
									<DateTimeFormatter date={importValue.created_at} />
								</TableCell>
								<TableCell>
									{hasImportedData(importValue) ? (
										<DownloadCSV item={importValue} type="success" />
									) : (
										<StyledImportDataButton
											ImportedFlag={true}
											onClick={() => {
												displaySnackbar('error', 'No data to download');
											}}
										>
											<EdIcon>download</EdIcon>
										</StyledImportDataButton>
									)}
								</TableCell>
								<TableCell>
									{hasFailedData(importValue) ? (
										<DownloadCSV item={importValue} type="failed" />
									) : (
										// <CSVLink
										// 	data={JSON.parse(importValue.failed_data)}
										// 	filename={'FailedData' + importValue.id + '.csv'}
										// 	headers={
										// 		importValue?.description === 'instructor codes import'
										// 			? instructorFailedDataHeader
										// 			: failedDataHeader
										// 	}
										// >
										// 	<StyledImportDataButton ImportedFlag={false}>
										// 		<EdIcon>download</EdIcon>
										// 	</StyledImportDataButton>
										// </CSVLink>
										<StyledImportDataButton
											ImportedFlag={false}
											onClick={() => {
												displaySnackbar('error', 'No data to download');
											}}
										>
											<EdIcon>download</EdIcon>
										</StyledImportDataButton>
									)}
								</TableCell>
							</MainTableRow>
						);
					});
				}}
			/>
		</>
	);
};

const StyledImportDataButton = styled.button<{ ImportedFlag: boolean }>`
	width: 34px;
	height: 34px;
	border-radius: 6px;
	padding-top: 5px;
	border: none;
	cursor: pointer;
	background-color: ${(props) => (props.ImportedFlag ? '#54C0FC' : '#f74343')};
	color: white;
`;

const UploadButton = styled(Button)`
	width: fit-content;
	margin-bottom: 20px;
`;

export default ImportsTable;

type Props = {
	item: Import;
	type: 'success' | 'failed';
};
const DownloadCSV = ({ item, type }: Props) => {
	const convertToCSV = () => {
		let parsed = JSON.parse(
			type === 'success' ? item.success_data : item.failed_data
		) as SuccessSyncData;

		const centerAttendanceHeaders = [
			['Center Attendance', '', '', '', '', ''],
			[
				'Course',
				'Student',
				'Type',
				'Classroom',
				
				type === 'failed' ? ['Reason'] : '',
			],
		];
		const enrollmentHeaders = [
			['Enrollment', '', '', '', '', ''],
			[
				'Course',
				'Student',
				'Type',
				
				type === 'failed' ? ['Reason'] : '',
				'',
			],
		];
		const centerAttendanceData = parsed?.center_attendances.map((data) => [
			data.courseId,
			data.studentId,
			data._status,
			data.classroomId,
			
			type === 'failed' ? data.message : '',
		]);
		const enrollmentData = parsed?.enroll_courses.map((data) => [
			data.course_id,
			data.user_id,
			data._status,
			
			type === 'failed' ? data.message : '',
			'',
		]);

		const csvValues = [
			centerAttendanceHeaders.join('\n'),
			centerAttendanceData.join('\n'),
			enrollmentHeaders.join('\n'),
			enrollmentData.join('\n'),
		]
			.filter((_) => _.length > 0)
			.join('\n');

		return csvValues;
	};

	const filename =
		`${type === 'success' ? 'ImportedData' : 'FailedData'}` + item.id + '.csv';

	const downloadCSV = () => {
		const csvData = new Blob([convertToCSV()], { type: 'text/csv' });
		const csvURL = URL.createObjectURL(csvData);
		const link = document.createElement('a');
		link.href = csvURL;
		link.download = filename;
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	};
	if (item.module === 'sync')
		return (
			<StyledImportDataButton
				onClick={downloadCSV}
				ImportedFlag={type === 'success'}
			>
				<EdIcon>download</EdIcon>
			</StyledImportDataButton>
		);
	return (
		<CSVLink
			data={JSON.parse(
				type === 'success' ? item.imported_data : item.failed_data
			)}
			filename={'ImportedData' + item.id + '.csv'}
			headers={
				item?.description === 'instructor codes import'
					? instructorImportDataHeader
					: importDataHeader
			}
		>
			<StyledImportDataButton ImportedFlag={type === 'success'}>
				<EdIcon>download</EdIcon>
			</StyledImportDataButton>
		</CSVLink>
	);
};
