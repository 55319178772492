import DateTimeFormatter from '@Components/DateTimeFormatter/DateTimeFormatter';
import EdSpeedDial, {
	EdSpeedDialAction,
} from '@Components/UI/Utilities/EdSpeedDial/EdSpeedDial';
import { TableCell } from '@mui/material';
import React, { useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { TableCellStyled } from '../EnrollmentStudent';
import UserProgressModel from '../ProgressModels/UserProgressModel';
import UserProgress from '../UserProgress';
import ReplayIcon from '@mui/icons-material/Replay';
import CheckIcon from '@mui/icons-material/Check';
import LoopIcon from '@mui/icons-material/Loop';
import { rgba } from 'polished';
import { EnrolledUser } from '@Services/classrooms/classrooms.res.types';
import EdConfirmModal from '@Components/UI/Modals/EdConfirmModal/EdConfirmModal';
import { getCourseProgress } from '@Features/classrooms/classroomSlice';
import { UsersRequester } from '../Services/Userprogress/UserProgressRequester';
import useFilters from '@Hooks/useFilters';
import useSortByURL from '@Hooks/useSortBy';
import useQueryURL from '@Hooks/useQueryUrl';
import { useSnackbar } from '@Providers/useSnackbar';
import { useTypedSelector } from '@Features/store';
import { useDispatch } from 'react-redux';
import { enrolledUsersUnitsGetSingle } from '../Slice/users.Slice';
import ExpiredStatus from '@Components/UI/Utilities/ExpiredStatus/ExpiredStatus';
import TodayIcon from '@mui/icons-material/Today';
import ExpiredModel from '../ProgressModels/ExpiredModel';
import { Tooltip } from '@mui/material';
import { ActionsTableRow } from '@Components/MainTable/MainTable.styled';

type Props = {
	enrolled: EnrolledUser;
};

type Time = {
	[key: string]: number;
};

const ProgressTable: React.FC<Props> = ({ enrolled }) => {
	const params = useParams<{ id: string; name: string; crid: string }>();
	const { selectedFilters } = useFilters();
	const { selectedSortBy } = useSortByURL();
	const { selectedQuery } = useQueryURL();
	const dispatch = useDispatch();
	const { displaySnackbar } = useSnackbar();
	const { perPage, page } = useTypedSelector((state) => state.classroom);

	const [currentUser, setCurrentUser] = useState<any>();

	const [openUserProgressModel, setOpenUserProgressModel] =
		useState<boolean>(false);
	const [openResetModel, setOpenResetModel] = useState<boolean>(false);
	const [openExpireModel, setOpenExpireModel] = useState<boolean>(false);
	const [openCompleteModel, setOpenCompleteModel] = useState<boolean>(false);
	const [openSyncronizeModel, setOpenSyncronizeModel] =
		useState<boolean>(false);
	const UserProgressModalClose = async (close: boolean, confirm?: boolean) => {
		setOpenUserProgressModel(false);
	};
	const UserProgressExpireModalClose = async (
		close: boolean,
		confirm?: boolean
	) => {
		setOpenExpireModel(false);
	};

	const handleModelCompleteClose = async (
		close: boolean,
		confirm?: boolean
	) => {
		if (confirm) {
			try {
				await UsersRequester.getInstance().completeStudent(Number(params.id), [
					currentUser?.id,
				]);
				displaySnackbar('success', `User Progress completed successfully`);
				dispatch(
					getCourseProgress({
						page,
						perPage,
						sortBy: selectedSortBy?.direction === null ? '' : selectedSortBy,
						filters: selectedFilters,
						query: selectedQuery,
						enrolled: true,
						courseId: Number(params.id),
					})
				);
			} catch (e) {
				const msg =
					e.response?.data.message || 'Unable to complete User Progress ';
				displaySnackbar('error', msg);
			}
		}
		setOpenCompleteModel(false);
	};
	const handleModelSyncronizeClose = async (
		close: boolean,
		confirm?: boolean
	) => {
		if (confirm) {
			try {
				await UsersRequester.getInstance().syncronizeStudent(
					Number(params.id),
					[currentUser?.id]
				);
				displaySnackbar('success', `User syncronized successfully`);
				dispatch(
					getCourseProgress({
						page,
						perPage,
						sortBy: selectedSortBy?.direction === null ? '' : selectedSortBy,
						filters: selectedFilters,
						query: selectedQuery,
						enrolled: true,
						courseId: Number(params.id),
					})
				);
			} catch (e) {
				const msg = e.response?.data.message || 'Unable to syncronize User ';
				displaySnackbar('error', msg);
			}
		}
		setOpenSyncronizeModel(false);
	};
	const handleModelResetClose = async (close: boolean, confirm?: boolean) => {
		if (confirm) {
			try {
				await UsersRequester.getInstance().resetStudent(Number(params.id), [
					currentUser?.id,
				]);
				displaySnackbar('success', `User Reseted successfully`);
				dispatch(
					getCourseProgress({
						page,
						perPage,
						sortBy: selectedSortBy?.direction === null ? '' : selectedSortBy,
						filters: selectedFilters,
						query: selectedQuery,
						enrolled: true,
						courseId: Number(params.id),
					})
				);
			} catch (e) {
				const msg = e.response?.data.message || 'Unable to Reset User ';
				displaySnackbar('error', msg);
			}
		}
		setOpenResetModel(false);
	};

	const getSingleUser = async () => {
		try {
			dispatch(
				enrolledUsersUnitsGetSingle({
					id: Number(params.id),
					tId: enrolled?.id,
				})
			);
		} catch (error) {}
	};

	const renderStatus = () => {
		switch (enrolled?.progress_status) {
			case 'completed':
				return (
					<StatueText color="#00d66b">{enrolled?.progress_status}</StatueText>
				);
			case 'in progress':
				return (
					<StatueText color="#2196f3">{enrolled?.progress_status}</StatueText>
				);
			default:
				return (
					<StatueText color="#ff3100">{enrolled?.progress_status}</StatueText>
				);
		}
	};

	const learningTime = useMemo(() => {
		return Number(enrolled?.time);
	}, [enrolled]);

	const findTime = (num: number) => {
		if (num < 1) {
			return '0';
		}
		const qualifier = (num: number) => (num > 1 ? '' : '');
		const numToStr = (num: number, unit: string) =>
			num > 0 ? `${num}${unit} ${qualifier(num)}` : '';
		const oneMinute = 60;
		const oneHour = oneMinute * 60;
		const oneDay = oneHour * 24;
		const oneYear = oneDay * 365;
		const times: Time = {
			year: ~~(num / oneYear),
			day: ~~((num % oneYear) / oneDay),
			hour: ~~((num % oneDay) / oneHour),
			minute: ~~((num % oneHour) / oneMinute),
		};
		let str = '';
		for (let [key, value] of Object.entries(times)) {
			str += numToStr(times[key], key[0]);
		}
		const arr = str.trim().split(' ');
		const res: Array<any> = [];
		arr.forEach((x, i) => {
			res.push(x);
		});
		return res.slice(0, 2).join(' ');
	};

	return (
		<>
			<UserProgressModel
				handleModelClose={UserProgressModalClose}
				openModel={openUserProgressModel}
				setOpenModel={setOpenUserProgressModel}
				currentUser={currentUser}
				currentCourse={Number(params.id)}
			/>

			<EdConfirmModal
				text="Are you sure you want to complete this user?"
				open={openCompleteModel}
				handleClose={handleModelCompleteClose}
			/>
			<EdConfirmModal
				text="Are you sure you want to reset this user?"
				open={openResetModel}
				handleClose={handleModelResetClose}
			/>
			<EdConfirmModal
				text="Are you sure you want to syncronize this user?"
				open={openSyncronizeModel}
				handleClose={handleModelSyncronizeClose}
			/>

			<ExpiredModel
				handleModelClose={UserProgressExpireModalClose}
				openModel={openExpireModel}
				setOpenModel={setOpenExpireModel}
				currentUser={currentUser}
				currentCourse={Number(params.id)}
			/>

			<TableCellStyled>
				<DateTimeFormatter date={enrolled?.expire_at} />
			</TableCellStyled>
			<TableCellStyled>
				{enrolled?.expired !== undefined && (
					<ExpiredStatus expired={!enrolled?.expired} />
				)}
			</TableCellStyled>
			<TableCellStyled>{renderStatus()}</TableCellStyled>
			<TableCellStyled>
				<div
					onClick={(e) => {
						e.stopPropagation();
						getSingleUser();
						setOpenUserProgressModel(true);
						setCurrentUser(enrolled);
					}}
				>
					<UserProgress progress={enrolled?.progress} />
				</div>
			</TableCellStyled>
			<Tooltip
				title={<DateTimeFormatter date={enrolled.created_at} />}
				placement="top"
			>
				<TableCell
					sx={{
						fontFamily: 'Roboto',
						fontSize: '1rem',
						padding: '0 1.063rem 0',
					}}
				>
					{enrolled.created_at ? (
						<DateTimeFormatter date={enrolled.created_at} />
					) : (
						'-'
					)}
				</TableCell>
			</Tooltip>
			<TableCell>
				{enrolled.time === null || enrolled.time === '0' ? (
					'-'
				) : (
					<p>{findTime(learningTime)}</p>
				)}
			</TableCell>
			<ActionsTableRow>
				{enrolled.expired === true ? (
					<EdSpeedDial ariaLabel="SpeedDial basic example">
						<EdSpeedDialActionStyle
							key="Extend Course"
							icon={<TodayIcon />}
							tooltipTitle="Extend Course"
							onClick={(e) => {
								e.stopPropagation();
								setOpenExpireModel(true);
								setCurrentUser(enrolled);
							}}
						/>
						<EdSpeedDialActionStyle
							key="Reset"
							icon={<ReplayIcon />}
							tooltipTitle="Reset"
							onClick={(e) => {
								e.stopPropagation();
								setOpenResetModel(true);
								setCurrentUser(enrolled);
							}}
						/>
						<EdSpeedDialActionStyle
							key="Syncronize"
							icon={<LoopIcon />}
							tooltipTitle="Syncronize"
							onClick={(e) => {
								e.stopPropagation();
								setOpenSyncronizeModel(true);
								setCurrentUser(enrolled);
							}}
						/>
						<EdSpeedDialActionStyle
							key="Complete"
							icon={<CheckIcon />}
							tooltipTitle="Complete"
							onClick={(e) => {
								e.stopPropagation();
								setOpenCompleteModel(true);
								setCurrentUser(enrolled);
							}}
						/>
					</EdSpeedDial>
				) : (
					<EdSpeedDial ariaLabel="SpeedDial basic example">
						<EdSpeedDialActionStyle
							key="Reset"
							icon={<ReplayIcon />}
							tooltipTitle="Reset"
							onClick={(e) => {
								e.stopPropagation();
								setOpenResetModel(true);
								setCurrentUser(enrolled);
							}}
						/>
						<EdSpeedDialActionStyle
							key="Syncronize"
							icon={<LoopIcon />}
							tooltipTitle="Syncronize"
							onClick={(e) => {
								e.stopPropagation();
								setOpenSyncronizeModel(true);
								setCurrentUser(enrolled);
							}}
						/>
						<EdSpeedDialActionStyle
							key="Complete"
							icon={<CheckIcon />}
							tooltipTitle="Complete"
							onClick={(e) => {
								e.stopPropagation();
								setOpenCompleteModel(true);
								setCurrentUser(enrolled);
							}}
						/>
					</EdSpeedDial>
				)}
			</ActionsTableRow>
		</>
	);
};

const EdSpeedDialActionStyle = styled(EdSpeedDialAction)`
	&:hover {
		background-color: ${(props) =>
			rgba(props.theme.palette.primary.main, 0.95)};
	}
`;

const StatueText = styled.div<{ color: string }>`
	color: ${({ color }) => color};
	font-weight: 500;
	text-transform: capitalize;
`;

export default ProgressTable;
