import {
	FormHelperText,
	IconButton,
	InputAdornment,
	OutlinedInput,
} from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import PercentIcon from '@mui/icons-material/Percent';
import { useController, useFormContext } from 'react-hook-form';

const ProgressPercentage = () => {
	const { control } = useFormContext();

	const {
		field: { onChange, value, onBlur, ref },
	} = useController({
		control,
		name: 'progress_percentage',
	});

	const min = 1;
	const max = 100;

	return (
		<Wrapper onBlur={onBlur} ref={ref}>
			<EdInput
				type="number"
				inputProps={{ min, max, 'aria-label': 'Progress Percentage' }}
				placeholder="1-100"
				value={value}
				error={value > 100 && true}
				onChange={(
					event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
				) => {
					onChange(Number(event.target.value));
				}}
				endAdornment={
					<InputAdornment position="end">
						<IconButtonStyle edge="end">
							<PercentIconStyle />
						</IconButtonStyle>
					</InputAdornment>
				}
			/>
			<FormHelperText error id="progress-percentage">
				{value > 100 && 'Allowed maximum is 100'}
			</FormHelperText>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	position: relative;
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
	input[type='number'] {
		-moz-appearance: textfield;
	}
`;

const EdInput = styled(OutlinedInput)`
	height: 2.313rem;
	width: 8.5rem;
	fieldset {
		border: solid 1px rgba(0, 0, 0, 0.12);
	}
	.MuiButtonBase-root {
		padding: 3px;
	}
`;

const IconButtonStyle = styled(IconButton)`
	pointer-events: none;
`;

const PercentIconStyle = styled(PercentIcon)`
	color: #a4a4a4;
	font-size: 1.2rem;
`;

export default ProgressPercentage;
