import { array, boolean, number, object, string, StringSchema } from 'yup';
import { Flatten } from '@Utils/types/Flatten';

const SCHEMA_PART_1 = object({
	id: number().required(),
	uuid: string().required(),
	active: boolean(),
	first_name: string().required(),
	middle_name: string(),
	last_name: string().required(),
	email: string().required(),
	phone_number: string().required(),
	password: string().required(),
	type: string().required(),
	gender: string().required(),
	// profile_photo: string().required(),
	photo: string().required(),
	roles: array(number().required()),
	logs: array(number().required()),
});

const EDIT_CATEGORY = object({
	id: number().required(),
}).concat(SCHEMA_PART_1);

export const FULL_SCHEMA = SCHEMA_PART_1.concat(EDIT_CATEGORY);
export type AddAdminForm = typeof SCHEMA_PART_1.__outputType;
export type EditAdminForm = typeof EDIT_CATEGORY.__outputType;

export type FlattenAdminForm = Flatten<AddAdminForm>;
