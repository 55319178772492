import EdNumericInput from '@Components/UI/Inputs/EdNumericInput/EdNumericInput';
import EdAnimatedIcon from '@Components/UI/Utilities/EdAnimatedIcon/EdAnimatedIcon';
import EdFab from '@Components/UI/Utilities/EdFab/EdFab';
import EdIcon from '@Components/UI/Utilities/EdIcon/EdIcon';
import { DevTool } from '@hookform/devtools';
import { yupResolver } from '@hookform/resolvers/yup';
import {
	IconButton,
	Paper,
	TextField,
	Checkbox,
	FormGroup,
	FormControlLabel,
} from '@mui/material';
import {
	COURSE_SCHEMA,
	CURRICULUM_FORM,
} from '@Pages/ClassRooms/Schema/ClassroomCurriculum.schema';
import { ClassroomCoursesRequester } from '@Pages/ClassRooms/Services/ClassroomTabs/ClassroomCourses.req';
import {
	Course,
	CoursePayload,
	Section,
} from '@Pages/ClassRooms/Types/ClassroomTabs.types';
import { useSnackbar } from '@Providers/useSnackbar';
import { FlexItem, FlexLayout, GridLayout, Spacer } from '@Styled/utilities';
import React, { FC, useContext, useEffect, useState } from 'react';
import { Control, Controller, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { CurriculumContext } from '../../Context/Curriculum.context';
import { FabButtonLabel } from '../../Curriculum.styled';

type Props = {
	control: Control<CURRICULUM_FORM>;
	tabIndex: number;
	sectionIndex: number;
	section: Section;
	onAddCourse: (course: Course) => void;
	disabled?: boolean;
};
const CourseForm: FC<Props> = ({ section, onAddCourse, disabled }) => {
	const [showForm, setShowForm] = useState(false);
	const {
		control: CourseControl,
		formState,
		getValues,
		setValue,
		watch,
		reset,
	} = useForm<CoursePayload>({
		resolver: yupResolver(COURSE_SCHEMA),
		mode: 'all',
		defaultValues: {
			classroom_id: section.classroom_id,
			section_id: section.id,
			classroom_tab_id: section.classroom_tab_id,
			payment_methods_allowed: '*',
			price: null,
		},
	});
	const watchIsFree = watch('isFree');
	const { displaySnackbar } = useSnackbar();

	useEffect(() => {
		if (watchIsFree === undefined) return;
		if (watchIsFree) {
			setValue('price', 0, { shouldValidate: true });
		} else {
			setValue('price', null, { shouldValidate: true });
		}
	}, [watchIsFree]);

	const onConfirm = async () => {
		try {
			const {
				data: { data },
			} = await ClassroomCoursesRequester.getInstance().create(getValues());
			onAddCourse(data as Course);
			reset({
				classroom_id: section.classroom_id,
				section_id: section.id,
				classroom_tab_id: section.classroom_tab_id,
				payment_methods_allowed: '*',
			});
			setShowForm(false);
		} catch (error) {
			displaySnackbar('error', "Couldn't add course");
		}
	};
	return (
		<>
			<GridLayout gridTemplateColumns="15% 1fr" gridColumn={'1/3'}>
				<FlexLayout alignItems="center">
					<EdFab
						disabled={showForm || disabled}
						onClick={() => setShowForm(true)}
						size="small"
						edbackgroundColor="orange"
					>
						<EdIcon fontSize="medium">add</EdIcon>
					</EdFab>
					<Spacer mx={'0.5rem'} />
					<FabButtonLabel>Add Course</FabButtonLabel>
				</FlexLayout>

				{showForm && (
					<FlexItem flex="1">
						<CourseFormPaper>
							<FlexLayout alignItems="center" gridGap={'1rem'}>
								<EdIcon sx={{}}>height</EdIcon>
								<FlexLayout minWidth="15rem">
									<Controller
										control={CourseControl}
										name="name"
										render={({ field, fieldState }) => {
											return (
												<TextField
													{...field}
													error={fieldState.invalid}
													fullWidth
													size="small"
													placeholder="Course name"
												/>
											);
										}}
									/>
								</FlexLayout>
								<FlexLayout maxWidth="10rem">
									<Controller
										control={CourseControl}
										name="code"
										render={({ field, fieldState }) => {
											return (
												<TextField
													{...field}
													error={fieldState.invalid}
													size="small"
													placeholder="Code"
												/>
											);
										}}
									/>
								</FlexLayout>
								<FlexLayout maxWidth="10rem">
									<Controller
										control={CourseControl}
										name="price"
										render={({
											field: { onChange, value, ...inputProps },
											fieldState,
										}) => {
											return (
												<EdNumericInput
													value={value === null ? '' : value}
													disabled={watchIsFree}
													{...inputProps}
													onChange={onChange}
													error={fieldState.invalid}
													size="small"
													placeholder="Price"
												/>
											);
										}}
									/>
								</FlexLayout>

								<Controller
									control={CourseControl}
									name="isFree"
									render={({ field: { value, onChange, ...inputProps } }) => {
										return (
											<FormGroup>
												<FormControlLabel
													control={
														<Checkbox
															{...inputProps}
															checked={value}
															onChange={(e, checked) => {
																onChange(checked);
															}}
														/>
													}
													label="Free"
												/>
											</FormGroup>
										);
									}}
								/>
							</FlexLayout>
							{formState.isValid && (
								<IconButton onClick={onConfirm}>
									<EdAnimatedIcon animation="shake">
										<EdIcon>check_circle_outline</EdIcon>
									</EdAnimatedIcon>
								</IconButton>
							)}
							<DevTool control={CourseControl} />
						</CourseFormPaper>
					</FlexItem>
				)}
			</GridLayout>
		</>
	);
};

export default CourseForm;

const CourseFormPaper = styled(Paper)`
	padding: 0.375rem 1rem;
	display: flex;
	justify-content: space-between;
`;
