import React, { useEffect } from 'react';
import { useTypedSelector } from '@Features/store';
import { useDispatch } from 'react-redux';
import { getAllClassroomTabs } from '@Pages/ClassRooms/Slices/ClassroomTabs/ClassroomTabs.slice';
import CurriculumForm from './Components/CurriculumForm/CurriculumForm';

const Curriculum = () => {
	const dispatch = useDispatch();
	const { currentClassroom } = useTypedSelector((state) => state.classroom);
	const {
		loaders: { fetchAll },
	} = useTypedSelector((state) => state.ClassroomTabs);
	useEffect(() => {
		if (currentClassroom) {
			dispatch(getAllClassroomTabs(currentClassroom.id));
		}
	}, [currentClassroom]);

	return (
		<>{currentClassroom && fetchAll === 'fulfilled' && <CurriculumForm />}</>
	);
};

export default Curriculum;
