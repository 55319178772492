import { Box, Button, Modal, OutlinedInput } from '@mui/material';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import styled from 'styled-components';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import { useDispatch } from 'react-redux';
import { useSnackbar } from '@Providers/useSnackbar';
import {
	fetchAllWalletCodes,
	GenerateWalletCodes,
} from '@Features/walletCodes/walletCodesSlice';
import { useTypedSelector } from '@Features/store';
import { fetchWalletBatchesDropdown } from '@Features/dropdowns/dropdownsSlice';
import EdNumericInput from '@Components/UI/Inputs/EdNumericInput/EdNumericInput';
import { Spacer } from '@Styled/utilities';
import { rechargeCodesGetAll } from '../Slices/RechargeCodes.slice';
import useFilters from '@Hooks/useFilters';
import useQueryURL from '@Hooks/useQueryUrl';

type Props = {
	onConfirmation: React.Dispatch<boolean>;
	handleModelClose: React.Dispatch<boolean>;
	openModel: boolean;
};

export type CourseFormData = {
	value: number;
	quantity: number;
};

const SCHEMA = yup.object().shape({
	value: yup.number().positive().required(),
	quantity: yup.number().positive().required(),

	checker: yup.boolean(),
});

const GenerateWalletCodesModule: React.FC<Props> = ({
	handleModelClose,
	onConfirmation,
	openModel,
}) => {
	const dispatch = useDispatch();
	const {
		formState: { isValid },
		watch,
		reset,
		control,
		handleSubmit,
	} = useForm<any>({
		resolver: yupResolver(SCHEMA),
		mode: 'onTouched',
		defaultValues: {
			checker: false,
		},
	});

	const { codes, loaders } = useTypedSelector((state) => state.walletCodes);
	const { selectedFilters } = useFilters();
	const { selectedQuery } = useQueryURL();
	const { displaySnackbar } = useSnackbar();
	const [generate, setGenerate] = useState<boolean>();

	const onSubmitForm = (formData: CourseFormData) => {
		dispatch(
			GenerateWalletCodes({
				quantity: Number(formData.quantity),
				value: Number(formData.value),
			})
		);
		displaySnackbar('success', 'Generated Codes Successfully ');
		reset('', {
			keepValues: false,
		});
		handleModelClose(false);
	};

	useEffect(() => {
		if (loaders.generate === false) {
			setGenerate(true);
		}
	}, [loaders.generate]);
	useEffect(() => {
		setGenerate(false);
	}, []);
	const generateCode = () => {
		if (generate) {
			const dataStr = `data:text/csv;charset=utf-8,${codes}`;
			const downloadAnchorNode = document.createElement('a');
			downloadAnchorNode.setAttribute('href', dataStr);
			downloadAnchorNode.setAttribute('download', 'wallet-codes.csv');
			document.body.appendChild(downloadAnchorNode);
			downloadAnchorNode.click();
			downloadAnchorNode.remove();
		}
	};

	useEffect(() => {
		dispatch(fetchWalletBatchesDropdown());
		dispatch(
			rechargeCodesGetAll({
				page: 1,
				perPage: 10,
				sortBy: '',
				filters: selectedFilters,
				query: selectedQuery,
			})
		);
		dispatch(
			fetchAllWalletCodes({
				page: 1,
				query: '',
				sortBy: { direction: 'asc', field: 'id' },
				filters: [],
				perPage: 10,
			})
		);
		generateCode();
	}, [codes, generate, loaders.generate]);

	return (
		<Modal
			open={openModel}
			onClose={() => {
				handleModelClose(false);
				reset();
			}}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Container>
				<form onSubmit={handleSubmit(onSubmitForm)}>
					<Box sx={{ marginLeft: '1.5rem', marginTop: '2.25rem' }}>
						<TitleStyled id="modal-modal-title">Generate Codes </TitleStyled>
					</Box>

					<Box
						sx={{
							marginLeft: '3.5rem',
							marginRight: '4rem',
							marginTop: '1.5rem',
							display: 'flex',
							flexDirection: 'column',
						}}
					>
						<Controller
							control={control}
							name="value"
							render={({ field, fieldState: { error } }) => (
								<EdNumericInput
									variant="outlined"
									size="small"
									label="Set Value"
									error={error ? true : false}
									placeholder="Value"
									{...field}
								/>
							)}
						/>
						<Spacer my="1rem" />
						<Controller
							control={control}
							name="quantity"
							render={({ field, fieldState: { error } }) => (
								<EdNumericInput
									variant="outlined"
									size="small"
									label="Set Quantity"
									error={error ? true : false}
									placeholder="Quantity"
									{...field}
								/>
							)}
						/>
						<Controller
							control={control}
							name="checker"
							render={({ field }) => (
								<CheckboxStyled
									control={<Checkbox />}
									{...field}
									label="All selected options are correct"
								/>
							)}
						/>
					</Box>
					<ButtonsContainer>
						<Button
							variant="text"
							color="inherit"
							disabled={!isValid || !watch('checker')}
							sx={{
								fontFamily: 'Roboto',
								fontSize: '14px',
								padding: '6px 16px',
								color: '#6c63ff',
								backgroundColor: '#fff',
								outline: 'none',
							}}
							// onClick={() => setGenerate(false)}
							type="submit"
						>
							Submit
						</Button>
						<Button
							variant="text"
							color="warning"
							sx={{
								fontFamily: 'Roboto',
								fontSize: '14px',
								padding: '6px 16px',
								color: '#ff9800',
								outline: 'none',
								backgroundColor: '#fff',
							}}
							onClick={() => {
								handleModelClose(false);
								reset();
							}}
						>
							Cancel
						</Button>
					</ButtonsContainer>
				</form>
			</Container>
		</Modal>
	);
};

export default GenerateWalletCodesModule;

export const Container = styled(Box)`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	min-width: 23.625rem;
	/* min-height: 21rem; */
	border-radius: 4px;
	border: 0;
	box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.38);
	margin: 39px 80px 50px 11.4px;
	padding: 12px 0 0;
	background-color: #ffffff;
	outline: none;
`;

export const TitleStyled = styled.span`
	font-weight: 500;
	font-family: Roboto;
	text-align: left;
	font-size: 1rem;
	color: rgba(0, 0, 0, 0.6);
`;

export const ButtonsContainer = styled(Box)`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	outline: none;
	margin-top: 1.625rem;
	margin-bottom: 0.5rem;
`;

export const RadioGroupStyled = styled(RadioGroup)`
	display: flex;
	flex-direction: row;
	.MuiTypography-root {
		font-family: Roboto;
		font-size: 0.875rem;
		color: rgba(0, 0, 0, 0.87);
	}
`;

export const SelectStyled = styled(Select)`
	width: 16.2rem;
`;

export const InputStyled = styled(OutlinedInput)`
	width: 16.2rem;
`;

export const CheckboxStyled = styled(FormControlLabel)`
	padding-left: 1.5rem;
	.MuiTypography-root {
		font-size: 0.75rem;
		font-family: Roboto;
		font-weight: normal;
	}
`;

export const SpanStyled = styled.span`
	font-family: Roboto;
	font-size: 1rem;
	color: rgba(0, 0, 0, 0.14);
`;

export const LabelStyled = styled.label`
	font-family: Montserrat;
	margin-top: 1.3rem;
	margin-bottom: 0.294rem;
	font-size: 0.8rem;
`;
