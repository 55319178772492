import { BaseTinyMceConfig } from '@Pages/ClassRooms/Pages/SingleCourse/Pages/CourseForm/Pages/CourseContent/Pages/Test/Constants/Questions.config';
import { uploadPicture } from '@Services/uploads/uploads.req';
import { Editor, IAllProps } from '@tinymce/tinymce-react';
import React from 'react';

type Props = { min_height?: number } & IAllProps;
const TinyMceControl: React.FC<Props> = (props) => {
	return (
		<Editor
			apiKey="t0836u9ihhfgcthx7ze3a146uk6pjje44mq82ij090qjv0ue"
			{...BaseTinyMceConfig}
			init={{
				...BaseTinyMceConfig.init,
				min_height: props.min_height ?? 400,
				images_upload_handler: async (blobInfo) => {
					console.log(blobInfo.blob());
					const {
						data: {
							data: { uri },
						},
					} = await uploadPicture({
						folder: 'tinymc',
						photo: blobInfo.blob(),
					});
					return uri;
				},
			}}
			{...props}
		/>
	);
};

export default TinyMceControl;
