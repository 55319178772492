import * as yup from 'yup';
import { Unit } from '@Pages/ClassRooms/Pages/SingleCourse/Pages/CourseForm/Pages/CourseContent/Pages/Contents/Types/Units.types';

export type CourseFormData = {
	name: string;
	code: string;
	preview_url: string | null;
	order: number;
	section_id: number;
	description: string;
	price: number;
	old_price: number | null;
	expiry_duration: number;
	free: boolean;
	capacity: number | null;
	payment_methods_allowed: string;
	active: boolean;
	unlimited: boolean;
	buyable: boolean;
	add_to_sequence: boolean;
	current_course: boolean;
	scores_view: boolean;
	Set_buyable_period: boolean;
	Set_active_period: boolean;
	active_start_date?: Date | null;
	active_end_date?: Date | null;
	buy_start_date?: Date | null;
	buy_end_date?: Date | null;
	expiry_date?: Date | null;
	expiry_period: number;
	sequential_units?: boolean;
	skip_expiry_for_completed : boolean
	corresponding_course_id ? : number
	correspondingCourseClassroomId ? : number
	quiz_id?: number
	assignment_id?: number
};

export const CourseFormSchema = yup.object().shape(
	{
		name: yup.string().required(),
		code: yup.string().required(),
		preview_url: yup
			.string()
			.matches(
				/((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
				'Enter correct url!'
			)
			.transform((value) => (!!value ? value : null))
			.nullable(true)
			.notRequired(),
		order: yup
			.number()
			.positive()
			.nullable(true)
			.transform((v, o) => (o === '' ? null : v))
			.notRequired(),

		description: yup.string().notRequired().nullable(true),
		expiry_period: yup
			.number()
			.notRequired()
			.nullable(true)
			.transform((o, v) => {
				return isNaN(o) ? null : o;
			}),
		section_id: yup.number().notRequired().nullable(),
		free: yup.boolean().notRequired(),
		price: yup
			.number()
			.typeError('Price Is Required')
			.notRequired()
			.when('free', {
				is: false,
				then: yup
					.number()
					.typeError('Price Is Required')
					.positive()
					.min(1)
					.required(),
			}),
		old_price: yup
			.number()
			.transform((value) => (isNaN(value) ? undefined : value))
			.moreThan(
				yup.ref('price'),
				'price before discount must be bigger than price'
			)
			.nullable(true)
			.notRequired(),

		expiry_duration: yup.number().positive().min(1).notRequired(),
		add_to_sequence: yup.boolean().notRequired().default(true),
		current_course: yup.boolean().when('add_to_sequence', {
			is: false,
			then: yup.boolean().strip(),
			otherwise: yup.boolean().default(true).notRequired(),
		}),
		unlimited: yup.boolean(),
		capacity: yup
			.number()
			.nullable(true)

			.when('unlimited', {
				is: false,
				then: yup
					.number()
					.typeError('Capacity Must be a number')
					.min(1, 'Capacity must be bigger than zero')
					.required(),
			}),
		active: yup.boolean().required(),
		Set_active_period: yup.boolean().notRequired(),
		Set_buyable_period: yup.boolean().notRequired(),
		buyable: yup.boolean().required(),
		scores_view: yup.boolean().notRequired(),
		payment_methods_allowed: yup.string().required(),
		active_start_date: yup
			.date()
			.nullable(true)
			.optional()
			.transform((v, o) => (o === '' ? null : v)),
		active_end_date: yup
			.date()
			.nullable(true)
			.notRequired()
			.transform((v, o) => (o === '' ? null : v)),
		buy_start_date: yup
			.date()
			.nullable(true)
			.notRequired()
			.transform((v, o) => (o === '' ? null : v)),
		buy_end_date: yup
			.date()
			.nullable(true)
			.notRequired()
			.transform((v, o) => (o === '' ? null : v)),
		expiry_date: yup
			.date()
			.nullable(true)
			.notRequired()
			.transform((v, o) => (o === '' ? null : v)),
	},
	[['old_price', 'old_price']]
);
