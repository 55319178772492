import EdActiveStatus from '@Components/UI/Utilities/EdActiveStatus/EdActiveStatus';
import React from 'react';

type Props = {
	active: boolean;
};

const CheckComponent = ({ active }: Props) => {
	return (
		<>
			<EdActiveStatus active={active} />
		</>
	);
};

export default CheckComponent;
