import { Box, Button, Modal, Typography } from '@mui/material';
import React from 'react';

type Props = {
	onConfirmation: any;
	handleModelClose: () => void;
	openModel: boolean;
	word?: string;
};

const DeleteModel: React.FC<Props> = ({
	handleModelClose,
	onConfirmation,
	openModel,
	word,
}) => {
	return (
		<Modal
			open={openModel}
			onClose={handleModelClose}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Box
				sx={{
					position: 'absolute',
					top: '50%',
					left: '50%',
					transform: 'translate(-50%, -50%)',
					width: '280px',
					minHeight: '136px',
					borderRadius: '4px',
					border: '0',
					boxShadow: '0 3px 3px 0 rgba(0, 0, 0, 0.38)',
					margin: '39px 80px 50px 11.4px',
					padding: '12px 0 0',
					backgroundColor: '#ffffff',
					outline: 'none',
				}}
			>
				<Typography
					id="modal-modal-title"
					sx={{
						width: '232px',
						height: '40px',
						marginLeft: '24px',
						fontWeight: 'normal',
						fontFamily: 'Roboto',
						textAlign: 'left',
						fontSize: '14px',
						marginTop: '20px',
						color: 'rgba(0, 0, 0, 0.6)',
					}}
				>
					Are you sure you want to delete this {word}
				</Typography>
				<Box
					sx={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'flex-end',
						marginBottom: '7px',
						outline: 'none',
						marginTop: '26px',
					}}
				>
					<Button
						variant="text"
						sx={{
							fontFamily: 'Roboto',
							fontSize: '14px',
							padding: '6px 16px',
							color: '#ff8532',
							outline: 'none',
							backgroundColor: '#fff',
						}}
						onClick={handleModelClose}
					>
						No
					</Button>
					<Button
						variant="text"
						sx={{
							fontFamily: 'Roboto',
							fontSize: '14px',
							padding: '6px 16px',
							color: '#6200ee',
							backgroundColor: '#fff',
							outline: 'none',
						}}
						onClick={onConfirmation}
					>
						Yes
					</Button>
				</Box>
			</Box>
		</Modal>
	);
};

export default DeleteModel;
