export type QuestionTypeConfig = { [key in QuestionType]: string };
export type OptionsPair = { gap: string | number; choices: string[] };
export type QuestionType = 'gap' | 'mcq' | 'ordering' | "essay";
export type KeyPairAnswer = {
	target: number | string | Array<string>;
	answer: string;
};
export type OrderingAnswer = Array<string>;
export type Answers = {
	id?: number;
	test_attempt_id?: number;
	test_question_id?: number;
	created_at?: Date;
	updated_at?: Date;
};
type McqOption = {
	answer: string;
	correct: boolean;
	target?: number;
};
type OrderOption = {
	answer: Array<string>;
	correct: boolean;
	target?: number;
};
type EssayOption = {
	answer: string | Array<string>;
	type: string;
};
type GapOption = {
	answer: string | Array<string>;
	correct: boolean;
	target: number;
};
type McqAnswers = {
	content?: {
		options: McqOption;
	};
} & Answers;
type GapAnswers = {
	content?: {
		options: Array<GapOption>;
	};
} & Answers;
type OrderAnswers = {
	content?: {
		options: OrderOption;
	};
} & Answers;
type EssayAnswers = {
	content?: {
		options: EssayOption;
	};
} & Answers;
export type Options = {
	choice?: string;
	is_correct?: boolean;
	option?: string;
	test_question_id?: number;
	order?: number;
	gap?: number;
	choices?: string;
	correct?: string;
};
type QuestionOptions = Array<Options | string | OptionsPair>;
type GapAnswerOptions = GapAnswers;
type OrderAnswerOptions = OrderAnswers;
type McqAnswerOptions = McqAnswers;
type EssayAnswerOptions = EssayAnswers;

type QuestionAnswer =
	| Array<KeyPairAnswer>
	| OrderingAnswer
	| GapAnswerOptions
	| OrderAnswerOptions
	| McqAnswerOptions;
export type SummaryQuestion = {
	parsed_content: string | null;
	content: string | null;
	answer_schema?: string;
	id: number;
	test_id: number;
	type: QuestionType;
	weight: number;
	feedback: string | null;
	order: number;
	fillInTheGapOptions?: QuestionOptions;
	multipleChoiceOptions?: QuestionOptions;
	orderingOptions?: QuestionOptions;
	answer: QuestionAnswer;
	score: number;
	correct: boolean;
};

export type Test = {
	id?: number;
	unit_id?: number;
	uuid?: string;
	title?: string;
	duration?: number;
	passing_value: number;
	overall_score?: number;
	passing_unit: string;
	shuffle_questions: boolean;
	shuffle_answers: boolean;
	include_previous_attempts: boolean;
	student_notification_options: Array<any>;
	parent_notification_options: Array<any>;
	active_start_date: Date | null;
	locked: boolean;
	view_mode: string;
	model_mode?: string;
	start_text?: string;
	end_text?: string | null;
	status?: string;
	score?: number;
	allow_movement?: boolean;
	show_score_percentage?: boolean;
	show_score_value?: boolean;
	show_grade?: boolean;
	active?: boolean;
	show_status?: boolean;
	show_correct_if_failed?: boolean;
	show_correct_if_passed?: boolean;
	test_summary: boolean;
	allow_repetition_when?: string;
	message_if_passed?: string | null;
	message_if_failed?: string | null;
	allowed_trials?: number;
	questions_count?: string;
	active_end_date?: Date | null;
	unit?: {
		id?: number;
		course_id?: number;
		name?: string;
		order?: number;
		type_id?: number;
		active?: boolean;
	};
	created_at?: Date;
	updated_at?: Date;
};
export type Attempt = {
	attempt: {
		end_date?: string;
		grade: string | null;
		id?: number;
		test_model_id?: number | null;
		student_id?: number;
		test_id?: number;
		active?: boolean;
		notification_sent?: boolean;
		endDate?: Date;
		score?: number;
		overall_score?: number;
		status?: string;
		testModel?: string | null;
		studentId?: number;
		testId?: number;
		testModelId?: number;
		test?: Test;
		questionsOrder?: Array<number>;
		questions?: Array<SummaryQuestion>;
		created_at?: Date;
		updated_at?: Date;
	};
};
export type IGapAnswer = Omit<
	SummaryQuestion,
	'fillInTheGapOptions' | 'type'
> & {
	type: 'gap';
	fillInTheGapOptions: Array<Options>;
	parsed_content: string;
	answer: GapAnswerOptions;
};
export type IOrderingAnswer = Omit<
	SummaryQuestion,
	'orderingOptions' | 'type'
> & {
	type: 'ordering';
	orderingOptions: QuestionOptions;
	content: string;
	answer: OrderAnswerOptions;
};
export type IMcqAnswer = Omit<
	SummaryQuestion,
	'multipleChoiceOptions' | 'type'
> & {
	type: 'mcq';
	multipleChoiceOptions: Array<Options>;
	content: string;
	answer: McqAnswerOptions;
};
export type IEssayAnswer = Omit<SummaryQuestion, 'options' | 'type'> & {
	type: 'essay';
	options: Array<Options>;
	parsed_content: string;
	answer: EssayAnswerOptions;
};
export const isEssayAnswer = (
	value: SummaryQuestion
): value is IEssayAnswer => {
	return value !== undefined && value.type === 'essay';
};
export const isGapAnswer = (value: SummaryQuestion): value is IGapAnswer => {
	return value !== undefined && value.type === 'gap';
};
export const isOrderingAnswer = (
	value: SummaryQuestion
): value is IOrderingAnswer => {
	return value !== undefined && value.type === 'ordering';
};
export const isMcqAnswer = (value: SummaryQuestion): value is IMcqAnswer => {
	return value !== undefined && value.type === 'mcq';
};
