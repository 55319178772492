import { BaseSliceController } from '@Base/slices/slices.base';
import { PromoCodesRequester } from '../Services/PromoCodes.req';
import { PromoCode } from '../Types/PromoCodes.types';

export class PromoCodessSlice extends BaseSliceController<
	PromoCode,
	'PromoCodes'
> {
	private static instance: PromoCodessSlice;
	static getInstance() {
		if (!PromoCodessSlice.instance) {
			PromoCodessSlice.instance = new PromoCodessSlice();
		}
		return PromoCodessSlice.instance;
	}
	constructor() {
		super('PromoCodes', PromoCodesRequester.getInstance());
	}
}

export const {
	promoCodesDeleteById,
	promoCodesExportData,
	promoCodesGenerate,
	promoCodesCreate,
	promoCodesUpdate,
	promoCodesGetAll,
	promoCodesToggleActive,
	promoCodesGetSingle
} = PromoCodessSlice.getInstance().getActions();
