import { Stack } from '@mui/material';
import {
	CancelBtn,
	SaveBtn,
} from '@Pages/Roles/pages/AddRole/Components/addRoleInfo';
import React from 'react';
import { FormState } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { EditNewsFeedForm } from '../Schema/NewsFeed.schema';

type Props = {
	getValues: any;
	onSave: any;
	formState: FormState<EditNewsFeedForm>;
};

const GroupedBtns: React.FC<Props> = ({ getValues, onSave, formState }) => {
	const history = useHistory();

	return (
		<>
			<Stack sx={{ marginTop: '2.625rem' }} spacing={2} direction="row-reverse">
				<SaveBtn
					variant="contained"
					disabled={!formState.isValid || !formState.isDirty}
					onClick={() => {
						const data = getValues();
						onSave(data);
					}}
				>
					SAVE
				</SaveBtn>
				<CancelBtn
					variant="contained"
					disabled={!formState.isDirty}
					onClick={() => history.push('/dashboard/newsfeed')}
				>
					CANCEL
				</CancelBtn>
			</Stack>
		</>
	);
};

export default GroupedBtns;
