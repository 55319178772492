import { SectionPayload, Tab } from './../Types/ClassroomTabs.types';
import {
	AnySchema,
	array,
	ArraySchema,
	boolean,
	date,
	number,
	NumberSchema,
	object,
	ObjectSchema,
	string,
	StringSchema,
} from 'yup';

export const COURSE_SCHEMA = object({
	id: number(),
	coursefId: string(),
	name: string().required(),
	classroom_id: number().required(),
	classroom_tab_id: number().required(),
	order: number(),
	code: string().required(),
	isFree: boolean(),
	price: number().when({
		is: 'isFree',
		then: number(),
		otherwise: number().required(),
	}),
});

export const SECTION_SCHEMA = object({
	id: number().required().required(),
	classroom_tab_id: number().required(),
	classroom_id: number().required(),
	name: string().required(),
	order: number().required(),
	created_at: date(),
	updated_at: date(),
	sectionfId: number(),
	courses: array(COURSE_SCHEMA.required()),
});
export const TAB_SCHEMA = object().shape({
	id: number().required(),
	sections: array(SECTION_SCHEMA),
	classroom_id: number().required(),
	name: string().required(),
	order: number().required(),
	fdId: number(),
	created_at: date(),
	updated_at: date(),
});

export const CURRICULUM_SCHEMA = object({
	tabs: array(TAB_SCHEMA),
});

export type CURRICULUM_FORM = {
	tabs: Tab[];
};
