import styled from 'styled-components';

export const QuestionContent = styled.div`
	min-width: 1.563rem;
	max-width: 29.563rem;
	font-weight: 600;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	* {
		word-break: break-all;
	}
`;
export const QuestionContentExpanded = styled.div`
	* {
		word-break: break-all;
	}
	min-width: 1.563rem;
	/* max-width: 29.563rem; */
	/* max-width: 29.563rem; */
	font-weight: 600;
	overflow: hidden;
	text-overflow: ellipsis;
	/* white-space: nowrap; */
	word-break: break-all;
`;
