import { RechargeCode } from '@Pages/RechargeCodes/Types/RechargeCodes.types';
import api from '@Services/api';
import { AxiosResponse } from 'axios';
import {
	CRUDBase,
	DeleteSingleEntityPayload,
	GetAllMeta,
	GetSingleEntityPayload,
} from 'src/base/services/services.base';

interface RechargeCodeTabCRUD extends CRUDBase<RechargeCode, RechargeCode> {}
export class RechargeCodeTabRequester implements RechargeCodeTabCRUD {
	private static instance: RechargeCodeTabRequester;
	static getInstance() {
		if (!RechargeCodeTabRequester.instance) {
			RechargeCodeTabRequester.instance = new RechargeCodeTabRequester();
		}
		return RechargeCodeTabRequester.instance;
	}
	baseURL = 'user/profile';
	getAll(
		payload?: GetAllMeta
	): Promise<AxiosResponse<ApiResponse<RechargeCode[]>>>;
	getAll(payload: number): Promise<AxiosResponse<ApiResponse<RechargeCode[]>>>;
	async getAll(
		payload?: any
	): Promise<import('axios').AxiosResponse<ApiResponse<RechargeCode[]>>> {
		return await api.post(
			`${this.baseURL}/${payload.id}/recharge-cards`,
			payload
		);
	}
	get(id: string): Promise<AxiosResponse<ApiResponse<RechargeCode>>>;
	get(id: number): Promise<AxiosResponse<ApiResponse<RechargeCode>>>;
	get(
		id: string | number,
		payload: GetSingleEntityPayload
	): Promise<AxiosResponse<ApiResponse<RechargeCode>>>;
	async get(
		id: any,
		payload?: any
	): Promise<AxiosResponse<ApiResponse<RechargeCode>>> {
		return await api.post(this.baseURL, { uuid: id });
	}

	async create(
		payload: RechargeCode
	): Promise<AxiosResponse<ApiResponse<RechargeCode>>> {
		return await api.post(`${this.baseURL}/create`, payload);
	}
	async update(
		payload: RechargeCode
	): Promise<AxiosResponse<ApiResponse<RechargeCode>>> {
		return await api.put(`${this.baseURL}/update`, payload);
	}
	delete(id: number): Promise<AxiosResponse<ApiResponse<any>>>;
	delete(id: string): Promise<AxiosResponse<ApiResponse<any>>>;
	delete(
		id: string | number,
		payload: DeleteSingleEntityPayload
	): Promise<AxiosResponse<ApiResponse<RechargeCode>>>;
	async delete(
		id: any,
		payload?: any
	): Promise<AxiosResponse<ApiResponse<any>>> {
		return await api.delete(`${this.baseURL}/delete`, {
			data: {
				uuid: id,
			},
		});
	}
}
