import {
	SingleStudentData,
	SingleStudentResponse,
} from '@Services/students/students.res.types';
import { BaseSliceController } from '../../../base/slices/slices.base';
import { StudentsRequester } from '../Services/Students.Requester';
export class StudentsSlice extends BaseSliceController<
	SingleStudentResponse,
	'Students',
	SingleStudentResponse,
	SingleStudentData
> {
	private static instance: StudentsSlice;
	static getInstance() {
		if (!StudentsSlice.instance) {
			StudentsSlice.instance = new StudentsSlice();
		}
		return StudentsSlice.instance;
	}
	constructor() {
		super('Students', StudentsRequester.getInstance());
	}
}

export const {
	studentsGetAll,
	studentsExportData,
	studentsGetSingle,
	studentsGenerate,
} = StudentsSlice.getInstance().getActions();
