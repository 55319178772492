import api from '@Services/api';
import {
	AddressesResponseType,
	EducationalInfoResponseType,
} from '@Services/educationalInfo/educationalInfo.types';

export const getEducationalInfo = () =>
	api.get<EducationalInfoResponseType>('education/info');
export const getAddressesData = () =>
	api.get<AddressesResponseType>('dropdown/addresses');
