import { RolesData } from '@Pages/Roles/Services/roles.res.types';
import React from 'react';
import styled from 'styled-components';

type Props = {
	assignedRoles: Array<RolesData>;
};

const AssignedRoles: React.FC<Props> = ({ assignedRoles }) => {
	return (
		<AssignedCard>
			<Header>Assigned Roles</Header>
			{assignedRoles && assignedRoles.length > 0 ? (
				assignedRoles?.map((_) => <SelectedRole> {_.title} </SelectedRole>)
			) : (
				<NotSelected> No Roles Selected </NotSelected>
			)}
		</AssignedCard>
	);
};

const AssignedCard = styled.div`
	margin-top: 1.875rem;
	width: 16.125rem;
	min-height: 5.813rem;
	padding: 1rem 0.375rem 0.625rem 0.5rem;
	border-radius: 7px;
	background-color: #f5f5f5;
	display: flex;
	flex-direction: column;
	align-items: center;
`;
const Header = styled.div`
	color: #251b33;
	font-size: 1.15rem;
	font-weight: 600;
	margin-bottom: 0.625rem;
	text-transform: capitalize;
`;
const SelectedRole = styled.div`
	width: 15.25rem;
	height: 1.9rem;
	margin: 0.366rem 0;
	background-color: #fff;
	text-align: left;
	color: #251b33;
	font-size: 1rem;
	font-weight: 500;
	padding: 0.313rem 0 0.25rem 0.625rem;
	box-sizing: border-box;
	display: flex;
	align-items: center;
`;
const NotSelected = styled.div`
	color: #241f2c;
	font-size: 1rem;
	font-weight: 500;
	padding: 0.313rem 0 0.25rem 0.625rem;
	margin-top: 10px;
`;

export default AssignedRoles;
