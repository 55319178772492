import addQueryString from '@Utils/addQueryString';
import qs from 'querystring';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import styled from 'styled-components';
import { chipFilterName } from '@Utils/chipFilterName';

interface Props {
	tableFilters: Array<any>;
	setSelectedFilters?: any;
	createFunctionToCall?: any;
	show?: boolean;
	setShow?: React.Dispatch<boolean>;
}
const TableFilterBar: React.FC<Props> = (
	{ tableFilters, setSelectedFilters, createFunctionToCall, setShow, show },
	ref
) => {
	const location = useLocation();
	const history = useHistory();
	const [initialFilters, setInitialFilters] = useState(
		qs.parse(location.search.replace('?', ''))
	);
	const [filtersVal, setFiltersVal] = useState<Array<any>>([]);

	const _handleFilterChange = useCallback(
		(e: any) => {
			const { name, value } = e.target;
			let arr: any = filtersVal.concat({ name, value });
			let filtered: any = Object.values(
				arr.reduce((a: any, c: any) => {
					a[c.name] = c;
					return a;
				}, {})
			);
			setFiltersVal(filtered);
			setInitialFilters((prev) => ({ ...prev, [name]: value }));
			setSelectedFilters(filtered);
		},
		[filtersVal, setSelectedFilters]
	);

	const onApplyFilters = () => {
		const __ = new URLSearchParams(location.search);
		filtersVal.forEach((val) => __.set(`FILTER_${val.name}`, val.value));
		history.push({ search: __.toString() });
	};

	const onResetFilter = useCallback(
		(name: any, value: any) => {
			const filtersSearch = addQueryString(
				location.search,
				`FILTER_${name}`,
				''
			);
			let filterArr: any = filtersVal.filter((x: any) => x.name !== name);
			setFiltersVal(filterArr);
			setSelectedFilters(filterArr);
			setInitialFilters((prev) => ({ ...prev, [name]: '' }));
			const search = addQueryString(filtersSearch, 'page', 1);
			history.push({ search });
		},
		[filtersVal, history, location.search, setSelectedFilters]
	);

	useEffect(() => {
		function theFunctionToCall(name: any, value: any) {
			const filtersSearch = addQueryString(
				location.search,
				`FILTER_${name}`,
				''
			);
			let filterArr: any = filtersVal.filter((x: any) => x.name !== name);
			setFiltersVal(filterArr);
			setSelectedFilters(filterArr);
			setInitialFilters((prev) => ({ ...prev, [name]: '' }));
			const search = addQueryString(filtersSearch, 'page', 1);
			history.push({ search });
		}
		createFunctionToCall(() => theFunctionToCall);
	}, [history, location.search, createFunctionToCall]);

	const _resetFilters = useCallback(async () => {
		let usp = new URLSearchParams(document.location.search);
		const entries = usp.keys();
		let itreator = entries.next();
		const _search = new URLSearchParams();
		while (!itreator.done) {
			if (!itreator.value.includes('FILTER')) {
				_search.append(itreator.value, usp.get(itreator.value) ?? '');
			}
			itreator = entries.next();
		}
		setFiltersVal([]);
		setSelectedFilters([]);
		const prevFilters = { ...initialFilters };
		for (let key in prevFilters) {
			if (key.toString() !== 'page') prevFilters[key] = '';
		}
		setInitialFilters(prevFilters);
		history.push({ search: _search.toString() });
	}, [history, initialFilters]);

	const handleClose = () => {
		setShow && setShow(false);
	};
	const boxRef = useRef<HTMLDivElement>(null);
	const [boxHeight, setBoxHeight] = useState<number>();

	useEffect(() => {
		if (!boxHeight && show) {
			if (boxRef && boxRef.current) {
				setBoxHeight(boxRef.current.getBoundingClientRect().height);
			}
		}
	}, [show]);

	return (
		<>
			{true && (
				<div ref={boxRef}>
					<Paper
						variant="outlined"
						sx={{
							boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
							border: 'solid 1px #ffffff',
							width: 'fit-content',
							position: 'relative',
							maxHeight: show ? '100vh' : '0px',
							transition: 'all ease-in-out 250ms',
							zIndex: show ? '2' : '-1',
							opacity: show ? '1' : '0',
							mb: 1,
							ml: 12,
							mt: -2,
						}}
					>
						<Box
							sx={{
								width: '2.119rem',
								height: '2.119rem',
								padding: '5px 5px 5px',
								ml: 2,
								cursor: 'pointer',
								position: 'absolute',
								top: 0,
								right: 0,
							}}
						>
							<i
								className="tim-icons icon-simple-remove justify-self-end  "
								onClick={handleClose}
							></i>
						</Box>

						<Box
							sx={{
								display: 'flex',
								flexDirection: 'column',
								padding: '0.688rem',
							}}
						>
							{filtersVal.length > 0 && (
								<Box sx={{ m: 1 }}>
									{filtersVal.map((filter) => (
										<StyledChip
											key={filter?.name}
											color="primary"
											variant="outlined"
											label={chipFilterName(tableFilters, filter)}
											onDelete={() =>
												onResetFilter(filter?.name, filter?.value)
											}
										/>
									))}
								</Box>
							)}

							<Box
								sx={{
									display: 'grid',
									gridColumnGap: '2.188rem',
									gridRowGap: '1.563rem',
									gridAutoFlow: 'column',
									gridTemplateRows:
										tableFilters.length > 6
											? 'repeat(2, 1fr) '
											: 'repeat(1, 1fr) ',
									padding: '0 4.375rem',
									gridAutoColumns: 'minmax(100px,1fr)',
								}}
							>
								{tableFilters.map((filter: any, index) => {
									const { name, options, value } = filter;
									return (
										<FormControl
											key={`${name}-${index}-outer`}
											sx={{
												width: '100%',
												marginRight: '1.438rem',
												marginTop: '10px',
											}}
										>
											<InputLabel
												id="filter label"
												sx={{
													fontSize: '1rem',
												}}
											>
												{name}
											</InputLabel>
											<Select
												label={name}
												variant="standard"
												name={value}
												id={name + 'field'}
												onChange={_handleFilterChange}
												value={initialFilters[value] ?? ''}
											>
												{options?.map((opt: any) => (
													<MenuItem
														key={opt.name + opt.value}
														value={opt?.value}
													>
														{opt.name}
													</MenuItem>
												))}
											</Select>
										</FormControl>
									);
								})}
							</Box>

							<Box
								sx={{
									display: 'flex',
									justifyContent: 'flex-end',
									marginTop: '1.438rem',
								}}
							>
								<StyledBtn
									onClick={onApplyFilters}
									variant="contained"
									sx={{
										boxShadow: '0 3px 3px 0 rgba(0, 0, 0, 0.38)',
										backgroundColor: '#2196f3',
									}}
									disabled={filtersVal.length > 0 ? false : true}
								>
									Apply
								</StyledBtn>
								<StyledBtn
									variant={filtersVal.length > 0 ? 'outlined' : 'contained'}
									sx={{
										boxShadow: '0 3px 3px 0 rgba(0, 0, 0, 0.38)',
									}}
									onClick={_resetFilters}
									disabled={filtersVal.length > 0 ? false : true}
								>
									Reset
								</StyledBtn>
							</Box>
						</Box>
					</Paper>
				</div>
			)}
		</>
	);
};

export default TableFilterBar;

export const StyledChip = styled(Chip)`
	margin: 0 0.625rem 0.813rem 0;
	color: #2196f3;
	border: solid 0.063rem #2196f3;
	font-size: 0.75rem;
	font-weight: 600;
	font-family: Montserrat;
	font-style: normal;
	.css-i4bv87-MuiSvgIcon-root {
		color: #2196f3;
	}
	.css-6od3lo-MuiChip-label {
		padding-bottom: 0.75rem;
		padding-top: 0.75rem;
		margin: 0 0.813rem;
	}
`;

export const StyledBtn = styled(Button)`
	margin-left: 0.625rem;
	border-radius: 0.25rem;
	font-family: Roboto;
	font-size: 0.875rem;
	font-weight: 500;
`;
