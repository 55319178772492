import {
	ClassroomLanguage,
	ClassroomSubType,
	ClassroomType,
	ClassroomStatus,
	PaymentMethodPair,
} from '../Types/Classroom.types';

export const ClassroomTypes: ClassroomType[] = ['online', 'center'];
export const ClassroomLanguages: ClassroomLanguage[] = [
	'Arabic',
	'English',
	'French',
	'German',
];
export const ClassroomSubTypes: ClassroomSubType[] = [
	'class',
	'exam',
	'revision',
];

export const PaymentMethods: PaymentMethodPair[] = [
	{
		value: '*',
		title: 'Both',
	},
	{
		value: 'wallet',
		title: 'Wallet',
	},
	{
		value: 'scratchCard',
		title: 'Scratch Card',
	},
];
export const ClassroomStatuses: ClassroomStatus[] = ['open', 'closed', 'soon'];

export const AnalyticsSlices = [
	{ value: 'day', label: 'Day' },
	// { value: "week", label: "Week" },
	{ value: 'month', label: 'Month' },
	{ value: 'year', label: 'Year' },
];
