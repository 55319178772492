import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
const CustomSwal = withReactContent(Swal);

interface SwalOptions {
	title?: string | HTMLElement;
	titleText?: string;
	showCancelButton?: boolean;
	showDenyButton?: boolean;
	confirmButtonText?: string;
	confirmButtonAriaLabel?: string;
	cancelButtonText?: string;
	cancelButtonAriaLabel?: string;
	onConfirm?: Function;
	onDeny?: Function;
}

const ConfirmationSweetAlert = async (options: SwalOptions) => {
	const result = await CustomSwal.fire({
		title: options.title || 'Are you sure?',
		showCancelButton: options.showCancelButton || true,
		confirmButtonText:
			options.confirmButtonText || `<i class="fa fa-thumbs-up"></i> Confirm `,
		confirmButtonAriaLabel:
			options.confirmButtonAriaLabel || 'Thumbs up, great!',
		cancelButtonText:
			options.cancelButtonText || '<i class="fa fa-thumbs-down"></i> Cancel',
		cancelButtonAriaLabel: 'Thumbs down',
	});

	if (result.isConfirmed) {
		if (options.onConfirm) options.onConfirm();
	} else if (result.isDenied) {
		if (options.onDeny) options.onDeny();
	}
};

export default ConfirmationSweetAlert;
