import { MaterialIconsType } from '@Assets/globalStyles';
import { Icon, IconProps } from '@mui/material';
import React, { useEffect } from 'react';

type Props = {
	mIconType?: MaterialIconsType;
} & IconProps;
const EdIcon: React.FC<Props> = ({
	mIconType = 'Regular',
	children,
	...props
}) => {
	const getClassName = React.useMemo(() => {
		const baseClassName = 'material-icons';
		if (mIconType === 'Regular') return baseClassName;
		return `${baseClassName}-${mIconType.toLowerCase()}`;
	}, [mIconType]);

	return (
		<>
			<Icon className={getClassName} {...props}>
				{children}
			</Icon>
		</>
	);
};

export default EdIcon;
