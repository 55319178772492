import api from '@Services/api';
import { HeadersResponse } from '@Services/generics/response';
import { AxiosResponse } from 'axios';
import {
	CRUDBase,
	DeleteSingleEntityPayload,
	GenerateCardMeta,
	GetAllMeta,
	GetSingleEntityPayload,
} from 'src/base/services/services.base';
import { ScratchCard } from '../Types/ScratchCards.types';

export class ScratchCardsRequester implements CRUDBase<ScratchCard> {
	private static instance: ScratchCardsRequester;
	static getInstance() {
		if (!ScratchCardsRequester.instance) {
			ScratchCardsRequester.instance = new ScratchCardsRequester();
		}
		return ScratchCardsRequester.instance;
	}
	baseURL = 'scratchcards/';
	getAll(
		payload?: GetAllMeta
	): Promise<AxiosResponse<ApiResponse<ScratchCard[]>>>;
	getAll(payload: number): Promise<AxiosResponse<ApiResponse<ScratchCard[]>>>;
	async getAll(
		payload?: any
	): Promise<AxiosResponse<ApiResponse<ScratchCard[]>>> {
		return await api.post(`${this.baseURL}all`, payload);
	}
	get(id: string): Promise<AxiosResponse<ApiResponse<ScratchCard>>>;
	get(id: number): Promise<AxiosResponse<ApiResponse<ScratchCard>>>;
	get(
		id: string | number,
		payload: GetSingleEntityPayload
	): Promise<AxiosResponse<ApiResponse<ScratchCard>>>;
	get(
		id: any,
		payload?: any
	): Promise<import('axios').AxiosResponse<ApiResponse<ScratchCard>>> {
		throw new Error('Method not implemented.');
	}
	create(
		payload: Omit<ScratchCard, 'id'>
	): Promise<AxiosResponse<ApiResponse<ScratchCard>>> {
		throw new Error('Method not implemented.');
	}
	update(
		payload: ScratchCard
	): Promise<AxiosResponse<ApiResponse<ScratchCard>>> {
		throw new Error('Method not implemented.');
	}
	delete(id: number): Promise<AxiosResponse<ApiResponse<any>>>;
	delete(id: string): Promise<AxiosResponse<ApiResponse<any>>>;
	delete(
		id: string | number,
		payload: DeleteSingleEntityPayload
	): Promise<AxiosResponse<ApiResponse<any>>>;
	async delete(
		id: any,
		payload?: any
	): Promise<import('axios').AxiosResponse<ApiResponse<any>>> {
		return await api.delete(`${this.baseURL}delete`, { data: { id } });
	}
	async export(payload: GetAllMeta): Promise<AxiosResponse<string>> {
		return await api.post(`${this.baseURL}all`, { ...payload, export: true });
	}
	async generate?(payload: GenerateCardMeta): Promise<AxiosResponse<string>> {
		return await api.post(`${this.baseURL}generate`, payload);
	}
}
