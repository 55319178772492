import { array, boolean, number, object, string, StringSchema } from 'yup';
import { Flatten } from '@Utils/types/Flatten';
import { ValidateOptions, AnyObject } from 'yup/lib/types';

const re =
	/^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm;

export const ADD_NEWSFEED = object({
	content: string().required(),
	redirection_url: string().matches(re, 'URL is not valid').required(),
	photo: string().required(),
	is_active: boolean(),
});

export const EDIT_NEWSFEED = object({
	id: number().required(),
}).concat(ADD_NEWSFEED);

export const FULL_SCHEMA = ADD_NEWSFEED.concat(EDIT_NEWSFEED);
export type AddNewsFeedForm = typeof ADD_NEWSFEED.__outputType;
export type EditNewsFeedForm = typeof EDIT_NEWSFEED.__outputType;

export type FlattenNewsFeedForm = Flatten<AddNewsFeedForm>;
