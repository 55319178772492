import { useTypedSelector } from '@Features/store';

import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Switch, useParams, useRouteMatch } from 'react-router-dom';
import TestOverview from './Pages/TestOverview';
import { testsGetSingle } from './Slice/TestSlice';

export const Test: React.FC = () => {
	const dispatch = useDispatch();
	const { id } = useParams<{ id: string }>();
	useEffect(() => {
		dispatch(
			testsGetSingle({
				id: Number(id),
			})
		);
	}, []);
	const {
		currentEntity,
		loaders: { getSingle },
	} = useTypedSelector((state) => state.Tests);
	return <>{currentEntity && getSingle === 'fulfilled' && <TestOverview />}</>;
};

export default Test;
