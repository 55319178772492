import DateTimeFormatter from '@Components/DateTimeFormatter/DateTimeFormatter';
import MainTable from '@Components/MainTable';
import { MainTableRow } from '@Components/MainTable/MainTable';
import { onPageChangeRequestMeta } from '@Components/MainTable/MainTable.types';
import NoResults from '@Components/NoResults/NoResults';
import { useTypedSelector } from '@Features/store';
import { TableCell, Tooltip } from '@mui/material';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { walletLogsGetAll } from './Slices/WalletLogs.Slices';

const TABLE_HEADS = [
	{ displayName: 'old amount', fieldName: 'old_amount' },
	{ displayName: 'amount', fieldName: 'amount' },
	{ displayName: 'new amount', fieldName: 'new_amount' },
	{ displayName: 'description', fieldName: 'description' },
	{ displayName: 'account', fieldName: 'account' },
	{ displayName: 'created at', fieldName: 'created_at' },
];

const WalletTable = () => {
	const { currentEntity } = useTypedSelector((state) => state.Students);
	const { fetchAll, filters, meta } = useTypedSelector(
		(state) => state.WalletLogs
	);
	const dispatch = useDispatch();
	const onPageChangeRequest = useCallback(
		({
			activePage,
			endDate,
			filters,
			query,
			sortBy,
			startDate,
			perPage,
		}: onPageChangeRequestMeta) => {
			dispatch(
				walletLogsGetAll({
					page: activePage,
					perPage: perPage ?? 10,
					sortBy,
					filters,
					query,
					from: startDate,
					to: endDate,
					id: currentEntity?.id as any,
				})
			);
		},
		[dispatch]
	);

	return (
		<div>
			<MainTable
				total={meta?.total ?? 0}
				dateFilter
				pageNum={meta?.current_page}
				tableFilters={filters}
				tableHeads={TABLE_HEADS}
				totalPages={meta?.last_page ?? 0}
				RowsperPage={Number(meta?.per_page)}
				$hasActions
				onPageChangeRequest={onPageChangeRequest}
				renderItems={() => {
					if (fetchAll?.length === 0)
						return (
							<MainTableRow>
								<TableCell colSpan={TABLE_HEADS.length}>
									<NoResults />
								</TableCell>
							</MainTableRow>
						);
					return fetchAll?.map((wallet) => {
						return (
							<MainTableRow key={wallet.id} hover>
								<TableCell>{wallet.old_amount}</TableCell>
								<TableCell>{wallet.amount}</TableCell>
								<TableCell>{wallet.new_amount}</TableCell>
								<Tooltip title={wallet.description} placement="top">
									<TableCell>{wallet.description}</TableCell>
								</Tooltip>
								<Tooltip title={wallet.account} placement="top">
									<TableCell>{wallet.account}</TableCell>
								</Tooltip>
								<Tooltip
									title={<DateTimeFormatter date={wallet.created_at} />}
									placement="top"
								>
									<TableCell>
										<DateTimeFormatter date={wallet.created_at} />
									</TableCell>
								</Tooltip>
								<TableCell />
							</MainTableRow>
						);
					});
				}}
			/>
		</div>
	);
};

export default WalletTable;
