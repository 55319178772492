import api from '@Services/api';
import { AxiosResponse } from 'axios';
import {
	CRUDBase,
	DeleteSingleEntityPayload,
	GenerateCardMeta,
	GenerateCodesMeta,
	GetAllMeta,
	GetSingleEntityPayload,
} from 'src/base/services/services.base';
import { AttendancesCount } from '../Types/Attendaces.types';

interface AttendancesCountCRUD
	extends CRUDBase<AttendancesCount, AttendancesCount> {}
export class AttendancesCountRequester implements AttendancesCountCRUD {
	delete(id: number): Promise<AxiosResponse<ApiResponse<any>>>;
	delete(id: string): Promise<AxiosResponse<ApiResponse<any>>>;
	delete(
		id: string | number,
		payload?: DeleteSingleEntityPayload | undefined
	): Promise<AxiosResponse<ApiResponse<any>>>;
	delete(
		id: unknown,
		payload?: unknown
	): Promise<AxiosResponse<ApiResponse<any>>> {
		throw new Error('Method not implemented.');
	}
	export?(payload: GetAllMeta): Promise<AxiosResponse<string>> {
		throw new Error('Method not implemented.');
	}
	getAll(
		payload?: GetAllMeta | undefined
	): Promise<AxiosResponse<ApiResponse<AttendancesCount[]>>>;
	getAll(
		payload: number
	): Promise<AxiosResponse<ApiResponse<AttendancesCount[]>>>;
	getAll(
		payload?: unknown
	): Promise<AxiosResponse<ApiResponse<AttendancesCount[]>>> {
		throw new Error('Method not implemented.');
	}
	generate?(
		payload: GenerateCodesMeta | GenerateCardMeta
	): Promise<AxiosResponse<string>> {
		throw new Error('Method not implemented.');
	}
	toggleActive?(
		payload: AttendancesCount
	): Promise<AxiosResponse<ApiResponse<AttendancesCount>>> {
		throw new Error('Method not implemented.');
	}
	process?(id: number): Promise<AxiosResponse<ApiResponse<any>>> {
		throw new Error('Method not implemented.');
	}
	create(
		payload: Omit<AttendancesCount, 'id'>
	): Promise<AxiosResponse<ApiResponse<AttendancesCount>>> {
		throw new Error('Method not implemented.');
	}
	update(
		payload: AttendancesCount
	): Promise<AxiosResponse<ApiResponse<AttendancesCount>>> {
		throw new Error('Method not implemented.');
	}
	private static instance: AttendancesCountRequester;
	static getInstance() {
		if (!AttendancesCountRequester.instance) {
			AttendancesCountRequester.instance = new AttendancesCountRequester();
		}
		return AttendancesCountRequester.instance;
	}
	baseURL = 'classroom/analytics/attendance/';
	get(id: string): Promise<AxiosResponse<ApiResponse<AttendancesCount>>>;
	get(id: number): Promise<AxiosResponse<ApiResponse<AttendancesCount>>>;
	get(
		id: string | number,
		payload: GetSingleEntityPayload
	): Promise<AxiosResponse<ApiResponse<AttendancesCount>>>;
	async get(
		id: any,
		tId?: any,
		payload?: any
	): Promise<AxiosResponse<ApiResponse<AttendancesCount>>> {
		return await api.post(`${this.baseURL}count`, {
			classroom_id: id,
			student_id: tId,
		});
	}
}
