import React from 'react';
import { Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import ScratchCardsTable from './Pages/ScratchCardsTable/ScratchCardsTable';

const ScratchCards = () => {
	const { path, url } = useRouteMatch();
	return (
		<div>
			<Switch>
				<Route path={url} component={ScratchCardsTable} />
			</Switch>
		</div>
	);
};

export default ScratchCards;
