import { IconButton, IconButtonProps } from '@mui/material';
import { ExtraPropsBase, getVariant } from '@Styled/theme';
import React from 'react';
import styled from 'styled-components';

const EdIconButton: React.FC<IconButtonProps & ExtraPropsBase> = ({
	children,
	...props
}) => {
	return <StyledIconButton {...props}>{children}</StyledIconButton>;
};
export default EdIconButton;
const StyledIconButton = styled(IconButton)`
	${() =>
		getVariant({
			variantPath: 'buttons.icon',
			prop: 'edcolor',
		})}
`;
