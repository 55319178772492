import {
	DataCard,
	DataCardLabel,
	DataCardValue,
} from '@Components/UI/Cards/DataCard/DataCard';
import { FlexLayout } from '@eduact/ed-system';
import { useTypedSelector } from '@Features/store';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { studentWebinarsCountGetSingle } from '../Slices/WebinarsCount.slice';

type Props = {
	classroom_id: number;
};
const StudentWebinarsCount: React.VoidFunctionComponent<Props> = ({
	classroom_id,
}) => {
	const { currentEntity: Student } = useTypedSelector(
		(state) => state.Students
	);
    const { currentEntity } = useTypedSelector(
		(state) => state.StudentWebinarsCount
	);
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(
			studentWebinarsCountGetSingle({
				id: '',
				payload: { classroom_id, student_id: Student.id },
			})
		);
	}, []);
	return (
		<FlexLayout width={'100%'} gridGap={'1rem'}>
			<StyledDataCard color="primary">
				<StyledDataCardLabel>Registered</StyledDataCardLabel>
				<StyledDataCardValue>{currentEntity?.registered}</StyledDataCardValue>
			</StyledDataCard>
			<StyledDataCard color="malachite">
				<StyledDataCardLabel>Attended</StyledDataCardLabel>
				<StyledDataCardValue>{currentEntity?.attended}</StyledDataCardValue>
			</StyledDataCard>
			<StyledDataCard color="error">
				<StyledDataCardLabel>Not attended</StyledDataCardLabel>
				<StyledDataCardValue>{currentEntity?.not_attended}</StyledDataCardValue>
			</StyledDataCard>
			<StyledDataCard color="warning">
				<StyledDataCardLabel>Not Started</StyledDataCardLabel>
				<StyledDataCardValue>{currentEntity.not_started}</StyledDataCardValue>
			</StyledDataCard>
		</FlexLayout>
	);
};

export default StudentWebinarsCount;

const StyledDataCard = styled(DataCard)`
	flex: 1;
	padding-top: 1.75rem;
	padding-bottom: 1.75rem;
`;
const StyledDataCardLabel = styled(DataCardLabel)`
	font-size: 1.25rem;
	font-weight: bold;
`;
const StyledDataCardValue = styled(DataCardValue)`
	font-size: 1.75rem;
	font-weight: 600;
`;
