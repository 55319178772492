import api from '@Services/api';
import { HeadersResponse } from '@Services/generics/response';
import { AxiosResponse } from 'axios';
import {
	CRUDBase,
	DeleteSingleEntityPayload,
	GenerateCardMeta,
	GetAllMeta,
	GetSingleEntityPayload,
} from 'src/base/services/services.base';
import { PromoCode } from '../Types/PromoCodes.types';

export class PromoCodesRequester implements CRUDBase<PromoCode> {
	private static instance: PromoCodesRequester;
	static getInstance() {
		if (!PromoCodesRequester.instance) {
			PromoCodesRequester.instance = new PromoCodesRequester();
		}
		return PromoCodesRequester.instance;
	}
	baseURL = 'promocodes/';
	getAll(
		payload?: GetAllMeta
	): Promise<AxiosResponse<ApiResponse<PromoCode[]>>>;
	getAll(payload: number): Promise<AxiosResponse<ApiResponse<PromoCode[]>>>;
	async getAll(
		payload?: any
	): Promise<AxiosResponse<ApiResponse<PromoCode[]>>> {
		return await api.post(`${this.baseURL}fetch`, payload);
	}
	get(id: string): Promise<AxiosResponse<ApiResponse<PromoCode>>>;
	get(id: number): Promise<AxiosResponse<ApiResponse<PromoCode>>>;
	get(
		id: string | number,
		payload: GetSingleEntityPayload
	): Promise<AxiosResponse<ApiResponse<PromoCode>>>;
	async get(
		id: any,
		payload?: any
	): Promise<import('axios').AxiosResponse<ApiResponse<PromoCode>>> {
		return await api.post(`${this.baseURL}get`, { id });
	}
	async create(
		payload: Omit<PromoCode, 'id'>
	): Promise<AxiosResponse<ApiResponse<PromoCode>>> {
		return await api.post(`${this.baseURL}`, payload);
	}
	async update(
		payload: PromoCode
	): Promise<AxiosResponse<ApiResponse<PromoCode>>> {
		return await api.put(`${this.baseURL}`, payload);
	}
	delete(id: number): Promise<AxiosResponse<ApiResponse<any>>>;
	delete(id: string): Promise<AxiosResponse<ApiResponse<any>>>;
	delete(
		id: string | number,
		payload: DeleteSingleEntityPayload
	): Promise<AxiosResponse<ApiResponse<any>>>;
	async delete(
		id: any,
		payload?: any
	): Promise<import('axios').AxiosResponse<ApiResponse<any>>> {
		return await api.delete(`${this.baseURL}`, { data: { id } });
	}
	async export(payload: GetAllMeta): Promise<AxiosResponse<string>> {
		return await api.post(`${this.baseURL}all`, { ...payload, export: true });
	}
	async generate?(payload: GenerateCardMeta): Promise<AxiosResponse<string>> {
		return await api.post(`${this.baseURL}`, payload);
	}
}
