import { useTypedSelector } from '@Features/store';
import { Skeleton } from '@mui/material';
import { FlexLayout } from '@Styled/utilities';

import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import CourseStats from '../Components/CourseStats';
import {
	getCourseOverviewReport,
	getCourseReportGraph,
} from '../Slices/ReportOverview.slice';
import {} from '@nivo/pie';
import CourseReportLayout from '../Components/CourseReportLayout/CourseReportLayout';
const CourseOverview = () => {
	const dispatch = useDispatch();
	const { currentCourse } = useTypedSelector((state) => state.classroom);
	const { loaders } = useTypedSelector((state) => state.CourseReports);
	useEffect(() => {
		console.log({ currentCourse });
		if (currentCourse) {
			dispatch(getCourseOverviewReport(currentCourse.id));
			dispatch(
				getCourseReportGraph({
					id: currentCourse.id,
					to: new Date(),
					slice: 'day',
					from: (() => {
						const startDate = new Date();
						startDate.setDate(startDate.getDate() - 14);
						return startDate;
					})(),
				})
			);
		}
	}, []);

	const isLoading = useMemo(() => {
		return loaders.report === 'pending';
	}, [loaders.report]);
	return (
		<FlexLayout width="100%" gridGap={'1rem'} alignItems={'stretch'}>
			{isLoading ? (
				<Skeleton height={150} animation="wave" width="100%" />
			) : (
				<CourseReportLayout />
			)}
		</FlexLayout>
	);
};

export default CourseOverview;
