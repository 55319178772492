import api from '@Services/api';
import { AxiosResponse } from 'axios';
import {
	CRUDBase,
	DeleteSingleEntityPayload,
	GetAllMeta,
	GetSingleEntityPayload,
} from 'src/base/services/services.base';
import { Attempt } from '../Types/TestSummary.types';

interface TestSummaryCRUD extends CRUDBase<Attempt, Attempt> {}
export class TestSummaryRequester implements TestSummaryCRUD {
	getAll(
		payload?: GetAllMeta | undefined
	): Promise<AxiosResponse<ApiResponse<Attempt[]>>>;
	getAll(payload: number): Promise<AxiosResponse<ApiResponse<Attempt[]>>>;
	getAll(payload?: unknown): Promise<AxiosResponse<ApiResponse<Attempt[]>>> {
		throw new Error('Method not implemented.');
	}
	create(
		payload: Omit<Attempt, 'id'>
	): Promise<AxiosResponse<ApiResponse<Attempt>>> {
		throw new Error('Method not implemented.');
	}
	update(payload: Attempt): Promise<AxiosResponse<ApiResponse<Attempt>>> {
		throw new Error('Method not implemented.');
	}
	delete(id: number): Promise<AxiosResponse<ApiResponse<any>>>;
	delete(id: string): Promise<AxiosResponse<ApiResponse<any>>>;
	delete(
		id: string | number,
		payload?: DeleteSingleEntityPayload | undefined
	): Promise<AxiosResponse<ApiResponse<any>>>;
	delete(
		id: unknown,
		payload?: unknown
	): Promise<AxiosResponse<ApiResponse<any>>> {
		throw new Error('Method not implemented.');
	}
	private static instance: TestSummaryRequester;
	static getInstance() {
		if (!TestSummaryRequester.instance) {
			TestSummaryRequester.instance = new TestSummaryRequester();
		}
		return TestSummaryRequester.instance;
	}
	baseURL = '/test';
	get(id: string): Promise<AxiosResponse<ApiResponse<Attempt>>>;
	get(id: number): Promise<AxiosResponse<ApiResponse<Attempt>>>;
	get(
		id: string | number,
		payload: GetSingleEntityPayload
	): Promise<AxiosResponse<ApiResponse<Attempt>>>;
	async get(
		attempt_id: number | any,
		payload?: any
	): Promise<AxiosResponse<ApiResponse<Attempt>>> {
		return await api.post(`${this.baseURL}/summary`, { attempt_id });
	}
}
