import { Stack } from '@mui/material';
import {
	CancelBtn,
	SaveBtn,
} from '@Pages/Roles/pages/AddRole/Components/addRoleInfo';
import React from 'react';
import { FormState } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { EditCategoryForm } from '../Schema/CategoryInfo.schema';

type Props = {
	getValues: any;
	onSave: (form: EditCategoryForm) => void;
	formState: FormState<EditCategoryForm>;
	edit: boolean;
};

const GroupedBtn: React.FC<Props> = ({
	getValues,
	onSave,
	formState,
	edit,
}) => {
	const history = useHistory();
	return (
		<Stack sx={{ marginTop: '2.625rem' }} spacing={2} direction="row-reverse">
			<SaveBtn
				variant="contained"
				onClick={() => {
					const data = getValues();
					onSave(data);
				}}
				disabled={(edit && !formState.isValid) || !formState.isDirty}
			>
				SAVE
			</SaveBtn>
			<CancelBtn
				variant="contained"
				onClick={() => history.push('/dashboard/category')}
				disabled={!formState.isDirty}
			>
				CANCEL
			</CancelBtn>
		</Stack>
	);
};

export default GroupedBtn;
