import EdFormControl from '@Components/UI/Inputs/EdFormControl/EdFormControl';

import { useTypedSelector } from '@Features/store';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Checkbox, Icon, MenuItem } from '@mui/material';
import {
	ClassroomTypes,
	ClassroomLanguages,
	ClassroomSubTypes,
	PaymentMethods,
	ClassroomStatuses,
} from '@Pages/ClassRooms/Constants/Classroom.constants';
import { Divider, GridLayout, Spacer } from '@Styled/utilities';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { DevTool } from '@hookform/devtools';
import { Flatten } from '@Utils/types/Flatten';
import { omit } from 'lodash';
import EdUploadFile from '@Components/UI/Inputs/EdUploadFile/EdUploadFile';
import { useFormUpdater } from '@Hooks/Forms/useFormUpdater';
import { useSnackbar } from '@Providers/useSnackbar';
import { ClassroomRequester } from '@Pages/ClassRooms/Services/Classroom/Requests/Classroom.req';
import {
	ClassroomForm,
	FULL_SCHEMA,
} from '@Pages/ClassRooms/Schema/ClassroomForm.schema';
import { useHistory } from 'react-router-dom';
import { EducationalInfoControlType } from '@Pages/ClassRooms/Types/Classroom.types';
import { ChipFlexLayout } from '@Pages/ClassRooms/Components/AddEditClassroom.styled';
import SelectChip from '@Components/UI/Inputs/SelectChip/SelectChip';
import { getClassroomByLabel } from '@Features/classrooms/classroomSlice';
import { useDispatch } from 'react-redux';
import { DASHBOARD_BASE_ROUTE } from '@Constants/routes';

type Props = {
	onHasAdmissionChange: React.Dispatch<boolean>;
};
const EditClassroomBasicInfo: React.FC<Props> = ({ onHasAdmissionChange }) => {
	const history = useHistory();
	const { dropdownInstructors, dropdownCategories } = useTypedSelector(
		(state) => state.dropdowns
	);
	const { displaySnackbar } = useSnackbar();
	const dispatch = useDispatch();

	const { currentClassroom } = useTypedSelector((state) => state.classroom);
	const { dropdownCourses } = useTypedSelector((state) => state.dropdowns);
	const { educational_info, educational_type } = useTypedSelector(
		(state) => state.educational
	);
	const auth = useTypedSelector((state) => state.auth);
	const hasEditPermission =
		auth.user.type === 'super' || auth.permissions.has('u-cl');

	const defaultValues = React.useMemo<ClassroomForm>(() => {
		if (currentClassroom) {
			const defaultT: ClassroomForm = {
				...currentClassroom,
				educationLanguages: currentClassroom.educationLanguages
					.filter((_) => _.id !== undefined)
					.map((_) => _.id) as number[],
				educationSections: currentClassroom.educationSections
					.filter((_) => _.id !== undefined)
					.map((_) => _.id) as number[],
				educationTypes: currentClassroom.educationTypes
					.filter((_) => _.id !== undefined)
					.map((_) => _.id) as number[],
				educationYears: currentClassroom.educationYears
					.filter((_) => _.id !== undefined)
					.map((_) => _.id) as number[],
			};

			return defaultT;
		} else {
			return {} as ClassroomForm;
		}
	}, []);

	const { formState, watch, control, setValue, getValues, resetField } =
		useForm<ClassroomForm>({
			resolver: yupResolver(FULL_SCHEMA),
			mode: 'all',
			defaultValues,
		});

	const watchTypes = watch('educationTypes');
	const watchYears = watch('educationYears');
	const watchLanguages = watch('educationLanguages');
	const watchSections = watch('educationSections');

	const [updater] = useFormUpdater({
		data: defaultValues,
		setValue: setValue,
		keys: [
			'educationSections',
			'educationLanguages',
			'educationTypes',
			'educationSections',
		],
	});

	useEffect(() => {
		updater();
	}, [updater]);

	const years = React.useMemo(() => {
		if (!watchTypes || watchTypes.length === 0) {
			setValue('educationYears', []);
			return [];
		}
		let flatYears = educational_info
			.filter((_) => _.id && watchTypes.includes(_.id))
			.map(({ educationYears }) => educationYears)
			.flat();
		flatYears = flatYears.filterDups('id');

		return flatYears;
	}, [watchTypes?.length, educational_info]);

	const languages = React.useMemo(() => {
		if (!watchTypes || watchTypes.length === 0) {
			setValue('educationLanguages', []);
			return [];
		}
		let flatLanguages = educational_info
			.filter((_) => _.id && watchTypes.includes(_.id))
			.map(({ educationLanguages }) => educationLanguages)
			.flat();
		flatLanguages = flatLanguages.filterDups('id');

		return flatLanguages;
	}, [watchTypes?.length, educational_info]);

	const sections = React.useMemo(() => {
		if (!watchYears) return;
		if (watchYears.length === 0) {
			setValue('educationSections', []);
			return [];
		}
		let flatYears = educational_info
			.map(({ educationYears }) => educationYears)
			.flat()
			.filterDups('id')
			.filter((_) => watchYears.includes(_.id ?? -1));

		let flatSections = flatYears
			.map(({ educationSections }) => educationSections)
			.flat()
			.filterDups('id');

		return flatSections;
	}, [watchYears?.length, educational_info]);

	const isChildrenDisabled = React.useMemo(() => {
		return !watchTypes || (watchTypes && watchTypes.length === 0);
	}, [watchTypes]);

	const onDeleteValue = (
		control: EducationalInfoControlType,
		value: number
	) => {
		const values = getValues()[control];
		if (!values) return;
		values.splice(
			values.findIndex((_) => _ === value),
			1
		);
		setValue(control, values, { shouldValidate: true });
	};

	const hasAdmissionWatch = watch('has_admission');
	useEffect(() => {
		if (hasAdmissionWatch !== undefined) {
			onHasAdmissionChange(hasAdmissionWatch);
		}
	}, [hasAdmissionWatch, onHasAdmissionChange]);

	const onSave = async (form: ClassroomForm) => {
		try {
			const flatten: Flatten<ClassroomForm> = {
				...form,
				educationYears: form.educationYears,
				educationLanguages: form.educationLanguages,
				educationSections: form.educationSections,
				educationTypes: form.educationTypes,
			};
			const normalized = omit(flatten, 'educationalInfo');
			const {
				data: { data },
			} = await ClassroomRequester.getInstance().update(normalized);
			dispatch(
				getClassroomByLabel({ label: currentClassroom?.label as string })
			);
			displaySnackbar(
				'success',
				`Classroom ${data.title} Updated Successfully`
			);
		} catch (e) {
			const msg = e.response?.data.message;
			displaySnackbar('error', msg);
		}
	};
	useEffect(() => {
		if (!watchYears.includes(6)) {
			resetField('educationSections', { keepError: false });
		}
	}, [watchSections, resetField, watchYears]);

	useEffect(() => {
		if (performance.navigation.type == 1) {
			history.push({
				pathname: `${DASHBOARD_BASE_ROUTE}/classrooms/classroom/${currentClassroom?.label}/edit`,
				hash: '#info',
			});
		}
		return () => {
			performance.navigation.type == null;
		};
	}, [performance.navigation.type]);

	return (
		<>
			<SectionLayout
				gridGap={'2rem'}
				gridTemplateColumns={{ sm: 'repeat(2,1fr)' }}
			>
				<EdFormControl
					control={control}
					required
					name="title"
					label={'Title'}
					disabled={!hasEditPermission}
				/>

				<EdFormControl
					control={control}
					required
					select
					name="instructor_id"
					label={'Instructor'}
					disabled={!hasEditPermission}
				>
					{dropdownInstructors.map((_, index) => {
						return (
							<MenuItem
								value={_.id}
							>{`${_.first_name} ${_.last_name}`}</MenuItem>
						);
					})}
				</EdFormControl>
				<EdFormControl
					control={control}
					required
					select
					name="category_id"
					label={'Category'}
					disabled={!hasEditPermission}
				>
					{dropdownCategories.map((_, index) => {
						return <MenuItem value={_.id}>{`${_.name}`}</MenuItem>;
					})}
				</EdFormControl>

				<EdFormControl
					control={control}
					required
					multiline
					name="description"
					label={'Description'}
					disabled={!hasEditPermission}
				/>
				<EdFormControl
					control={control}
					multiline
					name="prerequisites"
					label={'Prerequisites'}
					disabled={!hasEditPermission}
				/>
			</SectionLayout>
			<Divider />
			<SectionLayout>
				<EdFormControl
					control={control}
					required
					name="label"
					label={'Label'}
					disabled={!hasEditPermission}
				/>

				<EdFormControl
					control={control}
					required
					select
					name="type"
					label={'Type'}
					disabled={!hasEditPermission}
				>
					{ClassroomTypes.map((_, index) => {
						return (
							<MenuItem key={`${index}-${_}`} value={_}>
								{_}
							</MenuItem>
						);
					})}
				</EdFormControl>
				<EdFormControl
					control={control}
					required
					select
					name="sub_type"
					label={'Sub Type'}
					disabled={!hasEditPermission}
				>
					{ClassroomSubTypes.map((_, index) => {
						return (
							<MenuItem key={`${index}-${_}`} value={_}>
								{_}
							</MenuItem>
						);
					})}
				</EdFormControl>

				<EdFormControl
					control={control}
					required
					select
					name="language"
					label={'Language'}
					disabled={!hasEditPermission}
				>
					{ClassroomLanguages.map((_, index) => {
						return (
							<MenuItem key={`${index}-${_}`} value={_}>
								{_}
							</MenuItem>
						);
					})}
				</EdFormControl>
				<EdFormControl
					control={control}
					required
					name="code"
					label={'Code'}
					disabled={!hasEditPermission}
				/>
			</SectionLayout>
			<Divider />
			<SectionLayout>
				<EdFormControl
					control={control}
					required
					select
					name="payment_methods_allowed"
					label={'Payment Methods'}
					disabled={!hasEditPermission}
				>
					{PaymentMethods.map((_, index) => {
						return (
							<MenuItem key={`${index}-${_.value}`} value={_.value}>
								{_.title}
							</MenuItem>
						);
					})}
				</EdFormControl>
				<EdFormControl
					control={control}
					required
					select
					name="status"
					label={'Status'}
					disabled={!hasEditPermission}
				>
					{ClassroomStatuses.map((_, index) => {
						return (
							<MenuItem key={`${index}-${_}`} value={_}>
								{_}
							</MenuItem>
						);
					})}
				</EdFormControl>
				<SectionLayout gridTemplateColumns={'repeat(1,1fr)'}>
					{/* <div> */}
					<EdFormControl
						control={control}
						switch
						name="active"
						label={'Active'}
						disabled={!hasEditPermission}
					/>
					<EdFormControl
						control={control}
						switch
						name="accessible"
						label={'Accessible'}
						disabled={!hasEditPermission}
					/>
					<EdFormControl
						control={control}
						switch
						name="has_admission"
						label={'Has Admission'}
						disabled={!hasEditPermission}
					/>
					<EdFormControl
						control={control}
						switch
						name="auto_accept_admission"
						label="Auto approve requests"
						disabled={!hasEditPermission}
					/>
					<EdFormControl
						control={control}
						switch
						name="admission_status"
						label={'Admission Status'}
						disabled={!hasEditPermission}
					/>
					<EdFormControl
						control={control}
						switch
						name="skip_registration_fees"
						label={'Skip Registration Fees'}
					/>
					
					{/* </div> */}
				</SectionLayout>
				<EdFormControl
					control={control}
					name="current_course"
					select
					label="Current course"
					disabled={!hasEditPermission}
				>
					{dropdownCourses.map((_, index) => {
						return (
							<MenuItem key={`${index}-${_.id}`} value={_.id}>
								{_.name}
							</MenuItem>
						);
					})}
				</EdFormControl>
			</SectionLayout>
			<Divider />
			<SectionLayout>
				<EdUploadFile
					required
					folder="classrooms"
					uploadLabel="UPLOAD"
					uploadIcon={<Icon>add</Icon>}
					control={control}
					name="thumbnail"
					label="Cover"
					optimize={false}
					disabled={!hasEditPermission}
				/>
				<EdFormControl
					control={control}
					width="4.75rem"
					type="number"
					inputMode="numeric"
					name="weight"
					label={'Weight'}
					disabled={!hasEditPermission}
				/>
			</SectionLayout>
			<Divider label="Educational Info." />
			<SectionLayout>
				<EdFormControl
					control={control}
					required
					select
					name="educationTypes"
					multiple
					disabled={!hasEditPermission}
					renderValues={(value) => {
						return (
							<div>
								{watchTypes &&
									watchTypes.map((id) => {
										const name = educational_type.find(
											(_) => _.value === id
										)?.name;
										return (
											<SelectChip
												onDelete={() => onDeleteValue('educationTypes', id)}
												label={name}
											/>
										);
									})}
							</div>
						);
					}}
					SelectProps={{
						multiple: true,
						renderValue: (selected) => {
							return (
								<ChipFlexLayout>
									{watchTypes &&
										watchTypes.map((id) => {
											const name = educational_type.find(
												(_) => _.value === id
											)?.name;
											return <>{name},</>;
										})}
								</ChipFlexLayout>
							);
						},
					}}
					label={'Type'}
				>
					{educational_type.map((_, index) => {
						return (
							<MenuItem value={_.value}>
								<Checkbox checked={watchTypes?.includes(_.value)} />
								{_.name}
							</MenuItem>
						);
					})}
				</EdFormControl>

				<EdFormControl
					control={control}
					required
					disabled={isChildrenDisabled || !hasEditPermission}
					select
					multiple
					renderValues={(value) => {
						return (
							<div>
								{watchYears &&
									watchYears.map((id) => {
										const name = years.find((_) => _.id === id)?.name;
										return (
											<SelectChip
												onDelete={() => onDeleteValue('educationYears', id)}
												label={name}
											/>
										);
									})}
							</div>
						);
					}}
					SelectProps={{
						multiple: true,
						renderValue: (selected) => {
							return (
								<ChipFlexLayout>
									{watchYears &&
										watchYears.map((i, index) => {
											const name = years.find((_) => _.id === i)?.name;
											return <>{name},</>;
										})}
								</ChipFlexLayout>
							);
						},
					}}
					name="educationYears"
					label={'Year'}
				>
					{years.map((_, index) => {
						return (
							<MenuItem key={`ed-year-${_.id}`} value={_.id}>
								<Checkbox
									checked={
										_.id !== undefined &&
										watchYears &&
										watchYears.includes(_.id)
									}
								/>

								{_.name}
							</MenuItem>
						);
					})}
				</EdFormControl>
				<EdFormControl
					control={control}
					required
					disabled={isChildrenDisabled || !hasEditPermission}
					select
					multiple
					renderValues={(selected) => {
						return (
							<div>
								{watchLanguages &&
									watchLanguages.map((i, index) => {
										const name = languages.find((_) => _.id === i)?.name;
										return (
											<>
												<SelectChip
													onDelete={() =>
														onDeleteValue('educationLanguages', i)
													}
													label={name}
												/>
											</>
										);
									})}
							</div>
						);
					}}
					SelectProps={{
						multiple: true,
						renderValue: (selected) => {
							return (
								<ChipFlexLayout>
									{watchLanguages?.map((i, index) => {
										const name = languages.find((_) => _.id === i)?.name;
										return <>{name},</>;
									})}
								</ChipFlexLayout>
							);
						},
					}}
					name="educationLanguages"
					label={'Language'}
				>
					{languages.map((_, index) => {
						return (
							<MenuItem key={`ed-lng-${_.id}`} value={_.id}>
								<Checkbox
									checked={
										_.id !== undefined &&
										watchLanguages &&
										watchLanguages.includes(_.id)
									}
								/>
								{_.name}
							</MenuItem>
						);
					})}
				</EdFormControl>

				{sections && sections.length > 0 && (
					<EdFormControl
						control={control}
						required
						disabled={isChildrenDisabled || !hasEditPermission}
						select
						multiple
						renderValues={(selected) => {
							return (
								<div>
									{watchSections?.map((i, index) => {
										const name = sections.find((_) => _.id === i)?.name;
										return (
											<SelectChip
												onDelete={() =>
													onDeleteValue('educationSections', i as number)
												}
												label={name}
											/>
										);
									})}
								</div>
							);
						}}
						SelectProps={{
							multiple: true,
							renderValue: (selected) => {
								return (
									<ChipFlexLayout>
										{watchSections &&
											watchSections.map((i, index) => {
												const name = sections.find((_) => _.id === i)?.name;
												return <>{name},</>;
											})}
									</ChipFlexLayout>
								);
							},
						}}
						name="educationSections"
						label={'Section'}
					>
						{sections.map((_, index) => {
							return (
								<MenuItem key={`ed-lng-${_.id}`} value={_.id}>
									<Checkbox
										checked={
											_.id !== undefined &&
											watchSections &&
											watchSections.includes(_.id)
										}
									/>
									{_.name}
								</MenuItem>
							);
						})}
					</EdFormControl>
				)}
			</SectionLayout>
			{hasEditPermission && (
				<ActiosnContainer>
					<Button
						disabled={!formState.isDirty}
						variant="contained"
						color="warning"
						onClick={() => history.push('/dashboard/classrooms')}
					>
						Cancel
					</Button>
					<Spacer mx={'.5rem'} />
					<Button
						onClick={() => {
							onSave(getValues());
						}}
						disabled={!formState.isDirty || !formState.isValid}
						variant="contained"
					>
						Update
					</Button>
				</ActiosnContainer>
			)}
			<DevTool control={control} />
		</>
	);
};
const UploadWrapper = styled.div`
	display: grid;
	grid-template-columns: 70% 30%;
	grid-gap: 1rem;
`;
const SectionLayout = styled(GridLayout)`
	/* margin-right: 5.5rem; */
	margin-right: 2.5rem;
	grid-gap: 2rem;
`;
const ActiosnContainer = styled.div`
	display: flex;
	justify-content: flex-end;
	margin-top: 8.313rem;
`;

export default EditClassroomBasicInfo;
