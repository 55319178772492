import EdFormControl, {
	TextFieldLabel,
} from '@Components/UI/Inputs/EdFormControl/EdFormControl';
import { DASHBOARD_BASE_ROUTE } from '@Constants/routes';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, MenuItem } from '@mui/material';
import { useSnackbar } from '@Providers/useSnackbar';
import { Divider, FlexLayout, GridLayout } from '@Styled/utilities';
import React, { useEffect } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { NOTE_SCHEMA } from '../../Schema/Assistant.Schema';
import { Note } from '../../Types/Notes.types';
import { useAppThunkDispatch, useTypedSelector } from '@Features/store';
import EdNumericInput from '@Components/UI/Inputs/EdNumericInput/EdNumericInput';
import EdUploadFile from '@Components/UI/Inputs/EdUploadFile/EdUploadFile';
import { DevTool } from '@hookform/devtools';
import { EdFormControlLabel } from '@Components/UI/Inputs/EdFormControlLabel/EdFormControlLabel';
import { getEducationalInfo } from '@Features/classrooms/educationalSlice';
import { notesCreate } from '../../Slices/Notes.Slices';
import { useRouteProvider } from '@Providers/Routes/RoutesProvider';

const AddNote = () => {
	const history = useHistory();
	const { displaySnackbar } = useSnackbar();

	const dispatch = useAppThunkDispatch();
	const { currentInstructor } = useTypedSelector((state) => state.instructor);
	const { getValues, control, formState, watch, ...formMethods } =
		useForm<Note>({
			resolver: yupResolver(NOTE_SCHEMA),
			mode: 'all',
			defaultValues: {
				instructor_id: currentInstructor?.id,
			},
		});

	const router = useRouteProvider();
	const onSave = async (form: Note) => {
		try {
			const { data, message } = await dispatch(notesCreate(form)).unwrap();
			displaySnackbar('success', message);
			router?.goToParent();
			// history.push(`${DASHBOARD_BASE_ROUTE}/notes/edit/note/${data.id}`);
		} catch (e) {
			const msg = e?.data.message;
			displaySnackbar('error', msg);
		}
	};

	useEffect(() => {
		dispatch(getEducationalInfo());
	}, []);

	const { educational_year, educational_info } = useTypedSelector(
		(state) => state.educational
	);
	const years = React.useMemo(() => {
		const flatYears = educational_info
			.filter((_) => _.id)
			.map(({ educationYears }) => educationYears)
			.flat();
		return flatYears.filterDups('id');
	}, [educational_info]);

	const { isDirty, isValid } = formState;
	return (
		<FormProvider
			control={control}
			getValues={getValues}
			formState={formState}
			watch={watch}
			{...formMethods}
		>
			<form>
				<DevTool control={control} />
				<GridLayout
					gridGap={'2rem'}
					gridTemplateColumns={{ sm: 'repeat(2,1fr)' }}
					marginRight="5.5rem"
					width="75%"
					padding="2.438rem 1.25rem"
				>
					<EdFormControl control={control} name="name" label="Name" required />
					<EdFormControl control={control} name="code" label="Code" required />
					<Controller
						control={control}
						name="price"
						render={({ field }) => (
							<FlexLayout mb="1rem">
								<TextFieldLabel required>Price</TextFieldLabel>
								<EdNumericInput
									size="small"
									label="Price"
									{...field}
									required
								/>
							</FlexLayout>
						)}
					/>
					<Controller
						control={control}
						name="old_price"
						render={({ field }) => (
							<FlexLayout mb="1rem">
								<TextFieldLabel>Old Price</TextFieldLabel>
								<EdNumericInput size="small" label="Old Price" {...field} />
							</FlexLayout>
						)}
					/>
					<EdFormControl
						control={control}
						required
						name="description"
						label="Description"
					/>
					<EdFormControl
						control={control}
						required
						name="purchase_link"
						label="Purchase Link"
					/>
					<EdUploadFile
						label={'Photo'}
						required
						folder="classrooms"
						name="photo"
						control={control}
						uploadLabel="Upload"
						aspectRation={1 / 1.414}
					/>
					<EdFormControl
						required
						control={control}
						name="education_year_id"
						label={'Study Year'}
						select
					>
						{years.map((year) => (
							<MenuItem key={year.id} value={year.id}>
								{year.name}
							</MenuItem>
						))}
					</EdFormControl>
				</GridLayout>

				<FlexLayout
					justifyContent="flex-end"
					gridGap="1rem"
					width="75%"
					marginTop="2.938rem"
				>
					<Button
						variant="contained"
						color="warning"
						disabled={!isDirty}
						onClick={() => router?.goToParent()}
					>
						CANCEL
					</Button>
					<Button
						variant="contained"
						disabled={!isDirty || !isValid}
						onClick={() => {
							const data = getValues();
							onSave(data);
						}}
					>
						Save
					</Button>
				</FlexLayout>
			</form>
		</FormProvider>
	);
};

export default AddNote;
