import { BreadcrumbContext } from '@Components/UI/Layout/Layout';
import { StudentTabs } from '@Constants/routes';
import useInvalidateRouteGroup from '@Hooks/Routes/useInvalidateRouteGroup';
import useRouteGroup from '@Hooks/Routes/useRouteGroup';
import React, { Component, useContext, useEffect, useState } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import StudentsTable from './StudentsTable';
const Students = () => {
	const [bar, setBar] = useState<boolean>(false);
	const { path, url } = useRouteMatch();
	const routeGroup = useRouteGroup('students');
	const { isCurrent, setURL, setCurrentRouteGroup } = useInvalidateRouteGroup({
		mode: 'end',
		baseURL: '/dashboard/students',
		routeGroup,
		routePlacement: 2,
	});
	const { removeLink } = useContext(BreadcrumbContext);
	useEffect(() => {
		if (isCurrent) {
			removeLink?.('students');
		}
	}, [isCurrent, removeLink]);

	useEffect(() => {
		// setCurrentRouteGroup?.(StudentTabs);
		// setURL(url);
		if (bar) {
			setCurrentRouteGroup?.(StudentTabs);
			setURL(url);
		}
	}, [bar, setURL]);

	useEffect(() => {
		if (bar) {
			setBar(false);
		}
	}, [bar]);

	return (
		<>
			{routeGroup && !Array.isArray(routeGroup) && (
				<Switch>
					<Route exact path={path}>
						<StudentsTable setBar={setBar} />
					</Route>
					{StudentTabs.routes.map((route, index) => {
						return (
							<Route
								path={`${path}/${route.route}`}
								key={`${route.route}-${index}`}
							>
								{route.component}
							</Route>
						);
					})}
				</Switch>
			)}
		</>
	);
};

export default Students;
