import { BaseSliceController } from '@Base/slices/slices.base';
import { TestReportsRequester } from '../Services/TestsReports.requester';
import { CourseTestsSummary } from '../Types/TestReports.types';

export class TestsReportsSlice extends BaseSliceController<
	CourseTestsSummary,
	'TestsReports',
	CourseTestsSummary
> {
	static instance: TestsReportsSlice;

	static getInstance() {
		if (!TestsReportsSlice.instance) {
			TestsReportsSlice.instance = new TestsReportsSlice();
		}
		return TestsReportsSlice.instance;
	}

	constructor() {
		super('TestsReports', TestReportsRequester.getInstance());
	}
}

export const { testsReportsGetAll } =
	TestsReportsSlice.getInstance().getActions();
