import { BaseSliceController } from '@Base/slices/slices.base';
import { SingleTestReportRequester } from '../Services/SingleTestReport.requester';
import { CourseSingleTest } from '../Types/TestReports.types';

export class SingleTestReportSlice extends BaseSliceController<
	CourseSingleTest,
	'SingleTestReport',
	CourseSingleTest
> {
	static instance: SingleTestReportSlice;

	static getInstance() {
		if (!SingleTestReportSlice.instance) {
			SingleTestReportSlice.instance = new SingleTestReportSlice();
		}
		return SingleTestReportSlice.instance;
	}

	constructor() {
		super('SingleTestReport', SingleTestReportRequester.getInstance());
	}
}

export const { singleTestReportGetAll } =
	SingleTestReportSlice.getInstance().getActions();
