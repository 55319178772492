import React, { createContext, FC, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
type RouteContextType = {
	goToParent: () => void;
	setParent?: React.Dispatch<string>;
	path: string;
};

const RouteContext = createContext<RouteContextType | null>(null);
export const RouteProvider: FC<{ path: string }> = ({ children, path }) => {
	const [parent, setParent] = useState<string | undefined>();
	const history = useHistory();

	const goToParent = () => {
		if (path !== undefined) {
			history.push(path);
		}
	};

	return (
		<RouteContext.Provider value={{ goToParent, setParent, path }}>
			{children}
		</RouteContext.Provider>
	);
};

export const useRouteProvider = () => useContext(RouteContext);
export const useGotoParentRoute = () => {
	const context = useContext(RouteContext);
	if (context === undefined || context === null) {
		throw new Error('useGotoParentRoute is not within MainContext');
	} else {
		return context.goToParent;
	}
};
