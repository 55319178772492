import { Stack } from '@mui/material';
import {
	CancelBtn,
	SaveBtn,
} from '@Pages/Roles/pages/AddRole/Components/addRoleInfo';
import React from 'react';
import { FormState } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { AdminForm } from '../Schema/AdminsInfo.schema';

type Props = {
	getValues?: any;
	onSave?: (form: AdminForm) => void;
	formState: FormState<AdminForm>;
};

const GroupedBtns: React.FC<Props> = ({ getValues, onSave, formState }) => {
	const history = useHistory();

	return (
		<>
			<Stack sx={{ width: '69rem' }} spacing={2} direction="row-reverse">
				<SaveBtn
					variant="contained"
					disabled={!formState.isValid || !formState.isDirty}
					onClick={() => {
						if (onSave && getValues) {
							const data = getValues();
							onSave(data);
						}
					}}
					type="submit"
				>
					SAVE
				</SaveBtn>
				<CancelBtn
					variant="contained"
					disabled={!formState.isDirty}
					onClick={() => history.push('/dashboard/admins')}
				>
					CANCEL
				</CancelBtn>
			</Stack>
		</>
	);
};

export default GroupedBtns;
