import { useTypedSelector } from '@Features/store';
import {
	Button,
	ButtonGroup,
	FormControl,
	Grow,
	MenuItem,
	MenuList,
	Modal,
	Paper,
	Popper,
	TextField,
} from '@mui/material';
import { FlexLayout, GridLayout, Spacer } from '@Styled/utilities';
import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { CloneRequester } from './Services/Clone.requester';
import { useSnackbar } from '@Providers/useSnackbar';
import { unitsGetAll } from '../Contents/Slices/Units.slice';
import { useDispatch } from 'react-redux';
import { DropButton } from '../Web Content/Pages/AddWebContent/AddWebContent';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { ContentConfig } from '../../Constants/CourseContent.constants';
import {
	fetchCoursesDropdown,
	fetchUnitsDropdown,
	resetClassroomDropdowns,
} from '@Features/dropdowns/dropdownsSlice';
import { ContentType } from '../../Types/CourseContent.types';
import { Controller, useForm } from 'react-hook-form';
import { object } from 'yup';
import { number } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
	DropdownCourse,
	DropdownUnit,
} from '@Features/dropdowns/dropdownsSlice.types';
import { DevTool } from '@hookform/devtools';

const schema = object({
	course: number(),
	classroom: number(),
	unit: number(),
});
type Props = {
	open: boolean;
	handleModelClose: React.Dispatch<boolean>;
};

type Form = {
	classroom: number | null;
	course: number | null;
	unit: number | null;
};
const CloneModal: FC<Props> = ({ open, handleModelClose }) => {
	const { displaySnackbar } = useSnackbar();
	const dispatch = useDispatch();
	const anchorRef = React.useRef<HTMLDivElement>(null);
	const [openButton, setOpenButton] = useState(false);
	const [allCourses, setAllCourses] = useState<
		Array<DropdownCourse> | undefined
	>([]);
	const [allUnits, setAllUnits] = useState<Array<DropdownUnit> | undefined>([]);

	const {
		control,
		formState: { dirtyFields },
		reset,
		watch,
		setValue,
	} = useForm<Form>({
		resolver: yupResolver(schema),
		defaultValues: {
			course: null,
			classroom: null,
			unit: null,
		},
	});

	const classroom = watch('classroom');
	const course = watch('course');
	const unit = watch('unit');

	const classrooms = useTypedSelector(
		(state) => state.dropdowns.dropdownClassrooms
	);
	const courses = useTypedSelector((state) => state.dropdowns.dropdownCourses);

	const dropUnits = useTypedSelector((state) => state.dropdowns.dropdownUnits);

	const { currentCourse } = useTypedSelector((state) => state.classroom);

	useEffect(() => {
		if (classroom) {
			dispatch(fetchCoursesDropdown(classroom));
		}
	}, [classroom]);

	useEffect(() => {
		if (course) {
			dispatch(fetchUnitsDropdown(course));
		}
	}, [course]);
	useEffect(() => {
		if (courses) {
			setAllCourses(courses);
		}
	}, [courses]);
	useEffect(() => {
		if (dropUnits) {
			setAllUnits(dropUnits);
		}
	}, [dropUnits]);

	const handleToggle = () => {
		setOpenButton((prevOpen) => !prevOpen);
	};

	const handleClose = (event: Event) => {
		if (anchorRef.current?.contains(event.target as HTMLElement)) {
			return;
		}
		setOpenButton(false);
	};

	const handleCloneRequest = async (fullReset: boolean = true) => {
		try {
			if (currentCourse?.id && unit) {
				console.log(unit);
				const {
					data: { message },
				} = await CloneRequester.getInstance().clone(currentCourse?.id, unit);
				dispatch(unitsGetAll(currentCourse?.id as number));
				displaySnackbar('success', message);
				if (fullReset) dispatch(resetClassroomDropdowns());
				reset({
					classroom: fullReset ? null : classroom,
					course: fullReset ? null : course,
					unit: null,
				});
			}
		} catch (e) {
			const msg = e.response?.data.message || 'Unable To Clone Unit';
			displaySnackbar('error', msg);
		}
	};
	const onResetClone = async () => {
		await handleCloneRequest(false);
		setOpenButton(false);
	};

	const onClone = async () => {
		await handleCloneRequest();
		handleModelClose(false);
	};

	return (
		<Modal
			open={open}
			onClose={() => {
				handleModelClose(false);
			}}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<ModelContainer>
				<DevTool control={control} />
				<FlexLayout
					flexDirection="column"
					gridGap="0.75rem"
					padding="2.313rem 1.375rem"
				>
					<Title>Clone unit from another course</Title>
					<Text>
						Please select the desired unit to be cloned to this course:
					</Text>
					<InputsWrapper gridGap={'2rem'}>
						<FlexLayout
							flexDirection="column"
							gridGap="0.4rem"
							alignItems="center"
							mt="1rem"
						>
							<Controller
								control={control}
								name="classroom"
								render={({ field: { onChange, ...others } }) => (
									<FormControl variant="outlined">
										<TextField
											select
											{...others}
											onChange={(e) => {
												setValue('course', null);
												setValue('unit', null);
												onChange(e);
											}}
											size="small"
											label={classroom ? null : 'Select Classroom'}
										>
											{classrooms?.map((cr, index) => (
												<MenuItem key={`${cr.title}-${index}`} value={cr.id}>
													{cr.title}
												</MenuItem>
											))}
										</TextField>
									</FormControl>
								)}
							/>
						</FlexLayout>
						<FlexLayout
							flexDirection="column"
							gridGap="0.4rem"
							alignItems="center"
							mt="1rem"
						>
							<Controller
								control={control}
								name="course"
								render={({ field, fieldState: { isTouched } }) => (
									<TextField
										select
										size="small"
										label={course ? null : 'Select Course'}
										{...field}
										error={
											dirtyFields.classroom &&
											allCourses &&
											allCourses?.length <= 0
										}
										helperText={
											dirtyFields.classroom &&
											allCourses &&
											allCourses.length <= 0 &&
											'No courses found'
										}
									>
										{allCourses &&
											allCourses?.map((course, index) => {
												if (course.id !== currentCourse?.id)
													return (
														<MenuItem
															key={`${course.name}-${index}`}
															value={course.id}
														>
															{course.name}
														</MenuItem>
													);
											})}
									</TextField>
								)}
							/>
						</FlexLayout>
						<FlexLayout
							flexDirection="column"
							gridGap="0.4rem"
							alignItems="center"
							mt="1rem"
						>
							<Controller
								control={control}
								name="unit"
								render={({ field }) => (
									<TextField
										{...field}
										size="small"
										select
										label={unit ? null : 'Select Unit'}
										error={
											dirtyFields.course && allUnits && allUnits?.length <= 0
										}
										helperText={
											dirtyFields.course &&
											allUnits &&
											allUnits.length <= 0 &&
											'No Units found'
										}
									>
										{allUnits &&
											allUnits?.map((unit, index) => {
												return (
													<MenuItem
														key={`${unit?.name}-${index}`}
														value={unit?.id}
													>
														<FlexLayout
															alignItems="initial"
															gridGap="0.5rem"
															margin=" 0 auto"
															marginTop="0.2rem"
														>
															<span>
																{
																	ContentConfig[
																		unit?.unitType?.name as ContentType
																	]?.icon
																}
															</span>
															<Name>{unit?.name}</Name>
														</FlexLayout>
													</MenuItem>
												);
											})}
									</TextField>
								)}
							/>
						</FlexLayout>
					</InputsWrapper>
				</FlexLayout>
				<FlexLayout justifyContent="flex-end" marginBottom="3rem">
					<Button
						variant="text"
						color="warning"
						onClick={() => {
							reset();
							handleModelClose(false);
							setAllCourses(undefined);
							setAllUnits(undefined);
						}}
					>
						Cancel
					</Button>
					<Spacer mx="0.5rem" />
					<Group variant="contained" ref={anchorRef} aria-label="split button">
						<CloneButton
							onClick={() => {
								onClone();
							}}
							variant="outlined"
							disabled={!course || !classroom || !unit}
						>
							Clone
						</CloneButton>
						<Drop
							size="small"
							aria-controls={openButton ? 'split-button-menu' : undefined}
							aria-expanded={openButton ? 'true' : undefined}
							onClick={handleToggle}
							variant="outlined"
							disabled={!course || !classroom || !unit}
						>
							<ArrowDropDownIcon />
						</Drop>
					</Group>
					<Popper
						sx={{
							zIndex: 1,
						}}
						open={openButton}
						anchorEl={anchorRef.current}
						role={undefined}
						transition
						disablePortal
					>
						{({ TransitionProps, placement }) => (
							<Grow
								{...TransitionProps}
								style={{
									transformOrigin:
										placement === 'bottom' ? 'center top' : 'left bottom',
								}}
							>
								<Paper>
									<ClickAwayListener onClickAway={handleClose}>
										<MenuList id="split-button-menu" autoFocusItem>
											<MenuItemStyle
												key={1}
												onClick={() => {
													onResetClone();
												}}
												disabled={!course || !classroom || !unit}
											>
												Clone and add another
											</MenuItemStyle>
										</MenuList>
									</ClickAwayListener>
								</Paper>
							</Grow>
						)}
					</Popper>
				</FlexLayout>
			</ModelContainer>
		</Modal>
	);
};

const ModelContainer = styled.div`
	height: 21.563rem;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	border-radius: 4px;
	border: 0;
	background-color: #ffffff;
	outline: none;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 0 5rem 0 1.5rem;
`;
const Title = styled.p`
	font-size: 1.3rem;
	font-weight: 600;
	color: rgba(0, 0, 0, 0.6);
`;
const Text = styled.p`
	font-size: 0.8rem;
	font-weight: normal;
	color: #251b33;
	padding: 0 2.625rem;
	margin-bottom: 1.5rem;
`;

const MenuItemStyle = styled(MenuItem)`
	min-width: 10.313rem;
	font-size: 0.875rem;
	padding: 0 0.75rem;
`;
const CloneButton = styled(Button)`
	color: #6c63ff !important;
	border-color: #6c63ff !important;
	font-size: 0.875rem;
	font-weight: 500;
	&:hover {
		border-color: #6c63ff !important;
	}
	&:disabled {
		color: rgba(0, 0, 0, 0.26) !important;
		border: 1px solid rgba(0, 0, 0, 0.12) !important;
	}
`;
const Drop = styled(DropButton)`
	color: #6c63ff;
	border-color: #6c63ff;
	&:hover {
		border-color: #6c63ff;
	}
`;
const Group = styled(ButtonGroup)`
	box-shadow: none;
	border-radius: unset;
	height: 2.25rem;
	.MuiButtonGroup-grouped {
		min-width: 40px !important;
	}
`;
const Name = styled.span`
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	width: 100px;
`;

const InputsWrapper = styled(GridLayout)`
	grid-template-columns: repeat(3, 33%);
`;
export default CloneModal;
