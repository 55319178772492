import { TabContext } from '@mui/lab';
import { Tab, TabProps, Tabs, TabsProps } from '@mui/material';
import { FlexLayout } from '@Styled/utilities';
import React, { createContext, FC, useContext, useMemo, useState } from 'react';
import {
	Route,
	Switch,
	useHistory,
	useLocation,
	useRouteMatch,
} from 'react-router-dom';
import styled from 'styled-components';
import {
	display,
	DisplayProps,
	flexbox,
	FlexboxProps,
	layout,
	LayoutProps,
} from 'styled-system';
import EdIcon from '../Utilities/EdIcon/EdIcon';
import {
	RoutedTabsProps,
	SingleTabContentProps,
	TabsContentProps,
} from './RoutedTabs/RoutedTabs.types';
type RoutedTabsContextType = {
	baseURL: string;
	withRouter: boolean;
};
const RoutedTabsContext = createContext<RoutedTabsContextType>({
	baseURL: '',
	withRouter: true,
});
// Single TabMenu Item
const TabItem: React.FC<TabProps> = ({ children, ...props }) => {
	const history = useHistory();
	const { pathname, hash } = useLocation();
	const { baseURL, withRouter } = useContext(RoutedTabsContext);
	const isSelected = React.useMemo(() => {
		if (withRouter) return pathname.includes(props.value) ? 1 : 0;
		else return props.value === hash ? 1 : 0;
	}, [props.value, pathname, hash, withRouter]);
	return (
		<StyledTab
			$isSelected={isSelected}
			onClick={() => {
				if (withRouter) {
					history.replace({ pathname: `${baseURL}/${props.value}` });
				} else {
					history.replace({ hash: props.value });
				}
			}}
			{...props}
		/>
	);
};

//  Tabs Content
const TabsContent: React.FC<TabsContentProps> = ({ value, children }) => {
	const { pathname, hash: urlHash } = useLocation();
	const { baseURL, withRouter } = useContext(RoutedTabsContext);
	return (
		<>
			{React.Children.map(children, (child) => {
				if (React.isValidElement(child)) {
					const { hash, alwaysVisible } = child.props as SingleTabContentProps;
					// const isSelected = pathname.includes(hash);
					const isSelected = () => {
						if (withRouter) return pathname.includes(hash);
						else return urlHash === hash;
					};
					const clonedChild = React.cloneElement(child, {
						key: hash,
					});
					const path = `${baseURL}/${hash?.toString()}`;
					return (
						<>
							<SingleTabContentWrapper
								$isSelected={isSelected() || alwaysVisible ? true : false}
							>
								{withRouter && (
									<Switch>
										<Route path={path}>
											<>{clonedChild}</>
										</Route>
									</Switch>
								)}
								{!withRouter && <>{child}</>}
							</SingleTabContentWrapper>
						</>
					);
				}
			})}
		</>
	);
};

//  Single Tab Content
const SingleTabContent: React.FC<SingleTabContentProps> = ({
	hash,
	children,
}) => {
	return <>{children}</>;
};
// Tabs Interface
interface TabsComposition {
	Wrapper: typeof TabsWrapper;
	Item: typeof TabItem;
	ItemIcon: typeof TabIcon;
	ItemTitle: typeof TabTitle;
	Content: typeof TabsContent;
	ContentItem: typeof SingleTabContent;
	Tabs: typeof TabsMenu;
}

type ParentRoutedTabsProps = {
	children: React.ReactElement[];
	withRouter?: boolean;
	baseURL?: string;
} & LayoutProps;

// Main Tabs Component
const RoutedTabs: React.FC<ParentRoutedTabsProps> & TabsComposition = ({
	children,
	withRouter,
	baseURL,
	...props
}) => {
	const { url, path } = useRouteMatch();

	return (
		<RoutedTabsContext.Provider
			value={{
				baseURL: baseURL ?? url,
				withRouter: withRouter ? true : false,
			}}
		>
			<TabsWrapper {...props}>{children}</TabsWrapper>
		</RoutedTabsContext.Provider>
	);
};
// Tabs Menu
const TabsMenu: React.FC<TabsProps & RoutedTabsProps> = ({
	children,
	...props
}) => {
	const tabIndex = React.useMemo(() => {
		return props.value;
	}, [props.value]);

	return (
		<TabContext value={tabIndex}>
			<StyledTabs value={tabIndex} {...props}>
				{children}
			</StyledTabs>
		</TabContext>
	);
};

export default RoutedTabs;
// Utilities Components
const TabsWrapper = styled.div<FlexboxProps & DisplayProps & LayoutProps>`
	display: flex;
	flex-direction: row-reverse;
	${flexbox};
	${display};
	${layout}
`;

const TabIcon = styled(EdIcon)`
	width: 60px;
	height: 60px;
	border-radius: 50%;
	place-content: center;
	display: grid !important;
	font-size: 1.875rem;
`;
const TabTitle = styled.span`
	font-weight: 600;
	margin: 0 1rem;
`;

export const StyledTab = styled(Tab).attrs<TabProps>({
	disableTouchRipple: true,
})<{ $isSelected: number }>`
	border-radius: 10px;
	padding: 12px 10px;
	justify-content: flex-start;
	display: flex;
	flex-direction: row;
	background-color: ${(props) => (props.$isSelected === 1 ? '#f3f3f3' : '')};
	${TabTitle} {
		color: ${(props) =>
			props.$isSelected ? props.theme.palette.primary.main : 'rgba(0,0,0,0.6)'};
	}
	${TabIcon} {
		background: ${(props) =>
			props.$isSelected ? props.theme.palette.primary.main : '#f3f3f3'};
		color: ${(props) => (props.$isSelected ? '#FFF' : '#251b33')};
	}
`;

const SingleTabContentWrapper = styled.div<{ $isSelected: boolean }>`
	display: ${(props) => (props.$isSelected ? 'block' : 'none')};
	width: 100%;
`;
export const StyledTabs = styled(Tabs)`
	/* width: 20rem; */
	min-width: 20rem;
	.MuiTabs-indicator {
		display: none;
	}
`;

// Assign Interface Components
RoutedTabs.Item = TabItem;
RoutedTabs.Content = TabsContent;
RoutedTabs.ItemIcon = TabIcon;
RoutedTabs.ItemTitle = TabTitle;
RoutedTabs.Wrapper = TabsWrapper;
RoutedTabs.Tabs = TabsMenu;
RoutedTabs.ContentItem = SingleTabContent;
