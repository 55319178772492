import { useTypedSelector } from '@Features/store';
import React from 'react';
import { Route, RouteProps, Redirect } from 'react-router-dom';

const PrivateRoute: React.FC<RouteProps> = ({ ...props }) => {
	const isAuth = useTypedSelector((state) => state.auth.isAuth);

	if (isAuth) {
		return <Route {...props} />;
	} else {
		return <Redirect to="/" />;
	}
};

export default PrivateRoute;
