import { useAppThunkDispatch, useTypedSelector } from '@Features/store';
import { promoCodesGetSingle } from '@Pages/PromoCodes/Slices/PromoCodes.slice';
import GenerateCodes from '@Pages/PromoCodes/components/GenerateCode';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

export const UpdatePromoCode = () => {
	const { id } = useParams<{ id: string }>();

	const {
		loaders: { getSingle },
		currentEntity,
	} = useTypedSelector((s) => s.PromoCodes);

	const dispatch = useAppThunkDispatch();

	useEffect(() => {
		dispatch(promoCodesGetSingle({ id: id }));
	}, []);

	return <>{getSingle === 'fulfilled' && currentEntity && <GenerateCodes data={currentEntity as any} />}</>;
};
