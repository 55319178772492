import React, { useState } from 'react';
import CollapedRoles from './CollapedRoles';
import { Control } from 'react-hook-form';
import { RolesData } from '@Pages/Roles/Services/roles.res.types';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import styled from 'styled-components';

type Props = {
	groupRoles: RolesData;
	onSelect: (selected: boolean) => void;
	onRoleSelect: (selected: boolean, value: number) => void;
	selected: boolean;
	currentRole: Array<number>;
};

const CollapseContainer: React.FC<Props> = ({
	groupRoles,
	onSelect,
	onRoleSelect,
	selected: sel,
	currentRole,
}) => {
	const [open, setOpen] = useState(false);
	const select = currentRole.find((_) => _ === groupRoles.id) ? true : false;

	const handleClick = () => {
		setOpen(!open);
	};

	return (
		<>
			<ListWrap>
				<>
					<List
						key={groupRoles?.id}
						component="nav"
						aria-labelledby="nested-list-subheader"
						sx={{
							boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
							backfround: '#fff',
							borderRadius: '5px',
						}}
					>
						<ListItemButton>
							<NameWrapper>
								<ListItemIcon>
									<Checkbox
										edge="start"
										checked={select ? true : false}
										onClick={() => {
											onRoleSelect(!select, groupRoles?.id);
										}}
										inputProps={{
											'aria-labelledby': `checkbox-list-label-${groupRoles?.id}`,
										}}
									/>
								</ListItemIcon>

								<ListItemText
									primary={groupRoles?.title}
									onClick={handleClick}
								/>
							</NameWrapper>
							<SelectedWrapper onClick={handleClick}>
								{open ? (
									<GroupedIcon>
										<ExpandLess />
									</GroupedIcon>
								) : (
									<GroupedIcon>
										<ExpandMore />
									</GroupedIcon>
								)}
							</SelectedWrapper>
						</ListItemButton>
						{groupRoles &&
							groupRoles?.permissions?.map((per) => {
								return (
									<div key={`perm-${per.id}`}>
										<CollapedRoles permission={per} open={open} />
									</div>
								);
							})}
					</List>
				</>
			</ListWrap>
		</>
	);
};

const ListWrap = styled.div`
	display: flex;
	flex-direction: column;
	gap: 2rem;
	margin-bottom: 2rem;
	width: 56.5rem;
`;

const GroupedIcon = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 2.188rem;
`;

const NameWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 1.25rem;
	width: 100%;
	.MuiTypography-root {
		font-family: Montserrat;
		font-size: 1rem;
		font-weight: 600;
		color: #251b33;
	}
	.MuiListItemIcon-root {
		min-width: 0rem;
	}
`;

const SelectedWrapper = styled.div`
	display: flex;
	justify-content: end;
	align-items: center;
	flex-direction: row;
	width: 100%;
	color: #251b33;
	font-size: 1rem;
`;

export default CollapseContainer;
