import EdFormControl from '@Components/UI/Inputs/EdFormControl/EdFormControl';
import { useTypedSelector } from '@Features/store';
import { yupResolver } from '@hookform/resolvers/yup';
import { Checkbox } from '@mui/material';
import { useSnackbar } from '@Providers/useSnackbar';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import TimeDatePicker from '../../Components/DateTimePicker/TimeDatePicker';
import { ADD_SLOT } from '../../Schema/SlotSchema/Slot.schema';
import { WebinarSlotsRequester } from '../../Services/Slots/SlotRequester';
import { Stack } from '@mui/material';
import {
	CancelBtn,
	SaveBtn,
} from '@Pages/Roles/pages/AddRole/Components/addRoleInfo';
import DurationSlider from '../../Components/DurationSlider';
import { Slot } from '../../Types/Webinar.types';
import { FlexLayout } from '@Styled/utilities';
import WebinarUnit from '../../Components/WebinarUnit';
import { webinarsGetSingle } from '../../Slices/Webinar.slice';
import { useDispatch } from 'react-redux';

const AddSlot: React.FC = () => {
	const dispatch = useDispatch();
	const { displaySnackbar } = useSnackbar();
	const history = useHistory();
	const { currentEntity } = useTypedSelector((state) => state.Webinars);

	const { getValues, control, formState, watch, setValue } = useForm<Slot>({
		resolver: yupResolver(ADD_SLOT),
		mode: 'all',
		defaultValues: {
			unlimited: true,
		},
	});

	const onSave = async (form: Slot) => {
		try {
			const {
				data: { data },
			} = await WebinarSlotsRequester.getInstance().create({
				...form,
				webinar_id: currentEntity?.id,
			});
			dispatch(webinarsGetSingle({ id: currentEntity?.id as number }));
			history.goBack();
			displaySnackbar('success', `Slot created successfully`);
		} catch (e) {
			const msg = e.response?.data.message || 'Unable to Create Slot ';
			displaySnackbar('error', msg);
		}
	};

	const watchUnlimited = watch('unlimited');

	useEffect(() => {
		if (watchUnlimited) setValue('capacity', null, { shouldValidate: true });
	}, [watchUnlimited]);

	return (
		<>
			<FlexLayoutStyle>
				<WebinarUnit />
				<EdFormControl
					control={control}
					required
					name={`name`}
					label="Slot Name"
				/>
				<Space flexDirection="row">
					<TimeDatePicker
						isEditFrom={false}
						control={control}
						name={`start_time`}
					/>
				</Space>
				<EdFormControl
					control={control}
					required
					name={`description`}
					label="Description"
					multiline
				/>
				<EdFormControl
					control={control}
					required
					name={`slot_url`}
					label="Slot URL"
				/>

				<FlexLayout flexDirection="row">
					<EdFormControlStyle
						control={control}
						width="4.75rem"
						disabled={watchUnlimited}
						type="number"
						inputMode="numeric"
						name={`capacity`}
						label="Capacity"
						required
						style={{ backgroundColor: watchUnlimited ? '#f3f3f3' : '' }}
					/>
					<Controller
						name="unlimited"
						control={control}
						render={({ field }) => (
							<FlexLayout
								display="flex"
								justifyContent="space-between"
								alignItems="center"
							>
								<Checkbox
									checked={watchUnlimited ? true : false}
									{...field}
									id="Unlimited"
								/>
								<LabelStyle>Unlimited</LabelStyle>
							</FlexLayout>
						)}
					/>
				</FlexLayout>

				<DurationSlider
					isEditFrom={false}
					name={`duration`}
					control={control}
				/>
			</FlexLayoutStyle>
			<StackStyle spacing={2} direction={'row-reverse'}>
				<SaveBtn
					variant="contained"
					disabled={!formState.isValid || !formState.isDirty}
					onClick={() => {
						onSave(getValues());
					}}
				>
					SAVE
				</SaveBtn>
				<CancelBtn
					variant="contained"
					disabled={!formState.isDirty}
					onClick={() => history.goBack()}
				>
					CANCEL
				</CancelBtn>
			</StackStyle>
		</>
	);
};

export const FlexLayoutStyle = styled(FlexLayout)`
	flex-direction: column;
	gap: 2.188rem;
`;

export const Space = styled(FlexLayout)`
	gap: 2rem;
`;

export const StackStyle = styled(Stack)`
	margin-top: 2.625rem;
`;

export const EdFormControlStyle = styled(EdFormControl)`
	.Capacity {
		background-color: #f3f3f3;
	}
`;

export const LabelStyle = styled.label`
	font-size: 1rem;
	color: ${(props) => props.theme.palette.cadet.main};
	font-weight: 700;
`;

export default AddSlot;
