import React from 'react';
import styled from 'styled-components';
import EditAdminInfo from './Components/EditAdminInfo';

const EditAdmin = () => {
	return (
		<Container>
			<EditAdminInfo />
		</Container>
	);
};

const Container = styled.div`
	padding: 1.375rem;
	border-radius: 10px;
	border: 1px solid #5ac0fc;
	height: 100vh;
`;

export default EditAdmin;
