import React, { useCallback, useContext, useState } from 'react';
import { Alert, Button, Slide, SlideProps, Snackbar } from '@mui/material';
import { createSlice } from '@reduxjs/toolkit';
interface DefaultValues {
	displaySnackbar(type: 'error' | 'success', message: string): void;
}
const SnackBarContext = React.createContext<DefaultValues>({
	displaySnackbar: () => {},
});

function TransitionUp(props: SlideProps) {
	return <Slide {...props} direction="up" />;
}
const SnackProvider: React.FC = ({ children }) => {
	const [message, setMessage] = useState('');
	const [type, setType] = useState<'error' | 'success' | undefined>();
	const displaySnackbar = useCallback(
		(type: 'error' | 'success', message: string) => {
			setType(type);
			setMessage(message);
		},
		[]
	);
	const onClose = () => {
		setTimeout(() => {
			setMessage('');
		}, 1000);
	};
	return (
		<SnackBarContext.Provider value={{ displaySnackbar }}>
			<Snackbar
				message={message}
				open={!!message}
				className={type}
				autoHideDuration={5000}
				anchorOrigin={{
					horizontal: 'center',
					vertical: 'bottom',
				}}
				TransitionComponent={TransitionUp}
				onClose={onClose}
				sx={{zIndex : '9999999999'}}
			>
				<Alert
					severity={type}
					action={
						<Button onClick={onClose} color="inherit" size="small">
							Dismiss
						</Button>
					}
				>
					{message}
				</Alert>
			</Snackbar>
			{children}
		</SnackBarContext.Provider>
	);
};

export default SnackProvider;

export const useSnackbar = () => useContext(SnackBarContext);
