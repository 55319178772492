import React from 'react';
import { FlexLayout } from '@Styled/utilities';
import { Button } from '@mui/material';
import {
	DragDropContext,
	Draggable,
	Droppable,
	DropResult,
} from 'react-beautiful-dnd';
import { DevTool } from '@hookform/devtools';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { ClassroomAdmissionForm } from '@Pages/ClassRooms/Types/ClassroomAdmission.types';
import QuestionSectionRHF from './QuestionSectionRHF';

type Props = {};

const QuestionsRHF: React.FC<Props> = () => {
	const { control, watch } = useFormContext<ClassroomAdmissionForm>();

	const { fields, append, swap, update, remove } = useFieldArray({
		control,
		name: 'questions',
		keyName: 'questionId',
	});

	const watchedQuestion = watch('questions');

	const controlledFields = fields.map((field, index) => {
		return {
			...field,
			...watchedQuestion[index],
		};
	});
	const addQuestionHandler = () => {
		append({ order: fields.length + 1, question: '', type: 'essay' });
	};

	const onDragEnd = (result: DropResult) => {
		const { source, destination } = result;
		if (!destination) return;
		swap(source.index, destination.index);
	};

	return (
		<div>
			<FlexLayout alignItems="flex-end" justifyContent="flex-end" mb={'20px'}>
				<Button onClick={addQuestionHandler} variant="contained">
					Add Question
				</Button>
			</FlexLayout>
			<DragDropContext onDragEnd={onDragEnd}>
				<div>
					<Droppable droppableId="question">
						{(provided) => (
							<div {...provided.droppableProps} ref={provided.innerRef}>
								{provided.placeholder}
								{controlledFields.map((question, index) => (
									<Draggable
										key={question.questionId}
										draggableId={question.questionId}
										index={index}
									>
										{(provided) => {
											return (
												<div
													ref={provided.innerRef}
													{...provided.draggableProps}
													{...provided.dragHandleProps}
												>
													<QuestionSectionRHF
														update={update}
														remove={remove}
														key={question.questionId}
														question={question as Question}
														index={index}
													/>
												</div>
											);
										}}
									</Draggable>
								))}
							</div>
						)}
					</Droppable>
				</div>
			</DragDropContext>
			<DevTool control={control} />
		</div>
	);
};

export default QuestionsRHF;
