import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import EditDocumentFormPage from '@Pages/ClassRooms/Pages/SingleCourse/Pages/CourseForm/Pages/CourseContent/Pages/Documents/EditDocumentFormPage';
import { useDispatch } from 'react-redux';
import { documentsGetSingle } from '@Pages/ClassRooms/Pages/SingleCourse/Pages/CourseForm/Pages/CourseContent/Pages/Documents/Slices/Documents.slice';
import { useTypedSelector } from '@Features/store';

type Props = {};

const EditDocument: React.FC<Props> = () => {
	const { id } = useParams<{ id: string; label: string }>();
	const dispatch = useDispatch();
	const {
		currentEntity,
		loaders: { getSingle },
	} = useTypedSelector((state) => state.Documents);
	useEffect(() => {
		dispatch(documentsGetSingle({ id }));
	}, []);
	return (
		<div>
			{currentEntity && getSingle === 'fulfilled' && <EditDocumentFormPage />}
		</div>
	);
};

export default EditDocument;
