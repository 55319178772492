import { filter } from './../admins/adminSlice.types';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
	ScratchCardsDeleteRequest,
	ScratchCardsGenerateRequest,
	ScratchCardsGetRequest,
} from '@Services/scratchCards/scratchCards.req';
import {
	DeleteResponse,
	ScratchCardsResponse,
} from '@Services/scratchCards/scratchCards.res.types';
import {
	DeleteScratchPayload,
	GeneratePayload,
	ScratchCardsPayload,
	ScratchCardsState,
} from './scratchCardsSlice.types';

//mapping object
const columnHeadToField: any = {
	'creation date': 'created_at',
	classroom: 'title',
	course: 'name',
};

export const getScratchCards = createAsyncThunk<
	ScratchCardsResponse,
	ScratchCardsPayload,
	{ rejectValue: string }
>(
	'scratchCards/getScratchCards',
	async (
		{ page, perPage, sortBy, filters, query, flag, from, to },
		thunkApi
	) => {
		try {
			if (
				Object.keys(sortBy).length !== 0 &&
				sortBy?.field in columnHeadToField
			) {
				sortBy.field = columnHeadToField[sortBy.field];
			}
			const { data } = await ScratchCardsGetRequest(
				page,
				perPage,
				sortBy,
				filters,
				query,
				flag,
				from,
				to
			);
			return data;
		} catch (err) {
			const msg = err.response?.data.messege || 'failed';
			return thunkApi.rejectWithValue(msg);
		}
	}
);

export const deleteScratchCard = createAsyncThunk<
	DeleteResponse,
	DeleteScratchPayload,
	{ rejectValue: string }
>('scratchCards/deleteScratchCard', async ({ id }, thunkApi) => {
	try {
		const { data } = await ScratchCardsDeleteRequest(id);
		return { ...data, id };
	} catch (err) {
		const msg = err.response?.data.message || 'failed';
		return thunkApi.rejectWithValue(msg);
	}
});

export const generateScratchCards = createAsyncThunk<
	string,
	GeneratePayload,
	{ rejectValue: string }
>(
	'scratchCards/generateScratchCard',
	async ({ classroom_id, course_id, quantity, scheme }, thunkApi) => {
		try {
			const { data } = await ScratchCardsGenerateRequest(
				classroom_id,
				course_id,
				scheme,
				quantity
			);
			return data;
		} catch (err) {
			const msg = err.response?.data.message || 'failed';
			return thunkApi.rejectWithValue(msg);
		}
	}
);
const initialState: ScratchCardsState = {
	scratchCards: [],
	filters: [],
	codes: '',
	total: 0,
	perPage: 0,
	page: 0,
	lastPage: 0,
	loaders: {
		scratchCards: null,
		deleteSc: null,
		generate: null,
		exportFile: null,
	},
	errors: {
		scratchCards: '',
		deleteSc: '',
		generate: '',
		exportFile: '',
	},
};
const ScratchCardsSlice = createSlice({
	name: 'scratchCards',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(getScratchCards.pending, (state) => {
				state.loaders.scratchCards = true;
				state.errors.scratchCards = '';
			})
			.addCase(getScratchCards.fulfilled, (state, { payload }) => {
				let { data, filters, message, meta } = payload;
				var dataStr = 'data:text/csv;charset=utf-8,' + payload;
				if (data) {
					state.scratchCards = data;
					state.total = meta.total;
					state.page = meta.current_page;
					state.perPage = meta.per_page;
					state.lastPage = meta.last_page;
					state.filters = filters;
				} else {
					var downloadAnchorNode = document.createElement('a');
					downloadAnchorNode.setAttribute('href', dataStr);
					downloadAnchorNode.setAttribute('download', 'scratchCards.csv');
					document.body.appendChild(downloadAnchorNode); // required for firefox
					downloadAnchorNode.click();
					downloadAnchorNode.remove();
				}
				state.loaders.scratchCards = false;
			})
			.addCase(getScratchCards.rejected, (state, action) => {
				state.errors.scratchCards = action.payload;
				state.loaders.scratchCards = undefined;
			});

		builder
			.addCase(generateScratchCards.pending, (state) => {
				state.loaders.generate = true;
				state.errors.generate = '';
			})
			.addCase(generateScratchCards.fulfilled, (state, { payload }) => {
				state.codes = payload;
				state.loaders.generate = false;
			})
			.addCase(generateScratchCards.rejected, (state, action) => {
				state.errors.generate = action.payload;
				state.loaders.generate = undefined;
			});

		builder
			.addCase(deleteScratchCard.pending, (state) => {
				state.loaders.deleteSc = true;
				state.errors.deleteSc = '';
			})
			.addCase(deleteScratchCard.fulfilled, (state, { payload }) => {
				state.scratchCards = state.scratchCards.filter(
					(sc) => sc.id !== payload?.id
				);
				state.loaders.deleteSc = false;
			})
			.addCase(deleteScratchCard.rejected, (state, action) => {
				state.errors.deleteSc = action.payload;
				state.loaders.deleteSc = undefined;
			});
	},
});

export default ScratchCardsSlice.reducer;
