import { NavMenuContext } from '@Components/UI/Layout/Layout';
import {
	ExtraProps,
	ExtraPropsIdentifier,
	RouteGroup,
} from '@Constants/routes';
import { param } from 'jquery';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
	generatePath,
	matchPath,
	useHistory,
	useLocation,
	useParams,
	useRouteMatch,
} from 'react-router-dom';

type ExistingLayer = 1 | 2 | 3;
type RoutingMode = 'end' | 'include';
type Params = {
	routeGroup?: RouteGroup;
	baseURL: string;
	mode?: RoutingMode;
	routeSearchValue?: string;
	routePlacement?: ExistingLayer;
	extraProps?: ExtraProps;
};
const useInvalidateRouteGroup = (params: Params) => {
	const [baseURL, setURL] = useState<string>();
	const { setBaseURL, setCurrentRouteGroup } = useContext(NavMenuContext);

	const { pathname } = useLocation();
	const { location } = useHistory();
	const { id, label } = useParams<{ label: string; id: string }>();
	const match = useRouteMatch({
		path: params.baseURL,
		location: location,
	});

	const isCurrent = useMemo(() => {
		if (!params.routeGroup || !match) return;
		const { isExact, path, url, ...others } = match;
		const newBaseURL = generatePath(params.baseURL, {
			label: label ?? '',
			id: id ?? '',
		});

		const splitted = pathname.trim().split(newBaseURL)[1].split('/');

		if (
			pathname.endsWith(newBaseURL) ||
			splitted[0].endsWith(newBaseURL) ||
			splitted.length <= 2
		) {
			setURL(params.baseURL);
			return true;
		}
		return false;
	}, [pathname]);

	useEffect(() => {
		if (isCurrent) {
			invalidate();
		}
	}, [isCurrent]);
	const invalidate = React.useCallback(() => {
		if (isCurrent) {
			if (!params.routeGroup) return;
			setCurrentRouteGroup && setCurrentRouteGroup(params.routeGroup);
			if (baseURL) {
				setBaseURL && setBaseURL(baseURL);
			}
		}
	}, [baseURL, isCurrent, params.routeGroup, setBaseURL, setCurrentRouteGroup]);

	const setGroupURL = (url: string) => {
		setURL(url);
		setBaseURL && setBaseURL(url);
	};
	return {
		invalidate,
		isCurrent,
		invalidatedBaseURL: baseURL,
		setURL: setGroupURL,
		setCurrentRouteGroup,
	};
};

export default useInvalidateRouteGroup;
