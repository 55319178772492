export const STUDENT_TABLE_SELECT_DATA = {
	gender: [
		{ name: 'male', value: 'male' },
		{ name: 'female', value: 'female' },
	],

	edu_type: [
		{ name: 'National English', value: 'National English' },
		{
			name: 'National Arabic',
			value: 'National Arabic',
		},
	],

	edu_field: [
		{
			name: 'Science',
			value: 'Science',
		},
		{
			name: 'Mathematics',
			value: 'Mathematics',
		},
		{
			name: 'Literature',
			value: 'Literature',
		},
	],

	edu_year: [
		{
			name: 'First Secondary',
			value: 'First Secondary',
		},
		{
			name: 'Second Secondary',
			value: 'Second Secondary',
		},
		{
			name: 'Third Secondary',
			value: 'Third Secondary',
		},
	],
	parent_relation: [
		{
			name: 'Father',
			value: 'Father',
		},
		{
			name: 'Mother',
			value: 'Mother',
		},
		{
			name: 'Brother',
			value: 'Brother',
		},
		{
			name: 'Sister',
			value: 'Sister',
		},
		{
			name: 'Other',
			value: 'Other',
		},
	],
};
