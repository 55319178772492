import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import useInvalidateRouteGroup from '@Hooks/Routes/useInvalidateRouteGroup';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { InvoicesRoute } from '@Pages/Invoices/Constants/routes';
import InvoicesTable from '@Pages/Invoices/InvoicesTable';
import { BreadcrumbContext, useBreadcrumb } from '@Components/UI/Layout/Layout';

const InvoicesTab: React.FC = () => {
	const { url, path } = useRouteMatch();
	useInvalidateRouteGroup({
		baseURL: '/dashboard/invoices',
		mode: 'include',
		routeGroup: InvoicesRoute,
	});

	const { removeAll } = useBreadcrumb();

	useEffect(() => {
		removeAll();
	}, []);

	return (
		<StyledContainer>
			{/* <Switch>
        <Route path={url} exact component={InvoicesTable} />
      </Switch>
      */}
			<Switch>
				{InvoicesRoute && !Array.isArray(InvoicesRoute) && (
					<>
						<Route exact path={url}>
							<InvoicesTable />
						</Route>

						{InvoicesRoute.routes.map((_) => {
							return (
								<Route strict path={`${path}/${_.route}`}>
									{_.component}
								</Route>
							);
						})}
					</>
				)}
			</Switch>
		</StyledContainer>
	);
};
const StyledContainer = styled.div`
	/* margin-top: 70px; */
`;

export default InvoicesTab;
