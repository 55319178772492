import { HashString } from '@Components/UI/Tabs/RoutedTabs/RoutedTabs.types';
import RoutedTabs from '@Components/UI/Tabs/Tabs';
import { Spacer } from '@Styled/utilities';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useRouteMatch } from 'react-router-dom';
import EditClassroomBasicInfo from './Components/EditClassroomBasicInfo';
import { useTypedSelector } from '@Features/store';
import styled from 'styled-components';
import { border, BorderProps, space, SpaceProps } from 'styled-system';
import { getAdmissionForm } from '@Features/classrooms/admissionFormSlice';
import { fetchCoursesDropdown } from '@Features/dropdowns/dropdownsSlice';
import { getEducationalInfo } from '@Features/classrooms/educationalSlice';
import { usePageLoader } from '@Providers/LoadingProvider';
import ClassroomAdmissionPage from '@Pages/ClassRooms/Pages/Admission/ClassroomAdmissionPage';
import { Groups } from '../Groups/Groups';
const EditClassroom: React.FC = () => {
	const { hash } = useLocation();
	const [tabIndex, setTabIndex] = useState<HashString>(hash as HashString);
	const [hasAdmission, setHasAdmission] = useState(false);
	const dispatch = useDispatch();
	const { setIsLoading } = usePageLoader();
	const {
		currentClassroom,
		loaders: { getOne },
	} = useTypedSelector((state) => state.classroom);
	const { id: admission_id, loaders } = useTypedSelector(
		(state) => state.classroomAdmissionForm
	);
	const auth = useTypedSelector((state) => state.auth);
	useEffect(() => {
		dispatch(getEducationalInfo());
	}, []);

	useEffect(() => {
		if (currentClassroom) dispatch(fetchCoursesDropdown(currentClassroom.id));
	}, [currentClassroom]);

	useEffect(() => {
		if (currentClassroom?.admission_form_id) {
			dispatch(getAdmissionForm(currentClassroom.admission_form_id));
		}
	}, [currentClassroom?.admission_form_id]);

	useEffect(() => {
		if (getOne === undefined || loaders.getOne === undefined) return;
		const isLoading = getOne || loaders.getOne;
		setIsLoading(Boolean(isLoading));
	}, [getOne, loaders.getOne]);

	return (
		<CardContainer>
			<RoutedTabs>
				<RoutedTabs.Tabs
					$hashPrefix="#"
					hashes={['#info', '#admission']}
					onChange={(e, value) => setTabIndex(value)}
					orientation="vertical"
					value={tabIndex}
				>
					<RoutedTabs.Item
						value="#info"
						label={<RoutedTabs.ItemTitle>Basic Info</RoutedTabs.ItemTitle>}
						icon={<RoutedTabs.ItemIcon>menu_book</RoutedTabs.ItemIcon>}
					/>
					{(auth.user.type === 'super' || auth.permissions.has('u-cl')) && (
						<RoutedTabs.Item
							value="#admission"
							label={<RoutedTabs.ItemTitle>Admission</RoutedTabs.ItemTitle>}
							disabled={!hasAdmission}
							icon={<RoutedTabs.ItemIcon>post_add</RoutedTabs.ItemIcon>}
						/>
					)}
					
				</RoutedTabs.Tabs>
				<Spacer mx={'1rem'} />
				<RoutedTabs.Content value={tabIndex}>
					<RoutedTabs.ContentItem hash="#info">
						{currentClassroom && getOne === 'fulfilled' && (
							<EditClassroomBasicInfo
								onHasAdmissionChange={(val) => {
									setHasAdmission(val);
								}}
							/>
						)}
					</RoutedTabs.ContentItem>
					<RoutedTabs.ContentItem hash="#admission">
						{currentClassroom && admission_id && loaders.getOne === false && (
							<ClassroomAdmissionPage mode="edit" />
						)}
						{currentClassroom && !admission_id && (
							<ClassroomAdmissionPage mode="create" />
						)}
					</RoutedTabs.ContentItem>
				</RoutedTabs.Content>
			</RoutedTabs>
		</CardContainer>
	);
};

export default EditClassroom;

const CardContainer = styled.div<SpaceProps & BorderProps>`
	padding: 1.375rem;
	border-radius: 10px;
	border: 1px solid ${(props) => props.theme.palette.primary.main};
	${space};
	${border}
`;
