import { useTypedSelector } from '@Features/store';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSnackbar } from '@Providers/useSnackbar';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { cloneDeep } from 'lodash';
import { FlexLayout, GridLayout, Spacer } from '@Styled/utilities';
import { Editor } from '@tinymce/tinymce-react';
import {
	Button,
	Checkbox,
	InputAdornment,
	ListItemText,
	MenuItem,
	Typography,
} from '@mui/material';
import EdIcon from '@Components/UI/Utilities/EdIcon/EdIcon';
import EdFormControl from '@Components/UI/Inputs/EdFormControl/EdFormControl';
import TagChip from '@Components/UI/Chips/TagChip/TagChip';
import { EdFormControlLabel } from '@Components/UI/Inputs/EdFormControlLabel/EdFormControlLabel';
import styled from 'styled-components';
import {
	getQuestion,
	TypedTestOrderingQuestionPayload,
} from '../../../Types/TestQuestion';
import { ORDERING_SCHEMA } from '../../../Schema/OrderingSchema';
import { TestQuestionsRequester } from '../../../Services/TestQuestions/TestQuestionsRequester';
import OrderingOption from './OrderingOption';
import { Note } from './Ordering';
import TinyMceControl from '@Components/UI/TinyMceControl/TinyMceControl';
import AddTag from '../../AddTag';

const EditOrderingInfo = () => {
	const editorRef = useRef<any>(null);
	const feedbackEditorRef = useRef<any>(null);
	const [addedId, setAddedId] = useState<number | undefined>();
	const { dropdownTags } = useTypedSelector((state) => state.dropdowns);
	const { currentEntity } = useTypedSelector((state) => state.Tests);
	const { currentEntity: currentQuestion } = useTypedSelector(
		(state) => state.TestQuestions
	);
	const { displaySnackbar } = useSnackbar();
	const history = useHistory();

	const question = useMemo(() => {
		if (!currentQuestion) return;
		return getQuestion('ordering', currentQuestion);
	}, []);

	const { control, watch, formState, handleSubmit, setValue } = useForm<
		TypedTestOrderingQuestionPayload<'ordering'>
	>({
		mode: 'all',
		resolver: yupResolver(ORDERING_SCHEMA),
		defaultValues: {
			test_id: currentEntity?.id ?? 0,
			tags: question?.tags.map((q) => q.id),
			content: currentQuestion?.content,
			feedback: currentQuestion?.feedback,
			id: currentQuestion?.id,
			orderingOptions: cloneDeep(currentQuestion?.orderingOptions),
			order: currentQuestion?.order ?? 1,
			type: 'ordering',
			weight: currentQuestion?.weight,
		},
	});

	const watchTags = watch('tags');
	const watchOrderOptions = watch('orderingOptions');

	useEffect(() => {
		if (addedId !== undefined) {
			setValue('tags', [...watchTags, addedId]);
		}
	}, [addedId]);

	const [orderIndex, setOrderIndex] = useState<number>(
		watchOrderOptions?.length
	);

	const canRemoveOption = useMemo(() => {
		if (!watchOrderOptions) return false;
		return watchOrderOptions.length > 2;
	}, [watchOrderOptions]);

	const { fields, append, remove } = useFieldArray({
		control,
		name: 'orderingOptions',
		keyName: 'opId',
	});

	const onSave = async (form: TypedTestOrderingQuestionPayload<'ordering'>) => {
		try {
			form.orderingOptions = form.orderingOptions.map((or, i) => ({
				...or,
				order: i + 1,
			}));
			await TestQuestionsRequester.instance.update(form);
			displaySnackbar('success', 'Updated question successfully');
			history.goBack();
		} catch (error) {
			displaySnackbar('error', "Couldn't update question");
		}
	};
	const { isDirty, isValid } = formState;

	return (
		<FlexLayout flexDirection="column" px="2.625rem" flex="1">
			<FlexLayout flex="1">
				<Controller
					control={control}
					name="content"
					render={({ field: { value, onChange, ...props } }) => {
						return (
							<>
								<TinyMceControl
									{...props}
									disabled={currentEntity.active}
									value={value}
									onEditorChange={(e) => onChange(e)}
									onInit={(evt, editor) => (editorRef.current = editor)}
								/>
							</>
						);
					}}
				/>
			</FlexLayout>

			<Spacer my="1.5rem" />
			<Note>
				Note: Add possible answers in the correct order. We'll present them
				randomly for the end-user.
			</Note>
			<GridLayout gridTemplateColumns={'repeat(1,1fr)'} gridGap="1.875rem">
				<FlexLayout mb="1.875rem" flexDirection="column">
					{fields.map((item, index) => {
						return (
							<OrderingOption
								key={item.opId}
								canRemoveOption={canRemoveOption}
								remove={remove}
								control={control}
								index={index}
								orderIndex={orderIndex}
								setOrderIndex={setOrderIndex}
							/>
						);
					})}
					{!currentEntity.active && (
						<div>
							<Button
								onClick={() => {
									setOrderIndex(orderIndex + 1);
									append({ option: '', order: orderIndex + 1 });
								}}
								variant="outlined"
								startIcon={<EdIcon>add</EdIcon>}
							>
								ADD ANSWER
							</Button>
						</div>
					)}
				</FlexLayout>
				<FlexLayout alignItems="center">
					<EdFormControl
						width="5rem"
						type="number"
						control={control}
						disabled={currentEntity.active}
						name="weight"
						label="Weight"
					/>
					<Spacer mx="0.5rem" />
					<Typography fontWeight="600">Points</Typography>
				</FlexLayout>
				<FlexLayout gridGap="1rem">
					<EdFormControl
						disabled={currentEntity.active}
						label="Tags"
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<EdIcon mIconType="Regular">local_offer</EdIcon>
								</InputAdornment>
							),
						}}
						SelectProps={{
							multiple: true,
							renderValue: (value) => {
								const values = Array.from(value as number[]);
								return (
									<>
										{values.map((v) => {
											const tag = dropdownTags.find((_) => _.id === v);
											return <>{tag?.name},</>;
										})}
									</>
								);
							},
						}}
						control={control}
						name="tags"
						select
						multiple
						renderValues={(value) => {
							return (
								<>
									<Spacer mb="1rem" />
									<FlexLayout
										// maxWidth={"17rem"}
										overflow="auto"
										py={'0.4rem'}
										flexWrap="wrap"
										gridGap="0.5rem"
									>
										{watchTags &&
											dropdownTags
												.filter((_) => watchTags?.includes(_.id))
												.map((tag) => {
													return (
														<TagChip
															bgColor={tag.theme}
															icon={<EdIcon>local_offer</EdIcon>}
															label={tag.name}
														/>
													);
												})}
									</FlexLayout>
								</>
							);
						}}
					>
						{dropdownTags.map((tag, index) => {
							return (
								<MenuItem value={tag.id} key={`tag-${tag.id}`}>
									<CheckboxStyle
										colorTag={tag.theme}
										checked={watchTags?.includes(tag.id)}
									/>
									<ListItemText primary={tag.name} />
								</MenuItem>
							);
						})}
					</EdFormControl>
					{!currentEntity.active && <AddTag setAddedId={setAddedId} />}
				</FlexLayout>
				<FlexLayout>
					<EdFormControlLabel>Feedback</EdFormControlLabel>
					<Controller
						control={control}
						name="feedback"
						render={({ field: { onChange, value, ...props }, fieldState }) => {
							return (
								<TinyMceControl
									{...props}
									disabled={currentEntity.active}
									min_height={200}
									value={value}
									onEditorChange={(e) => onChange(e)}
									onInit={(evt, editor) => (feedbackEditorRef.current = editor)}
								/>
							);
						}}
					/>
				</FlexLayout>

				<FlexLayout mt="1.688rem" justifyContent="flex-end">
					<Button
						disabled={!isDirty}
						variant="contained"
						color="warning"
						onClick={() => history.goBack()}
					>
						Cancel
					</Button>
					<Spacer mx="0.5rem" />
					<Button
						onClick={handleSubmit(onSave)}
						disabled={!isValid || !isDirty}
						variant="contained"
					>
						Save
					</Button>
				</FlexLayout>
			</GridLayout>
		</FlexLayout>
	);
};

const CheckboxStyle = styled(Checkbox)<{ colorTag: any }>`
	color: ${({ colorTag }) => colorTag};
`;
export default EditOrderingInfo;
