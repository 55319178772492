export const TooltipTitle = (arr: Array<any>) => {
	let title = '';
	arr.forEach((item: any, index = 0) => {
		title += `${item['name']}`;
		if (index !== arr.length - 1) {
			title += ' , ';
		}
	});
	return title;
};
