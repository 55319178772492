import {
	getAllClassroomEnrollments,
	getAllClassroomsGraph,
	getClassroomEnrollments,
	getClassroomGraph,
	getCourseEnrollments,
	getCourseGraph,
} from '@Features/classrooms/classroomSlice';
import { useTypedSelector } from '@Features/store';
import {
	Icon,
	Paper,
	PaperProps,
	Button,
	ButtonGroup,
	IconButton,
	TextField,
	MenuItem,
	TextFieldProps,
} from '@mui/material';
import {
	deepPurple,
	green,
	grey,
	orange,
	red,
	yellow,
} from '@mui/material/colors';
import {
	ClassroomGraphPayload,
	CourseGraphPayload,
	EnrollmentType,
	GraphPayload,
	GraphSlice,
	GraphSliceType,
} from '@Services/classrooms/classrooms.res.types';
import { rgba } from 'polished';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import MultipleLinesChart from '@Components/UI/Charts/MultipleLinesChart/MultipleLinesChart';
import { object, mixed, array, date, number } from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import MUISelectController, {
	OptionKeyPair,
} from '@Components/UI/Inputs/MUISelectController/MUISelectController';
import { Spacer } from '@Styled/utilities';
import MonthRangePicker from '@Components/UI/Pickers/MonthRangePicker/MonthRangePicker';
import YearRangePicker from '@Components/UI/Pickers/YearRangePicker/YearRangePicker';
// import { StaticDateRangePicker } from '@mui/x-date-pickers-pro';
import '@mui/lab';
import DateRangePickerPopup, {
	DateRangeOption,
} from '@Components/UI/Pickers/DateRangePickerPopup/DateRangePickerPopup';

import { useParams } from 'react-router-dom';
import { fetchCoursesDropdown } from '@Features/dropdowns/dropdownsSlice';
import {
	AnalyticsCardsContainer,
	enrollmentIcons,
	EnrollmentsCard,
	EnrollmentTypeCard,
	FormActionsWrapper,
	FormControlsWrapper,
	StyledIconButton,
	TypeInfo,
	TypeName,
	TypeValue,
} from '@Components/UI/Analytics/Analytics.styled';
import { AnalyticsTotal } from '@Components/UI/Analytics/Assets/Icons/Icons';
import EdFormControl from '@Components/UI/Inputs/EdFormControl/EdFormControl';
import { AnalyticsSlices } from '@Pages/ClassRooms/Constants/Classroom.constants';
import CourseUnitsAnalytics from './CourseUnitsAnalytics/CourseUnitsAnalytics';
const SCHEMA = object().shape({
	slice: mixed<GraphSliceType>().required(),
	dates: array(),
});

type FormType = {
	course_id: number;
	slice: GraphSliceType;
	dates: any;
};

const CourseAnalytics: React.FC = () => {
	const dispatch = useDispatch();
	const {
		analytics: {
			currentCourse: { enrollments, graph },
		},
		currentCourse,
	} = useTypedSelector((state) => state.classroom);
	const { id } = useParams<{ id: string }>();
	const defaultValues = useMemo<FormType>(() => {
		const startDate = new Date();
		startDate.setDate(startDate.getDate() - 14);
		return {
			course_id: Number(id),
			slice: 'day',
			dates: [startDate, new Date()],
		};
	}, []);
	const {
		control,
		watch,
		register,
		setValue,
		reset,
		handleSubmit,
		formState: { isValid, isDirty },
	} = useForm<FormType>({
		resolver: yupResolver(SCHEMA),
		defaultValues: defaultValues,
		mode: 'all',
	});

	useEffect(() => {
		register('dates');

		if (!currentCourse) return;
		dispatch(getCourseEnrollments(currentCourse.id));
		dispatch(
			getCourseGraph({
				course_id: currentCourse.id,
				from: defaultValues.dates[0],
				to: defaultValues.dates[1],
				slice: 'day',
			})
		);
	}, [currentCourse?.id]);

	const pickTypeColor = useCallback((type: EnrollmentType) => {
		if (type === 'admin') {
			return red[500];
		}
		if (type === 'wallet') {
			return deepPurple[500];
		}
		if (type === 'scratchcard') {
			return orange[500];
		}
		if (type === 'free') {
			return yellow[600];
		}
		if (type === 'instructor') {
			return green['A700'];
		}
	}, []);

	const getGraph = useCallback((args: CourseGraphPayload) => {
		dispatch(getCourseGraph(args));
	}, []);

	const watchSliceType = watch('slice');

	const handleFilterForm = (data: FormType) => {
		if (currentCourse) {
			getGraph({
				slice: data.slice,
				from: data.dates[0],
				to: data.dates[1],
				course_id: currentCourse.id,
			});
		}
	};

	const [dateRange, setDateRangeOption] =
		useState<DateRangeOption>('last 2 weeks');

	const handleDateRangeChange = (type: DateRangeOption) => {
		setDateRangeOption(type);
		if (type === 'custom') return;
		const startDate = new Date();
		const endDate = new Date();
		if (type === 'last 2 weeks') {
			startDate.setDate(startDate.getDate() - 14);
		}
		if (type === 'last month') {
			startDate.setMonth(startDate.getMonth() - 1);
		}
		if (type === 'last 3 months') {
			startDate.setMonth(startDate.getMonth() - 2);
		}
		setValue('dates', [startDate, endDate], {
			shouldValidate: true,
			shouldDirty: true,
		});
	};

	const [open, setOpen] = useState(false);
	const [anchorEl, setAnchorEl] = useState<HTMLElement>();
	const resetForm = () => {
		if (!currentCourse) return;
		reset({
			slice: 'day',
			dates: defaultValues.dates,
		});
		setDateRangeOption('last 2 weeks');
		register('dates');
		getGraph({
			course_id: currentCourse.id,
			slice: 'day',
			from: defaultValues.dates[0],
			to: defaultValues.dates[1],
		});
	};
	return (
		<>
			{enrollments && (
				<AnalyticsCardsContainer>
					<EnrollmentsCard elevation={10}>
						<AnalyticsTotal />
						<TypeInfo>
							<span>{enrollments.purchase_methods_total}</span>
							<label>Enrollments</label>
						</TypeInfo>
					</EnrollmentsCard>
					{enrollments.purchase_methods.map((en) => {
						return (
							<EnrollmentTypeCard color={pickTypeColor(en.purchase_method)}>
								<TypeInfo>
									{enrollmentIcons[en.purchase_method]}

									<TypeValue>{en.purchase_method_count}</TypeValue>
								</TypeInfo>
								<TypeName>{en.purchase_method}</TypeName>
							</EnrollmentTypeCard>
						);
					})}
				</AnalyticsCardsContainer>
			)}

			<FormControlsWrapper>
				<EdFormControl select name="slice" control={control}>
					{AnalyticsSlices.map((_) => {
						return (
							<MenuItem key={_.value} value={_.value}>
								{_.label}
							</MenuItem>
						);
					})}
				</EdFormControl>
				<Spacer mx={14} />

				<StyledIconButton
					onClick={(e: React.MouseEvent<HTMLElement>) => {
						setOpen(!open);
						setAnchorEl(e.currentTarget);
					}}
				>
					<Icon>event</Icon>
				</StyledIconButton>
				<DateRangePickerPopup
					open={open}
					anchorEl={anchorEl}
					onClose={() => {
						setOpen(false);
					}}
					onChange={handleDateRangeChange}
					rangeOptionValue={dateRange}
				>
					<>
						<DateRangePickerPopup.Body>
							{(watchSliceType === 'day' || watchSliceType === 'week') && (
								<Controller
									control={control}
									name="dates"
									render={({ field: { value, onChange, ref, onBlur } }) => {
										return (
											<div></div>
											// <StaticDateRangePicker
											//   calendars={2}
											//   showToolbar={false}
											//   onChange={onChange}
											//   value={value}
											//   ref={ref}
											//   renderInput={(
											//     startProps: TextFieldProps,
											//     endProps: TextFieldProps
											//   ) => (
											//     <>
											//       <TextField {...startProps} />
											//       <TextField {...endProps} />
											//     </>
											//   )}
											// />
										);
									}}
								/>
							)}

							{watchSliceType === 'month' && (
								<MonthRangePicker control={control as any} name="dates" />
							)}
							{watchSliceType === 'year' && (
								<YearRangePicker control={control as any} name="dates" />
							)}
						</DateRangePickerPopup.Body>
					</>
				</DateRangePickerPopup>
			</FormControlsWrapper>
			<FormActionsWrapper>
				<Button onClick={() => resetForm()} variant="outlined">
					Reset
				</Button>
				<Spacer mx={'1rem'} />
				<Button
					disabled={!isValid}
					onClick={handleSubmit(handleFilterForm)}
					variant="contained"
				>
					Apply
				</Button>
			</FormActionsWrapper>

			<MultipleLinesChart
				sliceType={watchSliceType}
				colors={(slice: GraphSlice) => {
					return pickTypeColor(slice.id as EnrollmentType);
				}}
				data={graph}
			/>
			<CourseUnitsAnalytics />
		</>
	);
};

export default CourseAnalytics;
